
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { environment } from '#environment';

import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { DROPDOWN_SETTINGS } from 'app/shared/constants/dropdown-settings.constants';
import { CmxDropdownService } from 'app/shared/components/cmx-dropdown/cmx-dropdown.service';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UtilsService } from '#services/_utils/utils.service';
import { UserService } from "app/auth/_services/user.service";
import { DomainService } from '#services/_domain/domain.service';

declare let AmCharts: any;

@Component({
    selector: 'stock-chart',
    templateUrl: './stock.component.html',
    styleUrls: ['./../charts.scss', './stock.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class StockComponent {

    dropdownSettings = _.cloneDeep(DROPDOWN_SETTINGS);
    editableElements = ['#stock', '.checkmark-container'];
    startDate = moment().format('01/MM/YYYY');
    endDate = moment().format('01/MM/YYYY');
    periodType = 'LAST_12_MONTHS';
    asyncStock = false;
    stockSwitch = true;
    chartData;
    vehicleTypes;
    typeColors = ['#364476', '#525D8F', '#747CA8', '#9DA7CB', '#B1BEDC', '#B7C4D9', '#BBC2CE', '#A9ABAE', '#848688'];
    stockTitle = 'Veículos Nacionalizados';
    selectedClients = [];
    currentUser$;
    currentUser;
    clientOptions: DropdownOption[] = [];
    firstLoad = true;

    constructor(
        public userService: UserService,
        public router: Router,
        private http: HttpClient,
        private utilsService: UtilsService,
        private domainService: DomainService,
        private userCacheService: UserCacheService,
        private _cmxDropdownService: CmxDropdownService,
    ) {}

    ngAfterViewInit() {
        this.currentUser$ = this.userService.currentUser$;
        this.userService.currentUser$.subscribe((user) => {
            this.currentUser = user;
        });
        this.domainService.getClientGroupByName('MERCEDES').subscribe(
            (mercedes) => {
                const clientGroups = this.userCacheService.getClientGroups();
                this.clientOptions = this._cmxDropdownService.toDropdownItems(clientGroups);
                if (this.currentUser.has('AUTOMOTIVE_USER')) {
                    this.selectedClients = this._cmxDropdownService.toDropdownItems([clientGroups[0]]);
                } else {
                    this.selectedClients = this._cmxDropdownService.toDropdownItems(mercedes);
                }
                this.loadStock('LAST_12_MONTHS');
            },
            (error) => {
                this.firstLoad = false;
            }
        );
    }

    changeDateParam(e, period) {
        if (!$(e.target).hasClass('checked')) {
            if (
                period === 'CURRENT_YEAR' ||
                period === 'LAST_12_MONTHS' ||
                period === 'CURRENT_MONTH' ||
                period === 'PREVIOUS_MONTH'
            ) {
                this.loadStock(period);
            } else {
                this.periodType = '';
                this.startDate = moment().add(-period, 'days').format('DD/MM/YYYY');
                this.endDate = moment().format('DD/MM/YYYY');
                this.loadStock();
            }
            $('stock-chart span.checkmark').removeClass('checked');
            $(e.target).addClass('checked');
        }
    }

    loadStock(preset?) {
        this.asyncStock = true;
        this.firstLoad = false;
        this.utilsService.setInvisible('stock-chart', this.editableElements);
        this.getClearedChassis(preset).subscribe(
            (itens) => {
                this.chartData = [];
                this.vehicleTypes = [];
                Object.entries(itens).forEach((item) => {
                    const itemString = item[0].substring(1).slice(0, -1);
                    const splited = itemString.split(',');

                    if (!this.vehicleTypes.includes(splited[0]) && splited[0] !== 'DESCONHECIDO') {
                        this.vehicleTypes.push(splited[0]);
                    }

                    let hasObject = false;
                    this.chartData.forEach((data) => {
                        if (data.date === splited[1]) {
                            hasObject = true;
                            data[splited[0]] = item[1];
                        }
                    });
                    if (!hasObject) {
                        this.chartData.push({
                            date: splited[1],
                        });
                        this.chartData[this.chartData.length - 1][splited[0]] = item[1];
                    }
                });
                this.chartData.forEach((data) => {
                    preset === 'CURRENT_YEAR' || preset === 'LAST_12_MONTHS'
                        ? (data.date = moment(data.date, 'YY/MM').format('MM/YY'))
                        : (data.date = moment(data.date, 'MM/DD').format('DD/MM'));
                });
                const graphs = [];
                this.vehicleTypes.forEach((type, index) => {
                    if (type !== 'DESCONHECIDO') {
                        graphs.push({
                            balloonText: `${type}:[[value]]`,
                            fillAlphas: 1,
                            id: `AmGraph-${index}`,
                            title: `${type}`,
                            type: 'column',
                            valueField: `${type}`,
                            fillColors: `${this.typeColors[index]}`,
                            lineColor: `${this.typeColors[index]}`,
                        });
                    }
                });
                this.stockTitle = 'Veículos Nacionalizados';
                const chart = AmCharts.makeChart('stock', {
                    type: 'serial',
                    categoryField: 'date',
                    startDuration: 0,
                    categoryAxis: {
                        gridPosition: 'start',
                    },
                    trendLines: [],
                    graphs,
                    guides: [],
                    valueAxes: [
                        {
                            id: 'ValueAxis-1',
                            title: 'Veículos',
                        },
                    ],
                    allLabels: [],
                    balloon: {},
                    legend: {
                        enabled: true,
                        useGraphSettings: true,
                    },
                    export: {
                        enabled: true,
                        menu: [
                            {
                                format: 'PDF',
                                label: "<i class='flaticon-download'></i>",
                                title: 'Gerar PDF',
                            },
                        ],
                    },
                    dataProvider: this.chartData,
                });

                $('a[title*=JavaScript]').attr('style', 'display: none; opacity: 0;');
                chart.addListener('drawn', () => {
                    $('a[title*=JavaScript]').attr('style', 'display: none; opacity: 0;');
                });
                this.asyncStock = false;
                this.utilsService.setVisible('stock-chart', this.editableElements);
            },
            (error) => {
                this.chartData = undefined;
                this.asyncStock = false;
                this.utilsService.errorHandler(error, 'Produtos Nacionalizados');
            }
        );
    }

    private getRequest(preset?) {
        let headers = new HttpHeaders();
        let params = new HttpParams();
        if (preset !== undefined && preset !== null && preset !== '') {
            params = params.append('periodType', preset);
        } else {
            if (this.startDate !== undefined && this.startDate !== null) {
                params = params.append('startDate', moment(this.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'));
            }
            if (this.endDate !== undefined && this.endDate !== null) {
                params = params.append('endDate', moment(this.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD'));
            }
        }
        headers = headers.set('X-Requested-Client-Groups', this.selectedClients.map((c) => c.id).join(','));
        return { headers, params };
    }

    private getClearedChassis(preset?): Observable<any> {
        const request = this.getRequest(preset);
        return this.http.get(`${environment.endpoints.automotiveV2QueryService}/api/v2/stats/nationalizations`, request);
    }

}
