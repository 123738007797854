import { HttpHeaders, HttpParams } from '@angular/common/http';
import { AppModule } from 'app/app.module';
import moment from 'moment';
import * as _ from 'lodash';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { IDocumentFilter } from '../interfaces/document-filter.interface';
import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';

export class DocumentsFilter {

    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);

    private _initialDocumentFilter: IDocumentFilter = <IDocumentFilter>{};
    private _documentFilter: IDocumentFilter = <IDocumentFilter>{
        automotive : false,
        searchType : 'IMP',
        search : '',
        clientGroups : [],
        referenceDateType : [{ id: 'registration', itemName: 'Criação' }],
        externalTypes : true,
        clientTypeFiles: [],
        internalTypeFiles: [],
    };

    private _startDate;
    private _endDate;
    private _filterField: string;
    private _filterValue: string;

    private _page: number;
    private _size: number;

    constructor(filter?: DocumentsFilter) {
        this._documentFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'documentFilter', this._documentFilter, filter);
        this._initialDocumentFilter = _.cloneDeep(this._documentFilter);
        this._startDate = filter ? filter._startDate : moment().add(-3, 'M').format('DD/MM/YYYY');
        this._endDate = filter ? filter._endDate : moment().format('DD/MM/YYYY');
        this._page = filter ? filter._page : 0;
        this._size = filter ? filter._size : 20;
    }

    getAutomotive(): boolean { return this._documentFilter.automotive }
    getSearchType(): string { return this._documentFilter.searchType }
    getSearch(): string { return this._documentFilter.search }
    getClientGroups(): string[] { return this._documentFilter.clientGroups }
    getReferenceDateType(): DropdownOption[] { return this._documentFilter.referenceDateType }
    getExternalTypes(): boolean { return this._documentFilter.externalTypes }
    getStartDate() { return this._startDate }
    getEndDate() { return this._endDate }
    getFilterField() { return this._filterField; }
    getFilterValue() { return this._filterValue; }
    getPage(): number { return this._page }
    getSize(): number { return this._size }
    getTypeFiles(): string[] {
        return this.getExternalTypes() ? this._documentFilter.clientTypeFiles : this._documentFilter.internalTypeFiles;
    }

    setAutomotive(event) { this._documentFilter.automotive = event}
    setSearchType(event) { this._documentFilter.searchType = event }
    setSearch(search): void { this._documentFilter.search = search }
    setClientGroups(clientGroups): void { this._documentFilter.clientGroups = clientGroups }
    setReferenceDateType(referenceDateType): void { this._documentFilter.referenceDateType = referenceDateType }
    setExternalTypes(externalTypes): void { this._documentFilter.externalTypes = externalTypes }
    setStartDate(startDate): void { this._startDate = startDate }
    setEndDate(endDate): void { this._endDate = endDate }
    setFilterField(filterField): void { this._filterField = filterField }
    setFilterValue(filterValue): void { this._filterValue = filterValue }
    setPage(page): void { this._page = page }
    setSize(size): void { this._size = size }
    setTypeFiles(typeFiles): void {
        this.getExternalTypes() ? this._documentFilter.clientTypeFiles = typeFiles :
        this._documentFilter.internalTypeFiles = typeFiles
    }

    getRequest() {
        this._initialDocumentFilter = this._userPreferencesService.verifyFilterChanges(
            this._initialDocumentFilter, this._documentFilter, 'documentFilter', this._userCacheService.getUserPreferences().id);
        let params = new HttpParams();
        let headers = new HttpHeaders();
        headers = headers.set('X-Requested-Client-Groups', this.getSearch() === '' ? this.getClientGroups().map((c) => c['id']).join(',') : '' );
        params = params.append('typeFiles', `${(this.getTypeFiles().map((c) => c['id']).join(',')) || []}`);
        params = params.append('startDate', `${moment(this.getStartDate(), 'DD/MM/YYYY').format('YYYY-MM-DD')}`);
        params = params.append('endDate', `${moment(this.getEndDate(), 'DD/MM/YYYY').format('YYYY-MM-DD')}`);
        if (this.getFilterValue()) {
            params = params.append(`${this.getFilterField()}`, `${this.getFilterValue()}`);
        }
        params = params.append('referenceDateType', this.getReferenceDateType()[0]['id']);
        params = params.append('importationNumber', this.getSearchType() === 'IMP' ? this.getSearch() : '');
        params = params.append('clientReference', this.getSearchType() === 'REF' ? this.getSearch() : '');
        params = params.append('chassisNumber', this.getSearchType() === 'CHASSI' ? this.getSearch() : '');
        params = params.append('externalType', `${this.getExternalTypes()}`);
        params = params.append('page', `${this.getPage()}`);
        params = params.append('size', `${this.getSize()}`);
        return { params, headers };
    }
}
