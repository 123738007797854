import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";

import { UserAgentInterceptor } from "./user-agent.interceptor";
import { MaskDataInterceptor } from "./mask-data.interceptor";
import { MaskDataCustomInterceptor } from "./mask-data-custom.interceptor";
import { UrfCodesInterceptor } from "./urf-codes.interceptor";
import { AcceptLanguageInterceptor } from "./accept-language.interceptor";

@NgModule({
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UserAgentInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MaskDataInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MaskDataCustomInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UrfCodesInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AcceptLanguageInterceptor,
            multi: true
        }
    ]
})
export class InterceptorsModule { }
