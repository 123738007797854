import { Injectable } from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { createXYChart, createPieChart, createDraggableRangeChart, createDragOrderingBars } from './graph-types';

export interface IChartAxis {
    axisPosition: string;
    categoryField: string;
}

export interface IChartContent {
    name: string;
    valueYField: string;
    valueXField: string;
}

export interface IChartConfig {
    chartId: string;
    chartType: string;
    axis: IChartAxis[];
    content?: IChartContent[];
    showLegend?: boolean;
}

@Injectable()
export class AmCharts5Service {

    amCharts5 = am5.Root;

    createChart(chartConfig: IChartConfig, data, visibleDataPoints?: number) {
        let root = am5.Root.new(chartConfig.chartId);
        root.autoResize = true;
        root.setThemes([am5themes_Animated.new(root)]);

        const chartType = chartConfig.chartType || 'XYChart';
        const limitedData = data.data.slice(0, visibleDataPoints);

        let chart;

        switch (chartType) {
            case 'XYChart':
                chart = createXYChart(root, chartConfig, limitedData);
                break;
            case 'DragOrderingBars':
                chart = createDragOrderingBars(root, chartConfig, limitedData);
                break;
            case 'PieChart':
                chart = createPieChart(root, chartConfig, limitedData);
                break;
            case 'DraggableRange':
                chart = createDraggableRangeChart(root, chartConfig, limitedData, visibleDataPoints);
                break;
            default:
                console.warn(`Tipo de gráfico ${chartType} não é suportado, usando XYChart como fallback.`);
                chart = createXYChart(root, chartConfig, limitedData);
                break;
        }

        root._logo.dispose();
        const showLegend = chartConfig.showLegend !== undefined ? chartConfig.showLegend : false;
        this._createLegend(chart, root, showLegend);
    }

    private _createLegend(chart, root, showLegend: boolean = true) {
        if (showLegend) {
            let legend = chart.children.push(
                am5.Legend.new(root, {
                    centerX: am5.p50,
                    x: am5.p50,
                    layout: root.horizontalLayout,
                    paddingTop: 10,
                    marginTop: 10,
                    y: am5.percent(95),
                    valign: "bottom",
                    width: am5.percent(90),
                    height: am5.percent(10),
                })
            );
            legend.data.setAll(chart.series.values);
        }
    }
}
