import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { User } from '../_models/user';
import moment from 'moment';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class UserService {

    private loggedUser: BehaviorSubject<User> = new BehaviorSubject(undefined);
    private loggedUserObservable = this.loggedUser.pipe(filter((value) => { return value !== undefined }));
    private authenticatedUser: BehaviorSubject<User> = new BehaviorSubject(undefined);
    private authenticatedUserObservable = this.authenticatedUser.pipe(filter((value) => { return value !== undefined }));

    get currentUser$() {
        return this.loggedUserObservable;
    }

    get authenticatedUser$() {
        return this.authenticatedUserObservable;
    }

    get userSessionIdFromLocalstorage(){
        const currentSessionId = window.localStorage.getItem('sessionId');
        return currentSessionId;
    }

    private get _authUserStorage(){
        const currentUser = window.localStorage.getItem('authUserNew');
        return currentUser && JSON.parse(atob(currentUser));
    }

    private get _permUserStorage(){
        const permissions = window.localStorage.getItem('permUser');
        return permissions && JSON.parse(atob(permissions));
    }

    constructor(private _authenticationService: AuthenticationService) {
        if (!this._localStorageHasUserData()) {
            this.clearUser()
        }

        if (this._authenticationService.permissionsUserApi === true || !!this._authenticationService.permissionsUserApi === null) {
            this._authenticationService.permissionsUserApi = false;
            const currentUser = this._authUserStorage;
            const permissionsUser = this._permUserStorage;
            if (currentUser && permissionsUser) {
                this.userSubscribe(currentUser.data, permissionsUser);
                this.setIsLoggedIn(true);
            } else {
                this._authenticationService.logout(true, true);
            }
        }

        this.loggedUser.subscribe((user: any) => {
            if (user) {
                localStorage.setItem('authUserNew', btoa(JSON.stringify(user)));
                this._authenticationService.permissionsUserApi = true;
            }
        });
    }

    getDateToExpire(){
        return moment(new Date()).add(30, 'minutes');
    }

    updateToken(currentUser){
        this.updateDateExpire();
        this._authenticationService.refreshToken(currentUser.refreshToken).then(data => {
            const user = this._authUserStorage;
            user.data.idToken = data?.id_token;
            user.data.refreshToken = data?.refresh_token;
            user.data.expireIn = this.getDateToExpire();
            localStorage.setItem('authUserNew', btoa(JSON.stringify(user)));
            const permissions = this._permUserStorage;
            this.userSubscribe(user.data, permissions);
        });
    }

    updateDateExpire(){
        const user = this._authUserStorage;
        user.data.expireIn = this.getDateToExpire();
        const permissions = this._permUserStorage;
        this.userSubscribe(user.data, permissions);
    }

    retryPermissionsUser(data): Promise<any>{
        return this._authenticationService.getUserPermissions(data.idToken, window.localStorage.getItem('sessionId'), data.email);
    }

    refreshUser() {
        return this._dataOf();
    }

    setIsLoggedIn(isLogged: boolean) {
        localStorage.setItem('isLogged', isLogged.toString());
        this.authenticatedUser$.subscribe((user) => {
            if (this.isLoggedIn() && user) {
                this.loggedUser.next(user);
            }
        });
    }

    isLoggedIn() {
        return localStorage.getItem('isLogged');
    }

    getCurrentUser() {
        return this.authenticatedUser.getValue();
    }

    verifyLinkResetPassword(oobCode: string): Observable<any> {
        return this._authenticationService.validateOobcode(oobCode).pipe();
    }

    confirmPasswordReset(oobCode, newPassword,token): Observable<any> {
        return this._authenticationService.resetPassword(oobCode, newPassword,token).pipe();
    }

    updatePassword(idToken,sessionId,email, newPassword, oldPassword, captchaToken, mobile): Observable<any> {
        return this._authenticationService.updatePassword(idToken,sessionId,email, newPassword, oldPassword, captchaToken, mobile).pipe();
    }

    userSubscribe(userDetails, permissions = null, emitEvent = true){
        this._emitToUser(new User(userDetails, new Set(permissions)));
    }

    clearUser() {
        this._emitToUser(null);
    }

    private _emitToUser(user: User) {
        this.authenticatedUser.next(user);
        this.loggedUser.next(user);
    }

    private _dataOf() {
        return this.loggedUser.subscribe(u => u);
    }

    private _localStorageHasUserData(): boolean {
        return localStorage.getItem('authUserNew') ? true : false;
    }
}
