import { environment } from '#environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MOVEMENT_BY_MODAL_TYPES } from './constants/movement-by-modal-types.constant';

@Injectable()
export class MovementByModalService {

    constructor(private _http: HttpClient) { }

    getLogisticsStates(): Observable<any> {
        return this._http.get(`${environment.endpoints.importationService}/stats/logistics-states`);
    }

    getLogisticsWarehouses(): Observable<any> {
        return this._http.get(`${environment.endpoints.importationService}/stats/logistics-warehouses`);
    }

    getMovementByModal(modalType, request): Observable<any> {
        const type = MOVEMENT_BY_MODAL_TYPES[modalType].paramName;
        const url = modalType === 'Veículos' ? `${environment.endpoints.importationMongoDBService}/chassis/stats/operational/logistics-handling?type=${type}` :
        `${environment.endpoints.importationMongoDBService}/stats/logistics-handling?modalType=${type}`
        return this._http.get(url, request);
    }

    getMovementByStateAndWarehouse(modalType, request): Observable<any> {
        const type = MOVEMENT_BY_MODAL_TYPES[modalType].paramName;
        const url = `${environment.endpoints.importationMongoDBService}/stats/logistics-handling-types?modalType=${type}`;
        return this._http.get(url, request);
    }

}



