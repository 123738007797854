import { IGridColumn } from 'app/shared/components/cmx-custom-grid/interfaces';

export const CHANNEL_AUTOMOTIVE_COLUMNS: IGridColumn[] = [
    {
        field: 'chassisNumber',
        name: 'Chassi',
        width: 120,
        formatterType: 'badge',
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
    },
    {
        field: 'model',
        name: 'Modelo',
    },
    {
        field: 'color',
        name: 'Cor',
        width: 100,
    },
    {
        field: 'clearanceDate',
        name: "Desembaraço DI",
        width: 75,
        formatterType: 'date',
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
    },
    {
        field: 'channel',
        name: 'Canal',
        width: 60,
        formatterType: 'round',
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
    },
];
