import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewEncapsulation, Renderer2 } from '@angular/core';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { UtilsService } from '#services/_utils/utils.service';
import { CustomGridError } from './models/custom-grid-error.model';
import { CheckboxSelection } from './plugins/checkbox-selection/checkbox-selection.model';
import { IGridColumn, IGridRow, IGridStyles } from './interfaces';
import { DEFAULT_OPTIONS } from './constants/default-options.constant';
import { takeUntil } from 'rxjs/operators';
import { CustomGridColumnsService } from './services/custom-grid-column.service';
import moment from 'moment';
import { editorFormatter } from './formatters/pos-formatters/editor.formatter';
import { CustomGridHeaderDropdownService } from './services/custom-grid-header-dropdown';
import { CustomGridHorizontalAnchoringService } from './services/custom-grid-horizontal-anchoring.service';
import { CustomGridDropdownService } from './services/custom-grid-dropdown.service';
import { CustomGridUtilsService } from './services/custom-grid-utils.service';

declare const Slick: any;

@Component({
    selector: 'cmx-custom-grid',
    templateUrl: './custom-grid.component.html',
    styleUrls: ['./custom-grid.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [CustomGridColumnsService, CustomGridDropdownService,
        CustomGridHeaderDropdownService, CustomGridHorizontalAnchoringService]
})
export class CustomGridComponent implements AfterViewInit, OnChanges, OnDestroy {
    @Input() columns: IGridColumn[];
    @Input() rows: IGridRow[] = [];
    @Input() totalRows?: number;
    @Input() loading?: boolean = false;
    @Input() options?: any;
    @Input() styles?: IGridStyles;
    @Input() preSelectedRowsIndexes?
    @Input() collapseActionColumns?: string[] = [];
    @Input() skeletonLoader?: boolean = false;

    @Output() onCallback? = new EventEmitter<any>();
    @Output() onRowClick? = new EventEmitter<any>();
    @Output() onCellClick? = new EventEmitter<any>();
    @Output() onSelectedRows? = new EventEmitter<any>();
    @Output() onSelectedAllRows? = new EventEmitter<any>();
    @Output() onPopoverClick? = new EventEmitter<any>();
    @Output() onPopoverHover? = new EventEmitter<any>();
    @Output() onPopoverMouseLeave? = new EventEmitter<any>();
    @Output() onSortColumn? = new EventEmitter<any>();
    @Output() onFilterColumn? = new EventEmitter<any>();
    @Output() onExchangeColumn? = new EventEmitter<any>();
    @Output() onCustomIconClick? = new EventEmitter<any>();
    @Output() onCollapseAllClick? = new EventEmitter<any>();
    @Output() onScrollTheEnd? = new EventEmitter<any>();
    @Output() onCellEdited? = new EventEmitter<any>();
    @Output() onDropdownApply? = new EventEmitter<any>();
    @Output() onCellDropdownSelect? = new EventEmitter<any>();
    @Output() onSwitch? = new EventEmitter<any>();

    gridId: string;

    private _rawColumnData;
    private _rawRowData;
    private _grid: any;
    private _options: any;
    private _checkboxSelection: CheckboxSelection;
    private _unsubscribeAll: Subject<any>;
    private _pluginsInstantiated: boolean;
    private _allCollapsedClosed: boolean;
    private _storeRowsLoaded: number = 0;
    private _dataScrollGrid = null;
    private _enableCellEditor: boolean = false;
    private _viewport: string = '.slick-viewport-left';
    private _currentGridLeftPosition = 1;
    private _visualFilters = [];

    loaderInfiniteScroll: boolean = false;

    constructor(
        private _utilsService: UtilsService,
        private _customGridUtilsService: CustomGridUtilsService,
        private _customGridColumnService: CustomGridColumnsService,
        private _customGridDropdownService: CustomGridDropdownService,
        private _customGridHeaderDropdownService: CustomGridHeaderDropdownService,
        private _customGridHorizontalAnchoringService: CustomGridHorizontalAnchoringService,
        private _renderer2: Renderer2
    ) {
        this.gridId = this._utilsService.generateGUID('grid');
        this._unsubscribeAll = new Subject();
        this._customGridHeaderDropdownService.dropdownApplySubject.subscribe(({ column, dropdownSelection }) => {
            this._customGridColumnService.resetColumnFilters(column);
            this.onDropdownApply.emit({ column, dropdownSelection });
        });
        this._customGridDropdownService.dropdownSelectSubject.subscribe((selection) => {
            this._customGridDropdownService.apllyDropdownValue(this._rawRowData, this.rows, selection);
            this.onCellDropdownSelect.emit(selection);
        });
    }

    ngAfterViewInit(): void {
        this._viewport = this.options?.hasOwnProperty('frozenColumn') ? '.slick-viewport-right' : '.slick-viewport-left'
        setTimeout(() => {
            this._validate();
            this._createGrid();
            this._checkboxSelection && this._checkboxSelection.selectedRowsChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe((selectedObject: any) => {
                this.onSelectedRows.emit({ selectedRows: selectedObject.selectedRows, lastRowSelected: selectedObject.lastRowSelected });
                this._grid.invalidate();
            });
            this._checkboxSelection && this._checkboxSelection.selectedAllRowsChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe((response: any) => {
                this.onSelectedAllRows.emit({ allSelected: response.allSelected, visibleRows:  response.visibleRows });
                this._grid.invalidate();
            });
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes?.styles && !changes.styles.firstChange) {
            this._renderer2.setStyle(document.querySelector(`#${this.gridId}`), 'height', changes.styles.currentValue.height);
        }
        if (changes?.columns && !changes.columns.firstChange) {
            this._updateCheckboxSelection();
            this._createGrid();
        }
        if (changes?.rows && !changes.rows.firstChange) {
            this._createGrid();
        }
        if (changes?.options && !changes.options.firstChange) {
            this._updateCheckboxSelection();
            this._createGrid();
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();

        if (this._checkboxSelection) {
            this._checkboxSelection.destroy();
        }
    }

    private _validate(): void {
        if (!this.columns || !this.rows) {
            throw new CustomGridError('Grid must have [columns] and [dataField] to render!');
        }
    }

    private isLastRowInViewport(el:HTMLElement) {
        const viewportGrid = document.querySelector('.slick-viewport').getBoundingClientRect();
        const rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.y <= viewportGrid.height + viewportGrid.y
        );
    }

    private _createGrid(): void {
        const dataView = new Slick.Data.DataView({ inlineFilters: false });
        let timeoutDetect = null;
        this._options = {
            ...DEFAULT_OPTIONS,
            ...(this.options || { }),
        };
        (this.rows).forEach((row: IGridRow, index: number) => {
            row.id = _.isNil(row.id) ? this._utilsService.generateGUID(index.toString()) : row.id;
            const idAsString = row.id.toString();
            const concatenatedId = `1${idAsString.replace('-', '')}`;
            row.childIndex = parseInt(concatenatedId, 10);
            row.rawIndex = index;
        });
        if (!this._pluginsInstantiated) this._instantiateCustomPlugins();
        this._rawColumnData = this.columns;
        this._rawRowData = _.cloneDeep(this.rows);
        this.columns = this._customGridColumnService.createColumns(this.columns);
        // Cria instância de lista de linhas selecionandas e visiveis, ou reseta os valores caso já exista.
        if (!(this._customGridUtilsService.getCurrentGridByGridId(this.gridId))) {
            this._customGridUtilsService.gridList.push({ gridId: this.gridId, selectedRows: [], visibleRows: dataView.getFilteredItems() });
        } else {
            if (!this.preSelectedRowsIndexes || !this.preSelectedRowsIndexes?.length) {
                this._customGridUtilsService.getCurrentGridByGridId(this.gridId).selectedRows = [];
            } else if (this.preSelectedRowsIndexes?.length) {
                this._customGridUtilsService.getCurrentGridByGridId(this.gridId).selectedRows = this.rows.filter((_row, index) => this.preSelectedRowsIndexes.includes(index));
            }
            this._customGridUtilsService.getCurrentGridByGridId(this.gridId).visibleRows = dataView.getFilteredItems();
        }
        this._customGridColumnService.defineColumnAutoWidth(this.columns, this.rows);
        dataView.beginUpdate();
        dataView.setItems(this.rows);
        dataView.setFilter(this._filter.bind(this));
        dataView.endUpdate();
        this._setClassRow(dataView);

        this._grid = new Slick.Grid(`#${this.gridId}`, dataView, this.columns, this._options);

        this._defineGrouping();
        this._verifyPopoverHover();

        this._grid.onCellChange.subscribe((e, args) => {
            dataView.updateItem(args.item.id, args.item);
        });

        dataView.onRowCountChanged.subscribe((e, args) => {
            this._grid.updateRowCount();
            this._grid.render();
        });

        dataView.onRowsChanged.subscribe((e, args) => {
            this._grid.invalidateRows(args.rows);
            this._grid.render();
        });

        this._options.infiniteScroll && this._grid.onScroll.subscribe((e, args) => {
            const el = args.grid.getCanvasNode();
            let lastRowElement: HTMLElement = el.childNodes[el.childNodes.length - 1];
            if (lastRowElement && !this.loaderInfiniteScroll) {
                const getIndexLastRow:number = parseInt(lastRowElement.getAttribute('index-row')) + 1;

                if (timeoutDetect) {
                    clearTimeout(timeoutDetect)
                }

                if (this._storeRowsLoaded === 0 && args.grid.getDataLength() < this._options.tryLoadResults) {
                    return;
                }

                timeoutDetect = setTimeout(() => {
                    if (this.isLastRowInViewport(lastRowElement) && args.grid.getDataLength() === getIndexLastRow && this._storeRowsLoaded !== args.grid.getDataLength()){
                        if (args.grid.getDataLength() < (this._storeRowsLoaded + this._options.tryLoadResults)) {
                            return;
                        }
                        lastRowElement = null;
                        this.loaderInfiniteScroll = true;
                        this._dataScrollGrid = {
                            lastPositionViewPort: document.querySelector('.slick-viewport').scrollTop,
                            rows: this.rows,
                            totalLoaded: args.grid.getDataLength(),
                            tryLoadResults: this._options.tryLoadResults
                        };
                        this._storeRowsLoaded = args.grid.getDataLength();
                        this.onScrollTheEnd.emit(this._dataScrollGrid);
                    }
                },100);
            }
        })

        this._grid.onClick.subscribe((e, args) => {
            if (this._enableCellEditor) { return }
            const element = $(e.target);
            const item = dataView.getItem(args.row);
            const column = this._grid.getColumns()[this._grid.getCellFromEvent(e).cell].field;
            const formatterData = this._grid.getColumns()[this._grid.getCellFromEvent(e).cell].formatterData;
            const formatterType = this._grid.getColumns()[this._grid.getCellFromEvent(e).cell].formatterType;
            if ((element.hasClass('toggle-area') && !item.denyCollapse) ||
            (element.hasClass('slick-cell') && $(e.target.firstElementChild).hasClass('toggle-area') && !item.denyCollapse) ||
            (this.collapseActionColumns.includes(column) && !item.denyCollapse)) {
                if (!item.isLeaf || item.multi) {
                    item._collapsed = !item._collapsed;
                    this._defineAllCollapse();
                    this._changeCollapseAllButton();
                    this.onCellClick.emit({ item, column, multiButton: null, element, event: this.collapseActionColumns.includes(column) ? 'collapse' : 'row' });
                    dataView.updateItem(item.id, item);
                    this. _updateSelectionIndexesAfterFilter(dataView);
                }
                e.stopImmediatePropagation();
            } else if (item.hasOwnProperty(`${column}_popover`) && item[`${column}_popover`]) {
                this.onPopoverClick.emit({data: item[`${column}_popover`], e, item, column});
            } else if (element.hasClass('grid-custom-icon') && element.hasClass('clickable')) {
                this.onCustomIconClick.emit({ item, column });
            } else if (element.hasClass('switch-formatter-container') && (!element.hasClass('disabled'))) {
                const switchContainer = element.children();
                const switchValue = switchContainer.hasClass('on') ? 'off' : 'on';
                switchContainer.removeClass('on');
                switchContainer.removeClass('off');
                switchContainer.removeClass('neutral');
                if (element.hasClass('neutral-type')) {
                    const width = switchContainer.width();
                    const clickPosition = e.clientX - switchContainer.offset().left;
                    let neutralSwitchValue;
                    let classValue;
                    if (clickPosition < width / 3) {
                        classValue = 'off';
                        neutralSwitchValue = formatterData?.leftValue;
                    } else if (clickPosition > (width * 2) / 3) {
                        classValue = 'on';
                        neutralSwitchValue = formatterData?.rightValue;
                    } else {
                        classValue = 'neutral';
                        neutralSwitchValue = 'neutral';
                    }
                    switchContainer.addClass(classValue);
                    this.onSwitch.emit({ item, column, rowIndex: args.row, value: neutralSwitchValue, neutral: true, element });
                } else {
                    switchContainer.addClass(switchValue);
                    this.onSwitch.emit({ item, column, rowIndex: args.row, value: switchValue, neutral: false, element });
                }
            } else if (element.hasClass('dropdown-formatter-content')) {
                this._customGridDropdownService.openDropdown(element, column, args.row, item)
            } else {
                this.onRowClick.emit(item);
                this.onCellClick.emit({ item, column, multiButton: formatterType === 'multiButtons' ? element.attr('class').split(' ')[0] : null, element});
            }
        });
        this._grid.onDblClick.subscribe((e, args) => {
            const element = e.target;
            const oldValue = _.cloneDeep(this.rows[args.row]);
            const item = dataView.getItem(args.row);
            const column = this._grid.getColumns()[this._grid.getCellFromEvent(e).cell].field;
            const formatterData = this._grid.getColumns()[this._grid.getCellFromEvent(e).cell].formatterData;
            const idxByIdItem = dataView.getItems()[args.row].id;
            if (formatterData && 'editable' in formatterData && !this._enableCellEditor) {
                this._enableCellEditor = true;
                const data = {
                    elementProperties: {
                        element: e.target,
                        position: {
                            row: args.row,
                            cell: args.cell
                        }
                    },
                    item: {
                        columnDef: {
                            columnName: column,
                            editable: formatterData.editable
                        },
                        dataContext: item,
                    }
                }
                editorFormatter(data);
                element.addEventListener('onSlickEditorCell', (event) => {
                    dataView.updateItem(idxByIdItem, event.detail.output);
                    this._enableCellEditor = false;
                    this.onCellEdited.emit({
                        field: column,
                        oldValue: oldValue,
                        newValue: event.detail.output
                    });
                    element.removeEventListener('onSlickEditorCell', null, false);
                });
            }
        });
        this._grid.onHeaderClick.subscribe((e, args) => {
            const columnIndex = this.columns.findIndex(c => { return c.field === args.column.field });
            if (args.column.isExchangeable && $(e.target).hasClass('exchange')) {
                this._exchangeColumn(args.column.field, args.column.secoundaryColumn);
                this.onExchangeColumn.emit();
            }
            if (args.column.isSortable && $(e.target).hasClass('sortColumn')) {
                this._currentGridLeftPosition = $(`#${this.gridId} ${this._viewport}`).scrollLeft();
                this.columns?.map((column, index) => { column.sort = index === columnIndex ? column.sort : 'default' });
                this.columns[columnIndex].sort = this.columns[columnIndex].sort === 'asc' ? 'desc' : 'asc';
                this.columns = this._customGridColumnService.createColumns(this.columns)?.map(this._customGridColumnService.resetColumnFilters);
                this._grid.setColumns(this.columns)
                if (this.columns[columnIndex].webSortable) {
                    dataView.fastSort(this.columns[columnIndex].field, this.columns[columnIndex].sort === 'asc')
                } else {
                    this.onSortColumn.emit({
                        field: this.columns[columnIndex]?.field,
                        path: this.columns[columnIndex]?.path,
                        sort: this.columns[columnIndex]?.sort
                    });

                }
            }
            if ((args.column.isFilterable || args.column.isDropdownFilterable) && ($(e.target).hasClass('search') || $(e.target).hasClass('dropdown-length'))) {
                this._currentGridLeftPosition = $(`#${this.gridId} ${this._viewport}`).scrollLeft();
                if (!this.options.multiFilter) {
                    this.columns = this.columns?.map(this._customGridColumnService.resetColumnFilters);
                }
                this.columns[columnIndex].filtering = true;
                this.columns = this._customGridColumnService.createColumns(this.columns);
                this._grid.setColumns(this.columns)
                if (this.options.multiFilter) { this._searchFilterColumn(dataView) }
                this._defineFilter(this.columns[columnIndex], dataView);
            }
            if ($(e.target).hasClass('cancelFilter')) {
                this._currentGridLeftPosition = $(`#${this.gridId} ${this._viewport}`).scrollLeft();
                this.columns[columnIndex] = this._customGridColumnService.resetColumnFilters(this.columns[columnIndex]);
                if (this.options.multiFilter) {
                    this.columns = this._customGridColumnService.createColumns(this.columns);
                } else {
                    this.columns = this._customGridColumnService.createColumns(this.columns)?.map(this._customGridColumnService.resetColumnFilters);
                }
                const columnsField = this.columns[columnIndex].field;
                this.onFilterColumn.emit({
                    field: columnsField,
                    path: this.columns[columnIndex]?.path,
                    value: null,
                    type: null,
                });
                this._grid.setColumns(this.columns);
                if (this.options.multiFilter) { this._searchFilterColumn(dataView) }
                // Remove filtro visual da lista de filtros visuais
                if (this.columns[columnIndex].webFilter) {
                    this._visualFilters = this._visualFilters.filter(filter => { return filter.column !== columnsField });
                    this._visualPreFilter();
                    setTimeout(() => {
                        dataView.setFilter(this._filter.bind(this))
                        this._updateSelectionIndexesAfterFilter(dataView);
                    }, 100);
                }
            }
            if ($(e.target).hasClass('switch-handle')) {
                $(e.target)?.offsetParent()?.removeClass('neutral');
                $(e.target)?.offsetParent()?.removeClass('on');
                if ($(e.target).hasClass('right-switch-handle')) {
                    $(e.target)?.offsetParent()?.addClass('on');
                }
                this.onFilterColumn.emit({
                    field: this.columns[columnIndex].field,
                    path: this.columns[columnIndex]?.path,
                    value: $(e.target).attr('id'),
                    type: 'boolean',
                });
            }
        });
        this._grid.onScroll.subscribe((e, args) => {
            this._verifyPopoverHover();
            this._customGridDropdownService.closeDropdown();
        });
        this._initCustomPlugins();
        this._setCustomOptions();
        this._searchFilterColumn(dataView);
        this._searchEditorCell();
        if (this._options.meta['checkboxSelection']) {
            if (this._options.meta['checkboxSelection']['enabled']) {
                if (this.preSelectedRowsIndexes) {
                    this._grid.setSelectedRows(this.preSelectedRowsIndexes)
                }
                this._customGridUtilsService.getCurrentGridByGridId(this.gridId).visibleRows = dataView.getFilteredItems();
                this._updateCheckboxSelection();
            }
        }
        this._verifyEmptyData();
        this._setTotalRows();
        this._setCollapseButton(dataView);
        this.onCallback.emit({ grid: this._grid, dataView });
        this._setPositionTopViewport();
        setTimeout(() => {
            this._movementGridPosition();
        });
    }

    private _movementGridPosition() {
        // this._currentGridLeftPosition = 1; Adicionar para manter posição ao Filtrar / Ordenar
        $(`#${this.gridId} ${this._viewport}`).animate({ scrollLeft: this._currentGridLeftPosition || 1 }, 0 );
    }

    private _setPositionTopViewport(){
        if(this._dataScrollGrid && this._options.infiniteScroll) {
            document.querySelector('.slick-viewport').scroll(0, this._dataScrollGrid.lastPositionViewPort);
            setTimeout(() => {
                this.loaderInfiniteScroll = false;
            }, 300);
        }
    }

    private _verifyEmptyData() {
        if (!this.rows.length && !this.loading && this._options.useEmptyDataMessage) {
            const element = this._options.showEmptyDataHeader ? $(`#${this.gridId} .slick-pane-top`) : $(`#${this.gridId}.custom-grid`);
            this._grid.invalidateAllRows();
            element?.html(`<div class="empty-grid-data">${this._options.emptyDataMessage || 'Nenhum resultado encontrado'}</div>`);
        }
        if (!this.rows.length && this.loading) {
            this._grid.invalidateAllRows();
        }
    }

    private _setTotalRows() {
        if (this.totalRows) {
            $(`#${this.gridId}`).append(`<div class="total-grid-rows">Total de registros: ${this.totalRows}</div>`);
        }
    }

    private _setCollapseButton(dataView) {
        if (this._options.collapseAllButton) {
            this._defineAllCollapse();
            const hasAnchorContainer = document.querySelector('.cmx-grid-horizontal-anchor-container') !== null;
            const topStyle = this.options && this.options.horizontalAnchoring && hasAnchorContainer ? '-50px !important' : '';
            $(`#${this.gridId}`).append(`<div class="custom-grid-collapse-button" style="top: ${topStyle};">
                <span class="toggle fa fa-caret-${
                    this._allCollapsedClosed ? 'down' : 'right'} pointer"></span>
                    ${this._allCollapsedClosed ? 'abrir' : 'fechar'} todos
            </div>`);
        }
        $(`#${this.gridId} .custom-grid-collapse-button`).off("click").on("click", () => {
            this._allCollapsedClosed = !this._allCollapsedClosed;
            this._changeCollapseAllButton();
            this.onCollapseAllClick.emit(this._allCollapsedClosed);
            this._updateSelectionIndexesAfterFilter(dataView);
        });
    }

    private _defineAllCollapse() {
        this._allCollapsedClosed = true;
        this.rows.forEach((row) => {
            if (!row.isLeaf && !row._collapsed) { this._allCollapsedClosed = false }
        })
    }

    private _changeCollapseAllButton() {
        $(`#${this.gridId} .custom-grid-collapse-button`)
                .html(`<span class="toggle fa fa-caret-${this._allCollapsedClosed ? 'down' : 'right'
                } pointer"></span>${this._allCollapsedClosed ? 'abrir' : 'fechar'} todos`);
    }

    private _setClassRow(dataView){
        dataView.getItemMetadata = (index) => {
            const item = dataView.getItem(index);
            if (item.rowClasses) { return { cssClasses: item.rowClasses } };
        };
    }

    private _searchFilterColumn(dataView) {
        this.columns.forEach((column) => {
            if (column.filtering) {
                this._defineFilter(column, dataView);
            }
        })
    }

    private _searchEditorCell() {
        this.columns.forEach((column) => {
            if (column.formatterData && 'editable' in column.formatterData) {
                this._applyIconToColumn(column, 'fa-pencil');
            }
        })
    }

    private _defineFilter(column, dataView) {
        if (column.filterSelectionMode === 'range') {
            this._defineRangeFilter(column);
        } else {
            $(`#${column.field}-input`).on('keyup', (event) => {
                column.filter = $(`#${column.field}-input`).val();
                if (event.key === "Enter") {
                    this._emitFilterColumn(column, column.filterType == 'date' ? moment(column.filter, 'DD/MM/YYYY').format('YYYY-MM-DD') : column.filter, true);
                } else if (this.options.multiFilter && !column.webFilter) {
                    this._emitFilterColumn(column, column.filterType == 'date' ? moment(column.filter, 'DD/MM/YYYY').format('YYYY-MM-DD') : column.filter, false);
                } else if (column.webFilter) {
                    this._visualfilterGridRows(column.field, column.filter, dataView, column.filterType);
                }
            });
            $(`#${column.field}-input`).focus();
        }
    }

    // Inclui ou atualiza o filtro na lista de filtros visuais
    private _visualfilterGridRows(column: string, filter: string, dataView, filterType: string) {
        const filterFound = this._visualFilters?.find(filter => filter.column === column)
        filterFound ? filterFound.filter = filter : this._visualFilters?.push({ column, filter, filterType });
        this._visualPreFilter();
        setTimeout(() => {
            dataView.setFilter(this._filter.bind(this));
            this._updateSelectionIndexesAfterFilter(dataView);
        }, 100);
    }

    private _defineRangeFilter(column) {
        const handleKeyup = (inputId: string, index: number) => {
            $(`#${inputId}`).on('keyup', (event) => {
                const value = $(`#${inputId}`).val();
                try {
                    column.filter[index] = value;
                    if (event.key === "Enter") {
                        const formattedFilter = this._formatDateRangeFilter(column);
                        this._emitFilterColumn(column, formattedFilter);
                    }
                } catch (error) {
                    $(`#${inputId}`).val('');
                    column.filter[index] = null;
                }
            });
        };
        handleKeyup(`${column.field}-input-start`, 0);
        handleKeyup(`${column.field}-input-end`, 1  );
        $(`#${column.field}-input-start`).focus();
    }

    private _formatDateRangeFilter(column) {
        return column.filter.map((filter) => {
            if (column.filterType == 'date') {
                const momentDate = moment(filter, 'DD/MM/YYYY');
                if (momentDate.isValid()) {
                    return momentDate.format('YYYY-MM-DD');
                } else {
                    return null;
                }
            } else {
                return filter;
            }
        });
    }

    private _emitFilterColumn(column, value, keypressEnter?: boolean) {
        this.onFilterColumn.emit({
            field: column.field,
            path: column?.path,
            value: value,
            type: column.filterType ? column.filterType : null,
            filterSelectionMode: column.filterSelectionMode ? column.filterSelectionMode : null,
            keypressEnter: keypressEnter
        });
    }

    private _applyIconToColumn(column, typeIcon) {
        $(`#${column.field}`).append(`<i class="icon-label-column fa ${typeIcon}"></i>`)
    }

    private _exchangeColumn(oldField, newColumn) {
        const index = this.columns.findIndex(c => c.field === oldField);
        this._rawColumnData[index] = newColumn;
        this._rawColumnData[index].name = newColumn.changeName;
        this._rawColumnData[index].secoundaryColumn = this.columns[index]
        this.columns = this._rawColumnData;
        this.columns = this._customGridColumnService.createColumns(this.columns);
        this._grid.setColumns(this.columns)
    }

    // Pré define as linhas como filtradas, de acordo com os filtros visuais
    private _visualPreFilter() {
        this.rows.forEach((row) => {
            if (!this._visualFilters.length) { row.visualFiltered = false }
            this._visualFilters?.forEach((filter, index) => {
                if (index === 0) { row.visualFiltered = false; }
                let itemValue = row[filter?.column]?.toString()?.toLowerCase();
                let filterValue = filter?.filter?.toString()?.toLowerCase();
                if (filter?.filterType === 'currency') {
                    filterValue = filterValue.replaceAll('.', '').replaceAll(',', '.');
                    filterValue = filterValue === '0.00' ? '0' : filterValue;
                    filterValue = parseFloat(filterValue).toString();
                    if (filterValue.indexOf('.') !== -1) {
                        filterValue = filterValue.replace(/(\.0+|(?<=\.\d)0+)$/, '');
                    }
                }
                if (!(itemValue?.includes(filterValue))) {
                    row.visualFiltered = true;
                }
            })
        });
    }

    private _filter(item: any): boolean {
        if (!_.isNil(item.parent)) {
            let parent = this.rows[item.parent];
            while (parent) {
                if (parent._collapsed) {
                    return false;
                }
                parent = this.rows[parent.parent];
            }
        }
        // Aplica o filtro visual já pré definido
        return !item.visualFiltered
    }

    private _setCustomOptions(): void {
        if (this._options.rowHover) {
            $(this._grid.getContainerNode()).addClass('row-hover');
        }
    }

    private _defineGrouping() {
        if (this._options.useCategoryGrouping) {
            const plugin = new Slick.ColumnGroup();
            this._grid.registerPlugin(plugin);
            this._grid.render();
            plugin.enableColumnGrouping(this._options.frozenColumn);
            if (this.options.horizontalAnchoring) {
                this._customGridHorizontalAnchoringService.defineAnchor(this._rawColumnData, this.gridId, this._options.frozenColumn);
            }
        }
    }

    private _verifyPopoverHover() {
        $('.popover-hover').hover((element) => {
            const cell = $(element.target).closest('.slick-cell');
            const row = $(element.target).closest('.slick-row');
            const rowIndex = row.attr('index-row');
            const item = this.rows[rowIndex];
            this.onPopoverHover.emit({data: item.popoverData || '', element, cellElement: cell, row: item});
            $('.cmx-slick-popover').removeClass('hidden-popover').addClass('visible-popover');
            $(cell).mouseleave((e) => {
                const popover = $('.cmx-slick-popover');
                const relatedTarget = e.relatedTarget as HTMLElement;
                if (relatedTarget && !popover.is(relatedTarget) && !popover.has(relatedTarget).length) {
                    popover.addClass('hidden-popover').removeClass('visible-popover');
                    $(cell).off('mouseleave');
                    this.onPopoverMouseLeave.emit();
                }
            });
        });
    }

    private _instantiateCustomPlugins(): void {
        this._pluginsInstantiated = true;
        for (const plugin of Object.keys(this._options.meta)) {
            // In the future, if there are others, it may be interesting to switch to SWITCH logic
            if (plugin === 'checkboxSelection' && this._options.meta[plugin].enabled) {
                if (this.columns?.length && this.columns[0]?.field === 'checkboxSelection') {
                    this.columns.shift();
                }
                const options = this._options.meta['checkboxSelection']['options'];
                const selectionOptions = this._options.meta['checkboxSelection']['selectionOptions'];
                const selectionOptionFieldPath = this._options.meta['checkboxSelection']['selectionOptionFieldPath'];
                const disableSelectCollapsed = this._options.meta['checkboxSelection']['disableSelectCollapsed'];
                const singleSelection = this._options.meta['checkboxSelection']['singleSelection'];
                const childSpecificCheckbox  = this._options.meta['checkboxSelection']['childSpecificCheckbox'];
                const onlyLeafSelectable  = this._options.meta['checkboxSelection']['onlyLeafSelectable'];
                const selectDownParents  = this._options.meta['checkboxSelection']['selectDownParents'];
                this._checkboxSelection = new CheckboxSelection(this._customGridUtilsService, options, selectionOptions, selectionOptionFieldPath, disableSelectCollapsed,
                    singleSelection, childSpecificCheckbox, onlyLeafSelectable, selectDownParents, this.gridId);
                this.columns.unshift(this._checkboxSelection.getColumnDefinition());
            }
        }
    }

    // Realoca indices selecionados ao filtrar
    private _updateSelectionIndexesAfterFilter(dataView) {
        // reseta as linhas selecionadas
        this._grid.setSelectedRows([]);
        let newSelectedRows = [];
        // coleta as linhas atuais sendo mostradas em tela (filtradas)
        const filteredRows = dataView.getFilteredItems();
        // aplica o indice momentâneo à lista de indices novos, caso a linha filtrada possua o mesmo id base da linha selecionada
        const currentGridHistoric = this._customGridUtilsService.getCurrentGridByGridId(this.gridId);
        filteredRows?.forEach((filteredRow, index) => {
            currentGridHistoric?.selectedRows?.forEach((selectedRow) => {
                if (filteredRow.rawIndex === selectedRow.rawIndex) {
                    newSelectedRows.push(index)
                }
            })
        })
        this._grid.setSelectedRows(newSelectedRows);
        this._customGridUtilsService.getCurrentGridByGridId(this.gridId).visibleRows = dataView.getFilteredItems();
    }

    private _updateCheckboxSelection() {
        if (this._checkboxSelection) {
            this._checkboxSelection.updateSelectionOptions(this._options.meta['checkboxSelection']['options']);
            if (this.columns?.length && this.columns[0]?.field === 'checkboxSelection') {
                this.columns.shift();
            }
            this.columns.unshift(this._checkboxSelection.getColumnDefinition());
        }
    }

    private _initCustomPlugins(): void {
        for (const plugin of Object.keys(this._options.meta)) {
            // In the future, if there are others, it may be interesting to switch to SWITCH logic
            if (plugin === 'checkboxSelection' && this._options.meta[plugin].enabled) {
                this._grid.setSelectionModel(new Slick.RowSelectionModel({ selectActiveRow: false }));
                this._checkboxSelection.init(this._grid);
            }
        }
    }

    public updateRows(updatedRows: any[]) {
        this._grid.invalidateRows(updatedRows);
        this._grid.render();
    }
}
