import { IGridColumn } from "app/shared/components/cmx-custom-grid/interfaces";

export const DATE_NOTIFICATION_COLUMNS: IGridColumn[] = [{
        field: 'label',
        name: 'page::config::notifications.gridDates::date',
        width: 400,
        isFilterable: true,
        webFilter: true,
    }, {
        field: 'add',
        name: 'page::config::notifications.gridDates::inclusion',
        width: 90,
        formatterType: 'switch',
        headerCssClass: 'grid-header align-center',
    }, {
        field: 'update',
        name: 'page::config::notifications.gridDates::modification',
        width: 90,
        formatterType: 'switch',
        headerCssClass: 'grid-header align-center',
    }, {
        field: 'frequency',
        name: 'page::config::notifications.gridDates::periodicity',
        width: 200,
        formatterType: 'dropdown',
    }
]
