import { IGridColumn } from "app/shared/components/cmx-custom-grid/interfaces";

export const CUSTOM_WIDGETS_DATA_COLUMNS: IGridColumn[] = [{
    field: 'dataName',
    name: 'Nome',
    width: 120
}, {
    field: 'visualDataType',
    name: 'Tipo',
    width: 100
}, {
    field: 'dataField',
    name: 'Campo',
    width: 400
}, {
    field: 'referenceLine',
    name: 'Linha Ref?',
    width: 100
}, {
    field: 'dataColor',
    name: 'Cor',
    width: 45,
    headerCssClass: 'grid-header align-center',
    cssClass: 'grid-cell align-center',
    formatterType: 'round',
    formatterData: { emptyBall: true }
}, {
    field: 'delete', 
    name: "<span class='material-icons' style='font-size:18px'>delete</span>",
    width: 45,
    formatterType: 'round',
    formatterData: {
        roundType: 'delete',
    },
    headerCssClass: 'grid-header align-center',
    cssClass: 'grid-cell align-center',
}]