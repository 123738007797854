import { Injectable } from '@angular/core';

@Injectable()
export class LoginService {
    urlLocal = window.location.href;
    logoLogin = this.getClient();
    backLogin = this.getBackground();

    getRandomBackgroundBI(){
        const arrayBackground = [
            'wallpaper-bi-1',
            'wallpaper-bi-2',
            'wallpaper-bi-3',
            'wallpaper-bi-4',
            'wallpaper-bi-5',
            'wallpaper-bi-6'
        ];
        const numberWallpaper = Math.floor(Math.random()*arrayBackground.length);
        return arrayBackground[numberWallpaper];
    }

    get pathLogosBase(){
        return 'assets/img/logos/';
    }

    getClient() {
        const url = this.urlLocal.replace('//', '.');
        const urlSplited = url.split('.')[1];
        if (urlSplited.includes('localhost')) {
            return 'bi';
        } else {
            return urlSplited;
        }
    }

    getLogo(darkTheme = false) {
        const client = this.getClient();
        if (client === 'bi' || client === 'bi-stage' || client === 'cms') {
            return this.getLogoComexportTheme(darkTheme);
        }
        return `${this.pathLogosBase}logo-${this.getClient()}.png`;
    }

    getLogoComexportTheme(darkTheme) {
        return darkTheme ? `${this.pathLogosBase}comexport-dark.svg` : `${this.pathLogosBase}comexport.svg`
    }

    getBackground() {
        const client = this.getClient();
        if (client === 'bi' || client === 'bi-stage') {
            const background = this.getRandomBackgroundBI();
            const backBase = 'assets/img/bg/';
            const backLogin = `url(${backBase}${background}.jpg)`;
            return backLogin;
        } else {
            const backBase = 'assets/img/bg/';
            const backLogin = `url(${backBase}bg-${client}.jpg)`;
            return backLogin;
        }
    }
}
