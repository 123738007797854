import { PageTabControlService } from '#services/_page-tab-control/page-tab-control.service';
import { Component, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DocumentsGedCRUDComponent } from './clients/documents-ged.component';
import { NotificationsCRUDComponent } from './notifications/notifications.component';
import { PreferencesCRUDComponent } from './preferences/preferences.component';
import { DateNotificationCRUDComponent } from './notifications/date-notification/date-notification.component';
import { DataAndScreenCRUDComponent } from './clients/data-screen/data-screen.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'configurations',
    templateUrl: './configurations.component.html',
    styleUrls: ['./configurations.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ConfigurationsComponent {

    @ViewChild('bodyContent', { read: ViewContainerRef }) _bodyContent: ViewContainerRef;

    private _translationKeys = {
        // Title
        configurations: 'menu::user.title::config',
        // Tabs
        notifications: 'configuration.tab::notifications',
        clients: 'configuration.tab::clients',
        preferences: 'configuration.tab::preferences',
        // Switch
        documents: 'page::config::notifications.documents',
        status: 'page::config::notifications.status',
        datas: 'page::config::notifications.dates',
        data: 'page::config::notifications.data',
    };

    pageConfig = {
        title: this._translateService.instant(this._translationKeys.configurations),
        url: 'configurations',
        urlParams: this._route.snapshot.params,
        tabs: [
            {
                code: 'notifications', title: this._translateService.instant(this._translationKeys.notifications), component: NotificationsCRUDComponent,
                switch: [
                    { code: 'status', name: this._translateService.instant(this._translationKeys.status), component: NotificationsCRUDComponent },
                    { code: 'datas', name: this._translateService.instant(this._translationKeys.datas), component: DateNotificationCRUDComponent }
                ]
            },
            {
                code: 'clients', title: this._translateService.instant(this._translationKeys.clients),
                switch: [
                    { code: 'documents', name: this._translateService.instant(this._translationKeys.documents), component: DocumentsGedCRUDComponent, permission: 'GED_DOC_CONFIGURATION', },
                    { code: 'data', name: this._translateService.instant(this._translationKeys.data), component: DataAndScreenCRUDComponent, permission: 'DATA_AND_SCREENS_CONFIGURATION', }
                ]
            },
            {
                code: 'preferences', title: this._translateService.instant(this._translationKeys.preferences), component: PreferencesCRUDComponent, permission: 'ROUTE_USER_PREFERENCES_VIEWS'
            }
        ]
    };

    constructor(
        public pageTabControlService: PageTabControlService,
        private _route: ActivatedRoute,
        private _translateService: TranslateService,
    ) { }

    ngAfterViewInit() {
        setTimeout(() => {
            this.pageTabControlService.setPageConfigs(this.pageConfig, this._bodyContent, this._translationKeys);
        }, 0);
    }

    get helpDescription() {
        return this.pageTabControlService.currentTabSelected?.title === 'Notificações' ?
            [{ type: 'text', value: "" }] : []
    }
}
