import { NotificationService } from '#services/_notification/notification.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, Output, ViewEncapsulation, EventEmitter, SimpleChanges } from '@angular/core';
import { TypeButton } from '../cmx-button/button.component';

type fadeType = 'default' | 'top' | 'right';
@Component({
    selector: 'cmx-confirmation-modal',
    templateUrl: './cmx-confirmation-modal.component.html',
    styleUrls: ['./cmx-confirmation-modal.component.scss'],
    animations: [
        trigger('openClose', [
            state('true', style({ transform: 'translateY(-50vh)', opacity: 0, pointerEvents: 'none' })),
            state('false', style({ transform: 'translateY(0vh)', opacity: 1, pointerEvents: 'all' })),
            transition('false <=> true', animate('.5s ease-in-out')),
        ]),
        trigger('opacityOverlay', [
            state('true', style({ opacity: 0, pointerEvents: 'none' })),
            state('false', style({ opacity: 1, pointerEvents: 'all' })),
            transition('false <=> true', animate('.3s ease-in-out')),
        ]),
    ],
    encapsulation: ViewEncapsulation.None,
})
export class ConfirmationModalComponent {
    @Input() question: string = 'Deseja prosseguir com a ação?';
    @Input() labelAction: string = 'Prosseguir';
    @Input() labelCancel: string = 'Cancelar';
    @Input() confirmType: TypeButton = 'primary';
    @Input() fade: fadeType = 'default';
    @Input() hidden: boolean = true;
    @Input() style: any = {};
    @Input() title: string;
    @Input() titleInputArea: string = 'Observação';
    @Input() enableInputArea: boolean = false;
    @Input() requiredInputArea: boolean = false;
    @Input() inputAreaValue?: string;
    @Input() maskInputArea?: string;
    @Input() currencyMaskOptions?: any;
    @Input() maxCharacters: number = 500;
    @Input() minCharacters:  number;
    @Input() minWords:  number;
    @Input() closeButton: boolean = false;
    @Input() enableCounterCharacters: boolean = false;

    @Output('onConfirm') onConfirm = new EventEmitter();
    @Output('onCancel') onCancel = new EventEmitter();
    @Output('onClose') onClose: EventEmitter<any> = new EventEmitter<any>(null);

    currentCharacterCount: number = 0

    constructor(private _notificationService: NotificationService) {}

    preventEnterKey(event: KeyboardEvent) {
        event.preventDefault();
    }

    updateCharacterCount(value) {
        this.currentCharacterCount = value.length; 
    }

    confirm() {
        if (this.enableInputArea && this.requiredInputArea) {
            if (!this.inputAreaValue || this.inputAreaValue.trim() === '') {
                this._notificationService.openNotification('error', `Adicione uma ${this.titleInputArea}`);
            } else {
                const words = this.inputAreaValue.trim().split(' ');
                if (words.length < this.minWords) {
                    this._notificationService.openNotification('error', `Mínimo ${this.minWords} palavras.`);
                } else if (this.inputAreaValue.length < this.minCharacters) {
                    this._notificationService.openNotification('error', `Mínimo ${this.minCharacters} caracteres.`);
                } else {
                    this.onConfirm.emit(this.inputAreaValue);
                    this._clearInputAreaValue();
                }
            }
        } else {
            this.onConfirm.emit(this.inputAreaValue);
        }
    }

    cancel() {
        if (this.enableInputArea) {
            this.onCancel.emit(this.inputAreaValue);
        } else {
            this.onCancel.emit();
        }
    }

    close(): void {
        this.hidden = true;
        this.onClose.emit();
    }

    private _clearInputAreaValue(): void {
        this.inputAreaValue = '';
        this.currentCharacterCount = 0;
    }
}
