import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { AppModule } from 'app/app.module';
import moment from 'moment';
import * as _ from 'lodash';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { IStatusConsolidationFilter } from './interface/status-consolidation.interface';
import { TransportModalService } from '#services/_transportModal/transportModal.service';

export class StatusConsolidationFilter {

    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);

    private _initialStatusConsolidationFilter: IStatusConsolidationFilter = <IStatusConsolidationFilter>{};
    private _statusConsolidationFilter: IStatusConsolidationFilter = <IStatusConsolidationFilter>{

        typeStatus: 'IMP',
        clientGroups: [],
        referenceDateType: [new DropdownOption('registration', 'Criação')],
        modals: [
            { id: 'assets/img/icons/air.png', itemName: 'Aéreo' },
            { id: 'assets/img/icons/maritime.png', itemName: 'Marítimo' },
            { id: 'assets/img/icons/roads.png', itemName: 'Rodoviário' },
        ],
        subsidiaryNames: []

    };

    private startDate;
    private endDate;

    constructor(private _transportModalService?: TransportModalService, filter?: StatusConsolidationFilter) {
        this._statusConsolidationFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'statusConsolidationFilter', this._statusConsolidationFilter, filter);
        this._initialStatusConsolidationFilter = _.cloneDeep(this._statusConsolidationFilter);
        this.startDate = filter ? filter.startDate : moment().add(-24, 'M').format('DD/MM/YYYY');
        this.endDate = filter ? filter.endDate : moment().format('DD/MM/YYYY');
    }

    public getTypeStatus(): string {
        return this._statusConsolidationFilter.typeStatus;
    }
    public getClientGroups(): string[] {
        return this._statusConsolidationFilter.clientGroups;
    }
    public getReferenceDateType(): DropdownOption[] {
        return this._statusConsolidationFilter.referenceDateType;
    }
    public getStartDate() {
        return this.startDate;
    }
    public getEndDate() {
        return this.endDate;
    }
    public getModals(): any[] {
        return this._statusConsolidationFilter.modals;
    }
    public getSubsidiaryNames(): string[] {
        return this._statusConsolidationFilter.subsidiaryNames;
    }
    public setTypeStatus(typeStatus): void {
        this._statusConsolidationFilter.typeStatus = typeStatus;
    }
    public setClientGroups(clientGroups): void {
        this._statusConsolidationFilter.clientGroups = clientGroups;
    }
    public setReferenceDateType(referenceDateType): void {
        this._statusConsolidationFilter.referenceDateType = referenceDateType;
    }
    public setStartDate(startDate): void {
        this.startDate = startDate;
    }
    public setEndDate(endDate): void {
        this.endDate = endDate;
    }
    public setModals(modals): void {
        this._statusConsolidationFilter.modals = modals;
    }
    public setSubsidiaryNames(subsidiaryNames): void {
        this._statusConsolidationFilter.subsidiaryNames = subsidiaryNames;
    }

    public getRequest(type: string) {

        this._initialStatusConsolidationFilter = this._userPreferencesService.verifyFilterChanges(
            this._initialStatusConsolidationFilter, this._statusConsolidationFilter, 'statusConsolidationFilter', this._userCacheService.getUserPreferences().id);

        const headers = {};
        const clientCodes = this.getClientGroups().map((c) => c['id']).join(',');
        const subsidiaryNames = this.getSubsidiaryNames().map((c) => c['itemName']);
        const selectedReferenceDateType = this.getReferenceDateType();
        const referenceDateType = selectedReferenceDateType?.length > 0 ? selectedReferenceDateType[0].id : 'registration';

        const modals = this.getModals().map(selectedItem => {
            const found = this._transportModalService.getModalList().find(modal => modal.id === selectedItem.id);
            return found ? found.code : null;
        }).filter(code => code !== null);

        const params = {
            startDate: `${moment(this.getStartDate(), 'DD/MM/YYYY').format('YYYY-MM-DD')}`,
            endDate: `${moment(this.getEndDate(), 'DD/MM/YYYY').format('YYYY-MM-DD')}`,
        };

        if (type == 'IMP' || type == 'CHASSI') {
            headers['clientCodes'] = clientCodes;
        }

        if (type == 'IMP' || type == 'SKU') {
            params['modalTransportations'] = modals;
            if (type == 'IMP') {
                params['referenceDateType'] = referenceDateType;
                params['subsidiaryNames'] = subsidiaryNames;
            } else {
                headers['X-Requested-Client-Groups'] = clientCodes;
            }
        }

        return { params, headers };
    }
}
