import { environment } from '#environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Injectable, TemplateRef } from '@angular/core';
import { TemplateLoginEnum, TemplateModel } from '../template-login.enum';

@Injectable()
export class AuthenticationService {

    template:TemplateRef<any>;
    templates:TemplateModel[] = [];
    changeInProgress$: Observable<boolean>;
    private changeInProgress: Subject<boolean>;
    private data$: BehaviorSubject<Object> = new BehaviorSubject(null);

    get permissionsUserApi() {
        const getPermissions = window.localStorage.getItem('getPermissions');
        return JSON.parse(getPermissions) || null;
    }

    set permissionsUserApi(value: boolean) {
        window.localStorage.setItem('getPermissions', value.toString());
    }

    get rememberCredentials() {
        const getCredential = window.localStorage.getItem('credentialRemember');

        if(getCredential){
            return JSON.parse(atob(getCredential));
        }
        return null;
    }

    set rememberCredentials(value){
        if(value){
            window.localStorage.setItem('credentialRemember', btoa(JSON.stringify(value)));
        }
    }

    constructor(
        private http: HttpClient
    ) {
        this.changeInProgress = new Subject();
        this.changeInProgress$ = this.changeInProgress.asObservable();
    }

    async getUserPermissions(token: string, sessionId: string, email: string): Promise<any> {
        const headers = new HttpHeaders().append('X-Authorization-SessionId', sessionId).append('Authorization', 'Bearer '+token);
        return this.http.get(`${environment.endpoints.adminService}/users/${email}/allowedPermissions`, { headers }).toPromise();
    }

    async logout(reload?, keepLastPage?) {
        this._logoutUser();
        this.clearDataUser(keepLastPage);
        if(reload){
            window.location.href = window.location.origin + '/login';
        }
    }

    login(email: string, password: string, captchaToken: string, captchaVisible: boolean): Observable<any> {
        const headers = new HttpHeaders().set('X-Mobile', `${!captchaVisible}`);
        return this.http.post(`${environment.endpoints.adminService}/signIn/signInWithEmailAndPassword`,{ email, password, returnSecureToken: true, captchaToken}, { headers: headers }).pipe();
    }

    validateToken(token: string): Promise<any> {

        return this.http.post(`${environment.endpoints.adminService}/signIn/validate-token`, token).toPromise();
    }

    verifyTwoFactor(email: string, idToken: string): Observable<any> {

        return this.http.post(`${environment.endpoints.adminService}/signIn/verifyTwoFactor`, {email, idToken});
    }

    refreshToken(refreshToken:string): Promise<any>{
        return this.http.post(`${environment.endpoints.adminService}/signIn/refresh-token`, refreshToken).toPromise();
    }

    createPassword(email: string, origin: string = 'comexport'){

        return this.http.post(`${environment.endpoints.adminService}/signIn/create-password`, {email, origin}).pipe();
    }

    validateOobcode(oobCode: string){
        return this.http.post(`${environment.endpoints.adminService}/signIn/reset-password`, {oobCode}).pipe();
    }

    resetPassword(oobCode: string, newPassword: string, token:string){
        return this.http.post(`${environment.endpoints.adminService}/signIn/reset-password`, {oobCode, newPassword,token}).pipe();
    }

    updatePassword(idToken: any,sessionId: any,email: string, newPassword: string, oldPassword, captchaToken: string, mobile: string): Observable<any> {
        const headers = new HttpHeaders().set('X-Mobile', `${mobile}`).set('X-Authorization-SessionId', sessionId).set('Authorization', 'Bearer '+ idToken);
        return this.http.post(`${environment.endpoints.adminService}/users/update-password`, {email, newPassword, oldPassword, captchaToken}, { headers: headers }).pipe();
    }

    validateCaptcha(token) {
		const headers = new HttpHeaders().set('Content-Type', 'text/plain');
        return this.http.post(`${environment.endpoints.adminService}/signIn/validate-captcha`,token, {headers: headers}).pipe();
    }

    validateOtpCode(email, idToken, otpCode) {
        return this.http.post(`${environment.endpoints.adminService}/signIn/signInWithTotp`, { email, idToken, otpCode });
    }

    hasTokenExpiredPassword(data) {
        this.data$.next(data);
        localStorage.setItem('idToken', data.idToken);
        localStorage.setItem('sessionId', data.sessionId);
    }

    renewCredentialRemember(){
        if(localStorage.getItem('credentialRemember') !== null){
            return localStorage.getItem('credentialRemember');
        }
        return null;
    }

    clearDataUser(keepLastPage: boolean) {
        const renewCredentialRemember = this.rememberCredentials;
        const lastPage = sessionStorage.getItem('lastPage');
        const language = localStorage.getItem('language') || 'pt-BR';

        localStorage.clear();
        sessionStorage.clear();
        sessionStorage.setItem('lastPage', keepLastPage ? (lastPage || '/index') : '/index');
        localStorage.setItem('language', language);
        this.rememberCredentials = renewCredentialRemember;
    }

    sendPasswordResetEmail(email: string, firstAccess: boolean,captchaToken, mobile: string, origin: String): Observable<any> {
        const headers = new HttpHeaders().set('X-Mobile', `${mobile}`);
        return this.http.post(`${environment.endpoints.adminService}/signIn/create-password`, { email: email, firstAccess: firstAccess, captchaToken: captchaToken, origin: origin }, {headers: headers});
    }

    enableTwoFactor(sessionId, token, uid){
        const headers = new HttpHeaders().append('X-Authorization-SessionId', sessionId).append('Authorization', 'Bearer '+token);
        return this.http.put(`${environment.endpoints.adminService}/users/twoFactorKey/${uid}/true`, null, { headers });
    }

    updateTwoFactor(uid, enable) {
        return this.http.put(`${environment.endpoints.adminService}/users/twoFactorKey/${uid}/${enable}`, '');
    }

    createPhoneIntegration(uid, phoneNumber) {
        return this.http.post(`${environment.endpoints.adminService}/api/v1/phone-integration/user/${uid}/${phoneNumber}`, '');
    }

    updatePhoneIntegration(uid, phoneNumber) {
        return this.http.put(`${environment.endpoints.adminService}/api/v1/phone-integration/user/${uid}/${phoneNumber}`, '')
    }

    getPhoneIntegrationData(uid){
        return this.http.get(`${environment.endpoints.adminService}/api/v1/phone-integration/user/${uid}`);
    }

    getPermissionsRoutes(){
        return this.http.get(`${environment.endpoints.adminService}/permissions`).pipe();
    }

    getTokenExpiredPassword() {
        return this.data$.asObservable();
    }

    getPasswordRegex() {
        return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*!&@#%^()-_])[0-9a-zA-Z\W_]{8,}$/;
	}

    getCaptchaKey() {
        return environment.captcha.key;
    }

    getTemplate(template:TemplateLoginEnum):TemplateRef<any>{
        return this.templates.find((f) => f.type === template).value;
    }

    setTemplate(template:TemplateLoginEnum){
        this.template = this.getTemplate(template)
    }

    setTemplates(templates:TemplateModel[]){
        this.templates = templates;
    }

    setActiveTemplate(template:TemplateRef<any>){
        this.template = template;
    }

    getActiveTemplate():TemplateRef<any>{
        return this.template;
    }

    private _logoutUser(): Observable<any>{
        const sessionId = localStorage.getItem('sessionId');
        const headers = new HttpHeaders().append('X-Authorization-SessionId', sessionId);
        return this.http.put(`${environment.endpoints.adminService}/signIn/logout`, '', { headers });
    }
}
