import { environment } from '#environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class DatamartService {
    constructor(private _http: HttpClient) {}

    getDatamartConfig(datamartName, serviceId, generatorId, modelName) {
        return this._http.get(
            `${environment.endpoints.datamartService}/api/v1/config/${serviceId}/${generatorId}/${modelName}`
        );
    }

    setDatamartConfiguration(datamartName, serviceId, generatorId, deliveryOptions, qualifier, parameters) {
        let headers = new HttpHeaders();
        let clientGroupCodes = parameters.clientGroupCodes;
        headers = headers.set('X-Requested-Client-Groups', clientGroupCodes?.join(','));
        const params = {
            datamartName,
            serviceId,
            generatorId,
            deliveryOptions,
            parameters,
            qualifier,
            intervalType: 'LAST_N_MONTHS',
            intervalValue: parameters.interval ? parameters.interval[0] : '12',
            periodicityType: parameters.periodicityType ? parameters.periodicityType[0] : 'MINUTES',
            periodicityValue: parameters.periodicity ? parameters.periodicity[0] : '120',
        };
        return this._http.put(`${environment.endpoints.datamartService}/api/v1/config`, params, { headers });
    }

    async migrateDatamartConfiguration(fromServiceId, fromGeneratorId, fromQualifier, datamartName, serviceId, generatorId, deliveryOptions, qualifier, parameters, modelId, isCustomer) {
        let headers = new HttpHeaders();
        let clientGroupCodes = parameters.clientGroupCodes;
        headers = headers.set('X-Requested-Client-Groups', clientGroupCodes.join(','));
        let params = new HttpParams();
        params = params.append('isCustomer', isCustomer);
        params = params.append('modelId', modelId);
        const body = {
            fromServiceId, 
            fromGeneratorId, 
            fromQualifier,
            dataSourceConfig: {
                datamartName,
                serviceId,
                generatorId,
                deliveryOptions,
                parameters,
                qualifier,
                intervalType: 'LAST_N_MONTHS',
                intervalValue: parameters.interval ? parameters.interval[0] : '12',
                periodicityType: 'MINUTES',
                periodicityValue: parameters.periodicity ? parameters.periodicity[0] : '120',
            }
        };

        return this._http.post(`${environment.endpoints.datamartService}/api/v1/config`, body, { params, headers });
    }
}
