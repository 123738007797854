import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { finalize } from 'rxjs/operators';

import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { DATE_NOTIFICATION_COLUMNS } from './constants/date-notification-columns.constant';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserService } from "app/auth/_services/user.service";
import { CmxDropdownService } from 'app/shared/components/cmx-dropdown/cmx-dropdown.service';
import { DateNotificationService } from './date-notification.service';
import { UtilsService } from '#services/_utils/utils.service';
import { NotificationService } from '#services/_notification/notification.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';

@Component({
    selector: 'date-notification-crud',
    templateUrl: './date-notification.component.html',
    styleUrls: ['./date-notification.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class DateNotificationCRUDComponent {

    async: boolean = false;
    changes: boolean = false;
    initialEmpty: boolean = false;
    showNoEmailConfirmationModal: boolean = false;
    loadingTranslate: boolean = false;

    currentUser;
    selectedClientGroup;
    gridRows = [];
    clientGroups = this._userCacheService.getClientGroups();
    gridColumns = this._utilsService.translateListField(DATE_NOTIFICATION_COLUMNS, 'name', false);
    translateSubscription: Subscription = null;

    dropdown: {
        emails: { options: DropdownOption[]; selected: DropdownOption[] }
    } = {
        emails: {  options: [], selected: [] }
    };

    emailDropdownSettings = {
        enableCheckAll: true,
        badgeShowLimit: 1,
        disabled: false,
        translate: true,
    };

    constructor(
        private _userService: UserService,
        private _utilsService: UtilsService,
        private _userCacheService: UserCacheService,
        private _cmxDropdownService: CmxDropdownService,
        private _notificationService: NotificationService,
        private _dateNotificationService: DateNotificationService,
        private _translateService: TranslateService,
        private _userPreferencesService: UserPreferencesService,
    ) {
        this._userService.currentUser$.subscribe((user) => {
            this.currentUser = user;
        });
    }

    ngOnInit() {
        // auto selecionar cliente no caso de usuários externos (clientes)
        if (!this.currentUser.has('INTERNAL_FILTERS') && this.currentUser.clientGroups[0]) {
            this.selectedClientGroup = { code: this.currentUser.clientGroups[0] };
            this._updateEmailList();
            this._updateDataList();
        }

        this.onChanges();
    }

    ngOnDestroy() {
        if(this.translateSubscription) {
            this.translateSubscription.unsubscribe();
        }
    }

    onChanges() {
        this.translateSubscription = this._userPreferencesService.onLanguageOrPreferenceChange(
            () => {
                this.loadingTranslate = true;
            }, (typeChange) => {
                if (typeChange === 'language') {
                    this.gridColumns = this._utilsService.translateListField(DATE_NOTIFICATION_COLUMNS, 'name', false);
                    this._translateGridRows();
                }
            }
        );
    }

    selectClientGroup(clientGroup) {
        this.async = true;
        this.selectedClientGroup = clientGroup;
        this._updateDataList();
    }

    emailSelected() {
        this.changes = true;
    }

    switchNotification({ item, column }): void {
        this.gridRows[item?.rawIndex][column] = !this.gridRows[item?.rawIndex][column];
        this.changes = true;
    }

    frequencySelection({ id, itemName, column, row }) {
        this.gridRows[row][column] = { ...this.gridRows[row][column], id, itemName };
        this.changes = true;
    }

    prepareNotification(skipConfirmation?: boolean) {
        const notificationData = [];
        this.gridRows?.forEach((row) => {
            notificationData.push({
                id: `DATA_CHANGE_${this._formatPath(row.path)}_add`,
                includedClientGroupCodes: row.includedClientGroupCodes,
                frequency: { id: row?.frequency?.id, itemName: row?.frequency?.itemName },
                mediums: { EMAIL: row.add ? true : false }
            });
            notificationData.push({
                id: `DATA_CHANGE_${this._formatPath(row.path)}_update`,
                includedClientGroupCodes: row.includedClientGroupCodes,
                frequency: { id: row?.frequency?.id, itemName: row?.frequency?.itemName },
                mediums: { EMAIL: row.update ? true : false }
            });
        });
        const request = {
            category: {
                id: 'DATA_CHANGE',
                children: notificationData,
            },
            mediumProperties: [{
                value: [],
                key: 'email',
                medium: 'EMAIL',
            }, {
                value: [],
                key: 'external-email',
                medium: 'EMAIL',
            }],
        };
        this.dropdown.emails.selected?.forEach((email) => {
            request.mediumProperties[0].value.push(email.id)
            if (!email.id?.includes('@comexport.com.br')) {
                request.mediumProperties[1].value.push(email.id)
            }
        })
        if (!this.dropdown.emails.selected?.length && !skipConfirmation) {
            this.showNoEmailConfirmationModal = true;
        } else {
            this.showNoEmailConfirmationModal = false
            this._saveNotification(request);
        }
    }

    hasConfiguration() {
        return this.gridRows.some((row) => { return row.add || row.update })
    }

    private _saveNotification(notificationData) {
        this.async = true;
        this._dateNotificationService.saveNotification(notificationData, this.selectedClientGroup?.code).subscribe((response) => {
            this._notificationService.openNotification('success', this._translateService.instant('common::messages.success::saved'), this._translateService.instant('configuration.tab::notifications'), 3000);
            this.changes = false;
            this.async = false;
        }, (error) => {
            this.async = false;
            this._utilsService.errorHandler(error, this._translateService.instant('configuration.tab::notifications'));
        });
    }

    private _updateEmailList() {
        this._dateNotificationService.getEmailList(this.selectedClientGroup?.code, this.currentUser.has('INTERNAL_FILTERS')).subscribe((response) => {
            const filteredEmailList = this._filterExternalUserEmailList(response);
            this.dropdown.emails.options = this._cmxDropdownService.toDropdownItemsEmail(filteredEmailList);
        });
    }

    // caso usuário externo, retorna apenas emails de final semelhante.
    private _filterExternalUserEmailList(emaiList) {
        const emailSuffix = this.currentUser?.data?.email.split('@')[1];
        return this.currentUser.has('INTERNAL_FILTERS') ? emaiList : emaiList.filter((email) => { return email?.email?.includes(emailSuffix) })
    }

    private _updateDataList() {
        this.gridRows = [];
        forkJoin([
            this._dateNotificationService.getDataList(this.selectedClientGroup?.code),
            this._dateNotificationService.getDataDefaultList(!this.currentUser.has('INTERNAL_FILTERS')),
            this._dateNotificationService.getEmailList(this.selectedClientGroup?.code, this.currentUser.has('INTERNAL_FILTERS'))
        ])
        .pipe(finalize(() => this.async = false))
        .subscribe(([notificationData, defaultDataList, emailDataList]) => {
            this._verifyEmptyClientConfiguration(notificationData);
            const notificationDateColumns = notificationData?.category?.children;
            const filteredEmailList = this._filterExternalUserEmailList(emailDataList);
            this.dropdown.emails.options = this._cmxDropdownService.toDropdownItemsEmail([ ...filteredEmailList.map((email) => { return { ...email, name: email?.email }; }) ]);
            this._populateEmails(notificationData?.mediumProperties);
            defaultDataList?.groups?.forEach((group) => {
                group?.columns?.forEach((column) => {
                    if (column?.field?.toLowerCase()?.includes('date') || column?.formatter?.toLowerCase()?.includes('date') || column?.path?.toLowerCase()?.includes('dates')) {
                        const add = notificationDateColumns.find((notificationColumn) => notificationColumn?.id === `DATA_CHANGE_${this._formatPath(column?.path)}_add` && notificationColumn?.mediums?.EMAIL === true)  ? true : false;
                        const update = notificationDateColumns.find((notificationColumn) => notificationColumn?.id === `DATA_CHANGE_${this._formatPath(column?.path)}_update` && notificationColumn?.mediums?.EMAIL === true) ? true : false;

                        this.gridRows.push({
                            ...column,
                            frequency: {
                                data: notificationData?.frequencyList || [],
                                id: notificationDateColumns.find((notificationColumn) => notificationColumn?.id === `DATA_CHANGE_${this._formatPath(column?.path)}_${add ? 'add' : 'update'}`)?.frequency?.id || 'ON_CHANGE',
                                itemName: notificationDateColumns.find((notificationColumn) => notificationColumn?.id === `DATA_CHANGE_${this._formatPath(column?.path)}_${add ? 'add' : 'update'}`)?.frequency?.itemName || this._translateService.instant('page::config::notifications.gridDates::byUpdate'),
                                disabled: false,
                            },
                            includedClientGroupCodes: [this.selectedClientGroup?.code],
                            add,
                            update,
                        });
                    }
                });
            });
        });
    }

    private _translateGridRows(): void {
        forkJoin([
            this._dateNotificationService.getDataList(this.selectedClientGroup?.code),
            this._dateNotificationService.getDataDefaultList(!this.currentUser.has('INTERNAL_FILTERS')),
        ])
        .pipe(finalize(() => this.loadingTranslate = false))
        .subscribe(([notificationData, defaultDataList]) => {
            if (!defaultDataList?.groups) return;
            const frequencyList = notificationData?.frequencyList || [];
            const rowMap = new Map<string, string>();

            defaultDataList.groups?.forEach(group => {
                group?.columns?.forEach(row => {
                    if (row?.field && row?.label) {
                        rowMap.set(row.field, row.label);
                    }
                });
            });

            this.gridRows?.forEach(gridRow => {
                const newLabel = rowMap.get(gridRow.field);
                if (newLabel) {
                    gridRow.label = newLabel;
                    gridRow.frequency.data = frequencyList;
                    gridRow.frequency = {
                        ...gridRow.frequency,
                        data: frequencyList,
                        itemName: frequencyList.find(frequency => frequency.id === gridRow.frequency.id)?.itemName || this._translateService.instant('page::config::notifications.gridDates::byUpdate'),
                    }
                }
            });
            this.gridRows = _.cloneDeep(this.gridRows);
        });
    }

    private _populateEmails(emailLists) {
        const emailList = this.currentUser.has('INTERNAL_FILTERS') ? emailLists[0] : emailLists[1];
        this.dropdown.emails.selected = [];
        emailList?.value?.forEach((email) => {
            const selectedEmail = this.dropdown.emails.options.find((option) => option?.id === email);
            if (selectedEmail) {
                this.dropdown.emails.selected.push(selectedEmail);
            }
        });
    }

    private _verifyEmptyClientConfiguration(notificationData) {
        this.initialEmpty = !notificationData?.mediumProperties?.length && !notificationData?.category?.children?.length;
    }

    private _formatPath(path): string {
        return path?.replaceAll('[*].', '_')?.replaceAll('.', '_');
    }
}
