import { environment } from '#environment';
import { allowedModePassword } from './../shared/types/allowed-mode-password.type';
import { Router } from '@angular/router';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AppComponent } from '../app.component';
import { LoginService } from './_services/login.service';
import { UserService } from './_services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from './_services/authentication.service';
import { UtilsService } from '#services/_utils/utils.service';

@Component({
    selector: '.m-grid.m-grid--hor.m-grid--root.m-page',
    templateUrl: './auth-handler.component.html',
    styleUrls: ['./auth-handler.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AuthHandlerComponent implements OnInit {

    captchaEnable = environment.captchaEnable;
    model: any = {};
    initialized = false;
    isValidCode = false;
    firstAccess = false;
    updated = false;
    loading = false;
    returnUrl: string;
    errorPasswordReset = '';
    msgPasswordReset = '';
    asyncLogin = false;
    isIE = false;
    resendDisabled = false;
    focusHit: boolean = false;
    expiredPassword: boolean = false;

    checkTotalPassword: boolean = false;
    checkNumbersCharsPassword: boolean = false;
    checkSpecialCharPassword: boolean = false;
    passwordAccept: boolean = false;

    flagModeExpired: allowedModePassword = 'EXPIRED_PASSWORD';
    flagModeReset: allowedModePassword = 'resetPassword';
    captchaToken: string;
    mobile: any = true;

    logoLogin = this.loginService.getLogo();
    backLogin = this.loginService.getBackground();
    client = this.loginService.getClient();

    firstPasswordVisible: boolean = true;
    secondPasswordVisible: boolean = true;

    @ViewChild('captcha') captcha;
    captchaValidated: boolean = true;
    captchaKey: string;
    showCaptcha: boolean = true;
    idToken: any;
    auth: any = {
        idToken: null,
        sessionId: null
    };

    constructor(
        private userService: UserService,
        private route: ActivatedRoute,
        private _router: Router,
        private loginService: LoginService,
        private app: AppComponent,
        private _authenticationService: AuthenticationService,
        private _utilsService: UtilsService,
        private _translateService: TranslateService
    ) {}

    ngOnInit() {
        this.showCaptcha = true;
        this.captchaKey = this._authenticationService.getCaptchaKey();
        this.route.queryParams.subscribe((params) => {
            this.model.mode = params?.mode as allowedModePassword;
            this.model.actionCode = params?.oobCode;
            this.model.continueUrl = params?.continueUrl;

            if (this.model.mode === this.flagModeReset) {
                const queryParams = this.parseQuery(this.model?.continueUrl);
                this.firstAccess = queryParams['firstAccess'] === 'true';
                this.model.email = queryParams['email'];
            } else {
                this.model.email = params.email;
            }
            this.getUser();
        });
        this.app.globalLoader = false;
        $('.m-page-loader.m-page-loader--non-block').attr('style', 'display: none');
        this._authenticationService.getTokenExpiredPassword().subscribe((data) => {
            this.auth = data;
            if (!this.auth && this.model.mode === 'EXPIRED_PASSWORD') {
                this._router.navigate(['/login']);
            }
        });
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (
                this.captchaEnable &&
                this.captcha &&
                this.captcha.el.nativeElement.childNodes.length > 0 &&
                this.captcha.el.nativeElement.childNodes[0].childElementCount > 0
            ) {
                this.captchaValidated = false;
            }
        }, 1000);
    }

    resendLink() {
        if (this.resendDisabled === true) {
            return false;
        }
        this.asyncLogin = true;
        this.resendDisabled = true;
        this.showCaptcha = true;
        this.errorPasswordReset = '';

        try {
            if (this.captchaValidated) {
                const origin = location.host;
                this._authenticationService.sendPasswordResetEmail(this.model.email,this.model.firstAccess,this.captchaToken,this.mobile,origin).subscribe(
                    (data) => {
                        this.showCaptcha = false;
                        this.asyncLogin = false;
                        this.msgPasswordReset = this._translateService.instant('auth.msgLinkSent');
                    }, (error) => {
                        this.reenabledResentButton();
                        this.asyncLogin = false;
                        this._utilsService.errorHandler(error, this._translateService.instant('auth.msgResendAccessLink'));
                    }
                );
            } else {
                this.asyncLogin = false;
                this.errorPasswordReset = this._translateService.instant('auth.msgCaptchaError');
            }
        } catch (err) {
            this.reenabledResentButton();
            this.asyncLogin = false;
            this.errorPasswordReset = this._translateService.instant('auth.msgEmailSendFail') + ' ' + this._translateService.instant('common::messages.tryAgain') + '.';
        }
    }

    checkPassword(input) {
        const password = input.target.value;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumber = /\d/.test(password);
        const hasSpecialChar = /[!@#$%&^*()_\-]/.test(password);
        const isValidLength = password.length >= 8;

        this.checkTotalPassword = isValidLength;
        this.checkNumbersCharsPassword = hasUpperCase && hasLowerCase && hasNumber;
        this.checkSpecialCharPassword = hasSpecialChar;
        this.passwordAccept = this.checkTotalPassword && this.checkNumbersCharsPassword && this.checkSpecialCharPassword;
    }

    confirmPasswordReset() {
        this.errorPasswordReset = '';
        this.msgPasswordReset = '';
        this.updated = false;
        this.showCaptcha = true;
        this.asyncLogin = true;

        const { password, passwordConfirmation, email, mode, actionCode, oldPassword } = this.model;

        if (!password || typeof password !== 'string') {
            this.errorPasswordReset = this._translateService.instant('auth.msgInvalidPassword');
            return;
        }

        if (!passwordConfirmation || typeof passwordConfirmation !== 'string') {
            this.errorPasswordReset = this._translateService.instant('auth.msgConfirmPassword');
            return;
        }

        if (password !== passwordConfirmation) {
            this.errorPasswordReset = this._translateService.instant('auth.msgPasswordMismatch');
            return;
        }

        const [localPart, domainPart] = email.split('@');
        const nameParts = localPart.split('.');
        const domainParts = domainPart.split('.');
        const forbiddenWords = [...nameParts, ...domainParts].filter(Boolean).join('|');
        const regex = new RegExp(forbiddenWords, 'gi');

        if (regex.test(password)) {
            this.resetForm();
            this.errorPasswordReset = this._translateService.instant('auth.msgPasswordContainsEmail');
            return;
        }

        if (!this._authenticationService.getPasswordRegex().test(password)) {
            this.resetForm();
            this.errorPasswordReset = this._translateService.instant('auth.msgPasswordNotMet');
            return;
        }

        if (!this.captchaValidated) {
            this.asyncLogin = false;
            this.errorPasswordReset = this._translateService.instant('auth.msgCaptchaError');
            return;
        }

        const onSuccess = (data) => {
            this.updated = true;
            this.showCaptcha = false;
            this.asyncLogin = false;
            this.msgPasswordReset = this._translateService.instant('auth.msgPasswordChanged');
        };

        const onError = (error) => {
            this.asyncLogin = false;
            this._utilsService.errorHandler(error, this._translateService.instant('auth.msgPasswordChange'));
        };

        if (mode === 'EXPIRED_PASSWORD') {
            this.userService.updatePassword(
                this.auth.idToken,
                this.auth.sessionId,
                email,
                password,
                oldPassword,
                this.captchaToken,
                this.mobile
            ).subscribe(onSuccess, onError);
        } else {
            this.userService.confirmPasswordReset(actionCode, password, this.captchaToken).subscribe(onSuccess, onError);
        }
    }

    resetForm(): void {
        this.asyncLogin = false;
        this.model.password = null;
        this.model.passwordConfirmation = null;
        this.passwordAccept = false;
    }

    continue() {
        const clientUrl = this.getClientUrl();
        window.open(clientUrl, '_self');
    }

    goToLogin() {
        this._router.navigate(['/login']);
    }

    validateCaptcha($event) {
        this.captchaValidated = true;
        this.captchaToken = $event.response;
    }

    resetCaptcha() {
        this.captchaValidated = false;
        this.captcha.reset();
    }

    changePasswordVisibility(firstPassword) {
        if (firstPassword) {
            this.firstPasswordVisible = !this.firstPasswordVisible;
        } else {
            this.secondPasswordVisible = !this.secondPasswordVisible;
        }
    }

    samePasswords() {
        return this.model.passwordConfirmation !== this.model.password && this.model.passwordConfirmation.length > 7;
    }

    private initialize() {
        this.app.globalLoader = false;
        this.initialized = true;
    }

    private _verifyLinkResetPassowrd() {
        this.userService.verifyLinkResetPassword(this.model.actionCode).subscribe(
            () => {
                this.isValidCode = true;
                this.asyncLogin = false;
            }, () => {
                this.errorPasswordReset = this._translateService.instant(this.firstAccess === true ? 'auth.msgFirstAccessExpired' : 'auth.msgInvalidOrExpiredLink') + '<br/>' + this._translateService.instant('auth.msgRecoveryLinkExpired');
                this.asyncLogin = false;
                this.isValidCode = false;
                this.reenabledResentButton();
            }
        );
    }

    private getClientUrl() {
        const l = document.createElement('a');
        l.href = this.model.continueUrl;
        const queryParams = this.parseQuery(this.model.continueUrl);
        const clientDomain = queryParams['domain'];
        return `${l.protocol}//${clientDomain}/login`;
    }

    private reenabledResentButton() {
        this.resendDisabled = false;
    }

    private parseQuery(url) {
        const query = {};
        const index: number = url.indexOf('?');
        const pairs = url.substr(index + 1).split('&');
        for (let i = 0; i < pairs.length; i++) {
            const pair = pairs[i].split('=');
            query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
        }
        return query;
    }

    private getUser() {
        this.userService.authenticatedUser$.subscribe((user) => {
            if (user === null && this.isIE === true) {
                setTimeout(() => {
                    this.getUser();
                }, 2000);
            } else {
                this.initialize();
                if (this.model.mode === this.flagModeReset) {
                    this.isValidCode = true;
                    this.asyncLogin = false;
                    this._verifyLinkResetPassowrd();
                } else if (this.model.mode === this.flagModeExpired) {
                    this.isValidCode = true;
                    this.asyncLogin = false;
                    this.expiredPassword = true;
                } else {
                    this.errorPasswordReset = this._translateService.instant('auth.msgInvalidOrExpiredLink');
                }
            }
        });
    }
}
