import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Inputmask from 'inputmask';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';

import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { NotificationsCRUDService } from './notifications.service';
import { UserService } from '../../../../auth/_services/user.service';
import { CmxDropdownService } from 'app/shared/components/cmx-dropdown/cmx-dropdown.service';
import { NotificationService } from '#services/_notification/notification.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';

@Component({
    selector: 'notifications-crud',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class NotificationsCRUDComponent {

    asyncNotifications: boolean = false;
    loadingTranslate: boolean = false;

    currentUser$ = this.userService.currentUser$;
    allClients = this._userCacheService.getClientGroups();
    currentUserEmail = this.userService.getCurrentUser().getEmail();
    translateSubscription: Subscription = null;

    email;
    phone;
    data;
    emailProperties;
    phoneProperties;

    dropdown: {
        frequency: DropdownOption[],
        clients: DropdownOption[]
    } = {
        frequency: [],
        clients: []
    };

    readonly dropdownSettingsFrequency = {
        singleSelection: true,
        enableSearchFilter: false,
        disabled: false,
        translate: true
    };

    readonly dropdownSettingsClients = {
        enableCheckAll: true,
        singleSelection: false,
        badgeShowLimit: 1,
        disabled: false,
        translate: true
    };

    constructor(
        private notificationsCRUDService: NotificationsCRUDService,
        private userService: UserService,
        private _userCacheService: UserCacheService,
        private _translateService: TranslateService,
        private _cmxDropdownService: CmxDropdownService,
        private _notificationService: NotificationService,
        private _userPreferencesService: UserPreferencesService
    ) { }

    ngAfterViewInit() {
        this.getStatusConfiguration();
    }

    ngOnDestroy() {
        if(this.translateSubscription) {
            this.translateSubscription.unsubscribe();
        }
    }

    getStatusConfiguration() {
        this.notificationsCRUDService.getNotificationConfiguration().subscribe((data) => {
            this.data = _.cloneDeep(data);
            this.dropdown.frequency = _.cloneDeep(data.frequencyList);

            if (this.data.category.hasOwnProperty('children')) {
                this.data.category.children.forEach((child: any) => {
                    child.dropdown = _.cloneDeep(this.dropdown);
                    this.appendPage(child.dropdown.clients);
                    child.dropdownSettingsClients = _.cloneDeep(this.dropdownSettingsClients);
                    child.dropdownSettingsFrequency = _.cloneDeep(this.dropdownSettingsFrequency);
                    child.frequency = [child.frequency || { id: 'DAILY', itemName: this._translateService.instant('page::config::notifications.gridDates::daily') }];
                    this.checkIfDisabled(child);
                });
            } else {
                this.data.category.dropdown = _.cloneDeep(this.dropdown);
                this.appendPage(this.data.category.dropdown.clients);
                this.data.category.dropdownSettingsClients = _.cloneDeep(this.dropdownSettingsClients);
                this.data.category.dropdownSettingsFrequency = _.cloneDeep(this.dropdownSettingsFrequency);
                this.data.category.frequency = [this.data.category.frequency || { id: 'DAILY', itemName: this._translateService.instant('page::config::notifications.gridDates::daily') }];
                this.checkIfDisabled(this.data.category);
            }

            this.emailProperties = this.getEmailProperties();
            this.asyncNotifications = true;
            this.onChanges();

            setTimeout(() => {
                Inputmask({ mask: '(99) 9999[9]-9999' }).mask($('input#phone'));
            }, 200);
        });
    }

    toogle(id, element) {
        if ($(element.target.previousElementSibling).hasClass('fa-caret-down')) {
            $(element.target.previousElementSibling).removeClass('fa-caret-down');
            $(element.target.previousElementSibling).addClass('fa-caret-right');
            $(`.single.notification.${id}`).css('display', 'none');
        } else {
            $(element.target.previousElementSibling).removeClass('fa-caret-right');
            $(element.target.previousElementSibling).addClass('fa-caret-down');
            $(`.single.notification.${id}`).css('display', 'flex');
        }
    }

    validateEmailDomain() {
        const currentUserEmailDomain = this.currentUserEmail.split("@")[1].toLowerCase();
        const formEmailDomain = this.email.split("@")[1].toLowerCase();
        const result = currentUserEmailDomain === formEmailDomain;
        if (!result) {
            const message = `${this._translateService.instant('page::config::notifications.emailDomainValidation')} "${currentUserEmailDomain}"`;
            this._notificationService.openNotification('error', message, this._translateService.instant('configuration.tab::notifications'));
        }
        return result;
    }

    addEmail() {
        if (this.validateEmail(this.email)) {
            if (this.emailProperties.value.includes(this.email)) {
                this._notificationService.openNotification('error', this._translateService.instant('page::config::notifications.emailAlreadyExists'), this._translateService.instant('configuration.tab::notifications'));
            } else if (this.validateEmailDomain()) {
                this.emailProperties.value.push(this.email);
                this.email = '';
            }
        }
    }

    addPhone() {
        let phone = this.phone.match(/\d/g);
        phone = phone.join('');
        if (parseInt(phone.length, 10) === 11) {
            if (this.phoneProperties.value.includes(phone)) {
                this._notificationService.openNotification('error', this._translateService.instant('page::config::notifications.phoneAlreadyExists'), this._translateService.instant('configuration.tab::notifications'));
            } else {
                this.phoneProperties.value.push(phone);
                this.phone = '';
            }
        } else {
            this._notificationService.openNotification('error', this._translateService.instant('auth.msgInvalidPhoneNumber'), this._translateService.instant('configuration.tab::notifications'));
        }
    }

    removeFrom(array, index) {
        if (array === 'emailList') {
            this.emailProperties.value.splice(index, 1);
        } else {
            this.data.phoneList.splice(index, 1);
        }
    }

    saveConfiguration() {
        const configurationData = this.preparedataToSave();
        this.asyncNotifications = false;
        this.notificationsCRUDService.saveNotificationConfiguration(configurationData).subscribe((response) => {
            this.asyncNotifications = true;
            this._notificationService.openNotification('success', this._translateService.instant('common::messages.success::saved'), this._translateService.instant('configuration.tab::notifications'));
        });
    }

    validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const result = re.test(String(email).toLowerCase());
        if (!result) {
            this._notificationService.openNotification('error', this._translateService.instant('auth.msgInvalidEmail'), this._translateService.instant('configuration.tab::notifications'));
        }
        return result;
    }

    changeMedium(child: any, medium: string) {
        child.mediums[medium] = !child.mediums[medium];
        this.checkIfDisabled(child);
    }

    onChanges() {
        this.translateSubscription = this._userPreferencesService.onLanguageOrPreferenceChange(
            () => {
                this.loadingTranslate = true;
            }, (typeChange) => {
                if (typeChange === 'language') {
                    this.notificationsCRUDService.getNotificationConfiguration().subscribe((statusData) => {
                        this._translateContent(statusData);
                    });
                }
            }
        );
    }

    private _translateContent(statusData) {
        const translateMap = new Map<string, string>();
        this.dropdown.frequency = _.cloneDeep(statusData.frequencyList);

        if (this.data.category.id === statusData.category.id) this.data.category.name = statusData.category.name;

        if (statusData.category.hasOwnProperty('children')) {
            statusData.category.children?.forEach((child: any) => {
                if (child?.id && child?.name) {
                    translateMap.set(child.id, child.name);
                }
            });
        }

        if (this.data.category.hasOwnProperty('children')) {
            this.data.category.children?.forEach(status => {
                const newLabel = translateMap.get(status.id);
                if (newLabel) {
                    status.name = newLabel;
                    status.frequency[0] = { ...status.frequency[0], itemName: this.dropdown.frequency.find(frequency => frequency.id === status.frequency[0].id)?.itemName },
                    status.dropdown.frequency = _.cloneDeep(this.dropdown.frequency);
                }
            });
        }

        this.loadingTranslate = false;
    }

    private appendPage(options: any[]) {
        const pageClients = this.allClients;
        this._cmxDropdownService.toDropdownItems(pageClients).forEach(e => options.push(e));
    }

    private checkIfDisabled(child: any) {
        let disabled = child.disabled;
        child.disabled = !Object.values(child.mediums).some(e => e);
        if (child.disabled != disabled) {
            const dropdownSettingsClients = _.cloneDeep(this.dropdownSettingsClients);
            const dropdownSettingsFrequency = _.cloneDeep(this.dropdownSettingsFrequency);

            dropdownSettingsClients.disabled = child.disabled;
            dropdownSettingsFrequency.disabled = child.disabled;

            child.dropdownSettingsClients = dropdownSettingsClients;
            child.dropdownSettingsFrequency = dropdownSettingsFrequency;

            if (child.disabled) {
                child.clients = [];
            } else if (!child.clients) {
                child.clients = this.selectClients(this.allClients, child.includedClientGroupCodes, child.excludedClientGroupCodes);
            }
        }
    }

    private getEmailProperties() {
        let properties: any = this.data.mediumProperties.find((item: any) => item.medium === 'EMAIL' && item.key === 'email');

        if (!properties) {
            properties = {
                medium: 'EMAIL',
                key: 'email',
                value: [],
            };
            this.data.mediumProperties.push(properties);
        }

        return properties;
    }

    private preparedataToSave() {
        const preparedData = _.cloneDeep(this.data);
        if (preparedData.category.hasOwnProperty('children')) {
            preparedData.category.children.forEach((child: any) => {
                child.frequency = child.frequency[0].id;
                const { included, excluded } = this.getIncludedExcluded(this.allClients, child.clients);
                child.includedClientGroupCodes = included;
                child.excludedClientGroupCodes = excluded;
                delete (child.clients);
                delete (child.dropdown);
                delete (child.disabled);
                delete (child.dropdownSettingsFrequency);
                delete (child.dropdownSettingsClients);
            });
        } else {
            preparedData.category.frequency = preparedData.category.frequency[0].id;
            const { included, excluded } = this.getIncludedExcluded(this.allClients, preparedData.category.clients);
            preparedData.category.includedClientGroupCodes = included;
            preparedData.category.excludedClientGroupCodes = excluded;
            delete (preparedData.category.clients);
            delete (preparedData.category.dropdown);
            delete (preparedData.category.disabled);
            delete (preparedData.category.dropdownSettingsFrequency);
            delete (preparedData.category.dropdownSettingsClients);
        }
        delete preparedData.frequencyList;
        return preparedData;
    }

    private getIncludedExcluded(allItems: any[], selectedItems: any[]) {
        if (selectedItems.length >= allItems.length / 2) {
            const included = selectedItems.map(e => e.id);
            const excluded = allItems.map(e => e.code).filter(e => !included.includes(e));
            return { included: [], excluded };
        } else if (selectedItems.length != allItems.length) {
            const included = selectedItems.map(e => e.id);
            const excluded = [];
            return { included, excluded };
        } else {
            return { included: [], excluded: [] }
        }
    }

    private selectClients(items: any[], included: any[], excluded: any[]) {
        if (included && included.length > 0) {
            return this._cmxDropdownService.toDropdownItems(items.filter(e => included.includes(e.code)));
        } else if (excluded && excluded.length > 0) {
            return this._cmxDropdownService.toDropdownItems(items.filter(e => !excluded.includes(e.code)));
        } else {
            return [];
        }
    }
}
