<div class="cmx-filter-search" [ngClass]="types.length > 1 ? 'multiple' : ''">
    <!-- Simple Search -->
    <input
        type="text"
        [style.width]="width"
        placeholder="{{ 'system::actions.search' | translate }} {{ type }}"
        [ngModel]="value"
        (ngModelChange)="onInputEvent($event)"
        (keyup.enter)="onApply.emit()"
    />

    <div *ngIf="!disableAplly" class="applyButton" (click)="onApply.emit()">
        <i class="fa fa-check"></i>
    </div>

    <!-- Multiple Search -->
    <ng-container *ngIf="types.length > 1">
        <div class="typeButton" (click)="changeTypeModal()"><i class="fa fa-angle-down"></i></div>
        <ul *ngIf="typeModal" class="types" [style.width]="disableAplly ? '100%' : 'calc(100% + 22px)'">
            <li *ngFor="let type of types" (click)="onChangeTypeEvent(type)">
                {{ type }}
            </li>
        </ul>
    </ng-container>
</div>
