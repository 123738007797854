<div class="moveBtn"><div class="moveLine"></div></div>

<div *ngIf="channelLoading || initLoading || loadingTranslate">
    <loader></loader>
</div>

<div class="header-container" *ngIf="currentUser$ | async as user">
    <div class="title-information">
        <span class="analyticsName">{{ 'dashboard.title::channelStatus' | translate }}</span>
        <cmx-help-modal
            [help-title]="'dashboard.title::channelStatus' | translate"
            [help-description]="helpDescription"
            [help-video-src]="'assets/videos/icone-informativo/dashboardsAtualizado.mp4'"
            help-video-name="{{'common::strings.dashboard' | translate }} {{ 'dashboard.title::channelStatus' | translate }}"
        ></cmx-help-modal>
        <h5>{{ 'dashboard.title::channelStatus' | translate }}</h5>
    </div>
</div>

<cmx-marker-filter
    *ngIf="currentUser$ | async as user"
    id="channel-marker-filter"
    [filters]="translatedPeriodOptions"
    [preSelected]="['CURRENT_YEAR']"
    returnBasedOn="code"
    type="period"
    [multiple]="false"
    [hasSelectAll]="false"
    [leftDistance]="190"
    alignment="start"
    (onSelected)="onSelectedPeriod($event)"
></cmx-marker-filter>

<div *ngIf="currentUser$ | async as user" class="filterContainer">
    <div
        *ngIf="currentUser?.has('ROUTE_AUTOMOTIVE') && !currentUser?.has('AUTOMOTIVE_USER')"
        tooltip="{{ 'common::strings.automotiveMode' | translate | uppercase }}: {{ (filterState.getAutomotiveMode() === 'Ligado' ? 'common::strings.on' : 'common::strings.off') | translate }}"
        class="filterMode"
        [ngClass]="{'clicked': filterState.getAutomotiveMode() === 'Ligado'}"
        (click)="toggleFilterMode()"
    >
        <div class="icon-align"><em class="material-icons">directions_car</em></div>
    </div>

    <div id="searchLine" class="reduzed">
        <div class="input-group" *ngIf="currentUser?.has('INTERNAL_FILTERS')">
            <cmx-dropdown
                [styles]="{width: '120px'}"
                id="channel-clients"
                name="clients"
                [data]="options.clients"
                [ngModel]="filterState.getClientGroups()"
                (ngModelChange)="filterState.setClientGroups($event)"
                [settings]="dropdownSettings.clientGroups"
            ></cmx-dropdown>
            <span class="input-group-addon" style="margin: 0 -6px 0 -15px;"></span>
        </div>
        <div class="input-group" *ngIf="currentUser?.has('INTERNAL_FILTERS')">
            <cmx-dropdown
                [styles]="{width: '90px'}"
                id="channel-urfs"
                name="urfs"
                [data]="options.urfs"
                [ngModel]="filterState.getUrfs()"
                (ngModelChange)="filterState.setUrfs($event)"
                [settings]="dropdownSettings.urfs"
            ></cmx-dropdown>
            <span class="input-group-addon to">{{ 'common::strings.by' | translate }}</span>
        </div>
        <div class="input-group">
            <cmx-dropdown
                [styles]="{width: '110px'}"
                id="channel-type-data"
                name="type-data"
                [data]="options.typeData"
                [ngModel]="filterState.getTypeData()"
                (ngModelChange)="filterState.setTypeData($event)"
                [settings]="dropdownSettings.typeData"
            ></cmx-dropdown>
        </div>
        <div class="input-group date-picker">
            <cmx-filter-datepicker
                id="channel-status-datepicker"
                type="range-month"
                periodType="month"
                [startDate]="filterState.getStartDate()"
                [endDate]="filterState.getEndDate()"
                (onChangeDates)="datesChanged($event)"
            ></cmx-filter-datepicker>
        </div>

        <button (click)="loadChannel()" class="applyImpStatus">
            <i class="fa fa-check"></i>
        </button>
    </div>
</div>

<cmx-simple-line-chart
    *ngIf="!initLoading"
    percentageValue="true"
    [chartData]="resultValues"
    [clickable]="true"
    (onSelect)="selectChannelColor($event)"
></cmx-simple-line-chart>

<div class="channel-dialog-container" *ngIf="showModal">
    <div class="channel-dialog-grid-error text-center mt-3" *ngIf="!gridLoading && gridRows?.length < 1">
        <span class="noContent">{{ 'common::strings.emptyDataMessage' | translate }}</span>
    </div>

    <div class="channel-dialog-grid-content" *ngIf="gridRows?.length > 0">
        <loader *ngIf="gridLoading"></loader>
        <cmx-custom-grid
            [id]="verifyAutomotiveMode(currentUser) ? 'channel-dialog-grid-automotive' : 'channel-dialog-grid-default'"
            [style.visibility]="!gridLoading ? 'visible' : 'hidden'"
            [columns]="gridColumns"
            [rows]="gridRows"
            [options]="gridOptions"
            [styles]="gridStyles"
            (onCellClick)="onCellClick($event)"
        ></cmx-custom-grid>
        <div [style.visibility]="!gridLoading ? 'visible' : 'hidden'"
            class="position-absolute" *ngIf="pages.length > 1">
            <pagination
                [numberOfPages]="pages.length"
                [queryId]="queryId"
                (onPageChange)="changePage($event)">
            </pagination>
        </div>
    </div>
</div>
