import { AfterViewInit, Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { forkJoin } from 'rxjs';

import { OperationalConsolidationFilter } from 'app/theme/layouts/operational/operational-consolidation/operationalConsolidation.model';
import { FilterStateService } from '#services/_filters/filter-state.service';
import { OperationalConsolidationService } from './operational-consolidation.service';
import { UtilsService } from '#services/_utils/utils.service';
import { UserService } from "app/auth/_services/user.service";
import { DropdownOption } from '../../../../shared/components/cmx-dropdown/cmx-dropdown.model';
import { DROPDOWN_SETTINGS } from '../../../../shared/constants/dropdown-settings.constants';
import * as _ from 'lodash';
import { CmxDropdownService } from 'app/shared/components/cmx-dropdown/cmx-dropdown.service';
import { FollowUpFilter } from 'app/theme/pages/reports/report-followup/report-followup-filter/followUpFilter.model';
import { UserCacheService } from '#services/_user/app-user-cache.service';

@Component({
    selector: 'operational-consolidation',
    templateUrl: './operational-consolidation.component.html',
    styleUrls: ['../../charts/charts.scss', './operational-consolidation.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class OperationalConsolidationComponent implements OnDestroy, AfterViewInit {
    currentUser = this._userService.getCurrentUser();
    subscription;
    filterState: OperationalConsolidationFilter = this.filterStateService.getOperationalConsolidationFilter();
    followupFilterState: FollowUpFilter = this.filterStateService.getFupFollowUpFilter();
    asyncConsolidation = true;
    asyncDetailConsolidation = true;
    futureDate = false;
    messageFutureDate = '';
    oldDate = false;
    messageOldDate = '';
    consolidationOrder = ['IMPS', 'TEUS', 'CONTAINERS', 'TRUCK_TRAILERS', 'TONS_BY_AIR', 'AIRCRAFTS', 'VEHICLES'];
    consolidationDataImp: object = new Object();
    consolidationDataAutomotive: object = new Object();
    selectedDetailTitle;
    selectedTableTitle;
    detailData = [];
    detailImpData: object = new Object();
    detailAutomotiveData: object = new Object();
    typeContext: string;
    helpDescription = this._defineHelpDescription();
    dropdownSettings = _.cloneDeep(DROPDOWN_SETTINGS);

    options: {
        profitCenters: DropdownOption[]

    } = {
        profitCenters: [],
    };

    constructor(
        public router: Router,
        private operationalConsolidationService: OperationalConsolidationService,
        private filterStateService: FilterStateService,
        private _utilsService: UtilsService,
        private _userService: UserService,
        private _userCacheService: UserCacheService,
        private _cmxDropdownService: CmxDropdownService,
    ) {}

    ngAfterViewInit() {
        this.options.profitCenters = this._cmxDropdownService.toDropdownItems(this._userCacheService.getProfitCenters());
        this.loadConsolidation();
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    changeDate(type) {
        let startDate = moment(this.filterState.getStartDate(), 'DD/MM/YYYY');
        let endDate = moment(this.filterState.getEndDate(), 'DD/MM/YYYY');
        const equalMonths = startDate.month() === endDate.month();
        if (type === 'go') {
            if (equalMonths) {
                startDate = moment(this.filterState.getStartDate(), 'DD/MM/YYYY').add(1, 'M').startOf('M');
                endDate = moment(this.filterState.getStartDate(), 'DD/MM/YYYY').add(1, 'M').endOf('M');
            } else {
                startDate = moment().add(1, 'M').startOf('M');
                endDate = moment().add(1, 'M').endOf('M');
            }
        } else {
            if (equalMonths) {
                startDate = moment(this.filterState.getStartDate(), 'DD/MM/YYYY').add(-1, 'M').startOf('M');
                endDate = moment(this.filterState.getStartDate(), 'DD/MM/YYYY').add(-1, 'M').endOf('M');
            } else {
                startDate = moment().add(-1, 'M').startOf('M');
                endDate = moment().add(-1, 'M').endOf('M');
            }
        }
        this.filterState.setStartDate(startDate.format('DD/MM/YYYY'));
        this.filterState.setEndDate(endDate.format('DD/MM/YYYY'));
        this.closeDetailModal();
        this.loadConsolidation();
    }

    redirectTo(data, companyCode?) {
        if (this.typeContext === 'VEHICLES') {
            this.followupFilterState.setSearch('');
            this.followupFilterState.setFollowUpType('CHASSI');
            this.followupFilterState.setStatus([]);
            this.followupFilterState.setModals([]);
            this.followupFilterState.setProfitCenters(this.filterState.getProfitCenters());
            this.followupFilterState.setClientGroups([{id: data.code, itemName: data.name}]);
            this.followupFilterState.setSubsidiaryNames([]);
            this.followupFilterState.setReferenceDateType([{ id: 'arrival', itemName: 'Atracação' }]);
            this.followupFilterState.setStartDate(this.filterState.getStartDate());
            this.followupFilterState.setEndDate(this.filterState.getEndDate());
        } else {
            this.followupFilterState.setSearch('');
            this.followupFilterState.setFollowUpType('SKU');
            this.followupFilterState.setStatus([]);
            this.followupFilterState.setModals([]);
            this.followupFilterState.setProfitCenters(this.filterState.getProfitCenters());
            this.followupFilterState.setClientGroups([{id: data.code, itemName: data.name}]);
            this.followupFilterState.setSubsidiaryNames([]);
            this.followupFilterState.setReferenceDateType([{ id: 'arrival', itemName: 'Atracação' }]);
            this.followupFilterState.setStartDate(this.filterState.getStartDate());
            this.followupFilterState.setEndDate(this.filterState.getEndDate());
        }
        this.router.navigate([`./report/followup`]);
    }

    datesChanged(event) {
        this.filterState.setStartDate(event.startDate);
        this.filterState.setEndDate(event.endDate);
    }

    loadConsolidation() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.asyncConsolidation = true;
        const startDate = moment(this.filterState.getStartDate(), 'DD/MM/YYYY').format('YYYY-MM-DD');
        const endDate = moment(this.filterState.getEndDate(), 'DD/MM/YYYY').format('YYYY-MM-DD');
        this.filterState.updatePreferences();
        this.subscription = forkJoin([
            this.operationalConsolidationService.getConsolidationAutomotive(
                startDate,
                endDate,
                this.filterState.getClientGroups(),
                this.filterState.getProfitCenters(),
            ),
            this.operationalConsolidationService.getConsolidation(
                startDate,
                endDate,
                this.filterState.getClientGroups(),
                this.filterState.getProfitCenters(),
            ),
        ]).subscribe(
            (data) => {
                const [consolidationAutomotive, consolidationImp] = data;
                if (consolidationImp.hasOwnProperty('stats') && consolidationAutomotive.hasOwnProperty('stats')) {
                    this.consolidationDataImp = consolidationImp.stats;
                    this.consolidationDataAutomotive = consolidationAutomotive.stats;
                    this.asyncConsolidation = false;
                }
            },
            (error) => {
                this.asyncConsolidation = false;
                this._utilsService.errorHandler(error, 'Consolidação Operacional');
            }
        );
    }

    openDetailModal(consolidation) {
        this.typeContext = consolidation;
        const startDate = moment(this.filterState.getStartDate(), 'DD/MM/YYYY').format('YYYY-MM-DD');
        const endDate = moment(this.filterState.getEndDate(), 'DD/MM/YYYY').format('YYYY-MM-DD');

        if (this.getValue(consolidation) > 0) {
            if (this.currentUser.has('INTERNAL_FILTERS')) {
                this.asyncDetailConsolidation = true;
                this.selectedDetailTitle = this.getTitle(consolidation);
                this.selectedTableTitle = this.getTableTitle(consolidation);
                $('.detailModal').removeClass('hidden');
            }

            if (consolidation === 'VEHICLES') {
                this.operationalConsolidationService
                    .getDetailedConsolidationAutomotive(
                        startDate,
                        endDate,
                        consolidation,
                        this.filterState.getClientGroups(),
                        this.filterState.getProfitCenters(),
                    )
                    .subscribe(
                        (detailData) => {
                            if (detailData.hasOwnProperty('items')) {
                                this.detailData = detailData.items;
                                this.asyncDetailConsolidation = false;
                            }
                        },
                        (error) => {
                            this.detailData = [];
                            this.asyncDetailConsolidation = false;
                        }
                    );
            } else {
                this.operationalConsolidationService
                    .getDetailedConsolidation(startDate, endDate, consolidation, this.filterState.getClientGroups(), this.filterState.getProfitCenters())
                    .subscribe(
                        (detailData) => {
                            if (!this.currentUser.has('INTERNAL_FILTERS')) {
                                this.redirectTo(null, detailData.items[0].code);
                            }

                            if (detailData.hasOwnProperty('items')) {
                                this.detailData = detailData.items;
                                this.asyncDetailConsolidation = false;
                            }
                        },
                        (error) => {
                            this.detailData = [];
                            this.asyncDetailConsolidation = false;
                        }
                    );
            }
        }
    }

    closeDetailModal() {
        $('.detailModal').addClass('hidden');
    }

    getValue(type) {
        if (type === 'VEHICLES') {
            return this.consolidationDataAutomotive.hasOwnProperty(type)
                ? this.consolidationDataAutomotive[type].hasOwnProperty('value')
                    ? this.consolidationDataAutomotive[type].value
                    : 0
                : 0;
        } else {
            return this.consolidationDataImp.hasOwnProperty(type)
                ? this.consolidationDataImp[type].hasOwnProperty('value')
                    ? this.consolidationDataImp[type].value
                    : 0
                : 0;
        }
    }
    getPercentage(type) {
        if (type === 'VEHICLES') {
            return (
                (this.consolidationDataAutomotive.hasOwnProperty(type)
                    ? this.consolidationDataAutomotive[type].hasOwnProperty('delta')
                        ? this.consolidationDataAutomotive[type].delta
                        : 0
                    : 0) * 100
            ).toFixed(0);
        } else {
            return (
                (this.consolidationDataImp.hasOwnProperty(type)
                    ? this.consolidationDataImp[type].hasOwnProperty('delta')
                        ? this.consolidationDataImp[type].delta
                        : 0
                    : 0) * 100
            ).toFixed(0);
        }
    }
    getTitle(type) {
        if (type === 'VEHICLES') {
            return this.consolidationDataAutomotive.hasOwnProperty(type)
                ? this.consolidationDataAutomotive[type].hasOwnProperty('title')
                    ? this.consolidationDataAutomotive[type].title
                    : ''
                : '';
        } else {
            return this.consolidationDataImp.hasOwnProperty(type)
                ? this.consolidationDataImp[type].hasOwnProperty('title')
                    ? this.consolidationDataImp[type].title
                    : ''
                : '';
        }
    }

    changeDateCustom() {
        this.loadConsolidation();
    }

    trackByFn(index: number, item: any) {
        return index;
    }

    private _checkFutureOrOldDate() {
        const startDate = moment(this.filterState.getStartDate(), 'DD/MM/YYYY');
        const endDate = moment(this.filterState.getEndDate(), 'DD/MM/YYYY');
        const equalMonths = startDate.month() === endDate.month();
        if (equalMonths) {
            this.futureDate = moment(endDate.add(1, 'M').format('YYYY-MM-DD')).isAfter(moment());
            this.oldDate = moment(startDate.add(-1, 'M').format('YYYY-MM-DD')).isBefore(moment('2020-01-01'));
        } else {
            this.oldDate = false;
            this.futureDate = true;
        }
        if (equalMonths) {
            this.messageOldDate = `Voltar para o mês anterior`;
            this.messageFutureDate = `Ir para o próximo mês`;
        } else {
            this.messageOldDate = `Período anterior: ${moment()
                .add(-1, 'M')
                .startOf('M')
                .format('DD/MM/YYYY')} Até ${moment().add(-1, 'M').endOf('M').format('DD/MM/YYYY')}`;
        }
    }

    private getTableTitle(type) {
        switch (type) {
            case 'IMPS':
                return 'IMPs';
            case 'TEUS':
                return 'TEUs';
            case 'CONTAINERS':
                return 'Containers';
            case 'TRUCK_TRAILERS':
                return 'Carretas';
            case 'TONS_BY_AIR':
                return 'Toneladas';
            case 'AIRCRAFTS':
                return 'Aeronaves';
            case 'VEHICLES':
                return 'Veículos';
        }
    }

    private _defineHelpDescription(): object[] {
        return [
            { type: 'text', value: "Com base na data de chegada dos veículos utilizados nos transportes (navios, aviões e caminhões), informa a quantidade de: Importações desembarcadas , TEU's movimentados, Containers movimentados,  Carretas (rodoviário internacional), e Toneladas embarcadas (via aérea)."},
        ]
    }
}
