import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { throwError as observableThrowError, of as observableOf, Observable } from 'rxjs';
import { delay, retryWhen, mergeMap, take, concat } from 'rxjs/operators';
import moment from 'moment';
import { environment } from '#environment';

import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { FilterSelection } from '../../default/balance-sheet/filter-selection';
import { NodeVisitor } from '../../default/balance-sheet/node.visitor';
import { StructureChangedEvent } from '../../default/balance-sheet/constants/structure-changed-event';
import { StructureSettings } from '../../default/balance-sheet/constants/structure-settings';
import { ReportStructureComponent } from '../report-structure/report-structure.component';
import { FilterComponent } from './../../default/balance-sheet/filter.component';

import { UserService } from '../../../../auth/_services/user.service';
import { DomainTypeService } from '#services/_domain/domainType.service';
import { DownloadService } from '#services/_download/download.service';
import { FinanceExportService } from '#services/_finance-export/finance-export.service';
import { UtilsService } from '#services/_utils/utils.service';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { Grouping, Hierarchy } from '#services/_results/results.service';

declare let Slick: any;

@Component({
    selector: 'report-base',
    templateUrl: './report-base.component.html',
    styleUrls: ['../reports.component.scss', './report-base.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class TraderReportComponent {
    @ViewChild(ReportStructureComponent, { static: true }) structureComponent: ReportStructureComponent;
    @ViewChild(FilterComponent, { static: true }) filterComponent: FilterComponent;

    validDate = true;
    startDate = moment().format('MM/YYYY');
    endDate = moment().format('MM/YYYY');
    asyncReport = false;
    asyncExport = false;
    asyncTitle = false;
    currentUuid;
    queryId = Math.random();
    editableElements = ['#reportTable', '#titleLine'];

    reportType = this.getReportType(window.location.href);
    reportTitle;
    selectedClient;
    selectedImp;
    downloadArquives = [];
    helpDescription  = this._defineHelpDescription();

    uuidSubscription;
    dataSubscription;

    active = new FilterSelection();
    firstFilter = true;

    filters = {
        companies: [],
        profitCenter: [],
        trader: [],
        director: [],
        client: [],
        currency: 'BRL',
    };

    limit = 50;
    pages = [];
    orderBy = 'IMPORTATION_NUMBER';
    sort = true;
    rows = null;
    private total = null;
    private offset = 0;
    private columns = null;
    private dataView = null;
    private reportGrid = null;

    private financeiroFilterState: FilterSelection;
    private financeiroGroupingState: Grouping;
    private financeiroHierarchyState: Hierarchy;

    constructor(
        public domainTypeService: DomainTypeService,
        private http: HttpClient,
        private userService: UserService,
        private downloadService: DownloadService,
        private urlValue: ActivatedRoute,
        private financeExportService: FinanceExportService,
        private utilsService: UtilsService,
        private userCacheService: UserCacheService,
        @Inject(DOCUMENT) private document: any
    ) {
        this.reportTitle = this.domainTypeService.getReportTitle(this.reportType);

        if (this.reportType === 'imp') {
            this.userCacheService.getClientGroups().map((client) => {
                if (client.code === urlValue.snapshot.params.clientGroupCode) {
                    this.asyncTitle = true;
                    this.selectedClient = client;
                }
            });
        } else {
            this.asyncTitle = true;
        }
    }

    ngAfterViewInit() {
        if (this.reportType !== 'imp') {
            this.initView();
        } else {
            const dataFromLastPage = localStorage.getItem('financeiro-filterState');
            if (dataFromLastPage !== null && dataFromLastPage !== undefined) {
                this.getLocalStorageData();
                this.startDate = this.financeiroFilterState.startDate;
                this.endDate = this.financeiroFilterState.endDate;
                this.filters.client = this.financeiroFilterState.client;
                this.filters.profitCenter = this.financeiroFilterState.profitCenter;
                this.filters.trader = this.financeiroFilterState.trader;
                this.filters.director = this.financeiroFilterState.director;
                this.filters.currency = this.financeiroFilterState.currency;
                $('#periodFrom-reportTable').val(this.startDate);
                $('#periodTo-reportTable').val(this.endDate);
                this.initView();
                this.firstState();
            }
        }
    }

    initView() {
        $('#periodFrom-reportTable, #periodTo-reportTable').datepicker({
            format: 'mm/yyyy',
            language: 'pt-BR',
            minViewMode: 'months',
            maxViewMode: 'years',
            updateViewDate: false,
        });
        this.updateDateInputs();

        $('#periodFrom-reportTable, #periodTo-reportTable')
            .datepicker()
            .on('changeDate', (e, options) => {
                const from = moment($('#periodFrom-reportTable').val().toString(), 'MM/YYYY');
                const to = moment($('#periodTo-reportTable').val().toString(), 'MM/YYYY');

                if (from.isAfter(to)) {
                    // shows error message
                    $('.datepicker-invalid.impStatusTable').removeClass('hidden');
                    this.validDate = false;
                } else {
                    // hides error message
                    $('.datepicker-invalid.impStatusTable').addClass('hidden');
                    this.validDate = true;
                    this.startDate = from.format('MM/YYYY');
                    this.endDate = to.format('MM/YYYY');
                }
            });
        $('#buttonsForTraders').css('display', 'none');
        $('#hierarchyGroupingRow').css('display', 'none');
        $('.ui-accordion-header').css('display', 'none');
    }

    ngOnDestroy() {
        if (this.uuidSubscription) {
            this.uuidSubscription.unsubscribe();
        }
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
        }

        const data = localStorage.getItem('financeiro-filterState');
        if (data !== null && data !== undefined) {
            localStorage.removeItem('financeiro-filterState');
            localStorage.removeItem('financeiro-groupingState');
            localStorage.removeItem('financeiro-hierarchyState');
        }
    }

    openFilter() {
        $('report-base .reportFilterModal').removeClass('hidden');
        $('#buttonsForTraders').css('display', 'block');
        $('#dropMgmt').css('display', 'none');
        this.reportType === 'imp'
            ? $('#clientDropdown').css('display', 'none')
            : $('#clientDropdown').css('display', 'block');
    }
    closeFilter() {
        $('report-base .reportFilterModal').addClass('hidden');
        $('#buttonsForTraders').css('display', 'none');
        $('#dropMgmt').css('display', 'block');
    }
    openStructure() {
        $('report-base .reportStructureModal').removeClass('hidden');
    }
    closeStructure() {
        $('report-base .reportStructureModal').addClass('hidden');
    }

    export(type: any, financeArquive?) {
        this.asyncExport = true;
        const options = this.downloadService.getSourceOptions();

        options.headers = new HttpHeaders({
            'X-Requested-Client-Groups': '',
            Accept:
                type === 'csv'
                    ? 'text/csv'
                    : type === 'xls'
                    ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    : 'application/zip',
        });
        // tslint:disable:max-line-length
        let url;
        let name;
        if (financeArquive && financeArquive !== 'all') {
            this.financeExportService.exportArquive(financeArquive).subscribe(() => {
                this.asyncExport = false;
            });
        } else if (financeArquive === 'all') {
            this.financeExportService.exportZip(this.selectedImp).subscribe(() => {
                this.asyncExport = false;
            });
        } else {
            const settings: StructureSettings = this.structureComponent.getActiveStructure();
            options.params = new HttpParams()
                .set('amountFormat', settings.amountsFormat)
                .set('showEmptyValues', settings.displayZeroedNodes ? 'true' : 'false')
                .set(
                    'clientGrouping',
                    settings.showLeaseHolder ? 'leaseHolder' : settings.groupingClients ? 'group' : 'none'
                )
                .set('showServiceInfo', settings.showServiceInfo ? 'true' : 'false');
            url = this.http.get(`${environment.endpoints.resultsService}/reports/${this.currentUuid.uuid}`, options);
            name = `${this.reportType} - ${moment(this.startDate, 'MM/YYYY').format('MM-YYYY')} • ${moment(
                this.endDate,
                'MM/YYYY'
            ).format('MM-YYYY')}.${type}`;
        }

        this.downloadService.downloadFrom(url, name).subscribe(() => {
            this.asyncExport = false;
        });
    }

    firstState() {
        this.applyFilter();
    }

    applyFilter(exportar?) {
        if (this.uuidSubscription) {
            this.uuidSubscription.unsubscribe();
        }
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
        }
        if (!exportar) {
            this.utilsService.setInvisible('report-base', this.editableElements);
            this.firstFilter = false;
            this.asyncReport = false;
        } else {
            this.asyncExport = true;
        }
        const settings = this.structureComponent.getActiveStructure();
        if (this.reportType === 'imp') {
            settings.amountsFormat = 'cents';
        }
        const params = this.prepareParams(exportar);

        this.uuidSubscription = this.getUuid(params).subscribe(
            (uuid) => {
                this.currentUuid = uuid;
                this.dataSubscription = this.getData(uuid, settings).subscribe((data) => {
                    exportar ? this.export('xls') : this.loadReport(data, settings);
                });
            },
            (error) => {
                this.asyncReport = true;
                this.utilsService.setVisible('report-base', this.editableElements);
                this.utilsService.errorHandler(error);
            }
        );
    }

    changeStructure(event: StructureChangedEvent) {
        this.closeStructure();
        if (event.changedProperties) {
            this.applyFilter();
        }
    }
    changeFilters(event) {
        if (this.reportType !== 'imp') {
            this.closeFilter();
            this.startDate = event.startDate;
            this.endDate = event.endDate;
            this.filters = event;
            this.applyFilter();
        }
    }

    prepareParams(exportar?) {
        const domains = ['companies', 'profitCenter', 'trader', 'director', 'client'];
        const filters = {
            companies: [],
            profitCenter: [],
            trader: [],
            director: [],
            client: [],
        };
        domains.map((domain) => {
            this.filters[domain].map((value) => {
                filters[domain].push(value.id);
            });
        });
        const settings = this.structureComponent.getActiveStructure();
        let params = {
            type: this.domainTypeService.getReportHierarchy(this.reportType),
            companies: filters.companies,
            profitCenters: filters.profitCenter,
            traders: filters.trader,
            directors: filters.director,
            clients: this.reportType === 'imp' ? [this.urlValue.snapshot.params.clientGroupCode] : filters.client,
            currency: this.filters.currency,
            startMonth: moment(this.startDate, 'MM/YYYY').format('YYYY-MM'),
            endMonth: moment(this.endDate, 'MM/YYYY').format('YYYY-MM'),
            byLeaseHolder: settings.showLeaseHolder
        };
        const impGridParams = {
            orderBy: {
                column: this.orderBy,
                direction: this.sort ? 'ASC' : 'DESC',
            },
            // offset: this.offset,
            // limit: this.limit,
            offset: 0,
            limit: 0,
        };
        if (this.reportType === 'imp' && !exportar) {
            params = Object.assign({}, params, impGridParams);
        }

        return params;
    }

    loadReport(data: any, settings: StructureSettings) {
        this.total = data.total === null ? 0 : data.total;
        const num = Math.ceil(data.total / 30);
        this.pages = Array.apply(null, { length: num }).map(Number.call, Number);
        const detailData = data;

        this.columns = this.createColumns(detailData);
        this.rows = this.extractRows(detailData, settings);
        this.rows.length === 0 ? $('#reportTable').attr('style', 'display:none') : $('#reportTable').attr('style', '');

        const options = {
            defaultColumnWidth: 120,
            editable: false,
            enableCellNavigation: true,
            enableTextSelectionOnCells: true,
            frozenColumn: 0,
        };

        // initialize the model
        this.dataView = new Slick.Data.DataView({ inlineFilters: false });
        this.dataView.beginUpdate();
        this.dataView.setItems(this.rows);
        this.dataView.setFilter(this.filter.bind(this));
        this.dataView.endUpdate();

        // initialize the grid
        this.reportGrid = new Slick.Grid('#reportTable', this.dataView, this.columns, options);

        this.reportGrid.onClick.subscribe(this.onClick.bind(this));

        this.dataView.onRowCountChanged.subscribe(
            function (e, args) {
                this.reportGrid.updateRowCount();
                this.reportGrid.render();
            }.bind(this)
        );

        this.dataView.onRowsChanged.subscribe(
            function (e, args) {
                this.reportGrid.invalidateRows(args.rows);
                this.reportGrid.render();
            }.bind(this)
        );

        this.utilsService.setVisible('report-base', this.editableElements);
        this.asyncReport = true;
    }

    changePage(pageNumber) {
        this.utilsService.setInvisible('report-base', this.editableElements);
        this.offset = pageNumber - 1;
        this.applyFilter();
    }

    private onClick(e, args) {
        if (
            $(e.target).hasClass('impValue') &&
            this.userService.getCurrentUser().has('ROUTE_SUPPLY_CHAIN_IMP_DETAIL')
        ) {
            const impNumber = this.dataView.getItem(args.row).description;
            const clientGroup = this.urlValue.snapshot.params.clientGroupCode;

            const imp: string[] = [];
            imp.push(impNumber);
            const clients: DropdownOption[] = [];
            clients.push(new DropdownOption(clientGroup, clientGroup));

            this.financeiroFilterState.imps = imp;
            this.financeiroFilterState.client = clients;
            this.financeiroFilterState.startDate = this.startDate;
            this.financeiroFilterState.endDate = this.endDate;
            this.financeiroGroupingState = Grouping.byImp;
            this.addLocalStorageData(impNumber);
            window.open(`./financeiro/resultado/${impNumber}/byImp`);
        }
        if ($(e.target).hasClass('download') && $(e.target).hasClass('ball')) {
            this.selectedImp = this.dataView.getItem(args.row).description;
            this.downloadArquives = this.dataView.getItem(args.row).download;
            const table = e.target.parentElement.parentElement;
            e.preventDefault();
            const cell = this.reportGrid.getCellFromEvent(e);
            const item = this.dataView.getItem(cell.row);
            const row = item.id;
            const col = this.columns[cell.cell];
            $('#contextMenu')
                // .css('position', 'fixed')
                // .css('top', 200)
                // .css('left', 175)
                // .css('visibility', 'visible');
                .show();
        } else {
            $('#contextMenu').hide();
        }
        if ($(e.target.childNodes[2]).hasClass('toggle') || $(e.target).hasClass('toggle')) {
            const item = this.dataView.getItem(args.row);
            if (item) {
                if (!item._collapsed) {
                    item._collapsed = true;
                } else {
                    item._collapsed = false;
                }
                this.dataView.updateItem(item.id, item);
            }
            e.stopImmediatePropagation();
        }
        if ($(e.target).hasClass('slick-cell')) {
            const table = e.target.parentElement.parentElement;
            const item = this.dataView.getItem(args.row);
            let row = args.row;
            let col = args.cell;
            row = item.id;
            col = this.columns[col].id;
        }
    }

    private formatAccountName(row, cell, value, columnDef, dataContext) {
        value = value.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
        const indent = 'indent';
        const spacer = `<span style='display:inline-block;height:1px;width:${15 * dataContext[indent]}px'></span>`;
        const idx = this.dataView.getIdxById(dataContext.id);

        if (this.reportType === 'imp') {
            if (value === 'TOTAL') {
                return `<span class='totalRow'>${value}</span>`;
            } else {
                return `<span class='impValue'>${value}</span>`;
            }
        }
        if (dataContext.alone) {
            return `${spacer} <span class='toggle'></span>&nbsp;${value.bold()}`;
        }
        if (this.rows[idx + 1] && this.rows[idx + 1].indent > this.rows[idx].indent) {
            if (dataContext._collapsed) {
                return `${spacer}  <span class='toggle fa fa-caret-right pointer'></span>&nbsp;&nbsp;${value}`;
            } else {
                return `${spacer} <span class='toggle fa fa-caret-down pointer'></span>&nbsp;&nbsp;${value}`;
            }
        } else {
            return `${spacer}<span style='margin-left: 13px;' class=''></span>&nbsp;${value}`;
        }
    }

    private formatTotalRow(row, cell, value, columnDef, dataContext) {
        if (dataContext.bold) {
            return `<span class='totalRow'>${value}</span>`;
        }
        return value;
    }

    private formatDownload(row, cell, value, columnDef, dataContext) {
        const lengthValue = value.length;
        if (lengthValue === 0) {
            return "<div class='ball'></div>";
        } else {
            return `<div class='ball download'>${lengthValue}</div>`;
        }
    }

    private createColumns(data: any) {
        const cols = [];
        for (let c = 0; c < data.metadata.columns.length; c++) {
            const name =
                `<span title="${data.metadata.columns[c].header}">${data.metadata.columns[c].header}</span>` ||
                '&nbsp;';
            const field = data.metadata.columns[c].field;
            const id = data.metadata.columns[c].id;
            if (c === 0) {
                cols.push({
                    id,
                    name,
                    field,
                    width: this.reportType === 'imp' ? 100 : 400,
                    formatter: this.formatAccountName.bind(this),
                    cssClass: 'pointer',
                });
            } else if (id === 'download') {
                cols.splice(1, 0, {
                    id,
                    name: '<i class="flaticon-download" style="font-size:15px"></i>',
                    width: 40,
                    field,
                    formatter: this.formatDownload.bind(this),
                    cssClass: 'download',
                });
            } else {
                cols.push({
                    id,
                    name,
                    field,
                    formatter: this.formatTotalRow.bind(this),
                });
            }
        }
        return cols;
    }

    private extractRows(data: any, settings) {
        const { nodes } = new NodeVisitor(settings).visit(
            data.results,
            data.metadata.columns,
            this.reportType === 'traders'
        );

        const rows = [];
        const parents = [];
        let index = -1;
        for (const node of nodes) {
            index++;
            const indent = 0;
            const parent = null;
            const description = null;
            rows[index] = node.data;
            rows[index].id = `${node.data.id}-${Math.random()}`;
            if (node.data.description == null) {
                rows[index].description = node.data.id;
            } else {
                rows[index].description = node.data.description;
            }
            if (data.hierarchy === 'balance-sheet') {
                rows[index]._collapsed = false;
            } else {
                rows[index]._collapsed = true;
            }
            rows[index].indent = indent;
            rows[index].parent = parent;
            const children = node.children;
            if (children == null) {
                rows[index].alone = true;
            } else {
                getChildren(children, indent, index);
            }
        }
        function getChildren(children, indentPassed, parent) {
            indentPassed++;
            for (const row of children) {
                index++;
                rows[index] = row.data;
                rows[index].id = `${row.data.id}-${Math.random()}`;
                if (row.data.description == null) {
                    rows[index].description = row.data.id;
                } else {
                    rows[index].description = row.data.description;
                }
                if (data.hierarchy === 'balance-sheet') {
                    rows[index]._collapsed = false;
                    if (indentPassed === 3) {
                        rows[index]._collapsed = true;
                    }
                } else {
                    rows[index]._collapsed = true;
                }
                rows[index].indent = indentPassed;
                rows[index].parent = parent;
                const childParent = index;
                const child = row.children;
                if (child == null) {
                    rows[index].isLeaf = true;
                } else {
                    getChildren(child, indentPassed, childParent);
                }
            }
        }
        return rows;
    }

    private requiredFieldValidator(value) {
        if (value == null || value === undefined || !value.length) {
            return { valid: false, msg: 'This is a required field' };
        } else {
            return { valid: true, msg: null };
        }
    }

    private filter(item) {
        if (item.parent != null) {
            let parent = this.rows[item.parent];
            while (parent) {
                if (parent._collapsed) {
                    return false;
                }
                parent = this.rows[parent.parent];
            }
        }
        return true;
    }

    private updateDateInputs() {
        $('.traderReportContainer #periodFrom-reportTable').datepicker('setDate', this.startDate);
        $('.traderReportContainer #periodTo-reportTable').datepicker('setDate', this.endDate);
    }

    private getData(uuid, settings): Observable<any> {
        let clientGrouping;
        let serviceInfo;
        settings.showLeaseHolder === true ? (clientGrouping = 'leaseHolder') : (clientGrouping = 'group');
        settings.showServiceInfo === true ? (serviceInfo = 'true') : (serviceInfo = 'false');
        return this.http.get(`${environment.endpoints.resultsService}/reports/${uuid.uuid}?clientGrouping=${clientGrouping}&showServiceInfo=${serviceInfo}`)
            .pipe(
                retryWhen((errors) => {
                    return errors.pipe(
                        mergeMap((error: any) => {
                            if (error.status === 507) {
                                return observableOf(error.status).pipe(delay(1000));
                            } else {
                                return observableThrowError(error);
                            }
                        }),
                        take(100),
                        concat(observableThrowError({}))
                    );
                })
            );
    }

    private getUuid(params): Observable<any> {
        return this.http.post(`${environment.endpoints.resultsService}/reports`, params);
    }

    private getReportType(url) {
        const urlSplited = url.split('/');
        return urlSplited[urlSplited.length - 1];
    }

    private getLocalStorageData() {
        this.financeiroFilterState = JSON.parse(localStorage.getItem('financeiro-filterState'));
        this.financeiroGroupingState = JSON.parse(localStorage.getItem('financeiro-groupingState'));
        this.financeiroHierarchyState = JSON.parse(localStorage.getItem('financeiro-hierarchyState'));
    }

    private addLocalStorageData(impNumber) {
        localStorage.setItem(`${impNumber}-financeiro-filterState`, JSON.stringify(this.financeiroFilterState));
        localStorage.setItem(`${impNumber}-financeiro-groupingState`, JSON.stringify(this.financeiroGroupingState));
        localStorage.setItem(`${impNumber}-financeiro-hierarchyState`, JSON.stringify(this.financeiroHierarchyState));
    }

    trackByFn(index: number, item: any) {
        return index;
    }

    private _defineHelpDescription(): object[] {
        if(this.reportType === 'despesas') {
            return [
                {
                    type: 'text',
                    value: 'Relatório de acompanhamento de despesas por Conta Contábeis, apenas de contas consideradas despesas.',
                },
                {
                    type: 'list',
                    list: 'As colunas são divididas por centro de custo',
                },
                {
                    type: 'list',
                    list: 'O relatório poderá ser ordenado de acordo com a necessidade do usuário, no ícone de engrenagem ',
                },
                {
                    type: 'list',
                    list: 'Filtros estão disponíveis nas opções: Período, Empresa, Centro de Custo, Trader, Diretor e Cliente ',
                },
            ]
        }  else {
            return [
                {
                    type: 'text',
                    value: 'Relatório para que os Trades possam acompanhar os valores a receber, no período trimestral.',
                },
                {
                    type: 'text',
                    value: 'Funcionamento da tela de forma hiérarquica, Diretor > Trader.',
                },
                {
                    type: 'list',
                    list: 'O relatório poderá ser ordenado de acordo com a necessidade do usuário, no ícone de engrenagem ',
                },
                {
                    type: 'list',
                    list: 'Filtros estão disponíveis nas opções: Período, Empresa, Centro de Custo, Trader, Diretor e Cliente',
                },
            ]
        }

    }


}
