import { Component, ViewEncapsulation } from '@angular/core';
import { LoginPermissionService } from '#services/_login-permission/login-permission.service';
import { DashComponent } from 'app/theme/pages/default/custom-dashboard/dashComponent.model';
import { DragulaService } from 'ng2-dragula/ng2-dragula';
import { DASHBOARD_COMPONENTS } from './custom-dashboard.constants';
import * as _ from 'lodash';
import { UserCacheService } from '#services/_user/app-user-cache.service';

@Component({
    selector: 'custom-dashboard',
    templateUrl: './custom-dashboard.component.html',
    styleUrls: ['./custom-dashboard.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CustomDashboardComponent {

    public isVisibleCustomDash: boolean = false;
    public selectedComponent: DashComponent = new DashComponent();

    dashboardComponents = _.cloneDeep(DASHBOARD_COMPONENTS);
    defaultDashCustomImg = this.userCacheService.getLanguage() === 'pt-BR' ? 'assets/img/tutos/dashCustom/DashCustomComponent_pt-BR.png' : 'assets/img/tutos/dashCustom/DashCustomComponent_en-US.png';

    constructor(
        public loginPermissionService: LoginPermissionService,
        public userCacheService: UserCacheService,
        private dragula: DragulaService
    ) {
        const bag: any = this.dragula.find('customDash');
        if (bag !== undefined) {
            this.dragula.destroy('customDash');
        }
        this.dragula.setOptions('customDash', {
            revertOnSpill: true,
            direction: 'horizontal',
            moves: (el, container, handle) => {
                return $(handle).is('.component');
            },
        });
    }

    public ngAfterViewInit() {
        if (this.loginPermissionService.getMergedComponentList().length === 0) {
            this.loginPermissionService.loadComponents();
        } else {
            this.loginPermissionService.asyncComponents = false;
        }
        this.loginPermissionService.populateStoredComponents();
    }

    public saveCustomChanges(): void {
        this.clearSelection();
        this.loginPermissionService.saveCustomChanges();
    }

    public selectComponent(component: DashComponent) {
        this.selectedComponent = component;
        this.dashboardComponents.forEach((descriptionComponent) => {
            if (descriptionComponent.component === component.component) {
                this.selectedComponent.description = descriptionComponent?.description || '';
                this.selectedComponent.titleKey = descriptionComponent?.titleKey || '';
            }
        });
    }

    public clearSelection(): void {
        this.selectedComponent = new DashComponent();
    }

    public resizeComponent(component): void {
        component.size === 100 ? component.size = 50 : component.size = 100;
        this.loginPermissionService.changeComponent(component);
    }

    trackByFn(index: number, item: any) {
        return index;
    }

}
