import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { DragulaModule } from 'ng2-dragula/ng2-dragula';
import { NgxPowerBiModule } from 'ngx-powerbi';
import { SharedModule } from 'app/shared/shared.module';
import { LayoutModule } from '../../layouts/layout.module';

import { AutomotiveDetailDailyService } from './report-automotive-daily/detail-daily.service';
import { AutomotiveDailyService } from './report-automotive-daily/report-automotive-daily.service';
import { FollowUpModelService } from './report-followup/report-followup-model/report-followup-model.service';
import { FollowUpStructureService } from './report-followup/report-followup-custom/report-followup-structure.service';
import { ReportService } from './report.service';

import { AutomotiveDailyReportComponent } from './report-automotive-daily/report-automotive-daily.component';
import { DetailAutomotiveDailyReportComponent } from './report-automotive-daily/detail-daily.component';
import { TraderReportComponent } from './report-base/report-base.component';
import { FollowUpReportModelComponent } from './report-followup/report-followup-model/report-followup-model.component';
import { PowerBiComponent } from './power-bi/power-bi.component';
import { ReportStructureComponent } from './report-structure/report-structure.component';

import { AutomotiveDailyReportSlickGrid } from './report-automotive-daily/report-automotive-daily.slickGrid';
import { AutomotiveDetailDailyReportSlickGrid } from './report-automotive-daily/detail-daily.slickGrid';
import { FollowUpReportFormatter } from './report-followup/formatters/report-followup.formatter';
import { FollowUpReportChassiFormatter } from './report-followup/formatters/report-followup.formatter.chassi';
import { FollowUpReportClearenceFormatter } from './report-followup/formatters/report-followup.formatter.clearence';
import { FollowUpReportContainerFormatter } from './report-followup/formatters/report-followup.formatter.container';
import { FollowUpReportDeliveryFormatter } from './report-followup/formatters/report-followup.formatter.delivery';
import { FollowUpReportExchangeFormatter } from './report-followup/formatters/report-followup.formatter.exchange';
import { FollowUpReportImpFormatter } from './report-followup/formatters/report-followup.formatter.imp';
import { FollowUpReportInvoiceFormatter } from './report-followup/formatters/report-followup.formatter.invoice';
import { FollowUpReportLiFormatter } from './report-followup/formatters/report-followup.formatter.li';
import { FollowUpReportNfFormatter } from './report-followup/formatters/report-followup.formatter.nf';
import { FollowUpReportPenaltyFormatter } from './report-followup/formatters/report-followup.formatter.penalty';
import { FollowUpReportSailingFormatter } from './report-followup/formatters/report-followup.formatter.sailing';
import { FollowUpReportSkuFormatter } from './report-followup/formatters/report-followup.formatter.sku';
import { FollowUpReportWarehousesFormatter } from './report-followup/formatters/report-followup.formatter.warehouses';
import { FollowUpIconList } from './report-followup/report-followup-model/report-icon-list';
import { FollowUpReportCustom } from './report-followup/report-followup-custom/report-followup.custom';
import { FollowUpReportSlickGrid } from './report-followup/report-followup.slickGrid';
import { FollowUpDatamartModalComponent } from './report-followup/followup-datamart-modal/followup-datamart-modal.component';
import { FollowupUnifiedCustomizationComponent } from './report-followup/followup-unified-customization/followup-unified-customization.component';
import { SlickTabsModule } from 'app/shared/components/cmx-slick-tabs-modal/cmx-slick-tabs-modal.module';
import { FollowUpUnifiedDatamartModalComponent } from './report-followup/followup-unified-exporters/followup-unified-datamart-modal/followup-unified-datamart-modal.component';
import { FollowUpUnifiedDatamartService } from './report-followup/followup-unified-exporters/followup-unified-datamart-modal/services/followup-unified-datamart.service';
import { FollowUpUnifiedReportComponent } from './report-followup/report-followup-unified.component';

const routes: Routes = [
    {
        path: 'traders',
        component: TraderReportComponent,
    },
    {
        path: 'despesas',
        component: TraderReportComponent,
    },
    {
        path: ':clientGroupCode/imp',
        component: TraderReportComponent,
    },
    {
        path: 'followup',
        component: FollowUpUnifiedReportComponent,
    },
    {
        path: 'followup/:imp',
        component: FollowUpUnifiedReportComponent,
    },
    {
        path: 'followup-imp',
        component: FollowUpUnifiedReportComponent,
    },
    {
        path: 'followup-chassi',
        component: FollowUpUnifiedReportComponent,
    },
    {
        path: 'followup-automotive',
        component: FollowUpUnifiedReportComponent,
    },
    {
        path: 'followup-sku',
        component: FollowUpUnifiedReportComponent,
    },
    {
        path: 'followup-imp/:imp',
        component: FollowUpUnifiedReportComponent,
    },
    // Verificar Remover (?)
    // {
    //     path: 'automotive-daily',
    //     component: AutomotiveDailyReportComponent,
    // },
    {
        path: 'power-bi/:report',
        component: PowerBiComponent
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        FormsModule,
        LayoutModule,
        DragulaModule,
        SharedModule,
        NgxPowerBiModule,
        SlickTabsModule
    ],
    declarations: [
        TraderReportComponent,
        FollowUpUnifiedReportComponent,
        PowerBiComponent,

        ReportStructureComponent,
        FollowUpReportCustom,
        AutomotiveDailyReportComponent,
        DetailAutomotiveDailyReportComponent,
        FollowUpReportModelComponent,
        FollowUpDatamartModalComponent,
        FollowupUnifiedCustomizationComponent,
        FollowUpUnifiedDatamartModalComponent,
    ],
    providers: [
        FollowUpReportImpFormatter,
        FollowUpReportChassiFormatter,
        FollowUpReportSkuFormatter,
        FollowUpReportExchangeFormatter,
        FollowUpReportInvoiceFormatter,
        FollowUpReportLiFormatter,
        FollowUpReportSailingFormatter,
        FollowUpReportClearenceFormatter,
        FollowUpReportPenaltyFormatter,
        FollowUpReportContainerFormatter,
        FollowUpReportDeliveryFormatter,
        FollowUpReportNfFormatter,
        FollowUpReportWarehousesFormatter,
        FollowUpReportFormatter,
        FollowUpReportSlickGrid,
        FollowUpReportCustom,
        AutomotiveDailyReportSlickGrid,
        AutomotiveDetailDailyReportSlickGrid,
        AutomotiveDailyService,
        AutomotiveDetailDailyService,
        FollowUpModelService,
        FollowUpIconList,
        FollowUpStructureService,
        ReportService,
        FollowUpUnifiedDatamartService,
    ],
})
export class OldReportsModule {}
