<div *ngIf="asyncDetailCosts">
    <loader></loader>
</div>

<div class="costs-container">

    <div class="extra-tabs-container"
        [style.left]="filterState.getAutomotiveMode() === 'Ligado' ? '410px' : '385px'">
        <cmx-switch
            [options]="[{ name: 'c/ U.C' }, { name: 's/ U.C' }]"
            [selected]="filterState.getShowUc() ? 'c/ U.C' : 's/ U.C'"
            (onSelect)="toggleUcSwitch($event)"
        ></cmx-switch>
        <cmx-switch
            *ngIf="filterState.getContext() === 'imp' || filterState.getAutomotiveMode() === 'Ligado'"
            [options]="[{ name: 'c/ Duplicatas' }, { name: 's/ Duplicatas' }]"
            [selected]="filterState.getShowDuplicatas() ? 'c/ Duplicatas' : 's/ Duplicatas'"
            (onSelect)="toggleDuplicatasSwitch($event)"
        ></cmx-switch>
    </div>

    <costs-filters
        (onLoadCost)="loadAnaliticCost(filterState.getAutomotiveMode(), null)"
    ></costs-filters>

    <ng2-completer
        id="costs-detailed-completer"
        style="width: 200px;
            position: fixed;
            top: 80px;
            right: 316px;
            display: block;"
        [dataService]="completerColumnsData"
        [minSearchLength]="2"
        [placeholder]="'Localizar Coluna'"
        [textSearching]="'common::strings.loading' | translate"
        [textNoResults]="'Coluna não encontrada'"
        [overrideSuggested]="true"
        [clearUnselected]="true"
        [(ngModel)]="completerSelectedValue"
        [disableInput]="asyncDetailCosts"
        (selected)="selectColumn($event)"
    ></ng2-completer>

    <cmx-custom-grid
        *ngIf="rows"
        id="detail-costs-grid"
        [style.visibility]="rows?.length && !asyncDetailCosts ? 'visible' : 'hidden'"
        [loading]="asyncDetailCosts"
        [columns]="columns"
        [rows]="rows"
        [options]="gridOptions"
        [styles]="{ height: 'calc(100vh - 300px)' }"
        (onCellClick)="onCellClick($event)"
    ></cmx-custom-grid>
</div>

<cmx-slick-tabs-modal
    *ngIf="detailDinamicDataOpen"
    id="costs-detailed-slick-modal"
    [tabs]="detailCostsTabs"
    [dinamicTabs]="true"
    [contentPadding]="'50px 20px 20px 20px'"
    [hidden]="!selectedGrid"
    (onDinamicClick)="onDinamicClick($event)"
    (onClose)="closeSlickModal()">
</cmx-slick-tabs-modal>

<cmx-custom-dialog
    [id]="'detail-costs-custom-dialog'"
    [title]="'IMP ' + selectedImp + ' • ' + selectedModalData?.title"
    [internalFade]="false"
    [closeButton]="true"
    [overlay]="true"
    [widthModal]="widthModal"
    (modal-closed)="closeModal()">
    <ng-container cmx-custom-dialog-body>
        <div class="modal-container" *ngIf="gridModalMode">
            <cmx-custom-grid
                id="detail-costs-modal-grid"
                [style.visibility]="modalRows?.length ? 'visible' : 'hidden'"
                [columns]="selectedModalData?.columns"
                [rows]="modalRows"
                [options]="{ forceFitColumns: true, defaultColumnWidth: 100 }"
                [styles]="{ margin: 0, height: 'calc(100vh - 205px)' }"
            ></cmx-custom-grid>
        </div>
        <div class="modal-container" *ngIf="!gridModalMode">
            <div *ngFor="let uc of modalRows; let i = index; trackBy: trackByFn" class="valueContainer">
                <div class="skuContainer">
                    <span class="sku">{{ uc?.skuCode }}</span>
                    <span class="value">{{ uc?.skuCostValue | currencyType }}</span>
                </div>
                <div class="ucContainer">
                    <span class="uc">x{{ uc?.unitsSold }} U.C</span>
                    <span class="value">{{ uc?.skuCostValueByUnit | currencyType }}</span>
                </div>
                <hr />
            </div>
        </div>
    </ng-container>
</cmx-custom-dialog>

<div class="paginationContent" *ngIf="pages.length > 1"
    [style.visibility]="asyncDetailCosts ? 'hidden' : 'visible'">
    <pagination [numberOfPages]="pages.length" [queryId]="queryId" (onPageChange)="changePage($event)"></pagination>
    <div *ngIf="!asyncDetailCosts" id="totalChassis">
        <img *ngIf="automotivefilterMode === 'Ligado'" width="30px" alt=""
            src="assets/img/icons/carModel-checked.png"
        />
        <span *ngIf="automotivefilterMode === 'Ligado'" class="title">Total de Chassis:</span>
        <em *ngIf="automotivefilterMode === 'Desligado'" class="flaticon-transport"
            width="35px" style="color: #215aa0"></em>
        <span *ngIf="automotivefilterMode === 'Desligado'" class="title"
            >Total de {{ costsService.currentParams?.context === 'sku' ? 'SKUs' : 'IMPs' }}:</span
        >
        <span class="value">{{ totalItems }}</span>
    </div>
</div>

<ul id="contextMenu">
    <i class="fa fa-close pointer" (click)="closeContextMenu()"></i>
    <b style="cursor: default">Download de {{ selectedImp }}:</b>
    <div class="list">
        <li *ngFor="let archive of downloadArchives; trackBy: trackByFn" (click)="export('xls', archive)">{{
            archive
        }}</li>
    </div>
    <span class="all" (click)="export('zip', 'all')">Baixar Todos</span>
</ul>

<cmx-datamart-preferences
    *ngIf="costsService.datamartFilters.length && costsService.currentUser.has('API_DATAMART') && !asyncDetailCosts"
    serviceId="expenses-service"
    generatorId="expenses-detail"
    datamartName="Custos"
    modalTitle="Data Mart - Custos"
    hideEmail="true"
    [subItensData]="options.modelList"
    [selectedSubItem]="selectedModel"
    [subItensSettings]="dropdownSettings.fupModels"
    [filters]="costsService.datamartFilters"
    [filterState]="filterState"
    [linkOptions]="linkOptions"
    [helpTitle]="'Data Mart - Custos'"
    [helpVideoSrc]="'assets/videos/icone-informativo/datamartCustos.mp4'"
    [helpVideoName]="'Data Mart - Custos'"
    [helpDescription]="helpDescription"
    (onLinkOptionsChange)="onLinkOptionsChange($event)"
></cmx-datamart-preferences>

<costs-customization id="cmx-costs-customization"></costs-customization>
