import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { SharedModule } from 'app/shared/shared.module';
import { DragulaModule } from 'ng2-dragula';
import { LayoutModule } from '../../../layouts/layout.module';

import { CustomDashboardComponent } from './custom-dashboard.component';
import { CustomWidgetsComponent } from '../custom-widgets/custom-widgets.component';
import { CustomWidgetsService } from '../custom-widgets/custom-widget.service';
import { TranslateModule } from '@ngx-translate/core';

const routes: Routes = [
    { path: '', component: CustomDashboardComponent },
    { path: 'widget', component: CustomWidgetsComponent },
];

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule.forChild(routes),
        FormsModule,
        ReactiveFormsModule,
        LayoutModule,
        DragulaModule,
        SharedModule,
    ],
    declarations: [
        CustomDashboardComponent,
        CustomWidgetsComponent,
    ],
    exports: [
        CustomDashboardComponent,
        CustomWidgetsComponent,
    ],
    providers: [
        CustomWidgetsService,
    ],
})
export class CustomDashboardModule {

}
