import { IGridColumn } from "app/shared/components/cmx-custom-grid/interfaces";

export const PO_DETAILS_SLICK_COLUMNS: IGridColumn[] = [{
    field: 'imp',
    name: 'IMP',
    formatterType: 'badge',
    headerCssClass: 'grid-header align-center',
    cssClass: 'grid-cell align-center',
    width: 100,
}, {
    field: 'clientReferences',
    name: 'Referência',
    formatterType: 'multiple',
    formatterData: {
        label: 'Várias',
    },
    width: 100,
}, {
    field: 'transportTypeDescription',
    name: 'Modal',
    formatterType: 'icon',
    formatterData: {
        icons: {
            aereo: 'local_airport',
            rodoviario: 'local_shipping',
            maritimo: 'directions_boat',
        },
    },
    headerCssClass: 'grid-header align-center',
    cssClass: 'grid-cell align-center',
    width: _getWidth('transportTypeDescription'),
}, {
    field: 'vendorInvoice',
    name: 'Invoice',
    formatterType: 'multiple',
    formatterData: {
        label: 'Várias',
        attributeValue: 'invoiceNumber'
    },
    width: 100,
}, {
    field: 'vendorInvoiceAmount',
    name: 'Valor Invoice',
    formatterType: 'currency',
    formatterData: {
        locale: 'en-US',
        currency: 'USD',
        digits: 2,
    },
    headerCssClass: 'grid-header align-right',
    cssClass: 'grid-cell align-right',
    width: 100,
}, {
    field: 'status',
    name: 'Status',
    formatterType: 'status',
    headerCssClass: 'grid-header align-center',
    cssClass: 'grid-cell align-center',
    width: _getWidth('status'),
}, {
    field: 'shippingDate',
    name: 'Data Embarque',
    formatterType: 'date',
    headerCssClass: 'grid-header align-center',
    cssClass: 'grid-cell align-center',
    width: 100,
}, {
    field: 'eta',
    name: 'ETA',
    formatterType: 'date',
    headerCssClass: 'grid-header align-center',
    cssClass: 'grid-cell align-center',
    width: 100,
}, {
    field: 'clientInvoiceAmount',
    name: 'Valor NF',
    formatterType: 'currency',
    headerCssClass: 'grid-header align-right',
    cssClass: 'grid-cell align-right',
    width: 100,
}, {
    field: 'diRegistrationNumber',
    name: 'Registro DI',
    width: 100,
}, {
    field: 'diDate',
    name: 'Data Registro DI',
    formatterType: 'date',
    headerCssClass: 'grid-header align-center',
    cssClass: 'grid-cell align-center',
    width: 100,
}, {
    field: 'diChannel',
    name: 'Canal',
    formatterType: 'round',
    headerCssClass: 'grid-header align-center',
    cssClass: 'grid-cell align-center',
    width: _getWidth('diChannel'),
}]

function _getWidth(field): number {
    const screenWidth = $(window).width();
    if (field === 'status' || field === 'transportTypeDescription' || field === 'diChannel') {
        return screenWidth > 1419 ? 50 : 100;
    } else {
        return 100;
    }
    
}
