import { Component, ElementRef, HostListener, Renderer2, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import * as _ from 'lodash';
import moment from 'moment';
import { environment } from '#environment';

import { ChannelFilter } from './channel.filter';
import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { IGridColumn, IGridRow } from 'app/shared/components/cmx-custom-grid/interfaces';
import { CHANNEL_AUTOMOTIVE_COLUMNS } from './constants/channel-automotive-columns.constants';
import { CHANNEL_COLUMNS, CHANNEL_NON_PAGINATION_STYLES, CHANNEL_PAGINATION_STYLES } from './constants/channel-columns.constants';

import { UserService } from '../../../../auth/_services/user.service';
import { UserPermissionBlockerService } from '#services/_user/userPermissionBlocker.service';
import { UtilsService } from '#services/_utils/utils.service';
import { FilterStateService } from '#services/_filters/filter-state.service';
import { ChannelService } from './channel.service';
import { CmxDropdownService } from 'app/shared/components/cmx-dropdown/cmx-dropdown.service';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { DomainService } from '#services/_domain/domain.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';

@Component({
    selector: 'channel-chart',
    templateUrl: './channel.component.html',
    styleUrls: ['../charts.scss', './channel.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [ChannelService]
})
export class ChannelComponent {

    currentUser = this._userService.getCurrentUser();
    currentUser$ = this._userService.currentUser$;
    filterState: ChannelFilter = this._filterStateService.getChannelFilter();
    subscription: Subscription = null;
    translateSubscription: Subscription = null;
    loadingTranslate: boolean = false;
    channelLoading: boolean = true;
    initLoading: boolean = true;
    showModal: boolean = false;
    channelStatusOpen: boolean = false;
    total: number;

    chartSubscription;
    chartData;
    green;
    yellow;
    red;
    grey;

    resultValues = [{
        code: 'green',
        value: (0).toFixed(2),
        name: 0,
        color: '#0ba100',
    }, {
        code: 'yellow',
        value: (0).toFixed(2),
        name: 0,
        color: '#d8a600'
    }, {
        code: 'red',
        value: (0).toFixed(2),
        name: 0,
        color: '#ff0000'
    }, {
        code: 'grey',
        value: (0).toFixed(2),
        name: 0,
        color: '#495057',
        valueTotal: '0 IMPs'
    }];

    options: {
        clients: DropdownOption[];
        urfs: DropdownOption[];
        typeData: DropdownOption[];
    } = {
        clients: this._cmxDropdownService.toDropdownItems(this._userCacheService.getClientGroups()),
        urfs: [],
        typeData: this.typeDataDropdownOptions
    };

    translatedPeriodOptions = this.periodOptions;
    selectedColors = [];
    dataType = [];

    gridOptions = { forceFitColumns: true, rowHover: !this.verifyAutomotiveMode(this.currentUser) };
    gridStyles = _.cloneDeep(CHANNEL_PAGINATION_STYLES);
    gridColumns: IGridColumn[] = [];
    gridRows: IGridRow[] = [];
    gridTotal = 0;
    gridLoading: boolean = false;
    queryId = Math.random();
    pages = [];

    @HostListener('click') onSetZIndex() {
        this.renderer2.setStyle(this.el.nativeElement, 'z-index', this.utilsService.autoIncrementValue);
    }

    get helpDescription(): object[] {
        return [
            {
                title: this._translateService.instant('dashboard::channelStatus.helpTitle1'),
                type: 'text', hasLineAfter: true,
                value: this._translateService.instant('dashboard::channelStatus.helpDescription1')
            },
            {
                title: this._translateService.instant('dashboard::channelStatus.helpTitle2'),
                type: 'text',
                value: this._translateService.instant('dashboard::channelStatus.helpDescription2')
            }
        ];
    }

    get periodOptions() {
        return [
            {
                code: 'CURRENT_YEAR',
                name: this._translateService.instant('common::strings.currentYear')
            },
            {
                code: 'LAST_12_MONTHS',
                name: this._translateService.instant('common::strings.last12Months')
            },
            {
                code: 'PREVIOUS_MONTH',
                name: this._translateService.instant('common::strings.previousMonth')
            },
            {
                code: 'CURRENT_MONTH',
                name: this._translateService.instant('common::strings.currentMonth')
            }
        ];
    }

    get dropdownSettings() {
        return {
            typeData: {
                enableCheckAll: false,
                singleSelection: true,
                text: this._translateService.instant('dashboard::channelStatus.registration'),
                enableSearchFilter: false,
                disabled: false,
                preventEmpty: false,
                translate: true
            },
            clientGroups: {
                text: this._translateService.instant('common::strings.clientsGroup'),
                translate: true
            },
            urfs: {
                enableCheckAll: true,
                singleSelection: false,
                text: this._translateService.instant('dashboard::channelStatus.urf'),
                badgeShowLimit: 1,
                translate: true
            }
        };
    }

    get typeDataDropdownOptions() {
        return [
            { id: 'di_register', itemName: this._translateService.instant('dashboard::channelStatus.diRegister') },
            { id: 'da_register', itemName: this._translateService.instant('dashboard::channelStatus.daRegister') },
            { id: 'di_clearance', itemName: this._translateService.instant('dashboard::channelStatus.diClearance') },
            { id: 'da_clearance', itemName: this._translateService.instant('dashboard::channelStatus.daClearance') },
        ];
    }

    constructor(
        public router: Router,
        private http: HttpClient,
        private _userService: UserService,
        private el: ElementRef,
        private permissionBlocker: UserPermissionBlockerService,
        private utilsService: UtilsService,
        private renderer2: Renderer2,
        private _filterStateService: FilterStateService,
        private _channelService: ChannelService,
        private _cmxDropdownService: CmxDropdownService,
        private _userCacheService: UserCacheService,
        private _domainService: DomainService,
        private _translateService: TranslateService,
        private _userPreferencesService: UserPreferencesService,
        private _utilsService: UtilsService,
    ) {
        this._domainService.getUrfs().subscribe((urfList) => {this.options.urfs = this._cmxDropdownService.toDropdownItems(urfList, true)});
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.chartSubscription) {
            this.chartSubscription.unsubscribe();
        }
        if (this.translateSubscription) {
            this.translateSubscription.unsubscribe();
        }
    }

    ngAfterViewInit() {
        this.loadChannel();
        this.onChanges();
    }

    onChanges() {
        this.translateSubscription = this._userPreferencesService.onLanguageOrPreferenceChange(
            () => {
                this.translatedPeriodOptions = this.periodOptions;
                this._getTypeDataOptions();
                this.updateTranslateGridColumns()
            }, (typeChange) => {}
        );
    }

    verifyAutomotiveMode(user) {
        return (user.has('AUTOMOTIVE_USER') || (user.has('ROUTE_AUTOMOTIVE') && this.filterState.getAutomotiveMode() === 'Ligado'));
    }

    toggleFilterMode() {
        if ($('logistic-costs-chart .filterMode').hasClass('clicked')) {
            this.filterState.setAutomotiveMode('Desligado');
            $('logistic-costs-chart .filterMode').removeClass('clicked');
        } else {
            this.filterState.setAutomotiveMode('Ligado');
            $('logistic-costs-chart .filterMode').addClass('clicked');
        }
        this.loadChannel();
    }

    changeDateParam(period) {
        this.channelLoading = true;
        this.filterState.setOffset(0);
        this.filterState.setStartDate(moment().format('MM/YYYY'));
        this.filterState.setEndDate(moment().format('MM/YYYY'));

        if (period === 99) {
            this.filterState.setStartDate(moment().format('01/YYYY'));
        } else if (period === 98) {
            this.filterState.setStartDate(moment().add(-1, 'M').format('MM/YYYY'));
            this.filterState.setEndDate(moment().add(-1, 'M').format('MM/YYYY'));
        } else {
            this.filterState.setStartDate(moment().add(-period, 'M').format('MM/YYYY'));
        }

        this.loadChannel();
    }

    selectChannelColor(data) {
        if (data.selected) {
            this.selectedColors.push(data.code);
        } else {
            this.selectedColors = this.selectedColors.filter((color) => color !== data.code);
        }

        this.filterState.setOffset(0);
        this._updateTabelView();
    }

    changePage(pageNumber) {
        this.filterState.setOffset(pageNumber - 1);
        this.loadChannelStatus();
    }

    loadChannelStatus(newPeriod?) {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        this.gridLoading = true;
        let startDate = this.filterState.getStartDate() ? moment(this.filterState.getStartDate(), 'MM/YYYY').format('YYYY-MM') : null;
        let endDate = this.filterState.getEndDate() ? moment(this.filterState.getEndDate(), 'MM/YYYY').format('YYYY-MM') : null;

        this.subscription = this._getDetailsByChannel(this.verifyAutomotiveMode(this.currentUser), startDate, endDate).subscribe(
            (imps) => {
                if (!this.verifyAutomotiveMode(this.currentUser)) {
                    this.gridTotal = imps.total || 0;
                    this.pages = Array.apply(null, { length: Math.ceil(this.gridTotal / 50) }).map(Number.call, Number);
                    this.gridRows = imps.impsChannel;
                } else {
                    this.gridTotal = imps.totalElements || 0;
                    this.pages = Array.apply(null, { length: Math.ceil(this.gridTotal / 50) }).map(Number.call, Number);
                    this.gridRows = imps.content;
                }
                this.updateTranslateGridColumns();
                this.gridStyles = this.pages.length > 1 ?  CHANNEL_PAGINATION_STYLES : CHANNEL_NON_PAGINATION_STYLES;
                this.gridLoading = false;
            }, (error) => {
                this.gridRows = [];
                this.gridLoading = false;
                this.utilsService.errorHandler(error, this._translateService.instant('menu::performance.title::channelStatus'));
            }
        );
    }

    updateTranslateGridColumns() {
        if (!this.verifyAutomotiveMode(this.currentUser)) {
            this.gridColumns = this._utilsService.translateListField(CHANNEL_COLUMNS, 'name', false);
        } else {
            this.gridColumns = this._utilsService.translateListField(CHANNEL_AUTOMOTIVE_COLUMNS, 'name', false);
        }
    }

    loadChannel() {
        if (this.permissionBlocker.permissionValidator(this.el.nativeElement.tagName)) {
            if (this.chartSubscription) {
                this.chartSubscription.unsubscribe();
            }
            this.channelLoading = true;
            const newStart = moment(this.filterState.getStartDate(), 'MM/YYYY').format('YYYY-MM');
            const newEnd = moment(this.filterState.getEndDate(), 'MM/YYYY').format('YYYY-MM');
            this.chartSubscription = this._getChannel(newStart, newEnd).subscribe(
                (response) => {
                    this.chartData = response;
                    this._populateChannel(response)
                    this._valueLegend(response)
                    this.resultValues = _.cloneDeep(this.resultValues);
                    this.channelLoading = false;
                    this.initLoading = false;
                }, (error) => {
                    this.chartData = undefined;
                    this.channelLoading = false;
                    this.initLoading = false;
                    this.utilsService.errorHandler(error, this._translateService.instant('menu::performance.title::channelStatus'));
                }
            );
        }
    }

    datesChanged(dates) {
        this.filterState.setStartDate(dates.startDate);
        this.filterState.setEndDate(dates.endDate);
    }

    onSelectedPeriod({ selected }): void {
        let periodCod = selected[0].code;
        this.filterState.setPeriod(_.cloneDeep(selected));
        if (periodCod === "CURRENT_YEAR") {
            this.changeDateParam(99);
        }
        if (periodCod === "TWELVE_MONTHS") {
            this.changeDateParam(11);
        }
        if (periodCod === "LAST_MONTH") {
            this.changeDateParam(98);
        }
        if (periodCod === "CURRENT_MONTH") {
            this.changeDateParam(0);
        }
        this.loadChannelStatus();
    }

    onCellClick({ item }): void {
        if(!this.verifyAutomotiveMode(this.currentUser)) {
            if(item.imp && item.companyCode && this.currentUser.has('ROUTE_SUPPLY_CHAIN_IMP_DETAIL')) {
                if ( navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1 || window.navigator.userAgent.indexOf('Edge') > -1 ) {
                    this.router.navigate([`./supply-chain/importacao/${item.companyCode}/${item.imp}`]);
                } else {
                    window.open(`./supply-chain/importacao/${item.companyCode}/${item.imp}`);
                }
            } else {
                this.router.navigate(['404']);
            }
        }
    }

    private _getTypeDataOptions() {
        this.options.typeData = this.typeDataDropdownOptions;
        this._verifySelectedTypeData();
    }

    // Get Imps By Channel
    private _getDetailsByChannel(type, start, end): Observable<any> {
        const newStart = moment(start, 'YYYY-MM').format('YYYY-MM-01');
        const newEnd = moment(end, 'YYYY-MM').add(1, 'M').add(-1, 'days').format('YYYY-MM-DD');
        const newStartMonth = moment(start, 'YYYY-MM').format('YYYY-MM');
        const newEndMonth = moment(end, 'YYYY-MM').add(1, 'M').add(-1, 'days').format('YYYY-MM');
        let dataType;
        let request = _.cloneDeep(this.filterState.getBaseRequest());

        request.params = request.params.append('channelVisionType', 'history');

        if (!type) {
            if (this.selectedColors.length === 0 || this.selectedColors.length === 4) {
                dataType = ['all'];
            } else {
                dataType = this.selectedColors;
            }

            request.params = request.params.append('startDate', newStart);
            request.params = request.params.append('endDate', newEnd);
            request.params = request.params.append('offset', `${this.filterState.getOffset() * 50}`);
            request.params = request.params.append('limit', `${this.filterState.getLimit()}`);

            return this.http.get(`${environment.endpoints.importationMongoDBService}/imps/imps-by-channel/${dataType}`, request );
        } else {
            request.params = request.params.append('startMonth', newStartMonth);
            request.params = request.params.append('endMonth', newEndMonth);
            request.params = request.params.append('offset', `${this.filterState.getOffset() * 50}`);
            request.params = request.params.append('limit', `${this.filterState.getLimit()}`);

            if (this.selectedColors.length === 0 || this.selectedColors.length === 4) {
                request.params = request.params.append('channels', 'all');
            } else {
                dataType = this.selectedColors;
                request.params = request.params.append('channels', dataType);
            }

            return this.http.get(`${environment.endpoints.importationMongoDBService}/chassis/stats/channel/details`, request );
        }
    }

    private _getChannel(start, end): Observable<any> {
        let request = _.cloneDeep(this.filterState.getBaseRequest());
        if (this.verifyAutomotiveMode(this.currentUser)) {
            return this._channelService.getChannelAutomotive(start, end, request);
        } else {
            return this._channelService.getChannel(start, end, request);
        }
    }

    private _valueLegend(value) {
        const total = Math.max(this.green + this.yellow + this.red + this.grey, 0);
        this.total = total;
        const valueGreen = value.green;
        const valueYellow = value.yellow;
        const valueRed = value.red;
        const valueGrey = value.grey;
        const imps = (this.verifyAutomotiveMode(this.currentUser) ? ' Chassi' : ' IMP') + (total === 1 ? '' : 's');

        this.resultValues[0].name = valueGreen;
        this.resultValues[1].name = valueYellow;
        this.resultValues[2].name = valueRed;
        this.resultValues[3].name = valueGrey;
        this.resultValues[3].valueTotal = `${total} ${imps}`
    }

    private _populateChannel(results) {
        this.green = results.green;
        this.yellow = results.yellow;
        this.red = results.red;
        this.grey = results.grey;

        const total = Math.max(this.green + this.yellow + this.red + this.grey, 0);
        this.total = total;
        const valueGreen = (total ===  0 ? 0 : (results.green * 100) / total).toFixed(2);
        const valueYellow = (total ===  0 ? 0 : (results.yellow * 100) / total).toFixed(2);
        const valueRed = (total ===  0 ? 0 : (results.red * 100) / total).toFixed(2);
        const valueGrey = (total ===  0 ? 0 : (results.grey * 100) / total).toFixed(2);

        this.resultValues[0].value = valueGreen;
        if (!parseFloat(valueGreen)) { this.selectedColors = this.selectedColors.filter((color) => color !== 'green'); }
        this.resultValues[1].value = valueYellow;
        if (!parseFloat(valueYellow)) { this.selectedColors = this.selectedColors.filter((color) => color !== 'yellow'); }
        this.resultValues[2].value = valueRed;
        if (!parseFloat(valueRed)) { this.selectedColors = this.selectedColors.filter((color) => color !== 'red'); }
        this.resultValues[3].value = valueGrey;
        if (!parseFloat(valueGrey)) { this.selectedColors = this.selectedColors.filter((color) => color !== 'grey'); }
        this._updateTabelView();
    }

    private _updateTabelView() {
        if (this.selectedColors?.length > 0) {
            this.loadChannelStatus();
             setTimeout(() => { this.showModal = true; }, 100);
        } else {
            this.showModal = false;
        }
    }

    private _verifySelectedTypeData() {
        const selectedTypeData = this.options.typeData?.filter(typeData => this.filterState.getTypeData().some(item => item.id === typeData.id));
        this.filterState.setTypeData(selectedTypeData);
    }
}
