<cmx-page-wrapper
    layout="bigHeader"
    showDevInfo="ALFA"
    [titleComponent]="titleComponent"
    [bottomButtonList]="bottomButtonList"
    (onBottomButtonClick)="onBottomButtonClick($event)">
    <ng-container cmx-page-wrapper-body>
        <div class="custom-widgets-container">
            <div *ngIf="initialForm && formStep === 'initial'" class="custom-widgets-form-container">
                <form [formGroup]="initialForm" novalidate="novalidade" autocomplete="off" id="responsibles-subsidiary-form">
                    <!-- Etapas 1, 2.1 e 2.2 -->
                    <div class="step-container">
                        <cmx-splitter-line title="Etapa 1 (Info Inicial)"></cmx-splitter-line>
                        <div class="step-content">
                            <div class="p-field">
                                <label>Nome do Widget<span class="required">*</span></label>
                                <input type="text" pInputText formControlName="widgetName" />
                            </div>
                            <div class="p-field">
                                <label>Tipo do Gráfico<span class="required">*</span></label>
                                <div class="check-container">
                                    <input id="BAR_LINE" class="item" formControlName="graphType" type="radio" value="BAR_LINE">
                                    <label for="BAR_LINE">Barras/Linhas</label>
                                </div>
                                <div class="check-container">
                                    <input id="PIZZA" class="item" formControlName="graphType" type="radio" value="PIZZA" disabled>
                                    <label for="PIZZA">Pizza</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="step-container right">
                        <cmx-splitter-line title="Etapa 2.1 (Eixo X)"></cmx-splitter-line>
                        <div class="step-content">
                            <div class="p-field">
                                <label>Nome do Eixo X</label>
                                <input type="text" pInputText formControlName="xAxisName"
                                    (keyup)="customWidgetsService.updatePreviewChart('xAxisName', initialForm)"/>
                            </div>
                            <div *ngIf="!customWidgetsService.getDropdownValue(initialForm, 'yType', 'id')" class="p-field">
                                <label>Tipo do Eixo X<span class="required">*</span></label>
                                <cmx-dropdown
                                    id="xType"
                                    size="md"
                                    formControlName="xType"
                                    [required]="true"
                                    [data]="dropdownXAxisTypes"
                                    [settings]="dropdownSettings"
                                    (onChange)="onAxisTypeChanges('x'); customWidgetsService.updatePreviewChart('xAxisName', initialForm)">
                                </cmx-dropdown>
                            </div>
                            <div *ngIf="verifyAxisPeriodType('x')" class="p-field">
                                <label>Campo Período<span class="required">*</span></label>
                                <cmx-dropdown
                                    id="xPeriodField"
                                    size="md"
                                    formControlName="xPeriodField"
                                    [required]="true"
                                    [data]="dropdownDataPathList"
                                    [settings]="dropdownSettings">
                                </cmx-dropdown>
                            </div>
                            <div *ngIf="customWidgetsService.getDropdownValue(initialForm, 'xType', 'id')" class="p-field">
                                <label>Período Default<span class="required">*</span></label>
                                <cmx-dropdown
                                    id="xPeriod"
                                    size="md"
                                    formControlName="xPeriod"
                                    [required]="true"
                                    [data]="dropdownPeriodDefault"
                                    [settings]="dropdownSettings"
                                    (onChange)="customWidgetsService.updatePreviewChart('data', initialForm)">
                                </cmx-dropdown>
                            </div>
                            <div *ngIf="customWidgetsService.getDropdownValue(initialForm, 'xType', 'id')" class="p-field">
                                <label>Agrupador</label>
                                <cmx-dropdown
                                    id="xSecondaryType"
                                    size="md"
                                    formControlName="xSecondaryType"
                                    [data]="dropdownSecondaryAxisTypes"
                                    [settings]="dropdownSettings"
                                    (onChange)="customWidgetsService.updatePreviewChart('xAxisName', initialForm)">
                                </cmx-dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="step-container right">
                        <cmx-splitter-line title="Etapa 2.2 (Eixo Y)"></cmx-splitter-line>
                        <div class="step-content">
                            <div class="p-field">
                                <label>Nome do Eixo Y</label>
                                <input type="text" pInputText formControlName="yAxisName" 
                                    (keyup)="customWidgetsService.updatePreviewChart('yAxisName', initialForm)"/>
                            </div>
                            <div *ngIf="!customWidgetsService.getDropdownValue(initialForm, 'xType', 'id')" class="p-field">
                                <label>Tipo do Eixo Y<span class="required">*</span></label>
                                <cmx-dropdown
                                    id="yType"
                                    size="md"
                                    formControlName="yType"
                                    [required]="true"
                                    [data]="dropdownYAxisTypes"
                                    [settings]="dropdownSettings"
                                    (onChange)="onAxisTypeChanges('y'); customWidgetsService.updatePreviewChart('yAxisName', initialForm)">
                                </cmx-dropdown>
                            </div>
                            <div *ngIf="verifyAxisPeriodType('y')" class="p-field">
                                <label>Campo Período<span class="required">*</span></label>
                                <cmx-dropdown
                                    id="yPeriodField"
                                    size="md"
                                    formControlName="yPeriodField"
                                    [required]="true"
                                    [data]="dropdownDataPathList"
                                    [settings]="dropdownSettings">
                                </cmx-dropdown>
                            </div>
                            <div *ngIf="customWidgetsService.getDropdownValue(initialForm, 'yType', 'id')" class="p-field">
                                <label>Período Default<span class="required">*</span></label>
                                <cmx-dropdown
                                    id="yPeriod"
                                    size="md"
                                    formControlName="yPeriod"
                                    [required]="true"
                                    [data]="dropdownPeriodDefault"
                                    [settings]="dropdownSettings"
                                    (onChange)="customWidgetsService.updatePreviewChart('data', initialForm)">
                                </cmx-dropdown>
                            </div>
                            <div *ngIf="customWidgetsService.getDropdownValue(initialForm, 'yType', 'id')" class="p-field">
                                <label>Agrupador</label>
                                <cmx-dropdown
                                    id="ySecondaryType"
                                    size="md"
                                    formControlName="ySecondaryType"
                                    [data]="dropdownSecondaryAxisTypes"
                                    [settings]="dropdownSettings"
                                    (onChange)="customWidgetsService.updatePreviewChart('yAxisName', initialForm)">
                                </cmx-dropdown>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <!-- Etapa 3 -->
            <div *ngIf="dataForm && formStep === 'data'" class="custom-widgets-form-container">
                <form [formGroup]="dataForm" novalidate="novalidade" autocomplete="off" id="responsibles-subsidiary-form">
                    <div class="step-container">
                        <cmx-splitter-line title="Etapa 3 (Dados)"></cmx-splitter-line>
                        <div class="step-content row">
                            <div class="p-field col-md-3">
                                <label>Nome Campo<span class="required">*</span></label>
                                <input type="text" pInputText formControlName="dataName" />
                            </div>
                            <div class="p-field col-md-1">
                                <label>Cor<span class="required">*</span></label>
                                <input type="color" formControlName="dataColor" style="width: 30px; background-color: white; border: 1px solid #ced4da" />
                            </div>
                            <div class="p-field col-md-2">
                                <label>Prefixo do Valor</label>
                                <input type="text" pInputText formControlName="prefix" />
                            </div>
                            <div class="p-field col-md-2">
                                <label>Sufixo do Valor</label>
                                <input type="text" pInputText formControlName="suffix" />
                            </div>
                            <div class="p-field col-md-3">
                                <label>Tipo do Campo<span class="required">*</span></label>
                                <div class="row" style="margin: 0">
                                    <div class="check-container" style="margin-right: 5px">
                                        <input id="BAR" class="item" formControlName="visualDataType" type="radio" value="BAR">
                                        <label for="BAR">Barra</label>
                                    </div>
                                    <div class="check-container">
                                        <input id="LINE" class="item" formControlName="visualDataType" type="radio" value="LINE">
                                        <label for="LINE">Linha</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="step-content row">
                            <div class="p-field col-md-3">
                                <label>Origem do Valor<span class="required">*</span></label>
                                <cmx-dropdown
                                    id="dataField"
                                    size="md"
                                    formControlName="dataField"
                                    [data]="dropdownPathList"
                                    [settings]="dropdownSettings"
                                    (onChange)="changeFirstDataValues()">
                                </cmx-dropdown>
                            </div>
                            <div class="p-field col-md-3">
                                <label>Tipo do Valor<span class="required">*</span></label>
                                <cmx-dropdown
                                    id="dataType"
                                    size="md"
                                    formControlName="dataType"
                                    [data]="dropdownValueTypes"
                                    [settings]="dropdownSettings"
                                    (onChange)="changeFirstDataValues()">
                                </cmx-dropdown>
                            </div>
                            <div *ngIf="!verifyDataRankType()" class="p-field col-md-3">
                                <label>Linha Referência?</label>
                                <cmx-dropdown
                                    id="referenceLine"
                                    size="md"
                                    formControlName="referenceLine"
                                    [data]="dropdownReferenceLineTypes"
                                    [settings]="referenceLineDropdownSettings"
                                    (onChange)="changeFirstDataValues(true)">
                                </cmx-dropdown>
                            </div>
                            <div *ngIf="verifyRefenceType()" class="p-field col-md-3">
                                <label>Valor Fixo<span class="required">*</span></label>
                                <input type="number" pInputText formControlName="fixedValue" class="p-inputtext p-component"/>
                            </div>
                            <div *ngIf="verifyDataRankType()" class="p-field col-md-3">
                                <label>Posições (Rank)</label>
                                <input type="number" pInputText formControlName="rankPositions" min="1" max="5" class="p-inputtext p-component"
                                    (input)="validateRankPosition()"/>
                            </div>
                        </div>
                        <div class="step-content row">
                            <div class="p-field col-md-3">
                                <label>Origem do Valor 2</label>
                                <cmx-dropdown
                                    id="secondaryDataField"
                                    size="md"
                                    formControlName="secondaryDataField"
                                    [data]="dropdownPathList"
                                    [settings]="dropdownSecondaryDataSettings"
                                    [required]="dataForm?.get('secondaryDataType')?.value?.length || dataForm?.get('dataRelation')?.value?.length">
                                </cmx-dropdown>
                            </div>
                            <div class="p-field col-md-3">
                                <label>Tipo do Valor 2</label>
                                <cmx-dropdown
                                    id="secondaryDataType"
                                    size="md"
                                    formControlName="secondaryDataType"
                                    [data]="dropdownValueTypes"
                                    [settings]="dropdownSecondaryDataSettings"
                                    [required]="dataForm?.get('secondaryDataField')?.value?.length || dataForm?.get('dataRelation')?.value?.length">
                                </cmx-dropdown>
                            </div>
                            <div class="p-field col-md-3">
                                <label>Relação Valor 1 e 2</label>
                                <cmx-dropdown
                                    id="dataRelation"
                                    size="md"
                                    formControlName="dataRelation"
                                    [data]="dropdownOperationTypes"
                                    [settings]="dropdownSecondaryDataSettings"
                                    [required]="dataForm?.get('secondaryDataField')?.value?.length || dataForm?.get('secondaryDataType')?.value?.length">
                                </cmx-dropdown>
                            </div>
                        </div>
                        <cmx-splitter-line
                            [rightButtonList]="buttonListAddData"
                            (onRightButtonClick)="dataListAddClick()"
                        ></cmx-splitter-line>
                        <div class="data-grid-container" style="padding-right: 20px">
                            <cmx-custom-grid
                                id="custom-widgets-data-grid"
                                [columns]="dataGridColumns"
                                [rows]="dataList"
                                [options]="{ forceFitColumns: true }"
                                [styles]="{ height: 'calc(100vh - 480px)' }"
                                (onCellClick)="onCellClick($event)"
                            ></cmx-custom-grid>
                        </div>
                    </div>
                </form>
            </div>
            <!-- Etapas 4 e 5 -->
            <div *ngIf="detailsForm && formStep === 'details'" class="custom-widgets-form-container">
                <form [formGroup]="detailsForm" novalidate="novalidade" autocomplete="off" id="responsibles-subsidiary-form">
                    <div class="step-container" style="width: 400px">
                        <cmx-splitter-line title="Etapa 4 (Filtros)"></cmx-splitter-line>
                        <div class="step-content" style="overflow: auto; height: calc(100vh - 210px)">
                            <div class="p-field">
                                <label>Filtro de Datas<span class="required">*</span></label>
                                <div class="check-container">
                                    <input id="DAILY" class="item" formControlName="dateFilter" type="radio" value="DAILY">
                                    <label for="DAILY">Diário</label>
                                </div>
                                <div class="check-container">
                                    <input id="MONTHLY" class="item" formControlName="dateFilter" type="radio" value="MONTHLY">
                                    <label for="MONTHLY">Mensal</label>
                                </div>
                                <!-- Necessária Evolução de Datepicker -->
                                <!-- <div class="check-container">
                                    <input id="YEAR" class="item" formControlName="dateFilter" type="radio" value="YEAR">
                                    <label for="YEAR">Anual</label>
                                </div> -->
                            </div>
                            <hr>
                            <div class="p-field">
                                <label>Demais Filtros</label>
                                <div class="check-container">
                                    <input id="CLIENT" class="item" formControlName="clientFilter" type="checkbox" value="CLIENT">
                                    <label for="CLIENT">Cliente</label>
                                </div>
                                <div class="check-container">
                                    <input id="STATUS" class="item" formControlName="statusFilter" type="checkbox" value="STATUS">
                                    <label for="STATUS">Status</label>
                                </div>
                                <div class="check-container">
                                    <input id="SUBSIDIARY" class="item" formControlName="subsidiaryFilter" type="checkbox" value="SUBSIDIARY">
                                    <label for="SUBSIDIARY">Filial</label>
                                </div>
                                <div class="check-container">
                                    <input id="CR" class="item" formControlName="crFilter" type="checkbox" value="CR">
                                    <label for="CR">CR</label>
                                </div>
                                <div class="check-container">
                                    <input id="MODAL" class="item" formControlName="modalFilter" type="checkbox" value="MODAL">
                                    <label for="MODAL">Modal</label>
                                </div>
                            </div>
                            <hr>
                            <div class="p-field">
                                <div class="check-container">
                                    <input id="PREVIOUS" class="item" formControlName="relationPreviousPeriod" type="checkbox" value="PREVIOUS">
                                    <label for="PREVIOUS">Relação Período Anterior</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="step-container right">
                        <cmx-splitter-line title="Etapa 5 (Compartilhamento)"></cmx-splitter-line>
                        <div class="step-content" style="margin-bottom: 20px">
                            <div class="p-field col-md-12">
                                <label>Somente Leitura</label>
                                <cmx-dropdown
                                    id="sharedUsersReadyOnly"
                                    size="md"
                                    formControlName="sharedUsersReadyOnly"
                                    [dataLoading]="asyncShareUsers"
                                    [data]="dropdownUserList"
                                    [settings]="dropdownMultipleSettings">
                                </cmx-dropdown>
                            </div>
                            <div class="p-field col-md-12">
                                <label>Leitura e Edição</label>
                                <cmx-dropdown
                                    id="sharedUsersEdit"
                                    size="md"
                                    formControlName="sharedUsersEdit"
                                    [dataLoading]="asyncShareUsers"
                                    [data]="dropdownUserList"
                                    [settings]="dropdownMultipleSettings">
                                </cmx-dropdown>
                            </div>
                        </div>
                        <cmx-splitter-line title="Etapa 6 (Preferências)"></cmx-splitter-line>
                        <div class="step-content">

                        </div>
                    </div>
                </form>
            </div>
            <div class="custom-widgets-preview-container">
                <cmx-splitter-line title="Preview"></cmx-splitter-line>
                <div class="widget-preview-container">
                    <h5>{{ initialForm?.get('widgetName')?.value }}</h5>
                    <div class="chart" id="previewChart"></div>
                </div>
            </div>
        </div>
    </ng-container>
</cmx-page-wrapper>