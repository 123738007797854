<div class="cmx-filter-datepicker" [ngClass]="visual" [class.alone]="alone" [class.lockable]="lockable" [class.disabled]="disabled">
    <div *ngIf="!alone" [ngClass]="{'connector': !intoPageWrapperComponent,'left': !intoPageWrapperComponent, 'with-name': date, 'label-from': intoPageWrapperComponent}">
        <div *ngIf="date" class="date-name">
            <div class="date-line"></div>
            <span class="name" [style.left]="type === 'single-date' || type === 'single-month' ? '30px' : '27px'">
                <span class="material-icons {{ inverted ? 'inverted' : '' }}">{{ icon }}</span>
                {{ date }}
            </span>
        </div>
        <span *ngIf="type === 'range-date' || type === 'range-month'" class="text">{{ 'common::strings.from' | translate }}</span>
    </div>
    <div *ngIf="alone && (type === 'range-date' || type === 'range-month')" class="connector" style="background-color: transparent;">
        <div class="date-name">
            <span class="name" [style.left]="type === 'single-date' || type === 'single-month' ? '30px' : '27px'">
                <span class="material-icons {{ inverted ? 'inverted' : '' }}">{{ icon }}</span>
                {{ date }}
            </span>
        </div>
    </div>
    <div [id]="uniqueId" class="start-datepicker {{ startActived ? (disabledWithData ? 'blocked-with-data' : '') : 'blocked' }}" [ngClass]="{ 'year-input': type === 'single-year' }">
        <input
            name="startDatePicker"
            id="{{ uniqueId }}-startDate"
            type="text"
            class="form-control m-input date-impStatusTable"
            [style.width]="fullFillWidth ? '-webkit-fill-available !important' : '125px'"
            autocomplete="off"
            (click)="checkOpen()"
            (keyup)="changeDate('startDate', $event)"
            [(ngModel)]="startDate"
            [mask]="getMask()"
        />
        <div *ngIf="lockable" class="lockable-button" (click)="switchActivatedDate('startDate')">
            <span class="material-icons">{{ startActived ? 'clear' : (disabledWithData ? 'clear' : 'add') }}</span>
        </div>
    </div>

    <div
        *ngIf="type === 'range-date' || type === 'range-month'"
        [ngClass]="{
            'right': !intoPageWrapperComponent,
            'connector': !intoPageWrapperComponent,
            'label-to': intoPageWrapperComponent,
            'm-left': !intoPageWrapperComponent && (visual === 'in-wrapper' || visual === 'in-wrapper alone'),
            'm-left-fix': !intoPageWrapperComponent && (visual !== 'in-wrapper' || visual !== 'in-wrapper alone')
        }"
    >
        <span class="text">{{ 'common::strings.to' | translate }}</span>
    </div>
    <div [id]="uniqueId" *ngIf="type === 'range-date' || type === 'range-month'" class="end-datepicker {{ endActived ? '' : 'blocked' }}">
        <input
            name="endDatePicker"
            id="{{ uniqueId }}-endDate"
            type="text"
            class="form-control m-input date-impStatusTable"
            style="width: 125px"
            autocomplete="off"
            (click)="checkOpen()"
            (keyup)="changeDate('endDate', $event)"
            [(ngModel)]="endDate"
            [mask]="getMask()"
        />
        <div *ngIf="lockable" class="lockable-button" (click)="switchActivatedDate('endDate')">
            <span class="material-icons">{{ endActived ? 'clear' : 'add' }}</span>
        </div>
    </div>
</div>
