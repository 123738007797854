import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from 'app/shared/shared.module';
import { LayoutModule } from '../../../layouts/layout.module';

import { PermissionInfoComponent } from './permission-info.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        LayoutModule,
        SharedModule
    ], exports: [
        PermissionInfoComponent,
    ], declarations: [
        PermissionInfoComponent,
    ],
})
export class PermissionInfoModule {

}
