<div *ngIf="async || loadingTranslate" class="fullLoader">
    <loader></loader>
</div>

<div class="date-notification-container">
    <div *ngIf="currentUser.has('INTERNAL_FILTERS')" class="date-notification-client-list">
        <cmx-listing
            id="date-notification-client-listing"
            atributeName="name"
            [list]="clientGroups"
            searchPlaceholder="{{ 'system::actions.search' | translate }} {{ 'common::strings.client' | translate }}"
            [instantSearch]="true"
            [height]="'calc(100vh - 290px)'"
            (onSelect)="selectClientGroup($event)"
        ></cmx-listing>
    </div>
    <div *ngIf="selectedClientGroup" class="date-notification-email-list">
        <cmx-splitter-line
            [title]="'page::config::notifications.emailList' | translate"
            [icon]="'mail'"
            [style.margin-top]="'10px'"
        ></cmx-splitter-line>
        <div *ngIf="!async" style="margin-top: 10px;">
            <cmx-dropdown
                id="emails"
                name="emails"
                [data]="dropdown?.emails?.options"
                [settings]="emailDropdownSettings"
                [(ngModel)]="dropdown.emails.selected"
                (onChange)="emailSelected()">
            </cmx-dropdown>
        </div>
        <div *ngIf="!async" class="email-list-container">
            <div *ngFor="let email of dropdown?.emails?.selected" class="email-container">
                <span class="email-text">{{ email?.id }}</span>
            </div>
        </div>
    </div>
    <div *ngIf="gridRows?.length" class="date-notification-date-list" [style.width]="currentUser.has('INTERNAL_FILTERS') ? '60%' : '80%'">
        <cmx-splitter-line
            [title]="'page::config::notifications.listDates' | translate"
            [icon]="'schedule'"
            [style.margin-top]="'10px'"
        ></cmx-splitter-line>
        <cmx-custom-grid
            *ngIf="!async"
            id="date-notification-grid"
            [columns]="gridColumns"
            [rows]="gridRows"
            [styles]="{ height: 'calc(100vh - 280px)', 'margin-top': '0px' }"
            [options]="{ forceFitColumns: true }"
            (onSwitch)="switchNotification($event)"
            (onCellDropdownSelect)="frequencySelection($event)"
            (onCellClick)="onCellClick($event)">
        </cmx-custom-grid>
        <cmx-button
            *ngIf="!async"
            id="date-notification-save"
            variant="success"
            [disabled]="!changes || (initialEmpty && (!dropdown?.emails?.selected?.length || !hasConfiguration()))"
            (click)="prepareNotification()"
            >{{ 'system::actions.save' | translate }}
        </cmx-button>
    </div>
</div>

<cmx-custom-dialog 
    animation="right-left"
    [widthModal]="25" 
    [id]="'modal-specific-processes'" 
    [title]="'page::config::notifications.specificProcesses.title' | translate"
    [overlay]="true"
    (modal-closed)="onModalSpecificProcessesClose()">
    <ng-container cmx-custom-dialog-body>
        <div class="p-field input-action-row">
            <label>{{ 'page::config::notifications.processNumber' | translate }}<span class="required">*</span></label>
            <div class="input-button-container">
                <input
                    required
                    pInputText
                    type="text"
                    name="specificProcesses"
                    [title]="'page::config::notifications.processNumber' | translate"
                    maxlength="6"
                    mask="0*"
                    [(ngModel)]="processInputValue"
                    (keydown.enter)="validateImp()"
                    (blur)="onImpBlur()"
                />
                <div class="spinner-loader">
                    <p-progressSpinner 
                        *ngIf="validImp" 
                        [style]="{ width: '20px', height: '20px' }" 
                        strokeWidth="3" 
                        animationDuration=".5s">
                    </p-progressSpinner>
                </div>
            </div>
        </div>
        <div *ngIf="specificProcesses.length > 0" style="margin-top: 15px;">
            <cmx-custom-grid
                id="specific-processes-grid"
                [columns]="specificProcessesColumns"
                [rows]="modalSpecificProcessesRows"
                [styles]="{ height: 'calc(100vh - 280px)', 'margin-top': '0px' }"
                [options]="{ forceFitColumns: true }"
                (onCellClick)="onSpecificProcessesCellClick($event)">
            </cmx-custom-grid>
        </div>
    </ng-container>
</cmx-custom-dialog>

<cmx-confirmation-modal
    id="date-notification-no-email"
    [question]="'page::config::notifications.confirmSaveWithoutEmail' | translate"
    [labelAction]="'system::actions.save' | translate"
    confirmType="danger"
    [hidden]="!showNoEmailConfirmationModal"
    (onConfirm)="prepareNotification(true)"
    (onCancel)="showNoEmailConfirmationModal = false"
></cmx-confirmation-modal>
