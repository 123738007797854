
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';

import { DownloadModalService } from '#services/_download/download-modal.service';
import { NewFeaturesConsumerService } from '#services/_newFeatureConsumer/newFeatureConsumer.service';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { AuthenticationService } from 'app/auth/_services/authentication.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { UtilsService } from '#services/_utils/utils.service';

@Component({
    selector: 'profile-display-menu',
    templateUrl: './profile-display-menu.component.html',
    styleUrls: ['./profile-display-menu.component.scss', './profile-display-menu.theme.scss'],
})

export class ProfileDisplayMenuComponent {
    @Input() user;
    @Input() hidden: boolean = true;
    @Input('cmx-dark-theme') darkTheme: boolean = true;
    @Output() onChangeTheme: EventEmitter<any> = new EventEmitter();

    themeSelected = null;
    userInitials: string;

    userPreference: {
        darkTheme?: boolean,
        enable?: boolean,
        language?: string
    } = {};

    languages = [
        { code: 'en-US', flag: 'https://flagcdn.com/24x18/us.png' },
        { code: 'pt-BR', flag: 'https://flagcdn.com/24x18/br.png' }
    ];

    constructor(
        public router: Router,
        public downloadModalService: DownloadModalService,
        public newFeaturesConsumerService: NewFeaturesConsumerService,
        private _authenticationService: AuthenticationService,
        private _userCacheService: UserCacheService,
        private _userPreferencesService: UserPreferencesService,
        private _utilsService: UtilsService,
        private _translateService: TranslateService
    ) { }

    ngOnInit(){
        this.userPreference = {
            ...this._userCacheService.getUserPreferences(),
            darkTheme: this._userCacheService.getUserDarkTheme(),
            language: this._userCacheService.getLanguage(),
            enable: false
        };
        if(this.user){
            this.userInitials = this.user.data.name.split(' ')[0].charAt(0);
        }

        if (this.userPreference.language !== this._translateService.currentLang) {
            this.changeLanguage({ code: this.userPreference.language, flag: '' });
        }
    }

    onTryChangeTheme(){
        this.themeSelected = !this.darkTheme;
        this.userPreference.darkTheme = this.themeSelected;
        this.onChangeTheme.emit(this.themeSelected);
    }

    goToLogin() {
        this._authenticationService.logout(true, false);
    }

    changeLanguage(lang: { code: string, flag: string }, typeChange: string = 'language'): void {
        const selectedLanguage = lang.code;
        this._userCacheService.setLanguage(selectedLanguage);
        this.userPreference.language = selectedLanguage;

        this._userPreferencesService.savePreference(this.userPreference, typeChange).subscribe(
            () => {
            }, error => {
                this._utilsService.errorHandler(error, this._translateService.instant('locale.language'));
            }
        );
    }
}
