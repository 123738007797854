<div class="user-initials-container"></div>
<div #elUserInitials id="user-initials" class="user-initials" [class.dark-theme]="darkTheme" [class.actived]="!hidden" [title]="user?.data?.displayName" (click)="hidden = !hidden">
    <span class="material-icons">settings</span>
</div>
<div class="profile-display-menu" [class.hidden]="hidden" [class.dark-theme]="darkTheme" (mouseleave)="hidden = true">
    <header class="profile-menu">
        <span>{{ user?.data?.displayName }}</span>
        <span class="email">{{ user?.data?.email }}</span>
    </header>
    <section class="user-preference" *ngIf="user.has('ROUTE_USER_PREFERENCES_VIEWS')">
        <span>{{ 'menu::user.label::userPreferencesFilter' | translate }}</span>
        <cmx-custom-view [cmx-dark-theme]="userPreference.darkTheme"></cmx-custom-view>
    </section>
    <nav class="profile-menu">
        <ul (click)="hidden = !hidden">
            <li id="profile-menu-item-painel-administrativo" *ngIf="user.has('ROUTE_ADM')" routerLinkActive="actived" routerLink="/adm">
                <span>{{ 'menu::user.title::adm' | translate }}</span><i class="fa fa-wrench"></i>
            </li>
            <li id="profile-menu-item-configuracoes" *ngIf="user.has('ROUTE_NOTIFICATION_CONFIGURATION')" routerLinkActive="actived" routerLink="/configurations">
                <span>{{ 'menu::user.title::config' | translate }}</span><i class="fa fa-cog"></i>
            </li>
            <li id="profile-menu-item-meu-perfil" routerLinkActive="actived" routerLink="/profile">
                <span>{{ 'menu::user.title::profile' | translate }}</span><i class="fa fa-user"></i>
            </li>
            <li id="profile-menu-item-timesheet" *ngIf="user.has('API_TIMESHEET')" routerLinkActive="actived" routerLink="/timesheet">
                <span>{{ 'menu::user.title::timesheet' | translate }}</span><i class="fa fa-calendar"></i>
            </li>
            <li id="profile-menu-item-downloads" (click)="downloadModalService.openDownloads();">
                <span>{{ 'menu::user.title::downloads' | translate }}</span><i class="fa fa-download"></i>
            </li>
            <li id="profile-menu-item-integracoes-tecnicas" *ngIf="user.has('ROUTE_NOTIFICATIONS_WEBHOOOK')" routerLinkActive="actived" routerLink="/integracoes-tech">
                <span>{{ 'menu::user.title::tech_integrations' | translate }}</span><i class="fa fa-code-fork"></i>
            </li>
            <li id="profile-menu-item-ultimos-releases" (click)="newFeaturesConsumerService.openReleaseList();">
                <span>{{ 'menu::user.title::lastReleases' | translate }}</span><i class="fa fa-list-ul"></i>
            </li>
            <li id="profile-menu-item-language" *ngIf="user.has('VALIDATE_NEW_FEATURES')">
                <span>{{ 'locale.language' | translate }}</span>
                <div class="flag-options">
                    <img *ngFor="let lang of languages" src="{{ lang.flag }}" [alt]="lang.code" class="flag-img" [ngClass]="userPreference?.language === lang.code ? 'selected' : ''" (click)="changeLanguage(lang)">
                </div>
            </li>
        </ul>
    </nav>
    <footer class="profile-menu">
        <button id="profile-menu-item-tema" class="btn-profile change-theme" *ngIf="user.has('INTERNAL_FILTERS')" (click)="onTryChangeTheme()">{{ darkTheme ? 'Light' : 'Dark'}} Theme <i class="fa fa-paint-brush"></i></button>
        <button id="profile-menu-item-sair" class="btn-profile logout" (click)="goToLogin()">{{ 'menu::user.button::logout' | translate }}</button>
    </footer>
</div>
