import { HttpHeaders, HttpParams } from '@angular/common/http';
import { AppModule } from 'app/app.module';
import moment from 'moment';
import * as _ from 'lodash';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { ICommercialPremiseFilter } from './interfaces/commercial-premise-filter.interface';

export class CommercialPremiseFilter {
    private _userCacheService = AppModule.injector.get(UserCacheService);
    private filterField: string;
    private filterValue: string;
    private sort: string;
    private _page: number;
    private _size: number;
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);
    private _initialCommercialPremiseFilter: ICommercialPremiseFilter = <ICommercialPremiseFilter>{};
    private _commercialPremiseFilter: ICommercialPremiseFilter = <ICommercialPremiseFilter> {
        sortField: 'premiseId',
        status: [
            { id: 'IN_PROGRESS', itemName: 'Em Preenchimento' },
            { id: 'AWAITING_TRADER_APPROVAL', itemName: 'Aguard. Aprovação Trader' },
            { id: 'COMMERCIAL_APPROVAL', itemName: 'Aguard. Aprovação Diretor Comercial' },
            { id: 'LOGISTIC_APPROVAL', itemName: 'Aguard. Aprovação Diretor Operacional' },
            { id: 'FINANCIAL_APPROVAL', itemName: 'Aguard. Aprovação Diretor Financeiro' },
            { id: 'FINAL_APPROVAL', itemName: 'Aguard. Aprovação Final' },
            { id: 'APPROVED', itemName: 'Aprovada' },
            { id: 'INACTIVE', itemName: 'Inativa' },
        ],
        trader: [],
        director: [],
    };

    constructor(filter?: CommercialPremiseFilter) {
        this._commercialPremiseFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'commercialPremiseFilter', this._commercialPremiseFilter, filter);
        this._initialCommercialPremiseFilter = _.cloneDeep(this._commercialPremiseFilter);
        this.filterField = filter ? filter.filterField : '';
        this.filterValue = filter ? filter.filterValue : '';
        this.sort = filter ? filter.sort : 'desc';
        this._page = filter ? filter._page : 0;
        this._size = filter ? filter._size : 50;
    }

    public getStatus(): any { return this._commercialPremiseFilter.status; }
    public getTraders(): any { return this._commercialPremiseFilter.trader; }
    public getDirectors(): any { return this._commercialPremiseFilter.director; }
    public getFilterField() { return this.filterField; }
    public getFilterValue() { return this.filterValue; }
    public getSortField(): string { return this._commercialPremiseFilter.sortField; }
    public getSort(): string { return this.sort; }
    public getPage(): number { return this._page; }
    public getSize(): number { return this._size; }

    public setStatus(status): void { this._commercialPremiseFilter.status = status; }
    public setTraders(traders): void { this._commercialPremiseFilter.trader = traders;}
    public setDirectors(directors): void { this._commercialPremiseFilter.director = directors; }
    public setFilterField(filterField): void { this.filterField = filterField; }
    public setFilterValue(filterValue): void { this.filterValue = filterValue; }
    public setSortField(sortField): void { this._commercialPremiseFilter.sortField = sortField; }
    public setSort(sort): void { this.sort = sort; }
    public setPage(page): void { this._page = page; }
    public setSize(size): void { this._size = size; }


    public getRequest() {
        this._initialCommercialPremiseFilter = this._userPreferencesService.verifyFilterChanges(
            this._initialCommercialPremiseFilter, this._commercialPremiseFilter, 'commercialPremiseFilter', this._userCacheService.getUserPreferences().id);
        let params = new HttpParams();
        let headers = new HttpHeaders();

        if (this.getFilterValue()) {
            params = params.append('field', `${this.getFilterField()}::${this.getFilterValue()}`);
        }

        if (this.getSortField() && this.getSort()) {
            params = params.append('sort', `${this.getSortField()},${this.getSort()}`);
        }

        if(this.getStatus()?.length > 0) {
            params = params.append('field', `status::${this.getStatus().map((c) => (c.id !== c.itemName ? c.id : null)).join(',')}`);
        }

        if(this.getTraders()?.length > 0) {
            params = params.append('field', `general.trader.name::${this.getTraders().map((c) => c.itemName).join(',')}`);
        }

        if(this.getDirectors()?.length > 0) {
            params = params.append('field', `general.director.name::${this.getDirectors().map((c) => c.itemName).join(',')}`);
        }
        params = params.append('page', `${ this.getPage() }`);
        params = params.append('size', `${ this.getSize() }`);

        return { params, headers };
    }

}
