<cmx-page-wrapper
    layout="bigHeader"
    [titleComponent]="{ title: pageTabControlService.currentPageTitle }"
    [tabs]="pageConfig.tabs"
    [tab-blur]="pageTabControlService.currentTabSelected"
    [switch]="pageTabControlService.currentSwitches"
    [selectedSwitch]="pageTabControlService.selectedSwitch"
    (onSelectTab)="pageTabControlService.onNavigate($event, null, true)"
    helpTitle="Notificações"
    [helpDescription]="helpDescription"
    [helpVideoSrc]="'assets/videos/icone-informativo/configuracoesNotificacoes.mp4'"
    [helpVideoName]="'Configurações Notificações'"
    (onSwitchSelect)="pageTabControlService.onSwitchSelect($event)">
    <ng-container cmx-page-wrapper-body #bodyContent></ng-container>
</cmx-page-wrapper>
