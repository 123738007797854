<cmx-page-wrapper
    layout="bigHeader"
    [titleComponent]="{ title: pageTabControlService.currentPageTitle }"
    [tabs]="pageConfig.tabs"
    [tab-blur]="pageTabControlService.currentTabSelected"
    [switch]="pageTabControlService.currentSwitches"
    [topButtonList]="pageTabControlService.currentTopButtonList"
    [rightButtonList]="rightExtraPaddingPage ? reportPreferencesService.rightButtonList : []"
    [selectedRightButton]="reportPreferencesService.selectedRightButton"
    [selectedSwitch]="pageTabControlService.selectedSwitch"
    [rightExtraPaddingPage]="rightExtraPaddingPage"
    [topButtonListOutside]="rightExtraPaddingPage"
    [helpDescription]="helpDescription"
    [helpVideoSrc]="'assets/videos/icone-informativo/CustosEPersonalinalizarFinal.mp4'"
    [helpVideoName]="'Custos e Personalizar'"
    (onSelectTab)="onSelectTab($event)"
    (onSwitchSelect)="pageTabControlService.onSwitchSelect($event)"
    (onTopButtonClick)="pageTabControlService.onTopButtonClick($event)"
    (onRightButtonClick)="pageTabControlService.onRightButtonClick($event)">
    <ng-container cmx-page-wrapper-body #bodyContent>
    </ng-container>
</cmx-page-wrapper>