import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, Renderer2, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { Observable } from 'rxjs';

import { environment } from '#environment';
import { UserPermissionBlockerService } from '#services/_user/userPermissionBlocker.service';
import { UtilsService } from '#services/_utils/utils.service';
import { ChartsServices } from './../charts.services';
import { AmCharts5Service } from '#services/_charts/charts.service';
import { NotificationService } from '#services/_notification/notification.service';

declare let AmCharts: any;

@Component({
    selector: 'exchange-chart',
    templateUrl: './exchange.component.html',
    styleUrls: ['../charts.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ExchangeComponent {
    public asyncExchange: boolean = false;

    public startDate = moment().add(-2, 'years').format('YYYY-MM-DD');
    public endDate = moment().format('YYYY-MM-DD');
    public chartData;
    helpDescription = this._defineHelpDescription();

    @HostListener('click') onSetZIndex() {
        this.renderer2.setStyle(this._elementRef.nativeElement, 'z-index', this.utilsService.autoIncrementValue);
    }

    constructor(
        public chartService: ChartsServices,
        public router: Router,
        private http: HttpClient,
        private el: ElementRef,
        private permissionBlocker: UserPermissionBlockerService,
        private utilsService: UtilsService,
        private _elementRef: ElementRef,
        private renderer2: Renderer2,
        private _AmCharts5Service: AmCharts5Service,
        private _notificationService: NotificationService,
    ) {}

    public ngAfterViewInit() {
        this.loadExchange();
    }

    public loadExchange() {
        this.asyncExchange = false;
    
        if (this.permissionBlocker.permissionValidator(this.el.nativeElement.tagName)) {
            this.getExchange().subscribe(
                (data) => {
                                        
                    this.asyncExchange = true;
                    this.chartData = data;
                    
                    const chartData = this.generateChartData(data);
                    const chartConfig = {
                        chartId: 'exchange',
                        chartType: 'DraggableRange',
                        axis: [
                            {
                                axisPosition: 'x',
                                categoryField: 'date',
                            },
                            {
                                axisPosition: 'y',
                                categoryField: 'value',
                            },
                        ],
                        content: [
                            {
                                name: 'Câmbio BRL/USD',
                                valueYField: 'value',
                                valueXField: 'date',
                            }
                        ],
                    };

                    this._AmCharts5Service.createChart(chartConfig, {
                        data: chartData
                    });
                },
                (error) => {
                    this.chartData = undefined;
                    this.asyncExchange = true;
                    this.utilsService.errorHandler(error, 'Câmbio');
                }
            );
        }
    }    

    private generateChartData(response) {
        const chartValues = [];
        if (response.values && Array.isArray(response.values)) {
            response.values.forEach((item) => {
                chartValues.push({
                    date: new Date(item.dateQuotation).getTime(),
                    value: item.quotationValue
                });
            });
        } else {
            this._notificationService.openNotification('error', 'Formato de dados inesperado');
        }
        return chartValues;
    } 

    private getExchange(): Observable<any> { 
        return this.http.get( `${environment.endpoints.exchangeService}/exchanges/daily-exchange/USD?startDate=${this.startDate}&endDate=${this.endDate}`);
    }

    private _defineHelpDescription(): object[] {
        return [{ type: 'text', value: 'Informa a cotação do Dólar em relação ao Real dia a dia dos últimos 2 anos.' }];
    }
}
