import { HttpHeaders, HttpParams } from '@angular/common/http';
import { AppModule } from 'app/app.module';
import * as _ from 'lodash';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { IExternalReportsFilter } from './interfaces/external-reports-filter.interface';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ExternalReportsFilter {
    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);
    private _initialExternalReportsFilter: IExternalReportsFilter = <IExternalReportsFilter>{};
    private _externalReportsFilter: IExternalReportsFilter = <IExternalReportsFilter>{
        isFavorite: null,
    };

    constructor(filter?: ExternalReportsFilter) {
        this._externalReportsFilter = this._userPreferencesService.defineFilter(
            this._userCacheService.getUserPreferences(),
            'externalReportsFilter',
            this._externalReportsFilter,
            filter
        );
        this._initialExternalReportsFilter = _.cloneDeep(this._externalReportsFilter);
    }

    public getFavorite(): any {
        return this._externalReportsFilter.isFavorite;
    }

    public setFavorite(isFavorite): void {
        this._externalReportsFilter.isFavorite = isFavorite;
    }

    public getRequest() {
        this._initialExternalReportsFilter = this._userPreferencesService.verifyFilterChanges(this._initialExternalReportsFilter,
            this._externalReportsFilter, 'externalReportsFilter', this._userCacheService.getUserPreferences().id);

        let params = new HttpParams();
        let headers = new HttpHeaders();

        if (this.getFavorite()?.length > 0) {
            params = params.append( 'field', `isFavorite::${this.getFavorite() .map((c) => (c.id !== c.itemName ? c.id : null)).join(',')}`);
        }

        return { params, headers };
    }
}
