import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DragulaModule } from 'ng2-dragula';
import { AccordionModule } from 'primeng/accordion';
import { SVGModule } from '../../svg/svg.module';
import { SharedModule } from 'app/shared/shared.module';
import { SimpleLineChartModule } from 'app/shared/components/cmx-simple-line-chart/cmx-simple-line-chart.module';
import { UserProfileDisplayModule } from 'app/shared/components/cmx-user-profile-display/cmx-user-profile-display.module';
import { PreferencesModule } from '../pages/preferences/preferences.module';
import { ChatAIModule } from '../pages/chat-ai/chat-ai.module';
import { TranslateModule } from '@ngx-translate/core';

import { AsideNavComponent } from './aside-nav/aside-nav.component';
import { AsyncDownloadsComponent } from '../pages/async-download/async-downloads.component';
import { BoardingStatusComponent } from './charts/boarding-status/boarding-status.component';
import { ChannelComponent } from './charts/channel/channel.component';
import { ChassiStatusComponent } from 'app/modules/operational/pages/chassi-status/chassi-status.component';
import { ContainerTrackingWidgetComponent } from './charts/container-tracking/container-tracking.component';
import { CostDistributionComponent } from './charts/cost-distribution/cost-distribution.component';
import { CrProcessesComponent } from './charts/cr-processes/cr-processes.component';
import { CrProcessesTableComponent } from './charts/cr-processes-table/cr-processes-table.component';
import { DamageWidgetComponent } from './charts/damage/damage.component';
import { DefaultComponent } from '../pages/default/default.component';
import { DetailTableComponent } from './detailtable/detailtable.component';
import { ExchangeComponent } from './charts/exchange/exchange.component';
import { FooterComponent } from './footer/footer.component';
import { FreightRatesComponent } from './charts/freight-rates/freight-rates.component';
import { HeaderNavComponent } from './header-nav/header-nav.component';
import { InvoicesComponent } from './charts/invoices/invoices.component';
import { InvoicesProcessesComponent } from './charts/invoices-processes/invoices-processes.component';
import { LayoutComponent } from './layout/layout.component';
import { LeadTimeComponent } from './charts/lead-time/lead-time.component';
import { LiLeadTimeComponent } from './charts/li-lead-time/li-lead-time.component';
import { LogisticCostsComponent } from './charts/logistic-costs/logistic-costs.component';
import { MainMenuComponent } from './header-nav/menu/main-menu.component';
import { NewFeaturesComponent } from '../pages/default/new-features/new-features.component';
import { NewTrackingTimelineComponent } from './new-tracking-timeline/newTrackingTimeline.component';
import { NotificationComponent } from './notification/notification.component';
import { OverlayComponent } from './overlay/overlay.component';
import { PaginationComponent } from './pagination/pagination.component';
import { ScrollTopComponent } from './scroll-top/scroll-top.component';
import { SkuStatusComponent } from './charts/sku-status/sku-status.component';
import { SlaConsolidationComponent } from './charts/sla-consolidation/sla-consolidation.component';
import { SlaStatusComponent } from './charts/sla-status/sla-status.component';
import { StatusConsolidationComponent } from './charts/status-consolidation/status-consolidation.component';
import { StockComponent } from './charts/stock/stock.component';
import { TreeTableComponent } from './treetable/treetable.component';

@NgModule({
    entryComponents: [
        InvoicesComponent,
        ExchangeComponent,
        ChannelComponent,
        InvoicesProcessesComponent,
        ChassiStatusComponent,
        CrProcessesComponent,
        CrProcessesTableComponent,
        SlaStatusComponent,
        SkuStatusComponent,
        CostDistributionComponent,
        LogisticCostsComponent,
        LeadTimeComponent,
        LiLeadTimeComponent,
        DamageWidgetComponent,
        StockComponent,
        AsyncDownloadsComponent,
        NotificationComponent,
        NewFeaturesComponent,
        NewTrackingTimelineComponent,
        SlaConsolidationComponent,
        StatusConsolidationComponent,
        BoardingStatusComponent,
        ContainerTrackingWidgetComponent,
        MainMenuComponent,
    ],
    declarations: [
        LayoutComponent,
        HeaderNavComponent,
        DefaultComponent,
        AsideNavComponent,
        FooterComponent,
        ScrollTopComponent,
        ExchangeComponent,
        InvoicesProcessesComponent,
        InvoicesComponent,
        FreightRatesComponent,
        ChassiStatusComponent,
        ChannelComponent,
        PaginationComponent,
        TreeTableComponent,
        CrProcessesComponent,
        CrProcessesTableComponent,
        DetailTableComponent,
        SlaStatusComponent,
        SkuStatusComponent,
        OverlayComponent,
        CostDistributionComponent,
        LogisticCostsComponent,
        LeadTimeComponent,
        LiLeadTimeComponent,
        DamageWidgetComponent,
        StockComponent,
        AsyncDownloadsComponent,
        NotificationComponent,
        NewFeaturesComponent,
        NewTrackingTimelineComponent,
        SlaConsolidationComponent,
        StatusConsolidationComponent,
        BoardingStatusComponent,
        ContainerTrackingWidgetComponent,
        MainMenuComponent
    ],
    exports: [
        LayoutComponent,
        HeaderNavComponent,
        DefaultComponent,
        AsideNavComponent,
        FooterComponent,
        ScrollTopComponent,
        ExchangeComponent,
        InvoicesProcessesComponent,
        InvoicesComponent,
        FreightRatesComponent,
        ChassiStatusComponent,
        ChannelComponent,
        PaginationComponent,
        TreeTableComponent,
        CrProcessesComponent,
        CrProcessesTableComponent,
        DetailTableComponent,
        SlaStatusComponent,
        SkuStatusComponent,
        OverlayComponent,
        CostDistributionComponent,
        LogisticCostsComponent,
        LeadTimeComponent,
        LiLeadTimeComponent,
        DamageWidgetComponent,
        StockComponent,
        NotificationComponent,
        NewTrackingTimelineComponent,
        SlaConsolidationComponent,
        StatusConsolidationComponent,
        BoardingStatusComponent,
        ContainerTrackingWidgetComponent,
        MainMenuComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule,
        FormsModule,
        AccordionModule,
        SVGModule,
        DragulaModule,
        SharedModule,
        UserProfileDisplayModule,
        SimpleLineChartModule,
        PreferencesModule,
        ChatAIModule,
    ],
})
export class LayoutModule {}
