<div class="moveBtn"><div class="moveLine"></div></div>
<div *ngIf="asyncChannel == false || initAsyncChannel == false"><loader></loader></div>

<cmx-marker-filter
    *ngIf="currentUser"
    class="marker-filter"
    type="period"
    [filters]="periodTypes"
    [preSelected]="preSelectedPeriod"
    [multiple]="false"
    [hasSelectAll]="false"
    [leftDistance]="150"
    (onSelected)="onSelectedPeriod($event)"
></cmx-marker-filter>

<div id="searchLine" class="reduzed">

    <div class="header-container align-icon-container">
        <div class="title-information" style="margin-bottom:10px">
            <span class="analyticsName">Canal</span>
            <cmx-help-modal
                *ngIf="!channelStatusOpen"
                [help-video-src]="'assets/videos/icone-informativo/dashboardsAtualizado.mp4'"
                [help-video-name]="'Dashboards Canal'"
                help-title="Canal Status"
                [help-description]="helpDescription">
            </cmx-help-modal>
            <h5>Canal</h5>
            <div *ngIf="currentUser?.has('ROUTE_AUTOMOTIVE') && !currentUser?.has('AUTOMOTIVE_USER')"
                title="Modo Automotivo: {{ filterAutomotiveMode }}"
                class="filterMode"
                (click)="toggleFilterMode()">
                <div><em class="material-icons">directions_car</em></div>
            </div>
        </div>
    </div>

    <div class="search-container align-search-container">
        <div class="input-group first" *ngIf="currentUser?.has('INTERNAL_FILTERS')">
            <cmx-dropdown
                [styles]="{width: '180px'}"
                id="channel-clients"
                name="clients"
                [data]="options.clients"
                [ngModel]="filterState.getClientGroups()"
                (ngModelChange)="filterState.setClientGroups($event)"
                [settings]="dropdownSettings.clientGroups"
            ></cmx-dropdown>
            <span class="input-group-addon"></span>
        </div>

        <div class="input-group second" *ngIf="currentUser?.has('INTERNAL_FILTERS')">
            <cmx-dropdown
                [styles]="{width: '110px'}"
                id="channel-urfs"
                name="urfs"
                [data]="options.urfs"
                [ngModel]="filterState.getUrfs()"
                (ngModelChange)="filterState.setUrfs($event)"
                [settings]="dropdownSettings.urfs"
            ></cmx-dropdown>
            <span class="input-group-addon">Por</span>
        </div>

        <div class="margin-start" *ngIf="!currentUser?.has('INTERNAL_FILTERS')"></div>

        <div class="input-group third">
            <cmx-dropdown
                [styles]="{width: '110px'}"
                id="channel-type-data"
                name="type-data"
                [data]="options.typeData"
                [ngModel]="filterState.getTypeData()"
                (ngModelChange)="filterState.setTypeData($event)"
                [settings]="dropdownSettingsTypeData"
            ></cmx-dropdown>
        </div>
        <div class="input-group date-picker">
            <cmx-filter-datepicker
                id="channel-status-datepicker"
                type="range-month"
                periodType="month"
                [startDate]="filterState.getStartDate()"
                [endDate]="filterState.getEndDate()"
                (onChangeDates)="datesChanged($event)"
            ></cmx-filter-datepicker>
        </div>
        <button (click)="loadChannel()" class="filter-btn pointer applyImpStatus">
            <i class="fa fa-check"></i>
        </button>
    </div>
</div>


<cmx-simple-line-chart
    *ngIf="initAsyncChannel"
    percentageValue="true"
    [chartData]="resultValues"
    [clickable]="true"
    (onSelect)="selectChannelColor($event)"
></cmx-simple-line-chart>

<div class="channel-dialog-container" *ngIf="showModal">
    <div class="channel-dialog-grid-error text-center mt-3" *ngIf="!gridLoading && gridRows.length < 1">
        <span class="noContent">Dados não encontrados</span>
    </div>

    <div class="channel-dialog-grid-content" *ngIf="gridRows.length > 0">
        <loader *ngIf="gridLoading"></loader>
        <cmx-custom-grid
            [id]="verifyAutomotiveMode(currentUser) ? 'channel-dialog-grid-automotive' : 'channel-dialog-grid-default'"
            [style.visibility]="!gridLoading ? 'visible' : 'hidden'"
            [columns]="gridColumns"
            [rows]="gridRows"
            [options]="gridOptions"
            [styles]="gridStyles"
            (onCellClick)="onCellClick($event)"
        ></cmx-custom-grid>
        <div [style.visibility]="!gridLoading ? 'visible' : 'hidden'"
            class="position-absolute" *ngIf="pages.length > 1">
            <pagination
                [numberOfPages]="pages.length"
                [queryId]="queryId"
                (onPageChange)="changePage($event)">
            </pagination>
        </div>
    </div>
</div>
