import { Component, EventEmitter, Input, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { AmCharts5Service } from '#services/_charts/charts.service';

@Component({
    selector: 'cmx-widget',
    templateUrl: './cmx-widget.component.html',
    styleUrls: ['./cmx-widget.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class WidgetComponent {

    currentFilter;

    @Input() widgetName: string;
    @Input() widgetInfo?: any;
    @Input() filterLineStructure: any[] = [];
    @Input() chartConfig?;
    @Input() chartData?;
    @Input() widgetMovable: boolean = false;
    @Input() chartDataLoaded: boolean = false;

    @Output() onApplyFilter = new EventEmitter();

    constructor(private _chartsService: AmCharts5Service) { }

    ngAfterViewInit() {
        if (this.chartConfig?.chartId && this.chartDataLoaded) { this._renderChart() }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes && changes?.chartDataLoaded) {
            if (this.chartConfig?.chartId && this.chartDataLoaded) { this._renderChart() }
        }
        if (changes && changes?.chartData) {
            if (this.chartConfig?.chartId && this.chartDataLoaded && this.chartData) { this._renderChart() }
        }
        if (changes && changes?.filterLineStructure) {
            this.currentFilter = this.filterLineStructure?.map((filter) => { return {
                code: filter.code, type: filter.type,
                value: filter.type === 'datepicker' ? 
                    { startDate: filter.component.startDate , endDate: filter.component.endDate }
                    : filter.component.dropdownSelected || []
            } })
        }
    }

    onFilterChanges({ value, type, code }) {
        const filter = this.currentFilter.find(item => item.code === code);
        if (filter) { filter.value = value }
    }

    applyFilter() {
        this.onApplyFilter.emit({ chartId: this.chartConfig?.chartId, filters: this.currentFilter });
    }

    private _renderChart() {
        setTimeout(() => {
            this._chartsService.createChart({ ...this.chartConfig, chartId: `customWidgetChart-${this.chartConfig.chartId}` },
                { data: this.chartData });
        }, 500);
    }

}