import { HttpClient } from '@angular/common/http';
import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, timer } from 'rxjs';
import Inputmask from 'inputmask';
import * as _ from 'lodash';
import * as moment from 'moment';
import { environment } from '#environment';

import { SkuStatusFilter } from 'app/theme/layouts/charts/sku-status/skuStatusFilter.model';
import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { DROPDOWN_SETTINGS } from 'app/shared/constants/dropdown-settings.constants';

import { UserService } from "app/auth/_services/user.service";
import { FilterStateService } from '#services/_filters/filter-state.service';
import { CmxDropdownService } from 'app/shared/components/cmx-dropdown/cmx-dropdown.service';
import { SkuStatusService } from './sku-status.service';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { EventSourceService } from '#services/_eventSource/eventSource.service';
import { UtilsService } from '#services/_utils/utils.service';

declare let Slick: any;

@Component({
    selector: 'sku-status-chart',
    templateUrl: './sku-status.component.html',
    styleUrls: ['./../charts.scss', './sku-status.component.scss'],
    providers: [SkuStatusService],
    encapsulation: ViewEncapsulation.None,
})
export class SkuStatusComponent {

    filterState: SkuStatusFilter = this.filterStateService.getSkuStatusFilter();
    currentUser = this.userService.getCurrentUser();
    currentUser$;
    dropdownSettings = _.cloneDeep(DROPDOWN_SETTINGS);
    moment = moment;
    queryId = Math.random();
    subscription;
    exportAsync = false;
    asyncSkuStatusTable = false;
    skuView = 'skuClient';
    options: {
        client: DropdownOption[];
        modal: DropdownOption[];
    } = {
        client: [],
        modal: [],
    };

    allStatus = [];
    preSelectedStatus = [];
    imp = null;
    ncm = null;
    sku = null;
    ncms;
    references;
    selectedNcm = {
        ncm: '',
        categoria: '',
        descricao: '',
        IPI: '',
        inicioVigencia: '',
        uTrib: '',
        descricaoUTrib: '',
    };
    diList = null;
    daNumber = null;

    gridData;
    total: number = 0;
    totalImps: number = 0;
    typeSearch = 'SKU';
    placeholderSearch = 'Buscar SKU';
    pages = [];
    sort = true;
    path = 'comexportSku';
    private columns = null;
    private rows = null;
    private dataView = null;
    private StatusGrid = null;

    constructor(
        public router: Router,
        public filterStateService: FilterStateService,
        private http: HttpClient,
        private userService: UserService,
        private eventChannel: EventSourceService,
        private utilsService: UtilsService,
        private userCacheService: UserCacheService,
        private _skuStatusService: SkuStatusService,
        private _cmxDropdownService: CmxDropdownService,
    ) {
        this.getNCMS().subscribe((data) => (this.ncms = data));
        this.skuView = this.currentUser.has('INTERNAL_FILTERS') ? 'skuComex' : 'skuClient';
    }

    ngOnDestroy() {
        $(document).off('keyup');
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        this.filterState.setStatus([]);
    }

    ngAfterViewInit() {
        $(document).ready(() => {
            $(document).on('keyup', this.enterBtn.bind(this));
            $(document).click((e: any) => {
                const parent = e.target.offsetParent;
                if (!$(parent).hasClass('searchInput') && !$(parent).hasClass('searchOptions')) {
                    $('sku-status-chart .searchOptions').addClass('hidden');
                }
            });
        });
        this.currentUser$ = this.userService.currentUser$;
        this.options.modal = this._cmxDropdownService.toDropdownItemsImg(_.cloneDeep(this.userCacheService.getModals()));
        this.filterState.setModals(this._cmxDropdownService.toDropdownItemsImg(this.filterState.getModals()));
        this.options.client = this._cmxDropdownService.toDropdownItems(this.userCacheService.getClientGroups());
        this.updateDateInputs();
        this._getSkuStatus();
    }

    applyFilter() {
        this.resetPages();
        this._getSkuStatus();
        this.loadSkuStatusTable();
    }

    onSelectedStatus({ selected }): void {
        this.filterState.setStatus(_.cloneDeep(selected));
        this.loadSkuStatusTable();
    }

    changePage(pageNumber) {
        this.setInvisible();
        this.filterState.setOffset(pageNumber - 1);
        this.loadSkuStatusTable();
    }

    changeSearch() {
        if ($('sku-status-chart .searchOptions').hasClass('hidden')) {
            $('sku-status-chart .searchOptions').removeClass('hidden');
        } else {
            $('sku-status-chart .searchOptions').addClass('hidden');
        }
    }

    enterBtn(element) {
        if (element.keyCode === 13) {
            if (
                element.currentTarget.URL.indexOf('/operacional/importacao') >= 0 ||
                $('sku-status-chart #impSearch').is(':focus')
            ) {
                $('sku-status-chart .searchOptions').addClass('hidden');
                this.applyFilter();
            }
        }
    }

    selectSearchType(element, type) {
        $('sku-status-chart .searchItem').removeClass('selected');
        $(element.target).addClass('selected');
        this.filterState.setSearch('');
        this.typeSearch = type;
        this.placeholderSearch = `Buscar ${type}`;
    }

    showNcmDetail(e) {
        const code = $(e.target).text().split('.').join('');
        this.ncms.NCMS.map((ncm) => {
            if (ncm.ncm === code) {
                this.selectedNcm = ncm;
                this.selectedNcm.ncm = this.selectedNcm.ncm.replace(/(\d{4})(\d{2})(\d{2})/, '$1.$2.$3');
            }
        });
    }

    closeNcmModal() {
        $('.ncmModal').addClass('hidden');
    }
    closeRefs() {
        $('.RefModal').addClass('hidden');
    }
    closeDi() {
        $('.diModal').addClass('hidden');
    }

    loadSkuStatusTable(ordered?) {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.setInvisible();
        const request = this.filterState.getRequest(this.typeSearch, this.skuView);
        this.subscription = timer(500).subscribe(() => this.getSKU(request).subscribe(
            (data) => {
                this.createTable(data);
            },
            (error) => {
                this.gridData = undefined;
                this.asyncSkuStatusTable = true;
                this.utilsService.errorHandler(error, 'SKU');
            }
        ));
    }

    exportSku() {
        this.callExportMessage();
        const headers = `${this.filterState
            .getClientGroups()
            .map((c) => c['id'])
            .join(',')}`;
        const type = 'SKU Relatório';
        const request = this.filterState.getRequest(this.typeSearch, this.skuView);
        const url = `${environment.endpoints.skuService}/analytical-report-sku/async?`;
        this.eventChannel.openEventSource(url, headers, request.params, type);
    }

    createTable(data, ordered?) {
        this.gridData = data;
        this.total = data.total === null ? 0 : data.total;
        this.totalImps = data.totalImps === null ? 0 : data.totalImps;
        const num = Math.ceil(data.total / 30);
        this.pages = Array.apply(null, { length: num }).map(Number.call, Number);
        this.columns = this.createColumns();
        this.rows = this.extractRows(data.skus);
        const options = {
            defaultColumnWidth: 120,
            editable: false,
            enableCellNavigation: true,
            enableTextSelectionOnCells: true,
            rowHeight: 35,
            forceFitColumns: false,
        };

        this.dataView = new Slick.Data.DataView({ inlineFilters: false });
        this.dataView.beginUpdate();
        this.dataView.setItems(this.rows);
        this.dataView.endUpdate();

        this.StatusGrid = new Slick.Grid('#SkuStatusGrid', this.dataView, this.columns, options);
        this.StatusGrid.onHeaderClick.subscribe(this.onHeaderClick.bind(this));
        this.StatusGrid.onHeaderClick.subscribe(
            function (e, args) {
                this.order(e, args);
            }.bind(this)
        );

        this.StatusGrid.onClick.subscribe(this.onClick.bind(this));

        this.StatusGrid.onCellChange.subscribe(
            function (e, args) {
                this.dataView.updateItem(args.item.id, args.item);
            }.bind(this)
        );

        this.dataView.onRowCountChanged.subscribe(
            function (e, args) {
                this.grid.updateRowCount();
                this.grid.render();
            }.bind(this)
        );

        this.dataView.onRowsChanged.subscribe(
            function (e, args) {
                this.grid.invalidateRows(args.rows);
                this.grid.render();
            }.bind(this)
        );

        this.StatusGrid.getCanvases().bind('mousedown', (event) => {
            if (event.detail > 1) {
                event.preventDefault();
            }
        });
        this.setVisible();
    }

    verifyDates(datePicker, date) {
        if (date.replace(/\D/g, '').length === 8) {
            if (datePicker === 'startDate') {
                if (moment(date, 'DD/MM/YYYY').isAfter(moment(this.filterState.getEndDate(), 'DD/MM/YYYY'))) {
                    this.filterState.setEndDate(date);
                    this.filterState.setStartDate(date);
                    setTimeout(() => {
                        this.updateDateInputs();
                    }, 300);
                } else {
                    this.filterState.setStartDate(date);
                    this.updateDateInputs();
                }
            } else {
                if (moment(this.filterState.getStartDate(), 'DD/MM/YYYY').isAfter(moment(date, 'DD/MM/YYYY'))) {
                    this.filterState.setStartDate(date);
                    this.filterState.setEndDate(date);
                    setTimeout(() => {
                        this.updateDateInputs();
                    }, 300);
                } else {
                    this.filterState.setEndDate(date);
                    this.updateDateInputs();
                }
            }
        }
    }

    getPath() {
        return this.path;
    }
    setPath(path) {
        this.path = path;
    }
    getSort() {
        return this.sort;
    }
    setSort(sort) {
        if (sort) {
            this.sort = true;
        } else {
            this.sort = !this.sort;
        }
    }

    private order(e: any, args: any) {
        if ($(e.target).hasClass('la')) {
            this.filterState.setSort(args.column.pathParam);
            const lastPath = this.getPath();
            this.setPath(args.column.path);
            if (lastPath === this.getPath()) {
                this.setSort(false);
            } else {
                this.setSort(true);
            }
            this.loadSkuStatusTable(true);
        }
    }

    private onClick(e, args) {
        const item = this.dataView.getItem(args.row);
        if ($(e.target).hasClass('ImpValue')) {
            this.imp = item.imp;
            if (
                navigator.userAgent.indexOf('MSIE') !== -1 ||
                navigator.appVersion.indexOf('Trident/') > -1 ||
                window.navigator.userAgent.indexOf('Edge') > -1
            ) {
                this.router.navigate([`./supply-chain/importacao/${item.companyCode}/${this.imp}`]);
            } else {
                window.open(`./supply-chain/importacao/${item.companyCode}/${this.imp}`);
            }
        } else if ($(e.target).hasClass('ncmValue')) {
            this.showNcmDetail(e);
            $('sku-status-chart .ncmModal').removeClass('hidden');
            this.ncm = item.ncm;
        } else if ($(e.target).hasClass('multipleReferences')) {
            $('sku-status-chart .RefModal').removeClass('hidden');
            this.references = item.clientReference;
        } else if ($(e.target).hasClass('multipleDi')) {
            $('sku-status-chart .diModal').removeClass('hidden');
            this.imp = item.imp;
            this.diList = item.diList;
        }
    }

    private onHeaderClick(e, args) {
        const item = this.dataView.getItem(args.row);
        if ($(e.target).hasClass('material-icons')) {
            this.setInvisible();
            this.skuView = this.skuView === 'skuClient' ? 'skuComex' : 'skuClient';
            this.createTable(this.gridData);
        }
    }

    private resetPages() {
        this.queryId = Math.random();
        this.filterState.setOffset(0);
    }

    private updateDateInputs() {
        if (!moment(this.filterState.getStartDate(), 'DD/MM/YYYY').isValid()) {
            this.filterState.setStartDate(moment().format('DD/MM/YYYY'));
        }

        if (!moment(this.filterState.getEndDate(), 'DD/MM/YYYY').isValid()) {
            this.filterState.setEndDate(moment().format('DD/MM/YYYY'));
        }

        setTimeout(() => {
            ($('sku-status-chart input#startDatePicker') as any).val(this.filterState.getStartDate()).Zebra_DatePicker({
                onSelect: (selectedDate) => {
                    this.verifyDates('startDate', selectedDate);
                },
                onClear: () => {
                    this.filterState.setStartDate(undefined);
                    this.filterState.setEndDate(undefined);
                    this.updateDateInputs();
                },
            });

            ($('sku-status-chart input#endDatePicker') as any).val(this.filterState.getEndDate()).Zebra_DatePicker({
                direction: [this.filterState.getStartDate(), false],
                onSelect: (selectedDate) => {
                    this.filterState.setEndDate(selectedDate);
                },
            });
        }, 300);

        Inputmask({ mask: '99/99/9999' }).mask($('sku-status-chart input#startDatePicker'));
        Inputmask({ mask: '99/99/9999' }).mask($('sku-status-chart input#endDatePicker'));
    }

    private formatImp(row, cell, value, columnDef, dataContext) {
        return `<span class='ImpValue'>${value}</span>`;
    }
    private formatSku(row, cell, value, columnDef, dataContext) {
        return `<span class='skuValue'>${value}</span>`;
    }
    private formatDescriptions(row, cell, value, columnDef, dataContext) {
        if (value === null) {
            return '';
        } else {
            return `<span title='${value}'>${value}</span>`;
        }
    }
    private formatNcm(row, cell, value, columnDef, dataContext) {
        if (value === null) {
            return '';
        } else {
            return `<b class='ncmValue'>${value}</b>`;
        }
    }
    private formatValue(row, cell, value, columnDef, dataContext) {
        const format = { minimumFractionDigits: 2 };
        let newValue;
        if (value === null) {
            return '';
        } else {
            newValue = value.toLocaleString('pt-BR', format);
        }
        return `<span class="formatValue">${dataContext.currency || ''} ${newValue}</span>`;
    }
    private formatStatus(row, cell, value, columnDef, dataContext) {
        const img = dataContext.clazz.replace('label ', '');
        return `<img title="${value}" class="impImg" style='width: 30px' src="assets/img/icons/${img}-checked.png">`;
    }
    private formatModal(row, cell, value, columnDef, dataContext) {
        let newValue = value || '';
        newValue = newValue.toLowerCase();
        return `<i class='material-icons' title='
        ${
            newValue === 'aereo' ? 'Aéreo' : newValue === 'rodoviario' ? 'Rodoviário' : 'Marítimo'
        }' style='margin-top: -2px;color: #1d8ab3'>
        ${
            newValue === 'aereo' ? 'local_airport' : newValue === 'rodoviario' ? 'local_shipping' : 'directions_boat'
        }</i>`;
    }
    private formatRef(row, cell, value, columnDef, dataContext) {
        return value === undefined ? '' : value;
    }
    private formatDate(row, cell, value, columnDef, dataContext) {
        return value === '' || value === null || value === undefined
            ? ''
            : moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY');
    }

    private formatChannel(row, cell, value, columnDef, dataContext) {
        if (!value) {
            value = 'DESCONHECIDO';
        }
        if (value.includes('Várias')) {
            return `<span class="multipleDi">${value}</span>`;
        }
        const clazz = value.toLowerCase();
        return `<span style='display:flex;justify-content:center'><div class='channelBall ${clazz}'></div></span>`;
    }

    private formatDiNumber(row, cell, value, columnDef, dataContext) {
        if (!value) {
            return '';
        }
        if (value.includes('Várias')) {
            return `<span class="multipleDi">${value}</span>`;
        }
        return value;
    }

    private formatDiDate(row, cell, value, columnDef, dataContext) {
        if (!value) {
            return '';
        }
        if (value.includes('Várias')) {
            return `<span class="multipleDi">${value}</span>`;
        }
        return moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY');
    }

    private createColumns() {
        const cols = [];
        if (this.skuView === 'skuClient') {
            if (this.currentUser.has('INTERNAL_FILTERS')) {
                cols.push({
                    id: 0,
                    name: "<div id='sku'><i class='material-icons'>loop</i>SKU</div>",
                    width: 145,
                    field: 'customerSku',
                    formatter: this.formatSku,
                    cssClass: 'impHeader',
                    headerCssClass: 'impHeader',
                    path: 'customerSku',
                });
            } else {
                cols.push({
                    id: 0,
                    name: 'SKU',
                    width: 145,
                    field: 'customerSku',
                    formatter: this.formatSku,
                    cssClass: 'impHeader',
                    headerCssClass: 'impHeader',
                    path: 'customerSku',
                });
            }
        } else {
            cols.push({
                id: 0,
                name: "<div id='sku'><i class='material-icons'>loop</i>SKU Comex</div>",
                width: 145,
                field: 'comexportSku',
                formatter: this.formatSku,
                cssClass: 'impHeader',
                headerCssClass: 'impHeader',
                path: 'comexportSku',
            });
        }
        cols.push({
            id: 1,
            name: 'IMP',
            width: 100,
            field: 'imp',
            formatter: this.formatImp,
            cssClass: 'impHeader',
            headerCssClass: 'impHeader',
            path: '',
        });
        cols.push({
            id: 2,
            name: 'Ref. Cliente',
            width: 110,
            field: 'clientReference',
            path: '',
            formatter: this.formatRef,
        });
        cols.push({ id: 3, name: 'Cliente', field: 'clientName', path: '' });
        cols.push({
            id: 4,
            name: 'Descrição',
            width: 160,
            field: 'description',
            formatter: this.formatDescriptions,
            path: 'description',
        });
        cols.push({
            id: 5,
            name: 'Modal',
            width: 90,
            field: 'modal',
            formatter: this.formatModal,
            cssClass: 'impHeader',
            headerCssClass: 'impHeader',
            path: '',
        });
        cols.push({ id: 6, name: 'Invoice', width: 100, field: 'invoiceNumber', path: '' });
        cols.push({ id: 7, name: 'NCM', width: 85, field: 'ncm', formatter: this.formatNcm, path: 'ncm' });
        cols.push({
            id: 8,
            name: 'Fornecedor',
            width: 120,
            field: 'manufacturer',
            formatter: this.formatDescriptions,
            path: 'manufacturer',
        });
        cols.push({
            id: 9,
            name: 'Status',
            width: 75,
            field: 'status',
            formatter: this.formatStatus,
            headerCssClass: 'impHeader',
            path: '',
        });
        cols.push({
            id: 10,
            name: 'ETA',
            width: 95,
            field: 'eta',
            path: '',
            formatter: this.formatDate,
            cssClass: 'impHeader',
            headerCssClass: 'impHeader',
        });
        cols.push({ id: 11, name: 'Qtd', width: 80, field: 'quantity', path: 'quantity' });
        cols.push({
            id: 12,
            name: 'Valor Unitário',
            width: 130,
            field: 'price',
            formatter: this.formatValue,
            cssClass: 'right-align',
            headerCssClass: 'right-align',
            path: 'price',
        });
        cols.push({
            id: 13,
            name: 'Valor Total',
            width: 130,
            field: 'totalPrice',
            formatter: this.formatValue,
            cssClass: 'right-align',
            headerCssClass: 'right-align',
            path: '',
        });
        cols.push({
            id: 14,
            name: 'Registro DA',
            width: 130,
            field: 'daNumber',
            formatter: this.formatDiNumber,
            cssClass: 'impHeader',
            headerCssClass: 'impHeader',
        });
        cols.push({
            id: 15,
            name: 'Data Registro DA',
            width: 160,
            field: 'daRegistrationDate',
            formatter: this.formatDiDate,
            cssClass: 'impHeader',
            headerCssClass: 'impHeader',
        });
        cols.push({
            id: 16,
            name: 'Canal DA',
            width: 110,
            field: 'daChannel',
            formatter: this.formatChannel,
            cssClass: 'impHeader',
            headerCssClass: 'impHeader',
        });
        cols.push({
            id: 17,
            name: 'Registro DI',
            width: 130,
            field: 'diNumber',
            formatter: this.formatDiNumber,
            cssClass: 'impHeader',
            headerCssClass: 'impHeader',
        });
        cols.push({
            id: 18,
            name: 'Data Registro DI',
            width: 160,
            field: 'diRegistrationDate',
            formatter: this.formatDiDate,
            cssClass: 'impHeader',
            headerCssClass: 'impHeader',
        });
        cols.push({
            id: 19,
            name: 'Canal DI',
            width: 110,
            field: 'diChannel',
            formatter: this.formatChannel,
            cssClass: 'impHeader',
            headerCssClass: 'impHeader',
        });

        for (const col of cols) {
            const name = col.name;
            col.ptName = name;
            let newName;
            let pathParam;
            if (col.path !== '') {
                if (col.path === this.path) {
                    if (this.sort) {
                        newName = `<div id="${col.path}" class="headerItem"><i class='la la-arrow-down'></i> ${name}
                        <div title="Remover Filtro" class="columnFilter">
                        <i class="comexport-close-cross"></i></div>  <div>`;
                        pathParam = `${col.path},desc`;
                    } else {
                        newName = `<div id="${col.path}" class="headerItem"><i class='la la-arrow-up'></i> ${name}
                        <div title="Remover Filtro" class="columnFilter">
                        <i class="comexport-close-cross"></i></div> <div>`;
                        pathParam = `${col.path},asc`;
                    }
                } else {
                    newName = `<div id="${col.path}" class="headerItem"><i class='la default'>•</i> ${name}<div title="Remover Filtro" class="columnFilter">
                    <i class="comexport-close-cross"></i></div><div>`;
                    pathParam = `${col.path},asc`;
                }
            } else {
                newName = `<div>${name}<div title="Remover Filtro" class="columnFilter">
                <i class="comexport-close-cross"></i></div><div>`;
            }
            col.name = newName;
            col.pathParam = pathParam;
        }
        cols.sort((a, b) => {
            if (a.id > b.id) {
                return 1;
            }
            if (a.id < b.id) {
                return -1;
            }
            return 0;
        });
        return cols;
    }

    private extractRows(data: any) {
        const rows = [];
        for (let i = 0; i < data.length; i++) {
            const row = data[i];
            const {
                customerSku,
                comexportSku,
                imp,
                client,
                companyCode,
                description,
                ncm,
                currency,
                quantity,
                transportTypeDescription,
                invoiceNumber,
                price,
                totalPrice,
                manufacturer,
                status,
                clientReference,
                eta,
            } = row;
            const clazz = `label ${status.code.toLowerCase()}`;

            let diList = [
                {
                    channel: '',
                    registrationDate: null,
                    registrationNumber: '',
                },
            ];

            let daNumber = '';
            let daChannel = '';
            let daRegistrationDate = null;

            let diNumber = '';
            let diChannel = '';
            let diRegistrationDate = null;

            if (row.clearanceData !== null && row.clearanceData !== undefined) {
                if (row.clearanceData.daData !== null && row.clearanceData.daData !== undefined) {
                    daNumber = row.clearanceData.daData.registrationNumber;
                    this.daNumber = daNumber;
                    daChannel = row.clearanceData.daData.channel;
                    daRegistrationDate = row.clearanceData.daData.registrationDate;
                }

                const diDataList = row.clearanceData.diDataList;
                if (diDataList !== null) {
                    if (diDataList.length > 1) {
                        const multipleString = `Várias (${diDataList.length})`;
                        diChannel = multipleString;
                        diRegistrationDate = multipleString;
                        diNumber = multipleString;
                        diDataList.forEach((data) => {
                            data.registrationDate = moment(data.registrationDate).format('DD/MM/YYYY');
                            const channel = data.channel.toLowerCase();
                            data.channel = `<div class='channelBall ${channel}'></div>`;
                        });
                        diList = diDataList;
                    } else if(diDataList.length === 1) {
                        const di = diDataList[0];
                        diChannel = di.channel;
                        diRegistrationDate = di.registrationDate;
                        diNumber = di.registrationNumber;
                    }
                }
            }

            rows.push({
                id: i,
                clazz,
                comexportSku,
                imp,
                companyCode,
                clientReference,
                customerSku: customerSku ? customerSku : comexportSku,
                clientName: client ? client.name : '',
                clientCode: client ? client.code : '',
                description,
                ncm,
                quantity,
                invoiceNumber,
                price,
                totalPrice,
                manufacturer: manufacturer ? manufacturer.name : '',
                status: status ? status.name : '',
                currency,
                eta,
                modal: transportTypeDescription,
                daNumber,
                daRegistrationDate,
                daChannel,
                diNumber,
                diRegistrationDate,
                diChannel,
                diList,
            });
        }
        return rows;
    }

    private _getSkuStatus() {
        const request = this.filterState.getRequest(this.typeSearch, this.skuView);
        const statusCodes = this.filterState.getStatus().map((status) => status && status.code);

        this._skuStatusService.getSkuStatusList(request).subscribe((skuStatusList) => {
            this.allStatus = skuStatusList;
            this.preSelectedStatus = this.allStatus
                .filter((status) => {
                    return statusCodes.length
                        ? statusCodes.indexOf(status.code) !== -1
                        : status.code !== 'FINALIZADO'
                })
                .map((status) => status.code);
        });
    }

    private callExportMessage() {
        this.exportAsync = true;
        setTimeout(() => {
            this.exportAsync = false;
        }, 5000);
    }

    private setVisible() {
        this.asyncSkuStatusTable = true;
        $('sku-status-chart #SkuStatusGrid').removeClass('invisible');
        $('sku-status-chart #infoLine').removeClass('invisible');
        $('sku-status-chart pagination').removeClass('invisible');
    }

    private setInvisible() {
        this.asyncSkuStatusTable = false;
        $('sku-status-chart #SkuStatusGrid').addClass('invisible');
        $('sku-status-chart #infoLine').addClass('invisible');
        $('sku-status-chart pagination').addClass('invisible');
    }

    // Get IMPS
    private getSKU(request): Observable<any> {
        return this.http.get(`${environment.endpoints.skuService}/quick-sku-status`, request);
    }
    private getNCMS(): Observable<any> {
        return this.http.get('/assets/app/js/ncms.json');
    }

    trackByFn(index: number, item: any) {
        return index;
    }
}
