import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { environment } from '#environment';
import { DashComponent } from 'app/theme/pages/default/custom-dashboard/dashComponent.model';
import { UserService } from "app/auth/_services/user.service";
import { LoginService } from 'app/auth/_services/login.service';
import { DASHBOARD_COMPONENTS, OPERATIONAL_DASH_COMPONENT } from 'app/theme/pages/default/custom-dashboard/custom-dashboard.constants';
import { UtilsService } from '#services/_utils/utils.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LoginPermissionService {

    client = this._loginService.getClient();
    currentUser$ = this._userService.currentUser$;
    currentUser = this._userService.getCurrentUser();
    asyncComponents: boolean = true;
    customComponentId;

    private _customDashboardComponent = "CustomDashboardComponent";

    private _storedComponents: DashComponent[] = [];
    private _defaultComponents: DashComponent[] = [];
    private _customComponents: DashComponent[] = [];
    private _mergedComponents: DashComponent[] = [];

    private _operationalMergedComponents = _.cloneDeep(OPERATIONAL_DASH_COMPONENT);
    private _dashboardComponents = _.cloneDeep(DASHBOARD_COMPONENTS);

    constructor(
        public router: Router,
        private _http: HttpClient,
        private _userService: UserService,
        private _loginService: LoginService,
        private _utilsService: UtilsService,
        private _translateService: TranslateService
    ) { }

    getDefaultComponentList(): DashComponent[] {
        return this._defaultComponents;
    }

    setDefaultComponentList(defaultComponents: DashComponent[]): void {
        this._defaultComponents = defaultComponents;
    }

    getCustomComponentList(): DashComponent[] {
        return this._customComponents;
    }

    setCustomComponentList(customComponents: DashComponent[]): void {
        this._customComponents = customComponents;
    }

    getMergedComponentList(operational?): DashComponent[] {
        return operational ? this._operationalMergedComponents || [] : this._mergedComponents || [];
    }

    setMergedComponentList(mergedComponents: DashComponent[]): void {
        this._mergedComponents = mergedComponents;
    }

    getStoredComponentList(): DashComponent[] {
        return this._storedComponents;
    }

    pushToMerged(component: DashComponent) {
        this._mergedComponents.push(component);
    }

    pushToStored(component: DashComponent) {
        const storedIndex = this.getStoredComponentList().findIndex((storedComponent) => storedComponent.component === component.component);
        const mergedIndex = this.getMergedComponentList().findIndex((mergedComponent) => mergedComponent.component === component.component);
        if (storedIndex === -1 && mergedIndex === -1) {
            this._storedComponents.push(component);
        }
    }

    getComponents(): Observable<DashComponent[]> {
        return this.currentUser$.pipe(
            take(1),
            map((user) => {
                return this._mergedComponents.filter((c) => user.has(c.permission));
            })
        );
    }

    setComponents(componentList): void {
        this._mergedComponents = componentList;
    }

    setStoredComponents(componentList): void {
        this._storedComponents = componentList;
    }

    changeComponent(newComponent): void {
        this._mergedComponents[this._mergedComponents.findIndex((component) => component.component === newComponent.component)] = newComponent;
    }

    addComponent(addComponent): void {
        this._storedComponents.splice(this._storedComponents.findIndex((component) => component.component === addComponent.component), 1);
        this._mergedComponents.unshift(addComponent);
    }

    removeComponent(deleteComponent): void {
        this._mergedComponents.splice(this._mergedComponents.findIndex((component) => component.component === deleteComponent.component), 1);
        this._storedComponents.unshift(deleteComponent);
    }

    saveCustomChanges(): void {
        this.asyncComponents = true;
        this.saveOrUptadeCustomComponents(this.getPositionedMergedComponents('mergedComponents')).subscribe((response) => {
            this.loadComponents(true);
        }, error => {
            this.asyncComponents = false;
            this._utilsService.errorHandler(error, this._translateService.instant('dashboard.customDash'));
        });
    }

    resetChanges(): void {
        this.setMergedComponentList([]);
        this.setMergedComponentList(this.getDefaultComponentList());
        this.setStoredComponents([]);
    }

    loadComponents(save?: boolean): void {
        this._resetComponentLists();

        this.getDefaultComponents().subscribe((defaultComponents) => {
            const defaultItems = defaultComponents?.items ?? [];
            this.updateTitleKeys(defaultItems);
            this.setDefaultComponentList(defaultItems);

            this.getCustomComponents().subscribe((customComponents) => {
                const customItems = customComponents?.items ?? [];
                this.updateTitleKeys(customItems);
                this.setCustomComponentList(customItems);

                this.populateMergedComponents();
                this.populateStoredComponents();
                this.asyncComponents = false;

                if (save) {
                    sessionStorage.setItem('lastPage', '/index');
                    this.router.navigate(['/']);
                }
            });
        });
    }

    updateTitleKeys(components: any[]): void {
        components.forEach((itemComponent) => {
            const dashboardComponent = this._dashboardComponents?.find((component) => component.component === itemComponent.component);
            if (dashboardComponent) {
                itemComponent.titleKey = dashboardComponent?.titleKey;
            }
        });
    }

    populateStoredComponents(): void {
        this.getDefaultComponentList().forEach((defaultComponent: DashComponent) => {
            const found = this.getCustomComponentList()?.find((customComponent) => defaultComponent.component === customComponent.component);
            if (!found) {
                this.pushToStored(defaultComponent);
            }
        });
    }

    populateMergedComponents(): void {
        this.getCustomComponentList().forEach((customComponent: DashComponent) => {
            const found = this.getDefaultComponentList()?.find((defaultComponent) => defaultComponent.component === customComponent.component);
            if (found) {
                this.pushToMerged(customComponent);
            }
        });
    }

    getDefaultComponents(): Observable<any> {
        return this._http.get(`${environment.endpoints.userPreferencesService}/component/default?componentId=${this._customDashboardComponent}&isCustomer=${this.currentUser.has('FOLLOWUP_COMPLETE')  ? false : true}`);
    }

    getCustomComponents(): Observable<any> {
        return this._http.get(`${environment.endpoints.userPreferencesService}/component?componentId=${this._customDashboardComponent}&isCustomer=${this.currentUser.has('FOLLOWUP_COMPLETE')  ? false : true}`);
    }

    getCustomWidgets(): Observable<any> {
        return this._http.get(`${environment.endpoints.customWidgetsService}`);
    }

    saveOrUptadeCustomComponents(saveList): Observable<any> {
        var dat = {
            label: 'Dashboard',
            componentId: this._customDashboardComponent,
            isCustomer: this.currentUser.has('FOLLOWUP_COMPLETE') ? false : true,
            items: saveList
        }

        return this.customComponentId ? this.updateComponent(dat) :  this.saveComponent(dat);
    }

    getPositionedMergedComponents(container, componentChangeSize?): DashComponent[] {
        const positionedMergedComponents: DashComponent[] = [];
        const components = $(`#${container}`)[0].children;
        Array.from(components).forEach((component) => {
            const found = this._mergedComponents?.find((mergedComponent) => mergedComponent.component === component.id);
            if (found) {
                positionedMergedComponents.push(found);
            }
        });
        return componentChangeSize ? this._getMergedAfterChangeSize(positionedMergedComponents, componentChangeSize) : positionedMergedComponents;
    }

    setCustomComponentId(id: string){
        this.customComponentId = id;
    }

    private _getMergedAfterChangeSize(positionedMergedComponents, componentChangeSize) {
        const index = positionedMergedComponents.findIndex((component: DashComponent) => component.component === componentChangeSize);
        if (index !== -1) {
            positionedMergedComponents[index].size === 100
                ? (positionedMergedComponents[index].size = 50)
                : (positionedMergedComponents[index].size = 100);
        }
        return positionedMergedComponents;
    }

    private _resetComponentLists(): void {
        this._defaultComponents = [];
        this._mergedComponents = [];
        this._customComponents = [];
        this._storedComponents = [];
    }

    private saveComponent(component){
        return this._http.post(
            `${environment.endpoints.userPreferencesService}/component?isCustomer=${this.currentUser.has('FOLLOWUP_COMPLETE')  ? false : true}`,
            component,
            { observe: 'response', responseType: 'json' }
        ).pipe(
            map((resp: any) => {
                this.customComponentId = resp.body.id;
                return resp;
            })
        );
    }

    private updateComponent(component){
        return this._http.put(
            `${environment.endpoints.userPreferencesService}/component/${this.customComponentId}?isCustomer=${this.currentUser.has('FOLLOWUP_COMPLETE')  ? false : true}`,
            component,
            { observe: 'response', responseType: 'json' }
        );
    }
}
