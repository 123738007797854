<div *ngIf="asyncTraderDirectors === false" class="fullLoader admModalLoader">
    <loader></loader>
</div>

<i (click)="close()" id="close" class="la la-close"></i>

<div class="admin-account-border">
    <div class="line-space">
        <div>
            <cmx-listing
                [list]="traders"
                height="calc(55vh - 160px)"
                searchPlaceholder="Buscar Trader"
                [hasAddButton]="true"
                (onNewItem)="clearUserForm('trader')"
                (onSelect)="selectPermissionTrader($event)"
            ></cmx-listing>
        </div>
        <div style="margin-top: 25px">
            <cmx-listing
                [list]="directors"
                height="calc(55vh - 160px)"
                searchPlaceholder="Buscar Diretor"
                [hasAddButton]="true"
                (onNewItem)="clearUserForm('director')"
                (onSelect)="selectPermissionDirector($event)"
            ></cmx-listing>
        </div>
    </div>
</div>
<div class="admItem">
    <a *ngIf="!newDomain" (click)="admService.openDeleteModal(modal)" class="deleteBtn"><i class="la la-trash"></i></a>
    <span *ngIf="traderDomain && newDomain" class="admTitle">Adicionar Trader</span>
    <span *ngIf="traderDomain && !newDomain" class="admTitle">Editar {{selectedTrader.name}}</span>
    <span *ngIf="!traderDomain && newDomain" class="admTitle">Adicionar Diretor</span>
    <span *ngIf="!traderDomain && !newDomain" class="admTitle">Editar {{selectedDirector.name}}</span>
    <hr>
    <div class="admForm">
        <label>Nome Completo</label>
        <div *ngIf="traderDomain">
            <input class="admInput" type="text" [(ngModel)]="selectedTrader.name" (keyup)="identifyChange('trader')">
        </div>
        <div *ngIf="!traderDomain">
            <input class="admInput" type="text" [(ngModel)]="selectedDirector.name" (keyup)="identifyChange('director')">
            <label>Código</label>
            <div *ngIf="newDomain">
                <input class="admInput" type="text" [(ngModel)]="selectedDirector.code" (keyup)="identifyChange('client')">
            </div>
            <div *ngIf="!newDomain">
                <input disabled class="admInput" type="text" [(ngModel)]="selectedDirector.code">
            </div>
        </div>
        <div *ngIf="traderDomain && newDomain">
            <label>Diretor Atribuído</label>
            <cmx-dropdown [styles]="{ width: '250px' }" size="md" name="directors" [data]="dropdowns.directors.options" [(ngModel)]="dropdowns.directors.selected" (onScroll)="scrollList($event)"
            [settings]="dropdownSettingsDirectors" (onChange)="identifyChange('trader')"></cmx-dropdown>
        </div>
        <div *ngIf="traderDomain && !newDomain">
            <label>Diretor Atribuído</label>
            <input disabled class="admInput" type="text" [(ngModel)]="dropdowns.directors.selected[0].itemName">
            <label>Grupos Atribuídos</label>
            <div class="box">
                <table class="intern">
                    <tr *ngFor="let group of traderGroups; let i = index; trackBy: trackByFn">
                        <td>{{group.name}}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div *ngIf="!traderDomain && !newDomain">
            <label>Traders Atribuídos</label>
            <div class="box">
                <table class="intern">
                    <tr *ngFor="let trader of directorTraders; let i = index; trackBy: trackByFn">
                        <td>{{trader.name}}</td>
                    </tr>
                </table>
            </div>
        </div>
        <p class="success create hidden"><i (click)='admService.hideSuccess(modal)' class="la la-close"></i>Criado com sucesso!</p>
        <p class="success edit hidden"><i (click)='admService.hideSuccess(modal)' class="la la-close"></i>Editado com sucesso!</p>
        <p class="success delete hidden"><i (click)='admService.hideSuccess(modal)' class="la la-close"></i>Deletado com sucesso!</p>
        <p class="error name hidden"><i (click)='admService.hideError(modal)' class="la la-close"></i>Preencha o campo Nome.</p>
        <p class="error code hidden"><i (click)='admService.hideError(modal)' class="la la-close"></i>Preencha o campo Código.</p>
        <p class="error directorWithTrader hidden"><i (click)='admService.hideError(modal)' class="la la-close"></i>Existem Traders vinculados à esse Diretor</p>
        <p class="error directorCode hidden"><i (click)='admService.hideError(modal)' class="la la-close"></i>Já existe um Diretor com esse código</p>
        <p class="error director hidden"><i (click)='admService.hideError(modal)' class="la la-close"></i>Selecione um Diretor.</p>
        <p class="error err hidden"><i (click)='admService.hideError(modal)' class="la la-close"></i>Algo deu errado.</p>
    </div>
    <hr>
    <button *ngIf="newDomain" (click)="createDomain()" class="add">Adicionar</button>
    <button *ngIf="!newDomain && !hasAnyChange" class="edit disabled">Salvar</button>
    <button *ngIf="!newDomain && hasAnyChange" (click)="editDomain()" class="edit">Salvar</button>
</div>
<div class="deleteModal hidden">
    <p *ngIf="traderDomain">Deseja deletar o trader {{selectedTrader.name}} ?</p>
    <p *ngIf="!traderDomain">Deseja deletar o diretor {{selectedDirector.name}} ?</p>
    <div class="deleteButtons">
        <button (click)="confirmDelete()" class="delete">Deletar</button>
        <button (click)="admService.closeDeleteModal(modal)" class="cancel">Cancelar</button>
    </div>
</div>
