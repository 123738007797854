import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '#environment';
import {  from, Observable } from 'rxjs';
import * as _ from 'lodash';
import * as moment from 'moment';
import { DownloadService } from '#services/_download/download.service';

@Injectable()
export class AutomotiveCrudService {
    private _shipmentBaseUrl: String = environment.endpoints.shipmentService;
    
    constructor(
        private http: HttpClient,
        private _downloadService: DownloadService,
    ) {}


    getBoarding(request): Observable<any> {
        return this._request(request, 'api/v2/shipment');
    }

    getBoardingSummary(request): Observable<any> {
        return this._request(request, 'api/v2/shipment-summary');
    }

    getShipmentByDomainType(shipmentDomainType: string): Observable<any> {
        return this.http.get(`${this._shipmentBaseUrl}/api/v2/shipment-domain?shipmentDomainType=${shipmentDomainType}`);
    }

    getVehicleTypes(): Observable<any> {
        return this.http.get(`${this._shipmentBaseUrl}/api/v2/shipment/vehicle-types`);
    }

    createBoarding(data): Observable<HttpResponse<any>> {
        return this.http.post(`${this._shipmentBaseUrl}/api/v2/shipment`, data, { observe: 'response', responseType: 'json' });
    }

    editBoarding(data, id): Observable<HttpResponse<any>> {
        return this.http.put(`${this._shipmentBaseUrl}/api/v2/shipment/${encodeURIComponent(id)}`, data, { observe: 'response', responseType: 'json' });
    }

    deleteBoarding(id): Observable<HttpResponse<any>> {
        return this.http.delete(`${this._shipmentBaseUrl}/api/v2/shipment?id=${encodeURIComponent(id)}`, { observe: 'response', responseType: 'json' });
    }

    importShipment(file: File): Observable<any> {
        const request = new FormData();
        const filename = file[0] && file[0].name;
        const headers = new HttpHeaders({
            Accept: 'text/csv',
        });
        headers.set('Content-Type', 'multipart/form-data');

        request.append('sheet', file[0], filename);

        return this.http.post(`${this._shipmentBaseUrl}/api/v2/shipment/import`, request, { headers });
    }

    importTemplateShipment(): Observable<any> {
        const headers = new HttpHeaders({
            Accept: 'text/csv',
        });
        return this.http.get(`${this._shipmentBaseUrl}/api/v2/shipment/import/template`, { headers , responseType: 'blob'});

    }

    export(type: string, params): Observable<any> {
        const options = this._downloadService.getSourceOptions();
        if (type === 'xls') {
            options.headers = new HttpHeaders({
                Accept: 'application/vnd.ms-excel',
            });
        } else {
            options.headers = new HttpHeaders({
                Accept: 'text/csv',
            });
        }
        const requestParams = this._getRequestParams(params);

        const url = this.http.post(`${this._shipmentBaseUrl}/api/v2/shipment/export?${requestParams}`, params, options);

        return this._downloadService.downloadFrom(url, `quickShipment-${moment().format('YYYY-MM-DD')}.${type}`);
    }

    private _getRequestParams(params: { [key: string]: any }): string {
        return `${Object.keys(params)
            .map((key) => `${key}=${params[key]}`)
            .join('&')}`;
    }

    private _request(
        requestData: { params: { [key: string]: any }; headers: { [key: string]: any } },
        endpoint: string
    ): any {
        const requestParams = this._getRequestParams(requestData.params);
        const requestHeaders = {
            headers: new HttpHeaders().set('X-Requested-Client-Groups', requestData.headers.clientGroupCodes),
        };
        return this.http.get(`${this._shipmentBaseUrl}/${endpoint}?${requestParams}`, requestHeaders);

    }
}
