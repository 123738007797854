<div class="cmx-filter-line-container" [style.padding-left]="leftMargin">
    <div (click)="applyFilters()" class="apply-button-container">
        <span style="display: flex; align-items: center; margin-top: 2px"
            >Aplicar<span class='material-icons search'>search</span></span>
    </div>
    <div class="divisor"></div>
    <div *ngFor="let component of filterStructure; let index = index; let last = last" class="filter-container"
        [style.width]="getComponentWidth(component?.width, component.type)">
        <ng-container [style.position]="absolute" *ngIf="component.type === 'search'">
            <ng-container *ngTemplateOutlet="filterLineSearch; context: {$implicit: {config: component, index: index}}"></ng-container>
        </ng-container>
        <ng-container [style.position]="absolute" *ngIf="component.type === 'dropdown'">
            <ng-container *ngTemplateOutlet="filterLineDropdown; context: {$implicit: {config: component, index: index}}"></ng-container>
        </ng-container>
        <ng-container [style.position]="absolute" *ngIf="component.type === 'switch'">
            <ng-container *ngTemplateOutlet="filterLineSwitch; context: {$implicit: {config: component, index: index}}"></ng-container>
        </ng-container>
        <ng-container [style.position]="absolute" *ngIf="component.type === 'datepicker'">
            <ng-container *ngTemplateOutlet="filterLineDatepicker; context: {$implicit: {config: component, index: index}}"></ng-container>
        </ng-container>
        <ng-container [style.position]="absolute" *ngIf="component.type === 'timer'">
            <ng-container *ngTemplateOutlet="filterLineTimer; context: {$implicit: {config: component, index: index}}"></ng-container>
        </ng-container>
        <ng-container [style.position]="absolute" *ngIf="component.type === 'button'">
            <ng-container *ngTemplateOutlet="filterLineButton; context: {$implicit: {config: component, index: index}}"></ng-container>
        </ng-container>
        <ng-container [style.position]="absolute" *ngIf="component.type === 'frozenColumn'">
            <ng-container *ngTemplateOutlet="filterLineFrozenColumn; context: {$implicit: {config: component, index: index}}"></ng-container>
        </ng-container>
    </div>

</div>

<!-- Search -->
<ng-template #filterLineSearch let-data>
    <input pInputText type="text" class="cmx-filter-line-input"
        [style.width]="data.config.width + 'px'"
        [id]="'cmx-filter-line-search-' + data.index"
        [name]="data.config.code"
        [placeholder]="data.config?.component?.placeholder || 'Buscar'" 
        [ngModel]="data.config?.component?.searchValue || ''"
        [maxlength]="data.config?.component?.maxLength"
        (keydown.enter)="filterChanged($event, 'search', data.config.code)">
</ng-template>

<!-- Dropdown -->
<ng-template #filterLineDropdown let-data>
    <cmx-dropdown class="cmx-filter-line-dropdown"
        [id]="'cmx-filter-line-dropdown-' + data.index"
        [name]="data.config.code"
        [data]="data.config?.component?.dropdownData"
        [settings]="data.config?.component?.dropdownSettings"
        [ngModel]="data.config?.component?.dropdownSelected"
        (ngModelChange)="filterChanged($event, 'dropdown', data.config.code)"
    ></cmx-dropdown>
</ng-template>

<!-- Switch -->
<ng-template #filterLineSwitch let-data>
    <cmx-filter-switch class="cmx-filter-line-switch"
        [id]="'cmx-filter-line-switch-' + data.index"
        [values]="data.config?.component?.switchData"
        [inputIndex]="data.config?.component?.switchIndex"
        (onSwitch)="filterChanged($event, 'switch', data.config.code)"
    ></cmx-filter-switch>
</ng-template>

<!-- Datepicker -->
<ng-template #filterLineDatepicker let-data>
    <cmx-filter-datepicker class="cmx-filter-line-datepicker"
        [id]="'cmx-filter-line-datepicker-' + data.index"
        [type]="data.config?.component?.type || 'range-date'"
        [periodType]="data.config?.component?.periodType || 'day'"
        [date]="data.config?.component?.date"
        [inverted]="data.config?.component?.inverted"
        [lockable]="data.config?.component?.lockable"
        [noData]="data.config?.component?.noData"
        [startActived]="data.config?.component?.startActived === false ? false : true"
        [endActived]="data.config?.component?.endActived === false ? false : true"
        [singleNoData]="data.config?.component?.singleNoData"
        [startDate]="data.config?.component?.startDate"
        [endDate]="data.config?.component?.endDate"
        (onChangeDates)="filterChanged($event, 'datepicker', data.config.code)"
    ></cmx-filter-datepicker>
</ng-template>

<!-- Button -->
<ng-template #filterLineButton let-data>
    <div class="cmx-filter-line-button"
        [id]="'cmx-filter-line-button-' + data.index"
        title="{{ data.config?.component?.title }}: {{ data.config?.component?.clicked ? 'Ligado' : 'Desligado' }}"
        [ngClass]="{ clicked: data.config?.component?.clicked }"
        (click)="filterChanged($event, 'button', data.config.code, data.config?.component)">
        <em class="material-icons">{{ data.config?.component?.icon }}</em>
    </div>
</ng-template>

<!-- Timer -->
<ng-template #filterLineTimer let-data>
    <cmx-filter-reload-timer class="cmx-filter-line-timer"
        [id]="'cmx-filter-line-timer-' + data.index"
        [autoReset]="data.config?.component?.autoReset === false ? false : true"
        [seconds]="data.config?.component?.seconds || 600"
        (onTimerFinish)="filterChanged($event, 'timer', data.config.code)"
    ></cmx-filter-reload-timer>
</ng-template>

<!-- Frozen Column -->
<ng-template #filterLineFrozenColumn let-data>
    <cmx-filter-frozen-column class="cmx-filter-line-frozen-column"
        [id]="'cmx-filter-line-frozen-column-' + data.index"
        [quantity]="data.config?.component?.quantity || 0"
        (onChangeNumber)="filterChanged($event, null, 'frozenColumn')"
    ></cmx-filter-frozen-column>
</ng-template>
