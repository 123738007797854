<div class="page-title-identifier">Taxas Câmbiais</div>
<div id="currency-exchange-page">

    <div *ngIf="asyncLoad" class="fullLoader">
        <span class="exporting">{{ 'common::strings.loading' | translate }}</span>
        <loader></loader>
    </div>

    <div class="groupTitle">
        <div class="titleLine">
            <div class="titleContainer">
                <span>Financeiro - Taxas</span>
            </div>
        </div>

        <div class="dates">

            <div class="btnMonth leftBtnMonth">
                <i (click)="reloadDate('subtract')" class="la la-angle-left"></i>
            </div>

            <span (click)="selectCurrencyExchangeMonth($event)"
                  *ngFor="let currencyExchangeMonth of currencyExchangeYearList; let even = even; let odd = odd; let first = first; let last = last; trackBy: trackByFn"
                  [ngClass]="{ first: first, last: last, 'span-date':even, 'span-date-n':odd }">{{currencyExchangeMonth.format('MM/YYYY')}}</span>

            <div class="btnMonth rightBtnMonth">
                <i (click)="reloadDate('add')" class="la la-angle-right"></i>
            </div>
        </div>
    </div>

    <hr>

    <div class="taxContainer">

        <div class="taxForm">

            <div class="taxGroup">
                <label>Taxa Média</label>
                <div class="taxGroup">
                    <input (keydown)="maskCurrencyExchange()" (keyup)="maskCurrencyExchange()"
                           [(ngModel)]="selectedCurrencyExchange.averageCurrencyExchange" class="taxInput"
                           type="number">
                </div>
            </div>

            <div class="taxGroup">
                <label>Taxa Histórica</label>
                <div class="taxGroup">
                    <input (keydown)="maskCurrencyExchange()" (keyup)="maskCurrencyExchange()"
                           [(ngModel)]="selectedCurrencyExchange.historicalExchangeRate" class="taxInput" step="0.0001"
                           type="number">
                </div>
            </div>

            <div class="taxGroup">
                <label>Taxa Histórica Anual</label>
                <div class="taxGroup">
                    <input (keydown)="maskCurrencyExchange()" (keyup)="maskCurrencyExchange()"
                           [(ngModel)]="selectedCurrencyExchange.annualHistoricalExchangeRate" class="taxInput"
                           step="0.0001" type="number">
                </div>
            </div>

            <div class="taxGroup">
                <label>Taxa Fim</label>
                <div class="taxGroup">
                    <input (keydown)="maskCurrencyExchange()" (keyup)="maskCurrencyExchange()"
                           [(ngModel)]="selectedCurrencyExchange.finalExchangeRate" class="taxInput" step="0.0001"
                           type="number">
                </div>
            </div>

        </div>

        <div class="buttons">
            <p class="error notaxselected hidden"><i (click)='hideError()' class="la la-close"></i>É necessário
                selecionar
                um período.</p>
            <p class="error notax hidden"><i (click)='hideError()' class="la la-close"></i>Todas as taxas precisam ser
                definidas.</p>
            <p class="error nocreated hidden"><i (click)='hideError()' class="la la-close"></i>A Taxa não pôde ser
                criada.
            </p>
            <p class="error noedited hidden"><i (click)='hideError()' class="la la-close"></i>A Taxa não pôde ser
                editada.
            </p>
            <p class="error hastax hidden"><i (click)='hideError()' class="la la-close"></i>Já existe uma taxa definida
                com
                estes dados.</p>
            <p class="success created hidden"><i (click)='hideError()' class="la la-close"></i>Taxa criada com sucesso.
            </p>
            <p class="success edited hidden"><i (click)='hideError()' class="la la-close"></i>Taxa editada com sucesso.
            </p>
            <cmx-button
                variant="light"
                (click)="clear()">
                Limpar
            </cmx-button>
            <cmx-button
                variant="save"
                (click)="save()">
                Salvar
            </cmx-button>
        </div>

    </div>


</div>
