<div class="cmx-marker-filter {{ smallSize ? 'smallSize' : '' }}">
    <div *ngIf="surpassWidth && !preventMaxWidth" class="scroll-arrows-container" [style.right]="arrowsPosition + 'px'">
        <div class="left-arrow" (click)="scrollContainer('left', $event)"> <span class="material-icons">chevron_left</span> </div>
        <div class="right-arrow" (click)="scrollContainer('right', $event)"> <span class="material-icons">chevron_right</span> </div>
    </div>
    <div *ngIf="filters?.length && !televisionMode" [id]="markerFilterId" class="marker-filter-container alignment-{{ alignment }}"
        [style.overflow-x]="surpassWidth ? 'auto' : 'inherit'"
        [style.max-width]="maxWidth + 'px'"
        [style.left]="leftDistance + 'px'"
        >
        <div class="marker-filter-content{{ selectedFilters.indexOf(filter.code) !== -1 ? ' selected' : '' }}"
            *ngFor="let filter of filters; trackBy: identify"
            [style.max-width]="(maxWidth - 100) + 'px'">
            <div
                class="marker-filter pointer {{ filter.father ? 'child' : '' }} {{ type }}"
                [style.border-bottom]="
                    selectedFilters.indexOf(filter.code) !== -1 ? '4px solid ' + (filter.color || defaultColor) : 'unset'
                "
                [style.color]="selectedFilters.indexOf(filter.code) !== -1 ? filter.color || defaultColor : ''"
                [style.display]="filter.hidden ? 'none' : 'block'"
                (click)="selectFilter(filter)"
                [tooltip]="type === 'alert' ? filter?.name : ''"
            >
                <span *ngIf="filter.count !== undefined && filter.count !== null && type !== 'color'" class="count count-{{ type }}">{{ filter.count }}
                    <span *ngIf="filter.countName !== undefined && filter.countName !== null">{{ filter.countName }}</span>
                </span>
                <div class="marker-content">
                    <div *ngIf="type === 'color'" class="color-ball"
                    [style.background-color]="!blocked ? filter.color || 'grey' : 'grey'"
                    [style.margin-top]="filter.count || filter.count === 0 ? '5px' : '0px'"></div>
                    <img
                        *ngIf="filter.img; else alertContent"
                        class="{{ type }}"
                        src="{{ filter.img.path }}{{ selectedFilters.indexOf(filter.code) !== -1 && type !== 'tab' ? '-checked' : '' }}{{
                            filter.img.ext
                        }}"
                        alt="Image - {{ filter.code }}"
                    />
                    <span 
                        *ngIf="filter.img && type === 'tab'"
                        [style.font-size]="filter.father ? '10px' : '12px'"
                        [style.margin-bottom]="'-4px'"
                    >{{ filter.name }}</span>
                </div>
    
                <ng-template #alertContent>
                    <i *ngIf="type === 'alert'; else nameContent" class="comexport-alarm-1"></i>
                </ng-template>
    
                <ng-template #nameContent>
                    <div class="name-content">
                        <div *ngIf="hasMarker" class="marker" [style.background-color]="filter.color"></div>
                        <span>{{ type === 'color' ? filter.count : ''}} {{ filter.name }} {{ type === 'color' ? filter.countName : '' }}</span>
                    </div>
                </ng-template>
            </div>
    
            <span *ngIf="filter.img && type !== 'tab'" class="name-description">{{ filter.name }}</span>
        </div>
        <div *ngIf="hasSelectAll && multiple && type !== 'alert'" class="selection-action pointer" (click)="selectAllFilters()">{{
            selectedFilters.length === filters.length ? 'remover todos' : 'aplicar todos'
        }}</div>
    </div>
    
    <!-- Television Mode -->
    
    <div *ngIf="filters?.length && televisionMode" class="television-marker-filter-container">
        <div class="television-marker-filter-content{{ selectedFilters.indexOf(filter.code) !== -1 ? ' selected' : '' }}"
        *ngFor="let filter of filters; trackBy: identify"
        [style.background-color]="selectedFilters.indexOf(filter.code) !== -1 ? filter.color || '#e6e6e6' : '#e6e6e6'"
        (click)="selectFilter(filter)">
            <span class="count">{{ filter.count }}</span>
            <span class="sub-info">{{ filter.countName }}</span>
        </div>
    </div>    
</div>
