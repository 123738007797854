import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, Renderer2, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import * as _ from 'lodash';

import { IMarkerFilter } from 'app/shared/components/cmx-marker-filter/interfaces/marker-filter.interface';
import { REFERENCE_DATE_TYPES } from 'app/shared/constants/reference-date-types.constants';
import { DROPDOWN_SETTINGS } from 'app/shared/constants/dropdown-settings.constants';
import { MARKER_FILTER_TYPES } from 'app/shared/components/cmx-marker-filter/constants/marker-filter-types.const';
import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { StatusConsolidationFilter } from 'app/theme/layouts/charts/status-consolidation/statusConsolidationFilter.model';
import { ChassiStatusFilter } from 'app/modules/operational/pages/chassi-status/chassiStatusFilter.model';
import { ImpStatusFilter } from 'app/modules/operational/pages/imp-status/impStatusFilter.model';
import { SkuImportStatusFilter } from 'app/modules/operational/pages/sku-status/skuImportStatusFilter.model';
import { FollowUpFilter } from 'app/theme/pages/reports/report-followup/report-followup-filter/followUpFilter.model';

import { UserService } from "app/auth/_services/user.service";
import { SkuStatusService } from '../sku-status/sku-status.service';
import { FilterStateService } from '#services/_filters/filter-state.service';
import { CmxDropdownService } from 'app/shared/components/cmx-dropdown/cmx-dropdown.service';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UtilsService } from '#services/_utils/utils.service';
import { ChassiStatusService } from 'app/modules/operational/pages/chassi-status/chassi-status.service';
import { ImpStatusService } from 'app/modules/operational/pages/imp-status/imp-status.service';
import { FeatureFlagService } from '#services/_feature-flag/feature-flag-service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { SubsidiaryCrudService } from 'app/modules/crud/pages/logistic/delivery-coordination/subsidiary/subsidiary.service';

@Component({
    selector: 'status-consolidation-widget',
    templateUrl: './status-consolidation.component.html',
    styleUrls: ['./../charts.scss', './status-consolidation.component.scss'],
    providers: [ImpStatusService, SkuStatusService, ChassiStatusService],
    encapsulation: ViewEncapsulation.None,
})

export class StatusConsolidationComponent implements AfterViewInit, OnDestroy {

    currentUser = this._userService.getCurrentUser();
    currentUser$ = this._userService.currentUser$;
    asyncConsolidation: boolean = true;
    loadingTranslate: boolean = false;
    subscription: Subscription = null;
    translateSubscription: Subscription = null;
    totalPercentage = 0;
    allStatus = [];
    defaultColor = '#14509b';
    i18nModalList = this._userCacheService.getModals()?.map(modal => ({ ...modal, i18n: `common::modal.${modal?.code?.toLowerCase()}` }));

    allTypes = [
        { name: 'IMP', icon: 'directions_boat', type: 'material', permission: 'DASHBOARD_COMPONENT_IMP_STATUS', checked: true },
        { name: 'SKU', icon: 'comexport-package', type: 'awesome', permission: 'DASHBOARD_COMPONENT_SKU_STATUS', checked: false },
        { name: 'CHASSI', icon: 'directions_car', type: 'material', permission: 'DASHBOARD_COMPONENT_CHASSI_STATUS', checked: false }
    ];

    filterState: StatusConsolidationFilter = this._filterStateService.getStatusConsolidationFilter();
    followupFilterState: FollowUpFilter = this._filterStateService.getFupFollowUpFilter();
    typeSelected = this.filterState.getTypeStatus();

    // To Operational
    skuFilterState: SkuImportStatusFilter = this._filterStateService.getSkuImportStatusFilter();
    impFilterState: ImpStatusFilter = this._filterStateService.getImpStatusFilter();
    chassiFilterState: ChassiStatusFilter = this._filterStateService.getChassiStatusFilter();

    options: {
        client: DropdownOption[];
        referenceDateType: DropdownOption[];
        subsidiaryNames: DropdownOption[];
        status: DropdownOption[];
        modal: DropdownOption[];
    } = {
        client: this._cmxDropdownService.toDropdownItems(_.cloneDeep(this._userCacheService.getClientGroups())),
        referenceDateType: [],
        subsidiaryNames: [],
        status: [],
        modal: [],
    };

    @HostListener('click') onSetZIndex() {
        this.renderer2.setStyle(this._elementRef.nativeElement, 'z-index', this._utilsService.autoIncrementValue);
    };

    get helpDescription(): object[] {
        return [
            { type: 'text', value: this._translateService.instant('dashboard::statusConsolidation.description') },
        ];
    }

    get dropdownSettings() {
        return {
            modals: {
                ..._.cloneDeep(DROPDOWN_SETTINGS.modals),
                translate: true
            },
            clientGroups: {
                text: this._translateService.instant('common::strings.clientsGroup'),
                translate: true
            },
            subsidiary: {
                text: this._translateService.instant('common::strings.subsidiaries'),
                translate: true
            },
            referenceDateType: {
                singleSelection: true,
                translate: true
            }
        };
    }

    constructor(
        public router: Router,
        public _filterStateService: FilterStateService,
        private _userService: UserService,
        private _skuStatusService: SkuStatusService,
        private _impStatusService: ImpStatusService,
        private _chassiStatusService: ChassiStatusService,
        private _cmxDropdownService: CmxDropdownService,
        private _userCacheService: UserCacheService,
        private _utilsService: UtilsService,
        private _elementRef: ElementRef,
        private renderer2: Renderer2,
        private _featureFlagService: FeatureFlagService,
        private _translateService: TranslateService,
        private _userPreferencesService: UserPreferencesService,
        private _subsidiaryCrudService: SubsidiaryCrudService
    ) { }

    ngOnDestroy() {
        this._unsubscribe();

        if(this.translateSubscription) {
            this.translateSubscription.unsubscribe();
        }
    }

    ngAfterViewInit() {
        this.getDropdownValues();
        this.applyType({ name: this.typeSelected });
        this.onChanges();
    }

    async applyType(event: any) {
        const selectedType = event?.name || event;
        for (let type of this.allTypes) {
            if (type.name == selectedType) {
                this.filterState.setTypeStatus(type.name);
                this.typeSelected = type.name;
                type.checked = true;
                this._getStatusByType(selectedType);
            } else {
                type.checked = false;
            }
        }
    }

    onChanges() {
        this.translateSubscription = this._userPreferencesService.onLanguageOrPreferenceChange(
            () => {
                this.loadingTranslate = true;
                this._getModalOptions();
                this._getDateTypeOptions();
            }, (typeChange) => {
                if (typeChange === 'language') {
                    this._getStatusByType(this.typeSelected);
                }
            }
        );
    }

    datesChanged(event) {
        this.filterState.setStartDate(event.startDate);
        this.filterState.setEndDate(event.endDate);
    }

    redirectToFup(status) {
        if (this.typeSelected == 'IMP') {
            this.followupFilterState.setSearch('');
            this.followupFilterState.setFollowUpType('SKU');
            this.followupFilterState.setModals(this.filterState.getModals());
            this.followupFilterState.setProfitCenters([]);
            this.followupFilterState.setClientGroups(this.filterState.getClientGroups());
            this.followupFilterState.setSubsidiaryNames(this.filterState.getSubsidiaryNames());
            this.followupFilterState.setReferenceDateType(this.filterState.getReferenceDateType());
            this.followupFilterState.setStartDate(this.filterState.getStartDate());
            this.followupFilterState.setEndDate(this.filterState.getEndDate());
            this.followupFilterState.setStatus([{ name: status.name, code: status.code }]);
        } else if (this.typeSelected == 'SKU') {
            this.followupFilterState.setSearch('');
            this.followupFilterState.setFollowUpType('SKU');
            this.followupFilterState.setModals(this.filterState.getModals());
            this.followupFilterState.setClientGroups(this.filterState.getClientGroups());
            this.followupFilterState.setStartDate(this.filterState.getStartDate());
            this.followupFilterState.setEndDate(this.filterState.getEndDate());
            this.followupFilterState.setStatus([{ name: status.name, code: status.code }]);
        } else {
            this.followupFilterState.setSearch('');
            this.followupFilterState.setFollowUpType('CHASSI');
            this.followupFilterState.setClientGroups(this.filterState.getClientGroups());
            this.followupFilterState.setStartDate(this.filterState.getStartDate());
            this.followupFilterState.setEndDate(this.filterState.getEndDate());
            this.followupFilterState.setStatus([{ name: status.name, code: status.code }]);
        }

        this.router.navigate([`./report/followup`]);
    }

    redirectToOperacional(status) {
        if (this.typeSelected == 'IMP') {
            this.impFilterState.setModals(this.filterState.getModals());
            this.impFilterState.setClientGroups(this.filterState.getClientGroups());
            this.impFilterState.setSubsidiaryNames(this.filterState.getSubsidiaryNames());
            this.impFilterState.setReferenceDateType(this.filterState.getReferenceDateType());
            this.impFilterState.setStartDate(this.filterState.getStartDate());
            this.impFilterState.setEndDate(this.filterState.getEndDate());
            this.impFilterState.setStatus([{ name: status.name, code: status.code }]);
            this.router.navigate([`./operacional/importacao`]);
        } else if (this.typeSelected == 'SKU') {
            this.skuFilterState.setModals(this.filterState.getModals());
            this.skuFilterState.setClientGroups(this.filterState.getClientGroups());
            this.skuFilterState.setStartDate(this.filterState.getStartDate());
            this.skuFilterState.setEndDate(this.filterState.getEndDate());
            this.skuFilterState.setStatus([{ name: status.name, code: status.code }]);
            this.router.navigate([`./operacional/sku`]);
        } else {
            this.chassiFilterState.setClientGroups(this.filterState.getClientGroups());
            this.chassiFilterState.setStartDate(this.filterState.getStartDate());
            this.chassiFilterState.setEndDate(this.filterState.getEndDate());
            this.chassiFilterState.setStatus([{ name: status.name, code: status.code }]);
            this.router.navigate([`./operacional/chassi`]);
        }
    }

    redirectTo(status) {
        this._featureFlagService.isFeatureFlagEnabledV2(FeatureFlagService.FEATURE_REDIRECT_STATUS_FUP).then(
            (isEnabled) => {
                if (isEnabled) {
                    this.redirectToFup(status);
                    return;
                }
                this.redirectToOperacional(status);
            }
        ).catch(() => this.redirectToOperacional(status));
    }

    styleConsolidation() {
        return this.allStatus.length > 10 ? 'large' : 'small';
    }

    getPercentage(element) {
        return (this.totalPercentage > 0 ? ((element.count / this.totalPercentage) * 100).toFixed(0) : 0 );
    }

    getDropdownValues(){
        this._getModalOptions();
        this._getSubsidiaryOptions();
        this._getDateTypeOptions();
    }

    private _getStatusByType(selectedType: string) {
        const serviceMap = {
            'IMP': this._impStatusService.getImpStatusList.bind(this._impStatusService),
            'SKU': this._skuStatusService.getSkuStatusList.bind(this._skuStatusService),
            'CHASSI': this._chassiStatusService.getChassiStatusList.bind(this._chassiStatusService)
        };

        if (serviceMap[selectedType]) {
            this._fetchStatus(selectedType, serviceMap[selectedType]);
        }
    }

    private _fetchStatus(type: string, serviceMethod: (request: any) => Observable<any>) {
        this._unsubscribe();
        this.asyncConsolidation = true;

        const request = this.filterState.getRequest(type);
        this.subscription = serviceMethod(request)
            .pipe(
                finalize(() => {
                    this.asyncConsolidation = false;
                    this.loadingTranslate = false;
                })
            )
            .subscribe(
                (statusList) => {
                    const status = _.cloneDeep(statusList);
                    this.allStatus = this._getStatusList(status);
                    this.totalPercentage = status.reduce((total, item) => total + item.count, 0);
                }, (error) => {
                    this._utilsService.errorHandler(error, this._translateService.instant('dashboard.title::statusConsolidation'));
                }
            );
    }

    private _getStatusList(statusList: any): IMarkerFilter[] {
        return statusList.map((currentValue: IMarkerFilter) => ({
            ...currentValue,
            ...MARKER_FILTER_TYPES['status'][currentValue.code],
        }));
    }

    private _getModalOptions() {
        const translatedList = this._utilsService.translateListField(this.i18nModalList, 'i18n', false);
        const newList =  translatedList?.map(modal => ({ ...modal, itemName: modal.i18n }));
        this.options.modal = this._cmxDropdownService.toDropdownItemsImg(newList);
        this._verifySelectedModal();
    }

    private _getDateTypeOptions() {
        const translatedList = this._utilsService.translateListField(REFERENCE_DATE_TYPES, 'i18n', false);
        const newList = translatedList?.map(type => ({ ...type, name: type.i18n }));
        this.options.referenceDateType = this._cmxDropdownService.toDropdownItems(newList);
        this._verifySelectedDateType();
    }

    private _getSubsidiaryOptions() {
        this._subsidiaryCrudService.getSubsidiaryNames().subscribe((data) => { this.options.subsidiaryNames = this._cmxDropdownService.toDropdownItems(data); });
    }

    private _verifySelectedModal(){
        const selectedModal = this.options.modal?.filter(modal => this.filterState.getModals().some(item => item.id === modal.id) );
        this.filterState.setModals(selectedModal);
    }

    private _verifySelectedDateType(){
        const selectedDateType = this.options.referenceDateType?.filter(type => this.filterState.getReferenceDateType().some(item => item.id === type.id) );
        this.filterState.setReferenceDateType(selectedDateType);
    }

    private _unsubscribe() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
