import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { Injectable } from '@angular/core';

@Injectable()
export class NotificationService {

    notificationList = [];

    constructor(private _userPreferencesService: UserPreferencesService) { }

    openSmallNotification(type, text, timer?: number) {
        if (!$('.smallNotificationModal').hasClass('hidden')) {
            this.closeSmallNotification();
            setTimeout(() => { this.smallModalAppear(type, text) }, 800);
        } else {
            this.smallModalAppear(type, text);
        }
    }

    updateSmallNotification(type, text) {
        this.smallModalAppear(type, text);
        setTimeout(() => { this.closeSmallNotification() }, 3000);
    }

    openNotification(type, text, title = null, timer?: number) {
        if (!$('.notificationModal').hasClass('hidden')) {
            this.closeNotification({ type, text, title, timer });
        } else {
            this.modalAppear(type, text, title, timer);
        }
    }

    closeNotification(newAppear?) {
        $('.notificationModal').addClass('hidden');
        setTimeout(() => {
            $('.notificationModal .styleContainer').removeClass('downloadSuccess downloadError error success');
            if (newAppear) {
                setTimeout(() => { this.modalAppear(newAppear.type, newAppear.text, newAppear.title, newAppear.timer) }, 600);
            }
        }, 300);
    }

    closeSmallNotification() {
        $('.smallNotificationModal').addClass('hidden');
        setTimeout(() => {
            $('.smallNotificationModal').removeClass('success');
            $('.smallNotificationModal').removeClass('error');
        }, 800);
    }

    smallModalAppear(type: any, text: any, timer?: number) {
        $('.smallNotificationModal').text(text);
        $('.smallNotificationModal').addClass(type);
        $('.smallNotificationModal').removeClass('hidden');
        if (timer) {
            setTimeout(() => { this.closeSmallNotification() }, timer);
        }
    }

    modalAppear(type: any, text: any, title?, timer?: number) {
        console.log(type)
        console.log(this.chooseIcon(type))
        $('.notificationModal .styleContainer .material-icons').text(this.chooseIcon(type));
        $('.notificationModal .infoContainer .title').text(title);
        $('.notificationModal .infoContainer .info').text(text);
        $('.notificationModal').removeClass('hidden');
        setTimeout(() => {
            $('.notificationModal .styleContainer').addClass(type);
        }, 100);
        if (timer) {
            setTimeout(() => { this.closeNotification() }, timer);
        }
    }

    chooseIcon(type) {
        switch (type) {
        case 'downloadInfo':
            return 'schedule';
        case 'downloadSuccess':
        case 'downloadError':
            return 'get_app';
        case 'error':
            return 'bug_report';
        case 'success':
            return 'check';
        case 'startUpload':
            return 'schedule';
        case 'startProcess':
            return 'schedule';
        case 'customWidget':
            return 'equalizer';
        }
    }

    verifyLoginNotifications() {
        this._userPreferencesService.getUserPreferences().subscribe((preferences) => {
            if (preferences?.length) {
                preferences.forEach((preference) => {
                    this._notifyCustomWidgetChanges(preference);
                });
            }
            setTimeout(() => {
                this.notificationList.forEach((message, index) => {
                    setTimeout(() => {
                        this.openNotification(message.type, message.text, null, 5000);
                    }, (5000 * index + 1) + 1000);
                })
            }, 3000);
        });
    }

    private _notifyCustomWidgetChanges(preference) {
        const notificationList = preference?.features?.notificationsUser?.customWidget?.content?.value || [];
        if (notificationList?.length) {
            notificationList.forEach((message) => {
                const text = `[Novo Widget Compartilhado]       
                    ${message?.widgetName}`
                this.notificationList.push({ text, type: 'customWidget' });
            });
            this._userPreferencesService.setFilterPreference('notificationsUser', [], preference.id);
        }
    }

}
