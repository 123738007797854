import { _applyPosFormatters } from "./pos-formatters/pos-formatters";

export function switchFormatter(row, cell, value, columnDef, dataContext) {
    const neutralPoint = columnDef.formatterData?.neutralPoint || false;
    const leftValue = columnDef.formatterData?.leftValue || 'left';
    const rightValue = columnDef.formatterData?.rightValue || 'right';
    const tooltipLeftValue = columnDef.formatterData?.tooltipLeftValue ?? '';
    const tooltipRightValue = columnDef.formatterData?.tooltipRightValue ?? '';
    const tooltipNeutralValue = columnDef.formatterData?.tooltipNeutralValue ?? '';
    const disabled = (dataContext && dataContext?.switchDisabled?.length && dataContext.switchDisabled.includes(columnDef.field)) || false;
    let tooltipText = '';
    if (neutralPoint) {
        if (value === leftValue) {
            tooltipText = tooltipLeftValue;
        } else if (value === rightValue) {
            tooltipText = tooltipRightValue;
        } else {
            tooltipText = tooltipNeutralValue;
        }
    } else {
        tooltipText = value ? tooltipRightValue : tooltipLeftValue;
    }

    const titleAttr = tooltipText ? ` title="${tooltipText}"` : '';

    const contentValue = neutralPoint
        ? `<div class="switch-formatter-container neutral-type ${disabled ? 'disabled' : ''}"${titleAttr}>
                <div class="switch-formatter-content neutral-type ${value === leftValue ? 'off' : value === rightValue ? 'on' : 'neutral'}">
                    <div class="switch-ball"></div>
                    <div class="hover-indicator"></div>
                </div>
           </div>`
        : `<div class="switch-formatter-container ${disabled ? 'disabled' : ''}"${titleAttr}>
                <div class="switch-formatter-content ${value ? 'on' : 'off'}">
                    <div class="switch-ball"></div>
                    <div class="hover-indicator"></div>
                </div>
           </div>`;

    return _applyPosFormatters(row, cell, contentValue, columnDef, dataContext);
}