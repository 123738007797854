import { environment } from '#environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { Router } from '@angular/router';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import moment from 'moment';
import * as _ from 'lodash';
import { catchError } from 'rxjs/operators';
import { FINANCIAL_GUARANTEE_ATTRIBUTES } from '../constants/commercial-premise-resume.constants';
import { DownloadService } from '#services/_download/download.service';
import { UtilsService } from '#services/_utils/utils.service';
import { NotificationService } from '#services/_notification/notification.service';
import { UserService } from 'app/auth/_services/user.service';

@Injectable()
export class CommercialPremiseService {

    segmentChanged: Subject<any> = new Subject();
    cnpjChanged: Subject<any> = new Subject();
    manualOpened: Subject<any> = new Subject();
    opportunityOpened: Subject<any> = new Subject();
    premisseChanged: Subject<any> = new Subject();
    closeOpportunity: Subject<any> = new Subject();
    bottomButtonsChanged: Subject<any> = new Subject();
    bottomButtonsClicked: Subject<any> = new Subject();

    currentPremisseTab: string = 'premissa';
    resumePremise = '';
    resumeProduct = { data: [], basicInfo: '', recurrenceOperation: '', creditAnalysisRequired: '' };
    resumeLegal = '';
    resumeFinancialGuarantee: string = '';
    resumeArrasFinancialGuarantee: string[] = [];
    resumeExporter = [];
    resumeExporterCondition = [];
    resumeClientCondition = { fobDeadline: '', taxesDeadline: '', expensesDeadline: ''};
    resumePercentualMargin = [];
    absoluteMarginValues = [];

    private _currentUser = this._userService.getCurrentUser();
    private _premisseData: any;
    private _opportunityData: Subject<any> = new Subject<any>();
    private _premiseManualData: Subject<any> = new Subject<any>();
    public loaderSubject: Subject<boolean> = new Subject<boolean>();
    opportunityData$ = this._opportunityData.asObservable();
    premiseManualData$ = this._premiseManualData.asObservable();

    constructor(
        private _notificationService: NotificationService,
        private _downloadService: DownloadService,
        private _utilsService: UtilsService,
        private _userService: UserService,
        private _http: HttpClient,
        private _router: Router,
    ) { }

    getPremisse() { return this._premisseData; }
    setPremisse(data) {
        this._premisseData = data;
        this.premisseChanged.next(data);
    }
    setOpportunityData(data) {
        this._opportunityData.next(data);
    }
    setPremiseManualData(data) {
        this._premiseManualData.next(data);
    }
    changePremiseValue(category, key, value) {
        this._premisseData[category][key] = value;
        this.premisseChanged.next(this._premisseData);
    }
    changeArrayPremiseValue(category, key, index, value) {
        this._premisseData[category][key][index] = value;
    }

    showLoader(show: boolean) {
        this.loaderSubject.next(show);
    }

    getPremiseList(request) {
        return this._http.get(`${environment.endpoints.commercialPremiseService}/v1/premise/filter`, request);
    }

    getManualData(filter) {
        return this._http.get(`${environment.endpoints.commercialPremiseService}/v1/manual/filter`, filter);
    }

    getPremiseData(premiseId, version) {
        return this._http.get(`${environment.endpoints.commercialPremiseService}/v1/premise/${premiseId}/${version}`);
    }

   getOpportunityByCnpj(request) {
       return this._http.get(`${environment.endpoints.commercialPremiseService}/v1/sales-force/opportunity`, request);
   }

    // Retorna lista de Responsável Operacional
    getOperationalResponsibleList(): Observable<any> {
        return this._http.get(`${environment.endpoints.adminService}/users?permissionGroup=operational_manager_premise&excludeDeleted=true&internalUser=true`);
    }

    allPremiseId() {
        return this._http.get(`${environment.endpoints.commercialPremiseService}/v1/premise/all-id-version`);
    }

    downloadPremiseFile(fileId, fileName) {
        const options = this._downloadService.getSourceOptions();
        const url = this._http.get(`${environment.endpoints.commercialPremiseService}/v1/premise/download?fileId=${fileId}`, options);
        return this._downloadService.downloadFrom(url, fileName);
    }

    createPremiseOrReplicate(request) {
        return this._http.post(`${environment.endpoints.commercialPremiseService}/v1/premise/premise`, request);
    }

    allTaxStudies(request) {
        return this._http.post(`${environment.endpoints.commercialPremiseService}/v1/taxstudy/all-compatible-premise`, request);
    }

    createSalesForceOpportunity(premiseId: string, version: number, data: any) {
        const url = `${environment.endpoints.commercialPremiseService}/v1/premise/${premiseId}/${version}/sales-force`;
        return this._http.put(url, data);
    }

    editPremiseData(premiseId, version, data, category) {
        return this._http.put(`${environment.endpoints.commercialPremiseService}/v1/premise/${premiseId}/${version}/${category}`, data);
    }

    uploadPremiseFile(data, premiseId, version, fileType) {
        return this._http.put(`${environment.endpoints.commercialPremiseService}/v1/premise/${premiseId}/${version}/${fileType}/document`, data);
    }

    approvalPremise(data, premiseId, version, responsible) {
        return this._http.put(`${environment.endpoints.commercialPremiseService}/v1/premise/${premiseId}/${version}/${responsible}/approval`, data);
    }

    updateManual(manualId: string, data: any) {
        return this._http.put(`${environment.endpoints.commercialPremiseService}/v1/manual/${manualId}`, data);
    }

    batchApproval(requests) {
        return this._http.put(`${environment.endpoints.commercialPremiseService}/v1/premise/massive-approval/`, requests);
    }

    cancelPremise(premiseId, version, cancellationNote) {
        return this._http.put(`${environment.endpoints.commercialPremiseService}/v1/premise/${premiseId}/${version}/cancel`, {
            cancellationNote: cancellationNote
        });
    }

    deletePremiseFile(fileId, fileType) {
        const url = `${environment.endpoints.commercialPremiseService}/v1/premise/${this.getPremisse().premiseId}/${this.getPremisse().version}/${fileType}/document`;
        const options = { params: { fileId }, headers: { 'Content-Type': 'application/json' } };
        return this._http.delete(url, options);
    }

    verifyNewVersion(premiseId, oldVersion, newVerion): boolean {
        if (oldVersion < newVerion) {
            this._notificationService.openNotification('success', `Uma nova versão (${newVerion}) da Premissa ${premiseId} foi gerada`, '', 5000);
            this.getPremiseData(premiseId, newVerion).subscribe((data) => {
                this.setPremisse(data);
                this._router.navigate([`../financeiro/premissa-comercial/${premiseId}/${newVerion}`])
            });
            return true;
        } else {
            return false;
        }
    }

    sendToNextTab(premisseId, version, nextTab) {
        this.currentPremisseTab = nextTab;
        this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this._router.navigate([`financeiro/premissa-comercial/${premisseId}/${version}/${nextTab}`]);
        })
    }

    updateResume(item) {
        this.resumePremise = '';
        this.resumeProduct = { data: [], basicInfo: '', recurrenceOperation: '', creditAnalysisRequired: '' };
        this.resumeFinancialGuarantee = '';
        this.resumeArrasFinancialGuarantee = [];
        this.resumeExporter = [];
        this.resumeExporterCondition = [];
        this.resumeClientCondition = { fobDeadline: '', taxesDeadline: '', expensesDeadline: '' };
        this.resumePercentualMargin = [];

        // Resumo da Premissa
        this.resumePremise = `Criada em ${moment(item?.general?.elaborationDate, 'YYYY-MM-DD')?.format('DD/MM/YYYY')
            }${item?.general?.trader?.name ? `, por ${item?.general?.trader?.name
            }${item?.general?.trader?.name?.includes('(') ? '' : `${item?.general?.director?.name ? `(${item?.general?.director?.name})` : ''}`}` : ''
            }${item?.general?.operationalResponsible ? `, responsável ${item?.general?.operationalResponsible}` : ''
            }${item?.general?.tradeName ? `, para ${item?.general?.tradeName}` : ''
            }${item?.general?.city ? ` em ${item?.general?.city}` : ''
            }${item?.general?.state ? `, ${item?.general?.state}` : ''
            }${item?.financial?.operationValue ? `, com valor total de operação de ${item?.financial?.currency} ${this._utilsService
                .formatNumber(item?.financial?.operationValue, { style: 'currency', currency: 'none' }) }` : ''}.`;

        const productInfo = item?.commercial?.productInfoList || [];
        this.resumeProduct = {
            basicInfo: `${item?.commercial?.importationModality ? `${item?.commercial?.importationModality}, ` : ''
                        }${item?.commercial?.comexportSpecialRegime ? `${item?.commercial?.comexportSpecialRegime}, ` : ''
                        }${item?.comexportBranch ? `${item?.comexportBranch}` : ''
                        }${item?.general?.leasingOperation ? `, via Leasing (${item?.general?.acquiringBank || 'Sem Banco Selecionado'})` : ''}.`,
            recurrenceOperation: item?.general?.recurrenceOperation || '',
            creditAnalysisRequired: item?.financial?.creditAnalysisRequired ? 'Sim' : 'Não',
            data: [],
        }
        productInfo.forEach((product) => {
            if (product.productDescription && product.productNcm) {
                this.resumeProduct.data.push(
                    `• ${product.productDescription} (NCM ${product.productNcm?.replace(/(\d{4})(\d{2})(\d{2})/, "$1.$2.$3")
                    })${product?.number ? `. EX - ${product?.number}` : ''}${product?.accumulatesPisCofins ? ', acumula PIS/COFINS' : ''
                    }${product?.productIsUsed ? ', produto usado' : '' }.`);
            }

        })
        this.resumeLegal = item?.legal?.partnersObservations || '';

        // Dados do Exportador (usado em Garantias do Cliente e Condição de Pagamento Exportador)
        const exportInfo = item?.financial?.financialConditionsExporter || [];

        // Garantias do Cliente
        let firstGuarantee: boolean = true;
        let hasSomeAnticipated: boolean = false;
        FINANCIAL_GUARANTEE_ATTRIBUTES.forEach((attribute) => {
            if (item[attribute.key] === true) {
                // Tratamento para o caso específico de "Arras"
                if (attribute.title === 'Arras') {
                    let arrasPercent = item?.financial?.guaranteePercent || 0;
                    let arrasValue = item?.financial?.guaranteeValue || '';
                    let arrasFormatted = `${arrasPercent.toFixed(2)}%`;

                    if (exportInfo.length) {
                        exportInfo[0]?.installments.forEach((installment) => {
                            if (installment?.paymentForm === 'Antecipado') {
                                hasSomeAnticipated = true;
                            }
                        });
                    }
                    this.resumeFinancialGuarantee +=  (firstGuarantee ? '' : ' ⠀ • ⠀ ') + `${attribute.title}: ${arrasFormatted}${arrasFormatted && arrasValue ? ' - ' : ''}${arrasValue}`;
                    if (firstGuarantee) firstGuarantee = false;
                }
                // Tratamento para o caso específico de "Aval"
                else if (attribute.title === 'Aval') {
                    let avalPercent = item?.financial?.endorsementPercent || 0;
                    let avalFormatted = `${avalPercent.toFixed(2)}%`;
                    let avalValue = item?.financial?.endorsementValue || '';

                    this.resumeFinancialGuarantee +=
                        (firstGuarantee ? '' : ' ⠀ • ⠀ ') + `${attribute.title}: ${avalFormatted}${avalFormatted && avalValue ? ' - ' : ''}${avalValue}`;
                    if (firstGuarantee) firstGuarantee = false;
                }
                else {
                    this.resumeFinancialGuarantee +=
                        (firstGuarantee ? '' : ' ⠀ • ⠀ ') + attribute.title;
                    if (firstGuarantee) firstGuarantee = false;
                }
            }
        });

        // Condição de Pagamento Exportador
        exportInfo.forEach((exporter, index) => {
            if (exporter.exporter) {
                this.resumeExporter.push({
                    exporter: `• ${exporter.exporter}`,
                    observations: exporter?.exporterGeneralObservations,
                    escrow: exporter?.nameEscrow,
                    data: [] });
                exporter.installments.map((installment, installmentIndex) => {
                    // Exporter Infos
                    // Quantidade de Parcelas
                    const singleInstallment = exporter?.installments?.length === 1;
                    const prefixInstallmentNumber = singleInstallment ? 'Parcela única' : `${installmentIndex + 1}ª Parcela`;
                    let exporterInstallmentPhrase = `${prefixInstallmentNumber} `;
                    // Forma de Pagamento (Câmbio)
                    exporterInstallmentPhrase+= `⠀ • ⠀${installment.paymentForm || 'Sem Forma de Pagamento Declarada'}⠀ • ⠀`;
                    // Tipo de Lançamento: Valor Pagamento (Câmbio) ou % Pagamento (Câmbio)
                    let installmentValue = '';
                    const currencyPrefix = installment?.currencyExchange || 'BRL';
                    if (installment.typePayment === 'VALOR') {
                        installmentValue = (installment.paymentValue || installment.paymentValue === 0)
                            ? `${currencyPrefix} ${this._utilsService.formatNumber(installment.paymentValue, { style: 'currency', currency: 'none' })}`
                            : 'Sem Valor Declarado';
                    } else if (installment.typePayment === 'PERCENTUAL') {
                        installmentValue = (installment.paymentPercentage || installment.paymentPercentage === 0) ? `${installment?.paymentPercentage}%`
                            : 'Sem Percentual Declarado'
                    }
                    exporterInstallmentPhrase+= installmentValue ? `${installmentValue}⠀ • ⠀` : '';
                    // Data de Previsão + Tipo
                    exporterInstallmentPhrase+= `Data prevista do desembolso: ${installment?.exchangePaymentTermCounting ?
                        this._utilsService.capitalize(installment.exchangePaymentTermCounting) : 'Sem Tipo de Prazo'}`
                    // Prazo de Pagamento: (Câmbio) ou (Câmbio - Data)
                    let installmentPayment = '';
                    if (installment?.exchangePaymentTermCounting && installment?.exchangePaymentTermCounting !== 'DATA FIXA') {
                        installmentPayment = (installment?.exchangePaymentTerm || installment?.exchangePaymentTerm === 0) ? `${installment?.exchangePaymentTerm} dias`
                            : 'Sem Prazo';
                    } else {
                        installmentPayment = installment?.exchangePaymentTermDate ? moment(installment.exchangePaymentTermDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
                            : 'Sem Data de Prazo';
                    }
                    exporterInstallmentPhrase+= ` (${installmentPayment}).`
                    // Hedge Antes do Faturamento
                    exporterInstallmentPhrase+= ` ${installment.hedgeBeforeBilling ? 'Com' : 'Sem'} Hedge Antes do Fat.`

                    this.resumeExporter[index].data.push(exporterInstallmentPhrase);
                });
            }
        })

        // Condição de Recebimento do Cliente
        // Usados os campos '% Spread' e 'Períodicidade do Juros Spread' para formulação dos Juros,
        const financial = item?.financial;
        // Prazo de Pagamento FOB: 'Prazo Recebimento Parcela do Câmbio'
        this.resumeClientCondition.fobDeadline = `
        ${
            financial?.exchangePaymentMethod
            ? `Forma de Pagamento (Câmbio): ${financial.exchangePaymentMethod} + ` : 'Forma de Pagamento(Câmbio) não declarada.'}
        FOB: ${
            financial?.deadlineReceivingExchangePortion
            ? `pago em ${financial.deadlineReceivingExchangePortion}.`
            : 'Sem Prazo de Faturamento.'
        }
        Juros: ${financial?.interestIndexFinancialCostExchange || 'Sem Juros Declarado'}`;

        // Prazo de Pagamento Impostos: 'Resp. Pgto (Imposto de Nacionalização)' e 'Prazo Rec. (Imposto de Nacionalização)'
        this.resumeClientCondition.taxesDeadline = `
        ${
            financial?.natTaxPaymentMethod
            ? `Forma de Pagamento (Imposto de Nacionalização): ${financial.natTaxPaymentMethod} + ` : 'Forma de Pagamento (Imposto de Nacionalização) não declarada.'}
        Impostos (${
            financial?.responsibleNationalizationPayment || 'Sem Responsável Declarado'
        }):         ${financial?.receiptDeadlineNationalizationExpenses
            ? `pago em ${financial.receiptDeadlineNationalizationExpenses}.`
            : 'Sem Prazo de Faturamento.'}
                ${(financial?.interestIndexFinancialCost === 'Não há' && (!financial?.spreadPercentage || financial?.spreadPercentage === 0))
            ? 'Sem Juros'
            : (financial?.interestIndexFinancialCost == null && financial?.spreadPercentage == null)
            ? `Juros: 0,00% ${financial?.spreadInterestPeriodicity ? financial.spreadInterestPeriodicity : ''}`
            : financial?.spreadPercentage && financial?.spreadPercentage !== 0
            ? `Juros: ${financial?.interestIndexFinancialCost !== 'Não há' ? `${financial?.interestIndexFinancialCost} + ${financial?.spreadPercentage}% ${financial?.spreadInterestPeriodicity || ''}` : `${financial?.spreadPercentage}% ${financial?.spreadInterestPeriodicity || ''}`}`
            : `Juros: ${financial?.interestIndexFinancialCost !== 'Não há' ? `${financial?.interestIndexFinancialCost} + 0,00% ${financial?.spreadInterestPeriodicity || ''}` : 'Sem Juros'}`
        }`;

        // Prazo de Pagamento Despesas: 'Resp. Pgto (Desp. de Nacionalização)' e 'Prazo Rec. (Desp. de Nacionalização)'
        this.resumeClientCondition.expensesDeadline = `
        ${
            financial?.natExpensesPaymentMethod
            ? `Forma de Pagamento (Desp. de Nacionalização): ${financial.natExpensesPaymentMethod} + ` : 'Forma de Pagamento (Desp. de Nacionalização) não declarada.'}
        Despesas (${
            financial?.responsiblePaymentNationalizationExpenses || 'Sem Responsável Declarado'
        }): ${
            financial?.nationalizationPaymentTerm
            ? `pago em ${financial.nationalizationPaymentTerm}.`
            : 'Sem Prazo de Faturamento.'
        } ${
            (financial?.interestIndexFinancialCost === 'Não há' && (!financial?.spreadPercentage || financial?.spreadPercentage === 0))
            ? 'Sem Juros'
            : (financial?.interestIndexFinancialCost == null && financial?.spreadPercentage == null)
            ? `Juros: 0,00% ${financial?.spreadInterestPeriodicity ? financial.spreadInterestPeriodicity : ''}`
            : financial?.spreadPercentage && financial?.spreadPercentage !== 0
            ? `Juros: ${financial?.interestIndexFinancialCost !== 'Não há' ? `${financial?.interestIndexFinancialCost} + ${financial?.spreadPercentage}% ${financial?.spreadInterestPeriodicity || ''}` : `${financial?.spreadPercentage}% ${financial?.spreadInterestPeriodicity || ''}`}`
            : `Juros: ${financial?.interestIndexFinancialCost !== 'Não há' ? `${financial?.interestIndexFinancialCost} + 0,00% ${financial?.spreadInterestPeriodicity || ''}` : 'Sem Juros'}`
        }`;

        // Margem Percentual/Absoluta
        const feeList = item?.financial?.commercialConditionsCustomerFee || [];
        const discountList = item?.financial?.commercialConditionsCustomerDiscount || [];
        let totalMargin;
        const determineMargins = (values) => {
            const [exchange, feeExchange] = values;
            // Aplica Conversão para BRL na Margem Absoluta (Fee e Desconto)
            const convertAbsolutMarginToBrl = (absoluteMargin, exchange) => {
                if (exchange?.currency && exchange?.currency !== 'BRL') {
                    if (exchange?.values?.length > 0) {
                        if ((exchange?.values[0]?.quotationValue)) {
                            const result = this._utilsService.formatNumber((exchange?.values[0]?.quotationValue * absoluteMargin), { style: 'currency', currency: 'none' })
                            this.resumePercentualMargin[this.resumePercentualMargin.length - 1] = this.resumePercentualMargin[this.resumePercentualMargin.length - 1] + `${result && result !== 'NaN' ? ` (BRL ${result})` : ''} `;
                        }
                    }
                }
            }
            feeList.forEach((fee) => {
                const absoluteMargin = (fee?.totalPercentMarginOperation / 100) * item?.financial?.operationValue;
                const currencyPrefix = fee?.currencyFee || 'BRL';
                if (absoluteMargin && fee.comexportFeeType === '(%) Percentual') {
                    this.resumePercentualMargin.push(`
                        Aliquota ICMS (FEE) ${fee?.percentageIcmsRate
                        }% ⠀ • ⠀ Margem Percentual: ${fee?.presumedCreditTaxIncentive || 0}% + ${fee?.percentageFeeCharged
                        }% = ${fee?.totalPercentMarginOperation
                        }% ${absoluteMargin ? `⠀ • ⠀ Margem Absoluta: ${item?.financial?.currency} ${this._utilsService.formatNumber(
                            absoluteMargin, { style: 'currency', currency: 'none' })}` : ''}`);
                    convertAbsolutMarginToBrl(absoluteMargin, exchange);
                } else if (fee?.fixedFeeCharged && fee.comexportFeeType === 'Valor Fixo R$') {
                    this.resumePercentualMargin.push(`
                        Aliquota ICMS (FEE) ${fee?.percentageIcmsRate
                        }% ⠀ • ⠀ Valor Fixo de FEE Cobrado: ${currencyPrefix} ${this._utilsService.formatNumber(fee?.fixedFeeCharged, { style: 'currency', currency: 'none' })
                        } ${fee?.fixedFeeBillingBase}.`);
                }
                this.absoluteMarginValues.push(absoluteMargin);
            });
            discountList.forEach((discount, discountIndex) => {
                if (item?.financial?.operationValue
                    && (discount?.netProfitabilityTaxIncentive || discount?.netProfitabilityTaxIncentive === 0)
                    && (discount.percentageIcmsRateConditionsDiscount || discount.percentageIcmsRateConditionsDiscount === 0)
                    && (discount.presumedCreditTaxIncentive || discount.presumedCreditTaxIncentive === 0)
                    && (discount.percentageDiscountCalculationBase || discount.percentageDiscountCalculationBase === 0)) {
                    const absoluteMargin = (discount?.netProfitabilityTaxIncentive / 100) * item?.financial?.operationValue;
                    this.resumePercentualMargin.push(
                        discount?.calculationBasisApplyingDiscount === 'B - BASE ICMS'
                        ? `Aliquota ICMS (Desconto) ${discount?.percentageIcmsRateConditionsDiscount}% ⠀ • ⠀ Margem Percentual: ${discount?.presumedCreditTaxIncentive}% - ${discount?.percentageDiscountCalculationBase}% = ${discount?.profitability}% ${absoluteMargin ? ' ⠀ • ⠀ Margem Absoluta: ' + item?.financial?.currency + ' ' + this._utilsService.formatNumber(absoluteMargin, { style: 'currency', currency: 'none' }) : ''}`
                        : `Aliquota ICMS (Desconto) ${discount?.percentageIcmsRateConditionsDiscount}% ⠀ • ⠀ Margem Percentual: ${discount?.profitability}% ${absoluteMargin ? ' ⠀ • ⠀ Margem Absoluta: ' + item?.financial?.currency + ' ' + this._utilsService.formatNumber(absoluteMargin, { style: 'currency', currency: 'none' }) : ''}`
                    );
                    convertAbsolutMarginToBrl(absoluteMargin, exchange);
                    // Caso possuído apenas 1 Fee e 1 Desconto
                    if (feeList?.length === 1 && discountList?.length === 1) {
                        // Margem Total - Caso Tipo do Primeiro FEE = Valor Fixo e Base de Cobrança por Processo ou Unidade de Produto
                        if (discountIndex === 0 && feeList[0].comexportFeeType === 'Valor Fixo R$' && absoluteMargin && feeList[0].fixedFeeCharged &&
                            (feeList[0].fixedFeeBillingBase === 'Por Processo' || feeList[0]?.fixedFeeBillingBase === 'Por unidade de produto')) {
                            // Verifica as moedas (Moeda e Moeda FEE), devem ser diferente de CNH / CNY e HUF
                            const dismissiveCurrencies = ['CNH', 'CNY', 'HUF'];
                            if (!dismissiveCurrencies.includes(item?.financial?.currency) && !dismissiveCurrencies.includes(feeList[0]?.currencyFee)) {
                                if (exchange?.values?.length > 0 && feeExchange?.values?.length) {
                                    const domesticAbsoluteMargin = exchange?.values[0]?.quotationValue * absoluteMargin;
                                    const domesticFixedFeeCharged = feeExchange?.values[0]?.quotationValue * feeList[0].fixedFeeCharged;
                                    totalMargin = this._utilsService.formatNumber((domesticAbsoluteMargin + domesticFixedFeeCharged), { style: 'currency', currency: 'none' });
                                    this.resumePercentualMargin.push(`Margem Total: BRL ${totalMargin}`)
                                }
                            }
                        }
                    }
                    if (discount?.generalDiscountObservations) {
                        this.resumePercentualMargin.push(`Observações: ${discount?.generalDiscountObservations}`)
                    }
                    this.absoluteMarginValues.push(absoluteMargin);
                }
            });
        }
        if (feeList.length || discountList.length) {
            forkJoin([
                !item?.financial?.currency ? of(undefined) :
                 item?.financial?.currency === 'BRL' ? of({ values: [{ quotationValue: 1 }] }) : this.getExchange(item?.general?.elaborationDate, item?.financial?.currency)
                    .pipe(catchError((_err) => { return of(undefined) })),
                !feeList[0]?.currencyFee ? of(undefined) :
                 feeList[0]?.currencyFee === 'BRL' ? of({ values: [{ quotationValue: 1 }] }) : this.getExchange(item?.general?.elaborationDate, feeList[0]?.currencyFee)
                    .pipe(catchError((_err) => { return of(undefined) })),
            ]).subscribe((values) => {
                determineMargins(values);
            }), (_error) => {
                determineMargins([]);
            }
        }
    }

    checkAndDisableControls(status: string, form: FormGroup, dropdownSettings: any, disabledDatepickers: boolean): boolean {
        if (status === 'CANCELED' || status === 'INACTIVE') {
            disabledDatepickers = true;
            form.disable();
            dropdownSettings['disabled'] = true;
            dropdownSettings = _.cloneDeep(dropdownSettings);
            return true;
        }
        return false;
    }

    validateForm(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach((field) => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
                control.markAsDirty();
            } else if (control instanceof FormGroup) {
                this.validateForm(control);
            } else if (control instanceof FormArray) {
                const controlArray = formGroup.get(field)['controls'];
                if (controlArray) {
                    for (let i = 0; i < controlArray.length; i++) {
                        this.validateForm(controlArray[i]);
                    }
                }
            }
        });
    }

    defineButtons(type: 'GENERAL' | 'POTENCIAL_SOLUTIONS' | 'TRIBUTARY' | 'LEGAL_COMPLIANCE' | 'FINANCIAL' | 'CREDIT' | 'OPERATINAL_EXPENSES' | null, completeForm: boolean, editCompletedForm: boolean) {
        if (this._checkButtonCurrentTab(type)) {
            let buttonList = [];
            const currentPremisseStatus = this.getPremisse()?.status;
            const isCanceled = currentPremisseStatus === 'CANCELED';

            if (this._currentUser.has(`PREMISE_EDIT_${type}`) && currentPremisseStatus && currentPremisseStatus !== 'APPROVED') {
                if (completeForm && !editCompletedForm) {
                    buttonList.push({ code: 'primary', name: 'Editar', disabled: this._checkStatus() || isCanceled });
                }
                if (!completeForm || editCompletedForm) {
                    buttonList.push({ code: 'save', name: 'Salvar', disabled: this._checkStatus() || isCanceled });
                }
                if (!completeForm && this._currentUser.has(`PREMISE_COMPLETE_${type}`)) {
                    buttonList.push({ code: 'primary', name: 'Finalizar', disabled: this._checkStatus() || isCanceled });
                }
            }
            if (this._currentUser.has(`PREMISE_EDIT_${type}`) && currentPremisseStatus === 'APPROVED' && this._currentUser.has('PREMISE_EDIT_APPROVED')) {
                if (completeForm && !editCompletedForm) {
                    buttonList.push({ code: 'primary', name: 'Editar', disabled: this._checkStatus() || isCanceled });
                }
                if (!completeForm || editCompletedForm) {
                    buttonList.push({ code: 'save', name: 'Salvar', disabled: this._checkStatus() || isCanceled });
                }
            }
            if (this._currentUser.has(`PREMISE_EDIT_${type}`) && currentPremisseStatus === 'APPROVED') {
                if (!completeForm && this._currentUser.has(`PREMISE_COMPLETE_${type}`)) {
                    buttonList.push({ code: 'primary', name: 'Finalizar', disabled: this._checkStatus() || isCanceled });
                }
            }
            this.bottomButtonsClicked = new Subject();
            this.bottomButtonsChanged.next(buttonList);
        }
    }

    private _checkButtonCurrentTab(type): boolean {
        return type === 'GENERAL' && this.currentPremisseTab === 'premissa' ||
               type === 'POTENCIAL_SOLUTIONS' && this.currentPremisseTab === 'potenciais-solucoes' ||
               type === 'TRIBUTARY' && this.currentPremisseTab === 'tributario' ||
               type === 'LEGAL_COMPLIANCE' && this.currentPremisseTab === 'juridico-compilance' ||
               type === 'FINANCIAL' && this.currentPremisseTab === 'financeiro' ||
               type === 'CREDIT' && this.currentPremisseTab === 'credito' ||
               type === 'OPERATINAL_EXPENSES' && this.currentPremisseTab === 'despesas-operacionais' ||
               !type && this.currentPremisseTab === 'documentos' || this.currentPremisseTab === 'aprovacoes';
    }

    private _checkStatus() {
        const deniedStatus = ['LOGISTIC_APPROVAL', 'FINANCIAL_APPROVAL', 'FINAL_APPROVAL', 'INACTIVE'];
        return deniedStatus.includes(this.getPremisse()?.status);
    }

    private getExchange(elaborationDate, currency): Observable<any> {
        return this._http.get(
            `${environment.endpoints.exchangeService}/exchanges/daily-exchange/${currency}?startDate=${elaborationDate}&endDate=${elaborationDate}`, { }
        );
    }

}
