import { Injectable } from '@angular/core';
import { UserService } from "app/auth/_services/user.service";

import { SubscriptionsFilter } from 'app/theme/pages/technical-integrations/webhooks/models/subscriptionsFilter.model';
import { CostsFilter } from 'app/theme/pages/finance/costs/costsFilter.model';
import { ExchangePackageListingFilter } from 'app/theme/pages/finance/exchange/exchange-package-listing/exchangePackageFilter.model';
import { ExchangeSpListingFilter } from 'app/theme/pages/finance/exchange/exchange-sp-listing/exchangeSpListing.model';
import { FupChassiFilter } from 'app/theme/pages/reports/report-followup/report-followup-filter/fupChassiFilter.model';
import { FupImpFilter } from 'app/theme/pages/reports/report-followup/report-followup-filter/fupImpFilter.model';
import { FollowUpFilter } from 'app/theme/pages/reports/report-followup/report-followup-filter/followUpFilter.model';
import { FupSkuFilter } from 'app/theme/pages/reports/report-followup/report-followup-filter/fupSkuFilter.model';
import { InvoiceFilter } from 'app/theme/pages/default/invoice-external/invoiceFilter.model';
import { InvoiceReceivableFilter } from 'app/theme/pages/invoices-receivable/invoiceReceivableFilter.model';
import { NewTrafficMapFilter } from 'app/theme/layouts/charts/new-traffic-map/new-vessels/newTrafficMapFilter.model';
import { DocumentConsolidationFilter } from 'app/theme/layouts/operational/document-consolidation/document-consolidation.model';
import { LogisticsHandlingFilter } from 'app/theme/layouts/operational/logistics-handling/logisticsHandling.model';
import { OperationalConsolidationFilter } from 'app/theme/layouts/operational/operational-consolidation/operationalConsolidation.model';
import { SkuStatusFilter } from 'app/theme/layouts/charts/sku-status/skuStatusFilter.model';
import { SlaConsolidationFilter } from 'app/theme/layouts/charts/sla-consolidation/slaConsolidationFilter.model';
import { StatusConsolidationFilter } from 'app/theme/layouts/charts/status-consolidation/statusConsolidationFilter.model';
import { NewTrackingTimelineFilter } from 'app/theme/layouts/new-tracking-timeline/newTrackingTimelineFilter.model';
import { OperationalAlertsFilter } from 'app/theme/layouts/operational/operational-alerts/operationalAlertsFilter.model';
import { ChannelFilter } from 'app/theme/layouts/charts/channel/channel.filter';
import { ChannelStatusFilter } from 'app/modules/performance/pages/channel-status/channel-status.filter';
import { LiLeadTimeFilter } from 'app/theme/layouts/charts/li-lead-time/li-lead-time.filter';
import { BoardingStatusFilter } from 'app/theme/layouts/charts/boarding-status/boarding-status.filter';
import { CrProcessesFilter } from 'app/theme/layouts/charts/cr-processes-table/cr-processes-table.filter';
import { LogisticCostsFilter } from 'app/theme/layouts/charts/logistic-costs/logistic-costs.filter';
import { CostDistribuitionFilter } from 'app/theme/layouts/charts/cost-distribution/cost-distribuition.filter';
import { LeadTimeFilter } from 'app/theme/layouts/charts/lead-time/lead-time.filter';
import { InvoicesFilter } from 'app/theme/layouts/charts/invoices/invoices.filter';
import { TopLiRegistrationFilter } from 'app/theme/layouts/operational/top-registration/top-li-registration/top-li-registration.filter';
import { TopDiRegistrationFilter } from 'app/theme/layouts/operational/top-registration/top-di-registration/top-di-registration.filter';
import { InvoiceStatusFilter } from 'app/theme/pages/invoice-status/invoice-status.filter';
import { ExchangeFlowFilter } from 'app/theme/pages/finance/exchange/exchange-flow/exchange-flow.filter';
import { AlertsManagerFilter } from 'app/modules/performance/pages/alerts-manager/alerts-maneger-filter.model';
import { SlaStatusFilter } from 'app/theme/layouts/charts/sla-status/sla-status.filter.model';
import { DocumentsFilter } from 'app/modules/documents/pages/document/helpers/document.filter';
import { GedFilter } from 'app/modules/documents/pages/document/models/gedFilter.model';
import { DiValidationFilter } from 'app/modules/performance/pages/di-validation/di-validation-filter.model';
import { MovementByModalFilter } from 'app/theme/layouts/operational/movement-by-modal/movement-by-modal.filter';
import { TaxConsultationFilter } from 'app/modules/financial/pages/tax-consultation/models/tax-consultation.filter.model';
import { SalesOrderFilter } from 'app/theme/pages/sales-order/salesIOrderFilter.model';
import { ChassiStatusFilter } from 'app/modules/operational/pages/chassi-status/chassiStatusFilter.model';
import { DocumentsEmissionFilter } from 'app/modules/operational/pages/documents-emission/DocumentsEmissionFilter.model';
import { ImpStatusFilter } from 'app/modules/operational/pages/imp-status/impStatusFilter.model';
import { POStatusFilter } from 'app/modules/operational/pages/po-status/po-status-filter.model';
import { ProjectPricingFilter } from 'app/modules/operational/pages/project-pricing/projectPricingFilter.model';
import { SkuImportStatusFilter } from 'app/modules/operational/pages/sku-status/skuImportStatusFilter.model';
import { CommercialPremiseFilter } from 'app/modules/financial/pages/premise/commercial-premise.filter';
import { TransportModalService } from '#services/_transportModal/transportModal.service';

// Logistica
import { DeliveryCoordinationIndirectFilter } from 'app/modules/logistic/pages/delivery-coordination/models/delivery-coordination-indirect.filter.model';
import { DeliveryCoordinationTextileFilter } from 'app/modules/logistic/pages/delivery-coordination/models/delivery-coordination-textile.filter.model';
import { InternationalQuotationFilter } from 'app/modules/logistic/pages/international-quotation/models/international-quotation.filter.model';
import { SimulateFreightRateFilter } from 'app/modules/logistic/pages/simulate-freight-rate/models/simulate-freight-rate.filter.model';

// Integrações
import { PaymentRequestAutoFilter } from 'app/modules/integrations/pages/suppliers/payment-request/models/payment-request-auto-filter.model';
import { PaymentRequestManualFilter } from 'app/modules/integrations/pages/suppliers/payment-request/models/payment-request-manual-filter.model';
import { TaxStudyFilter } from 'app/modules/financial/pages/tax-study/tax-study.filter';
import { BillOfLadingFilter } from 'app/modules/integrations/pages/suppliers/bill-of-lading/models/bill-of-lading.model';
import { PaymentClosingFilter } from 'app/modules/financial/pages/payment-closing/payment-closing.filter';
import { ExternalReportsFilter } from 'app/modules/reports/pages/external-reports/external-reports.filter';

// Relatórios
import { ExpenseReportsFilter } from 'app/modules/reports/pages/expense/models/expense.filter.model';
import { TradersReportsFilter } from 'app/modules/reports/pages/traders/models/traders.filter.model';
import { CustomWidgetsFilter } from 'app/theme/pages/default/index/custom-widgests.filter';


@Injectable()
export class FilterStateService {
    private statusConsolidationFilter: StatusConsolidationFilter = new StatusConsolidationFilter(this.transportModalService);
    private impStatusFilter: ImpStatusFilter = new ImpStatusFilter(this.userService, this.transportModalService);
    private skuStatusFilter: SkuStatusFilter = new SkuStatusFilter();
    private skuImpStatusFilter: SkuImportStatusFilter = new SkuImportStatusFilter(this.transportModalService);
    private chassiStatusFilter: ChassiStatusFilter = new ChassiStatusFilter();
    private newTrafficMapFilter: NewTrafficMapFilter = new NewTrafficMapFilter();
    private newTrackingTimelineFilter: NewTrackingTimelineFilter = new NewTrackingTimelineFilter();
    private fupImpFilter: FupImpFilter = new FupImpFilter(this.transportModalService, this.userService);
    private fupChassiFilter: FupChassiFilter = new FupChassiFilter(this.userService);
    private fupSkuFilter: FupSkuFilter = new FupSkuFilter(this.transportModalService, this.userService);
    private fupFollowUpFilter: FollowUpFilter = new FollowUpFilter(this.transportModalService, this.userService);
    private costsFilter: CostsFilter = new CostsFilter(this.userService);
    private invoiceFilter: InvoiceFilter = new InvoiceFilter();
    private salesOrderFilter: SalesOrderFilter = new SalesOrderFilter();
    private gedFilter: GedFilter = new GedFilter();
    private poStatusFilter: POStatusFilter = new POStatusFilter();
    private invoiceReceivableFilter: InvoiceReceivableFilter = new InvoiceReceivableFilter(this.userService);
    private slaConsolidationFilter: SlaConsolidationFilter = new SlaConsolidationFilter();
    private exchangeSpListingFilter: ExchangeSpListingFilter = new ExchangeSpListingFilter();
    private exchangePackageListingFilter: ExchangePackageListingFilter = new ExchangePackageListingFilter();
    private projectPricingFilter: ProjectPricingFilter = new ProjectPricingFilter();
    private channelFilter: ChannelFilter = new ChannelFilter();
    private liLeadTimeFilter: LiLeadTimeFilter = new LiLeadTimeFilter();
    private channelStatusFilter: ChannelStatusFilter = new ChannelStatusFilter();
    private boardingStatusFilter: BoardingStatusFilter = new BoardingStatusFilter();
    private documentsEmissionFilter: DocumentsEmissionFilter = new DocumentsEmissionFilter();
    private crProcessesFilter: CrProcessesFilter = new CrProcessesFilter();
    private logisticCostsFilter: LogisticCostsFilter = new LogisticCostsFilter(this.transportModalService);
    private costDistribuitionFilter: CostDistribuitionFilter = new CostDistribuitionFilter();
    private leadTimeFilter: LeadTimeFilter = new LeadTimeFilter();
    private invoicesFilter: InvoicesFilter = new InvoicesFilter();
    private documentsFilter: DocumentsFilter = new DocumentsFilter();
    private invoiceStatusFilter: InvoiceStatusFilter = new InvoiceStatusFilter();
    private alertsManagerFilter: AlertsManagerFilter = new AlertsManagerFilter();
    private slaStatusFilter: SlaStatusFilter = new SlaStatusFilter();

    // Logistica
    private deliveryCoordinationIndirectFilter: DeliveryCoordinationIndirectFilter = new DeliveryCoordinationIndirectFilter();
    private deliveryCoordinationTextileFilter: DeliveryCoordinationTextileFilter = new DeliveryCoordinationTextileFilter();
    private internationalQuotationFilter: InternationalQuotationFilter = new InternationalQuotationFilter();
    private simulateFreightRateFilter: SimulateFreightRateFilter = new SimulateFreightRateFilter();

    // Integrações
    private paymentRequestAutoFilter: PaymentRequestAutoFilter = new PaymentRequestAutoFilter();
    private paymentRequestManualFilter: PaymentRequestManualFilter = new PaymentRequestManualFilter();
    private billOfLadingFilter: BillOfLadingFilter = new BillOfLadingFilter();

    // Relatórios
    private expenseReportsFilter: ExpenseReportsFilter = new ExpenseReportsFilter();
    private tradersReportsFilter: TradersReportsFilter = new TradersReportsFilter();

    // Operational
    private operationalAlertsFilter: OperationalAlertsFilter = new OperationalAlertsFilter();
    private operationalConsolidationFilter: OperationalConsolidationFilter = new OperationalConsolidationFilter();
    private logisticsHandlingFilterAir: LogisticsHandlingFilter = new LogisticsHandlingFilter('Air');
    private logisticsHandlingFilterMaritime: LogisticsHandlingFilter = new LogisticsHandlingFilter('Maritime');
    private logisticsHandlingFilterRail: LogisticsHandlingFilter = new LogisticsHandlingFilter('Rail');
    private logisticsHandlingFilterRoad: LogisticsHandlingFilter = new LogisticsHandlingFilter('Road');
    private logisticsHandlingFilterVehicles: LogisticsHandlingFilter = new LogisticsHandlingFilter('Vehicles');
    private movementByModalFilter: MovementByModalFilter = new MovementByModalFilter();
    private documentConsolidationFilter: DocumentConsolidationFilter = new DocumentConsolidationFilter();
    private subscriptionsFilter: SubscriptionsFilter = new SubscriptionsFilter();
    private topLiRegistrationFilter: TopLiRegistrationFilter = new TopLiRegistrationFilter();
    private topDiRegistrationFilter: TopDiRegistrationFilter = new TopDiRegistrationFilter();
    private diValidationFilter: DiValidationFilter = new DiValidationFilter();

    // Financeiro
    private exchangeFlowFilter: ExchangeFlowFilter = new ExchangeFlowFilter();
    private taxConsultationFilter: TaxConsultationFilter = new TaxConsultationFilter();
    private commercialPremiseFilter: CommercialPremiseFilter = new CommercialPremiseFilter();
    private taxStudyFilter: TaxStudyFilter = new TaxStudyFilter();
    private paymentClosingFilter: PaymentClosingFilter = new PaymentClosingFilter();

    //Relatórios
    private externalReportsFilter: ExternalReportsFilter = new ExternalReportsFilter();

    // Dashboard
    private customWidgetFilter: CustomWidgetsFilter = new CustomWidgetsFilter();

    constructor(private userService: UserService, private transportModalService: TransportModalService) {}

    // Dashboard (Custom Widgets)
    getCustomWidgetsFilter(): CustomWidgetsFilter {
        return this.customWidgetFilter;
    }
    setCustomWidgetsFilter(customWidgetFilter: CustomWidgetsFilter) {
        this.customWidgetFilter = customWidgetFilter;
    }

    // Status
    getImpStatusFilter(): ImpStatusFilter {
        return this.impStatusFilter;
    }
    setImpStatusFilter(impStatusFilter: ImpStatusFilter) {
        this.impStatusFilter = impStatusFilter;
    }

    getSkuStatusFilter(): SkuStatusFilter {
        return this.skuStatusFilter;
    }
    setSkuStatusFilter(skuStatusFilter: SkuStatusFilter) {
        this.skuStatusFilter = skuStatusFilter;
    }

    getSkuImportStatusFilter(): SkuImportStatusFilter {
        return this.skuImpStatusFilter;
    }
    setSkuImportStatusFilter(skuImpStatusFilter: SkuImportStatusFilter) {
        this.skuImpStatusFilter = skuImpStatusFilter;
    }

    getChassiStatusFilter(): ChassiStatusFilter {
        return this.chassiStatusFilter;
    }
    setChassiStatusFilter(chassiStatusFilter: ChassiStatusFilter) {
        this.chassiStatusFilter = chassiStatusFilter;
    }

    getPoStatusFilter(): POStatusFilter {
        return this.poStatusFilter;
    }
    setPoStatusFilter(poStatusFilter: POStatusFilter) {
        this.poStatusFilter = poStatusFilter;
    }

    // FUPs
    getFupImpFilter(): FupImpFilter {
        return this.fupImpFilter;
    }
    setFupImpFilter(fupImpFilter: FupImpFilter) {
        this.fupImpFilter = fupImpFilter;
    }

    getFupChassiFilter(): FupChassiFilter {
        return this.fupChassiFilter;
    }

    getFupChassiFilterV2(): FupChassiFilter {
        return this.fupChassiFilter;
    }

    setFupChassiFilter(fupChassiFilter: FupChassiFilter) {
        this.fupChassiFilter = fupChassiFilter;
    }

    getFupSkuFilter(): FupSkuFilter {
        return this.fupSkuFilter;
    }
    setFupSkuFilter(fupSkuFilter: FupSkuFilter) {
        this.fupSkuFilter = fupSkuFilter;
    }

    getFupFollowUpFilter(): FollowUpFilter {
        return this.fupFollowUpFilter;
    }
    setFupFollowUpFilter(fupFollowUpFilter: FollowUpFilter) {
        this.fupFollowUpFilter = fupFollowUpFilter;
    }

    // Container Tracking
    getNewTrafficMapFilter(): NewTrafficMapFilter {
        return this.newTrafficMapFilter;
    }
    setNewTrafficMapFilter(newTrafficMapFilter: NewTrafficMapFilter) {
        this.newTrafficMapFilter = newTrafficMapFilter;
    }

    // New Tracking TimeLine
    getNewTrackingTimelineFilter(): NewTrackingTimelineFilter {
        return this.newTrackingTimelineFilter;
    }
    setNewTrackingTimelineFilter(newTrackingTimelineFilter: NewTrackingTimelineFilter) {
        this.newTrackingTimelineFilter = newTrackingTimelineFilter;
    }

    // Costs
    getCostsFilter(): CostsFilter {
        return this.costsFilter;
    }
    setCostsFilter(costsFilter: CostsFilter) {
        this.costsFilter = costsFilter;
    }

    // Commercial Premisse
    getCommercialPremiseFilter(): CommercialPremiseFilter {
        return this.commercialPremiseFilter;
    }
    setCommercialPremiseFilter(commercialPremiseFilter: CommercialPremiseFilter) {
        this.commercialPremiseFilter = commercialPremiseFilter;
    }

    // Relatórios Externos
    getExternalReportsFilter(): ExternalReportsFilter {
        return this.externalReportsFilter;
    }
    setExternalReportsFilter(externalReportsFilter: ExternalReportsFilter) {
        this.externalReportsFilter = externalReportsFilter;
    }

    // Estudo Tributário
    getTaxStudyFilter(): TaxStudyFilter {
        return this.taxStudyFilter;
    }
    setTaxStudyFilter(taxStudyFilter: TaxStudyFilter) {
        this.taxStudyFilter = taxStudyFilter;
    }

    // Fechamento Financeiro
    getPaymentClosingFilter(): PaymentClosingFilter {
        return this.paymentClosingFilter;
    }
    setPaymentClosingFilter(paymentClosingFilter: PaymentClosingFilter) {
        this.paymentClosingFilter = paymentClosingFilter;
    }

    // Operational
    getOperationalConsolidationFilter(): OperationalConsolidationFilter {
        return this.operationalConsolidationFilter;
    }
    setOperationalConsolidationFilter(operationalConsolidationFilter: OperationalConsolidationFilter) {
        this.operationalConsolidationFilter = operationalConsolidationFilter;
    }

    getOperationalAlertsFilter(): OperationalAlertsFilter {
        return this.operationalAlertsFilter;
    }
    setOperationalAlertsFilter(operationalAlertsFilter: OperationalAlertsFilter) {
        this.operationalAlertsFilter = operationalAlertsFilter;
    }

    // Logistics Handling (Air)
    getLogisticsHandlingFilterAir(): LogisticsHandlingFilter {
        return this.logisticsHandlingFilterAir;
    }
    setLogisticsHandlingFilterAir(logisticsHandlingFilterAir: LogisticsHandlingFilter) {
        this.logisticsHandlingFilterAir = logisticsHandlingFilterAir;
    }

    // Logistics Handling (Maritime)
    getLogisticsHandlingFilterMaritime(): LogisticsHandlingFilter {
        return this.logisticsHandlingFilterMaritime;
    }
    setLogisticsHandlingFilterMaritime(logisticsHandlingFilterMaritime: LogisticsHandlingFilter) {
        this.logisticsHandlingFilterMaritime = logisticsHandlingFilterMaritime;
    }

    // Logistics Handling (Rail)
    getLogisticsHandlingFilterRail(): LogisticsHandlingFilter {
        return this.logisticsHandlingFilterRail;
    }
    setLogisticsHandlingFilterRail(logisticsHandlingFilterRail: LogisticsHandlingFilter) {
        this.logisticsHandlingFilterRail = logisticsHandlingFilterRail;
    }

    // Logistics Handling (Road)
    getLogisticsHandlingFilterRoad(): LogisticsHandlingFilter {
        return this.logisticsHandlingFilterRoad;
    }
    setLogisticsHandlingFilterRoad(logisticsHandlingFilterRoad: LogisticsHandlingFilter) {
        this.logisticsHandlingFilterRoad = logisticsHandlingFilterRoad;
    }

    // Logistics Handling (Vehicles)
    getLogisticsHandlingFilterVehicles(): LogisticsHandlingFilter {
        return this.logisticsHandlingFilterVehicles;
    }
    setLogisticsHandlingFilterVehicles(logisticsHandlingFilterVehicles: LogisticsHandlingFilter) {
        this.logisticsHandlingFilterVehicles = logisticsHandlingFilterVehicles;
    }

    // Movement By Modal
    getMovementByModalFilter(): MovementByModalFilter {
        return this.movementByModalFilter;
    }
    setMovementByModalFilter(movementByModalFilter: MovementByModalFilter) {
        this.movementByModalFilter = movementByModalFilter;
    }

    // Document Consolidation
    getDocumentConsolidationFilter(): DocumentConsolidationFilter {
        return this.documentConsolidationFilter;
    }
    setDocumentConsolidationFilter(documentConsolidationFilter: DocumentConsolidationFilter) {
        this.documentConsolidationFilter = documentConsolidationFilter;
    }

    // GED
    getGedFilter(): GedFilter {
        return this.gedFilter;
    }
    setGedFilter(gedFilter: GedFilter) {
        this.gedFilter = gedFilter;
    }

    // Invoice
    getInvoiceFilter(): InvoiceFilter {
        return this.invoiceFilter;
    }

     // Sales Order
     getSalesOrderFilter(): SalesOrderFilter {
        return this.salesOrderFilter;
    }
    setInvoiceFilter(invoiceFilter: InvoiceFilter) {
        this.invoiceFilter = invoiceFilter;
    }

    // Invoice Receivable
    getInvoiceReceivableFilter(): InvoiceReceivableFilter {
        return this.invoiceReceivableFilter;
    }
    setInvoiceReceivableFilter(invoiceReceivableFilter: InvoiceReceivableFilter) {
        this.invoiceReceivableFilter = invoiceReceivableFilter;
    }

    // Invoice Status
    getInvoiceStatusFilter(): InvoiceStatusFilter {
        return this.invoiceStatusFilter;
    }
    setInvoiceStatusbleFilter(invoiceStatusFilter: InvoiceStatusFilter) {
        this.invoiceStatusFilter = invoiceStatusFilter;
    }

    // Sla Consolidation
    getSlaConsolidationFilter(): SlaConsolidationFilter {
        return this.slaConsolidationFilter;
    }
    setSlaConsolidationFilter(slaConsolidationFilter: SlaConsolidationFilter) {
        this.slaConsolidationFilter = slaConsolidationFilter;
    }

    // Status Consolidation
    getStatusConsolidationFilter(): StatusConsolidationFilter {
        return this.statusConsolidationFilter;
    }
    setStatusConsolidationFilter(statusConsolidationFilter: StatusConsolidationFilter) {
        this.statusConsolidationFilter = statusConsolidationFilter;
    }

    // Exchange Sp Listing
    getExchangeSpListingFilter(): ExchangeSpListingFilter {
        return this.exchangeSpListingFilter;
    }
    setExchangeSpListingFilter(exchangeSpListingFilter: ExchangeSpListingFilter) {
        this.exchangeSpListingFilter = exchangeSpListingFilter;
    }

    // Exchange Package Listing
    getExchangePackageListingFilter(): ExchangePackageListingFilter {
        return this.exchangePackageListingFilter;
    }
    setExchangePackageListingFilter(exchangePackageListingFilter: ExchangePackageListingFilter) {
        this.exchangePackageListingFilter = exchangePackageListingFilter;
    }

    getDocumentsEmissionFilter(): DocumentsEmissionFilter {
        return this.documentsEmissionFilter;
    }

    // Project Pricing
    getProjectPricingFilter(): ProjectPricingFilter {
        return this.projectPricingFilter;
    }

    // Channel
    getChannelFilter(): ChannelFilter {
        return this.channelFilter;
    }
    setChannelFilter(channelFilter: ChannelFilter) {
        this.channelFilter = channelFilter;
    }

    // Channel Status
    getChannelStatusFilter(): ChannelStatusFilter {
        return this.channelStatusFilter;
    }
    setChannelStatusFilter(channelStatusFilter: ChannelStatusFilter) {
        this.channelStatusFilter = channelStatusFilter;
    }

    getSubscriptionsFilter(): SubscriptionsFilter {
        return this.subscriptionsFilter;
    }

    // LiLeadTime
    getLiLeadTimeFilter(): LiLeadTimeFilter {
        return this.liLeadTimeFilter;
    }
    setLiLeadTimeFilter(liLeadTimeFilter: LiLeadTimeFilter) {
        this.liLeadTimeFilter = liLeadTimeFilter;
    }

    // Boardingstatus
    getBoardingStatusFilter(): BoardingStatusFilter {
        return this.boardingStatusFilter;
    }
    setBoardingStatusFilter(boardingStatusFilter: BoardingStatusFilter) {
        this.boardingStatusFilter = boardingStatusFilter;
    }

    verifySelectedStatus(statusList, statusCode) {
        // tslint:disable-next-line:no-string-literal
        return statusList.findIndex((status) => status['code'] === statusCode) === -1 ? false : true;
    }

    // CR Processes
    getCrProcessesFilter(): CrProcessesFilter {
        return this.crProcessesFilter;
    }
    setCrProcessesFilter(crProcessesFilter: CrProcessesFilter) {
        this.crProcessesFilter = crProcessesFilter;
    }

    // Logistic Cost
    getLogisticCostsFilter(): LogisticCostsFilter {
        return this.logisticCostsFilter;
    }
    setLogisticCostsFilter(logisticCostsFilter: LogisticCostsFilter) {
        this.logisticCostsFilter = logisticCostsFilter;
    }

    // Cost Distribuition
    getCostDistribuitionFilter(): CostDistribuitionFilter {
        return this.costDistribuitionFilter;
    }
    setCostDistribuitionFilter(costDistribuitionFilter: CostDistribuitionFilter) {
        this.costDistribuitionFilter = costDistribuitionFilter;
    }
    // LeadTime
    getLeadTimeFilter(): LeadTimeFilter {
        return this.leadTimeFilter;
    }
    setLeadTimeFilter(leadTimeFilter: LeadTimeFilter) {
        this.leadTimeFilter = leadTimeFilter;
    }
    // Invoices
    getInvoicesFilter(): InvoicesFilter {
        return this.invoicesFilter;
    }
    setInvoicesFilter(invoicesFilter: InvoicesFilter) {
        this.invoicesFilter = invoicesFilter;
    }
    // Documents
    getDocumentsFilter(): DocumentsFilter {
        return this.documentsFilter;
    }
    setDocumentsFilter(documentsFilter: DocumentsFilter) {
        this.documentsFilter = documentsFilter;
    }

    // TopLiRegistrationFilter
    getTopLiRegistrationFilter(): TopLiRegistrationFilter {
        return this.topLiRegistrationFilter;
    }
    setTopLiRegistrationFilter(topLiRegistrationFilter: TopLiRegistrationFilter) {
        this.topLiRegistrationFilter = topLiRegistrationFilter;
    }

    // TopDiRegistrationFilter
    getTopDiRegistrationFilter(): TopDiRegistrationFilter {
        return this.topDiRegistrationFilter;
    }
    setTopDiRegistrationFilter(topDiRegistrationFilter: TopDiRegistrationFilter) {
        this.topDiRegistrationFilter = topDiRegistrationFilter;
    }

    // ExchangeFlowFilter
    getExchangeFlowFilter(): ExchangeFlowFilter {
        return this.exchangeFlowFilter;
    }
    setExchangeFlowFilter(exchangeFlowFilter: ExchangeFlowFilter) {
        this.exchangeFlowFilter = exchangeFlowFilter;
    }

    // TaxConsultationFilter
    getTaxConsultationFilter(): TaxConsultationFilter {
        return this.taxConsultationFilter;
    }
    setTaxConsultationFilter(taxConsultationFilter: TaxConsultationFilter) {
        this.taxConsultationFilter = taxConsultationFilter;
    }

    // Alerts Manager
    getManagerFilter(): AlertsManagerFilter {
        return this.alertsManagerFilter;
    }
    setAlertsManagerFilter(alertsManagerFilter: AlertsManagerFilter) {
        this.alertsManagerFilter = alertsManagerFilter;
    }

    // Sla Status
    getSlaStatusFilter(): SlaStatusFilter {
        return this.slaStatusFilter;
    }
    setSlaStatusFilter(slaStatusFilter: SlaStatusFilter) {
        this.slaStatusFilter = slaStatusFilter;
    }

    // Relatório de Despesas
    getExpenseReportsFilter(): ExpenseReportsFilter {
        return this.expenseReportsFilter;
    }
    setExpenseReportsFilter(expenseReportsFilter: ExpenseReportsFilter) {
        this.expenseReportsFilter = expenseReportsFilter;
    }

    // Relatório de Traders
    getTradersReportsFilter(): TradersReportsFilter {
        return this.tradersReportsFilter;
    }
    setTradersReportsFilter(tradersReportsFilter: TradersReportsFilter) {
        this.tradersReportsFilter = tradersReportsFilter;
    }

    // Solicitações de Pagamentos Automáticas/Manuais
    getPaymentRequestAutoFilter(): PaymentRequestAutoFilter {
        return this.paymentRequestAutoFilter;
    }
    setPaymentRequestAutoFilter(paymentRequestAutoFilter: PaymentRequestAutoFilter) {
        this.paymentRequestAutoFilter = paymentRequestAutoFilter;
    }

    getPaymentRequestManualFilter(): PaymentRequestManualFilter {
        return this.paymentRequestManualFilter;
    }
    setPaymentRequestManualFilter(paymentRequesManualFilter: PaymentRequestManualFilter) {
        this.paymentRequestManualFilter = paymentRequesManualFilter;
    }

    // Bill of Lading
    getBillOfLadingFilter(): BillOfLadingFilter {
        return this.billOfLadingFilter;
    }
    setBillOfLadingFilter(billOfLadingFilter: BillOfLadingFilter) {
        this.billOfLadingFilter = billOfLadingFilter;
    }

    // DiValidation
    getDiValidationFilter(): DiValidationFilter {
        return this.diValidationFilter;
    }
    setDiValidationFilter(diValidationFilter: DiValidationFilter) {
        this.diValidationFilter = diValidationFilter;
    }

    // -------------------------------------------------------------------------------------- Logistica
    // Carregamento / Delivery Coordination
    getDeliveryCoordinationIndirectFilter(): DeliveryCoordinationIndirectFilter {
        return this.deliveryCoordinationIndirectFilter;
    }
    setDeliveryCoordinationIndirectFilter(deliveryCoordinationIndirectFilter: DeliveryCoordinationIndirectFilter) {
        this.deliveryCoordinationIndirectFilter = deliveryCoordinationIndirectFilter;
    }

    getDeliveryCoordinationTextileFilter(): DeliveryCoordinationTextileFilter {
        return this.deliveryCoordinationTextileFilter;
    }
    setDeliveryCoordinationTextileFilter(deliveryCoordinationTextileFilter: DeliveryCoordinationTextileFilter) {
        this.deliveryCoordinationTextileFilter = deliveryCoordinationTextileFilter;
    }

    // Cotação Internacional
    getInternationalQuotationFilter(): InternationalQuotationFilter {
        return this.internationalQuotationFilter;
    }
    setInternationalQuotationFilter(internationalQuotationFilter: InternationalQuotationFilter) {
        this.internationalQuotationFilter = internationalQuotationFilter;
    }

    // Simular Frete
    getSimulateFreightRateFilter(): SimulateFreightRateFilter {
        return this.simulateFreightRateFilter;
    }
    setSimulateFreightRateFilter(simulateFreightRateFilter: SimulateFreightRateFilter) {
        this.simulateFreightRateFilter = simulateFreightRateFilter;
    }
}

