import { AfterViewInit, Component, Input, OnDestroy, Output, ViewEncapsulation, EventEmitter } from '@angular/core';
import { DROPDOWN_SETTINGS } from 'app/shared/constants/dropdown-settings.constants';
import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { FilterStateService } from '#services/_filters/filter-state.service';
import { DatamartService } from '#services/_datamart/datamart.service';
import { StatusService } from '#services/_status/status.service';
import { UtilsService } from '#services/_utils/utils.service';
import { UserService } from 'app/auth/_services';
import { IDatamartPreferencesFilters } from './interfaces/cmx-datamart-preferences-filters.interface';
import { DatamartFiltersService } from './services/cmx-datamart-preferences-filters';
import * as _ from 'lodash';

interface ILinkOption { id, name, enabled }

@Component({
    selector: 'cmx-datamart-preferences',
    templateUrl: './cmx-datamart-preferences.component.html',
    styleUrls: ['cmx-datamart-preferences.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [DatamartFiltersService]
})
export class DatamartPreferencesComponent implements OnDestroy, AfterViewInit {

    @Input() datamartName: string;
    @Input() serviceId: string;
    @Input() generatorId: string;
    @Input() modalTitle: string;
    @Input() filters: IDatamartPreferencesFilters[] = [];
    @Input() filterState;
    @Input() subItensData: [] = undefined;
    @Input() subItensSettings;
    @Input() selectedSubItem;
    @Input() hideEmail: boolean = false;
    @Input() hideGuide: boolean = false;
    @Input() linkOptions: ILinkOption[] = [];

    @Output() onLinkOptionsChange = new EventEmitter();

    array = Array;
    url = window.location.href.replace('//', '.').split('.')[1];
    dropdownSettings = _.cloneDeep(DROPDOWN_SETTINGS);

    async: boolean = false;
    linkEnabled: boolean = false;
    emailEnabled: boolean = false;
    showUpdateLinkModal: boolean = false;
    linkUrlBase: string = this._defineURLBase();
    linkUrl: string = '';
    options: { modelList: DropdownOption[] } = { modelList: [] };
    datamartFilters: any = new Object();
    asyncDatamart: boolean = true;
    subscription;
    currentUser;
    type;
    linkUpdateMessage: string = 'O link será atualizado cada 2 horas entre 07:00 e 19:00.';

    constructor(
        public filterStateService: FilterStateService,
        private _datamartService: DatamartService,
        private _utilsService: UtilsService,
        private _userService: UserService,
        private _statusService: StatusService,
        private _datamartFiltersService: DatamartFiltersService,
    ) {
        this.currentUser = this._userService.getCurrentUser();
    }

    ngAfterViewInit() {
        this.getDatamartConfig();
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    onChangeSwitch(event) {
        if (event.switchName === 'Gerar Link') {
            this.linkEnabled = event.switchValue;
        } else {
            this.emailEnabled = event.switchValue;
        }
        this.saveDatamartConfig();
    }

    onSelectLinkOption(option) {
        this.linkOptions?.map((option) => option.enabled = false);
        option.enabled = true;
        this.onLinkOptionsChange.emit(option);
        setTimeout(() => { this.saveDatamartConfig() }, 500);
    }

    confirmUpdateLink() {
        this.showUpdateLinkModal = true;
    }

    updateLink() {
        this.showUpdateLinkModal = false;
        this.saveDatamartConfig();
    }

    getDatamartConfig() {
        const subItem = this.selectedSubItem || [];
        this.asyncDatamart = true;
        this._datamartService
            .getDatamartConfig(this.datamartName, this.serviceId, this.generatorId, subItem.length ? subItem[0].itemName : 'default')
            .subscribe((datamartConfig: any) => {
                this.linkUrl = datamartConfig.uri || '';
                this._setDeliveryOptions(datamartConfig.deliveryOptions);
                this.datamartFilters = this._datamartFiltersService.defineVisualFilters(datamartConfig.parameters, this.generatorId, this.currentUser);
                this.updateLinkMessage(datamartConfig.parameters);
                this.asyncDatamart = false;
            }, () => {
                this.linkUrl = '';
                this.linkEnabled = false;
                this.emailEnabled = false;
                this.asyncDatamart = false;
            }
        );
    }

    saveDatamartConfig() {
        const subItem = this.selectedSubItem || [];
        this.asyncDatamart = true;
        if (subItem.length || !this.subItensData) {
            if (this.subscription) this.subscription.unsubscribe();
            this.subscription = this._datamartService.setDatamartConfiguration(
                this.datamartName,
                this.serviceId,
                this.generatorId,
                this._getDeliveryOptions(),
                subItem.length ? subItem[0].itemName : 'default',
                this._datamartFiltersService.prepareFiltersToService(
                    this.generatorId, this.filterState, this.currentUser, subItem || []))
                    .subscribe(
                    (datamartConfig: any) => {
                        this.linkUrl = datamartConfig.uri || '';
                        this.datamartFilters = this._datamartFiltersService.defineVisualFilters(datamartConfig.parameters, this.generatorId, this.currentUser);
                        this.updateLinkMessage(datamartConfig.parameters);
                        this.asyncDatamart = false;
                    },
                    (error) => {
                        this.asyncDatamart = false;
                        this._utilsService.errorHandler(error, 'Datamart');
                    }
                );
        } else {
            this.asyncDatamart = false;
            this._utilsService.errorHandler('Por favor, selecione um relatório', 'Datamart');
        }
    }

    downloadDatamartGuide() {
        window.open('/assets/app/pdf/Manual_Data_Mart.pdf', '_blank');
    }

    getCurrentFilter(filter, status?) {
        const statusLength = this._statusService.impStatuslLength;
        if (!filter) { return 'Todos' }
        if (filter.length === 1) {
            return filter[0] ? filter[0] : 'Todos';
        } else if (filter.length > 1) {
            return status && statusLength === filter.length ? 'Todos' : `${filter.length} selecionados`;
        } else {
            return 'Todos';
        }
    }

    private _getDeliveryOptions() {
        const options = [];
        if (this.linkEnabled) options.push('LINK');
        if (this.emailEnabled) options.push('EMAIL');
        return options;
    }

    private _setDeliveryOptions(options) {
        this.linkEnabled = options.includes('LINK') ? true : false;
        this.emailEnabled = options.includes('EMAIL') ? true : false;
    }

    private _defineURLBase() {
        return `https://${this.url.includes('bi-stage') || this.url.includes('localhost') ?
            'bi-stage' : 'bi' }.comexport.com.br/datamart`;
    }

    private updateLinkMessage(parameters) {
        if (parameters.periodicity && parameters.periodicity[0] === '1') {
            this.linkUpdateMessage = 'O link será atualizado uma vez ao dia às 23:00.';
        } else {
            this.linkUpdateMessage = 'O link será atualizado cada 2 horas entre 07:00 e 19:00.';
        }
    }
}
