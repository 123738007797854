import { UsageAnalyticsService } from '#services/_usage-analytics/usage-analytics.service';
import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

@Component({
    selector: 'cmx-video',
    templateUrl: './cmx-video.component.html',
    styleUrls: ['./cmx-video.component.scss'],
})
export class VideoComponent {

    private _videoSrc: string = '';
    @Input()
    set videoSrc(value: string) { this._videoSrc = value; }

    get videoSrc(): string {return this._videoSrc; }

    // (videoName) nome dinâmico do vídeo
    @Input() videoName: string = 'Desconhecido';
    // Título do vídeo
    @Input() videoTitle?: string;
    // (disableAnalytics) desabilita o envio de eventos de analytics
    @Input() disableAnalytics: boolean = false;
    // Define o tamanho do vídeo
    @Input() videoSize: 'small' | 'medium' | 'medium-large' | 'large' | 'full' = 'medium';

    @Output() videoStarted = new EventEmitter<void>();
    @Output() videoEnded = new EventEmitter<void>();

    @ViewChild('videoPlayer') videoPlayer!: ElementRef<HTMLVideoElement>;

    constructor(private _usageAnalyticsService: UsageAnalyticsService) { }

    ngAfterViewInit() {
        this._preventDownload();
    }

    // Função que previne clique direito do mouse
    onContextMenu(event) {
        event.preventDefault();
    }

    // Função chamada quando o vídeo começa
    onVideoStarted() {
        const eventName = `${this.videoName} (Vídeo)`;
        if (!this.disableAnalytics) {
            this._usageAnalyticsService.sendEvent('Feature', 'view', eventName, null, {});
        }
        this.videoStarted.emit();
    }

    // Função chamada quando o vídeo termina
    onVideoEnded() {
        this.videoEnded.emit();
    }

    // Função para calcular o tempo assistido até a pausa
    onVideoPaused() {
        this.videoPlayer.nativeElement.currentTime;
    }

    getVideoSizeClass(): string {
        switch (this.videoSize) {
            case 'small': 
                return 'video-small';
            case 'medium-large': 
                return 'video-medium-large';
            case 'large': 
                return 'video-large';
            case 'full': 
                return 'video-full';
            default: 
                return 'video-medium';
        }
    }

    private _preventDownload() {
        const videoElement = this.videoPlayer?.nativeElement;
        // Crie o MutationObserver para observar alterações no atributo 'controlslist' (segurança)
        const observer = new MutationObserver((mutationsList) => {
          mutationsList?.forEach(mutation => {
            if (mutation?.type === 'attributes' && mutation?.attributeName === 'controlslist') {
              if (!videoElement?.hasAttribute('controlslist') || !videoElement?.getAttribute('controlslist')?.includes('nodownload')) {
                // Re-adiciona o 'nodownload' se ele não estiver presente
                videoElement?.setAttribute('controlslist', 'nodownload');
              }
            }
          });
        });
        // Iniciar a observação no atributo 'controlslist'
        observer?.observe(videoElement, {
          attributes: true // Observar mudanças nos atributos
        });
    }
}
