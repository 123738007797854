<ng-template #signInTemplate>
    <form autocomplete="off" (ngSubmit)="signin()" [formGroup]="formSign">
        <span class="throw-error-message" *ngIf="errorLogin">{{ errorLogin }}</span>
        <div class="form-group">
            <input type="email" class="input-text-email" #txtEmail formControlName="email" [placeholder]="'auth.email' | translate" autocomplete="off">
        </div>
        <div class="form-group">
            <input [type]="firstPasswordVisible?'password':'text'" class="input-text-password" formControlName="password" [placeholder]="'auth.password' | translate" autocomplete="off">
            <span class="eyeicon" (click)="changePasswordVisibility(true)"><i [ngClass]="firstPasswordVisible?'fa fa-eye':'fa fa-eye-slash'"></i></span>
        </div>
        <div class="form-group no-margin actions-container">
            <label for="remember">
                <input type="checkbox" name="remember" class="checkbox-remember" [(ngModel)]="rememberCredential" [checked]="rememberCredential" [ngModelOptions]="{standalone: true}" id="remember">
                    {{ 'auth.rememberMe' | translate }}
            </label>
            <button type="button" class="button-recover-password" [disabled]="asyncLogin" (click)="forgotPassword()" i18n="@@auth.forgotPassword">
                {{ 'auth.forgotPassword' | translate }}
            </button>
        </div>
        <div class="recaptcha-container" *ngIf="!twoFactorRequired && captchaEnable">
            <p-captcha #captcha [siteKey]="captchaKey" (onResponse)="validateCaptcha($event)" (onExpire)="resetCaptcha()"></p-captcha>
        </div>
        <div class="form-group align-right actions-form" *ngIf="!twoFactorRequired">
            <button #enterToLogin type="submit" class="button-submit" [disabled]="asyncLogin || !formSign.valid" i18n="@@auth.submit">
                {{ 'auth.submit' | translate }}
            </button>
        </div><br>
        <div *ngIf="mobileLinkEnable" class="app-style">
            <div>
                <a href="https://play.google.com/store/apps/details?id=br.com.comexport.app" target="_blank">
                    <img class="logoPlayStore" src="assets/img/misc/googlePlay.svg">
                </a>
                <a href="https://apps.apple.com/tt/app/comexport/id1598931830" target="_blank">
                    <img class="logoAppStore" src="assets/img/misc/appStore.svg">
                </a>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #twoFactorTemplate>
    <form class="two-factor" autocomplete="off" (ngSubmit)="signin()" [formGroup]="formSign">
        <div class="form-group">
            <label>
                {{ 'auth.msgEnterAuthCode' | translate }} ({{ userData.email }})
            </label>
            <span class="throw-error-twofactor" *ngIf="messageTwoFactorError">{{ messageTwoFactorError }}</span>
            <input type="text" class="input-text-twofactor" mask="000 000" #txtTwofactor placeholder="Código" formControlName="otpCode" autocomplete="off">
        </div>
        <button type="submit" class="button-validate" [disabled]="!formSign.controls['otpCode'].valid || asyncLogin" (click)="validateTwoFactor()">
            {{ 'auth.msgValidateAndContinue' | translate }}
        </button>
    </form>
</ng-template>

<ng-template #createTwoFactor>
        <div class="warningTwoFactor" *ngIf="!showEnableTwoFactor">
            <span class="infoWarning">{{ 'auth.msgAttention' | translate }}</span>
            <div class="infoMessage mt-3">
                <span>
                    {{ 'auth.msgAttentionTwoFactorRequired' | translate }}<br />
                    {{ 'auth.msgLoginWithoutAuthCode' | translate }}
                </span>
            </div>

            <div class="actions-buttons">
                <button class="button-cancel" (click)="backToSignIn()">{{ 'system::actions.cancel' | translate }}</button>
                <button class="button-confirm" (click)="enableTwoFactor()">{{ 'system::actions.continue' | translate }}</button>
            </div>
        </div>

        <div class="twoFactorQRCode" *ngIf="showEnableTwoFactor">
            <span class="infoWarning">
                {{ 'auth.msgTwoFactorAuthActive' | translate }}<br />
                {{ 'auth.msgLoginWithoutAuthCode' | translate }}
            </span>

            <div class="infoMessage mt-4">
                <span>{{ 'auth.msgQRCodeGoogleAuthenticator' | translate }}</span>
            </div>

            <p class="tutorial-text" (click)="openTutorial()">{{ 'auth.msgSeeHow' | translate }}</p>

            <div class="qrcode-temp" *ngIf="!showDivQRCode"></div>

            <div id="qrcode" [ngStyle]="{'display': showDivQRCode ? 'inline-block' : 'none'}"></div>

            <div class="actions-buttons">
                <button class="button-confirm" (click)="backToSignIn(true)">{{ 'system::actions.continue' | translate }}</button>
            </div>
        </div>
</ng-template>

<div id="login-area" *ngIf="initialized">
    <div class="login-container">
        <div class="form-container">
            <div class="header-form">
                <h1>
                    <img [src]="themeLogo" [alt]="themeClient" class="logo" />
                </h1>
            </div>

            <ng-container [ngTemplateOutlet]="activeTemplate"></ng-container>

            <div *ngIf="asyncLogin" style="position: relative;">
                <div class="loaderModel4" style="margin-top: 20px">
                    <div class="cube cube1"></div>
                    <div class="cube cube2"></div>
                    <div class="cube cube3"></div>
                    <div class="cube cube4"></div>
                    <div class="cube cube5"></div>
                    <div class="cube cube6"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-container" [ngStyle]="{'background-position': themeClient === 'bi' ? 'left center' : 'right center'}" [style.background-image]="themeBackground"></div>
</div>

<div #twoFactorTutorial class="twoFactorTutorial hidden">
    <i (click)="closeTutorial()" class="la la-close"></i>
    <div class="twoFactorTutorialContent">
        <div class="imageContainer">
            <span>{{ 'auth.msgDownloadGoogleAuthenticator' | translate }}</span>
            <img src="assets/img/tutos/two-factor/two-factor1.png" alt="">
        </div>
        <div class="imageContainer">
            <span>{{ 'auth.msgClickStart' | translate }}</span>
            <img src="assets/img/tutos/two-factor/two-factor2.png" alt="">
        </div>
        <div class="imageContainer">
            <span>{{ 'auth.msgChooseBarcodeOption' | translate }}</span>
            <img src="assets/img/tutos/two-factor/two-factor3.png" alt="">
        </div>
        <div class="imageContainer">
            <span>{{ 'auth.msgPointCameraAtBarcode' | translate }}</span>
            <img src="assets/img/tutos/two-factor/two-factor4.png" alt="">
        </div>
        <div class="imageContainer">
            <span>{{ 'auth.msgReadyAccessKeySaved' | translate }}</span>
            <img src="assets/img/tutos/two-factor/two-factor5.png" alt="">
        </div>
    </div>
</div>
