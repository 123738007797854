<div *ngIf="async" class="fullLoader"><loader></loader></div>
<div class="configuration-content">
    <div class="clients-list-column" [style.width]="'30%'">
        <cmx-listing
            id="cost-limit-listing"
            [list]="clientList"
            [searchPlaceholder]="'Buscar Cliente'"
            [instantSearch]="true"
            [hasAddButton]="false"
            [height]="'calc(100vh - 250px)'"
            (onSelect)="selectClient($event)"
        ></cmx-listing>
    </div>
    <div class="data-screen-column">
        <h5>{{ selectedClient?.name }}</h5>
        <hr>
        <span style="font-weight: 600">Liberações de Dados</span>
        <div class="data-container">
            <div class="data-check-container">
                <label class="title" style="display: flex;">Tela</label>
                <div *ngFor="let data of dataList" class="check-container">
                    <input (click)="checkClick(data)" [id]="data.screen" class="check-item" name="type" type="checkbox" [checked]="data.enabled">
                    <div class="label" (click)="checkClick(data)">{{ data.screen }}</div>
                </div>
            </div>
            <div class="validity-container">
                <label class="title" style="display: flex;">Vigência</label>
                <div *ngFor="let data of dataList" class="check-container">
                    <cmx-filter-datepicker
                        [id]="data.screen + '-startDate'"
                        [type]="'single-date'"
                        alone="'true'"
                        [empty-dates]="'true'"
                        [disabled]="!data.enabled"
                        [startDate]="data.startDate"
                        (onChangeDates)="startDateChanged($event, data)"
                    ></cmx-filter-datepicker>
                </div>
            </div>
        </div>
    </div>
    <div class="button-component">
        <cmx-button variant="save" (click)="save()">Salvar</cmx-button>
    </div>
</div>
