import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, Renderer2, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '#environment';

import { LeadTimeFilter } from './lead-time.filter';
import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';

import { CmxDropdownService } from 'app/shared/components/cmx-dropdown/cmx-dropdown.service';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UtilsService } from '#services/_utils/utils.service';
import { UserService } from "app/auth/_services/user.service";
import { FilterStateService } from '#services/_filters/filter-state.service';
import { AmCharts5Service, IChartConfig } from '#services/_charts/charts.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';

@Component({
    selector: 'lead-time-chart',
    templateUrl: './lead-time.component.html',
    styleUrls: ['./../charts.scss', './lead-time.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class LeadTimeComponent {

    currentUser = this.userService.getCurrentUser();
    currentUser$ = this.userService.currentUser$;
    isInternalUser = this.currentUser.has('INTERNAL_FILTERS');
    noResultsFound: boolean = false;
    asyncLeadTime: boolean = true;
    loadingTranslate: boolean = false;
    editableElements = ['#leadTime'];
    selectedPeriod: string = 'CURRENT_YEAR';
    translatedPeriodOptions = this.periodOptions;
    filterState: LeadTimeFilter = this._filterStateService.getLeadTimeFilter();
    startDateRestriction = _.cloneDeep(this.filterState.getStartDate());
    endDateRestriction = _.cloneDeep(this.filterState.getEndDate());
    subscription: Subscription = null;
    translateSubscription: Subscription = null;
    clientOptions: DropdownOption[] = this._cmxDropdownService.toDropdownItems(this._userCacheService.getClientGroups());
    kpiOptions: DropdownOption[] = [];
    chartData;

    @HostListener('click') onSetZIndex() {
        this.renderer2.setStyle(this._elementRef.nativeElement, 'z-index', this.utilsService.autoIncrementValue);
    }

    get helpDescription(): object[] {
        return [
            { type: 'text', value: this._translateService.instant('dashboard::leadTime.description') }
        ];
    }

    get periodOptions() {
        return [
            {
                code: 'CURRENT_YEAR',
                name: this._translateService.instant('common::strings.currentYear')
            },
            {
                code: 'PREVIOUS_MONTH',
                name: this._translateService.instant('common::strings.previousMonth')
            },
            {
                code: 'CURRENT_MONTH',
                name: this._translateService.instant('common::strings.currentMonth')
            }
        ];
    }

    get dropdownSettings() {
        return {
            kpi: {
                text: 'KPI',
                singleSelection: true,
                translate: true
            },
            clientGroups: {
                text: this._translateService.instant('common::strings.clientsGroup'),
                translate: true
            }
        };
    }

    constructor(
        public router: Router,
        private userService: UserService,
        private http: HttpClient,
        private utilsService: UtilsService,
        private _userCacheService: UserCacheService,
        private _cmxDropdownService: CmxDropdownService,
        private _AmCharts5Service: AmCharts5Service,
        private _elementRef: ElementRef,
        private renderer2: Renderer2,
        private _filterStateService: FilterStateService,
        private _translateService: TranslateService,
        private _userPreferencesService: UserPreferencesService
    ) {
        if(!this.isInternalUser) {
            const client = [this._userCacheService.getClientGroups()[0]];
            this.filterState.setClientGroups(this._cmxDropdownService.toDropdownItems(client || []));
        }
    }

    ngOnDestroy() {
        this._unsubscribe();

        if(this.translateSubscription) {
            this.translateSubscription.unsubscribe();
        }
    }

    ngAfterViewInit() {
        if (this.filterState.getClientGroups()?.length > 0 || !this.isInternalUser) {
            this.getDropdownKPIs();
        } else {
            this.asyncLeadTime = false;
            this.noResultsFound = true;
        }

        this.onChanges();
    }

    onChanges() {
        this.translateSubscription = this._userPreferencesService.onLanguageOrPreferenceChange(
            () => {
                this.loadingTranslate = true;
            }, (typeChange) => {
                if (typeChange === 'language') {
                    this._translateContent();
                }
            }
        );
    }

    getDropdownKPIs(onlyDropdown?: boolean): void {
        this.asyncLeadTime = true;

        this.subscription = this.getKPIs().subscribe((kpis) => {
            if (kpis?.length > 0) {
                this.kpiOptions = this._cmxDropdownService.toDropdownItems(kpis);
                const [currentSelectedKpi] = this.filterState.getSelectedKpi() || [{}];
                if (!this.kpiOptions?.some(kpi => kpi.id === currentSelectedKpi?.id)) this.filterState.setSelectedKpi([]);

                if (onlyDropdown) {
                    this.noResultsFound = true;
                    this.asyncLeadTime = false;
                } else {
                    this.applyFilter();
                }

            } else {
                this.noResultsFound = true;
                this._resetKPIOptions();
                this.utilsService.setInvisible('lead-time-chart', this.editableElements);
                this.asyncLeadTime = false;
            }
        });
    }

    datesChanged(event) {
        const lastStartDate = _.cloneDeep(this.filterState.getStartDate());
        const lastEndDate = _.cloneDeep(this.filterState.getEndDate());
        this.filterState.setStartDate(event.startDate);
        this.filterState.setEndDate(event.endDate);
        this._verifyDateLimit(event.startDate, event.endDate, lastStartDate, lastEndDate);
    }

    applyFilter() {
        if (this.filterState.getSelectedKpi()?.length < 1 || this.filterState.getClientGroups()?.length < 1) {
            this.filterState.savePreferences();
            this.utilsService.setInvisible('lead-time-chart', this.editableElements);
            this.asyncLeadTime = false;
            this.noResultsFound = true;
        } else {
            this.loadLeadTime();
        }
    }

    loadLeadTime() {
        this.asyncLeadTime = true;
        this.utilsService.setInvisible('lead-time-chart', this.editableElements);

        this.getLeadTime().pipe(map((months) => this.formatLeadTimeData(months))).subscribe(
            (formattedMonths) => {
                this.chartData = formattedMonths;
                this._createChart(this.chartData);

                if (this.chartData && this.chartData?.length === 0) {
                    this.utilsService.setInvisible('lead-time-chart', this.editableElements);
                    this.noResultsFound = true;
                } else {
                    this.utilsService.setVisible('lead-time-chart', this.editableElements);
                    this.noResultsFound = false;
                }

                this.asyncLeadTime = false;
            }, (error) => {
                this.chartData = undefined;
                this.asyncLeadTime = false;
                this.utilsService.errorHandler(error, this._translateService.instant('dashboard.title::leadTime'));
            }
        );
    }

    toggleVisualSwitch(values) {
        const chartType = values.index === 0 ? 'XYChart' : 'LineChart';
        this.filterState.setChartType(chartType);
        this.applyFilter();
    }

    changePreset(selectedPreset) {
        const lastStartDate = _.cloneDeep(this.filterState.getStartDate());
        const lastEndDate = _.cloneDeep(this.filterState.getEndDate());

        if (!selectedPreset.firstChange) {
            const preset = selectedPreset.selected[0];
            if (preset) {
                this.filterState.setPeriodType(preset);
                const currentDay = moment().format('MM/YYYY');
                const firstDayOfMonth = moment().format('MM/YYYY');
                switch (preset) {
                    case 'CURRENT_YEAR':
                        this.filterState.setStartDate(moment().format('01/YYYY'));
                        this.filterState.setEndDate(currentDay);
                        break;
                    case 'CURRENT_MONTH':
                        this.filterState.setStartDate(firstDayOfMonth);
                        this.filterState.setEndDate(currentDay);
                        break;
                    case 'PREVIOUS_MONTH':
                        this.filterState.setStartDate(moment().add('-1', 'M').format('MM/YYYY'));
                        this.filterState.setEndDate(moment(firstDayOfMonth, 'MM/YYYY').add('-1', 'days').format('MM/YYYY'));
                        break;
                }
                this.applyFilter();
            }
        }

        this._verifyDateLimit(
            this.filterState.getStartDate(),
            this.filterState.getEndDate(),
            lastStartDate,
            lastEndDate
        );
    }

    onChangeClientGroups() {
        this._resetKPIOptions();
        this.utilsService.setInvisible('lead-time-chart', this.editableElements);
        this.getDropdownKPIs(true);
    }

    private _resetKPIOptions(): void {
        this.kpiOptions = [];
        this.filterState.setSelectedKpi([]);
    }

    private _verifyDateLimit(startDate, endDate, lastStartDate, lastEndDate) {
        if (startDate !== lastStartDate) {
            this.endDateRestriction = moment(this.filterState.getStartDate(), 'MM/YYYY').add(12, 'M').format('MM/YYYY');
        } else if (endDate !== lastEndDate) {
            this.startDateRestriction = moment(this.filterState.getEndDate(), 'MM/YYYY').add(-12, 'M').format('MM/YYYY');
        }
    }

    private _createChart(dataChart: any): void {
        const chartType = this.filterState.getChartType();
        const isXYChart = chartType === 'XYChart';

        const data = dataChart?.map((item) => ({
            date: item.yearMonth,
            planned: parseFloat(item.expected),
            actual: parseFloat(item.accomplished),
        }));

        const chartConfig: IChartConfig = {
            chartId: 'leadTime',
            chartType,
            axis: [
                { axisPosition: 'x', categoryField: 'date', type: 'category' },
                { axisPosition: 'y', categoryField: 'value' },
            ],
            axisConfig: {
                y: { extraMax: isXYChart ? 0.2 : 0.15 },
            },
            axisTitle: {
                y: this._translateService.instant('dashboard::leadTime.leadTimeInDays'),
            },
            content: [
                {
                    name: this._translateService.instant('dashboard::leadTime.planned'),
                    valueYField: 'planned',
                    valueXField: 'date',
                },
                {
                    name: this._translateService.instant('dashboard::leadTime.actual'),
                    valueYField: 'actual',
                    valueXField: 'date',
                },
            ],
            showLegend: true,
            showTooltip: !isXYChart,
            padding: { top: 20, bottom: isXYChart ? 20 : 30, left: 10, right: 10 },
            showValuesAboveBars: true,
            labelConfig: { fontSize: 10, fontWeight: 'bold' }
        };

        this._AmCharts5Service.createChart(chartConfig, { data });
    }

    private formatLeadTimeData(months: any[]): any[] {
        return (months || []).map(month => ({
          ...month,
          yearMonth: moment(month.yearMonth, 'YYYYMM').format('MM/YY')
        }));
    }

    private _translateContent() {
        this.translatedPeriodOptions = this.periodOptions;

        if(this.chartData) {
            this._createChart(this.chartData);
        }

        setTimeout(() => {
            this.loadingTranslate = false;
        }, 1000);
    }

    private getKPIs(): Observable<any> {
        return this.http.get(`${environment.endpoints.slaKpiService}/kpis-by-client-code`, this.filterState.getKpiRequest());
    }

    private getLeadTime(): Observable<any> {
        return this.http.get(`${environment.endpoints.slaKpiService}/slas/leadtime`, this.filterState.getLeadTimeRequest());
    }

    private _unsubscribe() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
