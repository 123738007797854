import { AfterViewInit, Component, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import Inputmask from 'inputmask';
import * as _ from 'lodash';
import moment from 'moment';
import { forkJoin } from 'rxjs';

import { DetailCostsComponent } from './detail-costs/detail-costs.component';
import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { CostCategoryDetail } from './costCategoryDetail';
import { CostsFilter } from 'app/theme/pages/finance/costs/costsFilter.model';
import { Cost } from './costs';

import { environment } from '#environment';
import { CostsService } from './costs.service';
import { UserService } from "app/auth/_services/user.service";
import { CmxDropdownService } from 'app/shared/components/cmx-dropdown/cmx-dropdown.service';
import { FilterStateService } from '#services/_filters/filter-state.service';
import { DomainService } from '#services/_domain/domain.service';
import { EventSourceService } from '#services/_eventSource/eventSource.service';
import { UtilsService } from '#services/_utils/utils.service';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { CustomDialogService } from 'app/shared/components/cmx-custom-dialog/custom-dialog.service';
import { DownloadService } from '#services/_download/download.service';
import { DROPDOWN_SETTINGS } from 'app/shared/constants/dropdown-settings.constants';

@Component({
    selector: 'costs',
    templateUrl: './costs.component.html',
    styleUrls: ['./costs.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [UserService],
})
export class CostsComponent implements AfterViewInit, OnDestroy {
    @ViewChild(DetailCostsComponent, { static: true })
    detailCosts: DetailCostsComponent;
    helpDescription = this._defineHelpDescription();
    dropdownSettings = _.cloneDeep(DROPDOWN_SETTINGS);
    filterState: CostsFilter = this.filterStateService.getCostsFilter();
    options: {
        cr: DropdownOption[];
        models: DropdownOption[];
        colors: DropdownOption[];
        manufacturerYears: DropdownOption[];
        modelYears: DropdownOption[];
        client: DropdownOption[];
    } = {
        cr: [],
        models: [],
        colors: [],
        manufacturerYears: [],
        modelYears: [],
        client: [],
    };

    startDate;
    endDate;
    subscription;
    asyncSkuValues = false;
    asyncCosts = false;
    exportAsync = false;
    costs: Cost = Cost.blank();
    percentageCosts: Cost = Cost.blank();
    currentUser = this.userService.getCurrentUser();
    allCosts;
    selectedcategoryName;
    selectedsubCategoryName;
    skuPage = 0;
    skuPages = [];
    queryId = Math.random();
    costCategoryDetail: CostCategoryDetail = new CostCategoryDetail();
    placeholderSearch: string;
    showWarning =  false;
    datamartFilters = [];

    constructor(
        public filterStateService: FilterStateService,
        private domainService: DomainService,
        private userService: UserService,
        private urlValue: ActivatedRoute,
        private eventChannel: EventSourceService,
        private _utilsService: UtilsService,
        private costsService: CostsService,
        private userCacheService: UserCacheService,
        private _cmxDropdownService: CmxDropdownService,
        private _customDialogService: CustomDialogService,
        private _downloadService: DownloadService
    ) {}

    ngOnDestroy() {
        $(document).off('keyup');
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    ngAfterViewInit() {
        $(document).ready(() => {
            $(document).on('keyup', this.enterBtn.bind(this));
        });
        this.userService.currentUser$.subscribe((user) => {
            this.currentUser = user;
            this.placeholderSearch = user.has('INTERNAL_FILTERS') ? 'Buscar IMP' : 'Buscar REF';
            this.filterState.setAutomotiveMode(user.has('AUTOMOTIVE_USER') ? 'Ligado' : 'Desligado');
            this.datamartFilters = this._determineDatamartFilters(user);
            this.costsService.getAutomotiveDomainFilters().subscribe((autoFilters) => {
                this.options.models = this._cmxDropdownService.toDropdownItems(autoFilters.models);
                this.options.colors = this._cmxDropdownService.toDropdownItems(autoFilters.colors);
            });
            let currentYear = moment().year();
            let manufacturerYears = [];
            this.range(currentYear, 5, manufacturerYears)
            this.options.manufacturerYears = this._cmxDropdownService.toDropdownItems(manufacturerYears);
            let modelYears = [];
            this.range( (currentYear +1), 6, modelYears)
            this.options.modelYears = this._cmxDropdownService.toDropdownItems(modelYears);
            this.costsService.currentParams.showUc = this.filterState.getShowUc();
            this.costsService.currentParams.context = this.filterState.getContext();
            this.options.client = this._cmxDropdownService.toDropdownItems(this.userCacheService.getClientGroups());
            this.options.cr = this._cmxDropdownService.toDropdownItems(this.userCacheService.getProfitCenters());
            if (
                this.urlValue.snapshot.queryParams.startDate !== undefined ||
                this.urlValue.snapshot.queryParams.endDate !== undefined
            ) {
                this.filterState.setStartDate(
                    moment(this.urlValue.snapshot.queryParams.startDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
                );
                this.filterState.setEndDate(
                    moment(this.urlValue.snapshot.queryParams.endDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
                );
                if (
                    this.urlValue.snapshot.queryParams.clientGroupCode !== undefined ||
                    this.urlValue.snapshot.queryParams.clientGroupName !== undefined
                ) {
                    const clientGroupCode = this.urlValue.snapshot.queryParams.clientGroupCode;
                    const clientGroupName = this.urlValue.snapshot.queryParams.clientGroupName;
                    const clientGroup = { id: clientGroupCode, itemName: clientGroupName };
                    this.filterState.getClientGroups().push(clientGroup);
                }
                if (
                    this.filterState.getStartDate() !== moment().add(-12, 'M').format('DD/MM/YYYY') &&
                    this.filterState.getEndDate() !== moment().format('DD/MM/YYYY')
                ) {
                    $('.checkmark').removeClass('checked');
                }
                this.loadCosts();
            } else {
                this.loadCosts();
            }
        });
    }

    range(currentYear: number, size: number, dropdow) {
        var startYear = currentYear;
        var endYear = currentYear - size;
        for (var i = startYear; i > endYear; i--) {
            dropdow.push( { code: i, name: i });
        }
    }

    openAnaliticModal(category, spotlight?) {
        this.detailCosts.offset = 0;
        this.detailCosts.pages = [];

        if (this.placeholderSearch === 'Buscar REF') {
            this.costsService.currentParams.clientReference = this.costsService.currentParams.imp;
            this.costsService.currentParams.imp = '';
        }
        this.detailCosts.loadAnaliticCost(
            category,
            this.costsService.getParamsForDetails(),
            this.filterState.getAutomotiveMode(),
            spotlight
        );
        $('costs .analiticCostsModal').removeClass('hidden');
    }

    closeAnaliticModal() {
        $('costs .analiticCostsModal').addClass('hidden');
    }

    openDetailModal(categoryName, subCategoryName?) {
        this._customDialogService.open('cmx-custom-dialog-costs-sku')
        this.queryId = Math.random();
        this.skuPage = 0;
        this.loadSkuModalValues(categoryName, subCategoryName);
    }

    closeDetailModal() {
        if (!$('costs .categoryDetailModal').hasClass('hidden')) {
            $('costs .categoryDetailModal').addClass('hidden');
        }
    }

    toggleFilterMode() {
        if ($('#searchLine .filterMode').hasClass('clicked')) {
            this.filterState.setAutomotiveMode('Desligado');
            this.filterState.setChassiSearch('');
            this.costsService.currentParams.chassi = '';
            this.filterState.setTypeSearch(this.currentUser.has('INTERNAL_FILTERS') ? 'IMP' : 'REF');
            $('#searchLine .filterMode').removeClass('clicked');
        } else {
            this.filterState.setAutomotiveMode('Ligado');
            this.filterState.setImpSearch('');
            this.costsService.currentParams.imp = '';
            this.costsService.currentParams.clientReference = '';
            this.filterState.setTypeSearch('Chassi');
            $('#searchLine .filterMode').addClass('clicked');
        }
        this.datamartFilters = this._determineDatamartFilters(this.currentUser);
    }

    swapVision() {
        if (this.filterState.getVision() === 'expandida') {
            $('.costsContainer').addClass('linear');
            this.filterState.setVision('reduzida');
        } else {
            $('.costsContainer').removeClass('linear');
            this.filterState.setVision('expandida');
        }
    }

    openDatamartModal() {
        this._customDialogService.open('datamart-modal');
    }

    datesChanged(dates) {
        this.filterState.setStartDate(dates.startDate);
        this.filterState.setEndDate(dates.endDate);
    }

    exportCosts(type?) {
        this.callExportMessage();
        let params = '';
        params += `?startDate=${
            this.filterState.getSearch() === ''
                ? moment(this.filterState.getStartDate(), 'DD/MM/YYYY').format('YYYY-MM-DD')
                : moment().subtract(30, 'days').format('YYYY-MM-DD')
        }`;
        params += `&endDate=${
            this.filterState.getSearch() === ''
                ? moment(this.filterState.getEndDate(), 'DD/MM/YYYY').format('YYYY-MM-DD')
                : moment().format('YYYY-MM-DD')
        }`;
        params += `&profitCenters=${
            this.filterState.getSearch() === '' && this.filterState.getAutomotiveMode() === 'Desligado'
                ? this.filterState
                      .getProfitCenters()
                      .map((c) => c['id'])
                      .join(',')
                : ''
        }`;
        params += `&models=${
            this.filterState.getSearch() === '' && this.filterState.getAutomotiveMode() === 'Ligado'
                ? this.filterState
                      .getModels()
                      .map((c) => c['id'])
                      .join(',')
                : ''
        }`;
        params += `&colors=${
            this.filterState.getSearch() === '' && this.filterState.getAutomotiveMode() === 'Ligado'
                ? this.filterState
                      .getColors()
                      .map((c) => c['id'])
                      .join(',')
                : ''
        }`;
        params += `&manufacturerYears=${
            this.filterState.getSearch() === '' && this.filterState.getAutomotiveMode() === 'Ligado'
                ? this.filterState
                      .getManufacturerYears()
                      .map((c) => c['id'])
                      .join(',')
                : ''
        }`;
        params += `&modelYears=${
            this.filterState.getSearch() === '' && this.filterState.getAutomotiveMode() === 'Ligado'
                ? this.filterState
                      .getModelYears()
                      .map((c) => c['id'])
                      .join(',')
                : ''
        }`;
        params += `&imp=${this.filterState.getImpSearch()}`;
        params += `&chassisNumbers=${this.filterState.getChassiSearch()}`;
        params += `${type === 'modal' ? `&expensesCategory=${this.selectedcategoryName}` : ''}`;
        params += `${type === 'modal' ? `&expensesCategoryValue=${this.selectedsubCategoryName || ''}` : ''}`;
        params += `&showUc=${ this.costsService.currentParams.showUc }`
        params += `&context=${ this.filterState.getAutomotiveMode() === 'Ligado' ? 'automotive'
            :  this.filterState.getContext().toLowerCase() }`;
        const headers = `${!this.filterState.getSearch() ?
            this.filterState.getClientGroups().map((c) => c['id']).join(',') : ''}`;
        let url = `${environment.endpoints.expensesService}/expenses`;
        if (type === 'modal') {
            url += '/expenses-sku-report/async';
        } else if (type === 'detail') {
            url += this.filterState.getContext() === 'item' ?
            '/expenses-item-report/async' :
            '/expenses-detail-report/async';
        } else {
            url += '/analytical-report/async';
        }
        if (type === 'detail') {
            this._exportDetails(url, headers, params);
        } else {
            this.eventChannel.openEventSource(url, headers, params);
        }
    }

    loadCosts(resetPreset?) {
        this._defineAnaliticFilters()
        if (resetPreset) {
            $('.checkmark').removeClass('checked');
            this.filterState.setPeriodType(null);
        }
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.asyncCosts = false;
        this._utilsService.setInvisible('costs', ['.inputContainer', '.search-container']);
        this.subscription = this.costsService
            .getCosts(this.filterState.getRequest(this.filterState.getAutomotiveMode()))
            .subscribe(
                (costs) => {
                    this.allCosts = costs.expensesCategories;
                    this.costs = costs.sum;
                    this.showWarning = costs.partialBilling;
                    this.percentageCosts = costs.comparison;
                    this.filterState.setStartDate(moment(costs.startDate, 'YYYY-MM-DD').format('DD/MM/YYYY'));
                    this.filterState.setEndDate(moment(costs.endDate, 'YYYY-MM-DD').format('DD/MM/YYYY'));
                    this.asyncCosts = true;
                    this.updateDateInputs();
                    this._utilsService.setVisible('costs', ['.inputContainer', '.search-container']);
                    this.getVisualParams();
                    setTimeout(() => {
                        this.verifyPreDefinitionDate(this.filterState.getStartDate(), this.filterState.getEndDate());
                    }, 500);
                },
                (error) => {
                    this.asyncCosts = true;
                    this._utilsService.setVisible('costs', ['.inputContainer', '.search-container']);
                    this._utilsService.errorHandler(error);
                }
            );
    }

    formatCategoryName(name) {
        if (name.includes('Composicao da NF')) {
            return 'composicaodanf';
        } else if (name.includes('Valor de Mercadoria') && this.filterState.getAutomotiveMode() === 'Ligado') {
            return 'valordemercadoriaAutomotivo';
        } else if (name.includes('Valor de Mercadoria') && this.filterState.getAutomotiveMode() === 'Desligado') {
            return 'valordemercadoriaImport';
        } else {
            return name.replace(/\s/g, '').toLowerCase();
        }
    }

    changePreset(selectedPreset, element) {
        if (!$(element.target).hasClass('checked') && this.filterState.getSearch() === '') {
            this.filterState.setPeriodType(selectedPreset);
            this.loadCosts();
        }
    }

    changeSkuModalPage(pageNumber) {
        $('costs .categoryDetailModal pagination').addClass('invisible');
        this.skuPage = pageNumber - 1;
        this.loadSkuModalValues(this.selectedcategoryName, this.selectedsubCategoryName);
    }

    verifyDates(datePicker, date) {
        if (date.replace(/\D/g, '').length === 8) {
            if (datePicker === 'startDate') {
                if (moment(date, 'DD/MM/YYYY').isAfter(moment(this.filterState.getEndDate(), 'DD/MM/YYYY'))) {
                    this.filterState.setStartDate(date);
                    this.filterState.setEndDate(date);
                    setTimeout(() => {
                        this.updateDateInputs();
                    }, 300);
                } else {
                    this.filterState.setStartDate(date);
                    this.updateDateInputs();
                }
            } else {
                if (moment(this.filterState.getStartDate(), 'DD/MM/YYYY').isAfter(moment(date, 'DD/MM/YYYY'))) {
                    this.filterState.setStartDate(date);
                    this.filterState.setEndDate(date);
                    setTimeout(() => {
                        this.updateDateInputs();
                    }, 300);
                } else {
                    this.filterState.setEndDate(date);
                    this.updateDateInputs();
                }
            }
        }
    }

    enterBtn(element) {
        if (element.keyCode === 13) {
            this.loadCosts(true);
        }
    }

    trackByFn(index: number, item: any) {
        return index;
    }

    private _exportDetails(url, headers, params) {
        this.costsService.export(url, headers, params).subscribe(() => {
            this._downloadService.onAsyncReport();
        }, (error) => {
            this._utilsService.errorHandler(error, 'Custos Detalhados');
        });
    }

    private loadSkuModalValues(categoryName, subCategoryName) {
        this.asyncSkuValues = false;
        this.selectedcategoryName = categoryName;
        this.selectedsubCategoryName = subCategoryName;
        forkJoin([
            this.costsService.getContainerCosts(this.getSkuRequest('container', categoryName, subCategoryName)),
            this.costsService.getSKUCosts(this.getSkuRequest('sku', categoryName, subCategoryName))
        ]).subscribe((responses) => {
            const [containerResponse, skuResponse] = responses;
            this.skuPages = Array.apply(null, { length: Math.ceil(skuResponse.total / 30) }).map(Number.call, Number);
            this.costCategoryDetail.containerAverageValue = containerResponse.containerAverageValue;
            this.costCategoryDetail.containerQuantity = containerResponse.containerQuantity;
            this.costCategoryDetail.name = containerResponse.name;
            this.costCategoryDetail.skuValueList = skuResponse.skuList;
            this.asyncSkuValues = true;
            $('costs .categoryDetailModal pagination').removeClass('invisible');
        });
    }

    private verifyPreDefinitionDate(start, end) {
        if (
            start === moment().format('01/MM/YYYY') &&
            end === moment('01/MM/YYYY', 'DD/MM/YYYY').add(1, 'M').add(-1, 'days').format('DD/MM/YYYY')
        ) {
            $('.checkmark').removeClass('checked');
            $('.checkmark#current-month').addClass('checked');
        }
        if (
            start === moment().add(-1, 'M').format('01/MM/YYYY') &&
            end === moment('01/MM/YYYY', 'DD/MM/YYYY').add(-1, 'days').format('DD/MM/YYYY')
        ) {
            $('.checkmark').removeClass('checked');
            $('.checkmark#previous-month').addClass('checked');
        }
        if (start === moment().format('01/01/YYYY') && end === moment().format('31/12/YYYY')) {
            $('.checkmark').removeClass('checked');
            $('.checkmark#current-year').addClass('checked');
        }
        if (start === moment().add(-12, 'M').format('DD/MM/YYYY') && end === moment().format('DD/MM/YYYY')) {
            $('.checkmark').removeClass('checked');
            $('.checkmark#last12-months').addClass('checked');
        }
    }

    private callExportMessage() {
        this.exportAsync = true;
        setTimeout(() => {
            this.exportAsync = false;
        }, 5000);
    }

    private getSkuRequest(type, categoryName, subCategoryName) {
        let params = new HttpParams();
        let headers = new HttpHeaders();
        if (this.filterState.getPeriodType() !== undefined && this.filterState.getPeriodType() !== null) {
            params = params.append('periodType', this.filterState.getPeriodType());
        } else {
            if (this.filterState.getStartDate() !== undefined && this.filterState.getStartDate() !== null) {
                params = params.append(
                    'startDate',
                    moment(this.filterState.getStartDate(), 'DD/MM/YYYY').format('YYYY-MM-DD')
                );
            }
            if (this.filterState.getEndDate() !== undefined && this.filterState.getEndDate() !== null) {
                params = params.append(
                    'endDate',
                    moment(this.filterState.getEndDate(), 'DD/MM/YYYY').format('YYYY-MM-DD')
                );
            }
        }
        params = params.append('imp', this.filterState.getImpSearch());
        params = params.append('expensesCategory', categoryName);
        if (subCategoryName) {
            params = params.append('expensesCategoryValue', subCategoryName);
        }
        if (type === 'sku') {
            params = params.append('page', `${this.skuPage}`);
            params = params.append('size', '30');
        }
        headers = headers.set(
            'X-Requested-Client-Groups',
            this.filterState
                .getClientGroups()
                .map((c) => c['id'])
                .join(',')
        );
        return { params, headers };
    }

    private getVisualParams() {
        if (this.filterState.getStartDate() !== undefined) {
            this.costsService.currentParams.startDate = _.cloneDeep(this.filterState.getStartDate());
        }
        if (this.filterState.getEndDate() !== undefined) {
            this.costsService.currentParams.endDate = _.cloneDeep(this.filterState.getEndDate());
        }
        this.costsService.currentParams.imp = _.cloneDeep(this.filterState.getImpSearch());
        this.costsService.currentParams.chassi = _.cloneDeep(this.filterState.getChassiSearch());
        this.costsService.currentParams.clients = _.cloneDeep(this.filterState.getClientGroups());
        this.costsService.currentParams.crs = _.cloneDeep(this.filterState.getProfitCenters());
        this.costsService.currentParams.models = _.cloneDeep(this.filterState.getModels());
        this.costsService.currentParams.colors = _.cloneDeep(this.filterState.getColors());
        this.costsService.currentParams.manufacturerYears = _.cloneDeep(this.filterState.getManufacturerYears());
        this.costsService.currentParams.modelYears = _.cloneDeep(this.filterState.getModelYears());
    }

    private updateDateInputs() {
        if (!moment(this.filterState.getStartDate(), 'DD/MM/YYYY').isValid()) {
            this.filterState.setStartDate(moment().subtract(30, 'days').format('DD/MM/YYYY'));
        }
        if (!moment(this.filterState.getEndDate(), 'DD/MM/YYYY').isValid()) {
            this.filterState.setEndDate(moment().format('DD/MM/YYYY'));
        }
        ($('costs input#startDatePicker') as any).val(this.filterState.getStartDate()).Zebra_DatePicker({
            onSelect: (selectedDate) => {
                this.verifyDates('startDate', selectedDate);
            },
            onClear: () => {
                this.filterState.setStartDate(undefined);
                this.filterState.setEndDate(undefined);
                this.updateDateInputs();
            },
        });
        setTimeout(() => {
            ($('costs input#endDatePicker') as any).val(this.filterState.getEndDate()).Zebra_DatePicker({
                direction: [this.filterState.getStartDate(), false],
                onSelect: (selectedDate) => {
                    this.filterState.setEndDate(selectedDate);
                },
            });
        }, 300);
        Inputmask({ mask: '99/99/9999' }).mask($('costs input#startDatePicker'));
        Inputmask({ mask: '99/99/9999' }).mask($('costs input#endDatePicker'));
    }

    private _defineAnaliticFilters() {
        if ((this.filterState.getClientGroups().length > 1 || (this.filterState.getClientGroups().length === 0 && this.currentUser.has('INTERNAL_FILTERS') && !this.filterState.getSearch()))
        && this.filterState.getAutomotiveMode() === 'Desligado') {
            if (this.filterState.getContext() === 'sku') {
                this.costsService.currentParams.context = 'imp';
                this.filterState.setContext('imp');
            }
            this.filterState.setDropdownOptionsBlocked(true);
        } else {
            this.filterState.setDropdownOptionsBlocked(false);
        }
    }

    private _determineDatamartFilters(user) {
        return this.filterState.getAutomotiveMode() === 'Desligado' ? [
            { title: `${user.has('INTERNAL_FILTERS') ? 'CR' : ''}`, field: 'profitCenters' },
            { title: `${user.has('INTERNAL_FILTERS') ? 'Clientes' : ''}`, field: 'clientGroups' },
            { title: 'UC', field: 'showUc' },
            { title: 'Por', field: 'context' },
            { title: 'Período', field: 'period' }
        ] : [
            { title: `${user.has('INTERNAL_FILTERS') ? 'Clientes' : ''}`, field: 'clientGroups' },
            { title: 'Modelos', field: 'models' },
            { title: 'Cores', field: 'colors' },
            { title: 'Ano Modelo', field: 'modelYears' },
            { title: 'Ano Fabricação', field: 'manufacturerYears' },
            { title: 'UC', field: 'showUc' },
            { title: '', field: 'context' },
            { title: 'Período', field: 'period' }
        ];
    }

    private _defineHelpDescription(): object[] {
        return [
            {
                type: 'text',
                value: 'Ao acessar a tela, por padrão, é apresentado o filtro com período de 1 mês, podendo ser alterado conforme necessidade do usuário. ',
            },
            {
                type: 'text',
                value: 'Os valores são divididos por categorias e, dentro deles, separados por nome de cada custos.',
            },
            {
                type: 'text',
                value: 'É possível alterar a forma de visualização:',
            },
            {
                type: 'list',
                list: 'Em formato expandido; ou',
            },
            {
                type: 'list', hasLineAfter: true,
                list: ' Em formato reduzido (em forma de lista).',
            },
            {
                type: 'text',
                textBold: 'Detalhamento Geral:',
            },
            {
                type: 'text',
                value: 'Para visualizar a composição detalhada dos custos, basta clicar na aba Detalhamento Geral, na opção Detalhamento ao lado de cada grupo de custos, ou no próprio título do custo.',
            },
            {
                type: 'text',
                value: 'Ao escolher Detalhamento Geral, serão exibidos todos os Custos Detalhados do processo.',
            },
            {
                type: 'text',
                value: 'Ao escolher Detalhamento, ou título do custo, referente a um grupo de custos, serão exibidos os Custos Detalhados daquele grupo. ',
            },
            {
                type: 'text',
                value: 'Será aberta uma janela, onde é possível selecionar se a visualização detalhada será por IMP ou por SKU.',
            },
            {
                type: 'text',
                value: 'Quando a visualização selecionada for por SKU, a relação de itens será listada, informando o custo de cada item.',
            },
            {
                type: 'text',
                value: 'Ao selecionar o Filtro com U.C o sistema irá adicionar a coluna de valores por Unidade comercializada por despesa.',
            },
            {
                type: 'text',
                value: 'Ao clicar no ícone em forma de cubo ao lado de cada custo, será aberto um modal apresentando:',
            },
            {
                type: 'list',
                list: 'Quantidade total de containers;',
            },
            {
                type: 'list',
                list: 'Valor médio por container; ',
            },
            {
                type: 'list',
                list: 'Separação de valores por SKU; ',
            },
            {
                type: 'list',
                list: 'Separação de valores por unidade comercializada U.C',
            },
        ]
    }

}
