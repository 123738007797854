import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '#environment';

import { IForecastByStatusResponse } from 'app/shared/interfaces/forecast-by-status.interface';
import { IStatusDomain } from 'app/shared/interfaces/status-domain.interface';

@Injectable()
export class ForecastByStatusService {
    private _baseUrl: string;

    constructor(private _http: HttpClient) {
        this._baseUrl = `${environment.endpoints.importationMongoDBService}/v1/forecast`;
    }

    getForecastByStatus(status: string, clientCodes: string): Observable<IForecastByStatusResponse> {
        let url = this._baseUrl;

        if (status) {
            url += `?status=${status}`;
        }

        return this._http.get<IForecastByStatusResponse>(url, {
            headers: new HttpHeaders().set('X-Requested-Client-Groups', clientCodes),
        });
    }

    getForecastStatusTypes(): Observable<IStatusDomain[]> {
        return this._http.get<IStatusDomain[]>(`${this._baseUrl}-status`);
    }
}
