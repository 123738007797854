import moment from "moment";
import { _applyPosFormatters } from "./pos-formatters/pos-formatters";
import { multipleFormatter } from "./multiple.formatter";
import { textStatusFormatter } from "./text-status.formatter";
import { textFormatter } from "./text.formatter";

export function dateFormatter(row, cell, value, columnDef, dataContext) {
    if (Array.isArray(value)) {
        if (value.length) {
            return multipleFormatter(row, cell, value, columnDef, dataContext);
        }
    }
    
    let inputFormat = ['YYYY-MM-DD', 'DD/MM/YYYY'];
    let outputFormat = 'DD/MM/YYYY';
    
    const currentValue = columnDef.formatterData && columnDef.formatterData.singleField ? value[columnDef.formatterData.singleField] : value;
    if (!currentValue || !moment(currentValue, inputFormat).isValid()) {
        const nonValueMessage = currentValue ? currentValue : (columnDef.formatterData && columnDef.formatterData.nonValueMessage) || null;
        if (nonValueMessage) {
            return textStatusFormatter(row, cell, nonValueMessage, columnDef, dataContext);
        }
        return textFormatter(row, cell, nonValueMessage, columnDef, dataContext);
    }

    if (columnDef.formatterData && columnDef.formatterData.hourFormat) {
        inputFormat = ['YYYY-MM-DD HH:mm:ss'];
        outputFormat = 'DD/MM/YYYY - HH:mm:ss';
    } else if (columnDef.formatterData && columnDef.formatterData.monthFormat) {
        inputFormat = ['YYYY-MM'];
        outputFormat = 'MM/YYYY';
    }

    if (columnDef.formatterData && columnDef.formatterData.singleField) {
        return `<span>${moment(value[columnDef.formatterData.singleField], inputFormat).format(
            outputFormat
        )}</span>`;
    }
    const contentValue = `<span>${moment(value, inputFormat).format(outputFormat)}</span>`
    return _applyPosFormatters(row, cell, contentValue, columnDef, dataContext);
}