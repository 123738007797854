import { Component, ElementRef, HostListener, OnDestroy, Renderer2, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { forkJoin } from 'rxjs';

import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { BoardingStatusFilter } from './boarding-status.filter';
import { IDropdownOptions } from './interfaces/dropdown-options.interface';
import { IGridColumn, IGridRow } from 'app/shared/components/cmx-custom-grid/interfaces';
import { DROPDOWN_SETTINGS } from './constants/dropdown-settings.constant';
import { DROPDOWN_TYPES } from './constants/dropdown-types.constant';
import { BOARDING_STATUS_COLUMNS, BOARDING_STATUS_PAGINATION_STYLES, BOARDING_STATUS_NON_PAGINATION_STYLES } from './constants/boarding-status-columns.constants';
import { BOARDING_STATUS_VEHICLES_COLUMNS } from './constants/boarding-status-vehicles-columns.constants';

import { UserService } from "app/auth/_services/user.service";
import { UserPermissionBlockerService } from '#services/_user/userPermissionBlocker.service';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UtilsService } from '#services/_utils/utils.service';
import { FilterStateService } from '#services/_filters/filter-state.service';
import { CustomDialogService } from 'app/shared/components/cmx-custom-dialog/custom-dialog.service';
import { AutomotiveCrudService } from 'app/modules/crud/pages/automotive/automotive.service';


@Component({
    selector: 'boarding-status-chart',
    templateUrl: './boarding-status.component.html',
    styleUrls: ['../charts.scss', './boarding-status.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [AutomotiveCrudService]
})

export class BoardingStatusComponent implements OnDestroy {
    currentUser$ = this._userService.currentUser$;
    filterState: BoardingStatusFilter = this._filterStateService.getBoardingStatusFilter();

    dropdownTypes: string[];
    dropdownOptions: IDropdownOptions;
    readonly dropdownSettings = {
        client: {
            text: 'Cliente',
            ...DROPDOWN_SETTINGS,
            badgeShowLabel: 'Clientes selecionados',
        },
        ship: {
            text: 'Navio',
            ...DROPDOWN_SETTINGS,
            badgeShowLabel: 'Navios selecionados',
        },
        portOrigin: {
            text: 'Porto de Embarque',
            ...DROPDOWN_SETTINGS,
            badgeShowLabel: 'Portos de embarques selecionados',
        },
        portDestination: {
            text: 'Porto de Destino',
            ...DROPDOWN_SETTINGS,
            badgeShowLabel: 'Portos de destinos selecionados',
        },
    };

    helpDescription = this._defineHelpDescription();

    subscription;
    asyncBoardingTable = true;
    asyncExport = false;
    showModal = false;

    summaryLine = [{title: 'Total de Veículos', value: 0}];
    boarding;
    vehicles = [];

    gridColumns: IGridColumn[] = _.cloneDeep(BOARDING_STATUS_COLUMNS);
    gridVehiclesColumns: IGridColumn[] = _.cloneDeep(BOARDING_STATUS_VEHICLES_COLUMNS);
    gridRows: IGridRow[] = [];
    gridVehiclesRows: IGridRow[] = [];
    gridStyles = BOARDING_STATUS_PAGINATION_STYLES;
    pages = [];
    queryId = Math.random();

    boardingStatus = ['PRE_SHIPPING', 'IN_TRANSIT', 'FINISHED'];
    filterStatus = [
        {
            code: 'PRE_SHIPPING',
            name: 'Oper. Embarque',
            img: {
                path: 'assets/img/icons/PRE_SHIPPING',
                ext: '.png'
            }
        },
        {
            code: 'IN_TRANSIT',
            name: 'Trânsito',
            img: {
                path: 'assets/img/icons/IN_TRANSIT',
                ext: '.png'
            }
        },
        {
            code: 'FINISHED',
            name: 'Oper. Finalizada',
            img: {
                path: 'assets/img/icons/FINISHED',
                ext: '.png'
            }
        },
    ];

    @HostListener('click') onSetZIndex() {
        this.renderer2.setStyle(this._elementRef.nativeElement, 'z-index', this.utilsService.autoIncrementValue);
    }

    constructor(
        private el: ElementRef,
        private permissionBlocker: UserPermissionBlockerService,
        private _automotiveService: AutomotiveCrudService,
        private _userService: UserService,
        private _userCacheService: UserCacheService,
        private utilsService: UtilsService,
        private _elementRef: ElementRef,
        private renderer2: Renderer2,
        private _filterStateService: FilterStateService,
        private _customDialogService: CustomDialogService,
        private _translateService: TranslateService,
    ) {
        this.dropdownTypes = DROPDOWN_TYPES;
        this.dropdownOptions = this._getInitilizedDropdownOptions();
        this._loadDropdownOptions();
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    loadBoardingStatusTable() {
        if (this.permissionBlocker.permissionValidator(this.el.nativeElement.tagName)) {

            this.asyncBoardingTable = true;
            if (this.subscription) {
                this.subscription.unsubscribe();
            }

            const requestData = this.filterState.getRequest();

            this.subscription = this._automotiveService.getBoardingSummary(requestData).subscribe(
                (response) => {
                    this.gridRows = response.shipments.content.map((row) => {
                        return { ...row,
                            transhipment: this._formatBoolean(row.transhipment),
                            status: this._formatStatus(row.shipmentStatus),
                        };
                    });

                    this.summaryLine[0].value = response.totalVehicles;
                    this.pages = Array.apply(null, { length: Math.ceil(response.shipments.totalPages / 50) }).map(Number.call, Number);
                    this.gridStyles = this.pages.length > 1 ? BOARDING_STATUS_PAGINATION_STYLES : BOARDING_STATUS_NON_PAGINATION_STYLES;
                    this.asyncBoardingTable = false;
                },
                (error) => {
                    this.gridRows = [];
                    this.pages = [];
                    this.summaryLine[0].value = 0;
                    this.asyncBoardingTable = false;
                    this.utilsService.errorHandler(error, 'Status do Embarque');
                }
            );
        }
    }

    applyFilter() {
        this._resetPages();
        this.loadBoardingStatusTable();
    }

    trackByFn(index: number): number {
        return index;
    }

    export(type: string): void {
        this.asyncExport = true;
        const requestData = this.filterState.getRequest().params;

        this._automotiveService.export(type, requestData).subscribe(() => {
            this.asyncExport = false;
        });
    }

    closeVehicleModal() {
        $('boarding-status-chart .vehiclesModal').addClass('hidden');
    }

    datesChanged(event) {
        this.filterState.setStartDate(event.startDate);
        this.filterState.setEndDate(event.endDate);
    }

    getDropdownSelected(type: string) {
        switch (type) {
            case 'client':
                return this.filterState.getClient() || [];
            case 'ship':
                return this.filterState.getShip() || [];
            case 'portOrigin':
                return this.filterState.getPortOrigin() || [];
            case 'portDestination':
                return this.filterState.getPortDestination() || [];
        }
        return []
    }

    setDropdownSelected(type: string, value) {
        switch (type) {
            case 'client':
                return this.filterState.setClient(value);
            case 'ship':
                return this.filterState.setShip(value);
            case 'portOrigin':
                return this.filterState.setPortOrigin(value);
            case 'portDestination':
                return this.filterState.setPortDestination(value);
        }
    }

    onCellClick({ item, column }): void {
        if(column === 'vehicles' && item.vehicles.totalVehicles > 0) {
            this.boarding = item.shipmentCode;
            item.vehicles.vehicles.map((vehicle) => {
                vehicle.totalVehicles > 0 ? this.gridVehiclesRows.push(vehicle) : null;
            });

            this._customDialogService.open('boarding-status-vehicle-dialog');
            setTimeout(() => { this.showModal = true; }, 100);
        }
    }

    onCloseVehiclesModal() {
        this.boarding = null
        this.gridVehiclesRows = [];
        this.showModal = false;
    }

    onSortColumn(column) {
        this.filterState.setSort(`${column.field},${column.sort}`);
        this.applyFilter();
    }

    onSelectedStatus({ selected }): void {
        let statusCode = [];
        selected.map((item) => {
            statusCode.push(item.code)
        });

        this.filterState.setBoardingStatus(statusCode);
        this.gridColumns = this._verifySortByStatus(statusCode);
        this.applyFilter();
    }

    changePage(pageNumber) {
        this.filterState.setOffset(pageNumber - 1);
        this.loadBoardingStatusTable();
    }

    private _loadDropdownOptions(): void {

        this.dropdownOptions.client.options = this._toDropdownItems(this._userCacheService.getClientGroups(), false);

        const shipmentDomainTypes = ['SHIP', 'PORT_ORIGIN', 'PORT_DESTINATION'];
        forkJoin(shipmentDomainTypes.map((type) => this._automotiveService.getShipmentByDomainType(type))).subscribe(
            ([ships, portOrigins, portDestinations]) => {
                this.dropdownOptions.ship.options = this._toDropdownItems(ships, false);
                this.dropdownOptions.portOrigin.options = this._toDropdownItems(portOrigins, false);
                this.dropdownOptions.portDestination.options = this._toDropdownItems(portDestinations, false);
            }
        );
    }

    private _getInitilizedDropdownOptions(): IDropdownOptions {
        return this.dropdownTypes.reduce((accumulator: IDropdownOptions, currentValue: string) => {
            return {
                ...accumulator,
                [currentValue]: {
                    options: [],
                    selected: [],
                },
            } as IDropdownOptions;
        }, {});
    }

    private _toDropdownItems(items: any[], includeCode = true) {
        return items != null ? items.map(includeCode ? this._toDropdownItemWithCode : this._toDropdownItem) : [];
    }

    private _toDropdownItemWithCode(e: any): DropdownOption {
        return new DropdownOption(e.code, `${e.code} - ${e.name}`);
    }

    private _toDropdownItem(e: any): DropdownOption {
        return new DropdownOption(e.code, `${e.name}`);
    }

    private _formatStatus(status) {
        const statusObj = {
            PRE_SHIPPING: {code: "PRE_SHIPPING", name: "Oper. Embarque"},
            IN_TRANSIT: {code: "IN_TRANSIT", name: "Em Trânsito"},
            FINISHED: {code: "FINISHED", name: "Oper. Finalizada"},
        };

        return statusObj[status] || null;
    }

    private _formatBoolean( value) {
        if (value === '' || value == null) {
            return '';
        } else {
            return value ? 'Sim' : 'Não';
        }
    }

    private _defineHelpDescription(): object[] {
        return [{ type: 'text', value: this._translateService.instant('dashboard::boardingStatus.description') }];
    }

    private _verifySortByStatus(status) {
        let hasSort = 'eta';

        if (status.length === 1 && status[0] === 'PRE_SHIPPING') {
            hasSort = 'etd';
        }

        this.filterState.setSort(`${hasSort},asc`);
        const columns = _.cloneDeep(BOARDING_STATUS_COLUMNS);
        return columns.map((column: IGridColumn) => {
            return {
                ...column,
                preSortable: column.field === hasSort ? 'asc' : null,
            };
        });
    }

    private _resetPages() {
        this.queryId = Math.random();
        this.filterState.setOffset(0);
    }
}
