import { HttpHeaders, HttpParams } from '@angular/common/http';
import { AppModule } from 'app/app.module';
import { Subject } from 'rxjs';
import moment from 'moment';
import * as _ from 'lodash';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { ICostsFilter } from './interface/costs.interface';
import { UserService } from "app/auth/_services/user.service";

export class CostsFilter {

    private changeClientGroups: Subject<any> = new Subject;
    private changeAutomotiveMode: Subject<any> = new Subject;
    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);
    private _userService = AppModule.injector.get(UserService);

    private _initiaCostsFilter: ICostsFilter = <ICostsFilter>{ };
    private _costsFilter: ICostsFilter = <ICostsFilter> {
        clientGroups: [],
        profitCenters: [],
        vision: 'expandida',
        automotiveMode: 'Desligado',
        clientReference: '',
        models: [],
        colors: [],
        manufacturerYears: [],
        modelYears: [],
        context: 'imp',
        showUc: true,
        showDuplicatas: true,
        search: '',
        firstAcess: true,
        // Padrão para Clientes (120 minutos ou 2 horas) - Range de Dados de 24 Meses
        periodicityType: 'MINUTES',
        periodicity: '120',
        interval: '24',
    };

    private skuSwitchBlocked: boolean = false;
    private _typeSearch = this._setInitialTypeSearch();
    private periodType: string;
    private _chassisNumbers = '';
    private startDate;
    private endDate;

    constructor(userService: UserService, filter?: CostsFilter) {
        this._costsFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'costsFilter', this._costsFilter, filter);
        this._initiaCostsFilter = _.cloneDeep(this._costsFilter);
        this.periodType = filter ? filter.periodType : null;
        this.startDate = filter ? filter.startDate : moment().add(-30, 'days').format('DD/MM/YYYY');
        this.endDate = filter ? filter.endDate : moment().format('DD/MM/YYYY');
    }

    getImpSearch(): string { return this._costsFilter.search; }
    getChassiSearch(): string { return this._chassisNumbers; }
    getPeriodType(): string { return this.periodType; }
    getStartDate() { return this.startDate; }
    getEndDate() { return this.endDate; }
    getClientGroups(): object[] { return this._costsFilter.clientGroups; }
    getProfitCenters(): string[] { return this._costsFilter.profitCenters; }
    getModels(): string[] { return this._costsFilter.models; }
    getColors(): string[] { return this._costsFilter.colors; }
    getManufacturerYears(): string[] { return this._costsFilter.manufacturerYears; }
    getModelYears(): string[] { return this._costsFilter.modelYears; }
    getVision(): string { return this._costsFilter.vision; }
    getClientReference(): string { return this._costsFilter.clientReference; }
    getTypeSearch(): string { return this._typeSearch; }
    getShowUc(): boolean { return this._costsFilter.showUc; }
    getShowDuplicatas(): boolean { return this._costsFilter.showDuplicatas; }
    getContext(): string { return this._costsFilter.context; }
    getAutomotiveMode(): string { return this._costsFilter.automotiveMode; }
    getDropdownOptionsBlocked(): boolean { return this.skuSwitchBlocked; }
    getFirstAcess(): boolean { return this._costsFilter.firstAcess; }
    getPeriodicity(): string { return this._costsFilter.periodicity; }
    getInterval(): string { return this._costsFilter.interval; }
    getPeriodicityType(): string { return this._costsFilter.periodicityType; }

    setImpSearch(search): void { this._costsFilter.search = search || ''; }
    setChassiSearch(chassisNumbers): void { this._chassisNumbers = chassisNumbers; }
    setPeriodType(periodType): void { this.periodType = periodType; }
    setStartDate(startDate): void { this.startDate = startDate; }
    setEndDate(endDate): void { this.endDate = endDate; }
    setClientGroups(clientGroups): void {
        this._costsFilter.clientGroups = clientGroups;
        this.changeClientGroups.next();
    }
    setProfitCenters(profitCenters): void { this._costsFilter.profitCenters = profitCenters; }
    setModels(models): void { this._costsFilter.models = models; }
    setColors(colors): void { this._costsFilter.colors = colors; }
    setManufacturerYears(manufacturerYears): void { this._costsFilter.manufacturerYears = manufacturerYears; }
    setModelYears(modelYears): void { this._costsFilter.modelYears = modelYears; }
    setVision(vision): void { this._costsFilter.vision = vision; }
    setClientReference(clientReference): void { this._costsFilter.clientReference = clientReference; }
    setTypeSearch(typeSearch): void { this._typeSearch = typeSearch; }
    setShowUc(showUc): void { this._costsFilter.showUc = showUc; }
    setShowDuplicatas(showDuplicatas): void { this._costsFilter.showDuplicatas = showDuplicatas; }
    setContext(context): void { this._costsFilter.context = context; }
    setAutomotiveMode(mode): void {
        this._costsFilter.automotiveMode = mode;
        this.changeAutomotiveMode.next();
    }
    setDropdownOptionsBlocked(blocked): void { this.skuSwitchBlocked = blocked; }
    setFirstAcess(): void { this._costsFilter.firstAcess = false; }
    setPeriodicity(periodicity): void { this._costsFilter.periodicity = periodicity; }
    setInterval(interval): void { this._costsFilter.interval = interval; }
    setPeriodicityType(periodicityType): void { this._costsFilter.periodicityType = periodicityType; }

    getRequest(filterAutomotiveMode, detailed?: boolean) {
        this._initiaCostsFilter = this._userPreferencesService.verifyFilterChanges(
            this._initiaCostsFilter, this._costsFilter, 'costsFilter', this._userCacheService.getUserPreferences().id);

        let params = new HttpParams();
        let headers = new HttpHeaders();
        headers = headers.set('X-Requested-Client-Groups', this.getSearch() === '' ?
        this._costsFilter.clientGroups.map((c) => c.id).join(',') : '');
        if (this.periodType && this.getSearch() === '' && !detailed) {
            params = params.append('periodType', this.periodType);
        } else {
            params = params.append('startDate', `${this.getSearch() === '' ?
                moment(this.getStartDate(), 'DD/MM/YYYY').format('YYYY-MM-DD') :
                '2016-01-01'}`);
            params = params.append('endDate', `${this.getSearch() === '' ?
                moment(this.getEndDate(), 'DD/MM/YYYY').format('YYYY-MM-DD') :
                moment().format('YYYY-MM-DD')}`);
        }
        params = params.append('context', filterAutomotiveMode === 'Ligado' ? 'automotive' : this.getContext());
        params = params.append('imp', `${this.getTypeSearch() === 'IMP' ? this.getSearch() : ''}`,);
        params = params.append('chassisNumbers', this.getChassiSearch());
        params = params.append('profitCenters',  this.getSearch() === '' && filterAutomotiveMode === 'Desligado'
        ? this._costsFilter.profitCenters.map((c) => c.id).join(',') : '');
        params = params.append('models', this.getSearch() === '' && filterAutomotiveMode === 'Ligado'
        ? this._costsFilter.models.map((c) => c.id).join(',') : '');
        params = params.append('colors', this.getSearch() === '' && filterAutomotiveMode === 'Ligado'
        ? this._costsFilter.colors.map((c) => c.id).join(',') : '');
        params = params.append('manufacturerYears',  this.getSearch() === '' && filterAutomotiveMode === 'Ligado'
        ? this._costsFilter.manufacturerYears.map((c) => c.id).join(',') : '');
        params = params.append('modelYears',  this.getSearch() === '' && filterAutomotiveMode === 'Ligado'
        ? this._costsFilter.modelYears.map((c) => c.id).join(',') : '');
        params = params.append('clientReference', `${this.getTypeSearch() === 'REF' ? this.getSearch() : ''}`)
        return { params, headers };
    }

    updatePreference() {
        this._initiaCostsFilter = this._userPreferencesService.verifyFilterChanges(
            this._initiaCostsFilter, this._costsFilter, 'costsFilter', this._userCacheService.getUserPreferences().id);
    }


    getSearch() {
        return this.getImpSearch() !== '' ? this.getImpSearch() :
        this.getChassiSearch() !== '' ? this.getChassiSearch() : '';
    }

    private _setInitialTypeSearch() {
        if (this._userService.getCurrentUser().has('AUTOMOTIVE_USER')) {
            return 'Chassi';
        } else {
            return this._userService.getCurrentUser().has('INTERNAL_FILTERS') ? 'IMP' : 'REF';
        }
    }
}
