<div #dropdownComponent class="cmx-dropdown-container" [id]="uniqueId" [class.dark-theme]="darkTheme" [style.z-index]="isActive ? 13 : 12" [ngClass]="'size-'+sizeTheme" [class.disabled]="mergedSettings.disabled" [attr.uid]="uniqueId" [ngStyle]="styles">
    <div id="selected-dropdown" [class.is-actived]="isActive" [style.border]="(required && selectedItems.length === 0 && !mergedSettings.disabled) ? '1px solid #dc3545' : '1px solid #ced4da'">
        <div class="c-dropdown-btn" [class.disabled]="mergedSettings.disabled" [class.with-icons]="mergedSettings.icon" [ngStyle]="styles">
            <ng-container *ngIf="selectedItems.length === 0">
                <span class="text-placeholder">
                    {{ mergedSettings.text }}
                </span>
            </ng-container>
            <ng-container *ngIf="selectedItems.length > 0 && !mergedSettings.icon">
                <span class="text-value">
                    <ng-container *ngIf="mergedSettings.singleSelection">
                        {{mergedSettings?.prefix}}{{ itemsNameSelecteds }}{{mergedSettings?.suffix}}
                    </ng-container>
                    <ng-container *ngIf="!mergedSettings.singleSelection">
                        {{ selectedItems?.length > mergedSettings.badgeShowLimit && mergedSettings.badgeShowLabel ? totalItemsSelecteds : itemsNameSelecteds }}
                    </ng-container>
                </span>
            </ng-container>
            <ng-container *ngIf="selectedItems.length > 0 && mergedSettings.icon">
                <div class="c-dropdown-list">
                    <div class="dropdown-icons">
                        <ng-container *ngFor="let item of selectedItems; trackBy: trackByFn; let isLast = last; let k = index">
                            <img [title]="item.itemName" [src]="item.id" alt="Icone" width="29" height="17" />
                        </ng-container>
                    </div>
                </div>
            </ng-container>
            <span *ngIf="forceLoading ? false : (dataLoading ? data?.length > 0 : true)" class="icon-dropdown fa" [class.fa-angle-down]="!isActive" [class.fa-angle-up]="isActive"></span>
            <div class="spinner-loader" *ngIf="forceLoading || (dataLoading && data?.length < 1)">
                <p-progressSpinner [style]="{ width: '20px', height: '20px' }" strokeWidth="3" animationDuration=".5s"></p-progressSpinner>
            </div>
        </div>
    </div>
    <div id="dropdown-list" [style.max-width]="maxWidth" [class.inverted]="inverted" *ngIf="isActive">
        <div class="list-area" [style.width]="mergedSettings.forceFullWidth ? 'auto' : 'max-content'">
            <div class="list-filter" #searchFilterContainer *ngIf="mergedSettings.enableSearchFilter">
                <span class="fa fa-search"></span>
                <input type="text" #searchFilterInput autofocus [placeholder]="mergedSettings.searchPlaceholderText || 'Buscar'" [(ngModel)]="filter.itemName" (keyup)="searchKeyUp($event)">
            </div>
            <div class="pure-checkbox" #checkboxFilterContainer *ngIf="mergedSettings.enableCheckAll && !mergedSettings.singleSelection && !mergedSettings.limitSelection && data?.length !== 0" (click)="toggleSelectAll()">
                <input type="checkbox" [checked]="isSelectAll" [disabled]="mergedSettings.limitSelection == selectedItems?.length" />
                <label>
                    <span [hidden]="isSelectAll">{{mergedSettings.selectAllText}}</span>
                    <span [hidden]="!isSelectAll">{{mergedSettings.unSelectAllText}}</span>
                </label>
            </div>
            <ul [style.maxHeight]="mergedSettings.maxHeight || 300 + 'px'"
                [style.minHeight]="!data?.length ? 'fit-content' : data?.length === 1 ? '40px' : '80px'"
                (scroll)="listScroll($event)">
                <ng-container *ngIf="!mergedSettings.singleSelection">
                    <ng-container *ngIf="showSelectedItems">
                        <li class="label-items-selecteds" *ngIf="selectedItems.length > 0">{{ 'cmx::dropdown.selectedItems' | translate }}</li>
                        <li *ngFor="let item of selectedItems | listFilter:filter | slice:0:incrementItems; let i = index; trackBy: trackByFn;" (click)="onItemClick(item)" class="selected" [class.disabled]="item.disabled">
                            <label style="pointer-events: none"
                                [class.item-with-icon]="mergedSettings.icon"
                                [style.display]="item.amount ? 'flex' : 'block'"
                                [style.justify-content]="item.amount ? 'space-between' : 'left'"
                                [style.width]="item.disabled && item.disabledInfo ? 'calc(100% - 28px)' : '100%'">
                                <img *ngIf="mergedSettings.icon" [src]="item.id" alt="">
                                <span [title]="item.itemName">{{mergedSettings?.prefix}}{{item.itemName}}{{mergedSettings?.suffix}}</span>
                                <span *ngIf='item.amount' style="margin-left: 15px; font-weight: 600">
                                    {{ utilsService.formatNumber(item.amount, { style: 'currency', currency: item.currency }) }}
                                </span>
                            </label>
                            <div *ngIf="item.disabled && item.disabledInfo" class="disabled-info">
                                <span [title]="item.disabledInfo" class="material-icons">report_problem</span>
                            </div>
                            <div *ngIf="!item.disabled && item.info" class="info-container">
                                <span [title]="item.info" [tooltip]="item.info" [placement]="'top'">?</span>
                            </div>
                        </li>
                    </ng-container>
                    <li class="divisor" *ngIf="showSelectedItems && data.length !== selectedItems.length"></li>
                    <li *ngFor="let item of data | listFilter:filter | slice:0:incrementItems; let i = index; trackBy: trackByFn; let isFirst = first" (click)="onItemClick(item)" [hidden]="showSelectedItems && verifySelectedItem(item.id) ? true : false"
                    [ngClass]="{'selected': verifySelectedItem(item.id), 'disabled': item.disabled, 'preSelected': preSelectedItemIndex === i }">
                        <label [class.item-with-icon]="mergedSettings.icon"
                            [style.display]="item.amount ? 'flex' : 'block'"
                            [style.justify-content]="item.amount ? 'space-between' : 'left'"
                            [style.width]="item.disabled && item.disabledInfo ? 'calc(100% - 28px)' : '100%'">
                            <img *ngIf="mergedSettings.icon" [src]="item.id" alt="">
                            <span [title]="item.itemName">{{mergedSettings?.prefix}}{{item.itemName}}{{mergedSettings?.suffix}}</span>
                            <span *ngIf='item.amount' style="margin-left: 15px; font-weight: 600">
                                {{ utilsService.formatNumber(item.amount, { style: 'currency', currency: item.currency }) }}
                            </span>
                        </label>
                        <div *ngIf="item.disabled && item.disabledInfo" class="disabled-info">
                            <span [title]="item.disabledInfo" class="material-icons">report_problem</span>
                        </div>
                        <div *ngIf="!item.disabled && item.info" class="info-container">
                            <div [title]="item.info" [tooltip]="item.info" [placement]="'top'">?</div>
                        </div>
                    </li>
                </ng-container>
                <ng-container *ngIf="mergedSettings.singleSelection">
                    <li *ngFor="let item of data | listFilter:filter | slice:0:incrementItems; let i = index; trackBy: trackByFn;" (click)="onItemClick(item)"
                    [ngClass]="{'selected': verifySelectedItem(item.id), 'disabled': item.disabled, 'preSelected': preSelectedItemIndex === i }">
                        <label [class.item-with-icon]="mergedSettings.icon"
                            [style.display]="item.amount ? 'flex' : 'block'"
                            [style.justify-content]="item.amount ? 'space-between' : 'left'"
                            [style.width]="item.disabled && item.disabledInfo ? 'calc(100% - 28px)' : '100%'">
                            <img *ngIf="mergedSettings.icon" [src]="item.id" alt="">
                            <span [title]="item.itemName">{{mergedSettings?.prefix}}{{item.itemName}}{{mergedSettings?.suffix}}</span>
                            <span *ngIf='item.amount' style="margin-left: 15px; font-weight: 600">
                                {{ utilsService.formatNumber(item.amount, { style: 'currency', currency: item.currency }) }}
                            </span>
                        </label>
                        <div *ngIf="item.disabled && item.disabledInfo" class="disabled-info">
                            <span [title]="item.disabledInfo" class="material-icons">report_problem</span>
                        </div>
                        <div *ngIf="!item.disabled && item.info" class="info-container">
                            <span [title]="item.info" [tooltip]="item.info" [placement]="'top'">?</span>
                        </div>
                    </li>
                </ng-container>
            </ul>
            <div class="list-message" *ngIf="data?.length == 0">{{ mergedSettings.emptyDataMessage || 'common::messages.emptyDataMessage' | translate }}</div>
        </div>
    </div>
</div>
