import { environment } from '#environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ContainerTrackingFilter } from './models/container-tracking.filter.model';

@Injectable()
export class ContainerTrackingService {

    constructor(private _http: HttpClient) { }

    getClientsWithVessels(filterState: ContainerTrackingFilter): Observable<any> {
        const request = filterState.getRequest();
        return this._http.get(`${environment.endpoints.newTrafficTraceability}/tracking-vessel/clients`, request);
    }

    newGetVessels(filterState: ContainerTrackingFilter): Observable<any> {
        const request = filterState.getRequest('full');
        return this._http.get(`${environment.endpoints.newTrafficTraceability}/tracking-vessel/grouped-vessels`, request);
    }

    public getTrackingTimeline(equipmentNumber): Observable<any> {
        return this._http.get(`${environment.endpoints.newTrafficTraceability}/container/timeline-events?containerNumber=${equipmentNumber}`);
    }

}
