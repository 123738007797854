import { HttpHeaders, HttpParams } from '@angular/common/http';
import { AppModule } from 'app/app.module';
import moment from 'moment';
import * as _ from 'lodash';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { IChannelFilter } from './interface/channel.interface';

export class ChannelFilter {
    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);

    private _initialChannelFilter: IChannelFilter = <IChannelFilter>{};
    private _channelFilter: IChannelFilter = <IChannelFilter>{
        automotiveMode: 'Desligado',
        clientGroups: [],
        urfs: [],
        typeData: [{ id: 'di_register', itemName: 'Registro DI' }],
        period:'',
    };

    private startDate;
    private endDate;
    private offset: number;
    private limit: number;

    constructor(filter?: ChannelFilter) {
        this._channelFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'channelFilter', this._channelFilter, filter);
        this._initialChannelFilter = _.cloneDeep(this._channelFilter);
        this.startDate = filter ? filter.startDate : moment().format('01/YYYY');
        this.endDate = filter ? filter.endDate : moment().format('MM/YYYY');
        this.offset = filter ? filter.offset : 0;
        this.limit = filter ? filter.limit : 50;
    }

    getAutomotiveMode() {
        return this._channelFilter.automotiveMode;
    }
    getClientGroups(): string[] {
        return this._channelFilter.clientGroups;
    }
    getUrfs(): any[] {
        return this._channelFilter.urfs;
    }
    getTypeData() {
        return this._channelFilter.typeData;
    }
    getStartDate() {
        return this.startDate;
    }
    getEndDate() {
        return this.endDate;
    }
    getPeriod() {
        return this._channelFilter.period;
    }
    getOffset(): number {
        return this.offset;
    }
    getLimit(): number {
        return this.limit;
    }

    setAutomotiveMode(automotiveMode): void {
        this._channelFilter.automotiveMode = automotiveMode;
    }
    setClientGroups(clientGroups): void {
        this._channelFilter.clientGroups = clientGroups;
    }
    setTypeData(typeData): void {
        this._channelFilter.typeData = typeData;
    }
    setUrfs(urfs): void {
        this._channelFilter.urfs = urfs;
    }
    setStartDate(startDate): void {
        this.startDate = startDate;
    }
    setEndDate(endDate): void {
        this.endDate = endDate;
    }
    setPeriod(period): void {
        this._channelFilter.period = period;
    }
    setOffset(offset): void {
        this.offset = offset;
    }
    setLimit(limit): void {
        this.limit = limit;
    }

    getBaseRequest() {

        this._initialChannelFilter = this._userPreferencesService.verifyFilterChanges(
            this._initialChannelFilter, this._channelFilter, 'channelFilter', this._userCacheService.getUserPreferences().id);

        let params = new HttpParams();
        let headers = new HttpHeaders();

        headers = headers.set('X-Requested-Client-Groups', this.getClientGroups().map((c) => c['id']).join(','));

        if (this._channelFilter.urfs.length > 0) {
            params = params.append('urfNationalizationCodes', this.getUrfs().map((c) => c['id']).join(','));
        }
        if (this._channelFilter.typeData[0]?.id === "da_register" || this._channelFilter.typeData[0]?.id === "da_clearance") {
            params = params.append('clearanceType', 'DA');
        }

        if (this._channelFilter.typeData[0]?.id === "da_clearance" || this._channelFilter.typeData[0]?.id === "di_clearance") {
            params = params.append('referenceDateType', 'clearance');
        }

        return { headers, params };
    }
}
