import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, Renderer2, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import * as _ from 'lodash';

import { environment } from '#environment';
import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { UserService } from "app/auth/_services/user.service";
import { CmxDropdownService } from 'app/shared/components/cmx-dropdown/cmx-dropdown.service';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UtilsService } from '#services/_utils/utils.service';

import { LogisticCostsFilter } from './logistic-costs.filter';
import { FilterStateService } from '#services/_filters/filter-state.service';
import { DROPDOWN_SETTINGS } from 'app/shared/constants/dropdown-settings.constants';
import { AmCharts5Service, IChartConfig } from '#services/_charts/charts.service';
import { TranslateService } from '@ngx-translate/core';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { TransportModalService } from '#services/_transportModal/transportModal.service';

@Component({
    selector: 'logistic-costs-chart',
    templateUrl: './logistic-costs.component.html',
    styleUrls: ['./../charts.scss', './logistic-costs.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class LogisticCostsComponent {

    currentUser$ = this.userService.currentUser$;
    currentUser = this.userService.getCurrentUser();
    isMixedUser = this.currentUser.has('ROUTE_AUTOMOTIVE') && !this.currentUser.has('AUTOMOTIVE_USER') ? true : false;
    isInternalUser = this.currentUser.has('INTERNAL_FILTERS');

    filterState: LogisticCostsFilter = this._filterStateService.getLogisticCostsFilter();
    editableElements = ['#logisticCosts', '.checkmark-container'];
    selectedPeriod: string = 'CURRENT_YEAR';
    translatedSwitchOptions = this.switchOptions;
    translatedPeriodOptions = this.periodOptions;

    subscription: Subscription = null;
    translateSubscription: Subscription = null;
    asyncLogisticCosts: boolean = false;
    loadingTranslate: boolean = false;
    summaryLine = [];
    chartData;

    costsOptions: DropdownOption[] = [];
    subsidiaryNames: DropdownOption[] = this._cmxDropdownService.toDropdownItems(this._userCacheService.getSubsidiaries());
    clientOptions: DropdownOption[] = this._cmxDropdownService.toDropdownItems(this._userCacheService.getClientGroups());
    modalOptions: DropdownOption[] = this._cmxDropdownService.toDropdownItemsImg(_.cloneDeep(this._transportModalService.getModalList()));

    @HostListener('click') onSetZIndex() {
        this.renderer2.setStyle(this._elementRef.nativeElement, 'z-index', this.utilsService.autoIncrementValue);
    }

    get helpDescription(): object[] {
        return [
            { type: 'text', value: this._translateService.instant('dashboard::logisticCosts.description') },
        ];
    }

    get dropdownSettings() {
        return {
            modals: {
                ..._.cloneDeep(DROPDOWN_SETTINGS.modals),
                translate: true
            },
            clientGroups: {
                text: this._translateService.instant('common::strings.clientsGroup'),
                translate: true
            },
            subsidiary: {
                text: this._translateService.instant('common::strings.subsidiaries'),
                translate: true
            },
            costs: {
                text: this._translateService.instant('dashboard::logisticCosts.operationalCost'),
                translate: true
            },
        };
    }

    get periodOptions() {
        return [
            {
                code: 'CURRENT_YEAR',
                name: this._translateService.instant('common::strings.currentYear')
            },
            {
                code: 'LAST_12_MONTHS',
                name: this._translateService.instant('common::strings.last12Months')
            }
        ];
    }

    get switchOptions() {
        return [
            { name: this._translateService.instant('common::strings.average'), code: 'average' },
            { name: this._translateService.instant('common::strings.total'), code: 'total' },
            { name: this._translateService.instant('common::strings.netTon'), code: 'netTon' }
        ];
    }

    constructor(
        public router: Router,
        private userService: UserService,
        private http: HttpClient,
        private utilsService: UtilsService,
        private _userCacheService: UserCacheService,
        private _cmxDropdownService: CmxDropdownService,
        private _elementRef: ElementRef,
        private renderer2: Renderer2,
        private _filterStateService: FilterStateService,
        private _AmCharts5Service: AmCharts5Service,
        private _utilsService: UtilsService,
        private _translateService: TranslateService,
        private _userPreferencesService: UserPreferencesService,
        private _transportModalService: TransportModalService
    ) {
        if(!this.isInternalUser) {
            const client = [this._userCacheService.getClientGroups()[0]];
            this.filterState.setClientGroups(this._cmxDropdownService.toDropdownItems(client || []));
        }
    }

    ngOnDestroy() {
        this._unsubscribe();

        if(this.translateSubscription) {
            this.translateSubscription.unsubscribe();
        }
    }

    ngOnInit() {
        this._getDropdownValues();
    }

    ngAfterViewInit() {
        this.onChanges();
    }

    onChanges() {
        this.translateSubscription = this._userPreferencesService.onLanguageOrPreferenceChange(
            () => {
                this.loadingTranslate = true;
            }, (typeChange) => {
                if (typeChange === 'language') {
                    this._translateContent();
                }
            }
        );
    }

    toggleFilterMode() {
        if ($('logistic-costs-chart .filterMode').hasClass('clicked')) {
            this.filterState.setAutomotiveMode('Desligado');
            $('logistic-costs-chart .filterMode').removeClass('clicked');
        } else {
            this.filterState.setAutomotiveMode('Ligado');
            $('logistic-costs-chart .filterMode').addClass('clicked');
        }
        this.loadLogisticCosts(this.selectedPeriod);
    }

    toggleTypeSwitch(value) {
        this.filterState.setType(value?.code);
        this.loadLogisticCosts(this.selectedPeriod);
    }

    toggleVisualSwitch(values) {
        const chartType = values.index === 0 ? 'XYChart' : 'LineChart';
        this.filterState.setChartType(chartType);
        this.loadLogisticCosts(this.selectedPeriod);
    }

    toggleStorage() {
        if ($('logistic-costs-chart .storageToogle').hasClass('clicked')) {
            $('logistic-costs-chart .storageToogle').removeClass('clicked');
            this.filterState.setStorageToogle('Desconsiderando');
        } else {
            $('logistic-costs-chart .storageToogle').addClass('clicked');
            this.filterState.setStorageToogle('Considerando');
        }
        this.loadLogisticCosts(this.selectedPeriod);
    }

    changeDateParam({ selected }): void {
        let periodCod = selected[0].code;

        if (periodCod === 'CURRENT_YEAR' || periodCod === 'LAST_12_MONTHS') {
            this.selectedPeriod = periodCod;
            this.loadLogisticCosts(periodCod);
        } else {
            this.selectedPeriod = '';
            this.filterState.setStartDate(moment().add(-periodCod, 'days').format('DD/MM/YYYY'));
            this.filterState.setEndDate(moment().format('DD/MM/YYYY'));
            this.loadLogisticCosts();
        }
    }

    loadLogisticCosts(preset?) {
        this._unsubscribe();
        this.asyncLogisticCosts = true;
        this.utilsService.setInvisible('logistic-costs-chart', this.editableElements);

        if (this.filterState.getClientGroups().length === 0 && this.isInternalUser) {
            this.utilsService.setInvisible('logistic-costs-chart', this.editableElements);
            this.asyncLogisticCosts = false;
        } else {
            this.chartData = undefined;
            this.subscription = this.getLogisticCosts(preset).subscribe(
                (logisticCosts) => {
                    this.chartData = logisticCosts;
                    this.chartData.consolidatedCosts?.forEach(cost => {
                        cost.value = parseFloat(cost.value).toFixed(2);
                    });

                    this._createChart(this.chartData.consolidatedCosts);
                    this._populateSummaryLine(this.chartData.consolidatedCosts);
                    this.utilsService.setVisible('logistic-costs-chart', this.editableElements);
                    this.asyncLogisticCosts = false;
                }, (error) => {
                    this.chartData = undefined;
                    this._populateSummaryLine([]);
                    this.asyncLogisticCosts = false;
                    this.utilsService.errorHandler(error, this._translateService.instant('dashboard.title::logisticCosts'));
                }
            );
        }
    }

    private _getModalOptions() {
        const translatedList = this._utilsService.translateListField(this.filterState.getI18nModalList(), 'i18n', false);
        const newList =  translatedList?.map(modal => ({ ...modal, itemName: modal.i18n }));
        this.modalOptions = this._cmxDropdownService.toDropdownItemsImg(newList);
        this._verifySelectedModal();
    }

    private _populateSummaryLine(costs) {
        const isMedia = this.filterState.getType() === 'average';
        const total = costs.reduce((acc, p) => acc + parseFloat(isMedia ? p.value : p.totalValue) || 0, 0);

        this.summaryLine = [
            {
                title: this._translateService.instant('common::strings.totalValue'),
                value: this._utilsService.formatNumber(total, { style: 'currency' })
            }
        ];
    }

    private loadOperationalCosts() {
        return this.http.get(`${environment.endpoints.expensesService}/costs/operational`);
    }

    private getLogisticCosts(preset?): Observable<any> {
        const request = this.filterState.getRequest(this.costsOptions, preset);
        return this.http.get(`${environment.endpoints.expensesService}/importationExpenses/logistic-cost`, request);
    }

    private _createChart(dataChart: any): void {
        const chartType = this.filterState.getChartType();
        const isXYChart = chartType === 'XYChart';

        const data = dataChart?.map(item => ({
            date: item.date,
            value: parseFloat(item.value),
        }));

        const chartConfig: IChartConfig = {
            chartId: 'logisticCosts',
            chartType,
            axis: [
                { axisPosition: 'x', categoryField: 'date', type: 'category' },
                { axisPosition: 'y', categoryField: 'value' },
            ],
            axisConfig: {
                y: { extraMax: isXYChart ? 0.2 : 0.05 },
            },
            axisTitle: {
                y: this._translateService.instant('dashboard::logisticCosts.costsInThousandsBRL'),
            },
            content: [
                {
                    name: this._translateService.instant('dashboard.title::logisticCosts'),
                    valueYField: 'value',
                    valueXField: 'date',
                },
            ],
            showLegend: false,
            showTooltip: !isXYChart,
            padding: { top: 20, bottom: isXYChart ? 20 : 30, left: 10, right: 10 },
            showValuesAboveBars: true,
            labelConfig: { fontSize: 10, fontWeight: 'bold' }
        };

        this._AmCharts5Service.createChart(chartConfig, { data });
    }

    private _translateContent() {
        this._getModalOptions();
        this.translatedSwitchOptions = this.switchOptions;
        this.translatedPeriodOptions = this.periodOptions;

        if(this.chartData) {
            this._createChart(this.chartData.consolidatedCosts);
            this._populateSummaryLine(this.chartData.consolidatedCosts);
        } else {
            this._populateSummaryLine([]);
        }

        setTimeout(() => {
            this.loadingTranslate = false;
        }, 1000);
    }

    private _verifySelectedModal(){
        const selectedModal = this.modalOptions?.filter(modal => this.filterState.getModal().some(item => item.id === modal.id) );
        this.filterState.setModal(selectedModal);
    }

    private _getDropdownValues() {
        this._getModalOptions();

        this.loadOperationalCosts().subscribe((data) => {
            this.costsOptions = this._cmxDropdownService.toDropdownItems(data);
        });
    }

    private _unsubscribe() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
