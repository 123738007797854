

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserService } from "app/auth/_services/user.service";

@Component({
    selector: 'permission-info',
    templateUrl: './permission-info.component.html',
    styleUrls: ['./permission-info.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PermissionInfoComponent implements OnInit {
    crs = [
        {
            code: '',
            name: '',
            type: '',
        },
    ];
    clients = [
        {
            code: '',
            name: '',
            type: '',
        },
    ];
    preferenceClients = [
        {
            id: '',
            itemName: ''
        },
    ];
    currentUser;
    asyncPermisssionInfo = false;

    constructor(
        private userService: UserService,
        private userCacheService: UserCacheService
    ) {}

    ngOnInit() {
        this.userService.currentUser$.subscribe((user) => {
            this.currentUser = user;
            this.clients = this.userCacheService.getClientGroups();
            this.crs = this.userCacheService.getProfitCenters();
            this.preferenceClients = this.userCacheService.getUserPreferences()?.filters?.clientGroups?.content?.value;
            this.asyncPermisssionInfo = true; 
        });
    }

    openPermissionInfoModal() {
        $('.permissionInfoModal').removeClass('hidden');
    }
    closePermissionInfoModal() {
        $('.permissionInfoModal').addClass('hidden');
    }
    openDateInfoModal() {
        $('.dateInfoModal').removeClass('hidden');
    }
    closeDateInfoModal() {
        $('.dateInfoModal').addClass('hidden');
    }

    trackByFn(index: number, item: any) {
        return index;
    }
}
