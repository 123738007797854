import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../../shared/shared.module';

import { ChatAIComponent } from './chat-ai.component';
import { NgxMaskModule } from 'ngx-mask';
import { QRCodeModule } from 'angularx-qrcode';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        QRCodeModule,
        NgxMaskModule.forRoot(),
    ],
    declarations: [
        ChatAIComponent
    ],
    exports: [
        ChatAIComponent,
    ],
    providers: [
    ],
})
export class ChatAIModule {

}
