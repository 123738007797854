import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5locales_pt_BR from '@amcharts/amcharts5/locales/pt_BR';

export function createXYChart(root, chartConfig, data) {
    root.locale = am5locales_pt_BR;

    const isHorizontal = chartConfig.orientation === 'horizontal';
    const chart = root.container.children.push(
        am5xy.XYChart.new(root, {
            layout: root.verticalLayout,
            panX: false,
            panY: false,
            wheelX: 'none',
            wheelY: 'none',
            paddingTop: chartConfig?.padding?.top || 20,
            paddingBottom: chartConfig?.padding?.bottom || 20,
            paddingLeft: chartConfig?.padding?.left || 0,
            paddingRight: chartConfig?.padding?.right || 20,
        })
    );

    if (chartConfig.title) {
        chart.children.unshift(
            am5.Label.new(root, {
                text: chartConfig.title.text,
                fontSize: chartConfig.title.size || 12,
                fontWeight: 'bold',
                x: am5.p50,
                centerX: am5.p50,
                paddingBottom: 10,
                paddingTop: 10,
            })
        );
    }

    let xAxis, yAxis;

    if (isHorizontal) {
        xAxis = chart.xAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererX.new(root, {
                    minGridDistance: chartConfig.axisConfig?.x?.minGridDistance || 20,
                }),
                extraMax: chartConfig.axisConfig?.y?.extraMax || 0.15,
            })
        );

        yAxis = chart.yAxes.push(
            am5xy.CategoryAxis.new(root, {
                categoryField: chartConfig.axis.find(a => a.axisPosition === 'x').categoryField,
                renderer: am5xy.AxisRendererY.new(root, {
                    minGridDistance: 20,
                }),
                tooltip: chartConfig.showTooltip ? am5.Tooltip.new(root, {}) : undefined,
            })
        );
    } else {

        xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
                categoryField: chartConfig.axis.find(a => a.axisPosition === 'x').categoryField,
                renderer: am5xy.AxisRendererX.new(root, {
                    minGridDistance: chartConfig.axisConfig?.x?.minGridDistance || 20,
                }),
                tooltip: chartConfig.showTooltip ? am5.Tooltip.new(root, {}) : undefined,
            })
        );

        yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererY.new(root, {
                    minGridDistance: 20,
                    inside: false,
                }),
                extraMax: chartConfig.axisConfig?.y?.extraMax || 0.15,
            })
        );
    }

    if (isHorizontal) {
        yAxis.data.setAll(data);
        if (chartConfig.referenceLine) {
            let rangeDataItem = xAxis.makeDataItem({
                value: chartConfig.referenceLine.value, above: true
            });
            let range = xAxis.createAxisRange(rangeDataItem);
            range.get("grid").setAll({
                stroke: '#e54a29',
                strokeWidth: 3,
                strokeOpacity: 1,
                location: 1
            });
        }
    } else {
        xAxis.data.setAll(data);
        if (chartConfig.referenceLine) {
            let rangeDataItem = yAxis.makeDataItem({
                value: chartConfig.referenceLine.value, above: true
            });
            let range = yAxis.createAxisRange(rangeDataItem);
            range.get("grid").setAll({
                stroke: '#e54a29',
                strokeWidth: 3,
                strokeOpacity: 1,
                location: 1
            });
        }
    }

    xAxis.children.push(am5.Label.new(root, {
        text: chartConfig.axisTitle?.x || '',
        fontWeight: 'bold',
        x: am5.p50,
        centerX: am5.p50,
    }));

    yAxis.children.unshift(
        am5.Label.new(root, {
            text: chartConfig.axisTitle?.y || '',
            fontWeight: 'bold',
            rotation: -90,
            centerX: am5.p50,
            y: am5.percent(50),
            centerY: am5.p50,
            textAlign: 'center'
        })
    );

    const colors = chartConfig.colors || ['#1f77b4', '#7f7f7f'];

    chartConfig.content.forEach((seriesConfig, index) => {
        let series;

        const tooltipValueField = isHorizontal ? '{valueX}' : '{valueY}';
        const tooltipLabelText = `${seriesConfig.prefix || ''} ${tooltipValueField} ${seriesConfig.suffix || ''}`;
        const color = am5.color(seriesConfig.color || colors[index % colors.length]);

        if (isHorizontal) {

            if (seriesConfig.seriesType === 'line') {
                series = chart.series.push(
                    am5xy.LineSeries.new(root, {
                        name: seriesConfig.name,
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueXField: seriesConfig.valueYField,
                        categoryYField: seriesConfig.valueXField,
                        tooltip: am5.Tooltip.new(root, {
                            pointerOrientation: "horizontal",
                            labelText: tooltipLabelText,
                            getFillFromSprite: false,
                        })
                    })
                );
                series.strokes.template.setAll({
                    stroke: color,
                    strokeWidth: 3,
                    strokeOpacity: 1
                });
                series.strokes.template.adapters.add("stroke", () => color);
                series.get("tooltip").get("background").setAll({
                    fill: color,
                    stroke: color,
                    fillOpacity: 1,
                    strokeWidth: 1
                });

                series.bullets.push(() => {
                    return am5.Bullet.new(root, {
                        sprite: am5.Circle.new(root, {
                            strokeWidth: 2,
                            stroke: color,
                            radius: 4,
                            fill: root.interfaceColors.get('background'),
                        }),
                    });
                });
            } else {

                series = chart.series.push(
                    am5xy.ColumnSeries.new(root, {
                        name: seriesConfig.name,
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueXField: seriesConfig.valueYField,
                        categoryYField: seriesConfig.valueXField,
                        tooltip: am5.Tooltip.new(root, { labelText: tooltipLabelText }),
                    })
                );
                series.columns.template.setAll({ fill: color, stroke: color });
            }
        } else {
            if (seriesConfig.seriesType === 'line') {
                series = chart.series.push(
                    am5xy.LineSeries.new(root, {
                        name: seriesConfig.name,
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueYField: seriesConfig.valueYField,
                        categoryXField: seriesConfig.valueXField,
                        tooltip: am5.Tooltip.new(root, {
                            pointerOrientation: "horizontal",
                            labelText: tooltipLabelText,
                            getFillFromSprite: false,
                        })
                    })
                );
                series.strokes.template.setAll({
                    stroke: color,
                    strokeWidth: 3,
                    strokeOpacity: 1
                });
                series.strokes.template.adapters.add("stroke", () => color);
                series.get("tooltip").get("background").setAll({
                    fill: color,
                    stroke: color,
                    fillOpacity: 1,
                    strokeWidth: 1
                });

                series.bullets.push(() => {
                    return am5.Bullet.new(root, {
                        sprite: am5.Circle.new(root, {
                            strokeWidth: 2,
                            stroke: color,
                            radius: 4,
                            fill: root.interfaceColors.get('background'),
                        }),
                    });
                });
            } else {
                series = chart.series.push(
                    am5xy.ColumnSeries.new(root, {
                        name: seriesConfig.name,
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueYField: seriesConfig.valueYField,
                        categoryXField: seriesConfig.valueXField,
                        tooltip: am5.Tooltip.new(root, { labelText: tooltipLabelText }),
                    })
                );
                series.columns.template.setAll({ fill: color, stroke: color });

                if (chartConfig.showValuesAboveBars && seriesConfig.seriesType !== 'line') {
                    series.bullets.push(() => {
                        return am5.Bullet.new(root, {
                            locationY: 1,
                            sprite: am5.Label.new(root, {
                                text: '{valueY}',
                                centerX: am5.p50,
                                centerY: am5.p0,
                                dy: -20,
                                fontSize: chartConfig.labelConfig?.fontSize || 12,
                                fontWeight: chartConfig.labelConfig?.fontWeight || 'bold',
                                populateText: true,
                                fill: am5.color(0x000000),
                            }),
                        });
                    });
                }
            }
        }

        series.data.setAll(data);
    });

    if (chartConfig.showTooltip) {
        chart.set('cursor', am5xy.XYCursor.new(root, { behavior: 'zoomX' }));
    }

    chart.zoomOutButton.set('forceHidden', true);

    setTimeout(() => {
        chart.seriesContainer.children.each((child) => {
            if (child instanceof am5xy.LineSeries) {
                (child as any).set("zIndex", 10);
                (child as any).toFront();
            }
            else if (child instanceof am5xy.ColumnSeries) {
                (child as any).set("zIndex", 1);
            }
        });
    }, 0);

    return chart;
}
