import { environment } from '#environment';
import { AfterViewInit, Component, ViewChild, ViewEncapsulation, ElementRef, Renderer2 } from '@angular/core';
import { FeatureFlagService } from '#services/_feature-flag/feature-flag-service';
import QRCode from 'qrcodejs2';
import Inputmask from 'inputmask';

import { LoginService } from '../../../auth/_services/login.service';
import { ActivatedRoute } from '@angular/router';
import { AppComponent } from 'app/app.component';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'app/auth/_services/user.service';
import { AuthenticationService } from 'app/auth/_services/authentication.service';
import { UtilsService } from '#services/_utils/utils.service';
import { NotificationService } from '#services/_notification/notification.service';

@Component({
    selector: 'edit-profile',
    templateUrl: './edit-profile.component.html',
    styleUrls: ['./edit-profile.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class EditProfileComponent implements AfterViewInit {
    currentUser$ = this.userService.currentUser$;
    user: any = this.userService.getCurrentUser();
    currentEmail = this.user.getEmail();

    asyncChangePassword = true;
    asyncTwoFactor = true;
    asyncEditPhone = true;
    actualPassword = '';
    newPassword = '';
    repeatPassword = '';
    warnMessage = null;
    twoFactorMandatory = false;

    focusHit: boolean = false;
    checkTotalPassword:boolean = false;
    checkNumbersCharsPassword:boolean = false;
    checkSpecialCharPassword:boolean = false;
    passwordAccept: boolean = false;
    confirmationTwoFactorSuccess: boolean = false;
    confirmTwoFactorMoment: boolean = false;

    qrcode: any = null;
    twoFactorKey: string = null;
    captchaToken: string = '';
    mobile: any = true;
    idToken: String = ''
    sessionId: String = ''
    logoLogin = this.loginService.getLogo();

    whatsappUrl;
    otpInterval = null;
    phoneFeatureFlag: boolean = false;
    phoneMask: any = Inputmask({ mask: '(99) 99999-9999' });
    showOtp: boolean = false;
    phoneNumber: any = null;
    formattedPhoneNumber: any = null;
    updatePhoneNumber: boolean = false;
    phoneIntegrationData: any = null;

    @ViewChild('enableTwoFactorModal') private _elEnableTwoFactorModal: ElementRef;
    @ViewChild('enableAIModal') private _elEnableAIModal: ElementRef;
    @ViewChild('editPhoneModal') private _elEditPhoneModal: ElementRef;
    @ViewChild('confirmDisableTwoFactorModal') private _elCloseConfirmationModal: ElementRef;
    @ViewChild('changePasswordModal') private _elChangePasswordModal: ElementRef;
    @ViewChild('twoFactorTutorial') private _elTwoFactorTutorial: ElementRef;

    constructor(
        private userService: UserService,
        private loginService: LoginService,
        private _authenticationService: AuthenticationService,
        private _featureFlagService: FeatureFlagService,
        private _renderer: Renderer2,
        private route: ActivatedRoute,
        private app: AppComponent,
        private _utilsService: UtilsService,
        private _notificationService: NotificationService,
        private _translateService: TranslateService
    ) { }

    ngOnInit() {
        this.getFeatureFlag();
        this.userService.refreshUser();
        this.twoFactorMandatory = this.user.hasToEnableTwoFactor();
        this.twoFactorKey = this.user.getTwoFactorKey();

        this.route.queryParams.subscribe((params) => {
            if(params.mode) {
                $('edit-profile .changePasswordModal').removeClass('hidden')
                this.openChangePasswordModal();
            }
        });
        this.app.globalLoader = false;
        this.idToken = localStorage.getItem('idToken');
        this.sessionId = localStorage.getItem('sessionId');
        this.phoneMask.mask($(`phone-number`));
        this.getPhoneIntegrationData();
    }

    ngAfterViewInit() {
        this.showQRCode();
        if (this.user.hasToEnableTwoFactor()) {
            this.openTwoFactorModal();
        }
    }

    ngOnDestroy() {
        clearInterval(this.otpInterval);
    }

    startOtpCodePolling() {
        this.otpInterval = setInterval(() => this.getPhoneIntegrationData(), 60000);
    }

    async getFeatureFlag(): Promise<void> {
        this.phoneFeatureFlag = await this._featureFlagService.isFeatureFlagEnabledV2('profile_enable_ai');
    }

    getPhoneIntegrationData() {
        const uid = this.userService.getCurrentUser().getUid();
        this._authenticationService.getPhoneIntegrationData(uid).subscribe(
            (data: any) => {
                this.phoneIntegrationData = data;
                this.phoneNumber = data.phoneNumber;
                const match = data.phoneNumber.match(/^(\d{2})(\d{5})(\d{4})$/);
                this.formattedPhoneNumber = `(${match[1]}) ${match[2]}-${match[3]}`;
                this.whatsappUrl = this._generateWhatsappUrl(data.otpCode);
                if (data.otpCode === null && this.otpInterval !== null) {
                    clearInterval(this.otpInterval);
                } else if (data.otpCode !== null && this.otpInterval === null) {
                    this.startOtpCodePolling();
                }
            }
        );
    }

    setShowOtp(showOtp: boolean) {
        this.showOtp = showOtp;
    }

    editPhoneOnKeydown(event) {
        if (event.key === 'Enter') {
            this.savePhoneIntegration();
        }
    }

    savePhoneIntegration() {
        if (this.verifyPhoneNumber(this.phoneNumber) === false) {
            this._notificationService.openNotification('error', this._translateService.instant('auth.msgInvalidPhoneNumber'), this._translateService.instant('auth.changePhone'));
            this.phoneNumber = this.updatePhoneNumber ? this.phoneIntegrationData.phoneNumber : null;
            return;
        }

        if (this.updatePhoneNumber) {
            this.updatePhoneIntegration();
        } else {
            this.createPhoneIntegration();
        }
        this.closeEditPhoneModal();
    }

    createPhoneIntegration() {
        this.asyncEditPhone = false;
        const uid = this.userService.getCurrentUser().getUid();
        this._authenticationService.createPhoneIntegration(uid, this.phoneNumber).subscribe(
            (data: any) => {
                this.asyncEditPhone = true;
                this.getPhoneIntegrationData();
                this.startOtpCodePolling();
            }, (error) => {
                this.asyncEditPhone = true;
                this._utilsService.errorHandler(error, this._translateService.instant('auth.phone'));
            }
        );
    }

    updatePhoneIntegration() {
        this.asyncEditPhone = false;
        const uid = this.userService.getCurrentUser().getUid();
        this._authenticationService.updatePhoneIntegration(uid, this.phoneNumber).subscribe(
            (_data: any) => {
                this.asyncEditPhone = true;
                this.getPhoneIntegrationData();
            }, (error) => {
                this.asyncEditPhone = true;
                this._utilsService.errorHandler(error, this._translateService.instant('auth.phone'));
            }
        );
    }

    verifyPhoneNumber(number) {
        return number !== null && number.length === 11;
    }

    openChangePasswordModal() {
        if(this._elChangePasswordModal){
            this._renderer.removeClass(this._elChangePasswordModal.nativeElement, 'hidden');
        }
    }

    openTwoFactorModal() {
        this._renderer.removeClass(this._elEnableTwoFactorModal.nativeElement, 'hidden');
    }

    openEditPhoneModal() {
        this.updatePhoneNumber = this.phoneIntegrationData ? true : false;
        this._renderer.removeClass(this._elEditPhoneModal.nativeElement, 'hidden');
    }

    openEnableAIModal() {
        this._renderer.removeClass(this._elEnableAIModal.nativeElement, 'hidden');
    }

    closeChangePasswordModal() {
        this.clearInputs();
        this._renderer.addClass(this._elChangePasswordModal.nativeElement, 'hidden');
    }

    closeEditPhoneModal() {
        this._renderer.addClass(this._elEditPhoneModal.nativeElement, 'hidden');
    }

    closeEnableAIModal() {
        this._renderer.addClass(this._elEnableAIModal.nativeElement, 'hidden');
        this.setShowOtp(false);
    }

    closeEnableTwoFactorModal() {
        if (this.confirmationTwoFactorSuccess) {
            this._authenticationService.logout(true, false);
            return;
        }
        this.confirmTwoFactorMoment = false;
        this._renderer.addClass(this._elEnableTwoFactorModal.nativeElement, 'hidden');
    }

    changePassword() {
        const [localPart, domainPart] = this.currentEmail.split('@');
        const nameParts = localPart.split('.');
        const domainParts = domainPart.split('.');
        const forbiddenWords = [...nameParts, ...domainParts].filter(Boolean).join('|');
        const regex = new RegExp(forbiddenWords, 'gi');

        if (this.newPassword !== this.repeatPassword) {
            this._notificationService.openNotification('error', this._translateService.instant('auth.msgPasswordMismatch'), this._translateService.instant('auth.msgPasswordChange'));
            return;
        }

        if (regex.test(this.newPassword)) {
            this._notificationService.openNotification('error', this._translateService.instant('auth.msgPasswordContainsEmail'), this._translateService.instant('auth.msgPasswordChange'));
            return;
        }

        if (!this._authenticationService.getPasswordRegex().test(this.newPassword)) {
            this._notificationService.openNotification('error', this._translateService.instant('auth.msgPasswordNotMet'), this._translateService.instant('auth.msgPasswordChange'));
            return;
        }

        this.asyncChangePassword = false;

        this._authenticationService.updatePassword(this.idToken,this.sessionId,this.currentEmail,this.newPassword,this.actualPassword,this.captchaToken,this.mobile).subscribe(
            () => {
                this.asyncChangePassword = true;
                this._notificationService.openNotification('success', this._translateService.instant('common::messages.success::updated'), this._translateService.instant('auth.msgPasswordChange'));
                setTimeout(() => {
                    this._authenticationService.logout(true, false);
                    this.closeChangePasswordModal();
                }, 3000);
            }, (error) => {
                this.asyncChangePassword = true;
                this._utilsService.errorHandler(error, this._translateService.instant('auth.msgPasswordChange'));
            }
        );
    }

    checkPassword(input) {
        const password = input.target.value;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumber = /\d/.test(password);
        const hasSpecialChar = /[!@#$%&^*()_\-]/.test(password);
        const isValidLength = password.length >= 8;

        this.checkTotalPassword = isValidLength;
        this.checkNumbersCharsPassword = hasUpperCase && hasLowerCase && hasNumber;
        this.checkSpecialCharPassword = hasSpecialChar;
        this.passwordAccept = this.checkTotalPassword && this.checkNumbersCharsPassword && this.checkSpecialCharPassword;
    }

    getQRCodeUrl() {
        const email = this.currentEmail;
        return `otpauth://totp/Comexport:${email}?secret=${this.twoFactorKey}&issuer=Comexport&algorithm=SHA1&digits=6&period=30`;
    }

    showQRCode() {
        if (this.isTwoFactorEnabled()) {
            if (this.qrcode) {
                this.qrcode.clear();
            }
            $('#qrcode').empty();
            this.qrcode = new QRCode('qrcode', {
                text: this.getQRCodeUrl(),
                width: 128,
                height: 128,
                colorDark: '#000000',
                colorLight: '#ffffff',
            });
            $('#qrcode').prop('title', 'QR Code');
        } else {
            if (this.qrcode) {
                this.qrcode.clear();
            }
            $('#qrcode').empty();
        }
    }

    confirmTwoFactor() {
        this.asyncTwoFactor = false;
        this.confirmationTwoFactorSuccess = true;
        const uid = this.userService.getCurrentUser().getUid();
        this._authenticationService.updateTwoFactor(uid, true).subscribe(
            (data: any) => {
                this.twoFactorKey = data.twoFactorKey;
                this.warnMessage = this._translateService.instant('auth.msgTwoFactorAuthActive') + ' ' + this._translateService.instant('auth.msgLoginWithoutAuthCode');
                this.asyncTwoFactor = true;
                this.userService.refreshUser();
                setTimeout(() => {
                    this.showQRCode();
                }, 300);
            }, (error) => {
                this.asyncTwoFactor = true;
                this._utilsService.errorHandler(error, this._translateService.instant('auth.msgTwoFactorAuth'));
            }
        );
    }

    disableTwoFactor() {
        this.confirmationTwoFactorSuccess = false;
        this._renderer.removeClass(this._elCloseConfirmationModal.nativeElement, 'hidden');
    }

    closeConfirmationModal() {
        this._renderer.addClass(this._elCloseConfirmationModal.nativeElement, 'hidden');
    }

    confirmDisableTwoFactor() {
        this.asyncTwoFactor = false;
        this.closeConfirmationModal();
        const uid = this.userService.getCurrentUser().getUid();
        this._authenticationService.updateTwoFactor(uid, false).subscribe(
            (data) => {
                this.userService.refreshUser();
                this.twoFactorKey = null;
                this.warnMessage = null;
                this.closeEnableTwoFactorModal();
                this._notificationService.openNotification('success', this._translateService.instant('common::messages.success::updated'), this._translateService.instant('auth.msgTwoFactorAuth'));
                setTimeout(() => {
                    this.asyncTwoFactor = true;
                    this.showQRCode();
                }, 300);
            }, (error) => {
                this.asyncTwoFactor = true;
                this._utilsService.errorHandler(error, this._translateService.instant('auth.msgTwoFactorAuth'));
            }
        );
    }

    openTutorial() {
        this._renderer.removeClass(this._elTwoFactorTutorial.nativeElement, 'hidden');
    }

    closeTutorial() {
        this._renderer.addClass(this._elTwoFactorTutorial.nativeElement, 'hidden');
    }

    isTwoFactorEnabled() {
        return this.twoFactorKey != null && this.twoFactorKey !== 'change_it';
    }

    isTwoFactorMandatory() {
        return this.twoFactorMandatory;
    }

    private clearInputs() {
        this.actualPassword = '';
        this.newPassword = '';
        this.repeatPassword = '';

        this.checkTotalPassword = false;
        this.checkNumbersCharsPassword = false;
        this.checkSpecialCharPassword= false;
        this.passwordAccept = false;
    }

    private _generateWhatsappUrl(otpCode: string | null): string {
        const baseUrl = environment.chatAI.whatsappBaseUrl;
        const name = environment.chatAI.name;
        const defaultMessage = `${this._translateService.instant('common::messages.hello')} ${name}!`;
        const messageWithOtp = `${this._translateService.instant('common::messages.hello')} ${name}, ${this._translateService.instant('auth.msgMyAccessCodeIs')} #${otpCode} !`;
        const message = otpCode === null ? defaultMessage : messageWithOtp;
        const encodedMessage = encodeURIComponent(message);
        return `${baseUrl}?text=${encodedMessage}`;
    }
}
