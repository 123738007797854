import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { environment } from '#environment';
import * as _ from 'lodash';
import { catchError, finalize, skip, switchMap, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class UserPreferencesService {

    private changePreferences: BehaviorSubject<any> = new BehaviorSubject(undefined);
    private readonly;
    private destroy$ = new Subject<void>();

    private _dataTypes = {
        equals: { value: 'value', default: '' },
        in: { value: 'value', default: [] }
    };

    get changePreferences$() {
        return this.changePreferences.asObservable();
    }

    constructor(
        private _http: HttpClient,
        private _translateService: TranslateService

    ) {
        this._definReadOnly();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onLanguageOrPreferenceChange(onLangChangeCallback: () => void, onPreferencesChangeCallback: (typeChange: string) => void): Subscription {
        return this._translateService.onLangChange.pipe(
            switchMap(() => {
                onLangChangeCallback();
                return this.changePreferences$.pipe(skip(1));
            }),
            takeUntil(this.destroy$)
        ).subscribe((typeChange) => {
            onPreferencesChangeCallback(typeChange);
        });
    }

    defineFilter(userPreference, feature: string, featureFilter, filter) {
        if (userPreference.features && userPreference.features[feature]) {
            let featurePreferences = userPreference.features[feature];
            this.manageFilterData(featurePreferences).forEach((filterPreference) => {
                featureFilter[filterPreference.name] = filter ? filter[filterPreference.name] : _.cloneDeep(filterPreference.value);
            });
        }
        if (filter) {
            this.manageDefaultFilterData(featureFilter, filter);
        }

        // > /configurations/preferences
        if (userPreference.filters) {
            this.manageGlobalFilterData(userPreference.filters).forEach((filterPreference) => {
                featureFilter[filterPreference.name] = filter ? filter[filterPreference.name] : _.cloneDeep(filterPreference.value);
            });
        }
        return featureFilter;
    }

    manageDefaultFilterData(featureFilter, filter) {
        const filterList: any = Object.values(filter);
        filterList.forEach((filter) => { featureFilter.push({
                name: filter.name,
                value: this.resolveValue(filter),
        })});

    }

    manageFilterData(filters) {
        const filterList: any = Object.values(filters);
        let managedFilters = [];
        filterList.filter( filter => this._dataTypes[filter.content.type] )
            .forEach(filter => {
                managedFilters.push({
                    name: filter.name,
                    value: this.resolveValue(filter),
                })
            }
        );
        return managedFilters;
    }

    resolveValue(filter) {
        if (typeof filter.content.value === 'boolean') {
            return filter.content.value;
        }
        return filter.content.value || this._dataTypes[filter.content.type]?.default;
    }

    manageGlobalFilterData(filters) {
        const filterList: any = Object.values(filters);
        let managedFilters = [];
        filterList
            .filter( filter => filter.content.value )
            .forEach((filter) => { managedFilters.push({
            name: filter.name,
            value: filter.content.value,
        })});
        return managedFilters;
    }

    verifyFilterChanges(initialPoFilter, poFilter, feature, id, force?) {
        // Tratativa para o user-preference não manipular os usuários de automação
        if (this.readonly) {
            return
        }
        if (!_.isEqual(initialPoFilter, poFilter) || force) {
            this.setFilterPreference(feature, poFilter, id);
            return _.cloneDeep(poFilter);
        } else {
            return initialPoFilter;
        }
    }

    getDefaultFilterPreferences(): Observable<any> {
        let preferenceId = localStorage.getItem('preferenceId');
        if (preferenceId) {
            return this._http.get(`${environment.endpoints.userPreferencesService}/preferences/${preferenceId}`)
                .pipe(
                    catchError( error => {
                        localStorage.removeItem('preferenceId');
                        return this.getDefaultFilterPreferences();
                    })
                )
        } else {
            return this._http.get(`${environment.endpoints.userPreferencesService}/preferences/default`);
        }
    }

    updateSelectedPreference(preference: any) {
        localStorage.setItem('preferenceId', preference);
        window.location.reload();
    }

    getUserPreferences(): Observable<any> {
        return this._http.get(`${environment.endpoints.userPreferencesService}/preferences`);
    }

    setFilterPreference(feature, filters, id) {
        const request = this.formatDataForSave(filters);
        this._http.put(`${environment.endpoints.userPreferencesService}/preferences/${id}/${feature}`, request).subscribe(
            () => { }, () => { console.log('Erro ao salvar os filtros')
        });
    }

    savePreference(preference: any, change?: string): Observable<any>{
        if (preference?.id) {
            return this._http.put(`${environment.endpoints.userPreferencesService}/preferences/${preference.id}`, preference)
                .pipe(finalize( () => { this._emitChangePreference(change) }));
        } else {
            return this._http.post(`${environment.endpoints.userPreferencesService}/preferences`, preference)
                .pipe(finalize( () => { this._emitChangePreference(change) }));
        }
    }

    deletePreference(preference: any) {
        return this._http.delete(`${environment.endpoints.userPreferencesService}/preferences/${preference.id}`)
                .pipe(finalize( () => { this._emitChangePreference() }));
    }

    formatDataForSave(filters) {
        const request = { filters: { } }
        for (const [key, value] of Object.entries(filters)) {
            const valueType = Array.isArray(value) ? 'in' : 'equals';
            request.filters[key] = {
                name: key,
                content : {
                    type: valueType,
                    value,
                }
            }
        }
        return request;
    }

    private _emitChangePreference(change?: string) {
        this.changePreferences.next(change || true);
    }

    private _definReadOnly(){
        this.getUserPreferences().subscribe(userPreference => {
            this.readonly = userPreference[0].readOnly;
        });
    }
}
