import * as _ from 'lodash';
import { ReplaySubject } from 'rxjs';
import { IGridRow } from '../../interfaces';
import { ISelectionOption } from './selection-option.interface';
import { DEFAULT_SELECTION_OPTIONS } from './default-selection-options.constant';
import { is } from '@babel/types';
declare const Slick: any;

export class CheckboxSelection {
    selectedRowsChanged: ReplaySubject<IGridRow>;
    selectedAllRowsChanged: ReplaySubject<boolean>;
    disableSelectCollapsed: boolean;
    singleSelection: boolean;
    childSpecificCheckbox: boolean;
    onlyLeafSelectable: boolean;

    private _grid: any;
    private _gridContainerNode: any;
    private _handler = new Slick.EventHandler();
    private _selectedRowsLookup = {};
    private _selectionOptions: ISelectionOption[];
    private _dropdownSelectionOptions: ISelectionOption[];
    private _selectionOptionFieldPath: string;
    private _externalOptions: any = {
        hiddenHeader: false,
    };
    private _options: any = {
        columnId: '_checkbox_selector',
        headerCssClass: 'grid-header',
        cssClass: 'grid-cell',
        width: 45,
    };
    private _lastRowSelected;

    constructor(
        options,
        selectionOptions: ISelectionOption[],
        selectionOptionFieldPath: string,
        disableSelectCollapsed?: boolean,
        singleSelection?: boolean,
        childSpecificCheckbox?: boolean,
        onlyLeafSelectable?: boolean
    ) {
        if (selectionOptions && selectionOptions.length) {
            this._selectionOptions = selectionOptions;
            this._selectionOptionFieldPath = selectionOptionFieldPath;
        }
        if (options && options.hiddenHeader) this._externalOptions.hiddenHeader = options.hiddenHeader;
        this.selectedRowsChanged = new ReplaySubject();
        this.selectedAllRowsChanged = new ReplaySubject();
        this.disableSelectCollapsed = disableSelectCollapsed;
        this.singleSelection = singleSelection;
        this.childSpecificCheckbox = childSpecificCheckbox;
        this.onlyLeafSelectable = onlyLeafSelectable;
    }

    init(grid: any): void {
        this._grid = grid;
        this._gridContainerNode = $(this._grid.getContainerNode());
        this._handler
            .subscribe(this._grid.onSelectedRowsChanged, this._handleSelectedRowsChanged.bind(this))
            .subscribe(this._grid.onClick, this._handleClick.bind(this))
            .subscribe(this._grid.onHeaderClick, this._handleHeaderClick.bind(this))
            .subscribe(this._grid.onKeyDown, this._handleKeyDown.bind(this));

        $(document).bind('click', () => {
            this._closeSelectionDropdown();
        });

        if (this._selectionOptions) {
            this._selectionOptions = this._selectionOptions.filter((selectionOption: ISelectionOption) => {
                return this._grid
                    .getData()
                    .getItems()
                    .some(
                        (item: IGridRow) =>
                            this._resolveObjectPath(this._selectionOptionFieldPath, item) === selectionOption.code
                    );
            });

            if (_.uniqBy(this._grid.getData().getItems() || [], this._selectionOptionFieldPath).length > 1) {
                this._dropdownSelectionOptions = [...DEFAULT_SELECTION_OPTIONS, ...this._selectionOptions];

                const columns = this._grid.getColumns();
                columns[0].width = 60;

                this._grid.setColumns(columns);
                this._gridContainerNode.find('.selection-options-icon').toggleClass('visible');
                this._createSelectionDropdownTemplate();
            }
        }
    }

    destroy(): void {
        this._handler.unsubscribeAll();
    }

    getColumnDefinition() {
        return {
            id: this._options.columnId,
            name: this._getCheckboxSelectionHeaderTemplate(),
            field: 'checkboxSelection',
            width: this._options.width,
            headerCssClass: this._options.headerCssClass,
            cssClass: this._options.cssClass,
            formatter: this._checkboxSelectionFormatter.bind(this),
        };
    }

    updateSelectionOptions(newOptions: any): void {
        if (newOptions?.hiddenHeader) this._externalOptions.hiddenHeader = newOptions.hiddenHeader;
    }

    private _handleSelectedRowsChanged(e, args) {
        const selectedRows = this._grid.getSelectedRows();
        const lookup = {};

        for (let i = 0; i < selectedRows.length; i++) {
            const row = selectedRows[i];
            lookup[row] = true;

            if (lookup[row] !== this._selectedRowsLookup[row]) {
                this._grid.invalidateRow(row);
                delete this._selectedRowsLookup[row];
            }
        }

        for (const i in this._selectedRowsLookup) {
            this._grid.invalidateRow(i);
        }

        this._selectedRowsLookup = lookup;
        this._grid.render();

        if (selectedRows.length) {
            const gridDataLength = this._grid.getDataLength();
            const checkboxState = selectedRows.length === gridDataLength ? 'checked' : 'indeterminate';
            this._grid.updateColumnHeader(
                this._options.columnId,
                this._getCheckboxSelectionHeaderTemplate(checkboxState)
            );
        } else {
            this._grid.updateColumnHeader(this._options.columnId, this._getCheckboxSelectionHeaderTemplate());
        }

        this._updateSelectionDropdownState();
        this._onSelectedRowsChanged();
    }

    private _handleKeyDown(e, args) {
        if (e.which == 32) {
            if (this._grid.getColumns()[args.cell].id === this._options.columnId) {
                if (!this._grid.getEditorLock().isActive() || this._grid.getEditorLock().commitCurrentEdit()) {
                    this._toggleRowSelection(args.row);
                }
                e.preventDefault();
                e.stopImmediatePropagation;
            }
        }
    }

    private _handleClick(e, args) {
        const row = this._grid.getData().getItems()[args.row];
        if (
            this._grid.getColumns()[args.cell].id === this._options.columnId &&
            $(e.target).is(':checkbox') &&
            !row.selectBlocked
        ) {
            if (this._grid.getEditorLock().isActive() && !this._grid.getEditorLock().commitCurrentEdit()) {
                e.preventDefault();
                e.stopImmediatePropagation();
                return;
            }

            this._toggleRowSelection(args.row);
            e.stopPropagation();
            e.stopImmediatePropagation();
        }
    }

    private _toggleRowSelection(row) {
        const rowData = this._grid.getData().getItem(row);
    
        const lookupKey = this.onlyLeafSelectable ? rowData.childIndex : this.disableSelectCollapsed ? rowData.id : row;
    
        const isSelected = this._selectedRowsLookup[lookupKey];
    
        this._lastRowSelected = { row: rowData, type: isSelected ? 'unselect' : 'select' };
    
        this._grid.setSelectedRows(
            isSelected
                ? this._grid.getSelectedRows().filter((selectedRowId) => selectedRowId !== lookupKey)
                : this.singleSelection
                ? [lookupKey]
                : [...this._grid.getSelectedRows(), lookupKey]
        );
    }   

    private _handleHeaderClick(e, args) {
        if (args.column.id == this._options.columnId) {
            if ($(e.target).is(':checkbox')) {
                if (this._grid.getEditorLock().isActive() && !this._grid.getEditorLock().commitCurrentEdit()) {
                    e.preventDefault();
                    e.stopImmediatePropagation();
                    return;
                }

                if ($(e.target).is(':checked')) {
                    const rows = [];
                    for (let i = 0; i < this._grid.getDataLength(); i++) {
                        const row = this._grid.getData().getItems()[i];
                        if (!row.selectBlocked) {
                            rows.push(i);
                        }
                        if (!row.preSelectedBlock ) {
                            rows.push(i);
                        }
                    }
                    this.selectedAllRowsChanged.next(true);
                    this._grid.setSelectedRows(rows);
                } else {
                    this.selectedAllRowsChanged.next(false);
                    this._grid.setSelectedRows([]);
                }
            } else if ($(e.target).is('.selection-options-icon')) {
                $(e.target).toggleClass('rotate');
                this._gridContainerNode.find('.selection-dropdown').toggleClass('visible');
            }
        }

        e.stopPropagation();
        e.stopImmediatePropagation();
    }

    private _handleSelectionOption(e) {
        const selectionOption = e.target;
        const rows = [];

        if (selectionOption.id !== 'none') {
            for (const item of this._grid.getData().getItems()) {
                const index = this._grid.getData().getItems().indexOf(item);

                if (selectionOption.id === 'all') {
                    rows.push(index);
                } else if (this._resolveObjectPath(this._selectionOptionFieldPath, item) === selectionOption.id) {
                    rows.push(index);
                }
            }
        }

        this._closeSelectionDropdown();
        this._grid.setSelectedRows(rows);

        e.stopPropagation();
        e.stopImmediatePropagation();
    }

    private _onSelectedRowsChanged(): void {
        const gridItems = this._grid.getData().getItems();
        let selectedRows;
        if (this.onlyLeafSelectable) {
            selectedRows = gridItems.filter((item) => this._selectedRowsLookup[item.childIndex]);
        } else if (this.disableSelectCollapsed) {
            selectedRows = gridItems.filter((item) => this._selectedRowsLookup[item.id]);
        } else {
            selectedRows = gridItems.filter((_, index: number) => this._selectedRowsLookup[index]);
        }
    
        this.selectedRowsChanged.next({
            selectedRows,
            lastRowSelected: this._lastRowSelected,
        });
        this._lastRowSelected = undefined;
    }
    
    private _resolveObjectPath(path: string, obj: any = {}): any {
        return path.split('.').reduce((previousValue: any, currentValue: string) => {
            return previousValue ? previousValue[currentValue] : null;
        }, obj);
    }

    private _closeSelectionDropdown(): void {
        this._gridContainerNode.find('.selection-options-icon').removeClass('rotate');
        this._gridContainerNode.find('.selection-dropdown').removeClass('visible');
    }

    private _getCheckboxSelectionHeaderTemplate(checkboxState?: 'checked' | 'indeterminate'): string {
        const selectionOptionsIconTemplate = this._gridContainerNode
            ? this._gridContainerNode.find('.selection-options-icon').clone().wrap('<div/>').parent().html()
            : '<i class="material-icons selection-options-icon pointer">keyboard_arrow_down</i>';

        if (!checkboxState) {
            return `<div class="checkbox-selection-container"
                style="display: ${this._externalOptions.hiddenHeader ? 'none' : 'block'}">
                <label class="checkbox-selection-content">
                    <input
                        type="checkbox"
                        id="checkbox-selection-header"
                        class="checkbox-selection-header"
                    />
                    <span class="checkmark pointer"></span>
                </label>
                ${selectionOptionsIconTemplate}
            </div>`;
        } else {
            return `<div class="checkbox-selection-container"
                style="display: ${this._externalOptions.hiddenHeader ? 'none' : 'block'}">
                <label class="checkbox-selection-content">
                    <input
                        type="checkbox"
                        id="checkbox-selection-header"
                        class="checkbox-selection-header"
                        checked="checked"
                    />
                    <span class="checkmark ${checkboxState} pointer"></span>
                </label>
                ${selectionOptionsIconTemplate}
            </div>`;
        }
    }

    private _checkboxSelectionFormatter(row, cell, value, columnDef, dataContext) {
        const lookupKey = this.onlyLeafSelectable
            ? dataContext.childIndex
            : this.disableSelectCollapsed
            ? dataContext.id
            : row;
    
        const isSelected = this._selectedRowsLookup[lookupKey];
   
        if (this.onlyLeafSelectable && dataContext?.childSpecificCheckbox) {
            return ''; 
        }

        if (this.disableSelectCollapsed && (dataContext.alone && !dataContext.isLeaf)) {
            return '';
        }

        if (dataContext.preSelectedBlock) {
            return `
                <div class="checkbox-selection-container">
                    <label class="checkbox-selection-content">
                        <input
                            type="checkbox"
                            id="checkbox-selection-row-${lookupKey}"
                            class="checkbox-selection-row"
                            checked="checked"
                            disabled
                        />
                        <span class="checkmark checked pointer preSelectedBlocked"></span>
                    </label>
                </div>`;
        }
    
        return `
            <div class="checkbox-selection-container">
                <label class="checkbox-selection-content">
                    <input
                        type="checkbox"
                        id="checkbox-selection-row-${lookupKey}"
                        class="checkbox-selection-row"
                        ${dataContext.selectBlocked ? 'disabled' : ''}
                        ${isSelected ? 'checked="checked"' : ''}
                    />
                    <span class="checkmark ${isSelected ? 'checked' : ''} pointer ${
            dataContext.selectBlocked ? 'blocked' : ''
        }"></span>
                </label>
            </div>`;
    }
    
    

    private _updateSelectionDropdownState(): void {
        const selectionDropdownElement$ = this._gridContainerNode.find('.selection-dropdown');
        const rows = this._grid.getData().getItems();

        Array.from(selectionDropdownElement$.children()).forEach((selectionOptionElement$: any) => {
            $(selectionOptionElement$).removeClass('selected');

            if (selectionOptionElement$.id === 'all') {
                this._grid.getSelectedRows().length === this._grid.getDataLength() &&
                    $(selectionOptionElement$).toggleClass('selected');
            } else if (selectionOptionElement$.id === 'none') {
                !this._grid.getSelectedRows().length && $(selectionOptionElement$).toggleClass('selected');
            } else {
                const rowsBySelectionOption = rows.filter(
                    (row: IGridRow) =>
                        this._resolveObjectPath(this._selectionOptionFieldPath, row) === selectionOptionElement$.id
                );
                const selectedRowsBySelectionOption = rows.filter(
                    (row: IGridRow, index: number) =>
                        this._selectedRowsLookup[index] &&
                        this._resolveObjectPath(this._selectionOptionFieldPath, row) === selectionOptionElement$.id
                );

                rowsBySelectionOption.length === selectedRowsBySelectionOption.length &&
                    this._grid.getSelectedRows().length === rowsBySelectionOption.length &&
                    $(selectionOptionElement$).toggleClass('selected');
            }
        });
    }

    private _createSelectionDropdownTemplate(): void {
        let el$ = $('<ul></ul>').addClass('selection-dropdown');

        for (const selectionOption of this._dropdownSelectionOptions) {
            const description = `${selectionOption.description.charAt(0).toUpperCase()}${selectionOption.description
                .slice(1)
                .toLowerCase()}`;
            const itemEl$ = $('<li></li>')
                .attr('id', selectionOption.code)
                .addClass(`selection-dropdown-item ${selectionOption.code === 'none' ? 'selected' : ''}`)
                .bind('click', this._handleSelectionOption.bind(this))
                .append(document.createTextNode(description));

            el$.append(itemEl$);
        }

        this._gridContainerNode.append(el$);
    }
}
