import { UserCacheService } from '#services/_user/app-user-cache.service';
import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

type Placements = 'top' | 'right' | 'bottom' | 'left';

@Directive({
    selector: '[tooltip]',
})
export class CMXTooltipDirective {
    @Input('tooltip') tooltipTitle: string;
    @Input() placement?: Placements;
    @Input() nowrap?: boolean = false;
    @Input() delay?: number = 300;
    @Input() checkEllipsisActive?: boolean = false;
    @Input() size?: string = 'lg';

    private _tooltip: HTMLElement;

    constructor(
        private _el: ElementRef, 
        private _renderer: Renderer2,
        public userCacheService: UserCacheService,
    ) { }


    @HostListener('mouseenter') onMouseEnter() {
        if (this.tooltipTitle && !this._tooltip) {
            if ((this.checkEllipsisActive && this._isEllipsisActive()) || !this.checkEllipsisActive) {
                this._show();
                if (this.userCacheService.getUserDarkTheme()) {
                    this._setClass('dark-theme');
                }
            }
        }
    }

    @HostListener('mouseleave') onMouseLeave() {
        if (this._tooltip) {
            this._hide();
        }
    }

    @HostListener('click', ['$event'])
    onClick(event: any): void {
        if (this._tooltip) {
            this._hide();
        }
        event.stopPropagation();
    }

    private _show() {
        if (this._tooltip) {
            this._hide();
            setTimeout(() => { this._create() }, this.delay + 100);
        } else {
            this._create();
            this._setPosition();
            this._setArrowDirection();
            this._setClass('cmx-tooltip-show');
        }
    }

    private _hide() {
        this._renderer.removeClass(this._tooltip, 'cmx-tooltip-show');
        window.setTimeout(() => {
            if (this._tooltip){
                this._renderer.removeChild(document.body, this._tooltip);
                this._tooltip = null;
            }
        }, this.delay);
    }

    private _create() {
        this._tooltip = this._renderer.createElement('span');
        this._renderer.appendChild(this._tooltip, this._renderer.createText(this.tooltipTitle));
        this._renderer.appendChild(document.body, this._tooltip);
        this._setClass('cmx-tooltip');
        this._setClass(`cmx-tooltip-${this.placement}`);
        this._setClass(`${this.size}`);
        
        if (this.nowrap) {
            this._setClass('cmx-tooltip-nowrap'); 
        }
    
        this._setStyle('transition', `opacity ${this.delay}ms`);
        this._calculateMaxWidth();
        this._setArrowDirection();
        this._setPosition();
        this._setClass('cmx-tooltip-show');
    }

    private _setStyle(property: string, value: any): void {
        if (property === 'transition') {
            const browserCompatibilitiesPrefix = ['-webkit-', '-moz-', '-o-', ''];
            for (const browserPrefix of browserCompatibilitiesPrefix) {
                this._renderer.setStyle(this._tooltip, `${browserPrefix}${property}`, value);
            }
        } else {
            this._renderer.setStyle(this._tooltip, property, value);
        }
    }

    private _setClass(name: string): void {
        this._renderer.addClass(this._tooltip, name);
    }

    private _setPosition(): void {
        const hostPosition = this._el.nativeElement.getBoundingClientRect();
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        const offset = 10;

        this._calculateMaxWidth();
    
        const adjustedTooltipPosition = this._tooltip.getBoundingClientRect();
    
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
    
        const positionsByPlacement = {
            top: [
                hostPosition.top - adjustedTooltipPosition.height - offset,
                hostPosition.left + (hostPosition.width - adjustedTooltipPosition.width) / 2,
            ],
            right: [
                hostPosition.top + (hostPosition.height - adjustedTooltipPosition.height) / 2,
                hostPosition.right + offset,
            ],
            bottom: [
                hostPosition.bottom + offset,
                hostPosition.left + (hostPosition.width - adjustedTooltipPosition.width) / 2,
            ],
            left: [
                hostPosition.top + (hostPosition.height - adjustedTooltipPosition.height) / 2,
                hostPosition.left - adjustedTooltipPosition.width - offset,
            ],
        };
    
        const calculatedPosition = positionsByPlacement[this.placement];
    
        if (this.placement === 'left' && calculatedPosition[1] < 0) {
            calculatedPosition[1] = offset; 
        } 
     
        else if (this.placement === 'right' && (calculatedPosition[1] + adjustedTooltipPosition.width) > windowWidth) {
            calculatedPosition[1] = windowWidth - adjustedTooltipPosition.width - offset; 
        }
        
        if (this.placement === 'top' && calculatedPosition[0] < 0) {
            calculatedPosition[0] = offset;
        } 
     
        else if (this.placement === 'bottom' && (calculatedPosition[0] + adjustedTooltipPosition.height) > windowHeight) {
            calculatedPosition[0] = windowHeight - adjustedTooltipPosition.height - offset;
        }
    
        this._setStyle('top', `${calculatedPosition[0] + scrollPosition}px`);
        this._setStyle('left', `${calculatedPosition[1]}px`);
    }

    private _calculateMaxWidth() {
        const hostPosition = this._el.nativeElement.getBoundingClientRect();
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
    
        const spaceLeft = Math.floor(hostPosition.left) - 70;
        const spaceRight = Math.floor(windowWidth - hostPosition.right) - 70;
        const spaceTop = Math.floor(hostPosition.top) - 70;
        const spaceBottom = Math.floor(windowHeight - hostPosition.bottom) - 70;
    
        let maxWidth;
    
        if (!this.placement) {
            if (spaceRight >= 200) {
                this.placement = 'right';
            } else if (spaceLeft >= 200) {
                this.placement = 'left';
            } else if (spaceTop >= 200) {
                this.placement = 'top';
            } else if (spaceBottom >= 200) {
                this.placement = 'bottom';
            }
        }
    
        if (this.placement === 'top' || this.placement === 'bottom'|| this.placement === 'left' || this.placement === 'right')  {
            if (spaceRight < 100) { 
                this.placement = 'left'; 
            } else if (spaceLeft < 100) { 
                this.placement = 'right'; 
            }
        }

        switch (this.placement) {
            case 'top':
            case 'bottom':
                maxWidth = Math.min(spaceLeft, spaceRight); 
                break;
            case 'left':
                maxWidth = spaceLeft;
                break;
            case 'right':
                maxWidth = spaceRight;
                break;
        }
    
        this._setStyle('max-width', `${maxWidth}px`);
    }

    private _setArrowDirection(): void {
        const borderColor = this.userCacheService.getUserDarkTheme() ? '#16263F' : 'white';
        const borderBackground = this.userCacheService.getUserDarkTheme() ? '#16263F' : '#a0b4cb';
    
        const arrow = this._tooltip.querySelector('.tooltip-arrow');
        if (arrow) {
            this._renderer.removeChild(this._tooltip, arrow);
        }
    
        const grayArrowEl = this._renderer.createElement('div');
        this._renderer.addClass(grayArrowEl, 'tooltip-arrow');
        this._renderer.setStyle(grayArrowEl, 'position', 'absolute');
        this._renderer.setStyle(grayArrowEl, 'border-style', 'solid');
        this._renderer.setStyle(grayArrowEl, 'width', '0');
        this._renderer.setStyle(grayArrowEl, 'height', '0');
    
        const whiteArrowEl = this._renderer.createElement('div');
        this._renderer.addClass(whiteArrowEl, 'tooltip-arrow');
        this._renderer.setStyle(whiteArrowEl, 'position', 'absolute');
        this._renderer.setStyle(whiteArrowEl, 'border-style', 'solid');
        this._renderer.setStyle(whiteArrowEl, 'width', '0');
        this._renderer.setStyle(whiteArrowEl, 'height', '0');
    
        switch (this.placement) {
            case 'top':

                this._renderer.setStyle(grayArrowEl, 'top', '100%');
                this._renderer.setStyle(grayArrowEl, 'left', '50%');
                this._renderer.setStyle(grayArrowEl, 'transform', 'translateX(-50%)');
                this._renderer.setStyle(grayArrowEl, 'border-width', '7px 7px 0 7px');
                this._renderer.setStyle(grayArrowEl, 'border-color', `${borderBackground} transparent transparent transparent`);
    
                this._renderer.setStyle(whiteArrowEl, 'top', '100%');
                this._renderer.setStyle(whiteArrowEl, 'left', '50%');
                this._renderer.setStyle(whiteArrowEl, 'transform', 'translateX(-50%) translateY(-2px)');
                this._renderer.setStyle(whiteArrowEl, 'border-width', '5px 5px 0 5px');
                this._renderer.setStyle(whiteArrowEl, 'border-color', `${borderColor} transparent transparent transparent`);
                break;
    
            case 'right':

                this._renderer.setStyle(grayArrowEl, 'top', '50%');
                this._renderer.setStyle(grayArrowEl, 'left', '-7px');
                this._renderer.setStyle(grayArrowEl, 'transform', 'translateY(-50%)');
                this._renderer.setStyle(grayArrowEl, 'border-width', '7px 7px 7px 0');
                this._renderer.setStyle(grayArrowEl, 'border-color', `transparent ${borderBackground} transparent transparent`);
    
                this._renderer.setStyle(whiteArrowEl, 'top', '50%');
                this._renderer.setStyle(whiteArrowEl, 'left', '-5px');
                this._renderer.setStyle(whiteArrowEl, 'transform', 'translateY(-50%) translateX(2px)');
                this._renderer.setStyle(whiteArrowEl, 'border-width', '5px 5px 5px 0');
                this._renderer.setStyle(whiteArrowEl, 'border-color', `transparent ${borderColor} transparent transparent`);
                break;
    
            case 'bottom':

                this._renderer.setStyle(grayArrowEl, 'top', '-7px');
                this._renderer.setStyle(grayArrowEl, 'left', '50%');
                this._renderer.setStyle(grayArrowEl, 'transform', 'translateX(-50%)');
                this._renderer.setStyle(grayArrowEl, 'border-width', '0 7px 7px 7px');
                this._renderer.setStyle(grayArrowEl, 'border-color', `transparent transparent ${borderBackground} transparent`);

                this._renderer.setStyle(whiteArrowEl, 'top', '-5px');
                this._renderer.setStyle(whiteArrowEl, 'left', '50%');
                this._renderer.setStyle(whiteArrowEl, 'transform', 'translateX(-50%) translateY(2px)');
                this._renderer.setStyle(whiteArrowEl, 'border-width', '0 5px 5px 5px');
                this._renderer.setStyle(whiteArrowEl, 'border-color', `transparent transparent ${borderColor} transparent`);
                break;
    
            case 'left':

                this._renderer.setStyle(grayArrowEl, 'top', '50%');
                this._renderer.setStyle(grayArrowEl, 'right', '-7px');
                this._renderer.setStyle(grayArrowEl, 'transform', 'translateY(-50%)');
                this._renderer.setStyle(grayArrowEl, 'border-width', '7px 0 7px 7px');
                this._renderer.setStyle(grayArrowEl, 'border-color', `transparent transparent transparent ${borderBackground}`);
    
                this._renderer.setStyle(whiteArrowEl, 'top', '50%');
                this._renderer.setStyle(whiteArrowEl, 'right', '-5px');
                this._renderer.setStyle(whiteArrowEl, 'transform', 'translateY(-50%) translateX(-2px)');
                this._renderer.setStyle(whiteArrowEl, 'border-width', '5px 0 5px 5px');
                this._renderer.setStyle(whiteArrowEl, 'border-color', `transparent transparent transparent ${borderColor}`);
                break;
        }
    
        this._renderer.appendChild(this._tooltip, grayArrowEl);  
        this._renderer.appendChild(this._tooltip, whiteArrowEl);
    }     

    private _isEllipsisActive(): boolean {
        return this._el.nativeElement.offsetWidth < this._el.nativeElement.scrollWidth;
    }
}
