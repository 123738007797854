import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '#environment';
import { IOperationalAlertClientResponse, IOperationalAlertClientsResponse, IOperationalAlertsResponse } from 'app/shared/interfaces/operational-alerts.interface';


@Injectable()
export class OperationalAlertsService {
    private _baseUrl: string;

    constructor(private _http: HttpClient) {
        this._baseUrl = `${environment.endpoints.importationMongoDBService}/operational-alerts`;
    }

    public setBaseUrl(baseUrl: string) {
        this._baseUrl = `${baseUrl}/operational-alerts`;
    }

    getOperationalAlerts(params): Observable<any> {
        return this._http.get<IOperationalAlertsResponse>(this._getGeneratedLinkEndpoint(), { ...params });
    }

    getOperationalAlertClients(link: string, params): Observable<any> {
        return this._http.get<IOperationalAlertClientsResponse>(this._getGeneratedLinkEndpoint(link), { ...params });
    }

    getOperationalAlertClient(link: string, params): Observable<any> {
        return this._http.get<IOperationalAlertClientResponse>(this._getGeneratedLinkEndpoint(link), { ...params });
    }

    private _getGeneratedLinkEndpoint(link?: string): string {
        return link ? `${this._baseUrl}${link}` : this._baseUrl;
    }
}
