import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { NewCostsComponent } from './costs.component';
import { LayoutModule } from 'app/theme/layouts/layout.module';
import { SharedModule } from 'app/shared/shared.module';
import { ConsolidatedCostsComponent } from './consolidated/costs-consolidated.component';
import { DetailedCostsComponent } from './detailed/costs-detailed.component';
import { CostsCustomizationComponent } from './detailed/costs-customization/costs-customization.component';
import { CostsService } from './consolidated/costs.service';
import { SlickTabsModule } from 'app/shared/components/cmx-slick-tabs-modal/cmx-slick-tabs-modal.module';
import { CostsFiltersComponent } from './filters/costs-filters.component';
import { TranslateModule } from '@ngx-translate/core';

const routes: Routes = [
    { path: '', component: NewCostsComponent },
    { path: ':page', component: NewCostsComponent },
    { path: ':page/:subPage', component: NewCostsComponent },
];

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule.forChild(routes),
        FormsModule,
        LayoutModule,
        SharedModule,
        SlickTabsModule
    ],
    declarations: [
        NewCostsComponent,
        ConsolidatedCostsComponent,
        DetailedCostsComponent,
        CostsCustomizationComponent,
        CostsFiltersComponent
    ],
    providers: [CostsService],
})
export class NewCostsModule { }
