import { environment } from '#environment';
import { AmCharts5Service, IChartConfig } from '#services/_charts/charts.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class CustomWidgetsService {

    previewConfig: IChartConfig | any = { 
        chartId: 'previewChart',
        chartType: 'XYChart',
        orientation: 'vertical',
        axisTitle: { x: '', y: '' },
        axis: [
            { axisPosition: 'x', categoryField: 'xField1' },
            { axisPosition: 'y', categoryField: 'yField1' }
        ],
        content: [{
            name: '',
            valueXField: 'xField1',
            valueYField: 'yField1',
        }],
        showLegend: false,
        showTooltip: true,
        showValuesAboveBars: false,
        changeColors: true,
    }
    previewData: any = [{ xField1: '', yField1: 0 }];

    constructor(
        private _http: HttpClient,
        private _chartsService: AmCharts5Service,
    ) { }

    getCustomWidgetList(): Observable<any> {
        return this._http.get(`${environment.endpoints.customWidgetsService}/widget-configuration`)
    }

    getCustomWidgetData(chartConfig, filter): Observable<any> {
        return this._http.post(`${environment.endpoints.importationMongoDBService}/v1/custom-dashboard`, chartConfig, filter)
    }

    saveCustomWidget(request): Observable<any> {
        return this._http.post(`${environment.endpoints.customWidgetsService}/widget-configuration`, request)
    }

    getPathList(): Observable<any> {
        return this._http.get(`${environment.endpoints.customWidgetsService}/widget-configuration/fields`)
    }

    getUserShareList(clientGroupCodeList) {
        const headers = new HttpHeaders();
        let params = new HttpParams();
        params = params.append('excludeDeleted', 'true')
        params = params.append('onlyActive', 'true')
        params = params.append('ignoreGroupAll', 'false')
        params = params.append('clientGroup', clientGroupCodeList);
        return this._http.get(`${environment.endpoints.adminService}/users`, { params, headers });
    }

    updatePreviewChart(update: 'xAxisName' | 'yAxisName' | 'data' | string, form, data?) {
        switch (update) {
            case 'xAxisName':
                this.previewConfig.axisTitle.x = form?.get('xAxisName')?.value ? form?.get('xAxisName')?.value : 
                    form?.get('xType')?.value?.length ? form?.get('xType')?.value[0]?.itemName : null
                break;
            case 'yAxisName':
                this.previewConfig.axisTitle.y = form?.get('yAxisName')?.value ? form?.get('yAxisName')?.value :
                    form?.get('yType')?.value?.length ? form?.get('yType')?.value[0]?.itemName : null
                break;
            case 'data':
                if (!data?.length) { this.previewData = [{ xField1: '', yField1: 0 }]; break; }
                if (data?.length) {
                    this.previewConfig.showLegend = true;
                    this.previewData = [];
                    this.previewConfig.content = [];
                    this.previewConfig.referenceLine = null;
                    const instances = form?.get('xPeriod')?.value ? form?.get('xPeriod')?.value[0]?.id :
                        form?.get('yPeriod')?.value ? form?.get('yPeriod')?.value[0]?.id : '0';
                    this.previewData = Array.from({ length: parseInt(instances) }, () => ({}));
                    this.previewConfig.colors = [],
                    data.forEach((line, dataIndex) => {
                        this.previewConfig.content.push({
                            name: line.dataName,
                            valueXField: `xField${dataIndex + 1}`,
                            valueYField: `yField${dataIndex + 1}`,
                            prefix: line?.prefix, suffix: line?.suffix,
                            seriesType: line.graphType === 'LINE' ? 'line' : 'column',
                            color: line.dataColor
                        })
                        for (let i = 0; i < parseInt(instances); i++) {
                            this.previewData[i][`xField${dataIndex + 1}`] = i;
                            this.previewData[i][`yField${dataIndex + 1}`] = Math.random();
                        }
                        if (line.referenceLine && line.referenceLine !== 'Não') {
                            this.previewConfig.referenceLine = { value: 0.5 }
                        }
                    });
                }
                break;
        }
        const xTypeId = form?.get('xType')?.value?.[0]?.id;
        const yTypeId = form?.get('yType')?.value?.[0]?.id;
        this.previewConfig.orientation = (!this.isPeriod(xTypeId) && this.isPeriod(yTypeId)) ?
            'horizontal' : 'vertical';
        setTimeout(() => {
            this._chartsService.createChart(this.previewConfig, { data: this.previewData });
        }, 100);
    }

    getValue(form, field: string) {
        return form?.get(field)?.value;
    }

    getDropdownValue(form, field: string, type: 'id' | 'itemName') {
        return form?.get(field)?.value?.length ? form?.get(field)?.value[0]?.[type] : null;
    }

    isPeriod(value: string): boolean {
        return ['DAILY', 'WEEKLY', 'MONTHLY', 'YEAR'].includes(value?.toUpperCase());
    }
}