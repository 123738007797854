import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { FormTabService } from '#services/_form-tab/form-tab.service';
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'body',
    templateUrl: './app.component.html',
    encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
    globalLoader = false;

    constructor(
        private _formTabService: FormTabService,
        private config: PrimeNGConfig,
        private _router: Router,
        private _translateService: TranslateService
    ) {
        const language = localStorage.getItem('language') || 'pt-BR';
        this._translateService.setDefaultLang(language);
    }

    ngOnInit() {
        this._formTabService.identifyTabClick();
        this._router.events.subscribe((route) => {
            if (route instanceof NavigationStart) {
                this.globalLoader = true;
            }
            if (route instanceof NavigationEnd) {
                this.globalLoader = false;
            }
            if (route instanceof NavigationCancel) {
                this.globalLoader = false;
            }
        });

        this.config.setTranslation({
            dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sabado'],
            dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
            dayNamesMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
            monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
            monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun','Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        });
    }
}
