import { HttpHeaders, HttpParams } from '@angular/common/http';
import { AppModule } from 'app/app.module';
import moment from 'moment';
import * as _ from 'lodash';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { ILeadTimeFilter } from './interface/lead-time.interface';

export class LeadTimeFilter {

    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);

    private _initiaLeadTimeFilter: ILeadTimeFilter = <ILeadTimeFilter>{};
    private _leadTimeFilter: ILeadTimeFilter = <ILeadTimeFilter>{
        clientGroups: [],
        chartType: 'XYChart',
        selectedKpi: [],
    };

    private startDate;
    private endDate;
    private periodType: string;

    constructor(filter?: LeadTimeFilter) {
        this._leadTimeFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'leadTimeFilter', this._leadTimeFilter, filter);
        this._initiaLeadTimeFilter = _.cloneDeep(this._leadTimeFilter);
        this.periodType = filter ? filter.periodType : null;
        this.startDate = filter ? filter.startDate : moment().format('01/YYYY');
        this.endDate = filter ? filter.endDate : moment().format('MM/YYYY');
    }

    getClientGroups() {
        return this._leadTimeFilter.clientGroups;
    }
    getStartDate() {
        return this.startDate;
    }
    getEndDate() {
        return this.endDate;
    }
    getChartType() {
        return this._leadTimeFilter.chartType;
    }
    getSelectedKpi() {
        return this._leadTimeFilter.selectedKpi;
    }
    getPeriodType(): string {
        return this.periodType;
    }

    setClientGroups(clientGroups): void {
        this._leadTimeFilter.clientGroups = clientGroups;
    }
    setStartDate(startDate): void {
        this.startDate = startDate;
    }
    setEndDate(endDate): void {
        this.endDate = endDate;
    }
    setChartType(chartType): void {
        this._leadTimeFilter.chartType = chartType;
    }
    setSelectedKpi(selectedKpi): void {
        this._leadTimeFilter.selectedKpi = selectedKpi;
    }
    setPeriodType(periodType): void {
        this.periodType = periodType;
    }

    getLeadTimeRequest() {
        this.savePreferences();

        let headers = new HttpHeaders();
        let params = new HttpParams();
        headers = headers.set('X-Requested-Client-Groups', this.getClientGroups()?.map((c) => c['id']).join(','));
        params = params.append('kpis', this.getSelectedKpi().map((kpi) => kpi.id).join(','));
        params = params.append('startYearMonth', moment(this.startDate, 'MM/YYYY').format('YYYY-MM'));
        params = params.append('endYearMonth', moment(this.endDate, 'MM/YYYY').format('YYYY-MM'));
        if (this.getPeriodType()) params = params.append('periodType', this.getPeriodType());

        return { headers, params };
    }

    getKpiRequest() {
        this.savePreferences();
        let headers = new HttpHeaders();
        let params = new HttpParams();
        headers = headers.set('X-Requested-Client-Groups', this.getClientGroups()?.map((c) => c['id']).join(','));
        return { headers, params };
    }

    savePreferences() {
        this._initiaLeadTimeFilter = this._userPreferencesService.verifyFilterChanges(
            this._initiaLeadTimeFilter, this._leadTimeFilter, 'leadTimeFilter', this._userCacheService.getUserPreferences().id);
    }
}
