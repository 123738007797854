import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER_PROVIDER } from './app-initializer.provider';

import { ChartsServices } from './theme/layouts/charts/charts.services';

import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from './auth/auth.module';
import { DragulaModule } from 'ng2-dragula/ng2-dragula';
import { SVGModule } from './svg/svg.module';
import { ExternalModule } from './theme/pages/external/external.module';
import { LayoutModule } from './theme/layouts/layout.module';
import { OperationalModule } from './theme/layouts/operational/operational.module';
import { AdmModule } from './theme/pages/adm-panel/adm-panel.module';
import { AsyncDownloadsModule } from './theme/pages/async-download/async-downloads.module';
import { ConfigurationModule } from './theme/pages/configuration/configurations.module';
import { BalanceSheetModule } from './theme/pages/default/balance-sheet/balance-sheet.module';
import { CustomDashboardModule } from './theme/pages/default/custom-dashboard/custom-dashboard.module';
import { MaquinaRateioModule } from './theme/pages/default/maquina-rateio/maquina-rateio.module';
import { NewFeaturesModule } from './theme/pages/default/new-features/new-features.module';
import { PermissionInfoModule } from './theme/pages/default/permission-info/permission-info.module';
import { FinanceModule } from './theme/pages/finance/finance.module';
import { TechnicalIntegrationsModule } from './theme/pages/technical-integrations/technical-integrations.module';
import { OldReportsModule } from './theme/pages/reports/reports.module';
import { SupplyChainModule } from './theme/pages/supply-chain/supply-chain.module';
import { ThemeRoutingModule } from './theme/theme-routing.module';
import { FreightRatesModule } from './theme/pages/quotation/freight-rates.module'; // Verificar remover na sprint 107
import { LocationsModule} from './theme/pages/quotation/locations.module';

import { AppComponent } from './app.component';
import { ThemeComponent } from './theme/theme.component';
import { SharedServicesModule } from '#services/services.module';
import { InterceptorsModule } from './interceptors/interceptors.module';
import { ImportDetailsService } from './theme/pages/import-details/import-details.service';
import { DocumentService } from './modules/documents/pages/document/services/document.service';
import { NewCostsModule } from './theme/pages/costs/new-costs.module';
import { CommercialPremiseService } from './modules/financial/pages/premise/premise-detail/premise-detail.service';
import { FormTabService } from '#services/_form-tab/form-tab.service';


@NgModule({
    declarations: [ThemeComponent, AppComponent],
    imports: [
        BrowserModule,
        HttpClientModule,
        InterceptorsModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        CommonModule,
        LayoutModule,
        ThemeRoutingModule,
        AuthModule,
        PermissionInfoModule,
        AdmModule,
        OldReportsModule,
        SVGModule,
        AsyncDownloadsModule,
        NewFeaturesModule,
        SharedServicesModule,
        // CMX
        BalanceSheetModule,
        MaquinaRateioModule,
        SupplyChainModule,
        FinanceModule,
        DragulaModule,
        CustomDashboardModule,
        OperationalModule,
        ExternalModule,
        ConfigurationModule,
        TechnicalIntegrationsModule,
        FreightRatesModule,
        LocationsModule,
        NewCostsModule
    ],
    providers: [
        ChartsServices,
        APP_INITIALIZER_PROVIDER,
        ImportDetailsService,
        DocumentService,
        CommercialPremiseService,
        FormTabService
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    static injector: Injector;
    constructor(injector: Injector) {
        AppModule.injector = injector;
    }
}
