import { Injectable } from '@angular/core';
import { DomainService } from '#services/_domain/domain.service';
import { FeatureFlagService } from '#services/_feature-flag/feature-flag-service';
import { UserPreferencesService } from './user-preferences.service';
import { UserService } from "app/auth/_services/user.service";
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

export interface IClientGroup {
    code: string, name: string, type: string, trader: object, clients: { name: string, code: string, type: string }[]
}

export interface ISubsidiary {
    code: string, name: string, documentName: string, urfCode: string
}

export interface IProfitCenter {
    code: string, name: string, type: string
}

export interface ICompany {
    code: string, name: string, type: string
}

export interface ITrader {
    code: string, name: string, type: string, director: object, clientGroups: { name: string, code: string, type: string }[]
}

export interface IDirector {
    code: string, name: string, type: string, traders: { name: string, code: string, type: string }[]
}

@Injectable()
export class UserCacheService {
    private _clientGroups: IClientGroup[];
    private _subsidiaries: ISubsidiary[];
    private _profitCenters: IProfitCenter[];
    private _companies: ICompany[];
    private _traders: ITrader[];
    private _directors: IDirector[];
    private _modals: any[];
    private _userPreferences;

    constructor(
        private _domainService: DomainService,
        private _featureFlagService: FeatureFlagService,
        private _userPreferencesService: UserPreferencesService,
        private _userService: UserService,
        private _translateService: TranslateService
    ) { }

    async Init() {
        await this.checkCache();
    }

    getClientGroups(): IClientGroup[] { return this._clientGroups || [] }
    getSubsidiaries(): ISubsidiary[] { return this._subsidiaries || [] }
    getProfitCenters(): IProfitCenter[] { return this._profitCenters || [] }
    getCompanies(): ICompany[] { return this._companies || [] }
    getTraders(withoutDirector?: boolean): ITrader[] { return withoutDirector ? this._removeDirectorFromTrader(this._traders) : this._traders || [] }
    getDirectors(): IDirector[] { return this._directors || [] }
    getModals(): any[] { return this._prepareModals(this._modals) || [] }
    getUserPreferences() { return this._userPreferences }

    getUserDarkTheme(): boolean {
        const internalUser = this._userService.getCurrentUser().has('INTERNAL_FILTERS');
        return !internalUser ? false : typeof this._userPreferences.darkTheme === 'boolean' ? this._userPreferences.darkTheme : true;
    }

    setUserDarkTheme(darkTheme: boolean): void {
        this._userPreferences.darkTheme = darkTheme;
    }

    getDefaultUserPreference(): string {
        return this._userPreferences.id || null;
    }

    getLanguage() {
        const language = this._userPreferences?.language || localStorage.getItem('language') || this._translateService.defaultLang || 'pt-BR';
        return language;
    }

    setLanguage(language: string) {
        this._translateService.use(language);
        localStorage.setItem('language', language);
        this._userPreferences.language = language;
    }

    private async checkCache() {
        if (!this._clientGroups || !this._clientGroups.length) {
            await this.loadClientGroups();
        }
        if (!this._subsidiaries || !this._subsidiaries.length) {
            await this.loadSubsidiaries();
        }
        if (!this._profitCenters || !this._profitCenters.length) {
            await this.loadProfitCenters();
        }
        if (!this._companies || !this._companies.length) {
            await this.loadCompanies();
        }
        if (!this._traders || !this._traders.length) {
            await this.loadTraders();
        }
        if (!this._directors || !this._directors.length) {
            await this.loadDirectors();
        }
        if (!this._modals || !this._modals.length) {
            await this.loadModals();
        }
        if (!this._userPreferences) {
            await this.loadUserPreferences();
        }
        if (!this._featureFlagService.getEnabledList() || !this._featureFlagService.getEnabledList().length) {
            await this.loadEnabledFeatureFlag();
        }
    }

    private async loadClientGroups() {
        return new Promise<IClientGroup[]>((resolve, reject) => {
            if (sessionStorage.getItem('clientGroups') && sessionStorage.getItem('clientGroups') !== 'undefined') {
                this._clientGroups = JSON.parse(sessionStorage.getItem('clientGroups') || '{}');
                resolve(this._clientGroups);
            } else if (this._clientGroups && this._clientGroups.length) {
                resolve(this._clientGroups);
            } else {
                this._domainService.getClientGroup().subscribe(
                    (clientGroups: IClientGroup[]) => {
                        sessionStorage.setItem('clientGroups', JSON.stringify(clientGroups));
                        this._clientGroups = clientGroups;
                        resolve(clientGroups);
                    },
                    (error) => {
                        reject(error);
                    }
                );
            }
        });
    }

    private async loadSubsidiaries() {
        return new Promise<ISubsidiary[]>((resolve, reject) => {
            if (sessionStorage.getItem('subsidiaries') && sessionStorage.getItem('subsidiaries') !== 'undefined') {
                this._subsidiaries = JSON.parse(sessionStorage.getItem('subsidiaries') || '{}');
                resolve(this._subsidiaries);
            } else if (this._subsidiaries && this._subsidiaries.length) {
                resolve(this._subsidiaries);
            } else {
                this._domainService.getSubsidiary().subscribe(
                    (subsidiaries: ISubsidiary[]) => {
                        sessionStorage.setItem('subsidiaries', JSON.stringify(subsidiaries));
                        this._subsidiaries = subsidiaries;
                        resolve(subsidiaries);
                    },
                    (error) => {
                        reject(error);
                    }
                );
            }
        });
    }

    private async loadProfitCenters() {
        return new Promise<IProfitCenter[]>((resolve, reject) => {
            if (sessionStorage.getItem('profitCenters') && sessionStorage.getItem('profitCenters') !== 'undefined') {
                this._profitCenters = JSON.parse(sessionStorage.getItem('profitCenters') || '{}');
                resolve(this._profitCenters);
            } else if (this._profitCenters && this._profitCenters.length) {
                resolve(this._profitCenters);
            } else {
                this._domainService.getProfitCenter().subscribe(
                    (profitCenters: IProfitCenter[]) => {
                        sessionStorage.setItem('profitCenters', JSON.stringify(profitCenters));
                        this._profitCenters = profitCenters;
                        resolve(profitCenters);
                    },
                    (error) => {
                        reject(error);
                    }
                );
            }
        });
    }

    private async loadTraders() {
        return new Promise<ITrader[]>((resolve, reject) => {
            if (sessionStorage.getItem('traders') && sessionStorage.getItem('traders') !== 'undefined') {
                this._traders = JSON.parse(sessionStorage.getItem('traders') || '{}');
                resolve(this._traders);
            } else if (this._traders && this._traders.length) {
                resolve(this._traders);
            } else {
                this._domainService.getTrader().subscribe(
                    (traders: ITrader[]) => {
                        sessionStorage.setItem('traders', JSON.stringify(traders));
                        this._traders = traders;
                        resolve(traders);
                    },
                    (error) => {
                        reject(error);
                    }
                );
            }
        });
    }

    private async loadDirectors() {
        return new Promise<IDirector[]>((resolve, reject) => {
            if (sessionStorage.getItem('directors') && sessionStorage.getItem('directors') !== 'undefined') {
                this._directors = JSON.parse(sessionStorage.getItem('directors') || '{}');
                resolve(this._directors);
            } else if (this._directors && this._directors.length) {
                resolve(this._directors);
            } else {
                this._domainService.getDirector().subscribe(
                    (directors: IDirector[]) => {
                        sessionStorage.setItem('directors', JSON.stringify(directors));
                        this._directors = directors;
                        resolve(directors);
                    },
                    (error) => {
                        reject(error);
                    }
                );
            }
        });
    }

    private async loadModals() {
        return new Promise<any[]>((resolve, reject) => {
            if (sessionStorage.getItem('modals') && sessionStorage.getItem('modals') !== 'undefined') {
                this._modals = JSON.parse(sessionStorage.getItem('modals') || '{}');
                resolve(this._modals);
            } else if (this._modals && this._modals.length) {
                resolve(this._modals);
            } else {
                this._domainService.getModal().subscribe(
                    (modals: any[]) => {
                        sessionStorage.setItem('modals', JSON.stringify(modals));
                        this._modals = modals;
                        resolve(modals);
                    },
                    (error) => {
                        reject(error);
                    }
                );
            }
        });
    }

    private async loadCompanies() {
        return new Promise<ICompany[]>((resolve, reject) => {
            if (sessionStorage.getItem('companies') && sessionStorage.getItem('companies') !== 'undefined') {
                this._companies = JSON.parse(sessionStorage.getItem('companies') || '{}');
                resolve(this._companies);
            } else if (this._companies && this._companies.length) {
                resolve(this._companies);
            } else {
                this._domainService.getCompanies().subscribe(
                    (companies: ICompany[]) => {
                        sessionStorage.setItem('companies', JSON.stringify(companies));
                        this._companies = companies;
                        resolve(companies);
                    },
                    (error) => {
                        reject(error);
                    }
                );
            }
        });
    }

    private async loadUserPreferences() {
        return new Promise<any>((resolve, reject) => {
            if (this._userPreferences) {
                resolve(this._userPreferences);
            } else {
                this._userPreferencesService.getDefaultFilterPreferences().subscribe((filters) => {
                        this._userPreferences = filters;
                        resolve(filters);
                    },
                    (_error) => {
                        // Ignorando erro em caso de incidente com user preference.
                        this._userPreferences = {};
                        resolve({});
                    }
                );
            }
        });
    }

    private async loadEnabledFeatureFlag() {
        return new Promise<any>((resolve, reject) => {
            if (this._featureFlagService.getEnabledList() && this._featureFlagService.getEnabledList().length) {
                resolve(this._featureFlagService.getEnabledList());
            } else {
                this._featureFlagService.getFeatureFlagEnabled().subscribe(
                    (enabledList) => {
                        this._featureFlagService.setEnabledList(enabledList);
                        resolve(enabledList);
                    },
                    (error) => {
                        reject(error);
                    }
                );
            }
        });
    }

    private _removeDirectorFromTrader(traderList: any[]) {
        return _.uniqBy(traderList?.map((trader) => { return { ...trader,
            name: trader?.name?.replace(new RegExp(String.raw`[^0-9a-zA-Z]*\(.*?$`, "g"), '')?.trim()
        }}), 'name');
    }

    private _prepareModals(modalList: any[], includeRail?: boolean) {
        if (!includeRail) { modalList = modalList.filter((modal) => modal?.id !== "RAIL") }
        return modalList.map((modal) => {
            return { ...modal, itemName: modal?.name, code: modal?.id, id: `assets/img/icons/${modal?.id?.toLowerCase()}.png` }
        })
    }
}
