import { Component, Input, Output, EventEmitter, ElementRef, ViewChild, AfterViewInit, AfterViewChecked, HostListener, Renderer2 } from '@angular/core';
import ResizeObserver from 'resize-observer-polyfill';

@Component({
    selector: 'cmx-summary-line',
    templateUrl: './cmx-summary-line.component.html',
    styleUrls: ['./cmx-summary-line.component.scss'],
})
export class SummaryLineComponent implements AfterViewInit, AfterViewChecked {
    @Input() title?: string;
    @Input() dataList: object[] = [];
    @Input() fontSize?: string = '12px';
    @Input() marginLeft?: string = '0px';
    @Input() switchList?: string[] = [];

    @Output('onSwitch') onSwitch = new EventEmitter();

    @ViewChild('summaryScrollContainer', { static: false }) scrollContainer: ElementRef;

    selectedSwitch;
    containerWidth: number = 0;
    dataListWidth: number = 0;
    dataListInitialized: boolean = false;
    dataExceedsContainer: boolean = false;

    constructor(private renderer: Renderer2) {}

    ngAfterViewInit() {
        this.selectedSwitch = this.switchList.length ? this.switchList[0] : null;

        const resizeObserver = new ResizeObserver(() => {
            this.updateWidths(); 
        });
        resizeObserver.observe(this.scrollContainer.nativeElement);
    }

    ngAfterViewChecked() {
        if (!this.dataListInitialized) {
            this.updateWidths();
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.updateWidths();
    }

    updateWidths() {
        const newDataListWidth = this.getDataListWidth();
        const windowWidth = window.innerWidth;

        if (newDataListWidth > 0) {
            this.containerWidth = Math.round(windowWidth) - 110;
            this.dataListWidth = Math.round(newDataListWidth);
            this.dataListInitialized = true;
            this.dataExceedsContainer = this.dataListWidth > this.containerWidth;

            if (this.dataExceedsContainer) {
                this.renderer.setStyle(this.scrollContainer.nativeElement, 'width', '100%');
                this.renderer.removeStyle(this.scrollContainer.nativeElement, 'border-top-right-radius');
                this.renderer.removeStyle(this.scrollContainer.nativeElement, 'border-bottom-right-radius');
            } else {
                this.renderer.setStyle(this.scrollContainer.nativeElement, 'width', 'fit-content');
                this.renderer.setStyle(this.scrollContainer.nativeElement, 'border-top-right-radius', '20px');
                this.renderer.setStyle(this.scrollContainer.nativeElement, 'border-bottom-right-radius', '20px');
            }
        }
    }

    scrollLeft() {
        const scrollContainer = this.scrollContainer.nativeElement;
        scrollContainer.scrollBy({
            left: -300,
            behavior: 'smooth'
        });
    }

    scrollRight() {
        const scrollContainer = this.scrollContainer.nativeElement;
        scrollContainer.scrollBy({
            left: 300,
            behavior: 'smooth'
        });
    }

    changeSwitch() {
        this.selectedSwitch = this.switchList[0] === this.selectedSwitch ? this.switchList[1] : this.switchList[0];
        this.onSwitch.emit(this.selectedSwitch);
    }

    detectScroll() {
        const container = this.scrollContainer.nativeElement;
        if (container.scrollWidth > container.clientWidth) {
            container.style.borderRadius = '0 20px 20px 0';
        } else {
            container.style.borderRadius = '20px';
        }
    }

    getDataListWidth(): number {
        const container = this.scrollContainer.nativeElement;
        const dataItems = container.querySelectorAll('.data');

        if (dataItems.length === 0) {
            return 0;
        }

        let totalWidth = 0;
        dataItems.forEach((item) => {
            const itemWidth = item.getBoundingClientRect().width;
            totalWidth += itemWidth;
        });

        return totalWidth;
    }
}
