import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '#environment';
import {  from, Observable } from 'rxjs';
import * as _ from 'lodash';
import * as moment from 'moment';
import { DownloadService } from '#services/_download/download.service';
import { FeatureFlagService } from '#services/_feature-flag/feature-flag-service';

@Injectable()
export class BoardingCrudService {
    private _queryBaseUrl: String;
    private _commandBaseUrl: String;
    private isFeatureEnabled: boolean = false;
    private VEHICLE_TYPES = [
        { code: '01', name: 'Indefinido' },
        { code: '02', name: 'Chassi' },
        { code: '03', name: 'Furgão' },
        { code: '04', name: 'Micrô-Ônibus' },
        { code: '05', name: 'XC40' },
        { code: '06', name: 'XC60' },
        { code: '07', name: 'XC90' },
        { code: '08', name: 'S60' },
        { code: '09', name: 'S90' },
        { code: '10', name: 'V60' },
        { code: '11', name: 'Hillux' },
        { code: '12', name: 'SW4' },
        { code: '13', name: '540C COUPE' },
        { code: '14', name: '570GT' },
        { code: '15', name: '570S COUPE' },
        { code: '16', name: '570S SPIDER' },
        { code: '17', name: '600LT COUPE' },
        { code: '18', name: '600LT SPIDER' },
        { code: '19', name: '720S COUPE' },
        { code: '20', name: '720S SPIDER' },
        { code: '21', name: 'GT' },
        { code: '22', name: 'Empilhadeira' },
        { code: '23', name: 'Carregador' },
        { code: '25', name: '620R COUPE' },
        { code: '26', name: 'ACCORD' },
        { code: '27', name: 'CR-V' },
        { code: '28', name: 'Pecas' },
        { code: '29', name: 'C40' },
        { code: '30', name: 'VANTAGE F1 COUPE' },
        { code: '31', name: 'VANTAGE COUPE' },
        { code: '32', name: 'DBX' },
        { code: '33', name: 'DB11 V12 COUPE' },
        { code: '34', name: 'DBS COUPE' },
        { code: '35', name: 'VANTAGE F1 ROADSTER' },
        { code: '36', name: 'DBX 707' },
        { code: '37', name: 'ARTURA' },
        { code: '38', name: '765LT SPIDER' },
        { code: '39', name: 'CIVIC HIBRIDRO TOURING' },
        { code: '40', name: 'TYPE R' },
        { code: '41', name: 'ACCORD HIBRIDRO' },
        { code: '42', name: 'ZR-V TOURING' },
        { code: '43', name: 'ZR-V EXL' },
        { code: '44', name: 'CR-V TOURING' },
        { code: '45', name: 'DBS 770 ULTIMATE COUPE' },
        { code: '46', name: 'DBS 770 ULTIMATE VOLANTE' },
        { code: '47', name: 'DB12 COUPE' },
        { code: '48', name: 'MCLAREN 750S COUPE' },
        { code: '49', name: 'MCLAREN 750S SPIDER' },
        { code: '50', name: 'ÔNIBUS HIGER BUS A12BR' },
        { code: '51', name: 'DB12 V8 VOLANTE' },
        { code: '52', name: 'VANQUISH' },
        { code: '53', name: 'MCLAREN ARTURA SPIDER ' },
        { code: '54', name: 'MCLAREN ARTURA ' },
        { code: '55', name: 'AIONY-HIGH ECUADOR VERSION' },
        { code: '56', name: 'AION V 505（NEDC) PREMIUM' },
        { code: '57', name: 'AION V 602（NEDC) LUXURY' },
        { code: '58', name: 'HYPER HT' },
        { code: '59', name: 'GS4 HEV' }
    ]

    constructor(
        private http: HttpClient,
        private _downloadService: DownloadService,
        private featureFlagService: FeatureFlagService,
    ) {
        this._queryBaseUrl = environment.endpoints.automotiveV2QueryService;
        this._commandBaseUrl = environment.endpoints.automotiveCommandService;
        this.initialize();
    }

    private async initialize(): Promise<void> {
        this.isFeatureEnabled = await this.featureFlagService.isFeatureFlagEnabledV2(
            FeatureFlagService.FEATURE_SHIPMENT_SERVICE_ENABLED
        );

        if (this.isFeatureEnabled) {
            this._queryBaseUrl = environment.endpoints.shipmentService;
        }
    }

    getBoarding(request): Observable<any> {
        return this._request(request, 'api/v2/shipment');
    }

    getBoardingSummary(request): Observable<any> {
        return this._request(request, 'api/v2/shipment-summary');
    }

    getShipmentByDomainType(shipmentDomainType: string): Observable<any> {
        return this.http.get(`${this._queryBaseUrl}/api/v2/shipment-domain?shipmentDomainType=${shipmentDomainType}`);
    }

    getVehicleTypes(): Observable<any> {
        if (this.isFeatureEnabled) {
            return this.http.get(`${this._queryBaseUrl}/api/v2/shipment/vehicle-types`);
        } else {
            return from(Promise.resolve(_.cloneDeep(this.VEHICLE_TYPES)));
        }
    }

    createBoarding(data): Observable<HttpResponse<any>> {
        const baseUrl = this.isFeatureEnabled ? this._queryBaseUrl : this._commandBaseUrl;
        return this.http.post(`${baseUrl}/api/v2/shipment`, data, { observe: 'response', responseType: 'json' });
    }

    editBoarding(data, id): Observable<HttpResponse<any>> {
        const baseUrl = this.isFeatureEnabled ? `${this._queryBaseUrl}/api/v2/shipment/${encodeURIComponent(id)}` : `${this._commandBaseUrl}/api/v2/shipment`;
        return this.http.put(baseUrl, data, { observe: 'response', responseType: 'json' });
    }

    deleteBoarding(id): Observable<HttpResponse<any>> {
        const baseUrl = this.isFeatureEnabled ? this._queryBaseUrl : this._commandBaseUrl;
        return this.http.delete(`${baseUrl}/api/v2/shipment?id=${encodeURIComponent(id)}`, { observe: 'response', responseType: 'json' });
    }

    importShipment(file: File): Observable<any> {
        const request = new FormData();
        const filename = file[0] && file[0].name;
        const headers = new HttpHeaders({
            Accept: 'text/csv',
        });
        headers.set('Content-Type', 'multipart/form-data');

        request.append('sheet', file[0], filename);

        const baseUrl = this.isFeatureEnabled ? this._queryBaseUrl : this._commandBaseUrl;
        return this.http.post(`${baseUrl}/api/v2/shipment/import`, request, { headers });
    }

    importTemplateShipment(): Observable<any> {
        const headers = new HttpHeaders({
            Accept: 'text/csv',
        });
        return this.http.get(`${this._queryBaseUrl}/api/v2/shipment/import/template`, { headers , responseType: 'blob'});

    }

    export(type: string, params): Observable<any> {
        const options = this._downloadService.getSourceOptions();
        if (type === 'xls') {
            options.headers = new HttpHeaders({
                Accept: 'application/vnd.ms-excel',
            });
        } else {
            options.headers = new HttpHeaders({
                Accept: 'text/csv',
            });
        }
        const requestParams = this._getRequestParams(params);

        const url = this.http.post(`${this._queryBaseUrl}/api/v2/shipment/export?${requestParams}`, params, options);

        return this._downloadService.downloadFrom(url, `quickShipment-${moment().format('YYYY-MM-DD')}.${type}`);
    }

    private _getRequestParams(params: { [key: string]: any }): string {
        return `${Object.keys(params)
            .map((key) => `${key}=${params[key]}`)
            .join('&')}`;
    }

    private _request(
        requestData: { params: { [key: string]: any }; headers: { [key: string]: any } },
        endpoint: string
    ): any {
        const requestParams = this._getRequestParams(requestData.params);
        const requestHeaders = {
            headers: new HttpHeaders().set('X-Requested-Client-Groups', requestData.headers.clientGroupCodes),
        };
        return this.http.get(`${this._queryBaseUrl}/${endpoint}?${requestParams}`, requestHeaders);

    }
}
