<section class="{{ layout }}"
    [ngClass]="{'tabs': tabList.length }"
    [style.borderTopColor]="borderColor"
    [style.height]="heightSection ? heightSection: 'calc(100vh - 90px)'"
    [style.width]="rightExtraPaddingPage ? 'calc(100vw - 75px)' : 'calc(100vw - 48px)'"
    [style.margin-right]="rightExtraPaddingPage ? '50px' : 'auto'">
    <div class="top-border">
        <div *ngIf="showDevInfo" class="page-dev-identifier {{ showDevInfo }}">{{ showDevInfo }}</div>
    </div>

    <div *ngIf="surpassWidth" class="scroll-arrows-container">
        <div class="left-arrow" (click)="scrollContainer('left', $event)"> <span class="material-icons">chevron_left</span> </div>
        <div class="right-arrow" (click)="scrollContainer('right', $event)"> <span class="material-icons">chevron_right</span> </div>
    </div>

    <div *ngIf="tabs.length" class="tabs-container" id="tabs-container"
        [style.max-width]="maxWidth + 'px'"
        [style.left]="surpassWidth ? '30px' : 'auto'"
        [style.right]="rightExtraPaddingPage ? '50px' : '30px'">
        <div *ngFor="let tab of tabList" class="page-tab" [ngClass]="{'selected': tab.code === selectedTab.code }" [id]="tab.code" (click)="selectTab(tab)">
            <img *ngIf="tab.icon" src="assets/img/icons/{{ tab.icon }}.png" alt="{{ tab.title }}">
            <span>{{ tab.title }}</span>
        </div>
    </div>

    <div class="page-wrapper-title">
        <ng-container *ngIf="!skeletonLoader && titleComponent">
            <div class="page-title-identifier">{{ titleComponent.title }}</div>
            <div *ngIf="titleComponent?.icon" [innerHTML]="titleComponent?.icon"></div>
            <div *ngIf="titleComponent?.image" class="icon-image">
                <img [src]="'../../../../' + titleComponent.image" class="icon-graph" alt="">
            </div>
            <div *ngIf="helpDescription.length" class="help-description">
                <cmx-help-modal
                    [help-title]="helpTitle"
                    [help-description]="helpDescription"
                    [help-video-src]="helpVideoSrc"
                    [help-video-name]="helpVideoName"
                ></cmx-help-modal>
            </div>
            <h3>
                {{ titleComponent.title }}
                <span *ngIf="titleComponent?.subtitle"> {{ titleComponent.subtitle }}</span>
            </h3>
            <span *ngIf="titleComponent?.status" class="title-status" [style.backgroundColor]="titleComponent.status.color">{{ titleComponent?.status?.name }}</span>
            <cmx-switch *ngIf="switch && switch.length > 1" [options]="switch" [selected]="selectedSwitch" (onSelect)="selectSwitch($event)"></cmx-switch>
        </ng-container>
        <ng-content *ngIf="!titleComponent" select="[cmx-page-wrapper-header]"></ng-content>
        <div *ngIf="skeletonLoader">
            <cmx-skeleton-loader [type]="'pageWrapperTitle'"></cmx-skeleton-loader>
        </div>
    </div>

    <div *ngIf="rightButtonList && rightButtonList.length" id="right-button-list">
        <ng-container *ngIf="!skeletonLoader">
            <div *ngFor="let button of rightButtonList; let i = index"
                class="right-button"
                [id]="button.code"
                [style.background-color]="verifyRightButtonSelected(button) ? button.color : '#ddd'"
                [style.color]="verifyRightButtonSelected(button) ? 'white' : button.color"
                [title]="(button?.description) ? (button?.name + ' - ' + button?.description) : (button?.name || '')"
                (click)="rightButtonClicked(button)">
                <span class="material-icons">{{ button?.icon }}</span>
            </div>
        </ng-container>
        <div *ngIf="skeletonLoader">
            <cmx-skeleton-loader [type]="'pageWrapperRightButtons'"></cmx-skeleton-loader>
        </div>
    </div>

    <div *ngIf="topButtonList && topButtonList.length" id="top-button-list"
        [ngClass]="{'outside': rightExtraPaddingPage && topButtonListOutside }"
        [style.top]="rightExtraPaddingPage && topButtonListOutside ?
            tabs.length ? '122px' : '102px' : '12px'">
        <ng-container *ngIf="!skeletonLoader">
            <div *ngFor="let button of topButtonList; let i = index" [id]="button.code" [ngClass]="button.disabled ? 'disabled' : 'enabled'"
                class="top-button {{  topButtonList.length === 1 ? 'left right' :  i === 0 ? 'left' : i === topButtonList.length - 1 ? 'right' : '' }}"
                [style.background-color]="button.color || '#b1b1b1'"
                [style.color]="button.textColor || 'white'"
                (mouseover)="topButtonMouseHover(button)"
                (mouseout)="topButtonMouseHover(button, 'out')"
                (click)="topButtonClicked(button, $event)"
                [disabled]="button.disabled || false"
                tooltip="{{ button.tooltip || '' }}">
                <span *ngIf="button.leftIcon" class="material-icons {{ button.name ? 'left' : '' }}">{{ button.leftIcon }}</span>
                <span >{{ button.name }}</span>
                <span *ngIf="button.multiple" class="multiple material-icons">keyboard_arrow_down</span>
                <span *ngIf="button.rightIcon && button.name" class="material-icons right">{{ button.rightIcon }}</span>
                <div *ngIf="(button.subItems && button.subItems.length) && selectedTopMultipleButton?.code === button.code"
                    class="sub-items-container">
                    <div *ngFor="let subItem of button.subItems" class="sub-item" [id]="subItem.code"
                        [style.background-color]="button.color || '#b1b1b1'"
                        [style.color]="button.textColor || 'white'"
                        (mouseover)="topButtonMouseHover(button, false, subItem)"
                        (mouseout)="topButtonMouseHover(button, 'out', subItem)"
                        (click)="topButtonClicked(subItem, $event)">
                        <span *ngIf="subItem.leftIcon" class="material-icons {{ subItem.name ? 'left' : '' }}">{{ subItem.leftIcon }}</span>
                        <span >{{ subItem.name }}</span>
                        <span *ngIf="subItem.rightIcon && subItem.name" class="material-icons right">{{ subItem.rightIcon }}</span>
                    </div>
                </div>
            </div>
        </ng-container>
        <div *ngIf="skeletonLoader">
            <cmx-skeleton-loader [type]="'pageWrapperTopButtons'"></cmx-skeleton-loader>
        </div>
    </div>

    <div style="display: flex"
        [style.height]="'calc(100vh - 155px)'"
        [style.flex-direction]="accordionOptions.flexDirection">
        <div *ngIf="showAccordion && internalAccordion" class="internal-accordion
            {{ internalAccordion }} {{ accordionOpen ? 'open' : 'closed' }}"
            [style.width]="accordionOptions.width"
            [style.height]="accordionOptions.height"
            [style.padding]="accordionOptions.padding">
            <div class="accordion-border {{ internalAccordion }}">
                <div class="accordion-arrow {{ internalAccordion }}"
                    (click)="toogleAccordion()">
                    <span class="material-icons">{{ accordionOptions.arrowIcon }}</span>
                </div>
                <span *ngIf="accordionName" class="accordion-name"
                    [style.transform]="accordionOptions?.name?.rotate"
                    [style.top]="accordionOptions?.name?.top"
                    [style.left]="accordionOptions?.name?.left"
                    [style.right]="accordionOptions?.name?.right"
                    [style.bottom]="accordionOptions?.name?.bottom"
                    (click)="toogleAccordion()">
                    {{ accordionName }}
                </span>
            </div>
            <div class="accordion-content {{ internalAccordion }}">
                <ng-content select="[cmx-page-wrapper-accordion]"></ng-content>
            </div>
        </div>
        <div class="page-body-content"
            [style.overflow-y]="accordionOpen ? 'auto' : 'hidden'"
            [style.width]="(internalAccordion === 'left' || internalAccordion === 'right') && showAccordion ? accordionOptions.contentSize : '100%'"
            [style.height]="(internalAccordion === 'top' || internalAccordion === 'bottom') && showAccordion ? accordionOptions.contentSize : 'auto'">
            <ng-content select="[cmx-page-wrapper-body]"></ng-content>
        </div>
    </div>

    <div class="teste">
    <div *ngIf="bottomButtonList && bottomButtonList.length && !asyncBottomButtonList" id="bottom-button-list">
        <div *ngFor="let button of bottomButtonList; let i = index;" class="bottom-button-container">
            <cmx-button
                [variant]="button?.code || 'primary'"
                [disabled]="button?.disabled || false"
                (click)="bottomButtonClicked(button)"
            >{{ button?.name || 'Botão ' + (i + 1) }}</cmx-button>
            <div *ngIf="button?.dangerous" class="dangerous-pipe"></div>
        </div>
    </div>
</div>

    <cmx-confirmation-modal
        id="page-wrapper-switch-change-alert-modal"
        [question]="alertSwitchMessage"
        [labelAction]="alertSwitchOptions[0]"
        [labelCancel]="alertSwitchOptions[1]"
        confirmType="danger"
        [hidden]="!showSwitchConfirmationModal"
        (onConfirm)="changeSwitchPage(switchEvent)"
        (onCancel)="showSwitchConfirmationModal = false"
    ></cmx-confirmation-modal>

    <cmx-confirmation-modal
        id="page-wrapper-tab-change-alert-modal"
        [question]="alertTabMessage"
        [labelAction]="alertTabOptions[0]"
        [labelCancel]="alertTabOptions[1]"
        confirmType="danger"
        [hidden]="!showTabConfirmationModal"
        (onConfirm)="changeTabPage(tabEvent)"
        (onCancel)="showTabConfirmationModal = false"
    ></cmx-confirmation-modal>

    <ng-content select="[cmx-page-wrapper-modal]"></ng-content>
</section>
