import { environment } from '#environment';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnInit,
    Renderer2,
    TemplateRef,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';

import { AppComponent } from '../app.component';
import { AuthGuard } from './_guards';
import { AuthenticationService } from './_services/authentication.service';
import { LoginService } from './_services/login.service';
import { UserService } from './_services/user.service';
import QRCode from 'qrcodejs2';
import { TemplateLoginEnum, TemplateModel } from './template-login.enum';

const ERROR_CAPTCHA_MESSAGE = 'Favor resolver a verificação de segurança';

@Component({
    selector: 'app-login-page',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AuthComponent implements OnInit {

    captchaEnable = environment.captchaEnable;
    initialized = false;
    loading = false;
    errorLogin = '';
    asyncLogin = false;
    captchaToken: any;

    formSign: FormGroup;
    rememberCredential: boolean = false;
    userData: any = null;
    twoFactorRequired: boolean = false;
    messageTwoFactorError: string = null;

    themeLogo: string = null;
    themeBackground: string = null;
    themeClient: string = null;
    passwordExpired: boolean = false
    mobileLinkEnable: boolean = false;

    @ViewChild('txtEmail') inputTxtEmail: ElementRef;
    @ViewChild('enterToLogin') buttonEnterToLogin: ElementRef;
    @ViewChild('txtTwofactor') inputTxtTwofactor: ElementRef;
    @ViewChild('captcha') captcha;
    @ViewChild('twoFactorTutorial') private _elTwoFactorTutorial: ElementRef;

    @ViewChild('createTwoFactor') createTwoFactor: TemplateRef<any>;
    @ViewChild('twoFactorTemplate') twoFactorTemplate: TemplateRef<any>;
    @ViewChild('signInTemplate') signInTemplate: TemplateRef<any>;
    activeTemplate: TemplateRef<any>;

    showEnableTwoFactor:boolean = false;
    qrcode: any = null;
    showDivQRCode: boolean = false;
    warningMessage:string = null;

    captchaValidated: boolean = true;
    captchaKey: string;
    captchaVisible: boolean = false;

    firstPasswordVisible: boolean = true;
    secondPasswordVisible: boolean = true;

    user = null;

    constructor(
        private router: Router,
        private userService: UserService,
        private authService: AuthenticationService,
        private _formBuilder: FormBuilder,
        private _loginService$: LoginService,
        private _renderer: Renderer2,
        private app: AppComponent,
        private authGuard: AuthGuard,
        private cdRef:ChangeDetectorRef
    ) {}

    ngOnInit() {

        this.warningMessage = 'Sua autenticação por dois fatores está ativa. Não será possível logar no sistema sem o código de acesso.';

        this.authService.clearDataUser();
        this.userService.clearUser();
        this.captchaKey = this.authService.getCaptchaKey();
        this.app.globalLoader = false;
        this.asyncLogin = false;
        this.formSign = this._formBuilder.group({
            email: [null, Validators.required],
            password: [null, Validators.required],
            otpCode: [null]
        });

        if(this.authService.rememberCredentials){
            this.formSign.patchValue(this.authService.rememberCredentials);
            this.rememberCredential = true;
        }

        this.themeLogo = this._loginService$.getLogo();
        this.themeBackground = this._loginService$.getBackground();
        this.themeClient = this._loginService$.getClient();

        const templates:TemplateModel[] = [
            {
                type: TemplateLoginEnum.LOGIN,
                value: this.signInTemplate
            },
            {
                type: TemplateLoginEnum.TWO_FACTOR,
                value: this.twoFactorTemplate
            },
        ]

        this.authService.setTemplates(templates);
        this.getUser();

    }

    ngAfterViewInit(): void {
        this.activeTemplate = this.authService.getActiveTemplate()
        if(!this.activeTemplate){
            this.activeTemplate = !this.twoFactorRequired ? this.signInTemplate : this.twoFactorTemplate
        }

        this.authService.setActiveTemplate(this.activeTemplate)

        setTimeout(() => {
            if(
                this.captchaEnable &&
                this.captcha &&
                this.captcha.el.nativeElement.childNodes.length > 0 &&
                this.captcha.el.nativeElement.childNodes[0].childElementCount > 0
            ){
                this.captchaValidated = false;
                this.captchaVisible = true
            }
        }, 1000);
    }

    ngAfterViewChecked(){
        this.cdRef.detectChanges();
    }

    signin() {
        this.errorLogin = '';
        this.asyncLogin = true;

        if(!this.verifyPreInputData()) return;

        const keysToRemove = [];
        for (let i = 0; i < localStorage.length; i++) {
            if (
                localStorage.key(i).endsWith('financeiro-filterState') ||
                localStorage.key(i).endsWith('financeiro-groupingState') ||
                localStorage.key(i).endsWith('financeiro-hierarchyState')
            ) {
                keysToRemove.push(localStorage.key(i));
            }
        }

        keysToRemove.forEach((element) => {
            localStorage.removeItem(element);
        });

        if(!this.twoFactorRequired){
            this.tryLogin();
        }
    }

    forgotPassword() {

        const email = this.formSign.controls['email'].value || '';
        const currentDomain = location.host;

        this.router.navigate(['pwd-recovery'], {queryParams: { domain: currentDomain, email: email }});
    }

    private getUser() {
        this.initialize();
    }

    private initialize() {
        this.app.globalLoader = false;
        this.initialized = true;
    }

    public validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    private verifyPreInputData(): boolean {

        if (!this.validateEmail(this.formSign.controls['email'].value)) {

            this.throwError('Insira um e-mail válido');
            return false;
        }

        if (typeof this.formSign.controls['password'].value !== 'string' || this.formSign.controls['password'].value === undefined || this.formSign.controls['password'].value === '') {

            this.throwError('Insira uma senha válida');
            return false;
        }

        return true;
    }

    validateTwoFactor(){
        const otpCodeValue = this.formSign.controls['otpCode'].value;
        this.messageTwoFactorError = null;
        if(!otpCodeValue){
            this.messageTwoFactorError = 'Código inválido';
            return;
        }
        this.asyncLogin = true;
        this.authService.validateOtpCode(this.userData.email, this.userData.idToken, otpCodeValue).subscribe((user: any) => {

            const sessionId = user.sessionId;
            this.asyncLogin = true;
            localStorage.setItem('sessionId', sessionId);

            this.authService.getUserPermissions(this.userData.idToken, sessionId, this.userData.email).then(permissions => {               
                localStorage.setItem('permUser', btoa(JSON.stringify(permissions)));
                this.userService.setIsLoggedIn(true);
                this.userService.userSubscribe(this.userData, permissions);
                if(this.rememberCredential){
                    this.authService.rememberCredentials = this.formSign.value;
                }
                this.asyncLogin = false;
                this.authService.permissionsUserApi = true;
                this.redirect();
                this.asyncLogin = false;
                this.twoFactorRequired = false;
            }).catch(error => {
                this.asyncLogin = false;
                return this.throwError('Não foi possível realizar o login', error);
            });
        }, error => {

            this.asyncLogin = false;
            if(error.url.indexOf('bi-stage') > 0){
                this.messageTwoFactorError = 'Ocorreu um erro';
                return;
            }

            this.formSign.controls['otpCode'].patchValue(null);
            this.messageTwoFactorError = 'Código inválido';
        });
    }

    private _twoFactorRequired(){

        this.formSign.controls['otpCode'].patchValue(null);
        this.formSign.controls['otpCode'].setValidators(Validators.required);

        this.twoFactorRequired = true;
        setTimeout(() => {

            this.inputTxtTwofactor.nativeElement.focus();
        }, 1000);
    }

    private tryLogin() {

        let userValidated = null;
        let userLogged = null;
        this.asyncLogin = true;
        this.errorLogin = '';
        this.userService.setIsLoggedIn(false);
        this.authService.permissionsUserApi = true;

        if (this.captchaValidated) {
            this.asyncLogin = true;
            this.authService.login(this.formSign.controls['email'].value, this.formSign.controls['password'].value, this.captchaToken, this.captchaVisible).subscribe(async (data) => {
                userValidated = await this.authService.validateToken(data.auth.idToken).catch(error => {
                    return this.throwError('Não foi possível realizar o login', error);
                });

                if(!userValidated){
                    return this.throwError('Não foi possível realizar o login');
                }


                const idToken = data.auth.idToken;
                const sessionId = data.sessionId;

                localStorage.setItem('idToken', idToken);
                localStorage.setItem('sessionId', sessionId);


                userLogged = data;
                this.userData = {
                    ...userValidated,
                    idToken: userLogged.auth.idToken,
                    refreshToken: userLogged.auth.refreshToken,
                    expireIn: this.userService.getDateToExpire()
                }



                this.authService.verifyTwoFactor(userLogged.auth.email, userLogged.auth.idToken).subscribe((result) => {
                    if((userValidated.twoFactorKey == null && result.twoFactorMandatory) ||
                        userValidated.twoFactorKey === 'change_it'){
                            this.asyncLogin = false;

                            this.user = {
                                token: userLogged.auth.idToken,
                                sessionId: result.sessionId,
                                uid: this.userData.uid,
                                twoFactorKey: userValidated.twoFactorKey,
                                email: userLogged.auth.email
                            }

                            this.activeTemplate = this.createTwoFactor;

                    } else if(result && result.error === '2fRequired'){

                        this.asyncLogin = false;
                        this.activeTemplate = this.twoFactorTemplate;
                        this._twoFactorRequired();
                    } else {

                        this.formSign.controls['otpCode'].clearValidators();
                        this.userData.twoFactorMandatory = result.twoFactorMandatory;

                        this.authService.getUserPermissions(userLogged.auth.idToken, userLogged.sessionId, userLogged.auth.email).then(permissions => {

                            localStorage.setItem('sessionId', result.sessionId);
                            localStorage.setItem('permUser', btoa(JSON.stringify(permissions)));
                            this.userService.setIsLoggedIn(true);
                            this.userService.userSubscribe(this.userData, permissions);
                            if(this.rememberCredential){
                                this.authService.rememberCredentials = this.formSign.value;
                            }
                            this.asyncLogin = false;
                            this.redirect();
                        }).catch(error => {
                            this.asyncLogin = false;
                            this.resetCaptcha();
                            return this.throwError('Não foi possível realizar o login', error);
                        });
                    }
                }, (error) => {
                    this.throwError('Não foi possível realizar o login', error);
                    this.resetCaptcha();
                });
            }, error => {
                this.asyncLogin = false;
                if (error.error.message === 'EXPIRED_PASSWORD') {

                    const data = {
                        idToken: error.error.idToken,
                        sessionId: error.error.sessionId,

                    }

                    this.authService.hasTokenExpiredPassword(data)
                    this.router.navigate(['/auth-handler'], {queryParams: {mode:'EXPIRED_PASSWORD',email: this.formSign.controls['email'].value}});
                    return

                } else if(error?.error === 'INVALID_CAPTCHA'){
                    this.throwError('Favor resolver a verificação de segurança');
                    this.resetCaptcha();
                }
                else{
                    this.throwError('Usuário ou Senha inválido(s)');
                    this.resetCaptcha();
                }
                this.captcha.reset();
            });
        } else {
            this.errorLogin = ERROR_CAPTCHA_MESSAGE;
            this.asyncLogin = false;
        }
    }

    private throwError(message:string, errorlog: any = null):void {
        console.trace(errorlog);
        this.errorLogin = message ? message : 'Ocorreu um erro desconhecido';
        this.asyncLogin = false;
    }

    private redirect() {
        if (localStorage.getItem('redirect-to') !== null) {
            const href = localStorage.getItem('redirect-to');
            localStorage.removeItem('redirect-to');
            window.location.href = href;
        } else {
            this.router.navigate([`${this.authGuard.getPreviousPageLogin()}`]);
        }
    }

    validateCaptcha($event) {
        this.captchaValidated = true;
        this.captchaToken = $event.response;

        setTimeout(() => {
            this.buttonEnterToLogin.nativeElement.focus();
            this.buttonEnterToLogin.nativeElement.classList.add('actived-focus')
        }, 200);
	}

    resetCaptcha() {
        if(this.captchaEnable){
            this.captchaValidated = false;
            this.captcha.reset();
            this.buttonEnterToLogin.nativeElement.classList.remove('actived-focus')
        }
    }

    backToSignIn(complete?:boolean){
        this.activeTemplate = this.signInTemplate;
        if(complete){
            this.activeTemplate = this.signInTemplate;
        }
        this.loading = false;
    }

    enableTwoFactor(){
        this.showEnableTwoFactor = true;
        this.loading = true;
        this.authService.enableTwoFactor(this.user.sessionId, this.user.token, this.user.uid).subscribe(
            (data: any) => {
                this.user = {
                    twoFactorKey: data.twoFactorKey,
                    ...this.user,
                }
                this.warningMessage = 'Sua autenticação por dois fatores está ativa. Não será possível logar no sistema sem o código de acesso.';
                setTimeout(() => {
                    this.showQRCode(data.twoFactorKey);
                }, 300);
            },
            (error) => {
                this.loading = false;
            }
        )
    }

    getQRCodeUrl(email: string,twoFactorKey: string) {
        return `otpauth://totp/Comexport:${email}?secret=${twoFactorKey}&issuer=Comexport&algorithm=SHA1&digits=6&period=30`;
    }

    showQRCode(twoFactorKey) {
        if (this._isTwoFactorEnabled(twoFactorKey)) {
            if (this.qrcode) {
                this.qrcode.clear();
            }
            $('#qrcode').empty();
            this.qrcode = new QRCode('qrcode', {
                text: this.getQRCodeUrl(this.user.email, twoFactorKey),
                width: 128,
                height: 128,
                colorDark: '#000000',
                colorLight: '#ffffff',
            });
            $('#qrcode').prop('title', 'QR Code');
         } else {
            if (this.qrcode) {
                this.qrcode.clear();
            }
            $('#qrcode').empty();
        }
        setTimeout(() => {
            this.showDivQRCode = true;
        }, 300);
    }

    openTutorial() {
        this._renderer.removeClass(this._elTwoFactorTutorial.nativeElement, 'hidden');
    }

    closeTutorial() {
        this._renderer.addClass(this._elTwoFactorTutorial.nativeElement, 'hidden');
    }

    private _isTwoFactorEnabled(twoFactorKey){
        return twoFactorKey != null && twoFactorKey !== 'change_it'
    }

    changePasswordVisibility(firstPassword) {
        if (firstPassword) {
            this.firstPasswordVisible = !this.firstPasswordVisible;
        } else {
            this.secondPasswordVisible = !this.secondPasswordVisible;
        }
    }

}
