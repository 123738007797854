<div *ngIf="asyncLogin" class="fullLoader"></div>
<div *ngIf="initialized === false" class="fullLoader">
    <img src="assets/img/logos/logo-bi.png" class="d-none" />
    <span class="exporting d-none">• {{ 'common::strings.loading' | translate }} •</span>
    <div class="loaderModel4">
        <div class="cube cube1"></div>
        <div class="cube cube2"></div>
        <div class="cube cube3"></div>
        <div class="cube cube4"></div>
        <div class="cube cube5"></div>
        <div class="cube cube6"></div>
    </div>
</div>

<div class="logoutMsgArea" *ngIf="initialized">
    <div class="bgContainer" style="background-position: center right" [style.background-image]="backLogin"></div>
    <div class="logoutMsgContainer">
        <div class="logoContainer">
            <img [src]="logoLogin" />
        </div>
        <div class="formContainer">
            <span class="logoutMsg">{{ msgLogout | translate }}</span>
            <span class="logoutMsgLogin">{{ 'auth.msgReturnToLogin' | translate }}</span>
            <form class="formFields">
                <button type="submit" [disabled]="loading" (click)="goToLogin()">{{ 'system::actions.return' | translate }}</button>
            </form>
            <div *ngIf="asyncLogin">
                <div class="loaderModel4" style="position: relative; margin-top: 30px">
                    <div class="cube cube1"></div>
                    <div class="cube cube2"></div>
                    <div class="cube cube3"></div>
                    <div class="cube cube4"></div>
                    <div class="cube cube5"></div>
                    <div class="cube cube6"></div>
                </div>
            </div>
        </div>
    </div>
</div>
