import { Injectable } from '@angular/core';
import moment from 'moment';
import { FollowUpReportExchangeFormatter } from './report-followup.formatter.exchange';

@Injectable()
export class FollowUpReportInvoiceFormatter {
    // PO
    public formatLinkedPo(row, cell, value, columnDef, dataContext) {
        const invoices = [];
        value?.forEach((invoice) => {
            if (!invoices.includes(invoice.linkedPo)) {
                invoices.push(invoice.linkedPo);
            }
        });
        if (invoices.length === 1) {
            return invoices[0];
        } else if (invoices.length > 1) {
            return `<span class="multipleInvoices">Várias (${invoices.length})</span>`;
        } else {
            return '';
        }
    }

    // Número da Invoice
    public formatInvoiceNumber(row, cell, value, columnDef, dataContext) {
        const invoices = [];
        value?.forEach((invoice) => {
            if (!invoices.includes(invoice.invoiceNumber)) {
                invoices.push(invoice.invoiceNumber);
            }
        });
        if (invoices.length === 1) {
            return invoices[0];
        } else if (invoices.length > 1) {
            return `<span class="multipleInvoices">Várias (${invoices.length})</span>`;
        } else {
            return '';
        }
    }

    // Valor Invoice
    public formatInvoiceValue(row, cell, value, columnDef, dataContext) {
        const format = { minimumFractionDigits: 2 };
        const invoices = [];
        value?.forEach((invoice) => {
            if (invoice.amount && !invoices.includes(invoice.amount)) {
                invoices.push({
                    value: `<span class="formatValue">${invoice.currency}
                    ${invoice.amount.toLocaleString('pt-BR', format)}</span>`,
                });
            }
        });
        if (invoices.length === 1) {
            return invoices[0].value;
        } else if (invoices.length > 1) {
            return `<span class="multipleInvoices">Várias (${invoices.length})</span>`;
        } else {
            return '';
        }
    }

    // Data Invoice
    public formatInvoiceDate(row, cell, value, columnDef, dataContext) {
        const invoiceDates = [];
        value?.forEach((invoice) => {
            if (invoice.invoiceDate && !invoiceDates.includes(invoice.invoiceDate)) {
                invoiceDates.push(invoice.invoiceDate);
            }
        });

        if (invoiceDates.length === 1) {
            return `<span class='dateValue'>${moment(invoiceDates[0], 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>`;
        } else if (invoiceDates.length > 1) {
            return `<span class="multipleInvoices">Várias (${invoiceDates.length})</span>`;
        } else {
            return '';
        }
    }

    // Incoterm
    public formatIncoterm(row, cell, value, columnDef, dataContext) {
        const invoices = [];
        value?.forEach((invoice) => {
            if (!invoices.includes(invoice.incoterm)) {
                invoices.push(invoice.incoterm);
            }
        });
        if (invoices.length === 1) {
            return invoices[0];
        } else if (invoices.length > 1) {
            return `<span class="multipleInvoices">Vários (${invoices.length})</span>`;
        } else {
            return '';
        }
    }

    // Fornecedor
    public formatVendor(row, cell, value, columnDef, dataContext) {
        const vendors = [];
        value?.forEach((invoice) => {
            if (!vendors.includes(invoice.vendorName)) {
                vendors.push(invoice.vendorName);
            }
        });
        if (vendors.length === 1) {
            return vendors[0];
        } else if (vendors.length > 1) {
            return `<span class="multipleInvoices">Várias (${vendors.length})</span>`;
        } else {
            return '';
        }
    }

    // Letra de Credito
    public formatCreditLetter(row, cell, value, columnDef, dataContext) {
        let letterOfCredits = false;
        value?.forEach((invoice) => {
            if (invoice.letterOfCredit === '1') {
                letterOfCredits = true;
            }
        });
        if (letterOfCredits) {
            return '<div class="ball true "></div>';
        }
        return '<div class="ball"></div>';
    }

    // Data Abertura LC
    public formatLcOpeningDate(row, cell, value, columnDef, dataContext) {
        if (value === undefined || value === null) {
            return '';
        }

        const lcs = [];
        value.forEach((invoice) => {
            if (invoice.lcOpeningDate) {
                lcs.push(invoice.lcOpeningDate);
            }
        });

        if (lcs.length === 1) {
            return `<span class='dateValue'>${moment(lcs[0], 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>`;
        } else if (lcs.length > 1) {
            return `<span class="multipleLcs">Várias (${lcs.length})</span>`;
        } else {
            return '';
        }
    }

    // Número LC
    public formatLcNumber(row, cell, value, columnDef, dataContext) {
        if (value === undefined || value === null) {
            return '';
        }

        const lcs = [];
        value.forEach((invoice) => {
            if (!lcs.includes(invoice.lcNumber) && invoice.lcNumber !== null) {
                lcs.push(invoice.lcNumber);
            }
        });
        if (lcs.length === 1) {
            return lcs[0];
        } else if (lcs.length > 1) {
            return `<span class="multipleLcs">Várias (${lcs.length})</span>`;
        } else {
            return '';
        }
    }

    // Peso Líquido Total
    public formatWeight(row, cell, value, columnDef, dataContext) {
        const invoices = [];
        value?.forEach((invoice) => {
            invoices.push(invoice.totalWeight);
        });
        if (invoices.length === 1) {
            return invoices[0];
        } else if (invoices.length > 1) {
            return `<span class="multipleInvoices">Várias (${invoices.length})</span>`;
        } else {
            return '';
        }
    }

    // Peso Bruto Total
    public formatGrossWeight(row, cell, value, columnDef, dataContext) {
        const invoices = [];
        value?.forEach((invoice) => {
            invoices.push(invoice.totalGrossWeight);
        });
        if (invoices.length === 1) {
            return invoices[0];
        } else if (invoices.length > 1) {
            return `<span class="multipleInvoices">Várias (${invoices.length})</span>`;
        } else {
            return '';
        }
    }

    // Fabricante
    public formatManufacturer(row, cell, value, columnDef, dataContext) {
        const manufacturers = [];

        if (value === undefined) {
            return '';
        }

        value.forEach((invoice) => {
            if (invoice.items !== undefined) {
                invoice.items.forEach((item) => {
                    if (!manufacturers.includes(item.manufacturer.name)) {
                        manufacturers.push(item.manufacturer.name);
                    }
                });
            }
        });

        if (manufacturers.length === 1) {
            return manufacturers[0];
        } else if (manufacturers.length > 1) {
            return `<span class="multipleManufacturers">Várias (${manufacturers.length})</span>`;
        } else {
            return '';
        }
    }

    public formatManufacturerCountry(row, cell, value, columnDef, dataContext) {
        const manufacturerCountries = [];

        if (value === undefined) {
            return '';
        }

        value.forEach((invoice) => {
            if (invoice.items !== undefined) {
                invoice.items.forEach((item) => {
                    if (
                        item.manufacturerCountry !== undefined &&
                        item.manufacturerCountry !== '' &&
                        !manufacturerCountries.includes(item.manufacturerCountry)
                    ) {
                        manufacturerCountries.push(item.manufacturerCountry);
                    }
                });
            }
        });

        if (manufacturerCountries.length === 1) {
            return manufacturerCountries[0];
        } else if (manufacturerCountries.length > 1) {
            return `<span class="multipleManufacturerCountries">Várias (${manufacturerCountries.length})</span>`;
        } else {
            return '';
        }
    }

    // Descrição do Produto
    public formatVendorInvoiceProductDescription(row, cell, value, columnDef, dataContext) {
        const productDescriptions = [];

        if (value === undefined) {
            return '';
        }

        value.forEach((invoice) => {
            if (invoice.items !== undefined) {
                invoice.items.forEach((item) => {
                    if (
                        item.productDescription !== undefined &&
                        item.productDescription !== '' &&
                        !productDescriptions.includes(item.productDescription)
                    ) {
                        productDescriptions.push(item.productDescription);
                    }
                });
            }
        });

        if (productDescriptions.length === 1) {
            return productDescriptions[0];
        } else if (productDescriptions.length > 1) {
            return `<span class="multipleInvoices">Várias (${productDescriptions.length})</span>`;
        } else {
            return '';
        }
    }

    // Descrição Detalhada de Produto
    public formatVendorInvoiceProductDescriptionDetail(row, cell, value, columnDef, dataContext) {
        const productDescriptionDetails = [];
        if (value === undefined) {
            return '';
        }

        value.forEach((invoice) => {
            if (invoice.items !== undefined) {
                invoice.items.forEach((item) => {
                    if (
                        item.productDescriptionDetail !== undefined &&
                        item.productDescriptionDetail !== '' &&
                        !productDescriptionDetails.includes(item.productDescriptionDetail)
                    ) {
                        productDescriptionDetails.push(item.productDescriptionDetail);
                    }
                });
            }
        });

        if (productDescriptionDetails.length === 1) {
            return productDescriptionDetails[0];
        } else if (productDescriptionDetails.length > 1) {
            return `<span class="multipleInvoices">Várias (${productDescriptionDetails.length})</span>`;
        } else {
            return '';
        }
    }

    // Unidade
    public formatUnitMeasureDescription(row, cell, value, columnDef, dataContext) {
        const unitMeasureDescs = [];
        if (value === undefined) {
            return '';
        }

        value.forEach((invoice) => {
            if (invoice.items !== undefined) {
                invoice.items.forEach((item) => {
                    if (!unitMeasureDescs.includes(item.unitMeasureDescription)) {
                        unitMeasureDescs.push(item.unitMeasureDescription);
                    }
                });
            }
        });

        if (unitMeasureDescs.length === 1) {
            return unitMeasureDescs[0];
        } else if (unitMeasureDescs.length > 1) {
            return `<span class="multipleInvoiceItemModal">Várias (${unitMeasureDescs.length})</span>`;
        } else {
            return '';
        }
    }

    // Quantidade
    public formatQuantity(row, cell, value, columnDef, dataContext) {
        const quantity = [];
        const format = { minimumFractionDigits: 3 };
        if (value === undefined || value === null) {
            return '';
        }

        value.forEach((invoice) => {
            if (invoice.items !== undefined) {
                invoice.items.forEach((item) => {
                    quantity.push(item.quantity);
                });
            }
        });
        if (quantity.length === 1) {
            return quantity[0];
        } else if (quantity.length > 1) {
            let sum = 0;
            for (let i = 0; i < quantity.length; i++) {
                sum += parseFloat(quantity[i]);
            }
            return `<span class="formatQuantity">${sum.toLocaleString('PT-BR', format)}</span>`;
        } else {
            return '';
        }
    }

    // Quantidade Faturável
    public formatBillableQuantity(row, cell, value, columnDef, dataContext) {
        const quantity = [];
        const format = { minimumFractionDigits: 3 };
        if (value === undefined) {
            return '';
        }

        value.forEach((invoice) => {
            if (invoice.items !== undefined) {
                invoice.items.forEach((item) => {
                    if (
                        item.billableQuantityDecimal !== undefined &&
                        item.billableQuantityDecimal !== null &&
                        item.billableQuantityDecimal !== ''
                    ) {
                        quantity.push(item.billableQuantityDecimal);
                    }
                });
            }
        });
        if (quantity.length === 1) {
            return quantity[0];
        } else if (quantity.length > 1) {
            let sum = 0;
            for (let i = 0; i < quantity.length; i++) {
                sum += parseFloat(quantity[i]);
            }
            return `<span class="formatQuantity">${sum.toLocaleString('PT-BR', format)}</span>`;
        } else {
            return '';
        }
    }

    // Unidade Faturável
    public formatBillableUnitMeasureDescription(row, cell, value, columnDef, dataContext) {
        const unitMeasureDescs = [];
        if (value === undefined) {
            return '';
        }

        value.forEach((invoice) => {
            if (invoice.items !== undefined) {
                invoice.items.forEach((item) => {
                    if (
                        item.billableUnitMeasureDescription !== undefined &&
                        item.billableUnitMeasureDescription !== null &&
                        item.billableUnitMeasureDescription !== '' &&
                        !unitMeasureDescs.includes(item.billableUnitMeasureDescription)
                    ) {
                        unitMeasureDescs.push(item.billableUnitMeasureDescription);
                    }
                });
            }
        });

        if (unitMeasureDescs.length === 1) {
            return unitMeasureDescs[0];
        } else if (unitMeasureDescs.length > 1) {
            return `<span class="multipleInvoiceItemModal">Várias (${unitMeasureDescs.length})</span>`;
        } else {
            return '';
        }
    }

    public formatVendorInvoiceCollection(row, cell, value, columnDef, dataContext) {
        const collectionList = [];
        if (value === undefined) {
            return '';
        }

        value.forEach((invoice) => {
            if (invoice.items !== undefined) {
                invoice.items.forEach((item) => {
                    if (
                        item.collection !== undefined &&
                        item.collection !== null &&
                        item.collection !== '' &&
                        !collectionList.includes(item.collection)
                    ) {
                        collectionList.push(item.collection);
                    }
                });
            }
        });
        if (collectionList.length >= 1) {
            return collectionList[0];
        } else {
            return '';
        }
    }

    // Lote
    public formatVendorInvoiceBatchNumber(row, cell, value, columnDef, dataContext) {
        const batchNumberList = [];
        if (value === undefined) {
            return '';
        }

        value.forEach((invoice) => {
            if (invoice.items !== undefined) {
                invoice.items.forEach((item) => {
                    if (
                        item.batchNumber !== undefined &&
                        item.batchNumber !== null &&
                        item.batchNumber !== '' &&
                        !batchNumberList.includes(item.batchNumber)
                    ) {
                        batchNumberList.push(item.batchNumber);
                    }
                });
            }
        });

        if (batchNumberList.length === 1) {
            return batchNumberList[0];
        } else if (batchNumberList.length > 1) {
            return `<span class="multipleInvoiceItemModal">Várias (${batchNumberList.length})</span>`;
        } else {
            return '';
        }
    }

    // Tax Hedge
    formatTaxHedge(row, cell, value, columnDef, dataContext) {
        const taxHedges = [];
        if (value === undefined) {
            return '';
        }
        value.forEach((invoice) => {
            if (invoice?.hedges !== undefined) {
                invoice?.hedges.forEach((hedge) => {
                    if (
                        hedge.tax !== undefined &&
                        hedge.tax !== null &&
                        hedge.tax !== '' &&
                        !taxHedges.includes(hedge.tax)
                    ) {
                        taxHedges.push(hedge.tax);
                    }
                });
            }
        });
        if (taxHedges.length === 1 && typeof taxHedges[0] === 'number') {
            return taxHedges[0].toLocaleString('pt-BR', FollowUpReportExchangeFormatter.formatFraction);
        } else if (taxHedges.length > 1) {
            return `<span class="multipleTaxHedge">Várias (${taxHedges.length})</span>`;
        } else {
            return '';
        }
    }

    // Value Hedge
    formatValueHedge(row, cell, value, columnDef, dataContext) {
        const valueHedges = [];
        if (value === undefined) {
            return '';
        }
        value.forEach((invoice) => {
            if (invoice?.hedges !== undefined) {
                invoice?.hedges.forEach((hedge) => {
                    if (
                        hedge.value !== undefined &&
                        hedge.value !== null &&
                        hedge.value !== '' &&
                        !valueHedges.includes(hedge.value)
                    ) {
                        valueHedges.push({
                            value: `<span class="formatValue">${invoice.currency}
                            ${hedge.value.toLocaleString('pt-BR', FollowUpReportExchangeFormatter.formatCurrency)}</span>`,
                        });
                    }
                });
            }
        });
        if (valueHedges.length === 1) {
            return valueHedges[0].value;
        } else if (valueHedges.length > 1) {
            return `<span class="multipleValueHedge">Várias (${valueHedges.length})</span>`;
        } else {
            return '';
        }
    }

    // Contract Date Hedge
    formatContractDateHedge(row, cell, value, columnDef, dataContext) {
        const contractDatesHedges = [];
        if (value === undefined) {
            return '';
        }
        value.forEach((invoice) => {
            if (invoice?.hedges !== undefined) {
                invoice?.hedges.forEach((hedge) => {
                    if (
                        hedge.contractDate !== undefined &&
                        hedge.contractDate !== null &&
                        hedge.contractDate !== '' &&
                        !contractDatesHedges.includes(hedge.contractDate)
                    ) {
                        contractDatesHedges.push(hedge.contractDate);
                    }
                });
            }
        });
        if (contractDatesHedges.length === 1) {
            return `<span class='dateValue'>${moment(contractDatesHedges[0], 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>`;
        } else if (contractDatesHedges.length > 1) {
            return `<span class="multipleContractDateHedge">Várias (${contractDatesHedges.length})</span>`;
        } else {
            return '';
        }
    }

    // Expiration Date Hedge
    formatExpirationDateHedge(row, cell, value, columnDef, dataContext) {
        const expirationDatesHedges = [];
        if (value === undefined) {
            return '';
        }
        value.forEach((invoice) => {
            if (invoice?.hedges !== undefined) {
                invoice?.hedges.forEach((hedge) => {
                    if (
                        hedge.expirationDate !== undefined &&
                        hedge.expirationDate !== null &&
                        hedge.expirationDate !== '' &&
                        !expirationDatesHedges.includes(hedge.expirationDate)
                    ) {
                        expirationDatesHedges.push(hedge.expirationDate);
                    }
                });
            }
        });
        if (expirationDatesHedges.length === 1) {
            return `<span class='dateValue'>${moment(expirationDatesHedges[0], 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>`;
        } else if (expirationDatesHedges.length > 1) {
            return `<span class="multipleExpirationDateHedge">Várias (${expirationDatesHedges.length})</span>`;
        } else {
            return '';
        }
    }
    
    private extractUniqueInvoiceItemValues = (invoices, field) => {
        const values = new Set();
        invoices?.forEach(invoice => {
            invoice?.items?.forEach(item => {
                const value = item[field];
                if (value) values.add(value);
            });
        });
        return Array.from(values);
    };
    
    private formatDate = (date) => date ? moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY') : '';
    
    private renderSpan = (value, singleClass, multipleClass) => {
        if (value.length === 1) {
            return `<span class='${singleClass}'>${value[0]}</span>`;
        } else if (value.length > 1) {
            return `<span class='${multipleClass}'>Várias (${value.length})</span>`;
        } else {
            return '';
        }
    };

    // Tipo do Selo
    public formatVendorInvoiceLabelType = (row, cell, value, columnDef, dataContext) => {
        const labelTypeList = this.extractUniqueInvoiceItemValues(value, 'labelType');
        return this.renderSpan(labelTypeList.map(type => type === 'SEM_SELAGEM' ? 'SEM SELAGEM' : type), '', 'multipleInvoiceItemModal');
    }

    // Data Requisição de Selos
    public formatVendorInvoiceLabelRequestDate = (row, cell, value, columnDef, dataContext) => {
        const labelRequestDates = this.extractUniqueInvoiceItemValues(value, 'labelRequestDate').map(this.formatDate);
        return this.renderSpan(labelRequestDates, 'dateValue', 'multipleInvoiceItemModal');
    }

    // Data Recebimento de Selos
    public formatVendorInvoiceLabelDeliveryDate = (row, cell, value, columnDef, dataContext) => {
        const labelDeliveryDates = this.extractUniqueInvoiceItemValues(value, 'labelDeliveryDate').map(this.formatDate);
        return this.renderSpan(labelDeliveryDates, 'dateValue', 'multipleInvoiceItemModal');
    }

    // Data Início Selagem
    public formatVendorInvoiceLabelStartDate = (row, cell, value, columnDef, dataContext) => {
        const labelStartDates = this.extractUniqueInvoiceItemValues(value, 'labelStartDate').map(this.formatDate);
        return this.renderSpan(labelStartDates, 'dateValue', 'multipleInvoiceItemModal');
    }

    // Data de Recebimento Documento BTG
    public formatVendorInvoiceDateBtgDocuments = (row, cell, value, columnDef, dataContext) => {
        const dateBtgDocuments = this.extractUniqueInvoiceItemValues(value, 'dateBtgDocuments').map(this.formatDate);
        return this.renderSpan(dateBtgDocuments, 'dateValue', 'multipleInvoiceItemModal');
    }

    // Data Protocolo BTG
    public formatVendorInvoiceBtgProtocolDate = (row, cell, value, columnDef, dataContext) => {
        const btgProtocolDate = this.extractUniqueInvoiceItemValues(value, 'btgProtocolDate').map(this.formatDate);
        return this.renderSpan(btgProtocolDate, 'dateValue', 'multipleInvoiceItemModal');
    }

    // Data BTG Liberado
    public formatVendorInvoiceBtgReleaseDate = (row, cell, value, columnDef, dataContext) => {
        const btgReleaseDate = this.extractUniqueInvoiceItemValues(value, 'btgReleaseDate').map(this.formatDate);
        return this.renderSpan(btgReleaseDate, 'dateValue', 'multipleInvoiceItemModal');
    }

    // Tempo para liberação estimado
    public formatVendorInvoiceEstimatedTimeRelease = (row, cell, value, columnDef, dataContext) => {
        let estimatedTimeRelease = value == undefined ? "" : this.extractUniqueInvoiceItemValues(value, 'estimatedTimeRelease');
        return this.renderSpan(estimatedTimeRelease, '', 'multipleInvoiceItemModal');
    };

    // Tempo para liberação real
    public formatVendorInvoiceFinalTimeRelease = (row, cell, value, columnDef, dataContext) => {
        let finalTimeRelease = value == undefined ? "" : this.extractUniqueInvoiceItemValues(value, 'finalTimeRelease');
        return this.renderSpan(finalTimeRelease, '', 'multipleInvoiceItemModal');
    };
}
