import { _applyPosFormatters } from "./pos-formatters/pos-formatters";

export function collapseFormatter(row, cell, value, columnDef, dataContext) {
    const spacer = "<span style='display:inline-block; height:1px; width:10px'></span>";
    const fontWeight = (columnDef.formatterData && columnDef.formatterData.fontWeight) || '200';
    const badge = columnDef.formatterData && columnDef.formatterData.badge ? 'hasBadge' : '';
    const formatedValue = badge && value ? `<div class="collapsedBadge">${value}</div>` : value;
    let contentValue;
    if (!dataContext.isLeaf) {
        if (!dataContext.alone) {
            if (!dataContext._collapsed) {
                contentValue = `<div class="toggle-area ${badge}"><span class="toggle fa fa-caret-down pointer"></span>&nbsp;&nbsp;<span class='value' style="font-weight: ${fontWeight}; pointer-events: none;">${
                    badge ? '' : value}</span></div>${badge ? formatedValue : ''}`;
            } else {
                contentValue = `<div class="toggle-area ${badge}"><span class="toggle fa fa-caret-right pointer"></span>&nbsp;&nbsp;<span class='value' style="font-weight: ${fontWeight}; pointer-events: none;">${
                    badge ? '' : value}</span></div>${badge ? formatedValue : ''}`;
            }
        } else {
            contentValue = `${spacer}<span class='value' style="font-weight: ${fontWeight}">${
                badge ? '' : value}</span>${badge ? formatedValue : ''}`;
        }
    } else {
        contentValue = `${spacer}&nbsp;&nbsp;&nbsp;&nbsp;<span class='value' style="font-weight: ${fontWeight}">${
            badge ? '' : value}</span>${badge ? formatedValue : ''}`;
    }

    return _applyPosFormatters(row, cell, contentValue, columnDef, dataContext);
}
