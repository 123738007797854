import * as am5 from '@amcharts/amcharts5';
import * as am5pie from '@amcharts/amcharts5/percent';

export function createPieChart(root, chartConfig, data) {
    const chart = root.container.children.push(
        am5pie.PieChart.new(root, { layout: root.verticalLayout })
    );

    const pieSeries = chart.series.push(
        am5pie.PieSeries.new(root, {
            valueField: "value1",
            categoryField: "local",
            tooltip: am5.Tooltip.new(root, {
                labelText: "{category}: {valuePercent.formatNumber('#.#')}%",
            }),
        })
    );

    pieSeries.data.setAll(data);
    return chart;
}
