import { environment } from '#environment';
import { NotificationService } from '#services/_notification/notification.service';
import { IClientGroup, UserCacheService } from '#services/_user/app-user-cache.service';
import { UtilsService } from '#services/_utils/utils.service';
import { HttpClient } from '@angular/common/http';
import { Component, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import moment from 'moment';

@Component({
    selector: 'data-screen-crud',
    templateUrl: './data-screen.component.html',
    styleUrls: ['./data-screen.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DataAndScreenCRUDComponent {

    async: boolean = false;
    clientList: IClientGroup[] = [];
    selectedClient: IClientGroup;
    dataList = [{
        screen: 'Fechamento', enabled: false, startDate: null
    }]

    constructor(
        private _notificationService: NotificationService,
        private _userCacheService: UserCacheService,
        private _utilsService: UtilsService,
        private _http: HttpClient,
    ) {
        this.clientList = this._userCacheService.getClientGroups();
        this.selectClient(this.clientList[0]);
    }

    selectClient(client): void {
        this.async = true;
        this.selectedClient = client;
        this._getLiberationByClient().subscribe((response) => {
            this.async = false;
            this.dataList[0].enabled = response?.enabled || false; // alterar de acordo com body
            this.dataList[0].startDate = moment(response?.startDate || undefined, 'YYYY-MM-DD').format('DD/MM/YYYY'); // alterar de acordo com body
        }, (error) => {
            this.async = false;
            if (error?.status === 400) {
                this.dataList[0].enabled = false;
                this.dataList[0].startDate = null;
            } else {
                this._utilsService.errorHandler(error, 'Configuração de Dados e Telas');
            }
        });
    }

    checkClick(item: any): void {
        item.enabled = !item.enabled;
    }

    startDateChanged(date, item: any): void {
        item.startDate = date.startDate;
    }

    save(): void {
        const hasInvalidDate = this.dataList.some(data => data.enabled && !data.startDate);
        if (hasInvalidDate) {
            this._notificationService.openNotification('error', 'A data de vigência é obrigatória.');
            return;
        }
        if (!moment(this.dataList[0].startDate, 'DD/MM/YYYY', true)?.isValid() ||
            !moment(this.dataList[0].startDate, 'DD/MM/YYYY', true).isAfter(moment('31/12/2024', 'DD/MM/YYYY'))) {
            this._notificationService.openNotification('error', 'Insira uma data válida à partir de 01/01/2025.');
            return;
        }
        this.async = true;
        this._saveLiberationAndValidity().subscribe((_response) => {
            this.async = false;
            this._notificationService.openNotification('success', 'Liberação de dados atualizada', '', 3000);
        }, (error) => {
            this.async = false;
            this._utilsService.errorHandler(error, 'Configuração de Dados e Telas');
        });
    }

    private _getLiberationByClient(): Observable<any> {
        return this._http.get(`${environment.endpoints.paymentClosingService}/client-group-config/${this.selectedClient?.code}`)
    }

    private _saveLiberationAndValidity(): Observable<any> {
        const request = { enabled: this.dataList[0].enabled, startDate: moment(this.dataList[0].startDate, 'DD/MM/YYYY').format('YYYY-MM-DD') }
        return this._http.put(`${environment.endpoints.paymentClosingService}/client-group-config/${this.selectedClient?.code}`, request)
    }

}