import { FilterStateService } from '#services/_filters/filter-state.service';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UtilsService } from '#services/_utils/utils.service';
import { Component, ViewEncapsulation } from '@angular/core';
import { UserService } from "app/auth/_services/user.service";
import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { CmxDropdownService } from 'app/shared/components/cmx-dropdown/cmx-dropdown.service';
import { DROPDOWN_SETTINGS } from 'app/shared/constants/dropdown-settings.constants';
import * as _ from 'lodash';
import moment from 'moment';
import { forkJoin } from 'rxjs';
import { TYPE_COLORS } from './constants/movement-by-modal-type-colors.contant';
import { MOVEMENT_BY_MODAL_TYPES } from './constants/movement-by-modal-types.constant';
import { MovementByModalFilter } from './movement-by-modal.filter';
import { MovementByModalService } from './movement-by-modal.service';
import { AmCharts5Service, IChartConfig } from '#services/_charts/charts.service';

@Component({
    selector: 'movement-by-modal',
    templateUrl: './movement-by-modal.component.html',
    styleUrls: ['./movement-by-modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class MovementByModalComponent {
    dropdownSettings = _.cloneDeep(DROPDOWN_SETTINGS);
    helpDescription;
    filterState: MovementByModalFilter = this._filterStateService.getMovementByModalFilter();
    modalType = 'Marítima';
    modalTypes = [{ name: 'Marítima' }, { name: 'Aérea' }, { name: 'Rodoviária' }, { name: 'Veículos' }];
    currentUser$ = this.userService.currentUser$;
    async: boolean = false;
    secondaryAsync: boolean = false;

    pages = [];
    page = 0;
    dropdown: {
        clients: { options: DropdownOption[]; selected: DropdownOption[] };
        states: { options: DropdownOption[]; selected: DropdownOption[] };
        warehouses: { options: DropdownOption[]; selected: DropdownOption[] };
        referenceDateType: { options: DropdownOption[]; selected: DropdownOption[] };
    } = {
        clients: { options: [], selected: [] },
        states: { options: [], selected: [] },
        warehouses: { options: [], selected: [] },
        referenceDateType: {
            options: [
                { id: 'arrival', itemName: 'Atracação' },
                { id: 'previsionArrival', itemName: 'ETA' },
            ],
            selected: [],
        },
    };

    constructor(
        private userService: UserService,
        private _filterStateService: FilterStateService,
        private _movementByModalService: MovementByModalService,
        private _AmCharts5Service: AmCharts5Service,
        private _cmxDropdownService: CmxDropdownService,
        private _userCacheService: UserCacheService,
        private _utilsService: UtilsService
    ) {
        this._populateDropdowns();
        this.applyFilters();
        this.helpDescription = this._defineHelpDescription();
    }

    applyFilters() {
        this.async = true;
        this.secondaryAsync = true;
        this.helpDescription = this._defineHelpDescription();
        this._movementByModalService
            .getMovementByModal(this.filterState.getType(), this.filterState.getRequest())
            .subscribe(
                (data) => {
                    this.filterState.savePreferences();
                    setTimeout(() => {
                        this.loadChart(this._prepareData(data));
                        if (this.filterState.getType() === 'Marítima' || this.filterState.getType() === 'Aérea') {
                            this._getSecondaryChartData();
                        }
                    }, 300);
                },
                (error) => {
                    this._utilsService.errorHandler(error, `Movimentação ${this.filterState.getType()}`);
                    this.async = false;
                }
            );
    }

    changeTypeChartPage(direction) {
        this.page += direction;
        this._createSecondaryChart();
    }

    private _prepareData(movementData) {
        const dataList = movementData.data
            .map((data) => ({
                ...data,
                month: moment(data.yearMonth, 'YYYYMM').format('MM'),
            }))
            .sort((a, b) => a.month.localeCompare(b.month))
            .map((data) => {
                const month = this._utilsService.capitalize(moment(data.month, 'MM').format('MMMM'));
                const formattedPercentage = this._utilsService.formatNumber(data.percentage, { style: 'percent' });
                const label = `${month}<br>${formattedPercentage}`;
                return { ...data, month, label };
            });
        return { ...movementData, data: dataList };
    }

    private _getSecondaryChartData(): void {
        this._resetPages();
        this._movementByModalService
            .getMovementByStateAndWarehouse(this.filterState.getType(), this.filterState.getStateAndWarehouseRequest())
            .subscribe(
                (data) => {
                    const responseData = data.data;
    
                    if (!responseData || responseData.length === 0) {
                        this.secondaryAsync = false;
                        return;
                    }

                    let pageSession = 0;
                    while (responseData.length) {
                        this.pages[pageSession] = responseData.slice(0, 3).map((data, index) => {
                            const fractionDigits = this.filterState.getType() !== 'Aérea' ? 0 : 2;
                            const formattedPercentage = this._utilsService.formatNumber(data.percentage, {
                                style: 'percent',
                                minimumFractionDigits: fractionDigits,
                                maximumFractionDigits: fractionDigits,
                            });
                            const color = TYPE_COLORS[index] || TYPE_COLORS[0];
                            return this.filterState.getSecondaryType() === 'Estado'
                                ? {
                                      ...data,
                                      color,
                                      label: `${data.code}\n${formattedPercentage}`,
                                  }
                                : {
                                      ...data,
                                      color,
                                      label: `${data.code}\n${data.name.split('-')[1]}\n${formattedPercentage}`,
                                  };
                        });
                        
                        responseData.splice(0, 3);
                        pageSession++;
                    }
    
                    if (this.pages[0]) {
                        this._createSecondaryChart();
                    } 

                    this.secondaryAsync = false;
                },
                (error) => {
                    this.secondaryAsync = false;
                    this._utilsService.errorHandler(error, `Movimentação ${this.filterState.getType()}`);
                }
            );
    }  

    private _populateDropdowns() {
        this.dropdown.clients.options = this._cmxDropdownService.toDropdownItems(
            this._userCacheService.getClientGroups()
        );
        forkJoin(
            this._movementByModalService.getLogisticsStates(),
            this._movementByModalService.getLogisticsWarehouses()
        ).subscribe(
            ([states, warehouses]) => {
                this.dropdown.states.options = this._cmxDropdownService.toDropdownItems(states, false);
                this.dropdown.warehouses.options = this._cmxDropdownService.toDropdownItems(
                    [...warehouses.map((d) => ({ ...d, name: `${d.code}-${d.name}` }))],
                    false
                );
            },
            (error) => {
                this._utilsService.errorHandler(error, `Movimentação ${this.filterState.getType()}`);
            }
        );
    }

    loadChart(dataChart) {
        setTimeout(() => {
            const period = dataChart.period.split('-');
            const year1 = period[0];
            const year2 = period[1];
    
            const data = dataChart.data.map((item) => ({
                month: `${item.month}\n${item.percentage.toFixed(2)}%`,
                [year1]: item.previousValue,
                [year2]: item.value,
                percentage: item.percentage,
            }));
    
            const chartConfig: IChartConfig = {
                chartId: 'movementChart',
                chartType: 'XYChart',
                axis: [
                    { 
                        axisPosition: 'x', 
                        categoryField: 'month' 
                    },
                    { 
                        axisPosition: 'y', 
                        categoryField: 'valueYField' 
                    },
                ],
                axisTitle: {
                    y: this.filterState.getType() === 'Marítima'
                        ? this.filterState.getTypeView()
                        : MOVEMENT_BY_MODAL_TYPES[this.filterState.getType()].subType,
                },
                content: [
                    { name: year1, valueYField: year1, valueXField: 'month' },
                    { name: year2, valueYField: year2, valueXField: 'month' },
                ],
                showLegend: true,
                showTooltip: false,
                showValuesAboveBars: true,
                labelConfig: {
                    fontSize: 10,
                    fontWeight: 'bold',
                },
                colors: ['#D3D3D3', '#4C5A79'],
            };
    
            this._AmCharts5Service.createChart(chartConfig, { data });
            this.async = false;
        }, 200);
    }
    
    private _createSecondaryChart(): void {
        if (!this.pages[this.page] || this.pages[this.page].length === 0) {
            return;
        }
    
        const dataProvider = this.pages[this.page];
    
        const chartConfig: IChartConfig = {
            chartId: 'secondaryChart', 
            chartType: 'XYChart', 
            axis: [
                {
                    axisPosition: 'x',
                    categoryField: 'label', 
                },
                {
                    axisPosition: 'y',
                    categoryField: 'value',
                },
            ],
            axisTitle: {
                y: this.filterState.getType() === 'Marítima' 
                    ? this.filterState.getTypeView()
                    : MOVEMENT_BY_MODAL_TYPES[this.filterState.getType()].subType,
            },
            content: [
                {
                    name: 'TEUs',
                    valueYField: 'value',
                    valueXField: 'label',
                },
            ],
            showLegend: false,
            showTooltip: false,
            showValuesAboveBars: true,
            changeColors: true,
            labelConfig: {
                fontSize: 10,
                fontWeight: 'bold',
            },
            colors: ['#4C5A79'],
        };
    
        this._AmCharts5Service.createChart(chartConfig, { data: dataProvider });
    }
  
    private _resetPages() {
        this.pages = [];
        this.page = 0;
    }

    private _defineHelpDescription(): object[] {
        if (this.filterState.getType() === 'Marítima') {
            return [
                {
                    type: 'text',
                    hasLineAfter: true,
                    value: 'Informa o volume de TEU’s ou Containers movimentados ao longo do ano.',
                },
                { type: 'text', hasLineAfter: true, value: 'Compara a movimentação realizada com o período anterior.' },
                {
                    type: 'text',
                    hasLineAfter: true,
                    value: 'Permite analisar se houve aumento ou diminuição no volume embarcado por Recinto e Estado.',
                },
                { type: 'text', value: 'Dados com base na data de chegada dos embarques.' },
            ];
        } else if (this.filterState.getType() === 'Aérea') {
            return [
                {
                    type: 'text',
                    hasLineAfter: true,
                    value: 'Informa o volume de Toneladas Aéreas movimentados ao longo do ano.',
                },
                { type: 'text', hasLineAfter: true, value: 'Compara a movimentação realizada com o período anterior.' },
                {
                    type: 'text',
                    hasLineAfter: true,
                    value: 'Permite analisar se houve aumento ou diminuição no volume embarcado por Recinto e Estado.',
                },
                { type: 'text', value: 'Dados com base na data de chegada dos embarques.' },
            ];
        } else if (this.filterState.getType() === 'Rodoviária') {
            return [
                {
                    type: 'text',
                    hasLineAfter: true,
                    value: 'Informa o volume de Carretas Internacionais movimentados ao longo do ano.',
                },
                { type: 'text', hasLineAfter: true, value: 'Compara a movimentação realizada com o período anterior.' },
                { type: 'text', value: 'Dados com base na data de chegada dos embarques.' },
            ];
        } else if (this.filterState.getType() === 'Veículos') {
            return [
                {
                    type: 'text',
                    hasLineAfter: true,
                    value: 'Informa o volume de Veículos movimentados ao longo do ano.',
                },
                { type: 'text', hasLineAfter: true, value: 'Compara a movimentação realizada com o período anterior.' },
                { type: 'text', value: 'Dados com base na data de chegada dos embarques.' },
            ];
        }
    }
    
}
