import { ReportPreferencesService } from '#services/_report-preferences/report-preferences.service';
import { TransportModalService } from '#services/_transportModal/transportModal.service';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { Injectable } from '@angular/core';
import { SLA_STATUS } from 'app/shared/constants/sla-status.constants';
import * as _ from 'lodash';

@Injectable()
export class DatamartFiltersService {

    constructor(
        private _reportPreferencesService: ReportPreferencesService,
        private _transportModalService: TransportModalService,
        private _userCacheService: UserCacheService,
        ) { }

    defineVisualFilters(filters, generatorId, currentUser) {
        const fupStatus = filters.field && filters.field.length === 1 ? filters.field : [];
        const translatedModals = this._transportModalService.mapToPortugueseModalNames(filters.modalTransportations);
        switch (generatorId) {
            // FUP IMP
            case 'fup':
                return {
                    clientGroups: filters.clientGroups && filters.clientGroups.length ? filters.clientGroups : [],
                    modalTransportations: filters.modalTransportations && filters.modalTransportations.length ? translatedModals : [],
                    referenceDateType: filters.referenceDateName && filters.referenceDateName.length ? filters.referenceDateName : ['Criação'],
                    status: filters.status ? filters.status : [],
                    fields: fupStatus[0].replace('status.code::', '').split(',') || [],
                    subsidiaryNames: filters.subsidiaryNames && filters.subsidiaryNames.length ? filters.subsidiaryNames : [],
                    period: ['2 anos (padrão)']
                }
            // FUP SKU
            case 'fup-sku':
                return {
                    clientGroups: filters.clientGroups && filters.clientGroups.length ? filters.clientGroups : [],
                    modalTransportations: filters.modalTransportations && filters.modalTransportations.length ? translatedModals : [],
                    referenceDateType: filters.referenceDateName && filters.referenceDateName.length ? filters.referenceDateName : ['Criação'],
                    status: filters.status ? filters.status : [],
                    fields: fupStatus[0].replace('consolidatedImp.status.code::', '').split(',') || [],
                }
            // Custos
            case 'expenses-detail':
                const internal = currentUser.has('INTERNAL_FILTERS') && (!filters.clientGroups?.length || filters.clientGroups?.length > 1);
                return {
                    clientGroups: filters.clientGroups && filters.clientGroups.length ? filters.clientGroups : [],
                    profitCenters: filters.profitCenterNames && filters.profitCenterNames.length ? filters.profitCenterNames : [],
                    showUc: filters.showUc && filters.showUc.length && filters.showUc[0] === 'true' ? ['Ligado'] : ['Desligado'],
                    showDuplicates: filters.context && filters.context.length && (filters.context[0].toUpperCase() === 'IMP' || filters.context[0].toUpperCase() === 'AUTOMOTIVE') &&
                        filters.showDuplicates && filters.showDuplicates.length && filters.showDuplicates[0] === 'true' ? ['Ligado'] : ['Desligado'],
                    context: filters.context && filters.context.length ? [filters.context[0].toUpperCase() === 'ITEM' ? 'NF' : filters.context[0].toUpperCase()] : [],
                    models: filters.models && filters.models.length ? filters.models : [],
                    colors: filters.colors && filters.colors.length ? filters.colors : [],
                    modelYears: filters.modelYears && filters.modelYears.length ? filters.modelYears : [],
                    manufacturerYears: filters.manufacturerYears && filters.manufacturerYears.length ? filters.manufacturerYears : [],
                    // Padrão para Clientes 24 meses (2 anos).
                    // Padrão para Internos 12 meses (1 ano).
                    // Possibilidades para BYD: (2 anos) ou (3 meses) em _verifyLinkOptionsEnabled(), costs-detailed.component.ts
                    period: internal ? ['1 ano (padrão)'] : filters.periodicity && filters.periodicity.length && filters.periodicity[0] === '30' ? ['3 meses'] : ['2 anos (padrão)'],
                }
            // Sla Status
            case 'slas-status-detail':
                let slaStatus = filters.slaStatus && filters.slaStatus.length ? filters.slaStatus[0].split(',') : [];
                return {
                    clientCodes: filters.clientGroupsNames && filters.clientGroupsNames.length ? filters.clientGroupsNames : [],
                    modalTransportations: filters.modalTransportations && filters.modalTransportations.length ? translatedModals : [],
                    referenceDateType: filters.referenceDateType && filters.referenceDateType.length && filters.referenceDateType[0] === 'impIssueDate' ? ['Criação'] : ['Faturamento'],
                    kpis: filters.kpisNames && filters.kpisNames.length ? filters.kpisNames : [],
                    slaStatus: slaStatus && slaStatus.length ? slaStatus?.map((status) => SLA_STATUS[status]) : [],
                    period: ['1 ano (padrão)']
                }
            // FUP UNIFIED IMP
            case 'fup-unified-imp':
                return {
                    clientGroups: filters.clientGroups && filters.clientGroups.length ? filters.clientGroups : [],
                    modalTransportations: filters.modalTransportations && filters.modalTransportations.length ? translatedModals : [],
                    referenceDateType: filters.referenceDateName && filters.referenceDateName.length ? filters.referenceDateName : ['Criação'],
                    status: filters.status ? filters.status : [],
                    fields: fupStatus[0].replace('status.code::', '').split(',') || [],
                    subsidiaryNames: filters.subsidiaryNames && filters.subsidiaryNames.length ? filters.subsidiaryNames : [],
                    period: (filters.interval && filters.interval == 24) ? ['2 anos (padrão)'] : ['1 ano (padrão)']
                }
            // FUP UNIFIED SKU
            case 'fup-unified-sku':
                return {
                    clientGroups: filters.clientGroups && filters.clientGroups.length ? filters.clientGroups : [],
                    modalTransportations: filters.modalTransportations && filters.modalTransportations.length ? translatedModals : [],
                    referenceDateType: filters.referenceDateName && filters.referenceDateName.length ? filters.referenceDateName : ['Criação'],
                    status: filters.status ? filters.status : [],
                    fields: fupStatus[0].replace('consolidatedImp.status.code::', '').split(',') || [],
                    subsidiaryNames: filters.subsidiaryNames && filters.subsidiaryNames.length ? filters.subsidiaryNames : [],
                    period: (filters.interval && filters.interval == 24) ? ['2 anos (padrão)'] : ['1 ano (padrão)']
                }
            case 'client-invoice-receivable':
                return {
                    type: filters.type ? filters.type : [],
                    invoiceStatus: filters?.type[0] === 'Nota Fiscal' ? filters.invoiceStatus || [] : filters.installmentStatus || [],
                    invoiceDate: currentUser.has('INTERNAL_FILTERS') ? ['6 meses (padrão)'] : ['1 ano (padrão)'],
                    dueDate: currentUser.has('INTERNAL_FILTERS') ? ['1 anos (padrão)'] : ['2 anos (padrão)'],
                    clientGroups: filters.clientGroups && filters.clientGroups.length ? filters.clientGroups : [],
                    profitCenters: filters.profitCenters && filters.profitCenters.length ? filters.profitCenters : [],
                    companies: filters.companies && filters.companies.length ? filters.companies : [],
                    subsidiaryNames: filters.subsidiaryNames && filters.subsidiaryNames.length ? filters.subsidiaryNames : [],
                }
        }
    }

    prepareFiltersToService(generatorId, filterState, currentUser, selectedSubItem?) {
        const groups = typeof filterState.getClientGroups === 'function' ? filterState.getClientGroups() : filterState.getClientGroupCodes();
        const internal = currentUser.has('INTERNAL_FILTERS') && ((!groups || groups.length === 0) || (groups.length === this._userCacheService.getClientGroups().length));
        const allClientsSelected = this._checkAllClientsSelected(currentUser, filterState);
        switch (generatorId) {
            // FUP IMP
            case 'fup':
                return {
                    userId: [currentUser.data.uid],
                    isCustomer: [currentUser.has('FOLLOWUP_COMPLETE')  ? false : true],
                    modelName: [selectedSubItem.length ? selectedSubItem[0].itemName : ''],
                    modalTransportations: [filterState.getModals()?.map((modals) => this._transportModalService.getEnglishName(modals.itemName)).join(', ')],
                    referenceDateType: filterState.getReferenceDateType()?.map((c) => { return c['id']; }) || [],
                    referenceDateName: filterState.getReferenceDateType()?.map((c) => { return c['itemName']; }) || [],
                    clientGroups: currentUser.has('INTERNAL_FILTERS') ? filterState.getClientGroups()?.map((c) => { return c['itemName']; }) || [] : null,
                    clientGroupCodes: currentUser.has('INTERNAL_FILTERS') ? (filterState.getClientGroups()?.map((c) => c['id']) || []) : this._mergeUserPermission(currentUser),
                    status: filterState.getStatus().length === 1 ? [filterState.getStatus()[0].name] : filterState.getStatus()?.map((c) => c['name']),
                    field: [`status.code::${filterState.getStatus()?.map((c) => c['code']).join(',')}` ],
                    subsidiaryNames: filterState.getSubsidiaryNames()?.map((c) => { return c['id']; }) || [],
                    sort: ['imp,asc'],
                    interval: ['24'],
                };
            // FUP SKU
            case 'fup-sku':
                return {
                    userId: [currentUser.data.uid],
                    isCustomer: [currentUser.has('FOLLOWUP_COMPLETE')  ? false : true],
                    modelName: [selectedSubItem.length ? selectedSubItem[0].itemName : ''],
                    modalTransportations: [filterState.getModals()?.map((modals) => this._transportModalService.getEnglishName(modals.itemName)).join(', ')],
                    referenceDateType: filterState.getReferenceDateType()?.map((c) => { return c['id']; }) || [],
                    referenceDateName: filterState.getReferenceDateType()?.map((c) => { return c['itemName']; }) || [],
                    clientGroups: currentUser.has('INTERNAL_FILTERS') ? filterState.getClientGroups()?.map((c) => { return c['itemName']; }) || [] : null,
                    clientGroupCodes: currentUser.has('INTERNAL_FILTERS') ? (filterState.getClientGroups()?.map((c) => c['id']) || []) : this._mergeUserPermission(currentUser),
                    status: filterState.getStatus().length === 1 ? [filterState.getStatus()[0].name] : filterState.getStatus()?.map((c) => c['name']),
                    field: [`consolidatedImp.status.code::${filterState.getStatus()?.map((c) => c['code']).join(',')}`],
                    sort: ['customerSku,asc'],
                    interval: ['24'],
                }
            // Custos
            case 'expenses-detail':
                return  {
                    allClientsSelected,
                    preferencesId: [this._reportPreferencesService?.selectedRightButton?.code],
                    clientGroups: currentUser.has('INTERNAL_FILTERS') ? filterState.getClientGroups()?.map((c) => { return c['itemName']; }) || [] : null,
                    clientGroupCodes: currentUser.has('INTERNAL_FILTERS') ? (filterState.getClientGroups()?.map((c) => c['id']) || []) : this._mergeUserPermission(currentUser),
                    profitCenterNames: !filterState.getAutomotiveMode() ? filterState.getProfitCenters()?.map((c) => { return c['itemName']; }) || [] : [],
                    profitCenters: !filterState.getAutomotiveMode() ? filterState.getProfitCenters()?.map((c) => { return c['id']; }) || [] : [],
                    models: filterState.getAutomotiveMode() ? filterState.getModels()?.map((c) => { return c['id']; }) || [] : [],
                    colors: filterState.getAutomotiveMode() ? filterState.getColors()?.map((c) => { return c['id']; }) || [] : [],
                    modelYears: filterState.getAutomotiveMode() ? filterState.getModelYears()?.map((c) => { return c['id']; }) || []: [],
                    manufacturerYears: filterState.getAutomotiveMode() ? filterState.getManufacturerYears()?.map((c) => { return c['id']; }) || [] : [],
                    context: filterState.getAutomotiveMode() === 'Ligado' ? ['automotive'] : [filterState.getContext()],
                    showUc: [filterState.getShowUc()],
                    showDuplicates: [(filterState.getShowDuplicatas() && (filterState.getAutomotiveMode() === 'Ligado' || filterState.getContext() === 'imp' )) ? true : false],
                    // Padrão para Clientes (120 minutos) - Range de Dados de 24 Meses.
                    // Padrão para Internos (1x ao dia) - Range de Dados de 12 Meses ano.
                    // Possibilidades para BYD: em _verifyLinkOptionsEnabled(), costs-detailed.component.ts
                    // (120 minutos) - Range de Dados de 24 Meses.
                    // (30 minutos) - Range de Dados de 3 Meses.
                    periodicity: internal ? ['1'] : [filterState.getPeriodicity()],
                    periodicityType: internal ? ['DAY'] : ['MINUTES'],
                    interval: internal ? ['12'] : [filterState.getInterval()],
                }
            // Sla Status
            case 'slas-status-detail':
                return {
                    allClientsSelected,
                    clientGroupCodes: currentUser.has('INTERNAL_FILTERS') ? (filterState.getClientGroups()?.map((c) => c['id']) || []) : this._mergeUserPermission(currentUser),
                    clientGroupsNames: currentUser.has('INTERNAL_FILTERS') ? filterState.getClientGroups()?.map((c) => { return c['itemName']; }) || [] : null,
                    modalTransportations: [filterState.getModals()?.map((modals) => this._transportModalService.getEnglishName(modals.itemName)).join(', ')],
                    referenceDateType: [filterState.getTypeSwitch()],
                    kpis: filterState.getSelectedKpi()?.map((kpi) => { return kpi['id']; }) || [],
                    kpisNames: filterState.getSelectedKpi()?.map((kpi) => { return kpi['itemName']; }) || [],
                    slaStatus: [filterState.getToggleStatus().join(',')],
                    intervalValue: ['12']
                }
            // FUP UNIFIED IMP
            case 'fup-unified-imp':
                return {
                    allClientsSelected,
                    userId: [currentUser.data.uid],
                    isCustomer: [currentUser.has('FOLLOWUP_COMPLETE')  ? false : true],
                    modelId: [selectedSubItem.length ? selectedSubItem[0].id : ''],
                    modelName: [selectedSubItem.length ? selectedSubItem[0].itemName : ''],
                    modalTransportations: filterState.getModals()?.map((modals) => this._transportModalService.getEnglishName(modals.itemName)) || [],
                    referenceDateType: filterState.getReferenceDateType()?.map((c) => { return c['id']; }) || [],
                    referenceDateName: filterState.getReferenceDateType()?.map((c) => { return c['itemName']; }) || [],
                    clientGroups: currentUser.has('INTERNAL_FILTERS') ? filterState.getClientGroups()?.map((c) => { return c['itemName']; }) || [] : null,
                    clientGroupCodes: currentUser.has('INTERNAL_FILTERS') ? (filterState.getClientGroups()?.map((c) => c['id']) || []) : this._mergeUserPermission(currentUser),
                    status: filterState.getStatus().length === 1 ? [filterState.getStatus()[0].name] : filterState.getStatus()?.map((c) => c['name']),
                    field: [`status.code::${filterState.getStatus()?.map((c) => c['code']).join(',')}` ],
                    subsidiaryNames: filterState.getSubsidiaryNames()?.map((c) => { return c['id']; }) || [],
                    sort: ['imp,asc'],
                    interval: internal ? ['12'] : ['24'],
                };
            // FUP UNIFIED SKU
            case 'fup-unified-sku':
                return {
                    allClientsSelected,
                    userId: [currentUser.data.uid],
                    isCustomer: [currentUser.has('FOLLOWUP_COMPLETE')  ? false : true],
                    modelId: [selectedSubItem.length ? selectedSubItem[0].id : ''],
                    modelName: [selectedSubItem.length ? selectedSubItem[0].itemName : ''],
                    modalTransportations: filterState.getModals()?.map((modals) => this._transportModalService.getEnglishName(modals.itemName)) || [],
                    referenceDateType: filterState.getReferenceDateType()?.map((c) => { return c['id']; }) || [],
                    referenceDateName: filterState.getReferenceDateType()?.map((c) => { return c['itemName']; }) || [],
                    clientGroups: currentUser.has('INTERNAL_FILTERS') ? filterState.getClientGroups()?.map((c) => { return c['itemName']; }) || [] : null,
                    clientGroupCodes: currentUser.has('INTERNAL_FILTERS') ? (filterState.getClientGroups()?.map((c) => c['id']) || []) : this._mergeUserPermission(currentUser),
                    status: filterState.getStatus().length === 1 ? [filterState.getStatus()[0].name] : filterState.getStatus()?.map((c) => c['name']),
                    field: [`consolidatedImp.status.code::${filterState.getStatus()?.map((c) => c['code']).join(',')}`],
                    subsidiaryNames: filterState.getSubsidiaryNames()?.map((c) => { return c['id']; }) || [],
                    sort: ['customerSku,asc'],
                    interval: internal ? ['12'] : ['24'],
                }
            // Contas a Pagar/Receber
            case 'client-invoice-receivable':
                const baseObject = {
                    allClientsSelected,
                    type: [filterState.getType()],
                    clientGroups: currentUser.has('INTERNAL_FILTERS') ? filterState.getClientGroupCodes()?.map((c) => { return c['itemName']; }) || [] : null,
                    clientGroupCodes: currentUser.has('INTERNAL_FILTERS') ? (filterState.getClientGroupCodes()?.map((c) => c['id']) || []) : this._mergeUserPermission(currentUser),
                    profitCenters: currentUser.has('INTERNAL_FILTERS') ? filterState.getProfitCenters()?.map((c) => { return c['itemName']; }) || [] : null,
                    companies: currentUser.has('INTERNAL_FILTERS') ? filterState.getCompanies()?.map((c) => { return c['itemName']; }) || [] : null,
                    subsidiaryNames: currentUser.has('INTERNAL_FILTERS') ? filterState.getSubsidiaryNames()?.map((c) => { return c['itemName']; }) || [] : null,
                    generatorId: filterState.getType() === 'Nota Fiscal' ? ['datamart-client-invoice-receivable'] : ['datamart-client-invoice-receivable-installment'],
                    interval: currentUser.has('INTERNAL_FILTERS') ? ['6'] : ['12'],
                }
                baseObject[filterState.getType() === 'Nota Fiscal' ? 'invoiceStatus' : 'installmentStatus'] = 
                    filterState.getStatus().length === 1 ? [filterState.getStatus()[0].name] : [filterState.getStatus()?.map((c) => c.name?.replace('À Vencer', 'Em Aberto'))?.join(',')];
                return baseObject;
        }

    }
    
    private _checkAllClientsSelected(currentUser, filterState) {
        const allGroups = this._userCacheService.getClientGroups() || [];
      
        let selectedGroups = [];
        
        if (currentUser.has('INTERNAL_FILTERS')) {
          if (typeof filterState.getClientGroups === 'function') {
            selectedGroups = filterState
              .getClientGroups()
              ?.map((c) => c['id']) || [];
          } else if (typeof filterState.getClientGroupCodes === 'function') {
            selectedGroups = filterState
              .getClientGroupCodes()
              ?.map((c) => c['id']) || [];
          }
        } else {
          selectedGroups = this._mergeUserPermission(currentUser);
        }

        return (selectedGroups.length === 0 || selectedGroups.length === allGroups.length);
    }
      
    private _mergeUserPermission(currentUser) {
        const groups = this._userCacheService.getClientGroups();
        if (currentUser.clientGroups.length === 1 && currentUser.clientGroups[0] === "all") {
            return groups.map(group => group.code); // recupera a lista do retorno de clientes da requisição 
        }
        return currentUser.clientGroups;       
    }
}
