import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5locales_pt_BR from "@amcharts/amcharts5/locales/pt_BR";

export function createDraggableRangeChart(root, chartConfig, data, visibleDataPoints) {

    root.locale = am5locales_pt_BR; 

    const chart = root.container.children.push(
        am5xy.XYChart.new(root, {
            panX: true,
            panY: true,
            wheelX: 'panX',
            wheelY: 'zoomX',
            pinchZoomX: true,
            paddingLeft: 20,
        })
    );

    let cursor = chart.set('cursor', am5xy.XYCursor.new(root, {
        behavior: "zoomX",
        lineX: am5.Line.new(root, {
            stroke: am5.color(0x68dc76),
            strokeWidth: 1,
        }),
        lineY: am5.Line.new(root, {
            stroke: am5.color(0x68dc76),
            strokeWidth: 1,
        })
    }));
    cursor.lineX.set('forceHidden', false);
    cursor.lineY.set('forceHidden', false);

    let xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
            baseInterval: { timeUnit: 'day', count: 1 },
            renderer: am5xy.AxisRendererX.new(root, { minGridDistance: 90 }),
            tooltip: am5.Tooltip.new(root, {}),
        })
    );

    let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {}),
            tooltip: am5.Tooltip.new(root, {}),
        })
    );

    let series = chart.series.push(
        am5xy.LineSeries.new(root, {
            name: chartConfig.content[0].name,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: chartConfig.content[0].valueYField,
            valueXField: chartConfig.content[0].valueXField,
            tooltip: am5.Tooltip.new(root, { labelText: '{valueY}' }),
            snapTooltip: true,
        })
    );

    series.set("tooltip", am5.Tooltip.new(root, {
        labelText: "{valueY}",
        pointerOrientation: "horizontal",
        autoTextColor: false,
        dy: -5,
        tooltipY: am5.percent(50),
        keepTargetHover: true,
        delayShow: 0,
        delayHide: 100,
    }));

    series.fills.template.setAll({ fillOpacity: 0.2, visible: true });
    series.data.setAll(data);

    return chart;
}
