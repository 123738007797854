import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import * as _ from 'lodash';

import { environment } from '#environment';
import { PROJECT_PRICING_MULTIBUTTONS } from './constants/project-pricing-multiButtons.constant';

import { CustomDialogService } from 'app/shared/components/cmx-custom-dialog/custom-dialog.service';
import { UserService } from 'app/auth/_services';
import { DownloadService } from '#services/_download/download.service';
import { UtilsService } from '#services/_utils/utils.service';


@Injectable()
export class ProjectPricingService {
    dialogType: string = 'Solicitação';
    dialogRevisionPricingNumber;
    confirmationType: string;
    
    popoverData;
    popoverTopDistance;
    popoverLeftDistance;
    forceFadeOut: boolean = false;

    selectedProposeId;

    asyncPricing: boolean;

    private _selectedRow;
    private _selectedProposes;
    private _pricingDetails;
    private _consolidatedRows = [];
    private _selectedProposalItem: Subject<any> = new Subject<any>();
    _selectedProposalItem$ = this._selectedProposalItem.asObservable();

    constructor(
        private _http: HttpClient,
        private _userService: UserService,
        private _customDialogService: CustomDialogService,
        private _utilsService: UtilsService,
        private _downloadService: DownloadService
    ) {}

    getSelectedRow() {
        return this._selectedRow;
    }
    getSelectedProposes() {
        return this._selectedProposes;
    }
    getPricingDetail() {
        return this._pricingDetails;
    }
    setSelectedProposalItem(data) {
        this._selectedProposalItem.next(data);
    }

    setSelectedRow(selectedRow) {
        this._selectedRow = selectedRow;
    }
    setSelectedProposes(pricingId, selectedProposes) {
        this._selectedProposes = _.cloneDeep(selectedProposes);
        this._selectedProposes.forEach((element) => {
            element.id = `${pricingId} - ${element.proposalId}`;
            element.popoverData = [
                { title: 'Observação: ', value: element.note || '' },
                { title: 'Observação (Comexport): ', value: element.internalNote || '' },
            ];
            element.hasNote = element.note || element.internalNote ? 'true' : 'false';
        });
    }
    setPricingDetail(pricingDetails) {
        this._pricingDetails = [
            {
                title: 'Cliente Final Não Contribuinte do ICMS',
                value: pricingDetails.isClientIcmsTaxpayer ? 'SIM' : 'NÃO',
            },
            {
                title: 'Estado Contribuinte',
                value: pricingDetails.fiscalState || '-',
            },
            {
                title: 'Rate',
                value: pricingDetails.rate.toString().replace('.', ',') || '-',
            },
            {
                title: 'Margem',
                value: pricingDetails.margin || '-',
                formatter: 'percentage',
            },
            {
                title: 'Risco Sacado',
                value: pricingDetails.receivableAnticipation || '-',
                formatter: 'percentage',
            },
            {
                title: 'Local de Entrega',
                value: pricingDetails.deliveryLocation || '-',
            },
            {
                title: 'Desconto Comercial',
                value: pricingDetails.commercialDiscount ? 
                    this._utilsService.formatNumber(pricingDetails.commercialDiscount, { style: 'currency', currency: 'BRL' }).replace('R$', '$') : '-',
            },
            {
                title: 'Observação',
                value: pricingDetails.note || '-',
            },
            {
                title: 'Observação Solicitação PO',
                value: pricingDetails.poNote || '-',
            },
        ];
    }

    downloadFile(type, pricingId, proposeId?): Observable<any> {
        const quote = type === 'proposal' ? `proposal/${proposeId}` : `request/${proposeId}`;
        const url = `${environment.endpoints.pricingService}/pricing/${pricingId}/${quote}/file`;
        const downloadName = type === 'proposal' ? `Proposta-${proposeId}` : `Solicitação-${pricingId}`;
        const options = this._downloadService.getSourceOptions();
        const download = this._http.post(url, {}, options);
        return this._downloadService.downloadFrom(download, downloadName);
    }

    getPricingList(request): Observable<any> {
        return this._http.get(`${environment.endpoints.pricingService}/pricing/filter`, request);
    }

    createPricing(data, files): Observable<any> {
        const request = new FormData();
        for (const [key, value] of Object.entries(data)) {
            request.append(`${key}`, `${value || ''}`);
        }
        for (const file of files) {
            request.append('file', file, file.name);
        }
        return this._http.post(`${environment.endpoints.pricingService}/pricing`, request, {});
    }

    createPropose(pricingId, proposeId, clientGroupCode, data): Observable<any> {
        const request = new FormData();
        for (const file of data.file) {
            request.append('file', file, file.name);
        }
        request.append('totalQuotationValue', data.totalQuotationValue);
        request.append('internalNote', data.internalNote || '');
        return this._http.post(
            `${environment.endpoints.pricingService}/pricing/${pricingId}/proposal/${proposeId}`,
            request,
            {
                headers: new HttpHeaders().set('X-Requested-Client-Groups', clientGroupCode),
            }
        );
    }

    updateRequest(pricingId, proposalId, clientGroupCode, formData, file) {
        const request = new FormData();
        
        for (const [key, value] of Object.entries(formData)) {
            request.append(key, value != null ? value.toString() : '');
        }
    
        if (file && file.length > 0) {
            for (const singleFile of file) {
                request.append('file', singleFile, singleFile.name);
            }
        }
    
        const headers = new HttpHeaders().set('X-Requested-Client-Groups', clientGroupCode);
        const url = `${environment.endpoints.pricingService}/pricing/${pricingId}/edit/${proposalId}`;
    
        return this._http.put(url, request, { headers });
    }

    requestReview(pricingId, proposeId, clientGroupCode, data): Observable<any> {
        const request = new FormData();
        for (const file of data.file) {
            request.append('file', file, file.name);
        }
        request.append('totalQuotationValue', data.totalQuotationValue);
        request.append('internalNote', data.internalNote || '');
        return this._http.post(
            `${environment.endpoints.pricingService}/pricing/${pricingId}/proposal-review-po/${proposeId}`,
            request,
            {
                headers: new HttpHeaders().set('X-Requested-Client-Groups', clientGroupCode),
            }
        );
    }

    requestReviewAprovedPo(pricingId, proposalId, clientGroupCode, formData, file) {
        const request = new FormData();
        
        for (const [key, value] of Object.entries(formData)) {
            request.append(key, value != null ? value.toString() : '');
        }
    
        if (file && file.length > 0) {
            for (const singleFile of file) {
                request.append('file', singleFile, singleFile.name);
            }
        }
    
        const headers = new HttpHeaders().set('X-Requested-Client-Groups', clientGroupCode);
        const url = `${environment.endpoints.pricingService}/pricing/${pricingId}/request-review-aproved-po/${proposalId}`;
    
        return this._http.post(url, request, { headers });
    }

    editProposalReview(pricingId, proposalId, clientGroupCode, formData, file) {
        const request = new FormData();
        
        for (const [key, value] of Object.entries(formData)) {
            request.append(key, value != null ? value.toString() : '');
        }
    
        if (file && file.length > 0) {
            for (const singleFile of file) {
                request.append('file', singleFile, singleFile.name);
            }
        }
    
        const headers = new HttpHeaders().set('X-Requested-Client-Groups', clientGroupCode);
        const url = `${environment.endpoints.pricingService}/pricing/${pricingId}/edit-proposal-review/${proposalId}`;
    
        return this._http.post(url, request, { headers });
    }
    
    sendExistingCotation(clientGroupCode, cotationNumbers): Observable<any> {
        const url = `${environment.endpoints.pricingService}/pricing/existing-cotation?clientGroupCode=${clientGroupCode}`;
        return this._http.post(url, cotationNumbers);
    }

    requestRevision(pricingId, proposeId, data) {
        const request = new FormData();
        if (data.file) {
            for (const file of data.file) {
                request.append('file', file, file.name);
            }
        }
        request.append('note', data.note || '');
        request.append('priority', data.priority || false);
        return this._http.post(
            `${environment.endpoints.pricingService}/pricing/${pricingId}/proposal/${proposeId}/review`,
            request,
            {}
        );
    }

    updatablePropose(pricingId, proposeId, data) {
        const request = new FormData();
        if (data.file) {
            for (const file of data.file) {
                request.append('file', file, file.name);
            }
        }
        request.append('internalNote', data.internalNote || '');
        request.append('totalQuotationValue', data.totalQuotationValue);
        return this._http.put(
            `${environment.endpoints.pricingService}/pricing/${pricingId}/proposal/${proposeId}`,
            request,
            {}
        );
    }

    approvePropose(pricingId, proposeId, data) {
        const request = new FormData();
        request.append('poNote', data.poNote || '');
        return this._http.put(
            `${environment.endpoints.pricingService}/pricing/${pricingId}/proposal/${proposeId}/approve`,
            request,
            {}
        );
    }

    deletePricing(pricingId, clientGroupCode, data) {
        const headers = new HttpHeaders();
        const request = new FormData();
        request.append('cancelJustification', data.cancelJustification || '');
        headers.set('X-Requested-Client-Groups', clientGroupCode);
        const requestJustification = {
            headers: headers,
            body: request
        }
        return this._http.delete(`${environment.endpoints.pricingService}/pricing/${pricingId}`,
        requestJustification);
    }

    callDownloadFile(item, column) {
        
        const proposalId = item.proposalId ? item.proposalId : item.proposals[0].proposalId;
        if (
            (column === 'numberOfProposals' || column === 'proposalFileIds') &&
            this.getSelectedRow().proposals[0] &&
            this.getSelectedRow().proposals[0].proposalId &&
            item.proposalFileIds &&
            item.proposalFileIds?.length &&
            item.status !== 'Aguard. Proposta'
        ) {
            this.downloadFile('proposal', this.getSelectedRow().pricingId, proposalId).subscribe(
                (response) => {
                    this.asyncPricing = false;
                },
                (error) => {
                    this.asyncPricing = false;
                    this._utilsService.errorHandler(error, 'Baixar Proposta');
                }
            );
        }
        if (
            (column === 'numberOfRequests' || column === 'requestFiles') &&
            item.requestFiles &&
            item.requestFiles?.length
        ) {
            this.downloadFile('request', this.getSelectedRow().pricingId, proposalId).subscribe(
                (response) => {
                    this.asyncPricing = false;
                },
                (error) => {
                    this.asyncPricing = false;
                    this._utilsService.errorHandler(error, 'Baixar Precificação');
                }
            );
        }
    }

    openModal(type) {
        this.dialogType = type;
        this._customDialogService.open('project-pricing-input-modal');
    }

    defineActionButtons(rows, child?, activeTab?: string) {
        this._consolidatedRows = [];
        const internalUser = this._userService.getCurrentUser().has('INTERNAL_FILTERS');
        const multiButtons = PROJECT_PRICING_MULTIBUTTONS;
        const isHistoricTab = activeTab === 'project_pricing_historic';
    
        rows.forEach((row) => {
            if (internalUser) {
                if (child) {
                    this._consolidatedRows.push(row.status);
                   if (row.updatable === true && row.status === 'Enviado') {
                        row.multiButtons = _.cloneDeep(multiButtons.internal_single_child_updatable);
                    }
                    else {
                        row.multiButtons = _.cloneDeep(multiButtons.internal_single_child);
                    }
                } else {
                    if (row.updatable === true && row.status === 'Enviado' && row.proposals.length === 1) {
                        row.multiButtons = _.cloneDeep(multiButtons.internal_single_updatable);
                    } else {
                        row.multiButtons =
                            row.proposals.length > 1
                                ? _.cloneDeep(multiButtons.internal_multiple)
                                : _.cloneDeep(multiButtons.internal_single);
                    }
                }
            } else {
                if (isHistoricTab) {
                    row.multiButtons =  row.proposals.length > 1 
                    ? _.cloneDeep(multiButtons.external_silgle_child_historic_multiple)
                    : _.cloneDeep(multiButtons.external_silgle_child_historic);
                } 
                else if (child) {
                    if (row.internalViewed !== true && row.status === 'Aguard. Proposta') {
                        row.multiButtons = _.cloneDeep(multiButtons.external_edit_single_child);
                    } else
                    if (row.status === 'PO Solicitada' || row.status === 'Finalizado' || row.status === 'Cancelado' || row.status === 'PO Solicitada - Revisada' || row.status === 'PO Solicitada - Em Revisão') {
                        row.multiButtons = _.cloneDeep(multiButtons.external_silgle_child_historic);
                    }
                    else {
                        row.multiButtons = _.cloneDeep(multiButtons.external_single_child);
                    }
                } else if (row.internalViewed === false && row.status === 'Aguard. Proposta') {
                    row.multiButtons = _.cloneDeep(multiButtons.external_edit_request);
                } else {
                    row.multiButtons =
                        row.proposals.length > 1
                            ? _.cloneDeep(multiButtons.external_multiple)
                            : _.cloneDeep(multiButtons.external_single);
                }
            }
            row.multiButtons.forEach((button) => {
                button.status = this._verifyMultiButtonEnable(row, button, child);
            });
        });
        return rows;
    }    

    private _verifyMultiButtonEnable(row, button, child?) {
        if (child) {
            if (button.code === 'insert_propose' &&  this._consolidatedRows.includes('PO Solicitada - Em Revisão')) {
                if (row.status !== 'PO Solicitada - Em Revisão') {
                    return 'disabled';
                }
            }
        }
        if (button.code === 'insert_propose') {
            if (row.status === 'Revisão Solicitada' || row.status === 'Enviado') {
                return 'disabled';
            }
        }
        if (button.code === 'ask_revision') {
            if (row.status === 'Aguard. Proposta' || row.status === 'Revisão Solicitada' || row.status === 'PO Solicitada - Em Revisão') {
                return 'disabled';
            }
        }
        if (button.code === 'approve') {
            if (row.status === 'Aguard. Proposta' || row.status === 'PO Solicitada - Em Revisão') {
                return 'disabled';
            }
        }
        if (button.code === 'ask_revision_historic') {
            if (row.status !== 'PO Solicitada') {
                return 'disabled';
            }
        }
        return 'enable';
    }

    manageResponsibleUsers(pricingId, responsibleUsers) {
        return this._http.put(`${environment.endpoints.pricingService}/pricing/${pricingId}/responsible-users`, { responsibleUsers }, {});
    }

    getResponsibleUsersList(clientId): Observable<any> {
        return this._http.get(`${environment.endpoints.adminService}/users?internalUser=true&clientGroup=${clientId}`);
    }

    getStatusList(): Observable<any> {
        return this._http.get(`${environment.endpoints.pricingService}/pricing/status`);
    }
}
