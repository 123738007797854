import { IMarkerFilter } from '../interfaces/marker-filter.interface';

export const MARKER_FILTER_TYPES = {
    status: ((): IMarkerFilter[] => {
        const status = {
            INICIADO: {
                color: '#68217a',
            },
            EMBARQUE: {
                color: '#29b473',
            },
            TRANSITO: {
                color: '#ff800a',
            },
            CARGA: {
                color: '#15519b',
            },
            LIBERACAO: {
                color: '#5cc6d0',
            },
            REGISTRO_DA: {
                color: '#ce0a0a',
            },
            DESEMBARACO_DA: {
                color: '#6b3922',
            },
            REGISTRO: {
                color: '#ce0a0a',
            },
            DESEMBARACO: {
                color: '#6b3922',
            },
            FATURAMENTO: {
                color: '#e89600',
            },
            FATURADO: {
                color: '#e8c658',
            },
            VPCEND: {
                color: '#a8cf45',
            },
            BLOQUEADO: {
                color: '#b83dad',
            },
            DISPFATURAMENTO: {
                color: '#585570',
            },
            ENTREGA: {
                color: '#b83dad',
            },
            FINALIZADO: {
                color: '#409bbf',
            },
            INVOICES_RECEIVABLE_PAGO: {
                color: 'green',
            },
            INVOICES_RECEIVABLE_EM_ABERTO: {
                color: '#15519b',
            },
            INVOICES_RECEIVABLE_VENCIDO: {
                color: '#a80000',
            },
        };

        const SVG_STATUS_DIR_PATH = 'assets/img/icons/status/';
        const EXTENSION = '.svg';

        return Object.keys(status).reduce((accumulator: any, currentValue: string) => {
            return {
                ...accumulator,
                [currentValue]: {
                    ...status[currentValue],
                    img: {
                        path: `${SVG_STATUS_DIR_PATH}${currentValue.toLowerCase()}`,
                        ext: EXTENSION,
                    },
                },
            };
        }, {});
    })(),

    period: {
        CURRENT_YEAR: {
            color: '#193d69',
        },
        TWELVE_MONTHS: {
            color: '#193d69',
        },
        CURRENT_MONTH: {
            color: '#193d69',
        },
        LAST_MONTH: {
            color: '#193d69',
        },
        TEN_DAYS: {
            color: '#193d69',
        },
        THIRTY_DAYS: {
            color: '#193d69',
        },
        LAST_12_MONTHS: {
            color: '#193d69',
        },
        PREVIOUS_MONTH: {
            color: '#193d69',
        },
    },

    progress: {
        ON_TIME: {
            color: 'green',
        },
        IN_PROGRESS: {
            color: '#277cfc',
        },
        TO_EXPIRE: {
            color: '#ffb515',
        },
        EXPIRED: {
            color: '#a80000',
        },
    },

    tab: ((): IMarkerFilter[] => {
        const tab = {
            imp: {
                color: '#15519b',
                countName: 'IMPs',
            },
            customs_clearance: {
                color: '#587fad',
                countName: 'IMPs',
            },
            operational: {
                color: '#587fad',
                countName: 'IMPs',
            },
            tax: {
                color: '#587fad',
                countName: 'IMPs',
            },
            bi: {
                color: '#587fad',
                countName: 'IMPs',
            },
            financial: {
                color: '#15519b',
                countName: 'Contas',
            },
            exchange: {
                color: '#15519b',
                countName: 'IMPs',
            },
            client_invoice: {
                color: '#15519b',
                countName: 'Contas',
            },
            sku: {
                color: '#15519b',
            },
            po_detail: {
                color: '#15519b',
            },
            sp_detail: {
                color: '#15519b',
            },
            sp_exchange_detail: {
                color: '#15519b',
            },
            project_pricing_progress: {
                color: '#15519b',
            },
            project_pricing_historic: {
                color: '#15519b',
            },
            sp_documents: {
                color: '#15519b',
            },
            sp_banking_channels: {
                color: '#15519b',
            },
            FAT: {
                color: '#15519b',
            },
            DEV: {
                color: '#15519b',
            },
            related: {
                color: '#15519b',
            },
            unrelated: {
                color: '#15519b',
            },
        };

        const SVG_STATUS_DIR_PATH = 'assets/img/icons/tabs/';
        const EXTENSION = '.svg';

        return Object.keys(tab).reduce((accumulator: any, currentValue: string) => {
            return {
                ...accumulator,
                [currentValue]: {
                    ...tab[currentValue],
                    img: {
                        path: `${SVG_STATUS_DIR_PATH}${currentValue.toLowerCase()}`,
                        ext: EXTENSION,
                    },
                },
            };
        }, {});
    })(),

    alert: {
        LOW: {
            color: 'green',
        },
        MEDIUM: {
            color: '#277cfc',
        },
        HIGH: {
            color: '#ffb515',
        },
        CRITICAL: {
            color: '#a80000',
        },
    },

    color: {
        GREEN: {
            color: 'green',
        },
        YELLOW: {
            color: '#ffb515',
        },
        RED: {
            color: '#a80000',
        },
        GREY: {
            color: 'grey',
        },
    },
};
