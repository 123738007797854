export const FOLLOWUP_MODAL = {
    validation: {
        title: 'Alertas',
        data: 'validation',
        width: '650px',
        columns: [
            { name: 'Mensagem', field: 'message' },
        ]
    },
    multipleDi: {
        title: 'Dados de DA',
        data: 'diInfo',
        width: '650px',
        columns: [
            { name: 'Imp Entreposto', field: 'warehouseNumber', width: 100 },
            { name: 'Número DI', field: 'registrationNumber', width: 100 },
            { name: 'Data Registro DI', field: 'registrationDate', width: 100 },
            { name: 'Protocolo do Registro da DI', field: 'registrationProtocol', width: 100 },
            { name: 'Canal DI', field: 'channel', width: 100 },
            { name: 'Desembaraço DI', field: 'clearanceDate',  width: 100 },
        ]        
    },
    multipleInvoices: {
        title: 'Invoices',
        data: 'invoice',
        width: '1200px',
        headers: [
            { title: 'Total', field: 'invoiceAmount.totalAmount', formatterType: 'currency', currency: 'BRL' },
        ],
        columns: [
            { name: 'P.O', field: 'linkedPo', width: 100 },
            { name: 'Invoice', field: 'invoiceNumber', width: 100 },
            { name: 'Data', field: 'invoiceDate', formatterType: 'date', width: 100 },
            { name: 'Peso Líquido', field: 'totalWeight', width: 100 },
            { name: 'Peso Bruto', field: 'totalGrossWeight', width: 100 },
            { name: 'Incoterm', field: 'incoterm', width: 100 },
            { name: 'Fornecedor', field: 'vendorName', width: 100 },
            { name: 'Valor', field: 'amount', width: 100, formatterType: 'currency' },
        ]
    },
    multipleLcs: {
        title: 'LCs',
        data: 'invoice',
        width: '900px',
        headers: [
            { title: 'Total', field: 'invoiceAmount.totalAmount', formatterType: 'currency' },
        ],
        columns: [
            { name: 'Número', field: 'lcNumber', width: 100 },
            { name: 'Data', field: 'lcOpeningDate', formatterType: 'date', width: 100 },
        ]
    },
    multipleReferences: {
        title: 'Referências',
        data: 'clientReference',
        width: '900px',
        columns: [
            { name: 'Referências', field: 'clientReference', width: 100 },
            { name: 'P.O', field: 'orderNumber', width: 100 },
        ]
    },
    multipleIncoterms: {
        title: 'Incoterms',
        data: 'incoterm',
        width: '900px',
        columns: [
            { name: 'Incoterms', field: 'incoterm', width: 100 },
            { name: 'P.O', field: 'orderNumber', width: 100 },
        ]
    },
    multipleVendors: {
        title: 'Fornecedores',
        data: 'vendorName',
        width: '900px',
        columns: [
            { name: 'Fornecedores', field: 'vendorName', width: 100 },
            { name: 'P.O', field: 'orderNumber', width: 100 },
        ]
    },
    multipleManufacturers: {
        title: 'Fabricantes',
        data: 'manufacturerName',
        width: '450px',
        transformation: 'changeManufacturers',
        columns: [
            { name: 'Fabricantes', field: 'manufacturerName', width: 100 },
        ]
    },
    multipleManufacturerCountries: {
        title: 'País de Origem',
        data: 'manufacturerCountry',
        width: '900px',
        transformation: 'changeManufacturersCountry', // testar
        columns: [
            { name: 'Item', field: 'code', width: 100 },
            { name: 'Fabricante', field: 'manufacturerName', width: 100 },
            { name: 'País', field: 'manufacturerCountry', width: 100 },
        ]
    },
    multipleInvoiceItemModal: {
        title: 'Itens da Invoice',
        data: 'vendorInvoiceBillableQuantity',
        width: '1500px',
        transformation: 'changeVendorInvoiceBillableQuantity', // testar
        columns: [
            { name: 'Item', field: 'code', width: 100 },
            { name: 'Lote', field: 'batchNumber', width: 100 },
            { name: 'Data Vencimento Lote', field: 'expirationOnDate', width: 100 },
            { name: 'Quantidade', field: 'quantity', width: 100 },
            { name: 'Unidade', field: 'unitMeasureDescription', width: 100 },
            { name: 'Quantidade Faturável', field: 'billableQuantityDecimal', width: 100 },
            { name: 'Unidade Faturável', field: 'billableUnitMeasureDescription', width: 100 },            
            { name: 'Tipo do Selo', field: 'labelType', width: 100 },
            { name: 'Data Requisição de Selos', field: 'labelRequestDate', width: 100 },
            { name: 'Data Recebimento de Selos', field: 'labelDeliveryDate', width: 100 },
            { name: 'Data Início Selagem', field: 'labelStartDate', width: 100 },
        ]
    },
    multipleLis: {
        title: 'LI\'s',
        data: 'liNumber',
        width: '900px',
        transformation: 'changeLiNumber',
        columns: [
            { name: 'LI', field: 'liNumber', width: 100 },
            { name: 'Órgão', field: 'licensingBody', width: 100 },
            { name: 'Status', field: 'liStatus', width: 100 },
            { name: 'Validade da Anuência para Embarque', field: 'boardingLimitDate', formatterType: 'date', width: 100 },
            { name: 'Validade da Anuência para Despacho', field: 'diRegistrationExpiryDate', formatterType: 'date', width: 100 },
        ]
    },
    multipleLiEmission: {
        title: 'Emissão L.I',
        data: 'liNumber',
        width: '900px',
        transformation: 'changeLiNumber',
        columns: [
            { name: 'Emissão', field: 'issueDate', formatterType: 'date', width: 100 },
            { name: 'L.I', field: 'liNumber', width: 100 },
        ]
    },
    multipleLiProtocol: {
        title: 'Emissão L.I',
        data: 'liProtocol',
        width: '900px',
        transformation: 'changeLiNumber',
        columns: [
            { name: 'Protocolo', field: 'protocolDate', formatterType: 'date', width: 100 },
            { name: 'L.I', field: 'liNumber', width: 100 },
        ]
    },
    multipleLiDeferment: {
        title: 'Deferimento L.I',
        data: 'liDeferment',
        width: '900px',
        transformation: 'changeLiDeferment',
        columns: [
            { name: 'Deferimento', field: 'acceptanceDate', formatterType: 'date', width: 100 },
            { name: 'L.I', field: 'liNumber', width: 100 },
        ]
    },
    multipleGruPayments: {
        title: 'Pagamento da GRU',
        data: 'gruPayments',
        width: '900px',
        transformation: 'changeGruPayments',
        columns: [
            { name: 'L.I', field: 'liNumber', width: 100 },
            { name: 'Pagamento GRU', field: 'gruDate', width: 100 },
        ]
    },
    formatBlock: {
        title: 'Bloqueio',
        data: '',
        dataHeader: '', // testar, esse só deve ter header
        width: '900px',
        headers: [
            { title: 'Situação', field: 'blockSituation' },
            { title: 'Início', field: 'blockStartDate', formatterType: 'date' },
            { title: 'Fim', field: 'blockEndDate', formatterType: 'date' },
        ],
    },
    multipleRegistrationDates: {
        title: 'Recebimento Pedido',
        data: 'clientRequestDate',
        width: '900px',
        columns: [
            { name: 'Datas', field: 'requestDate', width: 100 },
            { name: 'P.O', field: 'orderNumber', width: 100 },
        ]
    },
    multipleTropRegistrationDates: {
        title: 'Recebimento Pedido TROP',
        data: 'tropRequestDate',
        width: '900px',
        columns: [
            { name: 'Datas', field: 'registrationDate', width: 100 },
            { name: 'P.O', field: 'orderNumber', width: 100 },
        ]
    },
    multipleProformaDates: {
        title: 'Data da Proforma',
        data: 'proformReceptionDate',
        width: '900px',
        columns: [
            { name: 'Datas', field: 'proformaDate', width: 100 },
            { name: 'P.O', field: 'orderNumber', width: 100 },
        ]
    },
    multiplecontainerloadingRequestDates: {
        title: 'Pedido Carregamento',
        data: 'loadingRequestDates',
        width: '900px',
        columns: [
            { name: 'IMP', field: 'impReference', width: 100 },
            { name: 'Número Container', field: 'containerReference', width: 100 },
            { name: 'Pedido Carregamento', field: 'date', formatterType: 'date', width: 100 },
            { name: 'Origem', field: 'origin', width: 100 },
        ]
    },
    multiplecontainerdeliveryDates: {
        title: 'Data Entrega',
        data: 'deliveryDates',
        width: '900px',
        columns: [
            { name: 'IMP', field: 'impReference', width: 100 },
            { name: 'Número Container', field: 'containerReference', width: 100 },
            { name: 'Data Entrega', field: 'date', formatterType: 'date', width: 100 },
            { name: 'Origem', field: 'origin', width: 100 },
        ]
    },
    multiplecontainerdeliveryForecastDates: {
        title: 'Previsão de Entrega',
        data: 'deliveryForecastDates',
        width: '900px',
        columns: [
            { name: 'IMP', field: 'impReference', width: 100 },
            { name: 'Número Container', field: 'containerReference', width: 100 },
            { name: 'Previsão de Entrega', field: 'date', formatterType: 'date', width: 100 },
            { name: 'Origem', field: 'origin', width: 100 },
        ]
    },
    multiplecontainerloadingForecastDates: {
        title: 'Data de Previsão de Carregamento',
        data: 'loadingForecastDates',
        width: '900px',
        columns: [
            { name: 'IMP', field: 'impReference', width: 100 },
            { name: 'Número Container', field: 'containerReference', width: 100 },
            { name: 'Data de Previsão de Carregamento', field: 'date', formatterType: 'date', width: 120 },
            { name: 'Origem', field: 'origin', width: 100 },
        ]
    },
    multiplecontainerprevisionBillingDates: {
        title: 'Data de Previsão de Faturamento',
        data: 'previsionBillingDates',
        width: '900px',
        columns: [
            { name: 'IMP', field: 'impReference', width: 100 },
            { name: 'Número Container', field: 'containerReference', width: 100 },
            { name: 'Data de Previsão de Faturamento', field: 'date', formatterType: 'date', width: 120 },
            { name: 'Origem', field: 'origin', width: 100 },
        ]
    },
    multiplecontainerloadingDates: {
        title: 'Carregamento',
        data: 'loadingDates',
        width: '900px',
        columns: [
            { name: 'IMP', field: 'impReference', width: 100 },
            { name: 'Número Container', field: 'containerReference', width: 100 },
            { name: 'Carregamento', field: 'date', formatterType: 'date', width: 100 },
            { name: 'Origem', field: 'origin', width: 100 },
        ]
    },
    multiplecontainer: {
        title: 'Containers',
        data: 'containerName',
        width: '95',
        columns: [
            { name: 'Containers', field: 'containerNumber' },
            { name: 'Data Solicitação da remoção', field: 'requestRemoveDate', formatterType: 'date' },
            { name: 'Data da remoção', field: 'removeDate', formatterType: 'date'},
            { name: 'Data conclusão', field: 'transitConclusionRemoveDate', formatterType: 'date' },
            { name: 'Transportadora Remoção', field: 'carrierDescription'},
            { name: 'Número da programação', field: 'progNumberRemove' },
            { name: 'Data Solicitação de Desova', field: 'requestUnloadingDate', formatterType: 'date' },
            { name: 'Previsão Desova', field: 'expectedUnloadingDate', formatterType: 'date' },
            { name: 'Data Desova', field: 'unloadingDate', formatterType: 'date' },
            { name: 'Tipo Container', field: 'containerTypeModel' },
            { name: 'Dev. do Container', field: 'returnDate', formatterType: 'date' },
            { name: 'Pedido de Carregamento', field: 'loadingRequestDate', formatterType: 'date' },
            { name: 'Carregamento', field: 'loadingDate', formatterType: 'date' },
            { name: 'Previsão de Entrega', field: 'deliveryForecastDate', formatterType: 'date' },
            { name: 'Data de Entrega', field: 'deliveryDate', formatterType: 'date' },
        ]
    },
    multiplecontainerrequestRemoveDate: {
        title: 'Containers',
        data: 'requestRemoveDate',
        width: '900px',
        transformation: 'changeRequestRemoveDate',
        columns: [
            { name: 'Containers', field: 'number', width: 100 },
            { name: 'Data Solicitação da remoção', field: 'field', formatterType: 'date', width: 100 },
        ]
    },
    multiplecontainerremoveDate: {
        title: 'Containers',
        data: 'removeDate',
        width: '900px',
        transformation: 'changeRemoveDate',
        columns: [
            { name: 'Containers', field: 'number', width: 100 },
            { name: 'Data da remoção', field: 'field', formatterType: 'date', width: 100 },
        ]
    },
    multiplecontainertransitConclusionRemoveDate: {
        title: 'Containers',
        data: 'transitConclusionRemoveDate',
        width: '900px',
        transformation: 'changeTransitConclusionRemoveDate',
        columns: [
            { name: 'Containers', field: 'number', width: 100 },
            { name: 'Data Conclusão', field: 'field', formatterType: 'date', width: 100 },
        ]
    },
    multiplecontainercarrierDescription: {
        title: 'Containers',
        data: 'carrierDescription',
        width: '900px',
        transformation: 'changeCarrierDescription',
        columns: [
            { name: 'Containers', field: 'number', width: 100 },
            { name: 'Transportadora Remoção', field: 'field', formatterType: 'date', width: 100 },
        ]
    },
    multiplecontainerprogNumberRemove: {
        title: 'Containers',
        data: 'progNumberRemove',
        width: '900px',
        transformation: 'changeProgNumberRemove',
        columns: [
            { name: 'Containers', field: 'nukber', width: 100 },
            { name: 'Número da programação', field: 'field', formatterType: 'date', width: 100 },
        ]
    },
    multiplecontainerrequestUnloadingDate: {
        title: 'Containers',
        data: 'requestUnloadingDate',
        width: '900px',
        transformation: 'changeRequestUnloadingDate',
        columns: [
            { name: 'Containers', field: 'number', width: 100 },
            { name: 'Data Solicitação de Desova', field: 'field', formatterType: 'date', width: 100 },
        ]
    },
    multiplecontainerunloadingDate: {
        title: 'Containers',
        data: 'unloadingDate',
        width: '900px',
        transformation: 'changeUnloadingDate',
        columns: [
            { name: 'Containers', field: 'number', width: 100 },
            { name: 'Data Desova', field: 'field', formatterType: 'date', width: 100 },
        ]
    },
    multiplecontainerexpectedUnloadingDate: {
        title: 'Containers',
        data: 'expectedUnloadingDate',
        width: '900px',
        transformation: 'changeExpectedUnloadingDate',
        columns: [
            { name: 'Containers', field: 'number', width: 100 },
            { name: 'Previsão Desova', field: 'field', formatterType: 'date', width: 100 },
        ]
    },
    multiplecontainercontainerTypeModel: { 
        title: 'Containers',
        data: 'containerTypeModel',
        width: '900px',
        transformation: 'changeContainerTypeModel',
        columns: [
            { name: 'Containers', field: 'number', width: 100 },
            { name: 'Tipo Container', field: 'field', width: 100 },
        ]
    },
    multiplecontainercontainerDevolutionDate: {
        title: 'Containers',
        data: 'containerDevolutionDate',
        width: '900px',
        transformation: 'changeContainerDevolutionDate',
        columns: [
            { name: 'Containers', field: 'number', width: 100 },
            { name: 'Dev. do Container', field: 'field', width: 100 },
        ]
    },
    multipleAWB: {
        title: 'AWB BL',
        data: 'trackingNumber',
        width: '900px',
        columns: [
            { name: 'AWB BL', field: 'trackingNumber', width: 100 },
            { name: 'P.O', field: 'orderNumber', width: 100 },
        ]
    },
    multipleOperationalDocuments: {
        title: 'Docs. Originais Operacional',
        data: 'operationalOriginalDocuments',
        width: '900px',
        transformation: 'changeOperationalOriginalDocuments',
        columns: [
            { name: 'Documento', field: 'operationalDocumentNumberDelivered', width: 100 },
            { name: 'Data', field: 'operationalDocumentDeliveryDate', formatterType: 'date', width: 100 },
        ]
    },
    multipleClearingDocuments: {
        title: 'Docs. Originais Desembaraço',
        data: 'clearingOriginalDocuments',
        width: '900px',
        transformation: 'changeClearingOriginalDocuments',
        columns: [
            { name: 'Documento', field: 'clearingDocumentNumberDelivered', width: 100 },
            { name: 'Data', field: 'clearingDocumentDeliveryDate', formatterType: 'date', width: 100 },
        ]
    },
    multipleDeliveryDates: {
        title: 'Data de Entrega',
        data: 'deliveryDate',
        width: '900px',
        columns: [
            { name: 'Datas', field: 'deliveryDate', width: 100 },
        ]
    },
    multipleLoadingDates: {
        title: 'Data de Carregamento',
        data: 'loadingDate',
        width: '900px',
        columns: [
            { name: 'Datas', field: 'loadingDate', width: 100 },
        ]
    },
    multipleExchangeDates: {
        title: 'Data de Câmbio',
        data: 'exchangeDate',
        width: '650px',
        transformation: 'changePaymentTerms',
        columns: [
            { name: 'Condição', field: 'paymentTerms', formatterType: 'bold' },
            { name: 'Data', field: 'exchangeDueDate', formatterType: 'date' },
            { name: 'Venc. Câmbio', field: 'exchangeDate', formatterType: 'date' },
            { name: 'Prazo Câmbio', field: 'exchangeRemainingDays', formatterType: 'bold' },
        ]
    },
    multipleExchangeDueDates: {
        title: 'Data de Câmbio',
        data: 'exchangeDate',
        width: '650px',
        transformation: 'changePaymentTerms',
        columns: [
            { name: 'Condição', field: 'paymentTerms', formatterType: 'bold' },
            { name: 'Data', field: 'exchangeDate', formatterType: 'date' },
            { name: 'Venc. Câmbio', field: 'exchangeDueDate', formatterType: 'date' },
            { name: 'Prazo Câmbio', field: 'exchangeRemainingDays', formatterType: 'bold' },
        ]
    },
    multiplePaymentTerms: {
        title: 'Condições de Pagamento',
        data: 'paymentTerms',
        width: '650px',
        transformation: 'changePaymentTerms',
        columns: [
            { name: 'Condição', field: 'paymentTerms', formatterType: 'bold' },
            { name: 'Data', field: 'exchangeDate', formatterType: 'date' },
        ]
    },
    multipleExchangeDetailEntry: {
        title: 'Condições de Câmbio',
        data: 'exchangeDetail.entries',
        width: '650px',
        headers: [
            { title: 'Valor ME Total', field: 'exchangeDetail.exchangeAmountForeignTotal', formatterType: 'currency' },
            { title: 'Taxa Média', field: 'exchangeDetail.exchangeRateTotal', formatterType: 'percentage' },
            { title: 'Valor Total', field: 'exchangeDetail.exchangeAmountLocalTotal', formatterType: 'currency' },
        ],
        columns: [
            { name: 'Nº Contrato', field: 'exchangeContractNumber', formatterType: 'bold' },
            { name: 'Invoice', field: 'invoiceNumber', formatterType: 'bold' },
            { name: 'Data Câmbio', field: 'paymentExchangeDate', formatterType: 'date' },
            { name: 'Valor ME', field: 'exchangeAmountForeign', formatterType: 'currency' },
            { name: 'Taxa', field: 'exchangeRate', formatterType: 'percentage' },
            { name: 'Valor R$', field: 'exchangeAmountLocal', formatterType: 'currency' },
        ]
    },
    multipleshipmentInvoiceNumber: {
        title: 'Notas Fiscais de Remessa',
        data: 'shipmentInvoiceNumber',
        width: '650px',
        columns: [
            { name: 'NF', field: 'invoiceNumber', formatterType: 'bold' },
            { name: 'Data', field: 'invoiceDate', formatterType: 'date' },
            { name: 'Valor', field: 'amount', formatterType: 'currency' },
        ]
    },
    multipleshipmentInvoiceDate: {
        title: 'Notas Fiscais de Remessa',
        data: 'shipmentInvoiceNumber',
        width: '650px',
        columns: [
            { name: 'NF', field: 'invoiceNumber', formatterType: 'bold' },
            { name: 'Data', field: 'invoiceDate', formatterType: 'date' },
            { name: 'Valor', field: 'amount', formatterType: 'currency' },
        ]
    },
    multipleshipmentInvoiceAmount: {
        title: 'Notas Fiscais de Remessa',
        data: 'shipmentInvoiceNumber',
        width: '650px',
        columns: [
            { name: 'NF', field: 'invoiceNumber', formatterType: 'bold' },
            { name: 'Data', field: 'invoiceDate', formatterType: 'date' },
            { name: 'Valor', field: 'amount', formatterType: 'currency' },
        ]
    },
    multiplereturnInvoiceNumber: {
        title: 'Notas Fiscais de Retorno',
        data: 'returnInvoiceNumber',
        width: '650px',
        columns: [
            { name: 'NF', field: 'invoiceNumber', formatterType: 'bold' },
            { name: 'Data', field: 'invoiceDate', formatterType: 'date' },
            { name: 'Valor', field: 'amount', formatterType: 'currency' },
        ]
    },
    multiplereturnInvoiceDate: {
        title: 'Notas Fiscais de Retorno',
        data: 'returnInvoiceNumber',
        width: '650px',
        columns: [
            { name: 'NF', field: 'invoiceNumber', formatterType: 'bold' },
            { name: 'Data', field: 'invoiceDate', formatterType: 'date' },
            { name: 'Valor', field: 'amount', formatterType: 'currency' },
        ]
    },
    multiplereturnInvoiceAmount: {
        title: 'Notas Fiscais de Retorno',
        data: 'returnInvoiceNumber',
        width: '650px',
        columns: [
            { name: 'NF', field: 'invoiceNumber', formatterType: 'bold' },
            { name: 'Data', field: 'invoiceDate', formatterType: 'date' },
            { name: 'Valor', field: 'amount', formatterType: 'currency' },
        ]
    },
    multipleentryInvoiceNumber: {
        title: 'Notas Fiscais de Entrada',
        data: 'entryInvoiceNumber',
        width: '650px',
        columns: [
            { name: 'NF', field: 'invoiceNumber', formatterType: 'bold' },
            { name: 'Data', field: 'invoiceDate', formatterType: 'date' },
            { name: 'Valor', field: 'amount', formatterType: 'currency' },
        ]
    },
    multipleentryInvoiceDate: {
        title: 'Notas Fiscais de Entrada',
        data: 'entryInvoiceNumber',
        width: '650px',
        columns: [
            { name: 'NF', field: 'invoiceNumber', formatterType: 'bold' },
            { name: 'Data', field: 'invoiceDate', formatterType: 'date' },
            { name: 'Valor', field: 'amount', formatterType: 'currency' },
        ]
    },
    multipleentryInvoiceAmount: {
        title: 'Notas Fiscais de Entrada',
        data: 'entryInvoiceNumber',
        width: '650px',
        columns: [
            { name: 'NF', field: 'invoiceNumber', formatterType: 'bold' },
            { name: 'Data', field: 'invoiceDate', formatterType: 'date' },
            { name: 'Valor', field: 'amount', formatterType: 'currency' },
        ]
    },
    multiplebillingInvoiceNumber: {
        title: 'Notas Fiscais de Faturamento',
        data: 'billingInvoiceNumber',
        width: '650px',
        columns: [
            { name: 'NF', field: 'invoiceNumber', formatterType: 'bold' },
            { name: 'Filial NF', field: 'billingCnpj', formatterType: 'bold' },
            { name: 'Tipo', field: 'type', formatterType: 'bold' },
            { name: 'Data', field: 'invoiceDate', formatterType: 'date' },
            { name: 'Valor', field: 'amount', formatterType: 'currency' },
        ]
    },
    multiplebillingInvoiceDate: {
        title: 'Notas Fiscais de Faturamento',
        data: 'billingInvoiceNumber',
        width: '650px',
        columns: [
            { name: 'NF', field: 'invoiceNumber', formatterType: 'bold' },
            { name: 'Filial NF', field: 'billingCnpj', formatterType: 'bold' },
            { name: 'Tipo', field: 'type', formatterType: 'bold' },
            { name: 'Data', field: 'invoiceDate', formatterType: 'date' },
            { name: 'Valor', field: 'amount', formatterType: 'currency' },
        ]
    },
    multiplebillingInvoiceType: {
        title: 'Notas Fiscais de Faturamento',
        data: 'billingInvoiceNumber',
        width: '650px',
        columns: [
            { name: 'NF', field: 'invoiceNumber', formatterType: 'bold' },
            { name: 'Filial NF', field: 'billingCnpj', formatterType: 'bold' },
            { name: 'Tipo', field: 'type', formatterType: 'bold' },
            { name: 'Data', field: 'invoiceDate', formatterType: 'date' },
            { name: 'Valor', field: 'amount', formatterType: 'currency' },
        ]
    },
    multiplebillingCnpj: {
        title: 'Notas Fiscais de Faturamento',
        data: 'billingInvoiceNumber',
        width: '650px',
        columns: [
            { name: 'NF', field: 'invoiceNumber', formatterType: 'bold' },
            { name: 'Filial NF', field: 'billingCnpj', formatterType: 'bold' },
            { name: 'Tipo', field: 'type', formatterType: 'bold' },
            { name: 'Data', field: 'invoiceDate', formatterType: 'date' },
            { name: 'Valor', field: 'amount', formatterType: 'currency' },
        ]
    },
    multiplebillingInvoiceAmount: {
        title: 'Notas Fiscais de Faturamento',
        data: 'billingInvoiceNumber',
        width: '650px',
        columns: [
            { name: 'NF', field: 'invoiceNumber', formatterType: 'bold' },
            { name: 'Filial NF', field: 'billingCnpj', formatterType: 'bold' },
            { name: 'Tipo', field: 'type', formatterType: 'bold' },
            { name: 'Data', field: 'invoiceDate', formatterType: 'date' },
            { name: 'Valor', field: 'amount', formatterType: 'currency' },
        ]
    },
    multipleTaxHedge: {
        title: 'Hedge',
        data: 'hedgeTax',
        width: '650px',
        transformation: 'changeHedges',
        columns: [
            { name: 'Taxa', field: 'tax', formatterType: 'currency', formatterData: { currency: 'none', digits: 3 } },
            { name: 'Valor', field: 'value', formatterType: 'currency', formatterData: { currency: 'none' } },
            { name: 'Contratação', field: 'contractDate', formatterType: 'date' },
            { name: 'Vencimento', field: 'expirationDate', formatterType: 'date' },
        ]
    },
    multipleValueHedge: {
        title: 'Hedge',
        data: 'hedgeTax',
        width: '650px',
        transformation: 'changeHedges',
        columns: [
            { name: 'Taxa', field: 'tax', formatterType: 'currency', formatterData: { currency: 'none', digits: 3 } },
            { name: 'Valor', field: 'value', formatterType: 'currency', formatterData: { currency: 'none' } },
            { name: 'Contratação', field: 'contractDate', formatterType: 'date' },
            { name: 'Vencimento', field: 'expirationDate', formatterType: 'date' },
        ]
    },
    multipleContractDateHedge: {
        title: 'Hedge',
        data: 'hedgeTax',
        width: '650px',
        transformation: 'changeHedges',
        columns: [
            { name: 'Taxa', field: 'tax', formatterType: 'currency', formatterData: { currency: 'none', digits: 3 } },
            { name: 'Valor', field: 'value', formatterType: 'currency', formatterData: { currency: 'none' } },
            { name: 'Contratação', field: 'contractDate', formatterType: 'date' },
            { name: 'Vencimento', field: 'expirationDate', formatterType: 'date' },
        ]
    },
    multipleExpirationDateHedge: {
        title: 'Hedge',
        data: 'hedgeTax',
        width: '650px',
        transformation: 'changeHedges',
        columns: [
            { name: 'Taxa', field: 'tax', formatterType: 'currency', formatterData: { currency: 'none', digits: 3 } },
            { name: 'Valor', field: 'value', formatterType: 'currency', formatterData: { currency: 'none' } },
            { name: 'Contratação', field: 'contractDate', formatterType: 'date' },
            { name: 'Vencimento', field: 'expirationDate', formatterType: 'date' },
        ]
    },
    multiplebillingShippingCompany: {
        title: 'Transportadoras',
        data: 'billingInvoiceNumber',
        width: '650px',
        columns: [
            { name: 'NF', field: 'invoiceNumber', formatterType: 'bold' },
            { name: 'Transportadora', field: 'shippingCompanyDescription', formatterType: 'bold' },
        ]
    },
    multipleshipmentShippingCompany: {
        title: 'Transportadoras',
        data: 'shipmentInvoiceNumber',
        width: '650px',
        columns: [
            { name: 'NF', field: 'invoiceNumber', formatterType: 'bold' },
            { name: 'Transportadora', field: 'shippingCompanyDescription', formatterType: 'bold' },
        ]
    },
    multipleObservations: {
        title: 'Observações',
        data: 'historic',
        width: '1200px',
        columns: [
            { name: 'Data', field: 'date', formatterType: 'bold', width: 100 },
            { name: 'Mensagem', field: 'message', formatterType: 'bold', width: 1100 },
            
        ]   
    },
    multipleWarehouses: {
        title: 'Armazens',
        data: 'warehouses',
        width: '650px',
        columns: [
            { name: 'Armazém', field: 'description' },
        ]        
    },
    multipleExpense: {
        title: 'Armazens',
        data: 'warehouses',
        width: '650px',
        columns: [
            { name: 'Armazém', field: 'description' },
        ]
    },
    multipleSkuQuantity: {
        title: 'Quantidade por Container',
        data: 'skuQuantityByNumber',
        width: '450px',
        transformation: 'changeContainers',
        columns: [
            { name: 'Container', field: 'number', width: 100 },
            { name: 'Quantidade', field: 'quantity', width: 100 },
        ]
    },
    batchNumber: {
        title: 'Lotes',
        data: 'skuBatches',
        width: '450px',
        columns: [
            { name: 'Invoice', field: 'invoiceNumber', width: 100 },
            { name: 'Lote', field: 'skuBatchNumber', width: 100 },
            { name: 'Vencimento', field: 'skuExpirationOnDate', width: 100 },
        ]
    },
    expirationOnDate: {
        title: 'Lotes',
        data: 'skuBatches',
        width: '450px',
        columns: [
            { name: 'Invoice', field: 'invoiceNumber', width: 100 },
            { name: 'Lote', field: 'skuBatchNumber', width: 100 },
            { name: 'Vencimento', field: 'skuExpirationOnDate', width: 100 },
        ]
    },
    multiplecontainervolumedescription: {
        title: 'Containers',
        data: 'containerVolume',
        width: '700px',
        columns: [
            { name: 'Descrição', field: 'description', width: 100 },
            { name: 'Volume', field: 'quantity', width: 100 },
        ]
    },
    spNumbers: {
        title: 'Listagem de SPs',
        data: 'listSpNumbers',
        width: '500px',
        columns: [
            { name: 'Número da SP', field: 'spNumbers', width: 100 },
            { name: 'Sku', field: 'supplierSku', width: 100 },
            { name: 'Número Invoice SKU', field: 'invoiceNumber', width: 100 },
        ]
    },
}