import { UserService } from "app/auth/_services/user.service";
import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import moment from 'moment';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { AppModule } from 'app/app.module';
import * as _ from 'lodash';

import { IImpStatusFilter } from './interface/imp-status.interface';
import { TransportModalService } from '#services/_transportModal/transportModal.service';

export class ImpStatusFilter {

    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);
    private userService = AppModule.injector.get(UserService);
    private _initiaImpStatusFilter: IImpStatusFilter = <IImpStatusFilter>{};
    private _impStatusFilter: IImpStatusFilter = <IImpStatusFilter>{
        imp: '',
        typeSearch: this.userService.getCurrentUser().has('INTERNAL_FILTERS') ? 'IMP' : 'REF',
        clientReference: '',
        clientGroups: [],
        referenceDateType: [new DropdownOption('registration', 'Criação')],
        status: [
            { name: 'Aguard. Aut. Embarque', code: 'INICIADO' },
            { name: 'Aguard. Embarque', code: 'EMBARQUE' },
            { name: 'Em Trânsito', code: 'TRANSITO' },
            { name: 'Aguard. Presença de Carga', code: 'CARGA' },
            { name: 'Aguard. Liberação', code: 'LIBERACAO' },
            { name: 'Aguard. Registro DA', code: 'REGISTRO_DA' },
            { name: 'Aguard. Registro DI', code: 'REGISTRO' },
            { name: 'Aguard. Desembaraço DA', code: 'DESEMBARACO_DA' },
            { name: 'Aguard. Desembaraço DI', code: 'DESEMBARACO' },
            { name: 'Aguard. Faturamento', code: 'FATURAMENTO' },
            { name: 'Entrega', code: 'ENTREGA' },
        ],
        modals: this._userCacheService.getModals(),
        subsidiaryNames: []
    };

    private startDate;
    private endDate;
    private offset: number;
    private limit: number;

    constructor(userService: UserService, private _transportModalService?: TransportModalService, filter?: ImpStatusFilter) {

        this._impStatusFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'impStatusFilter', this._impStatusFilter, filter);
        this._initiaImpStatusFilter = _.cloneDeep(this._impStatusFilter);

        this.startDate = filter ? filter.startDate : moment().add(-24, 'M').format('DD/MM/YYYY');
        this.endDate = filter ? filter.endDate : moment().format('DD/MM/YYYY');
        this.offset = filter ? filter.offset : 0;
        this.limit = filter ? filter.limit : 30;
    }

    public getSearch(): string {
        return this._impStatusFilter.imp;
    }
    public getClientReference(): string {
        return this._impStatusFilter.clientReference;
    }
    public getClientGroups(): string[] {
        return this._impStatusFilter.clientGroups;
    }
    public getReferenceDateType(): DropdownOption[] {
        return this._impStatusFilter.referenceDateType;
    }
    public getStartDate() {
        return this.startDate;
    }
    public getEndDate() {
        return this.endDate;
    }
    public getStatus(): any[] {
        return this._impStatusFilter.status;
    }
    public getOffset(): number {
        return this.offset;
    }
    public getLimit(): number {
        return this.limit;
    }
    public getTypeSearch(): string {
        return this._impStatusFilter.typeSearch;
    }
    public getModals(): object[] {
        return this._impStatusFilter.modals;
    }
    public getSubsidiaryNames(): string[] {
        return this._impStatusFilter.subsidiaryNames;
    }

    public setSearch(imp): void {
        this._impStatusFilter.imp = imp;
    }
    public setClientReference(clientReference): void {
        this._impStatusFilter.clientReference = clientReference;
    }
    public setClientGroups(clientGroups): void {
        this._impStatusFilter.clientGroups = clientGroups;
    }
    public setReferenceDateType(referenceDateType): void {
        this._impStatusFilter.referenceDateType = referenceDateType;
    }
    public setStartDate(startDate): void {
        this.startDate = startDate;
    }
    public setEndDate(endDate): void {
        this.endDate = endDate;
    }
    public setStatus(status): void {
        this._impStatusFilter.status = status;
    }
    public setOffset(offset): void {
        this.offset = offset;
    }
    public setLimit(limit): void {
        this.limit = limit;
    }
    public setTypeSearch(typeSearch): void {
        this._impStatusFilter.typeSearch = typeSearch;
    }
    public setModals(modals): void {
        this._impStatusFilter.modals = modals;
    }
    public setSubsidiaryNames(subsidiaryNames): void {
        this._impStatusFilter.subsidiaryNames = subsidiaryNames;
    }

    public getRequest(allImpList) {

        this._initiaImpStatusFilter = this._userPreferencesService.verifyFilterChanges(
            this._initiaImpStatusFilter, this._impStatusFilter, 'impStatusFilter', this._userCacheService.getUserPreferences().id);

        const headers = {
            clientCodes:
                this.getClientGroups().length === 0 || this.getSearch() !== ''
                    ? ''
                    : this.getClientGroups()
                          .map((c) => c['id'])
                          .join(','),
        };
        const modals = this.getModals().map((c) => this._transportModalService.getEnglishName(c['itemName']));
        const subsidiaryNames = this.getSubsidiaryNames().map((c) => c['itemName']);

        const selectedReferenceDateType = this.getReferenceDateType();
        const referenceDateType =
            !this.getSearch() && selectedReferenceDateType && selectedReferenceDateType.length
                ? selectedReferenceDateType[0].id
                : 'registration';

        const params : any = {
            modalTransportations: this.getSearch() !== '' ? [] : modals,
            referenceDateType,
            startDate: `${
                this.getSearch() === ''
                    ? moment(this.getStartDate(), 'DD/MM/YYYY').format('YYYY-MM-DD')
                    : moment().add(-6, 'M').format('YYYY-MM-DD')
            }`,
            endDate: `${
                this.getSearch() === ''
                    ? moment(this.getEndDate(), 'DD/MM/YYYY').format('YYYY-MM-DD')
                    : moment().format('YYYY-MM-DD')
            }`,
            status: this.getSearch() === '' ? this.getStatus() : allImpList,
            limit: this.getLimit(),
            offset: this.getOffset() * 30,
            imp: `${this.getTypeSearch() === 'IMP' ? this.getSearch() : ''}`,
            clientReference: `${this.getTypeSearch() === 'REF' ? this.getSearch() : ''}`,
            manufacturerName: `${this.getTypeSearch() === 'Fornecedor' ? this.getSearch() : ''}`,
            subsidiaryNames: this.getSearch() !== '' ? [] : subsidiaryNames,
        };
        return { params, headers };
    }
}
