import { Injectable } from '@angular/core';
import jp from 'jsonpath';
import moment from 'moment';
import * as _ from 'lodash';

import { FOLLOWUP_FILTER_LISTS } from '../../../../shared/constants/followup-filter-lists.constant';
import { CountryFlagsService } from '#services/_country-flags/country-flags.service';
import { DomainTypeService } from '#services/_domain/domainType.service';
import { UserService } from '../../../../auth/_services/user.service';

import { FollowUpReportFormatter } from './formatters/report-followup.formatter';
import { FollowUpReportChassiFormatter } from './formatters/report-followup.formatter.chassi';
import { FollowUpReportClearenceFormatter } from './formatters/report-followup.formatter.clearence';
import { FollowUpReportContainerFormatter } from './formatters/report-followup.formatter.container';
import { FollowUpReportDeliveryFormatter } from './formatters/report-followup.formatter.delivery';
import { FollowUpReportExchangeFormatter } from './formatters/report-followup.formatter.exchange';
import { FollowUpReportImpFormatter } from './formatters/report-followup.formatter.imp';
import { FollowUpReportInvoiceFormatter } from './formatters/report-followup.formatter.invoice';
import { FollowUpReportLiFormatter } from './formatters/report-followup.formatter.li';
import { FollowUpReportNfFormatter } from './formatters/report-followup.formatter.nf';
import { FollowUpReportPenaltyFormatter } from './formatters/report-followup.formatter.penalty';
import { FollowUpReportSailingFormatter } from './formatters/report-followup.formatter.sailing';
import { FollowUpReportSkuFormatter } from './formatters/report-followup.formatter.sku';
import { FollowUpReportWarehousesFormatter } from './formatters/report-followup.formatter.warehouses';
import { UtilsService } from '#services/_utils/utils.service';

@Injectable()
export class FollowUpReportSlickGrid {
    // tslint:disable:max-line-length

    public currentUser = this.userService.getCurrentUser();
    public sort = true;
    public path;

    constructor(
        private flagsService: CountryFlagsService,
        private impFormatter: FollowUpReportImpFormatter,
        private chassiFormatter: FollowUpReportChassiFormatter,
        private skuFormatter: FollowUpReportSkuFormatter,
        private exchangeFormatter: FollowUpReportExchangeFormatter,
        private invoiceFormatter: FollowUpReportInvoiceFormatter,
        private liFormatter: FollowUpReportLiFormatter,
        private sailingFormatter: FollowUpReportSailingFormatter,
        private clearenceFormatter: FollowUpReportClearenceFormatter,
        private penaltyFormatter: FollowUpReportPenaltyFormatter,
        private containerFormatter: FollowUpReportContainerFormatter,
        private deliveryFormatter: FollowUpReportDeliveryFormatter,
        private nfFormatter: FollowUpReportNfFormatter,
        private warehousesFormatter: FollowUpReportWarehousesFormatter,
        private formatter: FollowUpReportFormatter,
        private userService: UserService,
        private _utilsService: UtilsService,
        public domainTypeService: DomainTypeService,
    ) {}

    public createColumns(customStructure, impColumn, type?) {
        if (type && this.path === undefined) {
            this.path = this.domainTypeService.getFollowUpSlickPath(type);
        }
        const cols = [];
        let index = 1;

        if (impColumn) {
            if (this.currentUser.has('FUP_VALIDATION')) {
                cols.push({
                    id: -1,
                    path: 'validation',
                    formatter: this.formatter.formatAlert,
                    groupName: ' ',
                    name: "<div id='alerts'><i class='material-icons'>warning</i></div>",
                    width: 32.001,
                    field: 'validation',
                });
            }
            cols.push(this.domainTypeService.getFollowUpSlickMainColumn(type));
        } else {
            customStructure.groups.forEach((group) => {
                group.columns.forEach((column) => {
                    cols.push({
                        id: index++,
                        path: column.path,
                        name: column.label,
                        width: parseFloat(column.width),
                        field: column.field,
                        groupName: group.name,
                        asyncPostRender: this.currentUser.has('FUP_VALIDATION') ? this.verifyAlert : '',
                    });
                    if (column.formatter) {
                        const self = this;
                        cols[cols.length - 1]['formatter'] = eval('self.' + column.formatter);
                        if (
                            column.formatter.includes('Date') ||
                            column.formatter.includes('formatStatus') ||
                            column.formatter.includes('formatDamages') ||
                            column.formatter.includes('formatAbsences') ||
                            column.formatter.includes('formatChannel') ||
                            column.formatter.includes('Di') ||
                            column.formatter.includes('formatNumberCenter')
                        ) {
                            cols[cols.length - 1]['cssClass'] = 'center';
                        }
                    }
                    if (column.asyncPostRender) {
                        cols[cols.length - 1]['asyncPostRender'] = column.asyncPostRender;
                    }
                });
            });
        }

        for (const col of cols) {
            const name = col.name;
            col.ptName = name;
            let newName;
            let pathParam;
            if (col.path !== '' && col.path !== 'validation') {
                if (col.path === this.path) {
                    if (this.sort) {
                        newName = `<div id="${col.path}" class="headerItem"><i class='la la-arrow-down'></i> ${name}
                        <div title="Remover Filtro" class="columnFilter">
                        <i class="comexport-close-cross"></i></div>  <div>`;
                        pathParam = `${col.path},desc`;
                    } else {
                        newName = `<div id="${col.path}" class="headerItem"><i class='la la-arrow-up'></i> ${name}
                        <div title="Remover Filtro" class="columnFilter">
                        <i class="comexport-close-cross"></i></div> <div>`;
                        pathParam = `${col.path},asc`;
                    }
                } else {
                    if (FOLLOWUP_FILTER_LISTS.listNotSortable.includes(col.path)) {
                        newName = `<div id="${col.path}" class="headerItem"><i class='la default gray'>•</i> ${name}<div title="Remover Filtro" class="columnFilter">
                        <i class="comexport-close-cross"></i></div><div>`;
                        pathParam = `${col.path},asc`;
                    } else {
                        newName = `<div id="${col.path}" class="headerItem"><i class='la default'>•</i> ${name}<div title="Remover Filtro" class="columnFilter">
                        <i class="comexport-close-cross"></i></div><div>`;
                        pathParam = `${col.path},asc`;
                    }
                }
            } else {
                newName = `<div>${name}<div title="Remover Filtro" class="columnFilter">
                <i class="comexport-close-cross"></i></div><div>`;
            }
            col.name = newName;
            col.pathParam = pathParam;
        }
        cols.sort((a, b) => {
            if (a.id > b.id) {
                return 1;
            }
            if (a.id < b.id) {
                return -1;
            }
            return 0;
        });
        return cols;
    }

    public extractSKUs(item) {
        if(!item?.vendorInvoices?.invoices) return [];

        const currency = item?.vendorInvoices?.currency ?? "BRL";
        const exporterCountry = item?.customsClearance?.exporterCountry ?? "";

        const skus = item.vendorInvoices.invoices.flatMap((invoice) => {
            const invoices = [invoice];
            return invoice.items.map((item) => {

                const invoiceItem = invoices.map(invoice => ({
                    ...invoice,
                    items: invoice.items.filter(ite => ite.code === item.code)
                })).filter(invoice => invoice.items.length > 0);

                const batches = this.parseBatches(item, invoice);

                return {
                    skuCode: item.code,
                    skuInvoiceNumber: invoice.invoiceNumber,
                    skuDescription: item.description,
                    skuBrand: item.brand,
                    skuFamily: item.family,
                    skuLine: item.line,
                    skuCollection: item.collection,
                    skuNcm: item.ncm,
                    skuManufacturerName: item.manufacturer.name,
                    skuWeightAsBigDecimal: item.weightAsBigDecimal,
                    skuGrossWeightAsBigDecimal: item.grossWeightAsBigDecimal,
                    skuQuantity: item.quantity,
                    skuPrice: item.price,
                    skuTotalPrice: item.totalPrice,
                    skuUnitMeasureDescription: item.unitMeasureDescription,
                    skuBillableQuantityDecimal: item.billableQuantityDecimal,
                    skuBillableUnitMeasureDescription: item.billableUnitMeasureDescription,
                    skuBatches: batches,
                    skuBatchNumber: batches,
                    skuExpirationOnDate: batches,
                    skuBarcode: item.barcode,
                    customerSku: item.customerSku,
                    skuUnitMeasureCode: item.unitMeasureCode,
                    skuBillableUnitMeasureCode: item.billableUnitMeasureCode,
                    skuProductDescription: item.productDescription,
                    skuProductDescriptionDetail: item.productDescriptionDetail,
                    skuCurrency: currency,
                    skuSupplierSku: item.supplierSku,
                    skuInvoiceSeries: item.invoiceSeries,
                    skuModel: item.model,
                    skuQuantityByNumber: item.containers,

                    // Invoice
                    invoiceNumber: invoices,
                    invoiceDate: invoices,
                    invoice: invoices,
                    incoterm: invoices,
                    currency: invoiceItem,
                    letterOfCredit: invoices,
                    lcOpeningDate: invoices,
                    lcNumber: invoices,
                    vendorName: invoices,
                    totalWeight: invoices,
                    totalGrossWeight: invoices,
                    vendorInvoiceQty: invoices,
                    vendorInvoiceUnitMeasureDescription: invoices,
                    vendorInvoiceCurrency: invoiceItem[0].currency,
                    productDescription: invoiceItem,
                    productDescriptionDetail: invoiceItem,
                    exporterCountry: exporterCountry,
                    // Invoice Item
                    vendorInvoiceBillableQuantity: invoices,
                    vendorInvoiceBillableUnitMeasureDescription: invoices,
                    vendorInvoiceBatchNumber: invoices,
                    collection: invoices,
                    labelType: invoices,
                    labelRequestDate: invoices,
                    labelDeliveryDate: invoices,
                    labelStartDate: invoices,
                }
            });
        });

        return skus;
    }


    private parseBatches(item, invoice) {
        if (item?.batches?.length > 0) {
            return item?.batches?.map(batch => {  
                return { 
                    skuBatchNumber: batch.batchNumber,
                    skuExpirationOnDate: batch.expirationOnDate ? moment(batch.expirationOnDate).format('DD/MM/YYYY') : undefined,
                    invoiceNumber: invoice.invoiceNumber }}) 
        } 

        if (item.batchNumber) {
            return [{ 
                skuBatchNumber: item.batchNumber,
                invoiceNumber: invoice.invoiceNumber,
                skuExpirationOnDate: null
             }];
        } 

        return null;
        
    }

    public extractChassis(consolidatedImp) {
        if(!consolidatedImp?.automotiveItems) return [];

        const packages = _.cloneDeep(consolidatedImp.packages);
        packages.forEach(pack => {
            pack.containerVolume = pack.quantity;
            pack.containerDescription = pack.description;
        });

        const impInvoices = consolidatedImp?.vendorInvoices?.invoices || [];
        const exporterCountry = consolidatedImp?.customsClearance?.exporterCountry ?? "";

        const automotives = consolidatedImp.automotiveItems.map((item) => {
            const invoice = this._filterChassisInvoice(item, impInvoices)

            return {
                chassiChassisNumber: item.chassisNumber,
                chassiCheckoutDate: '',
                chassiVehicleType: item.vehicleType,
                chassiChassiModel: item.model,
                chassiChassiColor: item.color,
                chassiModelYear: item.modelYear,
                chassiManufacturerYear: item.manufacturerYear,
                chassiEngineNumber: item.engineNumber,
                chassiBlockSituation: '',


                // Importação
                letterOfCredit: [invoice],
                lcOpeningDate: [invoice],
                lcNumber: [invoice],
                invoiceAmount: {
                    totalAmount: invoice.amount, // Total Invoice
                    currency: invoice.currency
                },

                // PO
                linkedPo: [invoice],
                incoterm: [invoice],
                vendorName: [invoice],

                // Invoice
                invoiceNumber: [invoice],
                vendorInvoiceCurrency: invoice.currency,
                currency: [invoice],
                invoice: [invoice],
                totalWeight: [invoice],
                totalGrossWeight: [invoice],
                invoiceDate: [invoice],
                chassiInvoice: invoice,
                vendorInvoiceQty: [invoice],
                vendorInvoiceUnitMeasureDescription: [invoice],
                vendorInvoiceBillableQuantity: [invoice],
                vendorInvoiceBillableUnitMeasureDescription: [invoice],
                billableUnitMeasureDescription: [invoice],
                vendorInvoiceBatchNumber: [invoice],
                collection: [invoice],
                productDescription: [invoice],
                productDescriptionDetail: [invoice],
                exporterCountry: exporterCountry,
                labelType: [invoice],
                labelRequestDate: [invoice],
                labelDeliveryDate: [invoice],
                labelStartDate: [invoice],

                // LI
                liAmount: new Set(item.importationLicenses?.map((license: any) => license.liNumber)).size,
                liNumber: item.importationLicenses,
                liAgency: item.importationLicenses,
                liStatus: item.importationLicenses,
                boardingLimitDate: item.importationLicenses,
                diRegistrationExpiryDate: item.importationLicenses,
                liEmission: item.importationLicenses,
                liProtocol: item.importationLicenses,
                liDeferment: item.importationLicenses,
                gruPayments: item.importationLicenses,
                restrictionDate: item.importationLicenses,

                // Transito
                manufacturerCountry: [invoice],

                containerVolume: packages,
                containerDescription: packages,

                paymentTerms: item.exchange,

                exchangeDate: item.exchange,
                exchangeRemainingDays: item.exchange,
                exchangeDueDate: item.exchange,

                billingInvoiceNumber: item.billingInvoices,
                billingInvoiceDate: item.billingInvoices,
                billingInvoiceAmount: item.billingInvoices,
                billingInvoiceCurrency: item.billingInvoices,
                billingInvoiceType: item.billingInvoices,
                billingShippingCompany: item.billingInvoices,

                returnInvoiceNumber: item.returnInvoices,
                returnInvoiceDate: item.returnInvoices,
                returnInvoiceAmount: item.returnInvoices,
                returnInvoiceCurrency: item.returnInvoices,

                entryInvoiceNumber: item.entryInvoices,
                entryInvoiceDate: item.entryInvoices,
                entryInvoiceAmount: item.entryInvoices,
                entryInvoiceCurrency: item.entryInvoices,

                shipmentInvoiceNumber: item.shipmentInvoices,
                shipmentInvoiceDate: item.shipmentInvoices,
                shipmentInvoiceAmount: item.shipmentInvoices,
                shipmentInvoiceCurrency: item.shipmentInvoices,
                shipmentShippingCompany: item.shipmentInvoices,

                billingInvoiceTotalAmount: item.clientInvoices?.totalAmount,
                totalWithoutMarkup: item.clientInvoices?.totalWithoutMarkup,
                billingCnpj: item.clientInvoices?.invoices,

                // Cód. Produto
                productCode: invoice.items,
                supplierSku: invoice.items,
                invoiceItemSku: invoice.items,
                customerSkuItem: invoice.items,
                customerSku: invoice.items,
                ncm: invoice.items,

            }
        });

        return automotives;
    }

    private _filterChassisInvoice(chassi, invoices: any) {
        for (let invoice of invoices) {
            if (chassi.vendorInvoiceNumber && chassi.vendorInvoiceNumber === invoice.invoiceNumber) {
                return invoice
            }
        }
        return {};
    }

    public extractRows(data: any, type) {
        data = data.map((data) => {
            return {
                ...data,
                skus: this.extractSKUs(data),
                chassis: this.extractChassis(data),
            }
        });

        const rows = [];
        for (let i = 0; i < data.length; i++) {
            const row = type === 'sku' ? data[i].consolidatedImp : data[i];
            const flagUrl = this.flagsService.getFlag(row.customsClearance.countryFrom);
            const clazz = `label ${row.status.code.toLowerCase()}`;

            // Containers
            let containerVolume = row.packages;
            let containerDescription = row.packages;
            let containerFreeTime;
            const manufacturerCountry = row.vendorInvoices.invoices;
            const manufacturerName = row.orders;

            if (['LCL', ''].includes(row.customsClearance.demurrageExpirationDays)) {
                containerFreeTime = `${row.customsClearance.demurrageExpirationDays}`;
            }
            if (row.customsClearance.demurrageExpirationDays) {
                let demurrageExpirationDays = Number(row.customsClearance.demurrageExpirationDays)
                if (demurrageExpirationDays === 1) {
                    containerFreeTime = `${row.customsClearance.demurrageExpirationDays} dia`;
                } else if (
                    demurrageExpirationDays > 1 ||
                    demurrageExpirationDays == 0
                ) {
                    containerFreeTime = `${row.customsClearance.demurrageExpirationDays} dias`;
                }
            }

            let diInfo = [];
            let daNumber;
            let daChannel;
            let daRegistrationDate;
            let registrationProtocol;
            let daClearanceDate;
            let warehouses;

            if (row.customsClearance.warehouses) {
                warehouses = row.customsClearance.warehouses;
            }

            if (row.customsClearance.clearanceData) {
                const daData = row.customsClearance.clearanceData.daData;
                if (daData) {
                    daNumber = daData.registrationNumber;
                    registrationProtocol = daData.registrationProtocol;
                    daChannel = daData.channel;
                    daRegistrationDate = daData.registrationDate;
                    daClearanceDate = daData.clearanceDate;
                }

                const diDataList = row.customsClearance.clearanceData.diDataList;
                if (diDataList) {
                    if (diDataList.length === 1) {
                        diInfo.push({
                            channel: diDataList[0].channel,
                            registrationDate: diDataList[0].registrationDate,
                            registrationProtocol: diDataList[0].registrationProtocol,
                            registrationNumber: diDataList[0].registrationNumber,
                            clearanceDate: diDataList[0].clearanceDate,
                            warehouseNumber: diDataList[0].warehouseNumber,
                        });
                    }

                    if (diDataList.length > 1) {
                        diDataList.forEach((data) => {
                            data.registrationDate = moment(data.registrationDate).format('DD/MM/YYYY');
                            data.clearanceDate = moment(data.clearanceDate).format('DD/MM/YYYY');
                            data.channel = `<div class='ball ${data.channel}'></div>`;
                        });
                        diInfo = diDataList;
                    }
                }
            }

            const impData = {
                id: i,
                validation: row.violations,
                chassiNumber: row.chassiNumber,
                clientCode: row.clientCode, // Código Cliente
                clientName: row.clientName, // Nome Cliente
                clientGroupName: row.clientGroupName, // Nome Grupo Cliente
                companyCode: row.companyCode, // Código Empresa
                imp: row.imp, // IMP
                operationModality: row.operationModality, // Operação
                description: this._utilsService.convertLineBreak(row.description), // Descrição
                operationType: row.operationType, // Tipo de Operação
                customsProcedure: row.customsClearance.customsProcedure, // Regime Aduaneiro
                costCenter: row.costCenter, // Centro de Custo
                observations: this._utilsService.convertLineBreak(row.customsClearance.observation), // Observações
                historic: row.notes, // Histórico
                status: row.status.name, // Status
                clazz,
                invoiceAmount: row.vendorInvoices,
                billingInvoiceTotalAmount: row.clientInvoices.totalAmount,
                totalWithoutMarkup: row.clientInvoices.totalWithoutMarkup,
                billingCnpj: row.clientInvoices.invoices,
                previsionBillingDate: row.previsionBillingDate,
                previsionBillingDates: row.previsionBillingDates,
                totalCash: row.totalCash,
                escortAvailable: row.escortAvailable,
                obsLogistic: this._utilsService.convertLineBreak(row.obsLogistic),
                obsBilling: this._utilsService.convertLineBreak(row.obsBilling),
                voyage: row.voyage,
                endCustomer: row.endCustomer,
                taxesPaidBy: row.taxesPaidBy,
                expensesPaidBy: row.expensesPaidBy,
                liAmount: row.liAmount,
                arrivalDock: row.arrivalDock,
                totalVolume: row.totalVolume,
                // IMP • PO
                clientReference: row.clientReference,
                purchaseOrderDate: row.purchaseOrderDate,
                registrationDate: row.registrationDate,
                proformaDate: row.proformaDate,
                modality: row.customsClearance.transportationTypeDescription,
                paymentTerms: row.exchange,
                freightForwarderSendDate: row.customsClearance.freightForwarderSendDate, // Envio ao Despachante
                boardingInstructionDate: row.customsClearance.boardingInstructionDate, // Instrução de Embarque
                merchandiseNeededDate: row.merchandiseNeededDate,
                daExpirationDate: row.daExpirationDate,
                manufacturerName,
                // Invoice
                invoiceNumber: row.vendorInvoices.invoices,
                invoiceDate: row.vendorInvoices.invoices,
                invoice: row.vendorInvoices.invoices,
                incoterm: row.vendorInvoices.invoices,
                currency: row.vendorInvoices.invoices,
                letterOfCredit: row.vendorInvoices.invoices,
                lcOpeningDate: row.vendorInvoices.invoices,
                lcNumber: row.vendorInvoices.invoices,
                vendorName: row.vendorInvoices.invoices,
                totalWeight: row.vendorInvoices.invoices,
                totalGrossWeight: row.vendorInvoices.invoices,
                vendorInvoiceQty: row.vendorInvoices.invoices,
                vendorInvoiceUnitMeasureDescription: row.vendorInvoices.invoices,
                vendorInvoiceCurrency: row.vendorInvoices.currency,
                productDescription: row.vendorInvoices.invoices,
                productDescriptionDetail: row.vendorInvoices.invoices,
                exporterCountry: row.customsClearance.exporterCountry,
                // Invoice Item
                vendorInvoiceBillableQuantity: row.vendorInvoices.invoices,
                vendorInvoiceBillableUnitMeasureDescription: row.vendorInvoices.invoices,
                vendorInvoiceBatchNumber: row.vendorInvoices.invoices,
                collection: row.vendorInvoices.invoices,
                labelType: row.vendorInvoices.invoices,
                labelRequestDate: row.vendorInvoices.invoices,
                labelDeliveryDate: row.vendorInvoices.invoices,
                labelStartDate: row.vendorInvoices.invoices,
                // Hedge
                hedgeTax: row.vendorInvoices.invoices,
                hedgeValue: row.vendorInvoices.invoices,
                hedgeContractDate: row.vendorInvoices.invoices,
                hedgeExpirationDate: row.vendorInvoices.invoices,
                // Sku
                skuProduct: (type === 'sku' ? row.code : row.skus),
                skuModel: (type === 'sku' ? row.skuModel : row.skus),
                skuQuantityByNumber: (type === 'sku' ? row.skuQuantityByNumber : row.skus),
                // Li
                liNumber: row.importationLicenses,
                liAgency: row.importationLicenses,
                liStatus: row.importationLicenses,
                boardingLimitDate: row.importationLicenses,
                diRegistrationExpiryDate: row.importationLicenses,
                // Sailing
                boardingPort: row.customsClearance.harborFrom,
                masterNumber: row.customsClearance.masterNumber, // Master BL
                origin: row.customsClearance.countryFrom,
                flagUrl,
                destination: row.customsClearance.harborTo,
                vessel: row.customsClearance.transportationDetail,
                eta: row.customsClearance.eta,
                inspectionDate: row.customsClearance.dates.inspectionDate,
                inspectionApprovalDate: row.customsClearance.dates.inspectionApprovalDate,
                // DTA
                unloadingInfoAvailable: row.unloadingInfoAvailable,
                requestRemoveDate: row.containerControls, // Data Solicitação da remoção
                removeDate: row.containerControls, // Data da remoção
                transitConclusionRemoveDate: row.containerControls, // Data conclusão
                carrierDescription: row.containerControls, // Transportadora Remoção
                dtaNumber: row.customsClearance.dtaNumber, // Numero DTA
                dtaChannel: row.customsClearance.dtaChannel,
                dtaDate: row.customsClearance.dates.customDate,
                // Clearence
                progNumberRemove: row.containerControls, // Número da programação
                urfNationalizationWithCode: row.customsClearance.urfNationalizationWithCode,
                clearingAgent: row.customsClearance.customAgentName,
                shipOwner: row.customsClearance.shipOwner,
                cargoPresence: row.customsClearance.cargoPresence,
                entranceWarehouse: row.customsClearance.entranceWarehouse, // Recinto De Entrada
                warehouses, // Armazens
                docType: row.customsClearance.docTypeDescription, // Confirmar
                operationalOriginalDocuments: row.customsClearance.documentDeliveries, // Documentos Originais Operacional
                clearingOriginalDocuments: row.customsClearance.documentDeliveries, // Documentos Originais Desembaraço
                enclosure: row.customsClearance.customs,
                urfEntrance: row.customsClearance.entry,
                boardingAuthorizationDate: row.boardingAuthorizationDate, // Data de Autorização de Embarque
                boardingForecastDate: row.customsClearance.boardingForecastDate, // Previsão de Embarque
                shippingDate: row.customsClearance.shippingDate, // Data Embarque
                requestUnloadingDate: row.containerControls, // Data Solicitação de Desova
                expectedUnloadingDate: row.containerControls, // Previsão Desova
                unloadingDate: row.containerControls, // Data Desova
                fiscal: row.customsClearance.fiscalName, // Fiscal
                imoLading: row.imoLading,
                //Cliente
                subsidiaryName: row.subsidiaryName,
                subsidiaryCnpj: row.subsidiaryCnpj,
                diTaxDolar: row.diTaxDolar,
                diTaxEuro: row.diTaxEuro,
                tracking: row.tracking,
                // Li Dates
                liEmission: row.importationLicenses,
                liProtocol: row.importationLicenses,
                liDeferment: row.importationLicenses,
                gruPayments: row.importationLicenses,
                restrictionDate: row.importationLicenses,
                // Clearence Dates
                arrivalDate: row.customsClearance.arrivalDate,
                cargoPresenceDate: row.customsClearance.cargoPresenceDate,
                receptionDI: row.customsClearance.dates.diReceptionDate,
                distributionDate: row.customsClearance.dates.distributionDate,
                mapaInspection: row.customsClearance.dates.inspectionMapDate,
                physicalInspection: row.customsClearance.dates.conferenceDate,
                merchandiseByExporter: row.customsClearance.dates.merchandiseByExporter,
                merchandiseToAgent: row.customsClearance.dates.merchandiseToAgent,
                operationalReceivedDocDate: row.customsClearance.documentDeliveries,
                clearingReceivedDocDate: row.customsClearance.documentDeliveries,
                registrationDeadline: row.customsClearance.registrationDeadline,
                mapaSubmissionDate: row.customsClearance.mapaSubmissionDate,
                mapaClearanceDate: row.customsClearance.mapaClearanceDate,
                ceNumber: row.customsClearance.ceNumber, // CE Mercante
                siscargaClearanceDate: row.customsClearance.siscargaClearanceDate,
                transshipmentVessel: row.customsClearance.transshipmentVessel,
                connectReference: row.customsClearance.connectReference,
                etaPort: row.customsClearance.etaPort,
                customsTransitDate: row.customsClearance.dates.customsTransitDate,
                transitClosureDate: row.customsClearance.dates.transitClosureDate,
                // DATA SOLICITAÇÂO LI
                liRequestDate: row.liRequestDate,
                // OBSERVAÇÔES
                liObservation: row.liObservation,

                // DA/DI information

                daNumber,
                daChannel,
                daRegistrationDate,
                daClearanceDate,
                diInfo,
                diInfoWarehouseNumber: diInfo,
                diInfoRegistrationNumber: diInfo,
                diInfoRegistrationDate: diInfo,
                diInfoRegistrationProtocol: diInfo,
                diInfoChannel: diInfo,
                diInfoClearanceDate: diInfo,

                // Penalties
                penaltyQtdy: row.customsClearance.qtPenalty,
                reason: row.customsClearance.penaltyReason,
                penaltyCauseWithElaborateRect: row.customsClearance.penaltyCauseWithElaborateRect,
                amount: row.customsClearance.penaltyAmount,
                // Containers
                containerControls: row.containerControls,
                delivery: row.delivery,

                qtdContainers: row.qtdContainers,
                containerName: row.containerControls,
                containerTypeModel: row.containerControls,
                containerDevolutionDate: row.containerControls,
                containerFreeTimeExpiration: row.customsClearance.dates.demurrageExpirationDate,
                containerVolume,
                containerDescription,
                containerFreeTime,
                manufacturerCountry,
                etaSearates: row.containerControls,
                etaP44: row.containerControls,
                vehicleVADNameSearates: row.containerControls, // Navio Searates
                // Delivery
                rescheduleClearanceDate: row.customsClearance.dates.rescheduleClearanceDate, // Vencimento Despacho
                loadingRequestDate: row.delivery.loadingRequestDate, // Pedido Carregamento
                removalDate: row.customsClearance.removalDate, // Chegada no Recinto
                deliveryForecastDate: row.delivery.deliveryForecastDate, // Previsão de Entrega
                deliveryDate: row.customsClearance.deliveryDate, // Data de Entrega
                loadingDate: row.customsClearance.loadingDate, // Data de Carregamento

                deliveryForecastDates: row.delivery.deliveryForecastDates,
                loadingForecastDates: row.delivery.loadingForecastDates,
                loadingForecastDate: row.delivery.loadingForecastDate,
                loadingDates: row.delivery.loadingDates,
                deliveryDates: row.delivery.deliveryDates,
                loadingRequestDates: row.delivery.loadingRequestDates,

                // Outras Datas
                insuranceExpiration: row.customsClearance.dates.insuranceExtensionDate,
                exchangeDate: row.exchange,
                exchangeRemainingDays: row.exchange,
                exchangeDueDate: row.exchange,
                rectification: row.customsClearance.dates.retificationDate,
                closureDate: row.closingDate, // Fechamento
                blReleaseDate: row.customsClearance.dates.billOfLadingLiberation,
                awb: row.customsClearance.trackingNumber,
                user: row.nmUser,
                // Billing
                billingInvoiceNumber: row.billingInvoices,
                billingInvoiceDate: row.billingInvoices,
                billingInvoiceAmount: row.billingInvoices,
                billingInvoiceCurrency: row.billingInvoices,
                billingInvoiceType: row.billingInvoices,
                billingShippingCompany: row.billingInvoices,
                // NFs
                // NF Remessa
                shipmentInvoiceNumber: row.shipmentInvoices,
                shipmentInvoiceDate: row.shipmentInvoices,
                shipmentInvoiceAmount: row.shipmentInvoices,
                shipmentInvoiceCurrency: row.shipmentInvoices,
                shipmentShippingCompany: row.shipmentInvoices,
                // NF Retorno
                returnInvoiceNumber: row.returnInvoices,
                returnInvoiceDate: row.returnInvoices,
                returnInvoiceAmount: row.returnInvoices,
                returnInvoiceCurrency: row.returnInvoices,
                // NF Entrada
                entryInvoiceNumber: row.entryInvoices,
                entryInvoiceDate: row.entryInvoices,
                entryInvoiceAmount: row.entryInvoices,
                entryInvoiceCurrency: row.entryInvoices,
                // damages: row.chassi.damages,
                // absences: row.chassi.absences,

                forecast: row.forecast, // Forecast
                importationForecastTax: row.importationForecastTax,

                //Importacao
                exchangeDetail: row.exchangeDetail,
                exchangeContractNumber: row.exchangeDetail,
                exchangeAmountForeign: row.exchangeDetail,
                exchangeAmountForeignTotal: row.exchangeDetail,
                exchangeRate: row.exchangeDetail,
                exchangeRateTotal: row.exchangeDetail,
                exchangeAmountLocal: row.exchangeDetail,
                exchangeAmountLocalTotal: row.exchangeDetail,
                discountWithoutCoverageTotal: row.exchangeDetail,
                exchangeBalanceTotal: row.exchangeDetail,

                paymentExchangeDate: row.exchangeDetail,
                dtaInfoAvailable: row.dtaInfoAvailable,

                // Selagem
                sealingEndDate: row.customsClearance.sealingEndDate, // Término de Selagem

                // Exibição Não Se Aplica
                mapInfoAvailable: row.mapInfoAvailable,
                liInfoAvailable: row.liInfoAvailable,

                // Arrendatário
                lesseeName: row.lesseeName,
                // Armazém de desembaraço
                warehouseClearanceDescription: row.customsClearance.warehouseClearanceDescription,
                // Recinto Entrada
                entranceWarehouseDescription: row.customsClearance.entranceWarehouseDescription,
                // Despachante
                freightForwarderDescription: row.customsClearance.freightForwarderDescription,
                // PO
                linkedPo: row.vendorInvoices.invoices,

                shippingWarehouse: row.shippingWarehouse,
                auxiliaryReference: row.auxiliaryReference,

                //SKU
                customerSku: row.skus,
                skuCode: row.skus,
                skuInvoiceNumber: row.skus,
                skuDescription: row.skus,
                skuBrand: row.skus,
                skuFamily: row.skus,
                skuLine: row.skus,
                skuCollection: row.skus,
                skuNcm: row.skus,
                skuManufacturerName: row.skus,
                skuWeightAsBigDecimal: row.skus,
                skuGrossWeightAsBigDecimal: row.skus,
                skuQuantity: row.skus,
                skuPrice: row.skus,
                skuTotalPrice: row.skus,
                skuUnitMeasureDescription: row.skus,
                skuBillableQuantityDecimal: row.skus,
                skuBillableUnitMeasureDescription: row.skus,
                skuBatchNumber: row.skus?.flatMap(sku => sku.skuBatches),
                skuExpirationOnDate: row.skus?.flatMap(sku => sku.skuBatches),
                skuBarcode: row.skus,
                skus: row.skus,

                //Chassi
                chassiChassisNumber: row.chassis,
                chassiCheckoutDate: row.chassis,
                chassiVehicleType: row.chassis,
                chassiChassiModel: row.chassis,
                chassiChassiColor: row.chassis,
                chassiModelYear: row.chassis,
                chassiManufacturerYear: row.chassis,
                chassiEngineNumber: row.chassis,
                chassiBlockSituation: row.chassis,
                chassis: row.chassis,
                chassiInvoice: row.vendorInvoices.invoices,

                productCode: row.vendorInvoices.invoices?.flatMap(invoice => invoice.items),
                supplierSku: row.vendorInvoices.invoices?.flatMap(invoice => invoice.items),
                invoiceItemSku: row.vendorInvoices.invoices?.flatMap(invoice => invoice.items),
                customerSkuItem: row.vendorInvoices.invoices?.flatMap(invoice => invoice.items),
                ncm: row.vendorInvoices.invoices?.flatMap(invoice => invoice.items),
                row: row,
            };

            if (type === 'chassi' || type === 'automotive') {
                impData['chassiInvoice'] = row.vendorInvoices.invoices[0];
                const chassiData = {
                    // Veículo
                    checkoutDate: jp.query(row, 'chassi.checkoutDate')[0],
                    vehicleType: jp.query(row, 'chassi.vehicleType')[0],
                    chassiModel: jp.query(row, 'chassi.model')[0],
                    chassiColor: jp.query(row, 'chassi.color')[0],
                    modelYear: jp.query(row, 'chassi.modelYear')[0],
                    manufacturerYear: jp.query(row, 'chassi.manufacturerYear')[0],
                    engineNumber: jp.query(row, 'chassi.engineNumber')[0],
                    blockSituation: jp.query(row, 'chassi.blockSituation')[0],
                    blockStartDate: jp.query(row, 'chassi.blockStartDate')[0],
                    blockEndDate: jp.query(row, 'chassi.blockEndDate')[0],

                    // Detalhes do Chassi
                    dealerName: jp.query(row, 'chassi.dealer.name')[0],
                    dealerDocumentNumber: jp.query(row, 'chassi.dealer.documentNumber')[0],
                    damageName: jp.query(row, 'chassi.damages')[0],
                    absencesName: jp.query(row, 'chassi.absences')[0],
                };
                rows.push(Object.assign({}, impData, chassiData));
            } else if (type === 'sku') {
                const rowSku = data[i];
                const skuData = {
                    customerSku: jp.query(rowSku, 'customerSku')[0],
                    skuNcm: jp.query(rowSku, 'ncm')[0],
                    skuManufacturerName: jp.query(rowSku, 'manufacturerName')[0],
                    skuWeight: jp.query(rowSku, 'weight')[0],
                    skuGrossWeight: jp.query(rowSku, 'grossWeight')[0],
                    skuQuantity: jp.query(rowSku, 'quantity')[0],
                    skuTotalPrice: jp.query(rowSku, 'totalPrice')[0],
                    skuPrice: jp.query(rowSku, 'price')[0],
                    skuInvoiceNumber: jp.query(rowSku, 'invoiceNumber')[0],
                    skuCurrency: jp.query(rowSku, 'currency')[0],
                    unitMeasureDescription: jp.query(rowSku, 'unitMeasureDescription')[0],
                    billableQuantityDecimal: jp.query(rowSku, 'billableQuantityDecimal')[0],
                    billableUnitMeasureDescription: jp.query(rowSku, 'billableUnitMeasureDescription')[0],
                    batchNumber: jp.query(rowSku, 'batchNumber')[0],
                    brand: jp.query(rowSku, 'brand')[0],
                    family: jp.query(rowSku, 'family')[0],
                    line: jp.query(rowSku, 'line')[0],
                    collection: jp.query(rowSku, 'collection')[0],
                    productDescription: jp.query(rowSku, 'productDescription')[0],
                    productDescriptionDetail: jp.query(rowSku, 'productDescriptionDetail')[0],
                    skuProduct: jp.query(rowSku, 'code')[0],
                    skuModel: jp.query(rowSku, 'model')[0],
                    skuBarcode: jp.query(rowSku, 'barcode')[0],
                    skuQuantityByNumber: jp.query(rowSku, 'containers')[0],
                };
                rows.push(Object.assign({}, impData, skuData));
            } else {
                rows.push(impData);
            }
        }
        return rows;

    }

    public getPath() {
        return this.path;
    }
    public setPath(path) {
        this.path = path;
    }
    public getSort() {
        return this.sort;
    }
    public setSort(sort) {
        if (sort) {
            this.sort = true;
        } else {
            this.sort = !this.sort;
        }
    }

    public verifyAlert(cellNode, row, dataContext, colDef) {
        if (!_.isNil(dataContext.validation)) {
            if (dataContext.validation.length > 0) {
                dataContext.validation.some((validation) => {
                    if (
                        validation.properties[0] === colDef.path?.replace(/\[\*\]/, '') ||
                        validation.message.indexOf(`"${colDef.ptName}"`) !== -1
                    ) {
                        const $alert = $('<div>', { class: 'alertBall multipleViolations' });
                        $(cellNode).append($alert);
                        return true;
                    }
                    return false;
                });
            }
        }
    }

    private reorderColumns(cols: any[], colsReordered: any[], groupName) {
        cols.map((col) => {
            if (col.groupName + '' === groupName + '') {
                colsReordered.push(col);
            }
        });
    }
}
