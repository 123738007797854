import { Component, ViewEncapsulation } from '@angular/core';
import { IPageWrapperTitle } from 'app/shared/components/cmx-page-wrapper/page-wrapper-interface';
import { CustomWidgetsService } from './custom-widget.service';
import { NotificationService } from '#services/_notification/notification.service';
import { UtilsService } from '#services/_utils/utils.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DROPDOWN_AXYS_PERIOD_DEFAULT_DAILY, DROPDOWN_AXYS_PERIOD_DEFAULT_MONTH, DROPDOWN_AXYS_PERIOD_DEFAULT_YEAR, DROPDOWN_AXYS_TYPES, DROPDOWN_MULTIPLE_SETTINGS, DROPDOWN_OPERTION_TYPES, DROPDOWN_REFERENCE_LINE_TYPES, DROPDOWN_SECONDARY_AXYS_TYPES, DROPDOWN_SETTINGS, DROPDOWN_VALUE_TYPES, WIDGET_FILTERS } from './constants/custom-widgets-dropdown.contant';
import { IBottomButton } from 'app/shared/components/cmx-page-wrapper/interfaces/top-button.interface';
import * as _ from 'lodash';
import { CUSTOM_WIDGETS_DATA_COLUMNS } from './constants/custom-widgets-grid-columns.constant';
import { IGridColumn } from 'app/shared/components/cmx-custom-grid/interfaces';
import { CmxDropdownService } from 'app/shared/components/cmx-dropdown/cmx-dropdown.service';
import { UserService } from 'app/auth/_services/user.service';
import { UserCacheService } from '#services/_user/app-user-cache.service';

@Component({
    selector: 'custom-widgets',
    templateUrl: './custom-widgets.component.html',
    styleUrls: ['./custom-widgets.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CustomWidgetsComponent {

    async: boolean = false;
    asyncShareUsers: boolean = false;
    titleComponent: IPageWrapperTitle = { title: 'Personalização de Widgets' }
    bottomButtonList: IBottomButton[] = [{ code: 'next', name: 'Continuar', disabled: true }]
    formStep: 'initial' | 'data' | 'details' = 'initial';
    initialForm: FormGroup;
    dataForm: FormGroup;
    detailsForm: FormGroup;
    dropdownSettings = DROPDOWN_SETTINGS;
    dropdownMultipleSettings = DROPDOWN_MULTIPLE_SETTINGS;
    referenceLineDropdownSettings = DROPDOWN_SETTINGS;
    dropdownSecondaryDataSettings = { ...DROPDOWN_SETTINGS, disabled: true};
    dropdownXAxisTypes = DROPDOWN_AXYS_TYPES;
    dropdownYAxisTypes = DROPDOWN_AXYS_TYPES;
    dropdownSecondaryAxisTypes = DROPDOWN_SECONDARY_AXYS_TYPES;
    dropdownPeriodDefault = DROPDOWN_AXYS_PERIOD_DEFAULT_MONTH;
    dropdownValueTypes = DROPDOWN_VALUE_TYPES;
    dropdownReferenceLineTypes = DROPDOWN_REFERENCE_LINE_TYPES;
    dropdownOperationTypes = DROPDOWN_OPERTION_TYPES;
    dropdownDataPathList = [];
    dropdownPathList = [];
    dropdownUserList = [];
    buttonListAddData = [{ code: 'addNew', name: 'Adicionar Campo',
        color: '#3163b1', hoverColor: '#2a4c81', textColor: '#ffffff'
    }]
    dataGridColumns: IGridColumn[] = CUSTOM_WIDGETS_DATA_COLUMNS
    dataList: any[] = [];
    currentUser;

    constructor(
        public customWidgetsService: CustomWidgetsService,
        private _fb: FormBuilder,
        private _userService: UserService,
        private _userCacheService: UserCacheService,
        private _utilsService: UtilsService,
        private _cmxDropdownService: CmxDropdownService,
        private _notificationService: NotificationService,
    ) {
        this._userService.currentUser$.subscribe((user) => { this.currentUser = user });
    }

    ngAfterViewInit() {
        this._loadPathList();
        this._createInitialForm();
        this.customWidgetsService.updatePreviewChart(null, null);
    }

    onCellClick({ item, column }) {
        if (column === 'delete') {
            this.dataList = _.cloneDeep(this.dataList.filter((_line, i) => i !== item.rawIndex));
            this.bottomButtonList = [{ code: 'last', name: 'Voltar' }, { code: 'next', name: 'Continuar', disabled: !this.dataList.length }]
            this.customWidgetsService.updatePreviewChart('data', this.initialForm, this.dataList);
            this._updateReferenceLineDisable();
        }
    }

    onAxisTypeChanges(axis: 'x' | 'y') {
        const opositeAxis: 'x' | 'y' = axis === 'x' ? 'y' : 'x';
        this.initialForm?.get(`${opositeAxis}Type`)?.setValue(null);
        this.initialForm?.get(`${opositeAxis}Type`)?.clearValidators();
        this.initialForm?.get(`${opositeAxis}Period`)?.clearValidators();
        this.initialForm?.get(`${opositeAxis}PeriodField`)?.clearValidators();
        this.initialForm?.get(`${opositeAxis}Type`)?.updateValueAndValidity();
        this.initialForm?.get(`${opositeAxis}Period`)?.updateValueAndValidity();
        this.initialForm?.get(`${opositeAxis}PeriodField`)?.updateValueAndValidity();
        if (this.customWidgetsService.getDropdownValue(this.initialForm, `${axis}Type`, 'id')) {
            this.initialForm?.get(`${opositeAxis}AxisName`)?.setValue('Valor');
        } else {
            this.initialForm?.get(`${opositeAxis}AxisName`)?.setValue(null);
        }
        this.initialForm?.get(`${axis}Period`)?.setValue(null);
        this.initialForm?.get(`${axis}PeriodField`)?.setValue(null);
        this.initialForm?.get(`${axis}SecondaryType`)?.setValue(null);
        const isPeriod = this.customWidgetsService.isPeriod(this.customWidgetsService.getDropdownValue(this.initialForm, `${axis}Type`, 'id'));
        this.initialForm?.get(`${axis}Type`)?.setValidators([Validators.required]);
        if (isPeriod) {
            this.initialForm?.get(`${axis}Period`)?.setValidators([Validators.required]);
            this.initialForm?.get(`${axis}PeriodField`)?.setValidators([Validators.required]);
        } else {
            this.initialForm?.get(`${axis}PeriodField`)?.clearValidators();
        }
        this.initialForm?.get(`${axis}Type`)?.updateValueAndValidity();
        this.initialForm?.get(`${axis}Period`)?.updateValueAndValidity();
        this.initialForm?.get(`${axis}PeriodField`)?.updateValueAndValidity();
        const typeValue = this.initialForm?.get(`${axis}Type`)?.value?.length ? this.initialForm?.get(`${axis}Type`)?.value[0]?.id : null
        if (typeValue) {
            if (typeValue === 'DAILY') { 
                this.dropdownPeriodDefault = DROPDOWN_AXYS_PERIOD_DEFAULT_DAILY
            } else if (typeValue === 'YEAR') {
                this.dropdownPeriodDefault = DROPDOWN_AXYS_PERIOD_DEFAULT_YEAR
            } else { 
                this.dropdownPeriodDefault = DROPDOWN_AXYS_PERIOD_DEFAULT_MONTH
            }
        }
        this.customWidgetsService.updatePreviewChart(`${opositeAxis}AxisName`, this.initialForm)
    }

    validateRankPosition() {
        const positions = this.dataForm.get('rankPositions')?.value;
        if (positions < 1) { this.dataForm.get('rankPositions')?.setValue(1) }
        if (positions > 5) { this.dataForm.get('rankPositions')?.setValue(5) }
    }

    verifyAxisPeriodType(axis: 'x' | 'y') {
        // Verifica se o tipo do Eixo é de Período, para mostrar dropdown adicional
        return this.initialForm?.get(`${axis}Type`)?.value && this.initialForm?.get(`${axis}Type`)?.value?.length && this.customWidgetsService.isPeriod(this.initialForm?.get(`${axis}Type`)?.value[0]?.id)
    }

    verifyDataRankType() {
        // Verifica se o tipo do valor é de Classificação (Rank) para esconder Linha de Ref e mostrar Quantidade de Rank
        return this.dataForm?.get('dataType')?.value?.length && this.dataForm?.get('dataType')?.value[0]?.itemName === 'CLASSIFICAÇÃO (RANK)';
    }

    verifyRefenceType() {
        // Verifica se o a linha de referência está selecionada como Valor Fixo
        return this.dataForm?.get('referenceLine')?.value?.length && this.dataForm?.get('referenceLine')?.value[0]?.itemName === 'VALOR FIXO';
    }

    verifyDataColor(selectedValue) {
        const brightness = this.colorBrightness(selectedValue);
        const brightnessLimit = 230;
        const darkessLimit = 40;
        if (brightness > brightnessLimit) {
            this._notificationService.openNotification('error', 'A cor selecionada é muito clara, por favor escolha uma cor mais escura.');
            this.dataForm.get('dataColor')?.setValue('#3163b1');
        } else if (brightness < darkessLimit) {
            this._notificationService.openNotification('error', 'A cor selecionada é muito escura, por favor escolha uma cor mais clara.');
            this.dataForm.get('dataColor')?.setValue('#3163b1');
        }
      }

    enableSecondaryDataValue() {
        // Verifica os campos de Origem do Valor e Tipo do Valor estão preenchidos, se o tipo do valor não é PERCENTAGE, RELATIVE_FREQUENCY ou RANK, e se a Linha de Referência está não preenchida
        return this.dataForm?.get('dataField')?.value?.length && 
               this.dataForm?.get('dataType')?.value?.length && 
               !['PERCENTAGE', 'RELATIVE_FREQUENCY', 'RANK'].includes(this.dataForm?.get('dataType')?.value[0]?.id) && 
               !this.dataForm?.get('referenceLine')?.value?.length;
    }

    changeFirstDataValues(referenceLine?: boolean) {
        this.dropdownSecondaryDataSettings = this.enableSecondaryDataValue() ? DROPDOWN_SETTINGS : { ...DROPDOWN_SETTINGS, disabled: true };
        this.dropdownSecondaryDataSettings = _.cloneDeep(this.dropdownSecondaryDataSettings);
        this.dataForm.get('rankPositions')?.setValidators(this.verifyDataRankType() ? [Validators.required] : null);
        this.dataForm.get('rankPositions')?.setValue(null);
        if (referenceLine || !this.enableSecondaryDataValue()) {
            this.dataForm?.get('secondaryDataField')?.setValue(null);
            this.dataForm?.get('secondaryDataType')?.setValue(null);
            this.dataForm?.get('dataRelation')?.setValue(null);
        }
    }

    onBottomButtonClick(button) {
        if (button.code === 'last') { this.changeStep('last') }
        if (button.code === 'next') { this.changeStep('next') }
        if (button.code === 'save') { this.saveWidget() }
    }

    dataListAddClick() {
        const hasSecondaryType = this.customWidgetsService.getDropdownValue(this.initialForm, 'xSecondaryType', 'id') || this.customWidgetsService.getDropdownValue(this.initialForm, 'ySecondaryType', 'id');
        const dataLimit = hasSecondaryType ? 1 : 5;
        if (this.dataList.length + 1 > dataLimit) {
            this._notificationService.openNotification('error', hasSecondaryType ? 'Apenas 1 dado é permitido ao possuir agrupador adicional nos eixos' : 'Você já atingiu o máximo de dados permitido');
            return;
        } else {
            const dataName = this.customWidgetsService.getValue(this.dataForm, 'dataName');
            const dataColor = this.customWidgetsService.getValue(this.dataForm, 'dataColor');
            const visualDataType = this.customWidgetsService.getValue(this.dataForm, 'visualDataType');
            const operation = this.customWidgetsService.getDropdownValue(this.dataForm, 'dataRelation', 'itemName');
            const dataField = this.dataForm.get('dataField')?.value ? this.dataForm.get('dataField')?.value[0]?.itemName : null;
            const dataIsCustomer = this.dataForm.get('dataField')?.value ? this.dataForm.get('dataField')?.value[0]?.isCustomer : false;
            const dataType = this.dataForm.get('dataType')?.value ? this.dataForm.get('dataType')?.value[0]?.itemName : null;
            const secondaryDataField = this.dataForm.get('secondaryDataField')?.value ? this.dataForm.get('secondaryDataField')?.value[0]?.itemName : null;
            const secondaryDataType = this.dataForm.get('secondaryDataType')?.value ? this.dataForm.get('secondaryDataType')?.value[0]?.itemName : null;
            const referenceLine = this.customWidgetsService.getDropdownValue(this.dataForm, 'referenceLine', 'itemName');
            const referenceLineType = this.customWidgetsService.getDropdownValue(this.dataForm, 'referenceLine', 'id');
            const referenceLineFixedValue = this.customWidgetsService.getValue(this.dataForm, 'fixedValue');
            const rankPositions = this.dataForm.get('rankPositions')?.value;
            if ((dataName && dataField && dataType && visualDataType) && ((!secondaryDataField) || (secondaryDataField && secondaryDataType && operation))
                && ((dataType !== 'CLASSIFICAÇÃO (RANK)') || (dataType === 'CLASSIFICAÇÃO (RANK)' && rankPositions))) {
                if (!this.dataList.some(item => item.dataName === dataName || item.dataColor === dataColor)) {
                    this.dataList.push({
                        dataName, dataType, dataColor, hexadecimal: dataColor,
                        dataTypeId: this.customWidgetsService.getDropdownValue(this.dataForm, 'dataType', 'id'),
                        secondaryDataField, secondaryDataType, dataIsCustomer,
                        secondaryDataFieldId: this.customWidgetsService.getDropdownValue(this.dataForm, 'secondaryDataField', 'id'),
                        secondaryDataTypeId: this.customWidgetsService.getDropdownValue(this.dataForm, 'secondaryDataType', 'id'),
                        visualDataType: visualDataType === 'BAR' ? 'Barra' : 'Linha',
                        dataField: secondaryDataField ?
                            `${dataField} (${dataType}) ${operation.charAt(operation.length - 2)} ${secondaryDataField} (${secondaryDataType})`
                            : `${dataField} (${dataType}${rankPositions ? ' ' + rankPositions : ''})`,
                        referenceLine: referenceLine ? `Sim (${referenceLine})` : 'Não',
                        referenceLineType, referenceLineFixedValue,
                        path: this.dataForm.get('dataField')?.value ? this.dataForm.get('dataField')?.value[0]?.id : null,
                        graphType: visualDataType,
                        operation: this.dataForm.get('dataRelation')?.value ? this.dataForm.get('dataRelation')?.value[0]?.id : null, 
                        prefix: this.dataForm.get('prefix')?.value,
                        suffix: this.dataForm.get('suffix')?.value,
                    })
                } else {
                    this._notificationService.openNotification('error', 'Já existe um dado com esse Nome ou Cor');
                    return;
                }
            } else {
                this._notificationService.openNotification('error', 'Preencha todos os campos necessários');
                return;
            }
            this.dataList = _.cloneDeep(this.dataList);
            this._verifyInternalField();
            this.bottomButtonList = [{ code: 'last', name: 'Voltar' }, { code: 'next', name: 'Continuar', disabled: !this.dataList.length }]
            this.customWidgetsService.updatePreviewChart('data', this.initialForm, this.dataList);
            this._updateReferenceLineDisable();
            this._createDataForm();
        }
    }

    changeStep(direction: 'last' | 'next') {
        if (this.formStep === 'initial') {
            this.formStep = 'data';
            this._createDataForm();
        } else if (this.formStep === 'data') {
            if (direction === 'next') {
                this.formStep = 'details';
                if (!this.detailsForm) {
                    this._createDetailsForm();
                }
            } else {
                this.formStep = 'initial';
            }
        } else {
            this.formStep = 'data';
            this._createDataForm();
        }
        this._updateBottomButtonsList();
    }

    createRequest() {
        const xTypeValue = this.customWidgetsService.getValue(this.initialForm, 'xType');
        const yTypeValue = this.customWidgetsService.getValue(this.initialForm, 'yType');
        const xIsPeriod = xTypeValue && xTypeValue?.length && this.customWidgetsService.isPeriod(xTypeValue[0]?.id);
        const yIsPeriod = yTypeValue && yTypeValue?.length && this.customWidgetsService.isPeriod(yTypeValue[0]?.id);
        const xAxisName = this.customWidgetsService.getValue(this.initialForm, 'xAxisName') || this.customWidgetsService.getDropdownValue(this.initialForm, 'xType', 'itemName');
        const yAxisName = this.customWidgetsService.getValue(this.initialForm, 'yAxisName') || this.customWidgetsService.getDropdownValue(this.initialForm, 'yType', 'itemName');
        const periodType = xIsPeriod ? this.customWidgetsService.getDropdownValue(this.initialForm, 'xType', 'id') 
            : this.customWidgetsService.getDropdownValue(this.initialForm, 'yType', 'id');
        const periodPath = xTypeValue?.length ? xIsPeriod ? this.customWidgetsService.getDropdownValue(this.initialForm, 'xPeriodField', 'id') : this.customWidgetsService.getDropdownValue(this.initialForm, 'xType', 'id')
            : yIsPeriod ? this.customWidgetsService.getDropdownValue(this.initialForm, 'yPeriodField', 'id') : this.customWidgetsService.getDropdownValue(this.initialForm, 'yType', 'id');
        let request = {
            widgetType: 'GRAPH',
            graphType: this.customWidgetsService.getValue(this.initialForm, 'graphType'),
            widgetName: this.customWidgetsService.getValue(this.initialForm, 'widgetName'),
            xaxis: { name: xAxisName },
            yaxis: { name: yAxisName },
            periodType,
            lastNMonths: this._convertMonths(xTypeValue?.length ? this.customWidgetsService.getDropdownValue(this.initialForm, 'xPeriod', 'id')
                : this.customWidgetsService.getDropdownValue(this.initialForm, 'yPeriod', 'id'), periodType),
            relationPreviousPeriod: this.customWidgetsService.getValue(this.detailsForm, 'relationPreviousPeriod'),
            variableFilters: [
                // Em caso do gráfico não possuir data em nenhum eixo, usa-se (Data de Criação)
                { name: 'Filtro de Datas', periodType: this.customWidgetsService.getValue(this.detailsForm, 'dateFilter'),
                    path: xIsPeriod || yIsPeriod ? periodPath : 'issueDate',
                }
            ],
            sharedWith: [
                ...(this.detailsForm.get('sharedUsersReadyOnly')?.value || [])?.map((readyOnly) => { return {
                        internalUser: readyOnly.id?.includes('@comexport.com.br'),
                        readOnly: true, userEmail: readyOnly.id, userName: readyOnly.itemName,
                } }),
                ...(this.detailsForm.get('sharedUsersEdit')?.value || [])?.map((edit) => { return {
                        internalUser: edit.id?.includes('@comexport.com.br'),
                        readOnly: false, userEmail: edit.id, userName: edit.itemName,
                } })
            ],
        }
        WIDGET_FILTERS.forEach((checkbox) => {
            const isChecked = this.detailsForm?.get(checkbox.formControlName)?.value;
            if (isChecked) {
                request.variableFilters.push({
                    name: checkbox.label,
                    path: checkbox.formControlName,
                    periodType: null,
                });
            }
        });
        const groupByFields = [{
            name: xTypeValue?.length ? this.customWidgetsService.getDropdownValue(this.initialForm, 'xType', 'itemName') : this.customWidgetsService.getDropdownValue(this.initialForm, 'yType', 'itemName'),
            path: periodPath,
        }]
        const secondaryType = xTypeValue?.length ? 'xSecondaryType' : 'ySecondaryType';
        if (this.customWidgetsService.getDropdownValue(this.initialForm, secondaryType, 'id')) {
            groupByFields.push({
                name: this.customWidgetsService.getDropdownValue(this.initialForm, secondaryType, 'itemName'),
                path: this.customWidgetsService.getDropdownValue(this.initialForm, secondaryType, 'id')
            })
        }
        const dataFields = this.dataList.map((data) => {
            const relatedField = data?.secondaryDataField ? {
                path: data?.secondaryDataFieldId,
                operation: data?.secondaryDataTypeId,
            } : null
            const referenceLine = data?.referenceLine !== 'Não' ? {
                show: true, type: data.referenceLineType,
                fixedValue: data.referenceLineType === 'FIXED_VALUE' ? data.referenceLineFixedValue : null,
            } : null
            return {
                name: data?.dataName,
                suffix: data?.suffix,
                prefix: data?.prefix,
                path: data?.path,
                graphType: data?.graphType,
                color: data?.dataColor,
                operation: data?.dataTypeId,
                relatedField, referenceLine,
                relatedOperation: data?.operation,
                qtyItems: data?.rankPositions,
            };
        })
        request[xTypeValue?.length ? 'yaxis' : 'xaxis']['dataFields'] = dataFields;
        request[xTypeValue?.length ? 'xaxis' : 'yaxis']['groupByFields'] = groupByFields;
        return request;
    }

    saveWidget() {
        const sharedUsersReadOnly = this.detailsForm.get('sharedUsersReadyOnly')?.value || [];
        const sharedUsersEdit = this.detailsForm.get('sharedUsersEdit')?.value || [];
        if (sharedUsersReadOnly.find(readyOnly => sharedUsersEdit.some(edit => readyOnly.id === edit.id))) {
            this._notificationService.openNotification('error', "Existem usuários que se repetem em ambas listas de compartilhamento. Escolha entre 'somente leitura' ou 'edição'");
        } else {
            this.async = true;
            const request = this.createRequest();
            this.customWidgetsService.saveCustomWidget(request).subscribe((_response) => {
                this.async = false;
                this._notificationService.openNotification('success', 'Widget criado com sucesso');
            }, (error) => {
                this.async = false;
                this._utilsService.errorHandler(error, 'Criação de Widget');
            });
        }
    }

    private _verifyInternalField() {
        const hasNoCustomer = this.dataList.some((item) => item.dataIsCustomer === false);
        this._loadUserShareList(hasNoCustomer);
    }

    private _createInitialForm() {
        this.initialForm = this._fb.group({
            widgetName: [null, [Validators.required]],
            graphType: ['BAR_LINE', [Validators.required]],
            xAxisName: [null],
            xType: [null, [Validators.required]],
            xSecondaryType: [null],
            xPeriod: [null],
            xPeriodField: [null],
            yAxisName: [null],
            yType: [null, [Validators.required]],
            ySecondaryType: [null],
            yPeriod: [null],
            yPeriodField: [null],
        });
        this.initialForm.valueChanges.subscribe((val) => {
            this.bottomButtonList = [{ code: 'next', name: 'Continuar', disabled: !this.initialForm.valid }]
        });
    }

    private _createDataForm() {
        this.dataForm = this._fb.group({
            dataName: [null, [Validators.required]],
            dataColor: ['#3163b1', [Validators.required]],
            prefix: [null],
            suffix: [null],
            visualDataType: ['BAR'],
            dataField: [null],
            dataType: [null],
            referenceLine: [null],
            rankPositions: [null],
            fixedValue: [null],
            secondaryDataField: [null],
            secondaryDataType: [null],
            dataRelation: [null],
        });
        this.dataForm.get('dataColor')?.valueChanges.subscribe((selectedValue) => {
            this.verifyDataColor(selectedValue);
        });
    }

    private _createDetailsForm() {
        this.detailsForm = this._fb.group({
            dateFilter: ['MONTHLY'],
            clientFilter: [null],
            statusFilter: [null],
            subsidiaryFilter: [null],
            crFilter: [null],
            modalFilter: [null],
            relationPreviousPeriod: [null],
            sharedUsersReadyOnly: [null],
            sharedUsersEdit: [null],
        })
    }

    private _updateReferenceLineDisable() {
        const hasReference = this.dataList.some(item => item.referenceLine !== 'Não');
        this.referenceLineDropdownSettings = hasReference ? 
            { ...DROPDOWN_SETTINGS, disabled: true } : { ...DROPDOWN_SETTINGS, disabled: false };
    }

    private _updateBottomButtonsList() {
        if (this.formStep === 'initial') {
            this.bottomButtonList = [{ code: 'next', name: 'Continuar', disabled: !this.initialForm.valid }]
        } else if (this.formStep === 'data') {
            this.bottomButtonList = [{ code: 'last', name: 'Voltar' }, { code: 'next', name: 'Continuar', disabled: !this.dataList.length }]
        } else {
            this.bottomButtonList = [{ code: 'last', name: 'Voltar' }, { code: 'save', name: 'Criar Widget' }]
        }
    }

    private _loadPathList() {
        this.customWidgetsService.getPathList().subscribe((pathList) => {
            this.dropdownDataPathList = this._cmxDropdownService.toDropdownItems(this._utilsService.sortObjectArrayBy(pathList, 'name')?.filter(item => item.name.toLowerCase()?.includes('data')));
            this.dropdownPathList = this._cmxDropdownService.toDropdownItems(this._utilsService.sortObjectArrayBy(pathList, 'name'));
        })
    }

    private _loadUserShareList(hasNoCustomer: boolean) {
        this.asyncShareUsers = true;
        const clientGroupCodeList = this._userCacheService.getClientGroups().map((c) => c.code).join(',')
        this.customWidgetsService.getUserShareList(clientGroupCodeList).subscribe((userList: any[]) => {
            // Caso o gráfico tenha campos internos, permitir apenas @comexport.com.br
            let filteredUserList = hasNoCustomer ? userList.filter(user => user.email && user.email?.trim()?.includes('@comexport.com.br')) : userList;
            // Caso o usuário seja externo, não mostrar usuários internos
            filteredUserList = !this.currentUser.has('INTERNAL_FILTERS') ? filteredUserList.filter(user => user.email && !user.email.includes('@comexport.com.br')) : filteredUserList;
            this.dropdownUserList = this._cmxDropdownService.toDropdownItemsEmail(filteredUserList.map((user) => {
                return { code: user.email, email: user.email, name: user.name } 
            }));
            this.asyncShareUsers = false;
        }, (_error) => {
            this.dropdownUserList = [];
            this.asyncShareUsers = false;
        })
    }

    private _convertMonths(lastNMonths, type: 'DAILY' | 'MONTHLY' | 'YEAR') {
        return type === 'MONTHLY' ? parseInt(lastNMonths) :
               type === 'YEAR' ? parseInt(lastNMonths) * 12 :
               parseInt(lastNMonths) / 30;
    }

    private colorBrightness(hex) {
        const r = parseInt(hex.substring(1, 3), 16);
        const g = parseInt(hex.substring(3, 5), 16);
        const b = parseInt(hex.substring(5, 7), 16);
        return 0.2126 * r + 0.7152 * g + 0.0722 * b;
    }

}