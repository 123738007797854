import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from 'app/shared/shared.module';
import { LayoutModule } from '../../layouts/layout.module';
import { EditProfileComponent } from './edit-profile.component';
import { QRCodeModule } from 'angularx-qrcode';
import { TranslateModule } from '@ngx-translate/core';

const routes: Routes = [
    {
        path: '',
        component: EditProfileComponent,
    },
];

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule.forChild(routes),
        FormsModule,
        LayoutModule,
        SharedModule,
        QRCodeModule,
        NgxMaskModule.forRoot(),
    ], declarations: [
        EditProfileComponent,
    ],
})
export class EditProfileModule {

}
