<div class="moveBtn"><div class="moveLine"></div></div>

<div *ngIf="asyncLeadTime || loadingTranslate">
    <loader></loader>
</div>

<div class="headerContainer" *ngIf="currentUser$ | async as user">
    <span class="analyticsName">{{ 'dashboard.title::leadTime' | translate }}</span>
    <cmx-help-modal
        [help-title]="'dashboard.title::leadTime' | translate"
        [help-description]="helpDescription"
        [help-video-src]="'assets/videos/icone-informativo/dashboardsAtualizado.mp4'"
        help-video-name="{{'common::strings.dashboard' | translate }} {{ 'dashboard.title::leadTime' | translate }}"
    ></cmx-help-modal>
    <h5>{{ 'dashboard.title::leadTime' | translate }}</h5>
</div>

<cmx-marker-filter
    *ngIf="currentUser$ | async as user"
    id="lead-time-marker-filter"
    [filters]="translatedPeriodOptions"
    [preSelected]="['CURRENT_YEAR']"
    returnBasedOn="code"
    type="period"
    [multiple]="false"
    [hasSelectAll]="false"
    [leftDistance]="180"
    alignment="start"
    (onSelected)="changePreset($event)"
></cmx-marker-filter>

<div id="searchLine" class="reduzed" *ngIf="currentUser$ | async as user">
    <div class="input-group right-switch">
        <cmx-filter-switch
            [values]="[ 'common::strings.columns' | translate, 'common::strings.rows' | translate ]"
            [inputIndex]="filterState.getChartType() === 'XYChart' ? 0 : 1"
            (onSwitch)="toggleVisualSwitch($event)"
        ></cmx-filter-switch>

        <span class="input-group-addon" style="margin: 0 -6px 0 -15px;"></span>
    </div>
    <div class="input-group" *ngIf="user.has('INTERNAL_FILTERS')">
        <cmx-dropdown
            [styles]="{ width: '180px' }"
            id="lead-time-clients-group-filter"
            name="clients"
            [data]="clientOptions"
            [ngModel]="filterState.getClientGroups()"
            (ngModelChange)="filterState.setClientGroups($event)"
            [settings]="dropdownSettings.clientGroups"
            (onChange)="onChangeClientGroups()"
        ></cmx-dropdown>
        <span class="input-group-addon" style="margin: 0 -6px 0 -15px;"></span>
    </div>
    <div class="input-group">
        <cmx-dropdown
            [styles]="{ width: '180px'}"
            name="kpi"
            [data]="kpiOptions"
            [ngModel]="filterState.getSelectedKpi()"
            (ngModelChange)="filterState.setSelectedKpi($event)"
            [settings]="dropdownSettings.kpi"
            (onChange)="applyFilter()"
        ></cmx-dropdown>
    </div>
    <div class="input-group">
        <cmx-filter-datepicker
            id="lead-time-datepicker"
            periodType="month"
            type="range-month"
            [date]="'dashboard::leadTime.finalization' | translate"
            [startDate]="filterState.getStartDate()"
            [endDate]="filterState.getEndDate()"
            (onChangeDates)="datesChanged($event)"
            [startDateRestriction]="startDateRestriction"
            [endDateRestriction]="endDateRestriction"
        ></cmx-filter-datepicker>
    </div>
    <button (click)="applyFilter()" class="applyImpStatus">
        <i class="fa fa-check"></i>
    </button>
</div>

<div class="chart" id="leadTime"></div>

<div class="empty-container" *ngIf="currentUser$ | async as user">
    <div *ngIf="!asyncLeadTime">
        <p *ngIf="kpiOptions?.length < 1 && (filterState.getClientGroups()?.length > 0 || !user.has('INTERNAL_FILTERS'))" class="empty-kpi">{{ 'common::messages.noKpiRegistered' | translate }}</p>
        <p *ngIf="noResultsFound && filterState.getClientGroups()?.length < 1 && user.has('INTERNAL_FILTERS')" class="empty-client">{{ 'common::messages.pleaseSelectClient' | translate }}</p>
        <p *ngIf="noResultsFound && filterState.getSelectedKpi()?.length < 1 && kpiOptions?.length > 0" class="empty-results">{{ 'common::messages.pleaseSelectKpi' | translate }}</p>
        <p *ngIf="noResultsFound && filterState.getSelectedKpi().length > 0 && (!user.has('INTERNAL_FILTERS') || filterState.getClientGroups()?.length > 0)" class="empty-results">{{ 'common::messages.emptyDataMessage' | translate }}</p>
    </div>
</div>
