<div class="moveBtn"><div class="moveLine"></div></div>
<div *ngIf="asyncConsolidation || loadingTranslate"><loader></loader></div>

<div class="header-container">
    <div class="title-information">
        <span class="analyticsName">{{ 'dashboard.title::statusConsolidation' | translate }}</span>
        <cmx-help-modal
            [help-title]="'dashboard::statusConsolidation.helpTitle' | translate"
            [help-description]="helpDescription"
            [help-video-src]="'assets/videos/icone-informativo/dashboardsAtualizado.mp4'"
            help-video-name="{{'common::strings.dashboard' | translate }} {{ 'dashboard.title::statusConsolidation' | translate }}"
        ></cmx-help-modal>
        <h5>{{ 'dashboard.title::statusConsolidation' | translate }}</h5>
    </div>

    <div id="searchLine" class="reduzed" *ngIf="currentUser$ | async as user">
        <cmx-switch
            style="z-index: 1"
            [options]="allTypes"
            [selected]="filterState.getTypeStatus()"
            (onSelect)="applyType($event)"
        ></cmx-switch>
        <div class="search-container right">
            <div class="input-group" *ngIf="typeSelected != 'CHASSI'">
                <cmx-dropdown
                    [styles]="{width: '123px'}"
                    name="modal"
                    [data]="options.modal"
                    [ngModel]="filterState.getModals()"
                    (ngModelChange)="filterState.setModals($event)"
                    [settings]="dropdownSettings.modals"
                ></cmx-dropdown>
                <span class="input-group-addon" *ngIf="user.has('INTERNAL_FILTERS')"></span>
            </div>
            <div *ngIf="user.has('INTERNAL_FILTERS')" class="input-group">
                <cmx-dropdown
                    [styles]="{width: '100px'}"
                    name="clients"
                    [data]="options.client"
                    [ngModel]="filterState.getClientGroups()"
                    (ngModelChange)="filterState.setClientGroups($event)"
                    [settings]="dropdownSettings.clientGroups"
                ></cmx-dropdown>
                <span *ngIf="user.has('INTERNAL_FILTERS')" class="input-group-addon"></span>
            </div>
            <div class="input-imp" *ngIf="typeSelected == 'IMP'">
                <div *ngIf="user.has('INTERNAL_FILTERS')" class="input-group">
                    <cmx-dropdown
                        [styles]="{width: '100px'}"
                        name="subsidiary-names"
                        [data]="options.subsidiaryNames"
                        [ngModel]="filterState.getSubsidiaryNames()"
                        (ngModelChange)="filterState.setSubsidiaryNames($event)"
                        [settings]="dropdownSettings.subsidiary"
                    ></cmx-dropdown>
                </div>
                <span class="input-group-addon first">{{ 'common::strings.by' | translate }}</span>
                <div class="input-group">
                    <cmx-dropdown
                        [styles]="{width: '100px'}"
                        name="reference-date-type"
                        [data]="options.referenceDateType"
                        [ngModel]="filterState.getReferenceDateType()"
                        (ngModelChange)="filterState.setReferenceDateType($event)"
                        [settings]="dropdownSettings.referenceDateType"
                    ></cmx-dropdown>
                </div>
            </div>
            <div class="input-group">
                <cmx-filter-datepicker
                    [startDate]="filterState.getStartDate()"
                    [endDate]="filterState.getEndDate()"
                    (onChangeDates)="datesChanged($event)"
                ></cmx-filter-datepicker>
            </div>

            <button (click)="applyType(typeSelected)" class="applyImpStatus">
                <em class="fa fa-check"></em>
            </button>
        </div>
    </div>
</div>

<div *ngIf="!asyncConsolidation || loadingTranslate" class="status-consolidation-container">
    <div class="consolidationContent">
        <div
            *ngFor="let status of allStatus"
            class="consolidation small"
            [style.width]="typeSelected === 'CHASSI' ? '7%' : '9%'"
            [tooltip]="status.name || ''"
            [size]="'sm'"
            (click)="redirectTo(status)"
        >
            <div class="icon-container">
                <img src="{{ status.img.path }}{{ '-checked' }}{{ status.img.ext }}" alt="Image - {{ status.code }}" />
                <span class="percentage">{{ getPercentage(status) + '%' }}</span>
            </div>
            <div class="progress">
                <div
                    id="dataInvoicesBilledItens"
                    class="progress-bar"
                    role="progressbar"
                    aria-valuenow="50"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    [style.width]="getPercentage(status) + '%'"
                    [style.background-color]="status.code !== -1 ? status.color || defaultColor : ''"
                ></div>
            </div>
            <div class="info-container">
                <span class="number" [style.color]="status.code !== -1 ? status.color || defaultColor : ''">
                    {{ status.count }}
                </span>
            </div>
        </div>
    </div>
</div>
