<div *ngIf="asyncExchange == false">
    <loader></loader>
</div>
<div class="title-information">
    <span class="analyticsName">Câmbio BRL/USD</span>
    <cmx-help-modal 
        [help-video-src]="'assets/videos/icone-informativo/dashboardsAtualizado.mp4'"
        [help-video-name]="'Dashboards Câmbio BRL/USD'"
        help-title="Câmbio BRL/USD" 
        [help-description]="helpDescription">
    </cmx-help-modal>
    <h5>Câmbio BRL/USD</h5>
</div>
<div class="moveBtn">
    <div class="moveLine"></div>
</div>
<i class="resizeBtn solo la la-expand" (click)="chartService.resizeBtn($event)"></i>
<div class="chart" id="exchange"></div>
<div *ngIf="asyncExchange === true && chartData === undefined" class="reloadContainer">
    <div class="reloadContent">
        <i (click)="loadExchange()" class="material-icons">refresh</i>
    </div>
</div>
