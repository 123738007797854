<div class="page-title-identifier">{{ 'dashboard.customDash' | translate }}</div>
<div *ngIf="loginPermissionService.asyncComponents" class="fullLoader">
    <span class="exporting">• {{ 'index.loading' | translate }} •</span>
    <loader></loader>
</div>

<div class="mainContainerPage">
    <div *ngIf="!loginPermissionService.asyncComponents" class="customDashContainer">
        <div class="containerInfo">
            <div class="titleContainer">
                <span class="title"><i class="material-icons">dashboard</i>
                    {{
                        selectedComponent?.name ?
                        (selectedComponent?.titleKey ? (selectedComponent?.titleKey | translate) : selectedComponent?.name)
                        : 'dashboard.customDash' | translate
                    }}
                </span>
                <i *ngIf="selectedComponent.name" (click)="clearSelection()" class="close la la-close"></i>
            </div>
            <hr>
            <div *ngIf="selectedComponent.name" class="customDashContent">
                <span>{{selectedComponent.description | translate}}</span>
                <hr style="width:100%">
                <img src="assets/img/tutos/dashCustom/{{selectedComponent.component}}.png" alt="">
            </div>
            <div *ngIf="!selectedComponent.name" class="customDashContent">
                <span>{{ 'dashboard.customizeDashboardInfo' | translate }}</span>
                <hr style="width:100%">
                <img src="assets/img/tutos/dashCustom/DashCustomComponent_pt-BR.png" alt="" *ngIf="userCacheService.getLanguage() === 'pt-BR'">
                <img src="assets/img/tutos/dashCustom/DashCustomComponent_en-US.png" alt="" *ngIf="userCacheService.getLanguage() != 'pt-BR'">
            </div>
        </div>
        <div class="trashListContainer">
            <div class="titleContainer">
                <span class="title"><i class="material-icons">indeterminate_check_box</i>{{ 'dashboard.availableComponents' | translate }}</span>
            </div>
            <hr>
            <div id="storedComponents" class="customDashContent">
                <span *ngIf="loginPermissionService.getStoredComponentList().length === 0" class="noContent">
                    {{ 'dashboard.noAvailableComponents' | translate }}
                </span>
                <div *ngFor="let component of loginPermissionService.getStoredComponentList(); trackBy: trackByFn" (click)="selectComponent(component)" class="component{{component.size === 100 ? ' full' : ''}}" id="{{component.component}}" style="cursor:pointer">
                    <div (click)="loginPermissionService.addComponent(component)">
                        <i class="add la la-plus"></i>
                    </div>
                    <i *ngIf="component.expansive" (click)="resizeComponent(component)" class="resize la {{component.size === 100 ? 'la-compress' : 'la-expand'}}"></i>
                    {{component.titleKey ? (component.titleKey | translate) : component.name}}
                </div>
            </div>
        </div>
        <div class="dashListContainer">
            <div class="titleContainer">
                <span class="title"><i class="material-icons">check_box</i>{{ 'dashboard.currentDashboard' | translate }}</span>
            </div>
            <hr>
            <div id="mergedComponents" class="customDashContent" [dragula]='"customDash-bag"' [dragulaModel]="">
                <span *ngIf="loginPermissionService.getMergedComponentList().length === 0" class="noContent">
                    {{ 'dashboard.noSelectedComponents' | translate }}
                </span>
                <div *ngFor="let component of loginPermissionService.getMergedComponentList(); trackBy: trackByFn" (click)="selectComponent(component)" class="component{{component.size === 100 ? ' full' : ''}}" id="{{component.component}}">
                    <i (click)="loginPermissionService.removeComponent(component)" class="close la la-close"></i>
                    <i *ngIf="component.expansive" (click)="resizeComponent(component)" class="resize la {{component.size === 100 ? 'la-compress' : 'la-expand'}}"></i>
                    {{component.titleKey ? (component.titleKey | translate) : component.name}}
                </div>
            </div>
           <hr>
           <section id="custom-dashboard-actions-buttons">
               <div class="actions-buttons">
                   <cmx-button (click)="loginPermissionService.resetChanges()" variant="primary">{{ 'system::actions.reset' | translate }}</cmx-button>
                   <cmx-button (click)="saveCustomChanges()" variant="success">{{ 'system::actions.save' | translate }}</cmx-button>
               </div>
           </section>
        </div>
    </div>
</div>

