import { APP_INITIALIZER_PROVIDER } from './app-initializer.provider';
import { AppComponent } from './app.component';
import { ThemeComponent } from './theme/theme.component';
import { Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { ChartsServices } from './theme/layouts/charts/charts.services';
import { ImportDetailsService } from './theme/pages/import-details/import-details.service';
import { DocumentService } from './modules/documents/pages/document/services/document.service';
import { CommercialPremiseService } from './modules/financial/pages/premise/premise-detail/premise-detail.service';
import { FormTabService } from '#services/_form-tab/form-tab.service';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AdmModule } from './theme/pages/adm-panel/adm-panel.module';
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from './auth/auth.module';
import { BalanceSheetModule } from './theme/pages/default/balance-sheet/balance-sheet.module';
import { ConfigurationModule } from './theme/pages/configuration/configurations.module';
import { CustomDashboardModule } from './theme/pages/default/custom-dashboard/custom-dashboard.module';
import { DragulaModule } from 'ng2-dragula/ng2-dragula';
import { ExternalModule } from './theme/pages/external/external.module';
import { FinanceModule } from './theme/pages/finance/finance.module';
import { InterceptorsModule } from './interceptors/interceptors.module';
import { LayoutModule } from './theme/layouts/layout.module';
import { LocationsModule} from './theme/pages/quotation/locations.module';
import { MaquinaRateioModule } from './theme/pages/default/maquina-rateio/maquina-rateio.module';
import { NewCostsModule } from './theme/pages/costs/new-costs.module';
import { OldReportsModule } from './theme/pages/reports/reports.module';
import { OperationalModule } from './theme/layouts/operational/operational.module';
import { PermissionInfoModule } from './theme/pages/default/permission-info/permission-info.module';
import { SharedServicesModule } from '#services/services.module';
import { SupplyChainModule } from './theme/pages/supply-chain/supply-chain.module';
import { SVGModule } from './svg/svg.module';
import { TechnicalIntegrationsModule } from './theme/pages/technical-integrations/technical-integrations.module';
import { ThemeRoutingModule } from './theme/theme-routing.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localePt from '@angular/common/locales/pt';

registerLocaleData(localeEn);
registerLocaleData(localePt);

@NgModule({
    declarations: [ThemeComponent, AppComponent],
    imports: [
        BrowserModule,
        CommonModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (http: HttpClient) => { return new TranslateHttpLoader(http, './assets/i18n/', '.json'); },
                deps: [HttpClient],
            },
        }),
        HttpClientModule,
        BrowserAnimationsModule,
        InterceptorsModule,
        AppRoutingModule,
        LayoutModule,
        ThemeRoutingModule,
        AuthModule,
        PermissionInfoModule,
        AdmModule,
        OldReportsModule,
        SVGModule,
        SharedServicesModule,
        // CMX
        BalanceSheetModule,
        ConfigurationModule,
        CustomDashboardModule,
        DragulaModule,
        ExternalModule,
        FinanceModule,
        LocationsModule,
        MaquinaRateioModule,
        NewCostsModule,
        OperationalModule,
        SupplyChainModule,
        TechnicalIntegrationsModule,
    ],
    providers: [
        APP_INITIALIZER_PROVIDER,
        ChartsServices,
        CommercialPremiseService,
        DocumentService,
        FormTabService,
        HttpClient,
        ImportDetailsService
    ],
    bootstrap: [AppComponent],
})

export class AppModule {
    static injector: Injector;
    constructor(injector: Injector) {
        AppModule.injector = injector;
    }
}
