import { environment } from '#environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IConsolidationDetail } from './interfaces/document-consolidation-detail.interface';
import { IConsolidation } from './interfaces/document-consolidation.interface';

@Injectable()
export class DocumentConsolidationService {
    constructor(private http: HttpClient) {}

    getDocumentConsolidation(type, startDate, endDate, clients, subsidiaryNames, urfs): Observable<IConsolidation> {
        let headers = new HttpHeaders();
        headers = headers.set('X-Requested-Client-Groups', clients.map((c) => c.id).join(','));

        let url = `${environment.endpoints.importationMongoDBService}/stats/consolidation/${type}?startYearMonth=${startDate}&endYearMonth=${endDate}`;
        url += subsidiaryNames !== '' ? `&subsidiaryNames=${subsidiaryNames}` : '';
        url += urfs !== '' ? `&urfNationalizationCodes=${urfs}` : '';

        return this.http.get<IConsolidation>(url, { headers });
    }

    getDocumentConsolidationDetails(
        type,
        startDate,
        endDate,
        clients,
        subsidiaryNames,
        urfs
    ): Observable<IConsolidationDetail[]> {
        let headers = new HttpHeaders();
        headers = headers.set('X-Requested-Client-Groups', clients.map((c) => c.id).join(','));

        let url = `${environment.endpoints.importationMongoDBService}/stats/consolidation/${type}/details?startYearMonth=${startDate}&endYearMonth=${endDate}`;
        url += subsidiaryNames !== '' ? `&subsidiaryNames=${subsidiaryNames}` : '';
        url += urfs !== '' ? `&urfNationalizationCodes=${urfs}` : '';

        return this.http.get<IConsolidationDetail[]>(url, { headers });
    }

    getStates(startDate, endDate, clients): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set('X-Requested-Client-Groups', clients.map((c) => c.id).join(','));

        return this.http.get(
            `${environment.endpoints.importationMongoDBService}/stats/consolidation/imp-states?startYearMonth=${startDate}&endYearMonth=${endDate}`,
            { headers }
        );
    }
    
}
