<div class="moveBtn"><div class="moveLine"></div></div>
<div *ngIf="async"><loader></loader></div>

<div class="header-container">

    <div class="title-information">
    <span class="analyticsName">SLA Status</span>
    <cmx-help-modal
        help-title="SLA Status"
        [help-description]="helpDescription"
        [help-video-src]="'assets/videos/icone-informativo/dashboardsAtualizado.mp4'"
        [help-video-name]="'Dashboards SLA Status'"
    ></cmx-help-modal>
    <h5>SLA Status</h5>
</div>

    <div id="searchLine" class="reduzed" *ngIf="currentUser | async as user">
        <div class="search-container">
            <div class="input-group" *ngIf="user.has('INTERNAL_FILTERS')">
                <cmx-dropdown
                    [styles]="{ width: '100px' }"
                    name="clients"
                    [ngClass]="showDetailTable ? 'extended' : ''"
                    [data]="options.client"
                    [ngModel]="filterState.getClientGroups()"
                    [settings]="dropdownSettings.clientGroups"
                    (ngModelChange)="filterState.setClientGroups($event)"
                ></cmx-dropdown>
            </div>

            <div class="input-group">
                <span class="input-group-addon">Por</span>
                <cmx-filter-switch
                    [values]="typeSwitch.values"
                    [inputIndex]="typeSwitch.index"
                    (onSwitch)="toggleTypeSwitch($event)"
                    [style]="{ marginLeft: '-15px' }"
                ></cmx-filter-switch>
            </div>

            <div class="input-group">
                <cmx-filter-datepicker
                    [startDate]="filterState.getStartDate()"
                    [endDate]="filterState.getEndDate()"
                    [endDateRestriction]="endDateRestriction"
                    (onChangeDates)="datesChanged($event)"
                ></cmx-filter-datepicker>
            </div>

            <button (click)="applyFilter()" class="applyImpStatus">
                <em class="fa fa-check"></em>
            </button>
        </div>
    </div>
</div>

<span class="noContent" *ngIf="errorMessage">{{ errorMessage }}</span>

<cmx-simple-line-chart
    [style.visibility]="!async && !errorMessage ? 'visible' : 'hidden'"
    [chartData]="resultValues"
></cmx-simple-line-chart>

<cmx-custom-grid
    id="slaConsolidationGrid"
    [style.visibility]="resultRows?.length && !async && showDetailTable ? 'visible' : 'hidden'"
    [columns]="resultColumns"
    [rows]="resultRows"
    [options]="{ forceFitColumns: true, rowHeight: 40 }"
    [styles]="{ height: showDetailTable ? getGridHeight() + 'px' : '0px', width: '99%' }"
    (onRowClick)="onRowClick($event)"
></cmx-custom-grid>

<div *ngIf="!errorMessage" class="detailButton" (click)="showDetail()"
    >Detalhamento
    <span class="material-icons">{{ showDetailTable ? 'arrow_drop_up' : 'arrow_drop_down' }}</span>
</div>
