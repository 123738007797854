<div *ngIf="!asyncCrTable || !loadingRows">
    <loader></loader>
</div>
<div style="margin-bottom:30px" class="title-information">
    <span class="analyticsName">Faturamento x CR</span>
    <cmx-help-modal
        [help-video-src]="'assets/videos/icone-informativo/dashboardsAtualizado.mp4'"
        [help-video-name]="'Dashboards Faturamento x CR'"
        help-title="Faturamento x CR"
        [help-description]="helpDescription">
    </cmx-help-modal>
    <h5>Faturamento x CR</h5>
</div>
<div class="moveBtn">
    <div class="moveLine"></div>
</div>
<cmx-marker-filter
    [alignment]="'center'"
    [filters]="periodTypes"
    [preSelected]="preSelectedPeriod"
    type="period"
    [multiple]="false"
    [hasSelectAll]="false"
    (onSelected)="onSelectedPeriod($event)"
></cmx-marker-filter>

<div class="custom-date" *ngIf="customSelection">
    {{startDate}} à {{endDate}}
</div>

<em class="editBtn la la-ellipsis-v" (click)="openEditModal();"></em>

<cmx-filter-wrapper [flex]="false" (onClick)="applyFilter(true)">
    <cmx-filter-wrapper-group>
        <cmx-dropdown [styles]="{width: '190px'}"
            name="companies"
            [data]="options.companies"
            [ngModel]="filterState.getCompanies()"
            (ngModelChange)="filterState.setCompanies($event)"
            (onChange)="changeDropdown()"
            [settings]="dropdownSettingsCompanies">
        </cmx-dropdown>
    </cmx-filter-wrapper-group>
    <cmx-filter-wrapper-group label="Tipo de Faturamento">
        <cmx-filter-switch
            [values]="typeSwitch.values"
            [inputIndex]="typeSwitch.values.indexOf(filterState.getTypeSwitch())"
            (onSwitch)="toggleTypeSwitch($event)"
        ></cmx-filter-switch>
    </cmx-filter-wrapper-group>
    <cmx-filter-wrapper-group label="Por" [divisor]="false" [label-inline]="true">
        <cmx-filter-switch
            [values]="viewSwitch.values"
            [inputIndex]="viewSwitch.values.indexOf(filterState.getViewSwitch())"
            (onSwitch)="toggleViewSwitch($event)"
        ></cmx-filter-switch>
    </cmx-filter-wrapper-group>
    <cmx-filter-wrapper-group label="Ordenar por" [divisor]="false">
        <cmx-filter-switch
            [values]="sortSwitch.values"
            [inputIndex]="sortSwitch.values.indexOf(filterState.getSortSwitch())"
            (onSwitch)="toggleSortSwitch($event)"
        ></cmx-filter-switch>
    </cmx-filter-wrapper-group>
    <cmx-filter-wrapper-group label="Valores zerados" [divisor]="false">
        <cmx-filter-switch
            [values]="zeroValuesSwitch.values"
            [inputIndex]="zeroValuesSwitch.values.indexOf(filterState.getZeroValuesSwitch())"
            (onSwitch)="toggleZeroValuesSwitch($event)"
        ></cmx-filter-switch>
    </cmx-filter-wrapper-group>
    <cmx-filter-wrapper-group label="Em" [divisor]="false" [label-inline]="true" *ngIf="this.filterState.getTypeSwitch() === 'Internacionais'">
        <cmx-filter-switch
            [values]="currencySwitch.values"
            [inputIndex]="currencySwitch.values.indexOf(this.filterState.getCurrencySwitch())"
            (onSwitch)="toggleCurrencySwitch($event)"
        ></cmx-filter-switch>
    </cmx-filter-wrapper-group>
</cmx-filter-wrapper>

<span *ngIf="chartData === null" class="empty-message">Dados não encontrados</span>
<cmx-custom-grid
    id="crProcessesGrid"
    [style.visibility]="gridRows?.length && asyncCrTable ? 'visible' : 'hidden'"
    [loading]="asyncCrTable"
    [columns]="gridColumns"
    [rows]="gridRows"
    [options]="{ forceFitColumns: gridColumns.length < 8 ? true : false, rowHeight: 27, frozenColumn: 0 }"
    [styles]="{ height: '400px' }"
    (onCellClick)="onCellClick($event)"
    (onSortColumn)="onSortColumn($event)"
></cmx-custom-grid>

<div *ngIf="asyncCrTable === true && chartData === undefined" class="reloadContainer">
    <div class="reloadContent">
        <em (click)="applyFilter()" class="material-icons">refresh</em>
    </div>
</div>


<cmx-custom-dialog
    [widthModal]="50"
    [maxWidthModal]="340"
    [id]="dialogId"
    animation="right-left"
    [title]="dialogTitle"
    [closeButton]="true"
    [overlay]="true"
    (modal-closed)="showModal = false"
>
    <ng-container cmx-custom-dialog-body>
        <div class="container-processes" *ngIf="showModal">
            <div class="form-processes">
                <div class="switches">
                    <div class="switch-modal">
                        <p>Consolidação</p>
                        <div class="switchValue">
                            <cmx-switch
                                [options]="[{ name: 'Mensal' }, { name: 'Diária' }]"
                                [selected]="selectedPeriod"
                                (onSelect)="toggleTablePeriodSwitch($event)"
                            ></cmx-switch>
                        </div>
                    </div>
                    <div class="switch-modal">
                        <p>InterCompany</p>
                        <div class="switchValue">
                            <cmx-switch
                                [options]="[{ name: 'Desconsiderar' }, { name: 'Considerar' }]"
                                [selected]="selectedInterCompany"
                                (onSelect)="toggleInterCompanySwitch($event)"
                            ></cmx-switch>
                        </div>
                    </div>
                </div>
                <div class="radio-buttons">
                    <p>Valores</p>
                    <div class="group-radios">
                        <label class="radio-modal">
                            <input (change)="toggleTableValueSwitch($event)" name="valueFormat" type="radio" [value]="'noCents'" [checked]="filterState.getValueSwitch() === 'noCents'">
                            Sem Centavos
                        </label>
                        <label class="radio-modal">
                            <input (change)="toggleTableValueSwitch($event)" name="valueFormat" type="radio" [value]="'thousand'" [checked]="filterState.getValueSwitch() === 'thousand'">
                            Milhar
                        </label>
                        <label class="radio-modal">
                            <input (change)="toggleTableValueSwitch($event)" name="valueFormat" type="radio" [value]="'million'" [checked]="filterState.getValueSwitch() === 'million'">
                            Milhão
                        </label>
                    </div>
                </div>
                <div class="dates-modal">
                    <p>Período</p>
                    <div class="dates-group" *ngIf="!clearDates">
                        <div class="date-from">
                            <span>De</span>
                            <cmx-filter-datepicker
                                id="period-from"
                                [visual]="'big'"
                                [alone]="true"
                                [startDate]="startDate"
                                [type]="checkPeriodDates('type')"
                                [periodType]="checkPeriodDates('periodType')"
                                [startDateRestriction]="checkPeriodDates('startDateRestriction')"
                                [endDateRestriction]="checkPeriodDates('endDateRestriction')"
                                (onChangeDates)="setDateFilter('startDate', $event)"
                                [empty-dates]="true"
                                ></cmx-filter-datepicker>
                        </div>
                        <div class="date-from">
                            <span>Até</span>
                            <cmx-filter-datepicker
                                id="period-top"
                                [visual]="'big'"
                                [alone]="true"
                                [startDate]="endDate"
                                [type]="checkPeriodDates('type')"
                                [periodType]="checkPeriodDates('periodType')"
                                [startDateRestriction]="checkPeriodDates('startDateRestriction')"
                                [endDateRestriction]="checkPeriodDates('endDateRestriction')"
                                (onChangeDates)="setDateFilter('endDate', $event)"
                                [empty-dates]="true"
                                ></cmx-filter-datepicker>
                        </div>
                    </div>
                </div>
            </div>
            <div class="actions-processes">
                <cmx-button (click)="onHandlerFilter()">Filtrar</cmx-button>
            </div>
        </div>
    </ng-container>
</cmx-custom-dialog>
