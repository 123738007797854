import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { interval } from 'rxjs';
import { NgxPowerBiService } from 'ngx-powerbi';

import { UserService } from 'app/auth/_services/user.service';
import { ReportService } from '../report.service';
import { UsageAnalyticsService } from '#services/_usage-analytics/usage-analytics.service';
import { IPageWrapperTitle } from 'app/shared/components/cmx-page-wrapper/page-wrapper-interface';

@Component({
    selector: 'app-power-bi',
    templateUrl: './power-bi.component.html',
    styleUrls: ['./power-bi.component.scss'],
})
export class PowerBiComponent implements OnInit {
    titleComponent: IPageWrapperTitle = {
        title: 'Relatórios Externos'
    }

    paramReport: string = null;
    loadingReport: boolean = true;

    powerBiService: NgxPowerBiService;
    pbiContainerElement: HTMLElement;

    _tabIndex: number = null;

    filterPaneEnabled: boolean = false;
    navContentPaneEnabled: boolean = false;
    showDashCms: boolean = false;
    configurations;
    autoRefreshEnabled: boolean = false;

    report;

    user = this._userService.getCurrentUser();
    urlSafe: SafeResourceUrl;

    config = {
        type: 'report',
        tokenType: 1,
        id: null,
        code: null,
        embedUrl: null,
        accessToken: null,
        settings: {
            filterPaneEnabled: this.filterPaneEnabled,
            navContentPaneEnabled: this.navContentPaneEnabled,
        },
    };

    private _currentTabSelected;

    constructor(
        private _reportService: ReportService,
        private _userService: UserService,
        private usageAnalyticsService: UsageAnalyticsService,
        private sanitizer: DomSanitizer,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    get currentTabSelected() {

        return this._currentTabSelected;
    }

    ngOnInit(): void {

        this.powerBiService = new NgxPowerBiService();
        this._createConfigurations();

        interval(600000).subscribe((val) => {
            if (this.autoRefreshEnabled) {
                this.refreshReport();
            }
        });
    }

    ngAfterViewInit() {

        setTimeout(() => {
            this.route.params.subscribe(paramRoute => {

                if(paramRoute && paramRoute.report){
                    const getReport = this.configurations.filter(c => c.paramRoute === paramRoute.report);

                    if(getReport.length > 0 && this._userService.getCurrentUser().has(getReport[0].permission)){
                        this.paramReport = getReport[0].paramRoute;
                    }else{
                        this.paramReport = null;

                        if(getReport.length === 0){
                            return this.router.navigate(['/404']);
                        }

                        if(!this._userService.getCurrentUser().has(this.configurations[0].permission)){
                            return this.router.navigate(['/index']);
                        }
                    }
                    this.loadingReport = true;
                    this._getStartTab(this.paramReport);
                    this.pbiContainerElement = <HTMLElement>document.getElementById('report-container');
                    this.handleEmbed();

                }
            })
        }, 0);
    }

    handleEmbed() {
        this.loadingReport = true;

        this.changeTab(this._tabIndex);
    }

    async changeTab(tabIndex) {

        this._tabIndex = tabIndex;

        const conf = this.paramReport ? this.configurations.filter(p => p.paramRoute === this.paramReport)[0] : this.configurations[0];

        if (conf.powerbi) {
            const token = await this._reportService
                .getToken('b55d3660-78f1-46af-a537-e64ef6ebd006', conf.code)
                .toPromise()
                .then((data) => {
                    return data.token;
                });
            this.config.accessToken = token;

            this.config.id = conf.code;
            this.config.embedUrl = conf.url;
            this.report = this.powerBiService.embed(this.pbiContainerElement, this.config);
            this.usageAnalyticsService.sendEvent('Feature', 'view', conf.title, null, {});

            const self = this;
            this.report.on('loaded', function(event){
                self.loadingReport = false;
                this.showDashCms = false;
            });
        } else {
            this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(conf.url);
            this.usageAnalyticsService.sendEvent('Feature', 'view', conf.title, null, {});
            this.showDashCms = false;
            this.loadingReport = false;
        }

        
    }

    _getStartTab(currentTab = null) {
        const tabFromRoute = this.configurations.find(c => c.paramRoute === currentTab);
        const elements = document.getElementsByClassName('page-tab');
        for (let index = 0; index < elements.length; index++) {
            let tab = elements[index];
            if (tabFromRoute && tabFromRoute.code === tab.id) {
                this._tabIndex = index;
                this._currentTabSelected = tabFromRoute;
                return;
            }
        }
        this._currentTabSelected = this.configurations.find(c => c.code === elements[0].id);
        this._tabIndex = 0;
    }

    _createConfigurations() {

        this.configurations = [
            {
                paramRoute: 'iff',
                code: 'ad4bcca8-e89c-403b-abfc-1946032782b9',
                url: 'https://app.powerbi.com/reportEmbed?reportId=ad4bcca8-e89c-403b-abfc-1946032782b9&groupId=0f090951-542a-49a3-bfdf-1810237a0683',
                title: 'IFF',
                powerbi: true,
                permission: 'REPORT_IFF',
            },
            {
                paramRoute: 'loreal',
                code: 'b44bb277-7812-4f8b-a669-0a50a4785767',
                url: 'https://app.powerbi.com/reportEmbed?reportId=b44bb277-7812-4f8b-a669-0a50a4785767&groupId=0f090951-542a-49a3-bfdf-1810237a0683',
                title: 'L’Oréal',
                powerbi: true,
                permission: 'REPORT_LOREAL',
            },
            {
                paramRoute: 'novelis',
                code: '3ab7486b-a10f-4389-b3c1-58510822b36c',
                url: 'https://app.powerbi.com/reportEmbed?reportId=3ab7486b-a10f-4389-b3c1-58510822b36c&groupId=0f090951-542a-49a3-bfdf-1810237a0683',
                title: 'Novelis',
                powerbi: true,
                permission: 'REPORT_NOVELIS',
            },
            {
                paramRoute: 'fiscal',
                code: 'e9978ffa-8474-4319-b3d2-200a0f93882e',
                url: 'https://app.powerbi.com/reportEmbed?reportId=e9978ffa-8474-4319-b3d2-200a0f93882e&groupId=0f090951-542a-49a3-bfdf-1810237a0683',
                title: 'Fiscal',
                powerbi: true,
                permission: 'REPORT_FISCAL',
            },
            {
                paramRoute: 'all-brands-vix',
                code: '66de46c3-c216-4244-b4e8-88cd71c5e75b',
                url: 'https://app.powerbi.com/reportEmbed?reportId=66de46c3-c216-4244-b4e8-88cd71c5e75b&groupId=0f090951-542a-49a3-bfdf-1810237a0683',
                title: 'Multimarcas - VIX',
                powerbi: true,
                permission: 'REPORT_ALL_BRANDS_VIX',
            },
            {
                paramRoute: 'all-brands',
                code: 'dddcfc1d-a149-4929-92fd-a29f2f6f5b76',
                url: 'https://app.powerbi.com/reportEmbed?reportId=dddcfc1d-a149-4929-92fd-a29f2f6f5b76&groupId=0f090951-542a-49a3-bfdf-1810237a0683',
                title: 'Multimarcas',
                powerbi: true,
                permission: 'REPORT_ALL_BRANDS',
            },
            {
                paramRoute: 'tokstok',
                code: '9f490671-3324-47f2-a787-8a1a0a4accbd',
                url: 'https://app.powerbi.com/reportEmbed?reportId=9f490671-3324-47f2-a787-8a1a0a4accbd&groupId=0f090951-542a-49a3-bfdf-1810237a0683',
                title: 'Tok&Stok',
                powerbi: true,
                permission: 'REPORT_TOKSTOK',
            },
            {
                paramRoute: 'cms-user',
                code: '4451d452-63b2-45b9-a7ab-c4c1687d0059',
                url: 'https://app.powerbi.com/reportEmbed?reportId=4451d452-63b2-45b9-a7ab-c4c1687d0059&groupId=0f090951-542a-49a3-bfdf-1810237a0683',
                title: 'Usuários CMS',
                powerbi: true,
                permission: 'REPORT_CMS_USER',
            },
            {
                paramRoute: 'byd',
                code: '62e67a05-4d15-400f-a25e-dae204f31960',
                url: 'https://app.powerbi.com/reportEmbed?reportId=62e67a05-4d15-400f-a25e-dae204f31960&groupId=0f090951-542a-49a3-bfdf-1810237a0683',
                title: 'BYD',
                powerbi: true,
                permission: 'REPORT_BYD',
            },
            {
                paramRoute: 'byd-suape',
                code: '4a8d7611-edf4-47fb-bc06-8b7378ed99bc',
                url: 'https://app.powerbi.com/reportEmbed?reportId=4a8d7611-edf4-47fb-bc06-8b7378ed99bc&groupId=0f090951-542a-49a3-bfdf-1810237a0683',
                title: 'BYD Suape',
                powerbi: true,
                permission: 'REPORT_BYD_SUAPE',
            },
            {
                paramRoute: 'byd-sc',
                code: 'a84be2d7-66ce-481e-bd13-d7a8d6374071',
                url: 'https://app.powerbi.com/reportEmbed?reportId=a84be2d7-66ce-481e-bd13-d7a8d6374071&groupId=0f090951-542a-49a3-bfdf-1810237a0683',
                title: 'BYD SC',
                powerbi: true,
                permission: 'REPORT_BYD_SC',
            },
            {
                paramRoute: 'gm',
                code: '8ec5489a-e1df-405a-8119-1dedb26ef8d2',
                url: 'https://app.powerbi.com/reportEmbed?reportId=8ec5489a-e1df-405a-8119-1dedb26ef8d2&groupId=0f090951-542a-49a3-bfdf-1810237a0683',
                title: 'GM',
                powerbi: true,
                permission: 'REPORT_GM',
            },
            {
                paramRoute: 'gm-vix',
                code: 'cdd3667a-b87b-469e-b715-339deec8223a',
                url: 'https://app.powerbi.com/reportEmbed?reportId=cdd3667a-b87b-469e-b715-339deec8223a&groupId=0f090951-542a-49a3-bfdf-1810237a0683',
                title: 'Visão 360º GM VIX',
                powerbi: true,
                permission: 'REPORT_GM_VIX',
            },
            {
                paramRoute: 'honda',
                code: '0fda8955-cf7b-4fa4-a5d9-f1e9e302dc84',
                url: 'https://app.powerbi.com/reportEmbed?reportId=0fda8955-cf7b-4fa4-a5d9-f1e9e302dc84&groupId=0f090951-542a-49a3-bfdf-1810237a0683',
                title: 'Honda',
                powerbi: true,
                permission: 'REPORT_HONDA',
            },
            {
                paramRoute: 'volvo',
                code: '8c876e2c-7c98-4c6c-b809-399fc3c09f69',
                url: 'https://app.powerbi.com/reportEmbed?reportId=8c876e2c-7c98-4c6c-b809-399fc3c09f69&groupId=0f090951-542a-49a3-bfdf-1810237a0683',
                title: 'Volvo',
                powerbi: true,
                permission: 'REPORT_VOLVO',
            },
            {
                paramRoute: 'volkswagen',
                code: '15449782-3a91-4e8a-8809-97578a5bf1ba',
                url: 'https://app.powerbi.com/reportEmbed?reportId=15449782-3a91-4e8a-8809-97578a5bf1ba&groupId=0f090951-542a-49a3-bfdf-1810237a0683',
                title: 'Volkswagen',
                powerbi: true,
                permission: 'REPORT_VOLKSWAGEN',
            },
            {
                paramRoute: 'gwm',
                code: 'd61ba05c-445d-4587-ad62-5d4eddb7e961',
                url: 'https://app.powerbi.com/reportEmbed?reportId=d61ba05c-445d-4587-ad62-5d4eddb7e961&groupId=0f090951-542a-49a3-bfdf-1810237a0683',
                title: 'GWM',
                powerbi: true,
                permission: 'REPORT_GWM',
            },
            {
                paramRoute: 'mercedes',
                code: '5d234db5-5901-447d-8a5b-27561a762536',
                url: 'https://app.powerbi.com/reportEmbed?reportId=5d234db5-5901-447d-8a5b-27561a762536&groupId=0f090951-542a-49a3-bfdf-1810237a0683',
                title: 'Mercedes',
                powerbi: true,
                permission: 'REPORT_MERCEDES',
            },
            {
                paramRoute: 'toyota',
                code: 'be3b2005-2795-4679-ae96-ce0a0caf61a7',
                url: 'https://app.powerbi.com/reportEmbed?reportId=be3b2005-2795-4679-ae96-ce0a0caf61a7&groupId=0f090951-542a-49a3-bfdf-1810237a0683',
                title: 'Toyota',
                powerbi: true,
                permission: 'REPORT_TOYOTA',
            },
            {
                paramRoute: 'solar',
                code: 'bf6b17bb-b492-497f-835d-e7d9c01083ec',
                url: 'https://app.powerbi.com/reportEmbed?reportId=bf6b17bb-b492-497f-835d-e7d9c01083ec&groupId=3e52fc17-b50f-4d25-bfdc-b618c82890b0',
                title: 'Solar',
                powerbi: true,
                permission: 'REPORT_SOLAR',
            },
            {
                paramRoute: 'estoque-cxt',
                code: '9f10b135-a968-4741-add0-d786dedfb25c',
                url: 'https://app.powerbi.com/reportEmbed?reportId=9f10b135-a968-4741-add0-d786dedfb25c&groupId=facc3167-e8e2-4644-a850-884bea9c462e',
                title: 'Estoque CXT',
                powerbi: true,
                permission: 'REPORT_CXT',
            },
            {
                paramRoute: 'painel-supri',
                code: 'c71cf76f-6394-46ea-9da1-371840a02ffd',
                url: 'https://app.powerbi.com/reportEmbed?reportId=c71cf76f-6394-46ea-9da1-371840a02ffd&groupId=facc3167-e8e2-4644-a850-884bea9c462e',
                title: 'Painel Supri',
                powerbi: true,
                permission: 'REPORT_CXT',
            },
            {
                paramRoute: 'painel-gerencial-cxt',
                code: '34e6280d-590a-46c9-86d1-504116adaea9',
                url: 'https://app.powerbi.com/reportEmbed?reportId=34e6280d-590a-46c9-86d1-504116adaea9&groupId=facc3167-e8e2-4644-a850-884bea9c462e',
                title: 'Painel Gerencial CXT',
                powerbi: true,
                permission: 'REPORT_MANAGEMENT_PANEL_CXT',
            },
            {
                paramRoute: 'fluxo-produtos-cxt',
                code: '0b4a2f27-4f60-4b59-8269-73478f5f0e17',
                url: 'https://app.powerbi.com/reportEmbed?reportId=0b4a2f27-4f60-4b59-8269-73478f5f0e17&groupId=facc3167-e8e2-4644-a850-884bea9c462e',
                title: 'Fluxo Produtos CXT',
                powerbi: true,
                permission: 'REPORT_PRODUCTS_CXT',
            },
            {
                paramRoute: 'kpi-ncpe',
                code: '702adc4a-8b28-4ad4-a63c-7ce6bafaa4be',
                url: 'https://app.powerbi.com/reportEmbed?reportId=702adc4a-8b28-4ad4-a63c-7ce6bafaa4be&groupId=0f090951-542a-49a3-bfdf-1810237a0683',
                title: 'KPI - NCPE',
                powerbi: true,
                permission: 'REPORT_KPI_NCPE',
            },
            {
                paramRoute: 'comparativo',
                code: 'ee1e2ba0-4693-492b-b719-a95b84f1fbb9',
                url: 'https://app.powerbi.com/reportEmbed?reportId=ee1e2ba0-4693-492b-b719-a95b84f1fbb9&groupId=0f090951-542a-49a3-bfdf-1810237a0683',
                title: 'Comparativo',
                powerbi: true,
                permission: 'REPORT_COMPARATIVO',
            },
            {
                paramRoute: 'porsche',
                code: 'b0c5e0d9-41e0-4d23-91a8-3b00dc279448',
                url: 'https://app.powerbi.com/reportEmbed?reportId=b0c5e0d9-41e0-4d23-91a8-3b00dc279448&groupId=0f090951-542a-49a3-bfdf-1810237a0683',
                title: 'Porsche',
                powerbi: true,
                permission: 'REPORT_PORSCHE',
            },
            {
                paramRoute: 'hyundai',
                code: '1c3519c9-c41d-4459-9cdf-fc94a656b3c3',
                url: 'https://app.powerbi.com/reportEmbed?reportId=1c3519c9-c41d-4459-9cdf-fc94a656b3c3&groupId=0f090951-542a-49a3-bfdf-1810237a0683',
                title: 'Hyundai',
                powerbi: true,
                permission: 'REPORT_HYUNDAI',
            },
            {
                paramRoute: 'sebra',
                code: 'a6bdfe6b-0e50-4110-82f9-8c4b44352ade',
                url: 'https://app.powerbi.com/reportEmbed?reportId=a6bdfe6b-0e50-4110-82f9-8c4b44352ade&groupId=0f090951-542a-49a3-bfdf-1810237a0683',
                title: 'Sebra',
                powerbi: true,
                permission: 'REPORT_SEBRA',
            },
            {
                paramRoute: 'financeiro',
                code: '40c7e43c-70eb-44e8-bae8-a4405b5a81ff',
                url: 'https://app.powerbi.com/reportEmbed?reportId=40c7e43c-70eb-44e8-bae8-a4405b5a81ff&groupId=f7112d37-b429-431b-a349-5ee045763f23',
                title: 'PBI Financeiro',
                powerbi: true,
                permission: 'REPORT_PBI_FINANCEIRO',
            },
            {
                paramRoute: 'cxt-carregamento',
                code: '047fb7b5-d123-489b-a160-83fa3618d9db',
                url: 'https://app.powerbi.com/reportEmbed?reportId=047fb7b5-d123-489b-a160-83fa3618d9db&groupId=0f090951-542a-49a3-bfdf-1810237a0683',
                title: 'CXT Carregamento',
                powerbi: true,
                permission: 'REPORT_CXT_CARREGAMENTO',
            },
            {
                paramRoute: 'processamento-pedidos-cxt',
                code: '5269b20b-b98c-4dfe-a070-45cebb196288',
                url: 'https://app.powerbi.com/reportEmbed?reportId=5269b20b-b98c-4dfe-a070-45cebb196288&groupId=facc3167-e8e2-4644-a850-884bea9c462e',
                title: 'Processamento Pedidos CXT',
                powerbi: true,
                permission: 'REPORT_PROCESSAMENTO_PEDIDOS_CXT',
            },
            {
                paramRoute: 'csl',
                code: '06bd1a74-de9b-409a-86a1-911b79ad204f',
                url: 'https://app.powerbi.com/reportEmbed?reportId=06bd1a74-de9b-409a-86a1-911b79ad204f&groupId=0f090951-542a-49a3-bfdf-1810237a0683',
                title: 'CSL',
                powerbi: true,
                permission: 'REPORT_CSL',
            }
            
        ];
    }

    openFullscreen() {

        const powerbi = this.configurations[this._tabIndex].powerbi;
        let element: HTMLElement = this.pbiContainerElement;
        if (!powerbi) {
            element = document.getElementById('report-datastudio');
        }
        element.requestFullscreen();
    }

    handleRefresh() {

        this.autoRefreshEnabled = !this.autoRefreshEnabled;
    }

    async refreshReport() {

        const token = await this._reportService
            .getToken('b55d3660-78f1-46af-a537-e64ef6ebd006',this.config.code)
            .toPromise()
            .then((data) => {
                return data.token;
            });
        this.config.accessToken = token;
        this.report.setAccessToken(token);
        this.report.refresh();
    }

    setCurrentSelectedTab(tabSelected) {

        this.router.navigate(['../',tabSelected.paramRoute], {relativeTo: this.route});
    }
}
