<div class="widget-container">
    <div class="move-container" [style.height]="widgetMovable ? '60px' : '0px'">
        <div class="move-line" [style.cursor]="widgetMovable ? '-webkit-grab' : 'default'"></div>
    </div>
    <div class="header-container">
        <h5 class="title">{{ widgetName }}</h5>
    </div>
    <div class="widget-top-container">
        <div class="button-container shared-users-container">
            <span class="material-icons" [tooltip]="getSharedInfo()">supervisor_account</span>
        </div>
        <div class="button-container edit-container">
            <span class="material-icons">settings</span>
        </div>
    </div>
    <cmx-filter-line *ngIf="filterLineStructure?.length"
        [filterStructure]="filterLineStructure"
        [leftMargin]="'0px'"
        (onFilterChanges)="onFilterChanges($event)"
        (onApplyFilters)="applyFilter()">
    </cmx-filter-line>
    <div class="body-container">
        <div *ngIf="chartConfig && !chartDataLoaded"><loader></loader></div>
        <div *ngIf="chartConfig" class="chart" [id]="'customWidgetChart-' + chartConfig.chartId"></div>
    </div>
</div>