import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WebsocketModule } from './_websocket/websocket.module';
import { environment } from '#environment';

import { AppConfigService } from './_app-config/app-config.service';
import { ApportionmentService } from './_apportionment/apportionment.service';
import { DownloadModalService } from './_download/download-modal.service';
import { CountdownService } from './_countdown/countdown.service';
import { CountryFlagsService } from './_country-flags/country-flags.service';
import { CustomService } from './_custom/custom.service';
import { DomainService, MockedDomainService } from './_domain/domain.service';
import { DomainTypeService } from './_domain/domainType.service';
import { DownloadService } from './_download/download.service';
import { EventSourceService } from './_eventSource/eventSource.service';
import { FeatureFlagService } from './_feature-flag/feature-flag-service';
import { FeatureFlagsService } from './_feature-flag/feature-flags.service';
import { FinanceExportService } from './_finance-export/finance-export.service';
import { LoginPermissionService } from './_login-permission/login-permission.service';
import { MessagesService } from './_messages/messages.service';
import { NewFeaturesConsumerService } from './_newFeatureConsumer/newFeatureConsumer.service';
import { NotificationService } from './_notification/notification.service';
import { MockedBalanceSheetService, ResultsService } from './_results/results.service';
import { StatusService } from './_status/status.service';
import { NewTrafficTraceabilityService } from './_traffic-traceability/newTraffic-traceability.service';
import { TransportModalService } from './_transportModal/transportModal.service';
import { UsageAnalyticsService } from './_usage-analytics/usage-analytics.service';
import { UserCacheService } from './_user/app-user-cache.service';
import { UserPermissionBlockerService } from './_user/userPermissionBlocker.service';
import { UserPreferencesService } from './_user/user-preferences.service';
import { UtilsService } from './_utils/utils.service';
import { FilterStateService } from './_filters/filter-state.service';
import { DatamartService } from './_datamart/datamart.service';
import { PageTabControlService } from './_page-tab-control/page-tab-control.service';
import { MenuControlService } from './_menu-control/menu-control.service';
import { CustomGridUtilsService } from 'app/shared/components/cmx-custom-grid/services/custom-grid-utils.service';
import { ReportPreferencesService } from './_report-preferences/report-preferences.service';
import { FormTabService } from './_form-tab/form-tab.service';
import { AmCharts5Service } from './_charts/charts.service';
import { HeaderNavService } from 'app/theme/layouts/header-nav/header-nav.service';

export function providerFor(real: any, mocked: any) {
    return {
        provide: real,
        useClass: environment.mock ? mocked : real,
    };
}

@NgModule({
    declarations: [],
    imports: [
      CommonModule,
      WebsocketModule
    ],
    exports: [
      WebsocketModule
    ],
    providers: [
        providerFor(DomainService, MockedDomainService),
        providerFor(ResultsService, MockedBalanceSheetService),
        providerFor(ApportionmentService, ApportionmentService),
        HeaderNavService,
        AppConfigService,
        DownloadModalService,
        CountdownService,
        CountryFlagsService,
        CustomService,
        DomainTypeService,
        DownloadService,
        EventSourceService,
        FeatureFlagService,
        FeatureFlagsService,
        FinanceExportService,
        FilterStateService,
        LoginPermissionService,
        MessagesService,
        NewFeaturesConsumerService,
        NewTrafficTraceabilityService,
        NotificationService,
        StatusService,
        TransportModalService,
        UsageAnalyticsService,
        UserCacheService,
        UserPermissionBlockerService,
        UserPreferencesService,
        UtilsService,
        DatamartService,
        PageTabControlService,
        MenuControlService,
        CustomGridUtilsService,
        ReportPreferencesService,
        AmCharts5Service,
        FormTabService
    ],
})
export class SharedServicesModule {}
