import { environment } from '#environment';
import { Component, ViewEncapsulation } from '@angular/core';
import { UserService } from "app/auth/_services/user.service";
import { FeatureFlagService } from '#services/_feature-flag/feature-flag-service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from '#services/_utils/utils.service';
import { AuthenticationService } from 'app/auth/_services/authentication.service';

@Component({
    selector: 'chat-ai',
    templateUrl: './chat-ai.component.html',
    styleUrls: ['./chat-ai.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ChatAIComponent {

    currentUser = this.userService.getCurrentUser();
    enabledChatAI: boolean = false;
    collapseTimeout: any;
    expanded: boolean = false;

    chatAiPreferences: any = {
        rememberClose: false
    };
    asyncEditPhone = true;
    errorMessage = '';

    notification = false;
    phoneIntegrationData: any = null;
    whatsappUrl;
    otpInterval = null;
    showOtp: boolean = false;

    phoneNumber: any = null;
    updatePhoneNumber: boolean = false;
    formattedPhoneNumber: any = null;

    constructor(
        private userService: UserService,
        private _featureFlagService: FeatureFlagService,
        private _userPreferencesService: UserPreferencesService,
        private _userCache: UserCacheService,
        private _utilsService: UtilsService,
        private _translateService: TranslateService,
        private _authenticationService: AuthenticationService
    ) { }

    ngOnInit() {
        this._fetchUserPermission();
        this.getPhoneIntegrationData();
    }

    ngOnDestroy() {
        clearInterval(this.otpInterval);
    }

    startOtpCodePolling() {
        this.otpInterval = setInterval(() => this.getPhoneIntegrationData(), 60000);
    }

    expand(): void {
        if (this.collapseTimeout) {
            clearTimeout(this.collapseTimeout);
            this.collapseTimeout = null;
        }
        this.expanded = true;
    }

    collapse(): void {
        this.collapseTimeout = setTimeout(() => {
            this.close();
        }, 30_000);
    }

    editPhoneOnKeydown(event) {
        if (event.key === 'Enter') {
            this.savePhoneIntegration();
        }
    }

    showEditPhoneOnKeydown() {
        this.updatePhoneNumber = true;
        this.phoneNumber = this.phoneIntegrationData.phoneNumber;
    }
    cancelPhoneOnKeydown() {
        this.updatePhoneNumber = false;
        this.errorMessage = '';
    }

    savePhoneIntegration() {
        this.errorMessage = '';
        if ( this.updatePhoneNumber && this.phoneIntegrationData.phoneNumber === this.phoneNumber) {
            this.cancelPhoneOnKeydown();
            return;
        }

        if (this._verifyPhoneNumber(this.phoneNumber) === false) {
            this.errorMessage = this._translateService.instant('auth.msgInvalidPhoneNumber');
            this.phoneNumber = this.updatePhoneNumber ? this.phoneIntegrationData.phoneNumber : null;
            return;
        }
        if (this.updatePhoneNumber) {
            this._updatePhoneIntegration();
        } else {
            this._createPhoneIntegration();
        }
    }

    close(): void {
        if (this.chatAiPreferences.rememberClose) {
            this.saveUserPreferences();
            setTimeout(() => {
                this.enabledChatAI = false;
            }, 200);
        }
        this.expanded = false;
    }

    saveUserPreferences() {
        this.chatAiPreferences = this._userPreferencesService.verifyFilterChanges(
            {}, this.chatAiPreferences, 'chatAi', this._userCache.getUserPreferences().id);
    }

    getPhoneIntegrationData() {
        const uid = this.userService.getCurrentUser().getUid();
        this._authenticationService.getPhoneIntegrationData(uid).subscribe(
            (data: any) => {
                this.phoneIntegrationData = data;
                this.phoneNumber = data.phoneNumber;
                this.cancelPhoneOnKeydown();
                const match = data.phoneNumber.match(/^(\d{2})(\d{5})(\d{4})$/);
                this.formattedPhoneNumber = `(${match[1]}) ${match[2]}-${match[3]}`;
                this.whatsappUrl = this._generateWhatsappUrl(data.otpCode);
                if (data.otpCode === null && this.otpInterval !== null) {
                    clearInterval(this.otpInterval);
                } else if (data.otpCode !== null && this.otpInterval === null) {
                    this.startOtpCodePolling();
                }
            }
        );
    }

    private _generateWhatsappUrl(otpCode: string | null): string {
        const baseUrl = environment.chatAI.whatsappBaseUrl;
        const name = environment.chatAI.name;
        const defaultMessage = `${this._translateService.instant('common::messages.hello')} ${name}!`;
        const messageWithOtp = `${this._translateService.instant('common::messages.hello')} ${name}, ${this._translateService.instant('auth.msgMyAccessCodeIs')} #${otpCode} !`;

        const message = otpCode === null ? defaultMessage : messageWithOtp;
        const encodedMessage = encodeURIComponent(message);

        return `${baseUrl}?text=${encodedMessage}`;
    }

    private _fetchUserPermission() {
        if (this.currentUser.has('API_USER_PHONE_INTEGRATION')) {
            this.getFeatureFlag();
        }
    }

    async getFeatureFlag(): Promise<void> {
        let enabledChatAI = await this._featureFlagService.isFeatureFlagEnabledV2('profile_enable_ai');
        if (enabledChatAI) {
            this.chatAiPreferences = this._userPreferencesService.defineFilter(this._userCache.getUserPreferences(), 'chatAi', this.chatAiPreferences, null);
            enabledChatAI = !this.chatAiPreferences.rememberClose;
        }
        this.enabledChatAI = enabledChatAI;
        setTimeout(() => {
            this.notification = true
        }, 2000);

    }

    private _verifyPhoneNumber(number) {
        return number !== null && number.length === 11;
    }

    private _updatePhoneIntegration() {
        this.asyncEditPhone = false;
        const uid = this.userService.getCurrentUser().getUid();
        this._authenticationService.updatePhoneIntegration(uid, this.phoneNumber).subscribe(
            (data: any) => {
                this.asyncEditPhone = true;
                this.getPhoneIntegrationData();
            }, (error) => {
                this.asyncEditPhone = true;
                this._utilsService.errorHandler(error, this._translateService.instant('auth.phone'));
            }
        );
    }

    private _createPhoneIntegration() {
        this.asyncEditPhone = false;
        const uid = this.userService.getCurrentUser().getUid();
        this._authenticationService.createPhoneIntegration(uid, this.phoneNumber).subscribe(
            (data: any) => {
                this.asyncEditPhone = true;
                this.getPhoneIntegrationData();
                this.startOtpCodePolling();
            }, (error) => {
                this.asyncEditPhone = true;
                this._utilsService.errorHandler(error, this._translateService.instant('auth.phone'));
            }
        );
    }
}
