<cmx-page-wrapper [titleComponent]="dataTitle" [layout]="'middle'" [helpDescription]="helpDescription" [helpTitle]="dataTitle.title">

  <ng-container cmx-page-wrapper-body>

    <div class="checkmark-container">
      <span id="freight-rate-national-tab" (click)='changeTab(0)' class="checkmark">Nacional</span>
      <span id="freight-rate-international-tab" (click)='changeTab(1)' class="checkmark">Internacional</span>
    </div>

    <div *ngIf="showNationalFreightTab" style="height: 900px;">
      <national-freight-rate></national-freight-rate>
    </div>

    <div *ngIf="!showNationalFreightTab" class="international-height">
      <international-freight-rate></international-freight-rate>
    </div>

  </ng-container>

</cmx-page-wrapper>
