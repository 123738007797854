
import {throwError as observableThrowError,  Observable } from 'rxjs';

import { environment } from "#environment";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ContainerTrackingFilter } from 'app/theme/layouts/charts/container-tracking/models/container-tracking.filter.model';

@Injectable()
export class NewTrafficTraceabilityService {
  constructor(private _http: HttpClient) {}

  public getClientsWithVessels(filterState: ContainerTrackingFilter): Observable<any> {
    const request = filterState.getRequest();
    return this._http.get(`${environment.endpoints.newTrafficTraceability}/tracking-vessel/clients`, request);
  }

  public newGetVessels(filterState: ContainerTrackingFilter): Observable<any> {
    const request = filterState.getRequest();
    return this._http.get(`${environment.endpoints.newTrafficTraceability}/tracking-vessel`, request);
  }
}
