<div class="moveBtn">
    <div class="moveLine"></div>
</div>

<ng-container *ngIf="loading || loadingClientsStatus">
    <loader></loader>
</ng-container>

<div *ngIf="allStatus && !loadingClientsStatus" class="checkmark-container">
    <div
        *ngFor="let status of allStatus; trackBy: identify"
        id="{{ status?.code | lowercase }}"
        (click)="toogleStatus(status)"
        class="checkmark{{ selectedStatusCodes[status.code] ? ' checked' : '' }}"
    >
        <img
            src="assets/img/icons/{{ status?.code | lowercase }}{{ selectedStatusCodes[status.code] ? '-checked' : '' }}.png"
            alt="{{ status?.code }} Icon"
        />
        <span class="tooltip-text">
            {{ status?.name === 'Aguard. Autorização Embarque' ? 'Aguard. Aut. Embarque' : status?.name }}
        </span>
    </div>
    <span (click)="selectAllStatus()" class="selectAll">{{
        allStatus.length !== filterState.status.length ? 'aplicar todos' : ''
    }}</span>
</div>

<div *ngIf="!loadingClientsStatus">
     <div class="title-container">
        <span class="analyticsName">Previsão de Faturamento</span>
        <cmx-help-modal help-title="Previsão de Faturamento" [help-description]="helpDescription"></cmx-help-modal>
        <h5>Previsão de Faturamento</h5>
    </div>

    <div *ngIf="currentUser$ | async as user" id="searchLine" class="reduzed">
        <div class="search-container" *ngIf="user.has('INTERNAL_FILTERS')">
            <div class="input-group">
                <cmx-dropdown
                    [styles]="{width: '220px'}"
                    name="clients"
                    [data]="clientOptions"
                    [ngModel]="filterState.clientGroups"
                    (ngModelChange)="filterState.clientGroups = $event"
                    [settings]="dropdownSettings.clientGroups"
                ></cmx-dropdown>
            </div>
            <button (click)="applyClientsFilter()" class="applyImpStatus">
                <i class="fa fa-check"></i>
            </button>
        </div>
    </div>
</div>

<cmx-custom-grid
    *ngIf="!loading && data?.length"
    [loading]="loading"
    [columns]="gridColumns"
    [rows]="gridRows"
    [options]="{ forceFitColumns: false, frozenColumn: 0 }"
    [styles]="{ 'margin-bottom': '5px', height: '400px' }"
></cmx-custom-grid>

<div class="info" *ngIf="!loading && !data?.length">
    <i class="fa fa-info-circle"></i>
    <span>Dados não encontrados, selecione pelo menos um status.</span>
</div>
