import { SlicePipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import moment from 'moment';
import { forkJoin } from 'rxjs';

import { UtilsService } from '#services/_utils/utils.service';
import { DomainService } from '#services/_domain/domain.service';
import { CommissionService } from '#services/_commision/commision.service';
import { NotificationService } from '#services/_notification/notification.service';
import { UserService } from 'app/auth/_services';
import { AdmPanelService } from '../adm-panel.service';
import { CmxDropdownService } from 'app/shared/components/cmx-dropdown/cmx-dropdown.service';
import { CustomDialogService } from 'app/shared/components/cmx-custom-dialog/custom-dialog.service';

import { Client } from './client';
import { ClientGroup } from './clientGroup';
import { CmxMaskPipe } from 'app/shared/pipes/mask.pipe';
import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { AdmDomainClientGroupCrud } from './adm-domain-client-group.crud';
import { DROPDOWN_SETTINGS } from 'app/shared/constants/dropdown-settings.constants';
import { IGridColumn, IGridRow } from 'app/shared/components/cmx-custom-grid/interfaces';
import { COMMISSION_LOG_COLUMNS } from './constants/commission-log-columns.constant';
import { CmxImgUploadComponent } from 'app/shared/components/cmx-img-upload/img-upload.component';

@Component({
    selector: 'adm-domain-client-group',
    templateUrl: './adm-domain-client-group.component.html',
    styleUrls: ['./adm-domain-client-group.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AdmClientGroupComponent implements AfterViewInit {

    @ViewChild('loginBackgroundUpload')
    private loginBackgroundUpload!: CmxImgUploadComponent;
    @ViewChild('logoUpload')
    private logoUpload!: CmxImgUploadComponent;

    @Output()
    public onClose = new EventEmitter<any>();
    public currentUser$ = this.userService.getCurrentUser();

    dropdownSettings = _.cloneDeep(DROPDOWN_SETTINGS);

    public readonly dropdownSettingsGroups = {
        singleSelection: true,
        text: 'Atribuir Grupo',
    };
    public readonly dropdownSettingsTraders = {
        singleSelection: true,
        text: 'Atribuir Trader',
    };

    public dropdowns: {
        groups: {
            options: DropdownOption[];
            selected: DropdownOption[];
        };
        traders: {
            options: DropdownOption[];
            selected: DropdownOption[];
        };
        leasingTraders: {
            options: DropdownOption[];
            selected: DropdownOption[];
        };
        clients: {
            options: DropdownOption[];
            selected: DropdownOption[];
        };
    } = {
        groups: {
            options: [],
            selected: [],
        },
        traders: {
            options: [],
            selected: [],
        },
        leasingTraders: {
            options: [],
            selected: [],
        },
        clients: {
            options: [],
            selected: [],
        },
    };
    public allTraders = [];
    private allClientGroups: any;

    public queryId = Math.random();
    public moment = moment;
    public changedDates = 0;
    public startDate;

    public validDate = true;
    public selectedDocument = 'cnpj';

    public index = 0;
    public clientDomain = true;
    public newDomain = true;
    public hasAnyChange = false;
    public asyncClientGroups = true;
    public asyncLeasingImps = true;
    public asyncInit = false;
    public asyncLog = true;

    public form: FormGroup;
    public formDates = null;
    public hasReply = false;
    public clearDates = false;
    public showCommissionModal = false;
    public showCommissionAlertModal = false;
    public commissionAlert = 'Comissão com Fim da Vigência já cadastrada e será alterada ao prosseguir.';
    public logColumns: IGridColumn[] = _.cloneDeep(COMMISSION_LOG_COLUMNS);
    public logRows: IGridRow = [];
    public lastCommissionStartDate: any;

    public clients: Client[] = [Client.blank()];
    public groups: ClientGroup[] = [ClientGroup.blank()];
    public clientsInGroup = [];
    public selectedClient: Client = this.clients[0].clone();
    public selectedGroup: ClientGroup = this.groups[0].clone();
    public auxSelectedGroup: ClientGroup = this.groups[0].clone();
    public selectedLeasingImp = {
        imp: '',
        leaseHolderName: '',
        leaseHolderTraderCode: '',
        leaseHolderTraderName: '',
        couponCustomerCode: '',
        clientGroupCode: '',
        directorCode: '',
        traderUserBiCode: '',
    };
    public leasingImps;
    public selectedLeasingDirector;
    public effectiveLeasingClient = false;

    public clientPage = 0;
    public groupPage = 0;
    public clientPages = [];
    public groupPages = [];
    public asyncClientPages = false;
    public asyncGroupPages = false;
    public searchedClient: string = '';
    public searchedClientGroup: string = '';

    public modal = 'domainClientGroupModal';
    private readonly CPF_SIZE: number = 14;
    private readonly CNPJ_SIZE: number = 18;
    private readonly MORE_CONTENT: string = '...';
    private readonly TRUNC_CLIENT_NAME_SIZE = 33;
    private scrollToggle = false;

    private domainRegex = new RegExp("^[a-zA-Z0-9-]+$");


    loginBackground = null;
    selectecItem;
    logo = null
    imagesPreview = {
        logo: null,
        loginBackground: null,
    }

    preview = {
        logo: null,
        loginBackground: null,
    }

    constructor(
        public crud: AdmDomainClientGroupCrud,
        private cmxMaskPipe: CmxMaskPipe,
        private slicePipe: SlicePipe,
        private fb: FormBuilder,
        public admService: AdmPanelService,
        private domainService: DomainService,
        private userService: UserService,
        private _cmxDropdownService: CmxDropdownService,
        private _customDialogService: CustomDialogService,
        private _commissionService: CommissionService,
        private _utilsService: UtilsService,
        private _notificationService: NotificationService
    ) { }

    public ngAfterViewInit() {
        this.setDatePickers();
        this.updateDateInputs();
        this.createForm();
        forkJoin([
            this.domainService.getClientGroupPage(100, this.groupPage),
            this.domainService.getClientPage(100, this.clientPage),
            this.domainService.getTrader(),
            this.domainService.getAllClientGroup(),
        ]).subscribe((values) => {
            const [clientGroups, clients, traders, clientGroupsDropdown] = values;
            this.allTraders = traders;
            this.allClientGroups = clientGroupsDropdown;
            this.updateGroupValues(clientGroups, clientGroupsDropdown);
            this.updateClientValues(clients);
            this.dropdowns.traders.options = this._cmxDropdownService.toDropdownItems(traders);
            this.dropdowns.leasingTraders.options = this._cmxDropdownService.toDropdownItems(traders);
            this.asyncInit = true;
        });
        $(document).click((e) => {
            if (!$(e.target).hasClass('selector')) {
                $('.options').attr('class', 'options hidden');
            }
        });
    }

    public isTruncatedClientName(clientName: string) {
        return clientName.length === this.TRUNC_CLIENT_NAME_SIZE;
    }

    public getDocumentNumberMaxLength() {
        return this.selectedDocument === 'cpf' ? this.CPF_SIZE : this.CNPJ_SIZE;
    }

    public isVisibleImps() {
        return this.effectiveLeasingClient && !this.newDomain;
    }

    public selectDocumentNumberType() {
        this.selectedClient.documentNumber = '';
        this.identifyChange('client');
    }

    public toogleLeasing() {
        setTimeout(() => {
            this.identifyChange('client');
        }, 100);
    }

    public maskDocumentNumber() {
        this.selectedClient.documentNumber = this.cmxMaskPipe.transform(
            this.selectedClient.documentNumber,
            this.selectedDocument
        );
        this.identifyChange('client');
    }

    public filterClientByName(filter) {
        this.asyncClientGroups = false;
        this.setClientInvisible();
        let nameParam = {
            clientName: filter,
        };

        this.domainService.getClientPage(100, this.clientPage, nameParam).subscribe((clients) => {
            this.setClientVisible();
            this.updateClientValues(clients);
            this.asyncClientGroups = true;
        });
    }

    public filterClientGroupByName(filter) {
        this.asyncClientGroups = false;
        this.setGroupInvisible();
        let nameParam = {
            clientGroupName: filter,
        };

        forkJoin([this.domainService.getClientGroupPage(100, this.groupPage, nameParam)]).subscribe((values) => {
            this.setGroupVisible();
            const [clientGroups] = values;
            this.updateGroupValuesFromSearch(clientGroups);
            this.asyncClientGroups = true;
        });
    }

    private updateGroupValuesFromSearch(groups) {
        this.groups = groups.content.map((group) => new ClientGroup(group));
        this.determinePages('group', groups);
        this.clearUserForm('group');
    }

    public openLeasingImpsModal() {
        this.asyncLeasingImps = false;
        $('.leasingImpsModal').removeClass('hidden');
        $('.leasingBackground').removeClass('hidden');
        this.crud.getLeasingImps(this.impsRequest()).subscribe((imps) => {
            this.leasingImps = imps;
            this.asyncLeasingImps = true;
            this.selectLeasingImp(imps[0], 'firstOpen', 0);
        });
    }
    public closeLeasingImpsModal() {
        $('.leasingImpsModal').addClass('hidden');
        $('.leasingBackground').addClass('hidden');
    }

    public selectLeasingImp(imp, element, index) {
        if (element !== 'firstOpen') {
            $('.leasingImpsContent tr').removeClass('selected');
            const tr = element.target.parentElement;
            $(tr).addClass('selected');
        } else {
            $('.leasingImpsContent tr').addClass('selected');
        }
        this.selectedLeasingImp = _.cloneDeep(imp);
        const leasingTrader = [
            {
                code: imp.leaseHolderTraderCode,
                name: imp.leaseHolderTraderName,
            },
        ];
        if (leasingTrader[0].code !== null && leasingTrader[0].code !== '') {
            this.dropdowns.leasingTraders.selected = this._cmxDropdownService.toDropdownItems(leasingTrader);
            this.selectLeasingTrader();
        } else {
            this.dropdowns.leasingTraders.selected = [];
            this.selectedLeasingDirector = '';
        }
    }

    public selectLeasingTrader() {
        if (this.dropdowns.leasingTraders.selected.length === 0) {
            this.selectedLeasingDirector = '';
            this.selectedLeasingImp.leaseHolderTraderCode = '';
            this.selectedLeasingImp.leaseHolderTraderName = '';
        } else {
            this.allTraders.forEach((trader) => {
                if (this.dropdowns.leasingTraders.selected[0].id === trader.code) {
                    this.selectedLeasingDirector = trader.director;
                    this.selectedLeasingImp.leaseHolderTraderCode = trader.code;
                    this.selectedLeasingImp.leaseHolderTraderName = trader.name;
                }
            });
        }
    }

    public saveLeaseHolder() {
        if (
            this.selectedLeasingImp.leaseHolderName === '' ||
            this.selectedLeasingImp.leaseHolderName === null ||
            this.selectedLeasingImp.leaseHolderName === undefined
        ) {
            this.callError('leaseHolder');
        } else if (
            this.selectedLeasingImp.leaseHolderTraderCode === '' ||
            this.selectedLeasingImp.leaseHolderTraderCode === null ||
            this.selectedLeasingImp.leaseHolderTraderCode === undefined
        ) {
            this.callError('leaseTrader');
        } else {
            this.asyncLeasingImps = false;
            this.crud.editLeaseHolder(this.leaseHolderRequest()).subscribe(
                (success) => {
                    this.crud.getLeasingImps(this.impsRequest()).subscribe((imps) => {
                        this.leasingImps = imps;
                        this.asyncLeasingImps = true;
                        this.callSuccess('leaseHolder');
                    });
                },
                (error) => {
                    this.asyncLeasingImps = true;
                    this.cleanLeasingState();
                    this.callError('err');
                }
            );
        }
    }

    public changePage(domain, pageNumber) {
        if (domain === 'client') {
            this.setClientInvisible();
            this.clientPage = pageNumber - 1;
            this.domainService.getClientPage(100, this.clientPage).subscribe((clients) => {
                this.updateClientValues(clients);
                this.setClientVisible();
            });
        } else {
            this.setGroupInvisible();
            this.groupPage = pageNumber - 1;
            forkJoin([
                this.domainService.getClientGroupPage(100, this.groupPage),
                this.domainService.getAllClientGroup(),
            ]).subscribe((values) => {
                const [clientGroups, clientGroupsDropdown] = values;
                this.updateGroupValues(clientGroups, clientGroupsDropdown);
                this.setGroupVisible();
            });
        }
    }

    public createDomain() {
        this.asyncClientGroups = false;
        this.admService.resetPage(this.modal);
        if (this.clientDomain) {
            if (this.selectedClient.name === null || this.selectedClient.name === '') {
                this.callError('name');
            } else if (this.selectedClient.code === null || this.selectedClient.code === '') {
                this.callError('code');
            } else if (this.selectedDocument !== 'inter' && this.selectedClient.documentNumber === null) {
                this.callError('document');
            } else if (this.dropdowns.groups.selected[0] === null || this.dropdowns.groups.selected[0] === undefined) {
                this.callError('group');
            } else {
                this.crud.createClient(this.selectedClient, this.dropdowns.groups.selected[0]).subscribe(
                    (response) => {
                        this.recallValues('create');
                    },
                    (error) => {
                        if (error.status === 304) {
                            this.callError('clientCode');
                        } else if (error.status === 400) {
                            this.callError('clientName');
                        } else {
                            this.callError('err');
                        }
                    }
                );
            }
        } else {
            if (this.selectedGroup.name === null || this.selectedGroup.name === '') {
                this.callError('name');
            } else {
                let clientGroup = this._clientGroupRequest()
                this.crud.createGroup(clientGroup, this.dropdowns.traders.selected[0], this.logo, this.loginBackground).subscribe(
                    (response) => {
                        this.recallValues('create');
                    },
                    (error) => {
                        if (error?.error?.ApiError?.message) {
                            this._notificationService.openNotification('error', error?.error?.ApiError?.message, 'Falha ao adicionar Grupo');
                            this.asyncClientGroups = true;
                            return;
                        }
                        if (error.status === 304) {
                            this.callError('clientCode');
                        } else if (error.status === 400) {
                            this.callError('groupName');
                        } else {
                            this.callError('err');
                        }
                    }
                );
            }
        }
    }

    public editDomain() {
        this.asyncClientGroups = false;
        if (this.clientDomain) {
            if (this.selectedClient.name === null || this.selectedClient.name === '') {
                this.callError('name');
            } else if (this.dropdowns.groups.selected[0] === null || this.dropdowns.groups.selected[0] === undefined) {
                this.callError('group');
            } else {
                this.crud.editClient(this.selectedClient, this.dropdowns.groups.selected[0]).subscribe(
                    (response) => {
                        this.recallValues('edit');
                    },
                    (error) => {
                        this.callError('err');
                        this.asyncClientGroups = true;
                    }
                );
            }
        } else {
            if (this.selectedGroup.name === null || this.selectedGroup.name === '') {
                this.callError('name');
            } else if (
                this.startDate === 'Invalid date' ||
                this.startDate === 'Data Inválida' ||
                this.startDate === null ||
                this.startDate === ''
            ) {
                this.callError('start');
            } else {

                var clientGroup = this._clientGroupRequest();
                this.crud.updateGroup(this.auxSelectedGroup.code, clientGroup, this.dropdowns.traders.selected[0], this.logo, this.loginBackground)
                    .subscribe(
                        (response) => {
                            this.recallValues('edit');
                        },
                        (error) => {
                            if (error?.error?.ApiError?.message) {
                                this._notificationService.openNotification('error', error?.error?.ApiError?.message, 'Falha ao salvar Grupo');
                                this.asyncClientGroups = true;
                                return;
                            }

                            if (error.status === 304) {
                                this.callError('clientCode');
                            } else if (error.status === 400) {
                                this.callError('groupName');
                            } else {
                                this.callError('err');
                            }
                        }
                    );
            }
        }
    }

    public confirmDelete() {
        this.asyncClientGroups = false;
        this.admService.resetPage(this.modal);
        if (this.clientDomain) {
            this.crud.deleteClient(this.selectedClient).subscribe((response) => {
                this.domainService.getClientPage(100, this.clientPage).subscribe(
                    (clients) => {
                        this.recallValues('delete');
                    },
                    (error) => {
                        this.callError('err');
                    }
                );
            });
        } else {
            this.crud.deleteGroup(this.selectedGroup).subscribe(
                (response) => {
                    this.recallValues('delete');
                },
                (error) => {
                    if (error.status === 412) {
                        this.callError('groupWithClient');
                    } else {
                        this.callError('err');
                    }
                    this.asyncClientGroups = true;
                }
            );
        }
    }

    selectPermissionClient(item) {
        this.hasAnyChange = false;
        this.selectedClient = item.clone();
        this.admService.resetPage(this.modal);
        this.changedDates = 0;
        this.newDomain = false;
        this.selectecItem = item;
        this.clientDomain = true;
        this.effectiveLeasingClient = this.selectedClient.leasing;
        this.dropdowns.groups.selected = this.dropdowns.groups.options.filter(
            (d) => this.selectedClient.clientGroup.code === d.id
        );
        this.selectedClient.documentNumber = this.cmxMaskPipe.transform(
            this.selectedClient.documentNumber,
            this.selectedDocument
        );
        setTimeout(() => {
            this.updateDateInputs();
        }, 200);
    }

    selectPermissionGroup (item) {
        this.selectecItem = item;
        this.admService.resetPage(this.modal);
        this.changedDates = 0;
        this.newDomain = false;
        this.clientDomain = false;
        this.selectedGroup = this._mapClientGroup(item.clone());
        this.auxSelectedGroup = item.clone();
        this.clientsInGroup = this.selectedGroup.clients;
        this.startDate = moment(this.selectedGroup.trader.dtValidityIni, 'YYYY/MM/DD').format('DD/MM/YYYY');
        this.updateImagesPreview(this.selectedGroup.domain);
        setTimeout(() => {
            this.updateDateInputs();
        }, 200);
    }

    public clearUserForm(domain) {
        this.newDomain = true;
        domain === 'client' ? (this.clientDomain = true) : (this.clientDomain = false);
        this.selectedClient = Client.blank();
        this.selectedGroup = ClientGroup.blank();
        this.dropdowns.groups.selected = [];
        this.dropdowns.traders.selected = [];
        this.updateImagesPreview()
        this.startDate = '';
        setTimeout(() => {
            this.updateDateInputs();
        }, 200);
        $('tr').removeClass('selected');
        this.asyncClientGroups = true;
    }

    public scrollList(e) {
        if (
            (e.direction === 'up' && e.scrollTop !== 0) ||
            (e.direction === 'down' && e.scrollTop + 20 < e.scrollHeight) ||
            Math.abs(e.previousScrollTop - e.scrollTop) > 30
        ) {
            this.scrollToggle = !this.scrollToggle;
        }
        if (this.scrollToggle) {
            let delta;
            if (e.direction === 'up') {
                delta = 20;
            } else {
                delta = -20;
            }
            $('.c-list').scrollTop(e.scrollTop + delta);
        }
    }

    public close() {
        this.onClose.emit(true);
    }

    public identifyChange(domain) {
        if (!this.newDomain) {
            if (domain === 'client') {
                const original = _.cloneDeep(this.clients[this.selectecItem]);
                this.applyDropDownChanges(domain);
                this.hasAnyChange = !original.equals(this.selectedClient);
            } else {
                const original = _.cloneDeep(this.groups[this.selectecItem]);
                this.applyDropDownChanges(domain);
                this.hasAnyChange = !original.equals(this.selectedGroup);
            }
        }
    }


    public domainChangeKeyPress(e) {
        var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (this.domainRegex.test(str)) {
            return true;
        }
        e.preventDefault();
        return false;
    }

    public domainChange($event) {
        var client = this._getClient($event.toLowerCase())
        this.selectedGroup.domain = client;
    }

    public updateImagesPreview($event?) {
        let client = this._getClient($event);
        if (client) {
            this.crud.loadImage(`assets/img/logos/logo-${client}.png?preventcache=${Math.random()}`).subscribe(res => {
                let file = res;
                file['name'] = `logo-${client}.png`;
                this.imagesPreview.logo =  file;
            }, error => this.logoUpload?.clenupAttach());
            this.crud.loadImage(`assets/img/bg/bg-${client}.jpg?preventcache=${Math.random()}`).subscribe(res => {
                let file = res;
                file['name'] = `bg-${client}.jpg`;
                this.imagesPreview.loginBackground =  file;
            }, error => this.loginBackgroundUpload?.clenupAttach());
        } else {
            this.imagesPreview.logo = null;
            this.imagesPreview.loginBackground = null;
            this.logoUpload?.clenupAttach();
            this.loginBackgroundUpload?.clenupAttach();
        }
    }

    public updatePreviewLogo($file) {
        if ($file) {
            const preview = new FileReader();
            preview.readAsDataURL($file);
            preview.onload = () => {
                this.preview.logo = preview.result;
            };
        } else {
            this.preview.logo = null;
        }
    }

    public updatePreviewBackground($file) {
        if ($file) {
            const preview = new FileReader();
            preview.readAsDataURL($file);
            preview.onload = () => {
                this.preview.loginBackground = preview.result;
            };
        } else {
            this.preview.loginBackground = null;
        }
    }



    public openPreview(): void {
        if (this.preview.loginBackground && this.preview.loginBackground) {
            this._customDialogService.open('adm-domain-client-group-dialog-preview');
        } else {
            this._notificationService.openSmallNotification('info', "Preencha o domínio e selecione todas as imagens.");
            setTimeout(() => {
                this._notificationService.closeSmallNotification();
            }, 2000);
        }
    }

    public closePreview(): void {
        this._customDialogService.close('adm-domain-client-group-dialog-preview');
    }

    private _mapClientGroup(clientGroup: any) {
        var selected = _.cloneDeep(clientGroup);
        selected.domain = this._getClient(selected.domain) || '';
        return selected;
    }

    private _getClient(domain) {
        if (domain && domain.indexOf('.') > -1) {
            var startIndex = 0
            if (domain.indexOf('//') > -1) {
                startIndex = domain.indexOf('//') + 2;
            }
            return domain.slice(startIndex, domain.indexOf('.'));
        }
        return domain;
    }

    private _clientGroupRequest(): any {
        var clientGroup = _.cloneDeep(this.selectedGroup);
        clientGroup.domain = this.selectedGroup.domain && this.selectedGroup.domain !== '' ? `${this.selectedGroup.domain}.comexport.com.br` : null;
        return clientGroup;
    }

    private applyDropDownChanges(domain) {
        if (domain === 'client') {
            if (this.dropdowns.groups.selected.length > 0) {
                this.selectedClient.clientGroup.code = this.dropdowns.groups.selected[0].id;
                this.selectedClient.clientGroup.name = this.dropdowns.groups.selected[0].itemName;
            } else {
                this.selectedClient.clientGroup.code = null;
                this.selectedClient.clientGroup.name = null;
            }
        } else {
            if (this.dropdowns.traders.selected.length > 0) {
                this.form.get('traderCode').setValue(this.dropdowns.traders.selected[0].id);
            } else {
                this.form.get('traderCode').setValue(null);
            }
        }
    }

    private updateDateInputs() {
        this.setDatePickers();
        if (this.startDate === null || this.startDate === '' || this.startDate === undefined) {
            $('#startDate').datepicker('setDate', '');
        } else {
            $('#startDate').datepicker('setDate', this.startDate);
        }
    }

    private setDatePickers() {
        $('#startDate').datepicker({
            format: 'dd/mm/yyyy',
            language: 'pt-BR',
            endDate: '+0d',
            updateViewDate: false,
            autoclose: true,
        });

        $('#startDate')
            .datepicker()
            .on('changeDate', (e, options) => {
                this.startDate = $('#startDate').val();
                if (this.changedDates > 0) {
                    this.identifyChange('group');
                }
                if (
                    $('#startDate').val() === '' ||
                    $('#startDate').val() === null ||
                    $('#startDate').val() === undefined
                ) {
                    this.validDate = false;
                }
                this.changedDates++;
            });
    }

    private cleanLeasingState() {
        this.dropdowns.leasingTraders.selected = [];
        this.selectedLeasingDirector = '';
        this.selectedLeasingImp.leaseHolderTraderCode = '';
        this.selectedLeasingImp.leaseHolderTraderName = '';
        this.selectedLeasingImp.leaseHolderName = '';
    }

    private callError(type) {
        this.admService.showError(this.modal, type);
        this.asyncClientGroups = true;
    }

    private callSuccess(type) {
        this.admService.showSuccess(this.modal, type);
        this.asyncClientGroups = true;
    }

    private determinePages(domain, data) {
        if (domain === 'client') {
            this.clientPages = Array.apply(null, { length: data.totalPages }).map(Number.call, Number);
        } else {
            this.groupPages = Array.apply(null, { length: data.totalPages }).map(Number.call, Number);
        }
    }

    private formatClientsNames(clients: any) {
        _.forEach(clients, (c) => (c.name = this.slicePipe.transform(c.name, 0, this.TRUNC_CLIENT_NAME_SIZE)));
    }

    private updateClientValues(clients) {
        this.clients = clients.content && clients.content.map((client) => new Client(client));
        this.formatClientsNames(this.clients);
        this.determinePages('client', clients);
        this.clearUserForm('client');
    }

    private updateGroupValues(groups, dropdownGroups) {
        this.allClientGroups = _.cloneDeep(dropdownGroups);
        this.groups = groups.content.map((group) => new ClientGroup(group));
        this.dropdowns.groups.options = this._cmxDropdownService.toDropdownItems(dropdownGroups);
        this.dropdowns.clients.options = this._cmxDropdownService.toDropdownItems(dropdownGroups);
        this.determinePages('group', groups);
        this.clearUserForm('group');
    }

    private recallValues(verb) {
        forkJoin([
            this.domainService.getClientGroupPage(100, this.groupPage),
            this.domainService.getClientPage(100, this.clientPage),
            this.domainService.getTrader(),
            this.domainService.getAllClientGroup(),
        ]).subscribe((values) => {
            const [clientGroups, clients, traders, clientGroupsDropdown] = values;
            this.updateGroupValues(clientGroups, clientGroupsDropdown);
            this.updateClientValues(clients);
            this.dropdowns.traders.options = this._cmxDropdownService.toDropdownItems(traders);
            this.callSuccess(verb);
        });
    }

    private impsRequest() {
        let params = new HttpParams();
        params = params.append('couponCustomerCode', `${this.selectedClient.code}`);
        return params;
    }

    private leaseHolderRequest() {
        const data = {
            updateFilters: [
                {
                    leaseHolderImp: `${this.selectedLeasingImp.imp}`,
                    leaseHolderName: `${this.selectedLeasingImp.leaseHolderName}`,
                    leaseHolderDirectorCode: `${this.selectedLeasingDirector.code}`,
                    leaseHolderDirectorName: `${this.selectedLeasingDirector.name}`,
                    leaseHolderTraderCode: `${this.selectedLeasingImp.leaseHolderTraderCode}`,
                    leaseHolderTraderName: `${this.selectedLeasingImp.leaseHolderTraderName}`,
                    leaseHolderCustomerCode: `${this.selectedLeasingImp.couponCustomerCode}`,
                    leaseHolderClientGroupCode: `${this.selectedLeasingImp.clientGroupCode}`,
                    directorCode: `${this.selectedLeasingImp.directorCode}`,
                    traderUserBiCode: `${this.selectedLeasingImp.traderUserBiCode}`,
                },
            ],
        };
        return data;
    }

    private setClientInvisible() {
        this.asyncClientPages = true;
        $('#clientTable').addClass('hidden');
    }

    private setGroupInvisible() {
        this.asyncGroupPages = true;
        $('#groupTable').addClass('hidden');
    }

    private setClientVisible() {
        this.asyncClientPages = false;
        $('#clientTable').removeClass('hidden');
    }

    private setGroupVisible() {
        this.asyncGroupPages = false;
        $('#groupTable').removeClass('hidden');
    }

    trackByFn(index: number, item: any) {
        return index;
    }

    public async getLastCommission() {
        await this._commissionService.getLastCommission(this.selectedGroup.code).then(
            (res) => {
                this.lastCommissionStartDate = moment(res.startDate, 'YYYY-MM').format('MM/YYYY');
            },
            (error) => {
                this.lastCommissionStartDate = null;
                this._utilsService.errorHandler(error, 'Comissão Trader');
            }
        );
    }

    public getCommissionlog() {
        this.asyncLog = true;
        this._commissionService.getClientHistory(this.selectedGroup.code).subscribe(
            (res) => {
                this.logRows = res.content;
                this.logRows.forEach(row => {
                    this.allTraders.some((trader) => {
                        if (trader.code == row.traderCode) {
                            row.traderName = trader.name;
                            return true;
                        }
                    });
                });
                this.asyncLog = false;
            },
            (error) => {
                this.asyncLog = false;
                this._utilsService.errorHandler(error, 'Histórico de Comissões');
            }
        );
    }

    public dateChanged(dates, type) {
        if (type == 'start') {
            this.form.get('startDate').setValue(dates);
        } else {
            this.form.get('endDate').setValue(dates);
        }
    }

    public async createForm() {
        this.form = this.fb.group({
            traderCode: [null, [Validators.required]],
            value: [2, [Validators.required]],
            startDate: [null, [Validators.required, Validators.minLength(7), Validators.maxLength(7)]],
            endDate: [null],
            scopes: [null],
        });
    }

    public verifyBtnReply() {
        if (this.form.valid && this.dropdowns.clients.selected.length > 0) {
            return false;
        } else {
            return true;
        }
    }

    public verifyBtnSave() {
        if (this.form.valid && this.dropdowns.clients.selected.length == 0) {
            let startDateYear = moment(this.form.get('startDate').value, 'MM/YYYY').format('YYYY');
            let endDateYear = moment(this.form.get('endDate').value, 'MM/YYYY').format('YYYY');

            let startDateMonth = moment(this.form.get('startDate').value, 'MM/YYYY').format('MM');
            let endDateMonth = moment(this.form.get('endDate').value, 'MM/YYYY').format('MM');

            if (endDateYear != null && endDateYear != 'Data inválida') {
                if (startDateYear > endDateYear) {
                    return true;
                } else if (startDateYear == endDateYear && startDateMonth > endDateMonth) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    public verifyFormComission(reply?) {
        this.hasReply = reply;

        if (this.form.valid) {
            if (this.logRows.length) {
                const formStartDate = parseInt(moment(this.form.get('startDate').value, 'MM/YYYY').format('YYYYMM'));
                let alert = false;
                this.logRows.forEach((row) => {
                    const rowStartDate = parseInt(moment(row.startDate, 'YYYY-MM').format('YYYYMM'));
                    if (formStartDate > rowStartDate) {
                        alert = true;
                    }
                })
                if (alert) {
                    if (!this.form.get('endDate').value) {
                        this.commissionAlert =
                            'Comissão com Início da Vigência já cadastrada e sem Fim de Vigência selecionado, será alterada ao prosseguir.';
                        this.showCommissionAlertModal = true;
                    } else {
                        this.commissionAlert =
                            'Comissão com Início da Vigência já cadastrada e será alterada ao prosseguir.';
                        this.showCommissionAlertModal = true;
                    }
                } else {
                    if (!this.form.get('endDate').value) {
                        this.commissionAlert =
                            'Comissão com Início da Vigência já cadastrada e sem Fim de Vigência selecionado, será alterada ao prosseguir.';
                        this.showCommissionAlertModal = true;
                    } else {
                        this.saveCommission();
                    }
                }
            } else {
                if (!this.form.get('endDate').value) {
                    this.commissionAlert = 'Comissão sem Fim da Vigência selecionado e será alterada ao prosseguir.';
                    this.showCommissionAlertModal = true;
                } else {
                    this.saveCommission();
                }
            }
        }
    }

    public saveCommission() {
        if (this.form.valid) {
            this.showCommissionAlertModal = false;
            let scopes = [];
            let traderCode = this.form.get('traderCode').value;

            this.allClientGroups?.some((group) => {
                if (group.code === this.selectedGroup.code) {
                    scopes.push({ clientGroup: { code: group.code, name: group.name }, traderCode: traderCode });
                    return true;
                }
            });

            if (this.hasReply) {
                this.dropdowns.clients.selected?.forEach((c) => {
                    this.allClientGroups?.some((group) => {
                        if (group.code === c.id) {
                            scopes.push({ clientGroup: { code: group.code, name: group.name }, traderCode: traderCode });
                            return true;
                        }
                    });
                });
            }

            this.form.get('scopes').setValue(scopes);
            const params = this.form.getRawValue();
            delete params.traderCode;
            params.startDate = moment(this.form.get('startDate').value, 'MM/YYYY').format('YYYY-MM');
            if (this.form.get('endDate').value) {
                params.endDate = moment(this.form.get('endDate').value, 'MM/YYYY').format('YYYY-MM');
            }

            this._commissionService.saveCommission(params).subscribe(
                (res) => {
                    this.setLogModalDefault();
                    this.getCommissionlog();
                    this.dropdowns.traders.selected = [];
                    this._notificationService.openNotification(
                        'success',
                        'Comissão salva com sucesso!',
                        'Comissão Trader'
                    );
                },
                (error) => {
                    this._utilsService.errorHandler(error, 'Salvar Comissão');
                }
            );
        }
    }

    public setLogModalDefault() {
        this.clearDates = true;
        this.dropdowns.clients.selected = [];
        this.hasReply = false;
        this.form.reset();
        this.form.get('value').setValue(2);
        setTimeout(() => {
            this.clearDates = false;
        }, 300);
    }

    public async openLogModal() {
        this.getCommissionlog();
        this.getLastCommission();
        this.showCommissionModal = true;
        this._customDialogService.open('adm-domain-client-group-dialog-log');
    }

    public closeLogModal() {
        this.asyncLog = true;
        this.lastCommissionStartDate = null;
        this.showCommissionModal = false;
        this._notificationService.closeNotification();
        this.setLogModalDefault();
    }
}
