import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { IFilterStructure } from './cmx-filter-line-structure.interface';

@Component({
    selector: 'cmx-filter-line',
    templateUrl: './cmx-filter-line.component.html',
    styleUrls: ['./cmx-filter-line.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class FilterLineComponent {

    @Input() filterStructure: IFilterStructure[] = [];
    @Input() leftMargin: string = '20px'
    @Output('onFilterChanges') onFilterChanges = new EventEmitter();
    @Output('onApplyFilters') onApplyFilters = new EventEmitter();

    getComponentWidth(width, type) {
        return type === 'switch' || type === 'timer' || type === 'button' || type === 'datepicker' ? 
            'fit-content' : width + 'px';
    }

    filterChanged(element, type: string, code: string, component?) {
        if (type === 'button') { component.clicked = !component.clicked }
        const value = type === 'button' ? component.clicked : type === 'search' ? element.target.value : element;
        this.onFilterChanges.emit({ value, type, code });
    }

    applyFilters() {
        this.onApplyFilters.emit();
    }

}