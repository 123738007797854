import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5locales_pt_BR from "@amcharts/amcharts5/locales/pt_BR";

export function createXYChart(root, chartConfig, data) {

    root.locale = am5locales_pt_BR;

    const chart = root.container.children.push(
        am5xy.XYChart.new(root, {
            layout: root.verticalLayout,
            panX: true,
            panY: true,
            wheelX: "panX",
            wheelY: "zoomX",
        })
    );

    if (chartConfig.title) {
        chart.children.unshift(
            am5.Label.new(root, {
                text: chartConfig.title.text,
                fontSize: chartConfig.title.size || 12,
                fontWeight: "bold",
                x: am5.p50,
                centerX: am5.p50,
                paddingBottom: 10,
                paddingTop: 10
            })
        );
    }

    const showTooltip = chartConfig.showTooltip !== undefined ? chartConfig.showTooltip : true;
    const showValuesAboveBars = chartConfig.showValuesAboveBars !== undefined ? chartConfig.showValuesAboveBars : false;

    let xAxis, yAxis;

    if (chartConfig.xAxisType === 'date') {
        xAxis = chart.xAxes.push(
            am5xy.DateAxis.new(root, {
                maxDeviation: 0.1,
                baseInterval: { timeUnit: "day", count: 1 },
                renderer: am5xy.AxisRendererX.new(root, {
                    minGridDistance: 30,
                }),
                tooltip: showTooltip ? am5.Tooltip.new(root, {}) : undefined,
            })
        );
    } else {
        xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
                renderer: am5xy.AxisRendererX.new(root, { minGridDistance: 20 }),
                categoryField: chartConfig.axis.find(axis => axis.axisPosition === 'x').categoryField,
                tooltip: showTooltip ? am5.Tooltip.new(root, {}) : undefined,
            })
        );

        xAxis.data.setAll(data);
    }

    yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, { minGridDistance: 30 }),
        })
    );

    chartConfig.content.forEach((seriesConfig) => {
        const series = chart.series.push(
            am5xy.ColumnSeries.new(root, {
                name: seriesConfig.name,
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: seriesConfig.valueYField,
                categoryXField: chartConfig.xAxisType === 'date' ? null : seriesConfig.valueXField,
                valueXField: chartConfig.xAxisType === 'date' ? seriesConfig.valueXField : null,
                tooltip: showTooltip ? am5.Tooltip.new(root, { labelText: "{valueY}" }) : undefined,
            })
        );

        series.data.setAll(data);

        if (showValuesAboveBars) {
            series.bullets.push(() => {
                return am5.Bullet.new(root, {
                    sprite: am5.Label.new(root, {
                        text: "{valueY}",
                        centerY: am5.p50,
                        centerX: am5.p50,
                        populateText: true,
                        dy: series.get("yField") ? -80 : 0,
                        fontSize: 12,
                        fill: am5.color(0x000000),
                    })
                });
            });
        }
    });

    const cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
        behavior: "zoomX",
    }));

    if (!showTooltip) {
        root._logo.dispose();
        cursor.lineX.set("visible", false);
        cursor.lineY.set("visible", false);
        cursor.get("behavior")?.set("enabled", false);
    }

    return chart;
}
