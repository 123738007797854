import { AppModule } from 'app/app.module';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';

import moment from 'moment';
import * as _ from 'lodash';
import { IPaymentRequestManualFilter } from '../interfaces/payment-request.interface';

export class PaymentRequestManualFilter {
    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);

    private _initialPaymentRequestManualFilter: IPaymentRequestManualFilter = <IPaymentRequestManualFilter>{};
    private _paymentRequestManualFilter: IPaymentRequestManualFilter = <IPaymentRequestManualFilter>{
        processNumber: '',
        statusCode: [],
        supplierCode: [],
        responsibleUser: [],
        clientGroups: [],
    };

    private _startDate;
    private _endDate;
    private _startDueDate;
    private _endDueDate;
    private _filterField;
    private _filterValue;
    private _page: number;
    private _size: number;

    constructor(filter?: PaymentRequestManualFilter) {
        this._paymentRequestManualFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'paymentRequestManualFilter', this._paymentRequestManualFilter, filter);
        this._initialPaymentRequestManualFilter = _.cloneDeep(this._paymentRequestManualFilter);
        this._startDate = filter ? filter._startDate : moment().format('01/01/YYYY');
        this._endDate = filter ? filter._endDate : moment().format('DD/MM/YYYY');
        this._startDueDate = filter ? filter._startDueDate : moment().format('DD/MM/YYYY');
        this._endDueDate = filter ? filter._endDueDate : moment().add(90, 'days').format('DD/MM/YYYY');
        this._filterField = filter ? filter._filterField : '';
        this._filterValue = filter ? filter._filterValue : '';
        this._page = filter ? filter._page : 0;
        this._size = filter ? filter._size : 50;
    }

    getImpNumber() { return this._paymentRequestManualFilter.processNumber }
    getStatus() { return this._paymentRequestManualFilter.statusCode; }
    getResponsibleUser() { return this._paymentRequestManualFilter.responsibleUser; }
    getUserSuppliers() { return this._paymentRequestManualFilter.supplierCode; }
    getClientGroups() { return this._paymentRequestManualFilter.clientGroups; }
    getStartDate() { return this._startDate; }
    getEndDate() { return this._endDate; }
    getStartDueDate() { return this._startDueDate; }
    getEndDueDate() { return this._endDueDate; }
    getFilterField() { return this._filterField; }
    getFilterValue() { return this._filterValue; }
    getPage(): number { return this._page; }
    getSize(): number { return this._size; }

    setImpNumber(impNumber): void {this._paymentRequestManualFilter.processNumber = impNumber; }
    setStatus(status): void { this._paymentRequestManualFilter.statusCode = status; }
    setResponsibleUser(responsibleUser): void { this._paymentRequestManualFilter.responsibleUser = responsibleUser; }
    setUserSuppliers(suppliers): void {this._paymentRequestManualFilter.supplierCode = suppliers; }
    setClientGroups(clientGroups): void {this._paymentRequestManualFilter.clientGroups = clientGroups; }
    setStartDate(startDate): void { this._startDate = startDate; }
    setEndDate(endDate): void { this._endDate = endDate; }
    setStartDueDate(startDueDate): void { this._startDueDate = startDueDate; }
    setEndDueDate(endDueDate): void { this._endDueDate = endDueDate; }
    setFilterField(filterField): void { this._filterField = filterField; }
    setFilterValue(filterValue): void { this._filterValue = filterValue; }
    setPage(page): void { this._page = page; }
    setSize(size): void { this._size = size; }

    updatePreferences() {
        this._initialPaymentRequestManualFilter = this._userPreferencesService.verifyFilterChanges(this._initialPaymentRequestManualFilter, this._paymentRequestManualFilter, 'paymentRequestManualFilter', this._userCacheService.getUserPreferences().id);
    }

    getRequest() {
        this.updatePreferences();

        let params = new HttpParams();
        let headers = new HttpHeaders();

        if (this.getFilterValue() && this.getFilterField()) {
            params = params.append(this.getFilterField(), this.getFilterValue());
        } else {
            this.setFilterField(null);
            this.setFilterValue(null);
        }

        params = params.append('clientGroups', (this.getClientGroups() || []).map((client) => client.id).join(','));
        params = params.append('processNumber', `${ this.getImpNumber() }`);
        params = params.append('statusCode', (this.getStatus() || []).map((status) => status.id).join(',').toUpperCase());
        params = params.append('responsibleUserNames', (this.getResponsibleUser() || []).map((responsibles) => responsibles.itemName).join(','));
        params = params.append('supplierCode', (this.getUserSuppliers() || []).map((suppliers) => suppliers.id).join(','));
        params = params.append('startDate', `${moment(this.getStartDate(), 'DD/MM/YYYY').format('YYYY-MM-DD')}`);
        params = params.append('endDate', `${moment(this.getEndDate(), 'DD/MM/YYYY').format('YYYY-MM-DD')}`);
        params = params.append('startDueDate', `${moment(this.getStartDueDate(), 'DD/MM/YYYY').format('YYYY-MM-DD')}`);
        params = params.append('endDueDate', `${moment(this.getEndDueDate(), 'DD/MM/YYYY').format('YYYY-MM-DD')}`);
        params = params.append('page', `${ this.getPage() }`);
        params = params.append('size', `${ this.getSize() }`);

        this.removeNullValuesFromQueryParams(params);

        return { params, headers };
    }

    removeNullValuesFromQueryParams(params: HttpParams) {
        const paramsKeysAux = params.keys();
        paramsKeysAux.forEach((key) => {
          const value = params.get(key);
          if (value === null || value === undefined || value === '') {
            params['map'].delete(key);
          }
        });

        return params;
    }
}
