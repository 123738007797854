import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '#environment';
import { IBranch, IBranchUser } from 'app/modules/crud/pages/logistic/delivery-coordination/subsidiary/subsidiary.interface';

@Injectable({
    providedIn: 'root',
})
export class SubsidiaryCrudService {
    constructor(private _http: HttpClient) {}

    getSubsidiaryNames(): Observable<any> {
        return this._http.get(`${environment.endpoints.importationMongoDBService}/imps/subsidiaryNames`);
    }

    getUsersForBranch(name: string): Observable<IBranch> {
        return this._http.get<IBranch>(`${environment.endpoints.deliveryCoordination}/branch/find?name=${name}`);
    }

    saveContacts(branch: IBranch) {
        return this._http.put<IBranchUser[]>(
            `${environment.endpoints.deliveryCoordination}/branch/${branch.id}`,
            branch.contacts
        );
    }

    saveBranch(branch: IBranch) {
        return this._http.post<IBranchUser[]>(`${environment.endpoints.deliveryCoordination}/branch`, branch);
    }
}
