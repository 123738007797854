import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import moment from 'moment';
import { Observable } from 'rxjs';
import { ALLOWED_ALERT_TYPES } from 'app/shared/constants/allowed-alert-types.constants';

import { environment } from '#environment';
import { UtilsService } from '#services/_utils/utils.service';
import { UsageAnalyticsService } from '#services/_usage-analytics/usage-analytics.service';
import { UserPermissionBlockerService } from '#services/_user/userPermissionBlocker.service';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserService } from '../../../auth/_services/user.service';

@Injectable()
export class HeaderNavService {

    public readonly CRITICAL_ALERT: string = 'critical';
    public readonly HIGH_ALERT: string = 'high';
    public readonly MEDIUM_ALERT: string = 'medium';
    public readonly LOW_ALERT: string = 'low';

    public currentUser = this.userService.currentUser$;
    public isVisibleAlerts: boolean = false;
    public editableElements = ['.alertContent', 'pagination', '.settings'];
    public summarySubscription;
    public newAlertSubscription;
    public newAlertSummarySubscription;

    public criticalNotificationQty: number = 0;
    public highNotificationQty: number = 0;
    public mediumNotificationQty: number = 0;
    public lowNotificationQty: number = 0;
    public internCriticalNotificationQty: number = 0;
    public internHighNotificationQty: number = 0;
    public internMediumNotificationQty: number = 0;
    public internLowNotificationQty: number = 0;
    public lowBallSpace: number = 0;
    public mediumBallSpace: number = 0;
    public highBallSpace: number = 0;
    public criticalBallSpace: number = 0;

    public alertList = {
        metadata: [],
        allowedTypes: [],
        atualFilters: [],
        maxPriority: this.LOW_ALERT,
        notificationQty: 0,
        notifications: [{
            title: '',
            mainCode: '',
            filterValues: [],
            fields: [],
            priority: this.LOW_ALERT,
            name: '',
            age: '',
            description: '',
            shortDescription: '',
            id: '',
            status: '',
            lastMovementDate: '',
            companyName: '',
            sourceSystem: '',
        }],
    };
    public alertQuantityByType = {
        financial: 0,
        imp: 0,
    };
    public pages = [];
    public page = 0;
    public selectedTypes = [];
    public selectedFilterField = '';
    public selectedFilters = [];
    public selectedFields = [];
    public priorityList = [];
    public commentList = [{
        comments: '',
        username: '',
    }];
    public currentComment = '';
    public selectedAlert = {
        title: '',
        mainCode: '',
        filterValues: [],
        fields: [],
        id: '',
        code: '',
        name: '',
        age: 0,
        description: '',
        shortDescription: '',
        ownerName: '',
        priority: '',
        status: '',
        comments: [],
        lastMovementDate: '',
        companyName: '',
    };
    public selectedSources = ['P', 'C'];
    public selectedIndex;
    public user = this.userService.getCurrentUser();
    public subscription;
    public asyncAlert = false;
    public asyncInternAlert = false;
    public asyncComments = false;
    public alertSearch = '';
    public userPreferenceClientGroups;
    private unsubscribeComments: () => void;

    constructor(
        private userService: UserService,
        private http: HttpClient,
        private usageAnalyticsService: UsageAnalyticsService,
        private permissionBlocker: UserPermissionBlockerService,
        private utilsService: UtilsService,
        public router: Router,
        private _userCacheService: UserCacheService,
    ) {
        this.userPreferenceClientGroups = this._userCacheService.getUserPreferences().filters?.clientGroups?.content?.value;
    }

    public toogleAlertTypes(element, type) {
        this.utilsService.setInvisible('app-header-nav', this.editableElements);
        if ($(element.target).hasClass('selected')) {
            $(element.target).removeClass('selected');
            this.selectedTypes = this.selectedTypes.filter((typeFound) => typeFound !== type);
        } else {
            $(element.target).addClass('selected');
            this.selectedTypes.push(type);
        }
        this.defineSources();
        this.countAlerts([], true);
    }

    public toDetail() {
        window.open(`/report/followup-imp/${this.selectedAlert.mainCode}`);
    }

    public changePage(pageNumber) {
        this.utilsService.setInvisible('app-header-nav', this.editableElements);
        this.page = pageNumber - 1;
        this.loadInternalAlerts();
    }

    public defineSources() {
        if (this.selectedTypes.length === 1) {
            const metadata = this.alertList.metadata[`${this.selectedTypes[0]}`];
            this.selectedFilterField = metadata.filters[0].name;
            this.selectedFilters =  _.cloneDeep(metadata.filters[0].values);
            this.alertList.atualFilters = _.cloneDeep(metadata.filters[0].values);
        } else {
            this.selectedFilterField = '';
        }
    }

    public alertDays(creationTime) {
        return moment().diff(moment(creationTime), 'days');
    }

    public loadInternalAlerts() {
        this.utilsService.setInvisible('app-header-nav', this.editableElements);
        if (this.newAlertSubscription) { this.newAlertSubscription.unsubscribe(); }
        this.asyncInternAlert = false;
        this.newAlertSubscription = this.getNewAlerts().subscribe((alertList) => {
            this.pages = Array.apply(null, { length: alertList.totalPages }).map( Number.call, Number);
            this.alertList.notifications = alertList.content;
            this.asyncAlert = true;
            this.asyncInternAlert = true;
            this.utilsService.setVisible('app-header-nav', this.editableElements);
        });
    }

    public loadAlerts() {
        if (this.newAlertSummarySubscription) { this.newAlertSummarySubscription.unsubscribe(); }
        if (this.permissionBlocker.permissionValidator('AlertService')) {
            this.asyncAlert = false;
            this.newAlertSummarySubscription = this.getNewAlertsSummary().subscribe((summary) => {
                this.alertList.maxPriority = summary.maxPriority;
                this.alertList.notificationQty = summary.problems;
                this.countAlerts(summary);
                this.alertList.metadata = summary.metadata.typeMetadata;
                this.alertList.allowedTypes = summary.metadata.types;
                this.countBallSpaces();
                this.loadInternalAlerts();
            });
        } else {
            this.asyncAlert = true;
        }
    }

    public toogleSource(source, element) {
        this.asyncInternAlert = false;
        this.utilsService.setInvisible('app-header-nav', this.editableElements);
        if ($(element.target).hasClass('selected')) {
            this.selectedFilters.splice(this.selectedFilters.indexOf(source), 1);
            $(element.target).removeClass('selected');
        } else {
            this.selectedFilters.push(source);
            $(element.target).addClass('selected');
        }
        this.countAlerts([], true);
    }

    public loadComments(index) {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.asyncComments = false;
        this.subscription = this.getComments(this.alertList.notifications[index].id).subscribe((comments) => {
            this.commentList = comments;
            const userData = 'data';
            const displayName = 'displayName';
            const userName = 'username';
            const user = 'user';
            this.commentList.forEach((c) => {
                if (this.user[userData][displayName] === c[userName]) {
                    c[user] = true;
                } else {
                    c[user] = false;
                }
            });
            this.asyncComments = true;
        }, (error) => {
            this.asyncComments = true;
            this.utilsService.errorHandler(error);
        });
    }

    public sendComment() {
        const userData = 'data';
        const displayName = 'displayName';
        const username = this.user[userData][displayName];
        this.asyncComments = false;
        this.postComment(this.selectedAlert.id, username).subscribe((comment) => {
            this.currentComment = '';
            this.loadComments(this.selectedIndex);
        });
    }

    public enableWheelInAllComponents() {
        $('.alertContent').unbind('wheel');
    }

    public closeAlertByClickingOutTheBell() {
        $('.mainMenuContent, #nameWithPhotoContainer, #messages, #features').off('click');
        $('.mainMenuContent, #nameWithPhotoContainer, #messages, #features').on('click', (e) => {
            const target = $(e.target);
            const isBellComponent = target.parents('#alerts').length;
            const isAlertComponent = target.parents('.alertModal, .selectedAlertModal').length;
            if (!isBellComponent && !isAlertComponent) {
                this.enableWheelInAllComponents();
                $('.mainMenuContent').unbind('click');
                this.closeAlert();
            }
        });
    }

    public closeAlert() {
        this.isVisibleAlerts = false;
        $('.alertModal').addClass('hidden');
        $('#alertIconContainer i').attr('class', 'comexport-alarm-1');
        $('.selectedAlertModal').addClass('hidden');
        $('#alerts').removeClass('clicked');
        $('.alertContent tr').removeClass('selected');
    }

    public openAlertModal() {
        if ($('.alertModal').hasClass('hidden') && this.alertList.notificationQty !== 0) {
            this.disableWheelOutOfAlertComponents();
            this.openAlert();
            this.closeAlertByClickingOutTheBell();
            this.usageAnalyticsService.sendEvent('Feature', 'view', 'Alerts', null, {});
        } else {
            this.enableWheelInAllComponents();
            this.closeAlert();
        }
    }

    public selectAlert(alert, element, index) {
        if (this.unsubscribeComments) { this.unsubscribeComments(); }
        this.selectedIndex = index;
        this.selectedAlert = alert;
        this.selectedFields = this.alertList.metadata[`${alert.type.toLowerCase()}`].fields;
        this.selectedAlert.fields.forEach((selectedAlertField) => {
            this.selectedFields.forEach((selectedField) => {
                if (selectedField.name === selectedAlertField.key) {
                    selectedField['value'] = selectedAlertField.value;
                }
            });
        });

        $('.alertModal').removeClass('border');
        $('.selectedAlertModal').removeClass('hidden');
        $('.alertContent tr').removeClass('selected');
        $(element.target.parentElement.parentElement).addClass('selected');
    }

    public closeSelectedAlert() {
        $('.selectedAlertModal').addClass('hidden');
        $('.alertContent tr').removeClass('selected');
        setTimeout(() => {
            $('.alertModal').addClass('border');
        }, 150);
    }

    public selectCriticalLevel(level, element) {
        this.utilsService.setInvisible('app-header-nav', this.editableElements);
        if ($(element.target).hasClass('selected')) {
            $(element.target).removeClass('selected');
            this.colorAlertCount(level, 'black');
            this.priorityList.splice(
                this.priorityList.indexOf(level), 1,
            );
        } else {
            $(element.target).addClass('selected');
            this.colorAlertCount(level, 'white');
            this.priorityList.push(level);
        }
        this.loadInternalAlerts();
    }

    private openAlert() {
        this.isVisibleAlerts = true;
        $('.alertModal').addClass('border');
        $('.alertModal').removeClass('hidden');
        $('#alerts').addClass('clicked');
        $('#alertIconContainer i').attr('class', 'comexport-alarm');
    }

    private disableWheelOutOfAlertComponents() {
        $('.alertContent').off('click');
        $('.alertContent').on('wheel', (e) => {
            const target = $(e.target);
            const isAlertDescendant = target.parents('.alertContent').length;
            if (!isAlertDescendant) {
                e.preventDefault();
            }
        });
    }

    private colorAlertCount(level: string, color: string) {
        $('.alerts-counters '.concat('.').concat(level))
          .css('color', color);
    }

    private countAlerts(summary, intern?) {
        if (intern) {
            this.asyncInternAlert = false;
            if (this.summarySubscription) { this.summarySubscription.unsubscribe(); }
            this.summarySubscription = this.getNewAlertsSummary().subscribe((internSummary) => {
                this.internCriticalNotificationQty =
                internSummary.byPriority.hasOwnProperty('CRITICAL') ? internSummary.byPriority.CRITICAL.problems : 0;
                this.internHighNotificationQty =
                internSummary.byPriority.hasOwnProperty('HIGH') ? internSummary.byPriority.HIGH.problems : 0;
                this.internMediumNotificationQty =
                internSummary.byPriority.hasOwnProperty('MEDIUM') ? internSummary.byPriority.MEDIUM.problems : 0;
                this.internLowNotificationQty =
                internSummary.byPriority.hasOwnProperty('LOW') ? internSummary.byPriority.LOW.problems : 0;
                this.loadInternalAlerts();
            });
        } else {
            this.internCriticalNotificationQty = this.criticalNotificationQty =
            summary.byPriority.hasOwnProperty('CRITICAL') ? summary.byPriority.CRITICAL.problems : 0;
            this.internHighNotificationQty = this.highNotificationQty =
            summary.byPriority.hasOwnProperty('HIGH') ? summary.byPriority.HIGH.problems : 0;
            this.internMediumNotificationQty = this.mediumNotificationQty =
            summary.byPriority.hasOwnProperty('MEDIUM') ? summary.byPriority.MEDIUM.problems : 0;
            this.internLowNotificationQty = this.lowNotificationQty =
            summary.byPriority.hasOwnProperty('LOW') ? summary.byPriority.LOW.problems : 0;
            summary.metadata.types.forEach((type) => {
                if (summary.alertSummary.hasOwnProperty(type)) {
                    this.alertQuantityByType[type] = summary.alertSummary[type].alerts;
                }
            });
        }
    }

    private countBallSpaces() {
        this.highBallSpace = (this.highNotificationQty.toString().length) - 1;
        this.mediumBallSpace = (this.mediumNotificationQty.toString().length + this.highBallSpace) - 1;
        this.lowBallSpace = (this.lowNotificationQty.toString().length + this.mediumBallSpace) - 1;
    }

    // Get NewAlerts
    private getNewAlerts(): Observable<any> {
        return this.http.get(`${environment.endpoints.newAlertService}/alerts?size=40&page=${
            this.page}${this.getParams()}`, this.getPreferencesClientGroups());
    }

    // Get NewAlerts
    private getNewAlertsSummary(): Observable<any> {
        return this.http.get(`${environment.endpoints.newAlertService}/summary?size=40${this.getParams()}`, this.getPreferencesClientGroups());
    }

    // Get Comments
    private getComments(id): Observable<any> {
        return this.http.get(`${environment.endpoints.alertService}/${id}/comments`);
    }

    // Get Comments
    private postComment(id, username): Observable<HttpResponse<any>> {
        return this.http.post(`${environment.endpoints.alertService}/${id}/comments`,
            { comments: this.currentComment, username }, { observe: 'response', responseType: 'text' });
    }

    public getMainAlerts(imps: string[], page: number = 0, size: number = 500, type: string = 'imp'): Observable<any[]> {
        const alertFilter = { keys: imps.filter(imp => imp && imp.trim() !== '') };
        let params = new HttpParams()
          .set('page', page.toString())
          .set('size', size.toString())
          .set('type', type);
 
        alertFilter.keys.forEach(key => {
          params = params.append('keys', key);  
        });

        return this.http.get<any[]>(`${environment.endpoints.newAlertService}/alerts/mainField`, { params });
      }

    private getParams() {
        let types = `&fulltext=${this.alertSearch}`;
        if (this.selectedTypes.length === 0) {
            ALLOWED_ALERT_TYPES.forEach((type) => {
                types = types.concat(`&types=${type}`);
            });
        } else {
            this.selectedTypes.forEach((type) => {
                types = types.concat(`&types=${type}`);
            });
        }
        if (this.selectedFilterField !== '') {
            types = types.concat(`&filter=${this.selectedFilterField}`);
            this.selectedFilters.forEach((filter) => {
                types = types.concat(`&${this.selectedFilterField}.value=${filter.name}`);
            });
        }
        if (this.priorityList.length > 0) {
            this.priorityList.forEach((priority) => {
                types = types.concat(`&priority=${priority}`);
            });
        }
        return types;
    }

    getPreferencesClientGroups() {
        let headers = new HttpHeaders();
        headers = headers.set('X-Requested-Client-Groups', this.userPreferenceClientGroups?.map((c) => c.id).join(','));
        if (this.userPreferenceClientGroups) {
            return { headers };
        }
    }

}
