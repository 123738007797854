<div *ngIf="asyncDistributionCost">
    <loader></loader>
</div>
<div class="title-information">
    <span class="analyticsName">{{ 'dashboard.title::distributedCosts' | translate }}</span>
    <cmx-help-modal
        [help-video-src]="'assets/videos/icone-informativo/dashboardsAtualizado.mp4'"
        help-video-name="{{'common::strings.dashboard' | translate }} {{ 'dashboard.title::distributedCosts' | translate }}"
        [help-title]="'dashboard.title::distributedCosts' | translate"
        [help-description]="helpDescription">
    </cmx-help-modal>
    <h5>{{ 'dashboard.title::distributedCosts' | translate }}</h5>
</div>

<div class="moveBtn">
    <div class="moveLine"></div>
</div>
<cmx-button class="detailsCosts" [variant]="'primary'" (click)="detailsCosts()">
    {{ 'common::strings.details' | translate }}
</cmx-button>

<div *ngIf="currentUser$ | async as user" id="searchLine" class="reduzed">
    <div class="search-container" style="margin-right: 42px">
        <cmx-filter-search
            [type]="filterState.getSearchType()"
            [types]="['IMP', 'REF', 'SKU']"
            [width]="'90px'"
            [value]="filterState.getSearchValue()"
            (onInput)="setSearch($event)"
            (onChangeType)="changeSearchType($event)"
            (onApply)="applyFilter()"
        ></cmx-filter-search>
    </div>

    <div class="search-container">
        <div class="input-group">
            <cmx-dropdown
                [styles]="{ width: '85px' }"
                *ngIf="user.has('INTERNAL_FILTERS')"
                id="clients"
                name="clients"
                [data]="options.clients"
                [ngModel]="filterState.getClientGroups()"
                (ngModelChange)="filterState.setClientGroups($event)"
                [settings]="dropdownSettings.clientGroups"
            ></cmx-dropdown>
        </div>

        <div class="input-group">
            <cmx-filter-datepicker
                [date]="'common::strings.billing' | translate"
                [startDate]="filterState.getStartDate()"
                [endDate]="filterState.getEndDate()"
                (onChangeDates)="datesChanged($event)"
            ></cmx-filter-datepicker>
        </div>

        <div *ngIf="filterState.getSearchValue().length !== 0 && filterState.getSearchType() !== 'SKU'" class="disableSearch"
            [tooltip]="'common::strings.filtersDisabledOnSearch' | translate">
        </div>

        <button (click)="applyFilter()" class="applyImpStatus">
            <i class="fa fa-check"></i>
        </button>
    </div>
</div>

<div *ngIf="!asyncDistributionCost && currentUser$ | async as user" class="checkmark-container">
    <div *ngIf="!zeroedData.valorcif" id="valor" class="checkmark" (click)="toggleTypeSwitch('Valor CIF', $event)" [tooltip]="'dashboard::distributedCosts.cifValue' | translate" placement="bottom">
        <img src="assets/img/icons/checkmark_{{ user.has('AUTOMOTIVE_USER') ? 'car' : 'fob' }}-checked.png" alt=""/>
    </div>
    <div *ngIf="!zeroedData.impostosdanacionalizao" id="impostos" class="checkmark" (click)="toggleTypeSwitch('Impostos da Nacionalização', $event)" [tooltip]="'dashboard::distributedCosts.nationalizationTaxes' | translate" placement="bottom">
        <img src="assets/img/icons/checkmark_tax.png" alt="" />
    </div>
    <div *ngIf="!zeroedData.despesasoperacionais" id="operacionais" class="checkmark impStatus-table" (click)="toggleTypeSwitch('Despesas Operacionais', $event)" [tooltip]="'dashboard::distributedCosts.operationalExpenses' | translate" placement="bottom">
        <img src="assets/img/icons/checkmark_despesas.png" alt="" />
    </div>
    <div *ngIf="!zeroedData.despesasfinanceiras" id="financeiras" class="checkmark impStatus-table" (click)="toggleTypeSwitch('Despesas Financeiras', $event)" [tooltip]="'dashboard::distributedCosts.financialExpenses' | translate" placement="bottom">
        <img src="assets/img/icons/checkmark_despesas_financeiras.png" alt="" />
    </div>
    <div *ngIf="!zeroedData.despesasservios" id="servicos" class="checkmark impStatus-table" (click)="toggleTypeSwitch('Despesas Serviços', $event)" [tooltip]="'dashboard::distributedCosts.serviceExpenses' | translate" placement="bottom">
        <img src="assets/img/icons/checkmark_despesas_financeiras.png" alt="" />
    </div>
    <div *ngIf="!zeroedData.crditoimpostosentrada" id="entrada" class="checkmark impStatus-table" (click)="toggleTypeSwitch('taxCreditEntry', $event)" [tooltip]="'dashboard::distributedCosts.taxCreditEntry' | translate" placement="bottom">
        <img src="assets/img/icons/checkmark_nf.png" alt="" />
    </div>
    <div *ngIf="!zeroedData.impostosdesada" id="saida" class="checkmark impStatus-table" (click)="toggleTypeSwitch('outputTaxes', $event)" [tooltip]="'dashboard::distributedCosts.outputTaxes' | translate" placement="bottom">
        <img src="assets/img/icons/checkmark_nf.png" alt="" />
    </div>
    <div *ngIf="!zeroedData.ipi" id="ipi" class="checkmark impStatus-table" (click)="toggleTypeSwitch('IPI', $event)" [tooltip]="'dashboard::distributedCosts.ipi' | translate" placement="bottom">
        <img src="assets/img/icons/checkmark_nf.png" alt="" />
    </div>
</div>

<div class="chart" id="costsDistributionChart"></div>
<div *ngIf="!asyncDistributionCost && (chartData === undefined || !chartData.length)" class="reloadContainer">
    <div class="reloadContent">
        <i (click)="loadCostsDistribution()" class="material-icons">refresh</i>
    </div>
</div>
