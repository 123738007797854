<div class="page-title-identifier">{{ 'menu::user.title::profile' | translate }}</div>
<div class="topContainer">
</div>

<div class="photoContainer">
    <img style="width:100%" src="assets/img/users/user-for-light.png" alt="">
</div>

<div class="profileInfo">
    <div *ngIf="currentUser$ | async as user" class="displayName">
        <span>{{user.data.displayName}}</span>
    </div>
    <div *ngIf="formattedPhoneNumber && phoneFeatureFlag" class="displayPhone">
        <span>{{formattedPhoneNumber}}</span>
    </div>
    <div *ngIf="currentUser$ | async as user" class="displayEmail">
        <span>{{user.data.email}}</span>
    </div>

    <div class="changePassword">
        <span (click)='openChangePasswordModal()'>{{ 'auth.msgPasswordChange' | translate | titlecase }}</span>
    </div>
</div>


<div class="profileOptions">
    <div class="enableTwoFactor">
        <div class="optionContent" (click)='openTwoFactorModal()'>
            <i class="material-icons">lock</i>
            <span>{{ 'auth.msgTwoFactorAuth' | translate }}</span>
        </div>
    </div>

    <div *ngIf="phoneFeatureFlag" class="enableAI">
        <div class="optionContent" (click)='openEnableAIModal()'>
            <i class="material-icons">memory</i>
            <span>{{ 'auth.msgConfigureAIChat' |  translate }}</span>
        </div>
    </div>
</div>

<div #changePasswordModal class="changePasswordModal hidden">
    <div class="changePasswordContent">
        <div class="inputItem">
            {{ 'auth.placeholderCurrentPassword' | translate }}
            <input [(ngModel)]="actualPassword" type="password">
        </div>
        <div class="inputItem">
            {{ 'auth.placeholderNewPassword' | translate }}
            <input [(ngModel)]="newPassword" type="password" (focus)="focusHit = true" (blur)="focusHit = false" (input)="checkPassword($event)">
        </div>
        <div class="inputItem">
            {{ 'auth.placeholderConfirmPassword' | translate }}
            <input [(ngModel)]="repeatPassword" type="password" (focus)="focusHit = true" (blur)="focusHit = false" (input)="checkPassword($event)">
        </div>
        <div class="password-hits">
            <ul [ngClass]="{'hit-password': focusHit}">
                <li [ngClass]="{'danger-color': !checkNumbersCharsPassword, 'success-color': checkNumbersCharsPassword}"><i class="fa fa-times" *ngIf="!checkNumbersCharsPassword"></i><i class="fa fa-check" *ngIf="checkNumbersCharsPassword"></i>{{ 'auth.msgPasswordRequirements' | translate }}</li>
                <li [ngClass]="{'danger-color': !checkSpecialCharPassword, 'success-color': checkSpecialCharPassword}"><i class="fa fa-times" *ngIf="!checkSpecialCharPassword"></i><i class="fa fa-check" *ngIf="checkSpecialCharPassword"></i>{{ 'auth.msgPasswordSpecialChar' | translate }}</li>
                <li [ngClass]="{'danger-color': !checkTotalPassword, 'success-color': checkTotalPassword}"><i class="fa fa-times" *ngIf="!checkTotalPassword"></i><i class="fa fa-check" *ngIf="checkTotalPassword"></i>{{ 'auth.msgPasswordMinLength' | translate }}</li>
            </ul>
        </div>
        <div *ngIf="asyncChangePassword === true" class="actions-buttons">
            <cmx-button variant="light" (click)='closeChangePasswordModal()'>{{ 'system::actions.close' | translate }}</cmx-button>
            <cmx-button [disabled]="!passwordAccept" (click)='changePassword()'>{{ 'system::actions.update' | translate }}</cmx-button>
        </div>
        <div *ngIf="asyncChangePassword === false">
            <loader></loader>
        </div>
    </div>
</div>

<div #enableAIModal class="enableAIModal hidden">
    <div *ngIf="!phoneIntegrationData" class="enableAIModalFirstAccess">
        <span class="dialogTitle">{{ 'auth.msgConfigureAIChat' |  translate }}</span>
        <div class="infoMessage">
            <span>{{ 'auth.msgChatAIPhoneRequired' | translate }}</span>
        </div>
        <div class="actions-buttons">
            <cmx-button variant="light" (click)='closeEnableAIModal()'>{{ 'system::actions.close' | translate }}</cmx-button>
            <cmx-button (click)='closeEnableAIModal(); openEditPhoneModal()'>{{ 'system::actions.continue' | translate }}</cmx-button>
        </div>
    </div>

    <div *ngIf="phoneIntegrationData" class="enableAIModalContent">
        <div class="modalContent">
            <div class="orderedList">
                <div class="listItem">
                    <div class="itemHeader">1.</div><div class="itemContent">{{ 'auth.msgStartChatWithComeXa' | translate }}</div>
                </div>
                <div class="listItem">
                    <div class="itemHeader">2.</div><div class="itemContent">{{ 'auth.msgFirstAccessCodeRequired' | translate }}</div>
                </div>
                <div class="listItem">
                    <div class="itemHeader">3.</div><div class="itemContent">{{ 'auth.msgAccessCodeButtonInfo' | translate }}</div>
                </div>
                <div class="listItem">
                    <div class="itemHeader">4.</div><div class="itemContent">{{ 'auth.msgProvideCodeViaWhatsApp' | translate }}</div>
                </div>
            </div>
            <div class="integrationLinks">
                <a [href]="whatsappUrl" class="whatsappIcon" ><img style="width: 150px;" src="assets/img/icons/whatsapp_green.svg"/></a>
                <qrcode [qrdata]="whatsappUrl" [cssClass]="'center'" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
            </div>
            <div class="integrationOptions">
                <button *ngIf="phoneIntegrationData.otpCode && !showOtp" (click)='this.setShowOtp(true)' class="showOtpBtn">{{ 'auth.showCode' | translate }}</button>
                <span *ngIf="showOtp" class="otpCode">{{phoneIntegrationData.otpCode}}</span>
                <button (click)='openEditPhoneModal()' class="editPhoneBtn">{{ 'auth.changePhone' | translate }}</button>
            </div>
        </div>
        <div class="buttons">
            <cmx-button variant="light" (click)='closeEnableAIModal()'>{{ 'system::actions.close' | translate }}</cmx-button>
        </div>
    </div>
</div>

<div #editPhoneModal class="editPhoneModal hidden">
    <div class="editPhoneModalContent">
        <span class="dialogTitle">{{ 'auth.msgConfigureAIChat' |  translate }}</span>
        <div class="inputItem">
            {{ 'auth.phone' | translate }}
            <input pInputText (keydown)="editPhoneOnKeydown($event)" [(ngModel)]="phoneNumber" type="text" placeholder="(99) 99999-9999" mask="(00) 00000-0000"/>
        </div>
        <div *ngIf="asyncEditPhone === true" class="buttons">
            <cmx-button variant="light" (click)='closeEditPhoneModal()'>{{ 'system::actions.close' | translate }}</cmx-button>
            <cmx-button [variant]="updatePhoneNumber ? 'secundary' : 'success'" (click)='savePhoneIntegration()'>{{ updatePhoneNumber ? ('system::actions.update' | translate) : ('system::actions.save' | translate) }}</cmx-button>
        </div>
        <div *ngIf="asyncEditPhone === false">
            <loader></loader>
        </div>
    </div>
</div>

<div #enableTwoFactorModal class="enableTwoFactorModal hidden">
    <div *ngIf="!asyncTwoFactor">
        <loader></loader>
    </div>
    <div *ngIf="asyncTwoFactor" class="enableTwoFactorContent p-4">
        <div *ngIf="isTwoFactorEnabled()">
            <span class="infoMessage">{{ 'auth.msgQRCodeGoogleAuthenticator' | translate }}</span>
            <span class="warnMessage" *ngIf="warnMessage">{{ warnMessage }}</span>
            <div class="guideMessage" (click)="openTutorial()">
                <span>{{ 'auth.msgSeeHow' | translate }}</span>
                <i class="material-icons">view_carousel</i>
            </div>
        </div>

        <div id="qrcode" class="p-2"></div>

        <div *ngIf="!isTwoFactorEnabled()" class="enableTwoFactorContent">
            <span class="dialogTitle warning">
                {{ 'auth.msgAttention' | translate }}
            </span>

            <div class="infoMessage" *ngIf="!confirmTwoFactorMoment">
                <span>
                    {{ 'auth.msgAttentionLoginWithoutAuthCode' | translate }}
                </span>
            </div>

            <div class="infoMessage" *ngIf="confirmTwoFactorMoment">
                {{ 'auth.msgClickEnableTwoFactor' | translate }}
            </div>

        </div>
        <div *ngIf="asyncChangePassword === true" class="actions-buttons pt-3">
            <cmx-button variant="light" *ngIf="isTwoFactorEnabled() || !isTwoFactorMandatory()" (click)='closeEnableTwoFactorModal()'>{{ 'system::actions.close' | translate }}</cmx-button>
            <cmx-button variant="success" *ngIf="!isTwoFactorEnabled() && confirmTwoFactorMoment" (click)='confirmTwoFactor()'>{{ 'system::actions.enable' | translate }}</cmx-button>
            <cmx-button variant="secondary" *ngIf="isTwoFactorEnabled()" (click)='disableTwoFactor()'>{{ 'system::actions.disable' | translate }}</cmx-button>
            <cmx-button *ngIf="!isTwoFactorEnabled() && !confirmTwoFactorMoment" (click)='confirmTwoFactorMoment = true'>{{ 'system::actions.continue' | translate }}</cmx-button>
        </div>
        <div *ngIf="asyncChangePassword === false">
            <loader></loader>
        </div>
    </div>
</div>

<div #confirmDisableTwoFactorModal class="confirmDisableTwoFactorModal hidden">
    <div class="closeConfirmationContent">
        <div class="dangerContainer">
            <span>{{ 'auth.msgConfirmDisableTwoFactor' | translate }}</span>
        </div>
        <div class="actions-buttons">
            <cmx-button variant="light" (click)='closeConfirmationModal()'>{{ 'system::actions.no' | translate }}</cmx-button>
            <cmx-button variant="danger" (click)='confirmDisableTwoFactor()'>{{ 'system::actions.yes' | translate }}</cmx-button>
        </div>
    </div>
</div>

<div #twoFactorTutorial class="twoFactorTutorial hidden">
    <i (click)="closeTutorial()" class="la la-close"></i>
    <div class="twoFactorTutorialContent">
        <div class="imageContainer">
            <span>{{ 'auth.msgDownloadGoogleAuthenticator' | translate }}</span>
            <img src="assets/img/tutos/two-factor/two-factor1.png" alt="">
        </div>
        <div class="imageContainer">
            <span>{{ 'auth.msgClickStart' | translate }}</span>
            <img src="assets/img/tutos/two-factor/two-factor2.png" alt="">
        </div>
        <div class="imageContainer">
            <span>{{ 'auth.msgChooseBarcodeOption' | translate }}</span>
            <img src="assets/img/tutos/two-factor/two-factor3.png" alt="">
        </div>
        <div class="imageContainer">
            <span>{{ 'auth.msgPointCameraAtBarcode' | translate }}</span>
            <img src="assets/img/tutos/two-factor/two-factor4.png" alt="">
        </div>
        <div class="imageContainer">
            <span>{{ 'auth.msgReadyAccessKeySaved' | translate }}</span>
            <img src="assets/img/tutos/two-factor/two-factor5.png" alt="">
        </div>
    </div>
</div>
