<div *ngIf="videoTitle" class="video-title">{{ videoTitle }}</div>
<div class="video-container" [ngClass]="getVideoSizeClass()">
  <video
    #videoPlayer
    [src]="videoSrc"
    controls
    controlsList="nodownload"
    (contextmenu)="onContextMenu($event)"
    (play)="onVideoStarted()"
    (ended)="onVideoEnded()"
    (pause)="onVideoPaused()"
    class="video-element"
  ></video>
</div>
