import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { finalize } from 'rxjs/operators';

import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { DATE_NOTIFICATION_COLUMNS, SPECIFIC_PROCESSES_COLUMNS } from './constants/date-notification-columns.constant';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserService } from "app/auth/_services/user.service";
import { CmxDropdownService } from 'app/shared/components/cmx-dropdown/cmx-dropdown.service';
import { DateNotificationService } from './date-notification.service';
import { UtilsService } from '#services/_utils/utils.service';
import { NotificationService } from '#services/_notification/notification.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { CustomDialogService } from 'app/shared/components/cmx-custom-dialog/custom-dialog.service';
import { ImportDetailsService } from 'app/theme/pages/import-details/import-details.service';

@Component({
    selector: 'date-notification-crud',
    templateUrl: './date-notification.component.html',
    styleUrls: ['./date-notification.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class DateNotificationCRUDComponent {

    async: boolean = false;
    changes: boolean = false;
    initialEmpty: boolean = false;
    showNoEmailConfirmationModal: boolean = false;
    loadingTranslate: boolean = false;
    specificProcesses: string[] = [];
    processInputValue: string = '';
    modalIsOpen: boolean = false;
    selectedGridRowIndex: number = null;

    currentUser;
    selectedClientGroup;
    gridRows = [];
    modalSpecificProcessesRows = [];
    clientGroups = this._userCacheService.getClientGroups();
    gridColumns = this._utilsService.translateListField(DATE_NOTIFICATION_COLUMNS, 'name', false);
    specificProcessesColumns = this._utilsService.translateListField(SPECIFIC_PROCESSES_COLUMNS, 'name', false);
    translateSubscription: Subscription = null;

    dropdown: {
        emails: { options: DropdownOption[]; selected: DropdownOption[] }
    } = {
        emails: {  options: [], selected: [] }
    };

    emailDropdownSettings = {
        enableCheckAll: true,
        badgeShowLimit: 1,
        disabled: false,
        translate: true,
    };
    validImp: boolean = false;
    impDetails: any;

    constructor(
        private _userService: UserService,
        private _utilsService: UtilsService,
        private _userCacheService: UserCacheService,
        private _cmxDropdownService: CmxDropdownService,
        private _notificationService: NotificationService,
        private _dateNotificationService: DateNotificationService,
        private _translateService: TranslateService,
        private _userPreferencesService: UserPreferencesService,
        private _customDialogService: CustomDialogService,
        private _importDetailsService: ImportDetailsService,
    ) {
        this._userService.currentUser$.subscribe((user) => {
            this.currentUser = user;
        });
    }

    ngOnInit() {

        this._translateService.onLangChange.subscribe(() => {
            this._updatePropertiesColumnTooltips();
            this.gridColumns = this._utilsService.translateListField(DATE_NOTIFICATION_COLUMNS, 'name', false);
            this.gridColumns = this._utilsService.translateListField(DATE_NOTIFICATION_COLUMNS, 'informative', false);
            this._translateGridRows();
        });

        this._updatePropertiesColumnTooltips();

        if (!this.currentUser.has('INTERNAL_FILTERS') && this.currentUser.clientGroups[0]) {
            this.selectedClientGroup = { code: this.currentUser.clientGroups[0] };
            this._updateEmailList();
            this._updateDataList();
        }
        this.onChanges();
    }

    private _updatePropertiesColumnTooltips(): void {

        const tooltipLeftValue = this._translateService.instant('page::config::notifications.gridDates.formatterData.tooltipLeftValue');
        const tooltipRightValue = this._translateService.instant('page::config::notifications.gridDates.formatterData.tooltipRightValue');
        const tooltipNeutralValue = this._translateService.instant('page::config::notifications.gridDates.formatterData.tooltipNeutralValue');
        const informative = this._translateService.instant('page::config::notifications.gridDates::informative');

        this.gridColumns = this.gridColumns.map(col => {
            if (col.field === 'properties' && col.formatterData) {
                return {
                    ...col,
                    informative,
                    formatterData: {
                        ...col.formatterData,
                        tooltipLeftValue,
                        tooltipRightValue,
                        tooltipNeutralValue
                    }
                };
            }
            return col;
        });
    }

    ngOnDestroy() {
        if (this.translateSubscription) {
            this.translateSubscription.unsubscribe();
        }
    }

    onChanges() {
        this.translateSubscription = this._userPreferencesService.onLanguageOrPreferenceChange(() => {
            this.loadingTranslate = true;
        }, (typeChange) => {
            if (typeChange === 'language') {
              this.gridColumns = this._utilsService.translateListField(DATE_NOTIFICATION_COLUMNS, 'name', false);
              // Aplica novamente os tooltips de acordo com o idioma
              this._updatePropertiesColumnTooltips();
              this._translateGridRows();
            }
        });

    }

    onSpecificProcessesCellClick({ item, column, _multiButtom }): void {
        if (column === 'action') {
            this.removeProcessNumber(item._index);
        }
    }
    
    selectClientGroup(clientGroup) {
        this.async = true;
        this.selectedClientGroup = clientGroup;
        this._updateDataList();
    }

    emailSelected() {
        this.changes = true;
    }

    onImpBlur(): void {
        setTimeout(() => {
            if (this.modalIsOpen) {
                this.validateImp();
            }
        }, 100);
    }

    onModalSpecificProcessesClose(): void {
        this.modalIsOpen = false;
        this.processInputValue = '';
        if (this.specificProcesses.length === 0 && this.selectedGridRowIndex !== null) {
            this.gridRows[this.selectedGridRowIndex].properties = null;
            this.gridRows = [...this.gridRows];
        }
    }

    validateImp(): void {
        const imp = this.processInputValue.trim();
        if (!imp || imp.length < 6) {
            return;
        }
    
        if (this.specificProcesses.includes(imp)) {
            return;
        }
    
        this.validImp = true;
    
        this._importDetailsService.getImportDetails(imp, 0)
            .subscribe(
                (response) => {
                    if (this.selectedClientGroup && response.clientGroupName) {

                        if (this.selectedClientGroup.name !== response.clientGroupName) {
                            const msgDifferentClient = this._translateService.instant('page::config::notifications.imp.differentClient', { imp });
                            this._notificationService.openNotification('error', msgDifferentClient, '', 3000);
                            this.validImp = false;
                            return;
                        }

                        if (response.status && response.status.code === 'FINALIZADO') {
                            const msgFinalizado = this._translateService.instant('page::config::notifications.imp.finalized', { imp });
                            this._notificationService.openNotification('error', msgFinalizado, '', 3000);
                            this.validImp = false;
                            return;
                        }
                        
                        else {
                            const msgValid = this._translateService.instant('page::config::notifications.imp.valid');
                            this._notificationService.openNotification('success', msgValid, '', 2000);
                            this.impDetails = response;
                            this.specificProcesses.push(imp);
                            this.updateModalSpecificProcessesRows();
                            this.updateImpsCount();
                            }
                            this.validImp = false;
                        }
                        
                },
                (error) => {
                    const msgInvalid = this._translateService.instant('page::config::notifications.imp.invalid');
                    this._notificationService.openNotification('error', msgInvalid, '', 3000);
                    this.validImp = false;
                }
            );
    }
    
      
    switchNotification({ item, column, value, rowIndex }): void {
        if (value === 'neutral') {
            this.gridRows[item.rawIndex][column] = value;
        } else {
            this.gridRows[item.rawIndex][column] = value;
        }
        this.changes = true;
        if (column === 'properties' && value === 'SPECIFIC') {
            this.selectedGridRowIndex = item.rawIndex;
            const row = this.gridRows[this.selectedGridRowIndex];
            this.specificProcesses = row.processList ?? [];
            this.updateModalSpecificProcessesRows();
            this.modalIsOpen = true;
            this._customDialogService.open('modal-specific-processes');
        }
        const rowElement = $(`[index-row="${rowIndex}"]`);
        const switchElement = rowElement.find('.switch-formatter-container.neutral-type')[0];
        const ballElement = switchElement.children[0]
        if ((!item.add || item.add === 'off') && (!item.update || item.update === 'off')) {
            item.switchDisabled = ['properties'];
            item.properties = null;
            if (!switchElement?.classList?.contains('disabled')) {
                switchElement?.classList?.add('disabled');
                ballElement?.classList?.remove('on');
                ballElement?.classList?.remove('off');
                ballElement?.classList?.add('neutral');
            }
        } else {
            item.switchDisabled = [];
            switchElement?.classList?.remove('disabled');
        }
    }

    frequencySelection({ id, itemName, column, row }) {
        this.gridRows[row][column] = { ...this.gridRows[row][column], id, itemName };
        this.changes = true;
    }

    prepareNotification(skipConfirmation?: boolean) {
        const notificationData = [];

        this.gridRows?.forEach((row) => {
            const selectionType = row.properties;
            const propertiesArray: Array<{ key: string; value: string }> = [];

            if (selectionType) {
                propertiesArray.push({ key: 'SELECTION_TYPE', value: selectionType });
                if (selectionType === 'SPECIFIC') {
                    const idList = (row.processList || []).join(',');
                    propertiesArray.push({ key: 'ID_LIST', value: idList });
                }
            }

            const baseConfig = {
                includedClientGroupCodes: row.includedClientGroupCodes,
                frequency: { id: row?.frequency?.id, itemName: row?.frequency?.itemName },
                properties: propertiesArray,
            };

            notificationData.push({
                ...baseConfig,
                id: `DATA_CHANGE_${this._formatPath(row.path)}_add`,
                mediums: { EMAIL: !!row.add },
            });

            notificationData.push({
                ...baseConfig,
                id: `DATA_CHANGE_${this._formatPath(row.path)}_update`,
                mediums: { EMAIL: !!row.update },
            });
        });

        const request = {
            category: {
                id: 'DATA_CHANGE',
                children: notificationData,
            },
            mediumProperties: [{
                value: [],
                key: 'email',
                medium: 'EMAIL',
            }, {
                value: [],
                key: 'external-email',
                medium: 'EMAIL',
            }],
        };

        this.dropdown.emails.selected?.forEach((email) => {
            request.mediumProperties[0].value.push(email.id);
            if (!email.id?.includes('@comexport.com.br')) {
                request.mediumProperties[1].value.push(email.id);
            }
        });

        if (!this.dropdown.emails.selected?.length && !skipConfirmation) {
            this.showNoEmailConfirmationModal = true;
        } else {
            this.showNoEmailConfirmationModal = false;
            this._saveNotification(request);
        }
    }
      

    hasConfiguration() {
        return this.gridRows.some((row) => { return row.add || row.update })
    }

    onCellClick({ item, column }): void {
        if (column === 'imps') {
            this.selectedGridRowIndex = item.rawIndex;
            const row = this.gridRows[this.selectedGridRowIndex];

            this.specificProcesses = row.processList ?? [];
  
            if (this.specificProcesses.length > 0) {
                this.updateModalSpecificProcessesRows();
                this.modalIsOpen = true;
                this._customDialogService.open('modal-specific-processes');
            }
        }
    }
    
    updateModalSpecificProcessesRows(): void {
        this._translateService.get('page::config::notifications.specificProcesses.remove').subscribe((translatedRemove: string) => {
            this.modalSpecificProcessesRows = this.specificProcesses.map((process, index) => ({
                imp: process,
                action: [{
                    code: 'remove_imp',
                    name: translatedRemove,
                    color: '#a70000',
                    icon: 'delete_outline',
                    status: 'enable'
                }],
                _index: index
            }));
        });
    }

    addProcessNumber() {
        const number = this.processInputValue.trim();
        if (number && !this.specificProcesses.includes(number)) {
            this.specificProcesses.push(number);
            this.processInputValue = '';
            if (this.selectedGridRowIndex !== null) {
                this.gridRows[this.selectedGridRowIndex].processList = [...this.specificProcesses];
                this.updateImpsCount();
            }
            this.updateModalSpecificProcessesRows();
        }
    }

    removeProcessNumber(index: number) {
        this.specificProcesses.splice(index, 1);
        if (this.selectedGridRowIndex !== null) {
            this.gridRows[this.selectedGridRowIndex].processList = [...this.specificProcesses];
            this.updateImpsCount();
        }
        this.updateModalSpecificProcessesRows();
    }
    
    private updateImpsCount(): void {
        if (this.selectedGridRowIndex !== null) {
            const count = this.specificProcesses.length;
            this.gridRows[this.selectedGridRowIndex].imps = count > 0 ? [{
                name: String(count),
                color: '#1976d2'
            }] : [];
            this.gridRows = [...this.gridRows];
            this.changes = true;
        }
    }
    
    private _saveNotification(notificationData) {
        this.async = true;
        this._dateNotificationService.saveNotification(notificationData, this.selectedClientGroup?.code).subscribe((response) => {
            this._notificationService.openNotification('success', this._translateService.instant('common::messages.success::saved'), this._translateService.instant('configuration.tab::notifications'), 3000);
            this._updateDataList();
            this.changes = false;
            this.async = false;
        }, (error) => {
            this.async = false;
            this._utilsService.errorHandler(error, this._translateService.instant('configuration.tab::notifications'));
        });
    }

    private _updateEmailList() {
        this._dateNotificationService.getEmailList(this.selectedClientGroup?.code, this.currentUser.has('INTERNAL_FILTERS')).subscribe((response) => {
            const filteredEmailList = this._filterExternalUserEmailList(response);
            this.dropdown.emails.options = this._cmxDropdownService.toDropdownItemsEmail(filteredEmailList);
        });
    }

    // caso usuário externo, retorna apenas emails de final semelhante.
    private _filterExternalUserEmailList(emaiList) {
        const emailSuffix = this.currentUser?.data?.email.split('@')[1];
        return this.currentUser.has('INTERNAL_FILTERS') ? emaiList : emaiList.filter((email) => { return email?.email?.includes(emailSuffix) })
    }

    private _updateDataList() {
        this.gridRows = [];
        forkJoin([
            this._dateNotificationService.getDataList(this.selectedClientGroup?.code),
            this._dateNotificationService.getDataDefaultList(!this.currentUser.has('INTERNAL_FILTERS')),
            this._dateNotificationService.getEmailList(this.selectedClientGroup?.code, this.currentUser.has('INTERNAL_FILTERS'))
        ]).pipe(finalize(() => this.async = false))
            .subscribe(([notificationData, defaultDataList, emailDataList]) => {
                this._verifyEmptyClientConfiguration(notificationData);
                const notificationDateColumns = notificationData?.category?.children || [];
                const filteredEmailList = this._filterExternalUserEmailList(emailDataList);
                this.dropdown.emails.options = this._cmxDropdownService.toDropdownItemsEmail(
                    filteredEmailList.map(email => ({ ...email, name: email?.email }))
                );

                this._populateEmails(notificationData?.mediumProperties);

                defaultDataList?.groups?.forEach((group) => {
                    group?.columns?.forEach((column) => {
                        if (column?.field?.toLowerCase()?.includes('date') || column?.formatter?.toLowerCase()?.includes('date') || column?.path?.toLowerCase()?.includes('dates')) {
                            const formattedPath = this._formatPath(column?.path);
                            const addItem = notificationDateColumns.find(
                                (item) => item.id === `DATA_CHANGE_${formattedPath}_add`
                            );
                            const updateItem = notificationDateColumns.find(
                                (item) => item.id === `DATA_CHANGE_${formattedPath}_update`
                            );
                            const add = !!(addItem && addItem.mediums?.EMAIL);
                            const update = !!(updateItem && updateItem.mediums?.EMAIL);

                            let selectionType: 'ALL' | 'SPECIFIC' | null = null;
                            let processList: string[] = [];

                            const extractProperties = (item) => {
                                if (!item || !item.properties) return;
                                const selTypeProp = item.properties.find(p => p.key === 'SELECTION_TYPE');
                                if (selTypeProp && (selTypeProp.value === 'SPECIFIC' || selTypeProp.value === 'ALL')) {
                                    if (!selectionType) {
                                        selectionType = selTypeProp.value;
                                    }
                                    if (selTypeProp.value === 'SPECIFIC') {
                                        const idListProp = item.properties.find(p => p.key === 'ID_LIST');
                                        if (idListProp && idListProp.value) {
                                            const ids = idListProp.value
                                                .split(',')
                                                .map(id => id.trim())
                                                .filter(id => !!id);
                                            ids.forEach(id => {
                                                if (!processList.includes(id)) {
                                                    processList.push(id);
                                                }
                                            });
                                        }
                                    }
                                }
                            };

                            extractProperties(addItem);
                            extractProperties(updateItem);

                            let freqItem = null;
                            if (add && addItem) {
                                freqItem = addItem;
                            } else if (update && updateItem) {
                                freqItem = updateItem;
                            }
                            if (!freqItem) {
                                freqItem = addItem || updateItem;
                            }
                            const freqId = freqItem?.frequency?.id || 'ON_CHANGE';
                            const freqName = freqItem?.frequency?.itemName ||
                                this._translateService.instant('page::config::notifications.gridDates::byUpdate');

                            this.gridRows.push({
                                ...column,
                                frequency: {
                                    data: notificationData?.frequencyList || [],
                                    id: freqId,
                                    itemName: freqName,
                                    disabled: false,
                                },
                                includedClientGroupCodes: [this.selectedClientGroup?.code],
                                add,
                                update,
                                properties: selectionType,
                                imps: processList.length
                                    ? [{
                                        name: String(processList.length),
                                        color: '#1976d2'
                                        }]
                                    : [],
                                processList,
                                switchDisabled: add || update ? [] : ['properties'],
                            });
                        }
                    });
                });
            });
    }

    private _translateGridRows(): void {
        forkJoin([
            this._dateNotificationService.getDataList(this.selectedClientGroup?.code),
            this._dateNotificationService.getDataDefaultList(!this.currentUser.has('INTERNAL_FILTERS')),
        ]).pipe(finalize(() => this.loadingTranslate = false))
        .subscribe(([notificationData, defaultDataList]) => {
            if (!defaultDataList?.groups) return;
            const frequencyList = notificationData?.frequencyList || [];
            const rowMap = new Map<string, string>();
            defaultDataList.groups?.forEach(group => {
                group?.columns?.forEach(row => {
                    if (row?.field && row?.label) {
                        rowMap.set(row.field, row.label);
                    }
                });
            });
            this.gridRows?.forEach((gridRow) => {
                const newLabel = rowMap.get(gridRow.field);
                if (newLabel) {
                    gridRow.label = newLabel;
                    gridRow.frequency.data = frequencyList;
                    gridRow.frequency = {
                        ...gridRow.frequency,
                        data: frequencyList,
                        itemName: frequencyList.find(frequency => frequency.id === gridRow.frequency.id)?.itemName || this._translateService.instant('page::config::notifications.gridDates::byUpdate'),
                    }
                }
            });
            this.gridRows = _.cloneDeep(this.gridRows);
        });
    }

    private _populateEmails(emailLists) {
        const emailList = this.currentUser.has('INTERNAL_FILTERS') ? emailLists[0] : emailLists[1];
        this.dropdown.emails.selected = [];
        emailList?.value?.forEach((email) => {
            const selectedEmail = this.dropdown.emails.options.find((option) => option?.id === email);
            if (selectedEmail) {
                this.dropdown.emails.selected.push(selectedEmail);
            }
        });
    }

    private _verifyEmptyClientConfiguration(notificationData) {
        this.initialEmpty = !notificationData?.mediumProperties?.length && !notificationData?.category?.children?.length;
    }

    private _formatPath(path): string {
        return path?.replaceAll('[*].', '_')?.replaceAll('.', '_');
    }
}
