import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import * as _ from 'lodash';

import { UtilsService } from '#services/_utils/utils.service';
import { CustomDialogService } from 'app/shared/components/cmx-custom-dialog/custom-dialog.service';
import { OperationalAlertsService } from '../../services/operational-alerts.service';
import { UserService } from 'app/auth/_services';

import { IGridColumn, IGridRow } from 'app/shared/components/cmx-custom-grid/interfaces';
import { IOperationalAlertClientInfo, IOperationalAlertClientResponse } from 'app/shared/interfaces/operational-alerts.interface';

import { AlertClientDetailsDialogComponent } from '../alert-client-details-dialog/alert-client-details-dialog.component';
import { FilterStateService } from '#services/_filters/filter-state.service';
import { OperationalAlertsFilter } from '../../operationalAlertsFilter.model';
import { FeatureFlagService } from '#services/_feature-flag/feature-flag-service';
import { environment } from '#environment';

@Component({
    selector: 'alert-client-dialog',
    templateUrl: './alert-client-dialog.component.html',
    styleUrls: ['./custom-dialog.scss', './alert-client-dialog.component.scss'],
})
export class AlertClientDialogComponent implements OnDestroy {
    @ViewChild(AlertClientDetailsDialogComponent, { static: true })
    alertClientDetailsDialog: AlertClientDetailsDialogComponent;

    filterState: OperationalAlertsFilter = this._filterStateService.getOperationalAlertsFilter();

    gridColumns: IGridColumn[];
    gridRows: IGridRow[];
    headerInfo: IOperationalAlertClientInfo;
    link: string;
    id: string;
    loading: boolean;
    isInternalUser: boolean;
    useMongodbService: boolean = false;

    private _unsubscribeAll$: Subject<any>;

    constructor(
        private _customDialogService: CustomDialogService,
        private _operationalAlertsService: OperationalAlertsService,
        private _featureFlagService: FeatureFlagService,
        private _router: Router,
        private _utilsService: UtilsService,
        private _userService: UserService,
        private _filterStateService: FilterStateService,
    ) {
        this.id = this._utilsService.generateGUID('dialog');
        this.loading = true;
        this.isInternalUser = this._userService.getCurrentUser().has('INTERNAL_FILTERS');

        this._unsubscribeAll$ = new Subject();
    }

    async ngOnInit() {
        this.useMongodbService = await this._featureFlagService.isFeatureFlagEnabledV2('operational-alerts-mongodb-enabled');
        if (this.useMongodbService) {
            this._operationalAlertsService.setBaseUrl(environment.endpoints.importationMongoDBService);
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
    }

    open(): void {
        if (!this.link) {
            throw new Error('[AlertDetailDialogError] Must have link to render dialog!');
        }

        this.loading = true;
        this._customDialogService.open(this.id);

        this._operationalAlertsService
            .getOperationalAlertClient(this.link, this.filterState.getRequest())
            .pipe(takeUntil(this._unsubscribeAll$))
            .subscribe(({ columns, data, info }: IOperationalAlertClientResponse) => {
                this.headerInfo = info;
                this.gridColumns = this._createColumns(columns);
                this.gridRows = data;

                const timeout = setTimeout(() => {
                    this.loading = false;
                    clearTimeout(timeout);
                }, 1000);
            });
    }

    onGridCallback({ grid, dataView }): void {
        grid.onClick.subscribe((e, _) => {
            const cell = grid.getCellFromEvent(e);
            const cellData = dataView.getItem(cell.row);
            const cellColumn = grid.getColumns()[cell.cell].field;

            if (cellColumn === 'importationNumber') {
                const imp = cellData.importationNumber;

                if (
                    navigator.userAgent.indexOf('MSIE') !== -1 ||
                    navigator.appVersion.indexOf('Trident/') > -1 ||
                    window.navigator.userAgent.indexOf('Edge') > -1
                ) {
                    this._router.navigate([`./supply-chain/importacao/${this.headerInfo.companyCode}/${imp}`]);
                } else {
                    window.open(`./supply-chain/importacao/${this.headerInfo.companyCode}/${imp}`);
                }
            }

            if (cellColumn === 'invoice' || cellColumn === 'packingList' || cellColumn === 'billOfLading') {
                if(cellData.invoice.length > 1 || cellData.packingList.length > 1 || cellData.billOfLading.length > 1){
                    this.alertClientDetailsDialog.link = this.link;
                    this.alertClientDetailsDialog.details = cellData;
                    this.alertClientDetailsDialog.open();
                }
            }
        });
    }

    private _createColumns(columns: IGridColumn[]): IGridColumn[] {
        const cssClasses = {
            headerCssClass: 'grid-header align-center',
            cssClass: 'grid-cell align-center',
        };

        return columns.reduce((accumulator: IGridColumn[], currentValue: IGridColumn, currentIndex: number) => {
            const fieldOption = { ...currentValue, id: currentIndex, name: currentValue.label, ...cssClasses };

            if (currentIndex === 0) {
                _.assign(fieldOption, { formatterType: 'badge', formatterData: { classes: 'pointer' } });
                return [...accumulator, fieldOption];
            }

            return [...accumulator, fieldOption];
        }, []);
    }
}
