<div *ngIf="asyncClientGroups === false" class="fullLoader admModalLoader">
    <loader></loader>
</div>

<i (click)="close()" id="close" class="la la-close"></i>

<div class="admin-account-border">
    <div class="line-space">
        <div>
            <cmx-listing
                height="calc(50vh - 160px)"
                searchPlaceholder="Buscar Cliente"
                [instantSearch]="false"
                [list]="clients"
                [hasAddButton]="true"
                (onSelect)="selectPermissionClient($event)"
                (onNewItem)="clearUserForm('client')"
                (onSearch)="filterClientByName($event)"
            ></cmx-listing>
            <div *ngIf="clientPages.length > 1">
                <pagination
                    [small]="true"
                    [numberOfPages]="clientPages.length"
                    [queryId]="queryId"
                    (onPageChange)="changePage('client', $event)"
                ></pagination>
            </div>
        </div>
        <div style="margin-top: 25px">
            <cmx-listing
                height="calc(50vh - 160px)"
                searchPlaceholder="Buscar Grupo"
                [instantSearch]="false"
                [list]="groups"
                [hasAddButton]="true"
                (onNewItem)="clearUserForm('group')"
                (onSelect)="selectPermissionGroup($event)"
                (onSearch)="filterClientGroupByName($event)"
            ></cmx-listing>
            <div *ngIf="groupPages.length > 1">
                <pagination
                    [small]="true"
                    [numberOfPages]="groupPages.length"
                    [queryId]="queryId"
                    (onPageChange)="changePage('group', $event)"
                ></pagination>
            </div>
        </div>
    </div>
</div>
<div class="admItem">
    <a *ngIf="!newDomain && currentUser$.has('API_ADMIN_CLIENTS_GROUPS')" (click)="admService.openDeleteModal(modal)" class="deleteBtn"><i class="la la-trash"></i></a>
    <span *ngIf="clientDomain && newDomain && currentUser$.has('API_ADMIN_CLIENTS_GROUPS')" class="admTitle">Adicionar Cliente</span>
    <span *ngIf="clientDomain && !newDomain" class="admTitle">Editar {{ selectedClient.name }}</span>
    <span *ngIf="!clientDomain && newDomain && currentUser$.has('API_ADMIN_CLIENTS_GROUPS')" class="admTitle">Adicionar Grupo</span>
    <span *ngIf="!clientDomain && !newDomain" class="admTitle">Editar {{ selectedGroup.name }}</span>
    <hr class="lineAddEdit" />
    <div class="admForm">
        <label>Nome Completo</label>
        <div *ngIf="clientDomain">
            <input class="admInput" type="text" [(ngModel)]="selectedClient.name" (keyup)="identifyChange('client')" />
            <label>Código</label>
            <div *ngIf="newDomain">
                <input
                    class="admInput"
                    type="text"
                    [(ngModel)]="selectedClient.code"
                    (keyup)="identifyChange('client')"
                />
            </div>
            <div *ngIf="!newDomain">
                <input disabled class="admInput" type="text" [(ngModel)]="selectedClient.code" />
            </div>
            <div *ngIf="selectedClient.documentNumberType === null">
                <label style="width: 100%">Documento</label>
                <div class="documentContainer">
                    <input
                        *ngIf="selectedDocument !== 'inter'"
                        class="admInput"
                        type="text"
                        style="width: 50%; margin-top: 1px"
                        [(ngModel)]="selectedClient.documentNumber"
                        (keydown)="maskDocumentNumber()"
                        (keyup)="maskDocumentNumber()"
                        maxlength="{{ getDocumentNumberMaxLength() }}"
                    />
                    <input
                        disabled
                        *ngIf="selectedDocument === 'inter'"
                        class="admInput"
                        type="text"
                        style="width: 50%; margin-top: 1px"
                    />
                    <div class="radioContainer">
                        <div class="admRadio" style="margin-top: 0px">
                            <label style="margin-top: 0px">CNPJ</label>
                            <input
                                class="admRadioItem"
                                value="cnpj"
                                name="type"
                                type="radio"
                                [(ngModel)]="selectedDocument"
                                (change)="selectDocumentNumberType()"
                            />
                        </div>
                        <div class="admRadio" style="margin-top: 0px">
                            <label style="margin-top: 0px">CPF</label>
                            <input
                                class="admRadioItem"
                                value="cpf"
                                name="type"
                                type="radio"
                                [(ngModel)]="selectedDocument"
                                (change)="selectDocumentNumberType()"
                            />
                        </div>
                        <div class="admRadio" style="margin-top: 0px">
                            <label style="margin-top: 0px">Internacional</label>
                            <input
                                class="admRadioItem"
                                value="inter"
                                name="type"
                                type="radio"
                                [(ngModel)]="selectedDocument"
                                (change)="selectDocumentNumberType()"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="selectedClient.documentNumberType">
                <label>{{ selectedClient.documentNumberType }}</label>
                <input
                    class="admInput"
                    type="text"
                    (keyup)="maskDocumentNumber()"
                    (keydown)="maskDocumentNumber()"
                    [(ngModel)]="selectedClient.documentNumber"
                    maxlength="{{ getDocumentNumberMaxLength() }}"
                />
            </div>

            <label>Grupo Atribuído</label>
            <cmx-dropdown
                [styles]="{ width: '250px' }"
                size="md"
                name="clients"
                [data]="dropdowns.groups.options"
                [(ngModel)]="dropdowns.groups.selected"
                (onScroll)="scrollList($event)"
                [settings]="dropdownSettingsGroups"
                (onChange)="identifyChange('client')"
            ></cmx-dropdown>
            <div class="leasingMarkContainer">
                <input name="leasing" type="checkbox" (click)="toogleLeasing()" [(ngModel)]="selectedClient.leasing" />
                <label>Leasing</label>
                <span *ngIf="isVisibleImps()" (click)="openLeasingImpsModal()" class="leasingImpsBtn">ver imp's</span>
            </div>
        </div>

        <!-- Group form -->
        <div *ngIf="!clientDomain">
            <input
                *ngIf="newDomain"
                class="admInput"
                type="text"
                [(ngModel)]="selectedGroup.name"
                (keyup)="identifyChange('group')"
            />
            <input
                disabled
                *ngIf="!newDomain"
                class="admInput"
                type="text"
                [(ngModel)]="selectedGroup.name"
                (keyup)="identifyChange('group')"
            />

            <label>Domínio</label>
            <div>
                <!-- -->
                <div class="client-group-domain">
                    <input
                        class="admInput domain-input"
                        type="text"
                        [(ngModel)]="selectedGroup.domain"
                        (keypress)="domainChangeKeyPress($event)"
                        (ngModelChange)="domainChange($event)"

                    />
                    <spam class="host">.comexport.com.br</spam>
                </div>
            </div>

            <div class="img mb-2">
                <label style="width: 100%">Imagens</label>
                <div class="d-flex justify-content-between">
                    <div class="row fileRow">
                        <div class="col-md-auto">
                            <cmx-img-upload
                                #logoUpload
                                id="adm-group-logo-cmx-img-upload"
                                [title]="'Logotipo'"
                                [accept]="['image/png']"
                                [tooltipOptions]="{ show: true, position: 'right' }"
                                [required]="false"
                                [preview]="imagesPreview.logo"
                                (onChange)="logo = $event; updatePreviewLogo($event)"
                                [assets]="true"
                                [disable]="!(selectedGroup.domain)"
                                height="80px"
                                width="80px"
                        ></cmx-img-upload>
                        </div>
                        <!-- [disabled]="true" -->
                        <div class="col-md-auto">
                            <cmx-img-upload
                                #loginBackgroundUpload
                                id="adm-group-login-cmx-img-upload"
                                [title]="'Tela inicial'"
                                [accept]="['image/jpg', 'image/jpeg']"
                                [tooltipOptions]="{ show: true, position: 'right' }"
                                [required]="false"
                                [preview]="imagesPreview.loginBackground"
                                (onChange)="loginBackground = $event; updatePreviewBackground($event)"
                                [assets]="true"
                                height="80px"
                                [disable]="!(selectedGroup.domain)"
                                width="120px"
                        ></cmx-img-upload>
                        </div>
                        <div class="col-md-auto image-warn" *ngIf="!newDomain">
                            <cmx-form-message message="As alterações das imagem podem levar até 5 minutos para refletir para o cliente." type="warning" size="auto"></cmx-form-message>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="!newDomain">
                <label>Clientes Atribuídos</label>
                <div class="box" style="height: 100px">
                    <table class="intern">
                        <tr *ngFor="let client of clientsInGroup; let i = index; trackBy: trackByFn">
                            <td>{{ client.name }}</td>
                        </tr>
                    </table>
                </div>
            </div>

        </div>
        <p class="success create hidden"
            ><i (click)="admService.hideSuccess(modal)" class="la la-close"></i>Criado com sucesso!</p
        >
        <p class="success edit hidden"
            ><i (click)="admService.hideSuccess(modal)" class="la la-close"></i>Editado com sucesso!</p
        >
        <p class="success delete hidden"
            ><i (click)="admService.hideSuccess(modal)" class="la la-close"></i>Deletado com sucesso!</p
        >
        <p class="error name hidden"
            ><i (click)="admService.hideError(modal)" class="la la-close"></i>Preencha o campo Nome.</p
        >
        <p class="error code hidden"
            ><i (click)="admService.hideError(modal)" class="la la-close"></i>Preencha o campo Código.</p
        >
        <p class="error clientCode hidden"
            ><i (click)="admService.hideError(modal)" class="la la-close"></i>Já existe um Cliente com esse código</p
        >
        <p class="error clientName hidden"
            ><i (click)="admService.hideError(modal)" class="la la-close"></i>Já existe um Cliente com esse nome</p
        >
        <p class="error groupName hidden"
            ><i (click)="admService.hideError(modal)" class="la la-close"></i>Já existe um Grupo com esse nome</p
        >
        <p class="error groupWithClient hidden"
            ><i (click)="admService.hideError(modal)" class="la la-close"></i>Existem Clientes vinculados à esse
            Grupo</p
        >
        <p class="error document hidden"
            ><i (click)="admService.hideError(modal)" class="la la-close"></i>Preencha um Documento.</p
        >
        <p class="error group hidden"
            ><i (click)="admService.hideError(modal)" class="la la-close"></i>Selecione um Grupo.</p
        >
        <p class="error trader hidden"
            ><i (click)="admService.hideError(modal)" class="la la-close"></i>Selecione um Trader.</p
        >
        <p class="error start hidden"
            ><i (click)="admService.hideError(modal)" class="la la-close"></i>Selecione uma Data Inicial.</p
        >
        <p class="error end hidden"
            ><i (click)="admService.hideError(modal)" class="la la-close"></i>Selecione uma Data Final.</p
        >
        <p class="error err hidden"
            ><i (click)="admService.hideError(modal)" class="la la-close"></i>Algo deu errado.</p
        >
    </div>
    <div>
        <hr class="lineAddEditBorder" />
        <button *ngIf="!newDomain && !clientDomain && currentUser$.has('API_ADMIN_CLIENTS_GROUPS')" (click)="openLogModal()" class="commission">Comissão Trader</button>
        <button *ngIf="!clientDomain" (click)="openPreview()" class="preview">Pré‑Visualização</button>
        <button *ngIf="newDomain && currentUser$.has('API_ADMIN_CLIENTS_GROUPS')" (click)="createDomain()" class="add">Adicionar</button>
        <button *ngIf="!clientDomain && !newDomain" (click)="editDomain()" class="edit">Salvar</button>
        <button *ngIf="clientDomain  && !newDomain && currentUser$.has('API_ADMIN_CLIENTS_GROUPS')" (click)="editDomain()" class="edit">Salvar</button>
    </div>
</div>
<div class="deleteModal hidden">
    <p *ngIf="clientDomain">Deseja deletar o cliente {{ selectedClient.name }} ?</p>
    <p *ngIf="!clientDomain">Deseja deletar o grupo {{ selectedGroup.name }} ?</p>
    <div class="deleteButtons">
        <button (click)="confirmDelete()" class="delete">Deletar</button>
        <button (click)="admService.closeDeleteModal(modal)" class="cancel">Cancelar</button>
    </div>
</div>

<div class="leasingBackground hidden"></div>
<div class="leasingImpsModal hidden">
    <i (click)="closeLeasingImpsModal()" id="close" class="la la-close"></i>
    <div class="leasingImpsContent">
        <div class="admItem left">
            <span class="admTitle">IMPs de {{ selectedClient.name }}</span>
            <hr />
            <div *ngIf="!asyncLeasingImps">
                <loader></loader>
            </div>
            <div *ngIf="asyncLeasingImps" class="table">
                <table>
                    <tr>
                        <th>IMP</th>
                        <th>Diretor</th>
                        <th>Trader</th>
                        <th style="text-align: right">Arrendatário</th>
                    </tr>
                    <tr
                        (click)="selectLeasingImp(imp, $event, i)"
                        *ngFor="let imp of leasingImps; let i = index; let first = first; trackBy: trackByFn"
                        [class.selected]="first"
                    >
                        <td>{{ imp.imp }}</td>
                        <td *ngIf="imp.leaseHolderName !== '' && imp.leaseHolderName !== null">{{
                            imp.leaseHolderDirectorName
                        }}</td>
                        <td *ngIf="imp.leaseHolderName === '' || imp.leaseHolderName === null">{{
                            imp.directorName
                        }}</td>
                        <td *ngIf="imp.leaseHolderName !== '' && imp.leaseHolderName !== null">{{
                            imp.leaseHolderTraderName
                        }}</td>
                        <td *ngIf="imp.leaseHolderName === '' || imp.leaseHolderName === null">{{
                            imp.traderUserBiName
                        }}</td>
                        <td style="text-align: right">
                            {{ imp.leaseHolderName }}
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="admItem right">
            <span class="admTitle">Atribuir Arrendatário</span>
            <hr />
            <div *ngIf="asyncLeasingImps" class="admForm">
                <label>Arrendatário</label>
                <input class="admInput" type="text" [(ngModel)]="selectedLeasingImp.leaseHolderName" />
                <label>Trader Atribuído</label>
                <cmx-dropdown
                    id="directors"
                    name="clients"
                    [data]="dropdowns.leasingTraders.options"
                    [(ngModel)]="dropdowns.leasingTraders.selected"
                    (onScroll)="scrollList($event)"
                    [settings]="dropdownSettingsTraders"
                    (onChange)="selectLeasingTrader()"
                ></cmx-dropdown>
                <div
                    *ngIf="
                        selectedLeasingDirector?.name !== null &&
                        selectedLeasingDirector?.name !== '' &&
                        selectedLeasingDirector?.name !== undefined
                    "
                >
                    <label>Diretor Atribuído</label>
                    <div class="leasingDirector">{{ selectedLeasingDirector?.name }}</div>
                </div>
                <button (click)="saveLeaseHolder()" class="edit">Salvar</button>
                <p class="success leaseHolder hidden"
                    ><i (click)="admService.hideError(modal)" class="la la-close"></i>Imp Atualizada!</p
                >
                <p class="error leaseHolder hidden"
                    ><i (click)="admService.hideError(modal)" class="la la-close"></i>Preencha o Arrendatário.</p
                >
                <p class="error leaseTrader hidden"
                    ><i (click)="admService.hideError(modal)" class="la la-close"></i>Selecione um Trader.</p
                >
            </div>
        </div>
    </div>
</div>

<cmx-custom-dialog
    id="adm-domain-client-group-dialog-preview"
    title="Pré‑Visualização"
    animation="right-left"
    [internalFade]="true"
    [closeButton]="true"
    [overlay]="true"
    [widthModal]="75"
    (modal-closed)="closePreview()"
>
<ng-container cmx-custom-dialog-body>
    <div class="login-area-preview" *ngIf="preview.logo && preview.loginBackground">
        <div class="login-container">
            <div class="form-container">
                <div class="header-form">
                    <h1>
                        <img [src]="preview.logo" class="logo" />
                    </h1>
                </div>
                <div class="login-form">
                    <div class="login-credential">
                        <div class="form-group">
                            <input type="email" class="input-text-email" [placeholder]="'auth.email' | translate" autocomplete="off">
                        </div>
                        <div class="form-group">
                            <input type="password" class="input-text-password" [placeholder]="'auth.password' | translate" autocomplete="off">
                        </div>
                        <div class="form-group align-right actions-form">
                            <button type="button" class="button-recover-password">
                                {{ 'auth.forgotPassword' | translate }}
                            </button>
                            <button #enterToLogin type="submit" class="button-submit">
                                Entrar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div [style.background-image]="'url(' + preview.loginBackground + ')'" class="bg-container" ></div>
    </div>

</ng-container>


</cmx-custom-dialog>
<cmx-custom-dialog
    [id]="'adm-domain-client-group-dialog-log'"
    title="Comissão Trader — {{ selectedGroup.name }}"
    animation="right-left"
    [internalFade]="true"
    [closeButton]="true"
    [overlay]="true"
    [widthModal]="43"
    (modal-closed)="closeLogModal()"
>
    <ng-container cmx-custom-dialog-body>
        <div class="log-modal-container" *ngIf="showCommissionModal">
            <div class="input-content mt-2 mb-3">
                <div class="p-field w-60">
                    <label>Trader<span class="required">*</span></label>
                    <cmx-dropdown
                    id="directors"
                    name="clients"
                    [data]="dropdowns.traders.options"
                    [(ngModel)]="dropdowns.traders.selected"
                    (onScroll)="scrollList($event)"
                    [settings]="dropdownSettingsTraders"
                    (onChange)="identifyChange('group')"
                    ></cmx-dropdown>
                </div>
            </div>
            <div class="input-content" [formGroup]="form" novalidate="novalidade" autocomplete="off">
                <div class="p-field w-20">
                    <label>Comissão<span class="required">*</span></label>
                    <input
                        pInputText
                        type="text"
                        formControlName="value"
                        suffix="%"
                        mask="separator.2"
                        thousandSeparator="."
                        decimalMarker=","
                        separatorLimit="1000"
                        [allowNegativeNumbers]="false"
                    />
                </div>
                <div class="p-field">
                    <label>Início Vigência<span class="required">*</span></label>
                    <cmx-filter-datepicker
                        id="formStartDate"
                        [type]="'single-month'"
                        alone="'true'"
                        [visual]="'big'"
                        [periodType]="'month'"
                        [empty-dates]="true"
                        (onChangeDates)="dateChanged($event.startDate, 'start')"
                        [clearDates]="clearDates"
                    ></cmx-filter-datepicker>
                </div>
                <div class="p-field">
                    <label>Fim Vigência</label>
                    <cmx-filter-datepicker
                        id="formEndDate"
                        [type]="'single-month'"
                        alone="'true'"
                        [visual]="'big'"
                        [periodType]="'month'"
                        [empty-dates]="true"
                        (onChangeDates)="dateChanged($event.startDate, 'end')"
                        [clearDates]="clearDates"
                    ></cmx-filter-datepicker>
                </div>
                <div class="p-field">
                    <button class="btn save" (click)="verifyFormComission(false)" [disabled]="verifyBtnSave()">
                        Salvar
                    </button>
                </div>
            </div>
            <div class="input-content mt-2 mb-3">
                <div class="p-field w-40">
                    <label>Replicar Para</label>
                    <cmx-dropdown
                        id="clients-multiselect"
                        name="clients"
                        [(ngModel)]="dropdowns.clients.selected"
                        [data]="dropdowns.clients.options"
                        [settings]="dropdownSettings.clientGroups"
                    ></cmx-dropdown>
                </div>
                <div class="p-field">
                    <button class="btn reply" (click)="verifyFormComission(true)" [disabled]="verifyBtnReply()">
                        Replicar
                    </button>
                </div>
            </div>
            <hr />

            <loader *ngIf="asyncLog"></loader>

            <div class="log-container-grid" *ngIf="!asyncLog">
                <span class="noContent" *ngIf="!logRows?.length">
                    Sem dados históricos, cadastre a primeira comissão para este cliente.
                </span>

                <cmx-custom-grid
                    *ngIf="logRows?.length"
                    id="adm-domain-client-group-log-grid"
                    [columns]="logColumns"
                    [rows]="logRows"
                    [options]="{ forceFitColumns: true }"
                    [styles]="{ height: 'calc(100vh - 410px)' }"
                ></cmx-custom-grid>
            </div>
        </div>
    </ng-container>
</cmx-custom-dialog>

<cmx-confirmation-modal
    id="modalDeleteDocument"
    [question]="commissionAlert"
    labelAction="Confirmar"
    confirmType="danger"
    [hidden]="!showCommissionAlertModal"
    (onConfirm)="saveCommission()"
    (onCancel)="showCommissionAlertModal = false; hasReply = false"
></cmx-confirmation-modal>
