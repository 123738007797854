export const PROJECT_PRICING_MULTIBUTTONS = {
    internal_single: [
        { code: 'insert_propose', name: 'Inserir Proposta', color: '#15519b', icon: 'file_upload', status: 'enable' },
        { code: 'responsible_users', name: 'Responsáveis', color: '#15519b', icon: 'manage_accounts', status: 'enable' },
        { code: 'cancel', name: 'Cancelar', color: '#a70000', icon: 'delete_outline', status: 'enable' },
    ],
    internal_single_child: [
        { code: 'insert_propose', name: 'Inserir Proposta', color: '#15519b', icon: 'file_upload', status: 'enable' },
    ],
    internal_single_updatable: [
        { code: 'updatable_propose', name: 'Editar Proposta', color: '#227ae6', icon: 'edit_note', status: 'enable' },
        { code: 'responsible_users', name: 'Responsáveis', color: '#15519b', icon: 'manage_accounts', status: 'enable' },
        { code: 'cancel', name: 'Cancelar', color: '#a70000', icon: 'delete_outline', status: 'enable' },
    ],
    internal_single_child_updatable: [
        { code: 'updatable_propose', name: 'Editar Proposta', color: '#227ae6', icon: 'edit_note', status: 'enable' },
    ],
    internal_multiple: [
        { code: 'open_proposes', name: 'Ver Solicitações', color: '#15519b', icon: 'list_alt', status: 'enable' },
        { code: 'responsible_users', name: 'Responsáveis', color: '#15519b', icon: 'manage_accounts', status: 'enable' },
        { code: 'cancel', name: 'Cancelar', color: '#a70000', icon: 'delete_outline', status: 'enable' },
    ],
    external_silgle_child_historic: [
        { code: 'ask_revision_historic', name: 'Solicitar Revisão', color: '#15519b', icon: 'record_voice_over', status: 'enable' },
    ],
    external_silgle_child_historic_multiple: [
        { code: 'open_proposes', name: 'Ver Solicitações', color: '#15519b', icon: 'list_alt', status: 'enable' },
    ],
    external_single: [
        { code: 'ask_revision', name: 'Solicitar Revisão', color: '#15519b', icon: 'record_voice_over', status: 'enable' },
        { code: 'approve', name: 'Solicitar PO', color: 'green', icon: 'done', status: 'enable' },
        { code: 'cancel', name: 'Cancelar', color: '#a70000', icon: 'delete_outline', status: 'enable' },
    ],
    external_edit_single_child: [
        { code: 'edit_proposal', name: 'Editar Solicitação', color: '#227ae6', icon: 'edit_note', status: 'enable' },
        { code: 'approve', name: 'Solicitar PO', color: 'green', icon: 'done', status: 'enable' },
    ],
    external_edit_request: [
        { code: 'edit_proposal', name: 'Editar Solicitação', color: '#227ae6', icon: 'edit_note', status: 'enable' },
        { code: 'approve', name: 'Solicitar PO', color: 'green', icon: 'done', status: 'enable' },
        { code: 'cancel', name: 'Cancelar', color: '#a70000', icon: 'delete_outline', status: 'enable' },
    ],
    external_single_child: [
        { code: 'ask_revision', name: 'Solicitar Revisão', color: '#15519b', icon: 'record_voice_over', status: 'enable' },
        { code: 'approve', name: 'Solicitar PO', color: 'green', icon: 'done', status: 'enable' },
    ],
    external_multiple: [
        { code: 'open_proposes', name: 'Ver Propostas', color: '#15519b', icon: 'list_alt', status: 'enable' },
        { code: 'cancel', name: 'Cancelar', color: '#a70000', icon: 'delete_outline', status: 'enable' },
    ],
}
