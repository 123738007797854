import { IGridColumn } from "app/shared/components/cmx-custom-grid/interfaces";

export const DATE_NOTIFICATION_COLUMNS: IGridColumn[] = [{
        field: 'label',
        name: 'page::config::notifications.gridDates::date',
        width: 400,
        isFilterable: true,
        webFilter: true,
    }, {
        field: 'add',
        name: 'page::config::notifications.gridDates::inclusion',
        width: 110,
        formatterType: 'switch',
        headerCssClass: 'grid-header align-center',
    }, {
        field: 'update',
        name: 'page::config::notifications.gridDates::modification',
        width: 110,
        formatterType: 'switch',
        headerCssClass: 'grid-header align-center',
    }, 
    {
        field: 'properties',
        name: 'page::config::notifications.gridDates::processes',
        width: 170,
        formatterType: 'switch',
        formatterData: {
            neutralPoint: true,
            leftValue: 'SPECIFIC',
            rightValue: 'ALL',
        },
        informative: 'page::config::notifications.gridDates::informative',
        informativeDirection: 'left'
    }, 
    {
        field: 'imps',
        name: 'page::config::notifications.gridDates::imps',
        width: 100,
        formatterType: 'multiRound',
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
    },
    {
        field: 'frequency',
        name: 'page::config::notifications.gridDates::periodicity',
        width: 200,
        formatterType: 'dropdown',
    }
]

export const SPECIFIC_PROCESSES_COLUMNS: IGridColumn[] = [
    {
        field: 'imp',
        name: 'IMP',
        width: 150,
        isFilterable: false,
      },
      {
        field: 'action',
        name: 'page::config::notifications.gridDates::action',
        width: 100,
        formatterType: 'multiButtons',
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
      }
]
