import { DashComponent } from './dashComponent.model';

export const OPERATIONAL_DASH_COMPONENT: DashComponent[] = [
    {
        component: 'OperationalConsolidationComponent',
        expansive: false,
        name: 'Consolidação Logística',
        permission: 'API_OPERATIONAL_CONSOLIDATION',
        size: 100,
        description: '',
        titleKey: ''
    },
    {
        component: 'ForecastByStatusComponent',
        expansive: false,
        name: 'Previsão de Faturamento',
        permission: 'API_FORECAST_BY_STATUS',
        size: 100,
        description: 'Previsão de Faturamento por status',
        titleKey: ''
    },
    {
        component: 'DocumentConsolidationComponent',
        expansive: false,
        name: 'Consolidação de Documentos',
        permission: 'API_DOCUMENT_CONSOLIDATION',
        size: 100,
        description: '',
        titleKey: ''
    },
    {
        component: 'TopDIRegistrationComponent',
        expansive: false,
        name: "Top DI's Registradas",
        permission: 'API_TOP_DI_REGISTRATION',
        size: 50,
        description: '',
        titleKey: ''
    },
    {
        component: 'TopLIRegistrationComponent',
        expansive: false,
        name: "Top LI's Registradas",
        permission: 'API_TOP_LI_REGISTRATION',
        size: 50,
        description: '',
        titleKey: ''
    },
    {
        component: 'OperationalAlertsComponent',
        expansive: false,
        name: 'Alertas operacionais',
        permission: 'API_OPERATIONAL_ALERTS',
        size: 100,
        description: '',
        titleKey: ''
    },
    {
        component: 'MovementByModalComponent',
        expansive: false,
        name: 'Movimentação',
        permission: 'API_OPERATIONAL_ALERTS',
        size: 100,
        description: '',
        titleKey: ''
    },
];

export const DASHBOARD_COMPONENTS = [
    {
        component: 'InvoicesComponent',
        description: 'dashboard::invoices.description',
        titleKey: 'dashboard.title::invoices',
    },
    {
        component: 'ContainerTrackingWidgetComponent',
        description: 'dashboard::containerTracking.description',
    },
    {
        component: 'ExchangeComponent',
        description: 'dashboard::exchange.description',
        titleKey: 'dashboard.title::exchange',
    },
    {
        component: 'ChannelComponent',
        description: 'dashboard::channelStatus.description',
        titleKey: 'dashboard.title::channelStatus',
    },
    {
        component: 'CrProcessesTableComponent',
        description: 'dashboard::crProcessesTable.description',
        titleKey: 'dashboard.title::crProcessesTable',
    },
    {
        component: 'InvoicesProcessesComponent',
        description: 'dashboard::invoicesProcesses.description',
    },
    {
        component: 'CrProcessesComponent',
        description: 'dashboard::crProcesses.description',
    },
    {
        component: 'ImpStatusComponent',
        description: 'dashboard::impStatus.description',
        titleKey: 'dashboard.title::impStatus',
    },
    {
        component: 'SkuStatusComponent',
        description: 'dashboard::skuStatus.description',
    },
    {
        component: 'ChassiStatusComponent',
        description: 'dashboard::chassiStatus.description',
    },
    {
        component: 'LeadTimeComponent',
        description: 'dashboard::leadTime.description',
        titleKey: 'dashboard.title::leadTime',
    },
    {
        component: 'LiLeadTimeComponent',
        description: 'dashboard::liLeadTime.description',
        titleKey: 'dashboard.title::liLeadTime',
    },
    {
        component: 'BoardingStatusComponent',
        description: 'dashboard::boardingStatus.description',
        titleKey: 'dashboard.title::boardingStatus',
    },
    {
        component: 'LogisticCostsComponent',
        description: 'dashboard::logisticCosts.description',
        titleKey: 'dashboard.title::logisticCosts',
    },
    {
        component: 'SlaStatusComponent',
        description: 'dashboard::slaStatus.description',
        titleKey: 'dashboard.title::slaStatus',
    },
    {
        component: 'SlaConsolidationComponent',
        description: 'dashboard::slaConsolidation.description',
        titleKey: 'dashboard.title::slaConsolidation',
    },
    {
        component: 'StatusConsolidationComponent',
        description: 'dashboard::statusConsolidation.description',
        titleKey: 'dashboard.title::statusConsolidation',
    },
    {
        component: 'CostDistributionComponent',
        description: 'dashboard::distributedCosts.description',
        titleKey: 'dashboard.title::distributedCosts',
    },
    {
        component: 'NewTrafficMapComponent',
        description: 'dashboard::newTrafficMap.description',
        titleKey: 'dashboard.title::newTrafficMap',
    },
    {
        component: 'StockComponent',
        titleKey: 'dashboard.title::nationalizedVehicles',
    },
];
