import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { CompleterService, CompleterData } from 'ng2-completer';
import { UserService } from 'app/auth/_services';
import { UtilsService } from '#services/_utils/utils.service';
import { DownloadService } from '#services/_download/download.service';
import { FinanceExportService } from '#services/_finance-export/finance-export.service';
import { CustomDialogService } from 'app/shared/components/cmx-custom-dialog/custom-dialog.service';
import * as _ from 'lodash';
import { FilterStateService } from '#services/_filters/filter-state.service';
import { IPageWrapperTitle } from 'app/shared/components/cmx-page-wrapper/page-wrapper-interface';
import { ITopButton } from 'app/shared/components/cmx-page-wrapper/interfaces/top-button.interface';
import { CostsService } from '../consolidated/costs.service';
import { DETAIL_COSTS_MODAL } from './detail-costs-modal.constant';
import { PageTabControlService } from '#services/_page-tab-control/page-tab-control.service';
import { CostsDropdownService } from '../filters/costs-dropdown.service';
import { ReportPreferencesService } from '#services/_report-preferences/report-preferences.service';
import { forkJoin } from 'rxjs'
import { DINAMIC_ATRIBUTE_VALUES, DINAMIC_GENERAL_MONETARY_VALUES } from './constants/dinamic-data-values.constants';
import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { CmxDropdownService } from 'app/shared/components/cmx-dropdown/cmx-dropdown.service';
import { DROPDOWN_SETTINGS } from 'app/shared/constants/dropdown-settings.constants';
import { UserCacheService } from '#services/_user/app-user-cache.service';

@Component({
    selector: 'costs-detailed',
    templateUrl: './costs-detailed.component.html',
    styleUrls: ['./costs-detailed.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [CostsDropdownService],
})
export class DetailedCostsComponent {

    dropdownSettings = _.cloneDeep(DROPDOWN_SETTINGS);
    titleComponent: IPageWrapperTitle = { title: 'Custos - Detalhamento' }
    topButtonList: ITopButton[] = [{
        code: 'download', name: 'Download', leftIcon: 'file_download'
    }, {
        code: 'datamart', name: 'Datamart', leftIcon: 'source'
    }, {
        code: 'customize', name: 'Personalizar', leftIcon: 'dashboard', rightIcon: 'add'
    }];

    moment = moment;
    subscription;
    editableElements = ['#DetailCosts', 'pagination'];
    asyncAnaliticCost = true;
    categoryName = '';
    offset = 0;
    pages = [];
    queryId = Math.random();
    asyncDetailCosts = true;
    currentUser;
    automotivefilterMode;
    totalItems;
    selectedImp;
    downloadArchives = [];
    metadataColumns: object = { };
    detailCostsTabs = [];
    selectedGrid: boolean = false;
    detailDinamicDataOpen: boolean = true;
    options: { modelList: DropdownOption[] } = { modelList: [] };
    selectedModel;
    linkOptions = [];

    selectedModalData;
    gridModalMode: boolean = false;
    modalRows = [];
    widthModal: string = '400px';
    modalConfig = _.cloneDeep(DETAIL_COSTS_MODAL);

    dataView;
    previousGridRows = null;
    rows = [];
    completerColumnsData: CompleterData;
    completerSelectedValue: string;
    columns = [];
    contentData;
    filterState: any = this._filterStateService.getCostsFilter();

    gridOptions = {
        defaultColumnWidth: 120,
        editable: false,
        enableCellNavigation: true,
        enableTextSelectionOnCells: true,
        rowHeight: 35,
        forceFitColumns: false,
        enableColumnReorder: true,
        useCategoryGrouping: true,
    }

    topButtonSubscription;
    rightButtonSubscription;
    switchSubscrition;
    firstTopButtonTrigger: boolean = true;
    firstRightButtonTrigger: boolean = true;
    firstSwitchTrigger: boolean = true;

    private _atualGroups = []

    constructor(
        public pageTabControlService: PageTabControlService,
        public costsDropdownService: CostsDropdownService,
        public costsService: CostsService,
        public router: Router,
        private _reportPreferencesService: ReportPreferencesService,
        private _financeExportService: FinanceExportService,
        private _customDialogService: CustomDialogService,
        private _filterStateService: FilterStateService,
        private _cmxDropdownService: CmxDropdownService,
        private _completerService: CompleterService,
        private _userCacheService: UserCacheService,
        private _downloadService: DownloadService,
        private _utilsService: UtilsService,
        private _userService: UserService,
        private _route: ActivatedRoute
    ) {
        this.costsService.detailedPage = true;
        this._verifyLinkOptionsEnabled();
    }

    ngOnDestroy() {
        if (this.subscription) { this.subscription.unsubscribe() }
        if (this.topButtonSubscription) { this.topButtonSubscription.unsubscribe() }
        if (this.rightButtonSubscription) { this.rightButtonSubscription.unsubscribe() }
        if (this.switchSubscrition) { this.switchSubscrition.unsubscribe() }
    }

    ngAfterViewInit() {
        forkJoin([
            this._reportPreferencesService.getDefaultReport('EXPENSES_DETAIL'),
            this._reportPreferencesService.getCustomReport('EXPENSES_DETAIL'),
        ]).subscribe((values) => {
            const [defaultValue, customValue] = values;
            this._reportPreferencesService.currentDefaultReport = _.cloneDeep(defaultValue);
            this._updateDatamartDropdown(customValue);
            this._verifyFistAcess(defaultValue, customValue);
        }, (error) => {
            this._reportPreferencesService.asyncValues = false;
        });
        setTimeout(() => {
            this.switchSubscrition = this.pageTabControlService.getSwitchChange().subscribe((type) => {
                this.firstSwitchTrigger ? this.firstSwitchTrigger = false : this.onSwitchTypeChange(type)
            });
            this.topButtonSubscription = this.pageTabControlService.topButtonSubject$.subscribe((button) => {
                this.firstTopButtonTrigger ? this.firstTopButtonTrigger = false : this.onTopButtonClick(button);
            });
            this.rightButtonSubscription = this.pageTabControlService.rightButtonSubject$.subscribe((button) => {
                this.firstRightButtonTrigger ? this.firstRightButtonTrigger = false : this.onRightButtonClick(button);
            });
            this._reportPreferencesService.costsSaveSubject.subscribe((save) => {
                this._reportPreferencesService.getCustomReport('EXPENSES_DETAIL').subscribe((customValue) => {
                    this._reportPreferencesService.saveList = this._reportPreferencesService.prepareSaveList(customValue.reports);
                    this._reportPreferencesService.rightButtonList = this._reportPreferencesService.prepareRightButtonList(customValue.reports)
                    this._reportPreferencesService.selectedRightButton = this._reportPreferencesService.prepareRightButton(_.cloneDeep(
                        save?.id ? save?.save : this._reportPreferencesService.rightButtonList[this._reportPreferencesService.rightButtonList.length - 1]
                    ));
                    this._reportPreferencesService.defineLeftAndRightData(
                        this._reportPreferencesService?.currentDefaultReport?.groups,
                        customValue.reports[0]?.groups
                    );
                    this._updateDatamartDropdown(customValue);
                    this._mergeColumns(save);
                    this._customDialogService.close('costs-customization-modal');
                })
            })
            this._userService.currentUser$.subscribe((user) => {
                this.currentUser = user;
            });
        });
    }

    identify(index: number): number {
        return index;
    }

    toggleUcSwitch({ name }) {
        this.filterState.setShowUc(name === 'c/ U.C' ? true : false);
        this.loadAnaliticCost(this.filterState.getAutomotiveMode(), null)
    }

    toggleDuplicatasSwitch({ name }) {
        this.filterState.setShowDuplicatas(name === 'c/ Duplicatas' ? true : false);
        this.columns = this.createColumns(this._reportPreferencesService.leftData, null);
        this.filterState.updatePreference();
    }

    onTopButtonClick(button) {
        if (!button) return;
        if (button.code === 'costs-detailed-download') {
            this.costsService.exportCosts('detail');
        } else if (button.code === 'costs-detailed-datamart') {
            this.costsService.datamartFilters = this.costsService.determineDatamartFilters(this.currentUser);
            this._openDatamartModal();
        } else if (button.code === 'costs-detailed-customize') {
            this._customDialogService.open('costs-customization-modal')
            setTimeout(() => { this.costsService.customizeAsync = false }, 100);
        }
    }

    onRightButtonClick(save) {
        this.asyncDetailCosts = true;
        this._reportPreferencesService.selectedRightButton = save;
        setTimeout(() => { this._mergeColumns(save) }, 300);
    }

    datesChanged(dates) {
        this.filterState.setStartDate(dates.startDate);
        this.filterState.setEndDate(dates.endDate);
    }

    changePage(pageNumber) {
        $('#DetailCosts .grid-canvas').addClass('hidden');
        this.offset = pageNumber - 1;
        this.loadAnaliticCost(this.automotivefilterMode);
    }

    onSwitchTypeChange(event) {
        const type = event.name === 'NF' ? 'item' : event.name.toLowerCase()
        this.offset = 0;
        this.filterState.setContext(type);
        this.loadAnaliticCost(this.automotivefilterMode);
        this.filterState.updatePreference();
        this.checkDisabled([{ id: type }])
    }

    onLinkOptionsChange(option) {
        this.filterState.setPeriodicity(option?.id || '120');
        this.filterState.setInterval(option?.id === '120' ? '12' : '3');
        this.filterState.updatePreference();
    }

    checkDisabled(dropdownOptions) {
        return dropdownOptions.map((option) => {
            return {
                ...option,
                disabled: this.filterState.getDropdownOptionsBlocked() && option.id === 'sku' ? true : false,
            }
        })
    }

    getDropdownContext(context) {
        return this.costsDropdownService.options.imp.find((item) => item.id === context.toUpperCase())
    }

    selectColumn(selectedColumn: any): void {
        if (selectedColumn && selectedColumn.originalObject) {
            const id = selectedColumn.originalObject?.id?.replace(/\|/g, "*");
            const element = document.getElementById(id)?.offsetParent
            const getBoundingClientRect = element?.getBoundingClientRect();
            const currentPosition = $('#detail-costs-grid .slick-viewport')?.scrollLeft();
            const grid = $('#detail-costs-grid .slick-viewport');
            grid.stop();
            if (getBoundingClientRect?.left) {
                grid.animate({ scrollLeft: (currentPosition + getBoundingClientRect?.left - 40) }, 1000 );
            }
        }
    }

    loadAnaliticCost(automotivefilterMode, spotlight?) {
        this.closeSlickModal();
        this.asyncDetailCosts = true;
        this.automotivefilterMode = automotivefilterMode;
        this.asyncAnaliticCost = true;
        if (this.subscription) { this.subscription.unsubscribe() }
        this.completerSelectedValue = '';
        this.subscription = this.costsService.getDetailedCosts(this.filterState.getRequest(this.filterState.getAutomotiveMode(), true), this.offset).subscribe((analiticCosts) => {
            this.totalItems = analiticCosts.totalElements;
            this.pages = Array.apply(null, { length: analiticCosts.totalPages }).map(Number.call, Number);
            this.asyncAnaliticCost = false;
            this.contentData = analiticCosts.content[0];
            this._atualGroups = analiticCosts.content[0]?.metadata || [];
            this.columns = this.createColumns(this._reportPreferencesService.leftData, null);
            this._defineTabGroups();
            this.completerColumnsData = this._getCompleterColumnsData(this.columns);
            this.rows = this.extractRows(this.contentData && this.contentData.results);
            setTimeout(() => { this._defineCompleterSelectedValue() }, 1000);
            this.asyncDetailCosts = false;
        });
    }

    closeContextMenu(): void {
        $('#contextMenu').hide();
    }

    export(type: any, financeArchive?) {
        this.asyncDetailCosts = true;
        const options = this._downloadService.getSourceOptions();
        options.headers = new HttpHeaders({
            'X-Requested-Client-Groups': '',
            Accept: type === 'csv' ? 'text/csv' : type === 'xls' ? 'application/vnd.ms-excel' : 'application/zip',
        });
        if (financeArchive && financeArchive !== 'all') {
            this._financeExportService.exportArquive(financeArchive).subscribe(() => {
                this.asyncDetailCosts = false;
            });
        } else if (financeArchive === 'all') {
            this._financeExportService.exportZip(this.selectedImp).subscribe(() => {
                this.asyncDetailCosts = false;
            });
        }

        this.closeContextMenu();
    }

    onCellClick({ item, column, _multiButtom, element }): void {
        if (column === 'importationNumber') {
            const companyCode = item.companyCode ? item.companyCode.padStart(2, "0") : 0;
            window.open(`./supply-chain/importacao/${companyCode}/${item.importationNumber}`);
        }
        if (column === 'details') {
            this.detailDinamicDataOpen = true;
            this._defineDinamicData(item);
        }
        if (element[0].classList.contains('multiple')) {
            this._openMultipleModal(item, column);
        }
        if (column === 'download' && item.download) {
            this.selectedImp = this.automotivefilterMode === 'Desligado' ? item.importationNumber : item.chassisNumber;
            this.downloadArchives = item.download;
            $('#contextMenu').show();
        } else {
            this.closeContextMenu();
        }
    }

    onDinamicClick(event) {
        if (Array.isArray(event.value)) {
            this._openMultipleModal(this.rows[0], event.field)
        }
    }

    closeModal() {
        this.gridModalMode = false;
    }

    closeSlickModal() {
        this.detailDinamicDataOpen = false;
        this.rows = this.previousGridRows;
        this.selectedGrid = false;
    }

    private _verifyFistAcess(defaultValue, customValue) {
        if (this.filterState.getFirstAcess()) {
            this.costsService.getUtilCostColumns().subscribe((utilColumns) => {
                this._reportPreferencesService.currentUtilColumns = utilColumns;
                const defaultGroups = _.cloneDeep(defaultValue);
                let defaultColumns = this.costsService.determineUtilColumns(defaultGroups, utilColumns);
                const save = customValue.reports[0];
                save.groups = defaultColumns.groups;
                this._reportPreferencesService.setCustomReport(save).subscribe(() => {
                    this._determineColumns(defaultValue, customValue)
                    this.filterState.setFirstAcess(false);
                    this.filterState.updatePreference();
                }, (_error) => {
                    this._determineColumns(defaultValue, customValue)
                })
            }) 
        } else {
            this._determineColumns(defaultValue, customValue)
            this.costsService.getUtilCostColumns().subscribe((utilColumns) => {
                this._reportPreferencesService.currentUtilColumns = utilColumns;
            })
        }
    }

    private _determineColumns(defaultValue, customValue) {
        this._reportPreferencesService.saveList = this._reportPreferencesService.prepareSaveList(customValue.reports);
        this._reportPreferencesService.rightButtonList = this._reportPreferencesService.prepareRightButtonList(customValue.reports)
        this._reportPreferencesService.selectedRightButton = this._reportPreferencesService.prepareRightButton(
            this._reportPreferencesService.saveList[0] || null);
        this._reportPreferencesService.selectedSave = this._reportPreferencesService.saveList[0] || null;
        this._reportPreferencesService.defineLeftAndRightData(
            defaultValue?.groups,
            customValue.reports[0]?.groups
        );
        const context = this._route.snapshot.paramMap.get('subPage');
        this.filterState.setContext(context === 'nf' ? 'item' : context);
        this.loadAnaliticCost(this.filterState.getAutomotiveMode(), null)
        this._reportPreferencesService.asyncValues = false;
    }

    private _mergeColumns(save) {
        this._reportPreferencesService.selectedSave = { ...save, title: save?.title || save?.name };
        const defaultColumns = this._reportPreferencesService.currentDefaultReport.groups || [];
        this._reportPreferencesService.defineLeftAndRightData(defaultColumns, save.groups || []);
        this.columns = this.createColumns(this._reportPreferencesService.leftData, null);
        this.asyncDetailCosts = false;
    }

    private _defineCompleterSelectedValue() {
        let columnFound
        if (this.costsService.detailColumnTransition) {
            this.contentData.metadata.forEach((group) => {
                group.columns.forEach((column) => {
                    if (column.label === this.costsService.detailColumnTransition) {
                        columnFound = { name: column.label, id: column.field };
                    }
                })
            });
        } else if (this.costsService.detailGroupTransition) {
            this.contentData.metadata.forEach((group) => {
                if (group.name === this.costsService.detailGroupTransition) {
                    columnFound = { name: group.columns[0].label, id: group.columns[0].field };
                }
            });
        }
        this.selectColumn({ originalObject : columnFound })
    }

    private _defineTabGroups() {
        const groups = [...new Set(this.columns.map(column => column.groupName))].filter((e) => { return e != null && e != ''; });
        this.detailCostsTabs = groups.map((group) => { return { code: group, name: group, data: [] }; });
    }

    private _defineDinamicData(item) {
        this.detailCostsTabs.forEach((tab) => { tab.data = [] });
        this.columns.forEach((column) => {
            if (column.groupName) {
                const group = this.detailCostsTabs.find(group => group.code === column.groupName)
                if (group?.data) {
                    const title = column?.name.includes('Fator da Importação') ? 'Fator da Importação' : column?.name;
                    const value = Array.isArray(item[`${column.field}`]) && item[`${column.field}`].length === 1 ?
                        item[`${column.field}`][0][DINAMIC_ATRIBUTE_VALUES[column.field]] : item[`${column.field}`]
                    group.data.push({
                        ...column,
                        title,
                        formatter: 'multiple',
                        value: (typeof value === 'number' && column.groupName !== 'Dados Gerais') ||
                               (DINAMIC_GENERAL_MONETARY_VALUES.includes(column?.id) && column.groupName === 'Dados Gerais')      ? `${
                            this._utilsService.formatNumber(value, { style: 'currency', currency: 'BRL' })
                        }` : value,
                    });
                }
            }
        })
        this._openSlickModal(item, this.detailCostsTabs[0])
    }

    private _openSlickModal(item, tab) {
        if (!this.selectedGrid) {
            this.selectedGrid = true;
            this.previousGridRows = this.rows;
            this.rows = [item];
        }
    }

    private _openMultipleModal(item, column) {
        this.selectedImp = this.automotivefilterMode === 'Desligado' ? item.importationNumber : item.chassisNumber;
        if (DETAIL_COSTS_MODAL.hasOwnProperty(column)) {
            this.widthModal = _.cloneDeep(this.modalConfig[column]?.width);
            this.selectedModalData = _.cloneDeep(DETAIL_COSTS_MODAL[column]);
            this.modalRows = _.cloneDeep(item[DETAIL_COSTS_MODAL[column].data]) || [];
            this._customDialogService.open('detail-costs-custom-dialog');
            setTimeout(() => {
                this.gridModalMode = true;
            }, 50);
        } else {
            this.selectedModalData = { title: this.metadataColumns[column]
                || column?.replace(/\|/g, ' ')?.replace(/\_/g, ' ') || '' };
            this.modalRows = _.cloneDeep(item[column]) || [];
            this.gridModalMode = false;
            this._customDialogService.open('detail-costs-custom-dialog');
        }
    }

    private _getCompleterColumnsData(columns: any): any {
        const columnsData = _.cloneDeep(columns);
        columnsData.shift(); columnsData.shift();
        return this._completerService.local(columnsData.map((column: any) => {
            if (columns.id !== 'details' && columns.id !== 'download') {
                return {
                    id: `${column.id}`,
                    name: column?.autoHeaderName || column?.name,
                };
            }
        }).slice(1), 'name', 'name');
    }

    private createColumns(categories, spotlight) {
        this._modifyColumns(categories);
        let index = 1;
        const cols = [];
        cols.push({
            id: 0,
            name: '<i class="flaticon-download" style="font-size:15px"></i>',
            width: 45,
            field: 'download',
            formatterType: 'round',
            formatterData: { roundType: 'download', needValue: true, emptyBall: true },
        });

        cols.push({
            id: 'details',
            name: '<i class="material-icons" style="font-size:19px">notes</i>',
            width: 45,
            field: 'details',
            formatterType: 'round',
            formatterData: { roundType: 'details', needValue: false, emptyBall: true },
        });

        if (categories && categories.length) {
            this._includeWantedColumns(this._removeUnwantedColumns(categories)).forEach((columnGroup) => {
                (columnGroup.columns || []).forEach((column, columnIndex) => {
                    index++;
                    this._verifyMetadataColumn(column);
                    this._includeColumn(cols, column, columnGroup, false, false);

                    if (column.label === 'IMP') {
                        cols[cols.length - 1].formatterType = 'badge';
                        cols[cols.length - 1].formatterData = { classes: 'pointer hoverable' };
                        cols[cols.length - 1].width = 120;
                    }

                    if (column.label === 'Chassi') {
                        cols[cols.length - 1].formatterType = 'badge';
                        cols[cols.length - 1].width = 195;
                    }

                    if (column.label === 'SKU') {
                        cols[cols.length - 1].formatterType = 'multiple';
                        cols[cols.length - 1].formatterData = { attributeValue: 'code' };
                        cols[cols.length - 1].width = 195;
                    }

                    if (column.label === 'Ref. Cliente') {
                        cols[cols.length - 1].formatterType = 'default';
                        cols[cols.length - 1].cssClass = 'align-left';
                        cols[cols.length - 1].width = 125;
                    }

                    if (column.label === 'PO') {
                        cols[cols.length - 1].formatterType = 'multiple';
                        cols[cols.length - 1].formatterData = { attributeValue: 'purchaseOrderCode' };
                        cols[cols.length - 1].cssClass = 'align-left';
                        cols[cols.length - 1].width = 125;
                    }

                    if (column.label === 'Item PO') {
                        cols[cols.length - 1].formatterType = 'multiple';
                        cols[cols.length - 1].formatterData = { attributeValue: 'purchaseOrderItem' };
                        cols[cols.length - 1].cssClass = 'align-left';
                        cols[cols.length - 1].width = 125;
                    }

                    if (column.label === 'Fornecedor') {
                        cols[cols.length - 1].formatterType = 'multiple';
                        cols[cols.length - 1].formatterData = { attributeValue: 'vendorName' };
                        cols[cols.length - 1].cssClass = 'align-left';
                        cols[cols.length - 1].width = 250;
                    }

                    if (column.label === 'País de Origem') {
                        cols[cols.length - 1].formatterType = 'multiple';
                        cols[cols.length - 1].formatterData = { attributeValue: 'countryFrom' };
                        cols[cols.length - 1].cssClass = 'align-left';
                        cols[cols.length - 1].width = 250;
                    }

                    if (column.label === 'Desc. Produto') {
                        cols[cols.length - 1].formatterType = 'multiple';
                        cols[cols.length - 1].formatterData = { attributeValue: 'description' };
                        cols[cols.length - 1].cssClass = 'align-left';
                        cols[cols.length - 1].width = 400;
                    }

                    if (column.label === 'Quantidade') {
                        cols[cols.length - 1].formatterType = 'multiple';
                        cols[cols.length - 1].formatterData = { attributeValue: 'quantity' };
                        cols[cols.length - 1].width = 125;
                    }

                    if (column.field === 'line') {
                        cols[cols.length - 1].formatterType = 'multiple';
                        cols[cols.length - 1].formatterData = { attributeValue: 'line' };
                        cols[cols.length - 1].width = 152;
                    }

                    if (column.field === 'family') {
                        cols[cols.length - 1].formatterType = 'multiple';
                        cols[cols.length - 1].formatterData = { attributeValue: 'family' };
                        cols[cols.length - 1].cssClass = 'align-left';
                        cols[cols.length - 1].width = 250;
                    }

                    if (column.field === 'brand') {
                        cols[cols.length - 1].formatterType = 'multiple';
                        cols[cols.length - 1].formatterData = { attributeValue: 'brand' };
                        cols[cols.length - 1].width = 152;
                    }

                    if (column.field === 'collection') {
                        cols[cols.length - 1].formatterType = 'multiple';
                        cols[cols.length - 1].formatterData = { attributeValue: 'collection' };
                        cols[cols.length - 1].width = 125;
                    }

                    if (column.label === 'Unidade') {
                        cols[cols.length - 1].formatterType = 'multiple';
                        cols[cols.length - 1].formatterData = { attributeValue: 'unitMeasureDescription' };
                        cols[cols.length - 1].cssClass = 'align-left';
                        cols[cols.length - 1].width = 200;
                    }

                    if (column.label === 'Número DI') {
                        cols[cols.length - 1].formatterType = 'default';
                        cols[cols.length - 1].width = 125;
                    }

                    if (column.label === 'Data DI') {
                        cols[cols.length - 1].formatterType = 'date';
                        cols[cols.length - 1].width = 125;
                    }

                    if (column.label === 'Conhecimento Embarque') {
                        cols[cols.length - 1].formatterType = 'date';
                        cols[cols.length - 1].width = 225;
                    }

                    if (column.label === 'Incoterm') {
                        cols[cols.length - 1].formatterType = 'default';
                        cols[cols.length - 1].width = 125;
                    }

                    if (column.label === 'NCM') {
                        cols[cols.length - 1].formatterType = 'multiple';
                        cols[cols.length - 1].formatterData = { attributeValue: 'ncmCode' };
                        cols[cols.length - 1].width = 125;
                    }

                    if (column.label === 'Nota Fiscal' || column.label === 'Número Invoice' || column.label === 'Número NF') {
                        cols[cols.length - 1].formatterType = 'multiple';
                        cols[cols.length - 1].formatterData = { attributeValue: 'invoiceNumber' };
                        cols[cols.length - 1].width = 150;
                    }

                    if (column.label === 'Data Nota Fiscal' || column.label === 'Data Invoice' || column.label === 'Data NF') {
                        cols[cols.length - 1].formatterType = 'multiple';
                        cols[cols.length - 1].formatterData = { formatterType: 'date', attributeValue: 'invoiceDate' };
                        cols[cols.length - 1].width = 150;
                    }

                    if (column.label === 'CFOP') {
                        cols[cols.length - 1].formatterType = 'multiple';
                        cols[cols.length - 1].formatterData = { attributeValue: 'cfopCode' };
                        cols[cols.length - 1].width = 125;
                    }

                    if (column.field === 'installmentCode') {
                        cols[cols.length - 1].formatterType = 'multiple';
                        cols[cols.length - 1].formatterData = { attributeValue: 'code' };
                        cols[cols.length - 1].width = 125;
                    }

                    if (column.field === 'installmentDueDate') {
                        cols[cols.length - 1].formatterType = 'multiple';
                        cols[cols.length - 1].formatterData = { formatterType: 'date', attributeValue: 'dueDate' };
                        cols[cols.length - 1].width = 150;
                    }

                    if (column.field === 'installments') {
                        cols[cols.length - 1].formatterType = 'multiple';
                        cols[cols.length - 1].formatterData = { formatterType: 'date', attributeValue: 'dueDate' };
                        cols[cols.length - 1].width = 150;
                    }

                    if (column.label === 'Fator da Importação') {
                        cols[cols.length - 1].formatterType = 'currency';
                        cols[cols.length - 1].formatterData = {
                            includeZero: true, currency: 'none', locale: 'pt-BR', digits: 2, float: 'left' },
                        cols[cols.length - 1].minWidth = 200;
                        cols[cols.length - 1].name =
                        `
                            <div id="${index}-grid-column">${column.label}</div>
                            <span id="tooltip-${column.field}" class='material-icons info' style="position: absolute; top: 6px; right: 10px; font-size: 20px; color: #345683;">info
                            <div class="informative">Base de Cálculo: Total da NF C/ IPI ÷ FOB</div><div class="arrow"></div></span>
                        `
                    }

                    if (column.label === 'Fator da Importação 2') {
                        cols[cols.length - 1].formatterType = 'currency';
                        cols[cols.length - 1].formatterData = {
                            includeZero: true, currency: 'none', locale: 'pt-BR', digits: 2, float: 'left' },
                        cols[cols.length - 1].minWidth = 200;
                        cols[cols.length - 1].name =
                        `
                            <div id="${index}-grid-column">${column.label}</div>
                            <span id="tooltip-${column.field}" class='material-icons info' style="position: absolute; top: 6px; right: 10px; font-size: 20px; color: #345683;">info
                            <div class="informative">Base de Cálculo: Total da NF antes dos impostos ÷ FOB</div><div class="arrow"></div></span>
                        `
                    }

                    if (column.label === 'Taxa Dolar') {
                        cols[cols.length - 1].formatterType = 'default';
                        cols[cols.length - 1].formatterType = 'replaceDot';
                        cols[cols.length - 1].width = 125;
                    }

                    if (column.label === 'F.O.B. Dolar' || column.label === 'Frete Dolar' ||
                        column.label === 'Seguro Dolar') {
                        cols[cols.length - 1].formatterType = 'currency';
                        cols[cols.length - 1].formatterData = {
                            includeZero: true, currency: 'USD', locale: 'pt-BR', float: 'left' },
                        cols[cols.length - 1].width = 125;
                    }

                    if (column.label === 'Desconto ICMS') {
                        cols[cols.length - 1].width = 125;
                    }

                    if (column.label === 'I.I. Nac') {
                        cols[cols.length - 1].formatterType = 'multiple',
                        cols[cols.length - 1].formatterData =  {
                            formatterType: 'percentage',
                            attributeValue: 'iiTaxPercentage'
                        },
                        cols[cols.length - 1].width = 100;
                    }

                    if (column.label === 'IPI Nac') {
                        cols[cols.length - 1].formatterType = 'multiple',
                        cols[cols.length - 1].formatterData =  {
                            formatterType: 'percentage',
                            attributeValue: 'ipiTaxPercentage'
                        },
                        cols[cols.length - 1].width = 100;
                    }

                    if (column.label === 'PIS Nac') {
                        cols[cols.length - 1].formatterType = 'multiple',
                        cols[cols.length - 1].formatterData =  {
                            formatterType: 'percentage',
                            attributeValue: 'pisTaxPercentage'
                        },
                        cols[cols.length - 1].width = 100;
                    }

                    if (column.label === 'COFINS Nac') {
                        cols[cols.length - 1].formatterType = 'multiple',
                        cols[cols.length - 1].formatterData =  {
                            formatterType: 'percentage',
                            attributeValue: 'cofinsTaxPercentage'
                        },
                        cols[cols.length - 1].width = 100;
                    }

                    if (column.label === 'ICMS Nac') {
                        cols[cols.length - 1].formatterType = 'multiple',
                        cols[cols.length - 1].formatterData =  {
                            formatterType: 'percentage',
                            attributeValue: 'icmsTaxPercentage'
                        },
                        cols[cols.length - 1].width = 100;
                    }

                    if (column.field === 'itemSummarizedApplication') {
                        cols[cols.length - 1].formatterType = 'multiple',
                        cols[cols.length - 1].formatterData =  {
                            formatterType: 'default',
                            attributeValue: 'application'
                        },
                        cols[cols.length - 1].width = 100;
                    }

                    if (column.field === 'itemSummarizedExternalCode') {
                        cols[cols.length - 1].formatterType = 'multiple',
                        cols[cols.length - 1].formatterData =  {
                            formatterType: 'default',
                            attributeValue: 'externalCode'
                        },
                        cols[cols.length - 1].width = 100;
                    }

                    if (column.field === 'itemSummarizedBarCode') {
                        cols[cols.length - 1].formatterType = 'multiple',
                        cols[cols.length - 1].formatterData =  {
                            formatterType: 'default',
                            attributeValue: 'barCode'
                        },
                        cols[cols.length - 1].width = 100;
                    }

                    if (column.field === 'clientInvoiceAccessKey') {
                        cols[cols.length - 1].formatterType = 'multiple',
                        cols[cols.length - 1].formatterData =  {
                            formatterType: 'default',
                            attributeValue: 'accessKey'
                        },
                        cols[cols.length - 1].width = 100;
                    }

                    if (column.field === 'automotiveColor' || column.field === 'automotiveModelYear'
                    || column.field === 'automotiveManufacturingYear' || column.field === 'automotiveMotorNumber') {
                        cols[cols.length - 1].formatterType = 'default';
                        cols[cols.length - 1].width = 100;
                    }

                    if (columnGroup.name !== 'Dados Gerais' && columnGroup.name !== 'Duplicatas') {
                        if (this.filterState.getShowUc()) {
                            this._includeColumn(cols, column, columnGroup, true, false);
                        }
                        if (columnIndex === columnGroup.columns.length - 1){
                            this._includeColumn(cols, column, columnGroup, false, true);
                            if (this.filterState.getShowUc()) {
                                this._includeColumn(cols, { label: 'TOTAL' }, columnGroup, true, true);
                            }
                        }
                    }
                });
            });
        }
        return cols;
    }

    private _modifyColumns(categories) {
        categories.forEach((categorie) => {
            if (categorie.name !== 'Dados Gerais') {
                categorie.columns.forEach((column) => {
                    this._atualGroups.forEach((atualGroup) => {
                        if (atualGroup.name !== 'MAIN') {
                            atualGroup.columns.forEach((atualColumn) => {
                                if (atualColumn.columnName === column.label &&
                                categorie.name === atualGroup.name) {
                                    column.field = atualColumn.columnId
                                }
                            })
                        }
                    })

                })
            }
        })
    }

    private _includeWantedColumns(groups) {
        let finalGroups = _.cloneDeep(groups);
        if (this.filterState.getContext() === 'imp') {
            if (this.filterState.getShowDuplicatas()) {
                const duplicatas = this._atualGroups.find((atualGroup) => atualGroup.name === 'Duplicatas')
                duplicatas?.columns?.forEach((column) => {
                    column.field = column.columnId;
                    column.path = column.columnId;
                    column.label = column.columnName;
                })
                finalGroups.push({ ...duplicatas, group: duplicatas?.name, data: duplicatas?.columns});
            }
        }
        return finalGroups
    }

    private _removeUnwantedColumns(groups) {
        let finalGroups = _.cloneDeep(groups);
        if (this.filterState.getContext() !== 'imp') {
            finalGroups = finalGroups.filter((group) => { return group.name !== 'Duplicatas' });
            finalGroups.forEach((group) => {
                if (group.name === 'Dados Gerais') {
                    group.columns = group.columns.filter((column) => { return column.label !== 'Desconto ICMS' });
                }
            })
        }
        return finalGroups;
    }

    private _includeColumn(cols, column, columnGroup, uc?, total?) {
        let field = total ? `${this._formatTotalColumnField(columnGroup)}|TOTAL` : column.field;
        if (uc) { field += '(UC)' }
        const name = uc ? `${column.label + ' (U.C)'}` : total ? 'TOTAL' : `${column.label}`;
        if (total && this.filterState.getContext() === 'imp') {
            this._atualGroups.forEach((atualGroup) => {
                if (atualGroup.name === columnGroup.name) {
                    atualGroup.columns.forEach((atualColumn) => {
                        if ((uc && atualColumn.columnName === 'Total (U.C)') ||
                        (!uc && atualColumn.columnName === 'Total')) {
                            field = atualColumn.columnId
                        }
                    })
                }
            })
        }

        // Ignora colunas duplicadas
        if (cols.filter(c => c.id === field).length === 0) {
            cols.push({
                id: field,
                name,
                minWidth: column.minWidth || 155,
                autoWidth: true,
                autoHeaderName: column.label,
                field,
                groupName: columnGroup.name,
                formatterType: 'currency',
                formatterData: { includeZero: true, float: 'right' },
                cssClass: 'value',
            });
        }
    }

    private _formatTotalColumnField(columnGroup) {
        return columnGroup.name.toUpperCase().replaceAll(' ', '_');
    }

    private _verifyMetadataColumn(column) {
         this.metadataColumns[column.field] = column.label;
    }

    private extractRows(data: any) {
        const rows = [];
        if (data && data.length) {
            data.forEach((element, i) => {
                const columns = Object.entries(element.columns);
                rows.push({
                    ...element,
                    id: i,
                    download: element.expensesWorksheetFileNames,
                    productCode: element.products,
                    productDesc: element.products,
                    skuCustomer: element.products,
                    productQuantity: element.products,
                    quantity: element.products,
                    purchaseOrderItems: element.purchaseOrders,
                    vendorInvoiceNumber: element.vendorInvoices,
                    vendorInvoiceDate: element.vendorInvoices,
                    clientInvoiceNumber: element.clientInvoices,
                    clientInvoiceDate: element.clientInvoices,
                    unitMeasureDescription: element.products,
                    countryFrom: element.vendorInvoices,
                    installmentDueDate: element.installments,
                    installmentCode: element.installments,
                    line: element.products,
                    family: element.products,
                    brand: element.products,
                    collection: element.products,
                    iiTaxPercentage: element.taxes,
                    ipiTaxPercentage: element.taxes,
                    pisTaxPercentage: element.taxes,
                    icmsTaxPercentage: element.taxes,
                    cofinsTaxPercentage: element.taxes,
                    itemSummarizedApplication: element?.itemsSummarized,
                    itemSummarizedExternalCode: element?.itemsSummarized,
                    itemSummarizedBarCode: element?.itemsSummarized,
                    clientInvoiceAccessKey: element?.clientInvoices
                });

                columns.forEach((c) => {
                    if (c[1].hasOwnProperty('value')) {
                        let value = '';
                        if (c[1]['value'] === null) {
                            if (c[1].hasOwnProperty('skuList')) {
                                const skuList = c[1]['skuList'] || [];
                                value = skuList.length === 0 ? '' : skuList;
                            }
                        } else {
                            value = c[1]['value'];
                        }
                        rows[rows.length - 1][`${c[0]}`] = value;
                    }
                });
            });
        }
        return rows;
    }

    private _updateDatamartDropdown(customValue) {
        this.options.modelList = this._cmxDropdownService.toDropdownItems(
            customValue.reports.map((model, index) => {
                if (index === 0) this.selectedModel = [{ id: model.reportName, itemName: model.reportName }];
                return { code: model.reportName, name: model.reportName };
            })
        );
    }

    private _openDatamartModal() {
        this._customDialogService.open('datamart-modal');
    }

    private _verifyLinkOptionsEnabled() {
        if (this._userCacheService.getClientGroups().length === 1 &&
        this._userCacheService.getClientGroups()[0]?.name === 'BYD DO BRASIL') {
            this.linkOptions = [
                { id: '120', name: 'A cada 2 horas, entre 07:00 e 19:00 (últimos 2 anos)',
                    enabled: this.filterState.getPeriodicity() === '120' ? true : false },
                { id: '30', name: 'A cada 30 minutos, entre 07:00 e 22:00 (últimos 3 meses)',
                    enabled: this.filterState.getPeriodicity() === '30' ? true : false }
            ];
        }
    }

    trackByFn(index: number, item: any) {
        return index;
    }
}
