import { UtilsService } from '#services/_utils/utils.service';
import { Component, Input, Output, OnInit, SimpleChanges, EventEmitter } from '@angular/core';
import { CustomDialogService } from '../cmx-custom-dialog/custom-dialog.service';

@Component({
    selector: 'cmx-help-modal',
    templateUrl: './cmx-help-modal.component.html',
    styleUrls: ['./cmx-help-modal.component.scss'],
})
export class CmxHelpModalComponent implements OnInit {
    @Input('help-title') helpTitle: string = 'Informação';
    @Input('help-description') helpDescription: object[] = [];
    @Input('help-icon') helpIcon: string = 'info';
    @Input('help-dialog-width') helpDialogWidth: number = 50;
    @Input('help-dialog-height') helpDialogHeight: string = '50';
    @Input() loading: boolean = false;
    @Input('help-video-src') helpVideoSrc: string | null = null;
    @Input('help-video-name') videoName: string | null = null;
    @Input() fromDialog: boolean = false;

    @Output() onOpen = new EventEmitter<any>();
    @Output() onClose = new EventEmitter<any>();

    helpId: string;
    renderTable: boolean = false;

    constructor(private _customDialogService: CustomDialogService, private _utilsService: UtilsService) {}

    ngOnInit() {
        this.helpId = this._utilsService.generateGUID('help');
    }

    ngOnChanges(changes: SimpleChanges ) {
        if ('fromDialog' in changes && this.fromDialog) {
            this.openDetailModal();
        }
    }

    ngAfterViewInit() {
        if (this.fromDialog) {
            this.openDetailModal();
        }
    }

    onModalCloses() {
        this.onClose.emit()
    }

    openDetailModal() {
        this._customDialogService.open(this.helpId);
        setTimeout(() => { this.onOpen.emit() }, 100);
        setTimeout(() => { this.renderTable = true }, 300);
    }
}
