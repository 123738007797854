
import { Injectable } from '@angular/core';
import { LoginPermissionService } from '#services/_login-permission/login-permission.service';
import { NotificationService } from '#services/_notification/notification.service';
import { UsageAnalyticsService } from '#services/_usage-analytics/usage-analytics.service';

@Injectable()
export class ChartsServices {

    public saveSubscription;

    constructor(
        private usageAnalyticsService: UsageAnalyticsService,
        private loginPermissionService: LoginPermissionService,
        private notificationService: NotificationService,
    ) { }

    // Open/Close editModal of respective selector
    public editBtn(event) {
        const el = event.target;
        const parent = $(el).parent();
        const selector = parent[0].localName;
        if ($(el).hasClass('la-ellipsis-v')) {
            $(el).removeClass('la-ellipsis-v');
            $(el).addClass('la-close');
        } else if ($(el).hasClass('flaticon-cogwheel-2')) {
            $(el).removeClass('flaticon-cogwheel-2');
            $(el).addClass('la la-close');
        } else if ($(el).hasClass('la-close') && $(el).hasClass('page')) {
            $(el).removeClass('la');
            $(el).removeClass('la-close');
            $(el).addClass('flaticon-cogwheel-2');
        } else {
            $(el).removeClass('la-close');
            $(el).addClass('la-ellipsis-v');
        }
        if ($('.' + selector).hasClass('hidden')) {
            $('.' + selector).removeClass('hidden');
        } else {
            $('.' + selector).addClass('hidden');
        }
    }
    // Resize and change icon for chart containers
    public resizeBtn(event) {
        const el = event.target;
        const parent = $(el).parent();
        const component = parent[0];
        let action: string;
        // identify/change the size of component
        if ($(component).hasClass('full')) {
            $(component).removeClass('full');
            action = 'Minimize';
        } else {
            $(component).addClass('full');
            action = 'Maximize';
        }
        if (this.saveSubscription) {
            this.saveSubscription.unsubscribe();
        }
        this.notificationService.openSmallNotification('request', 'Salvando Dashboard');
        this.saveSubscription = this.loginPermissionService.saveOrUptadeCustomComponents(
            this.loginPermissionService.getPositionedMergedComponents('mainDashboardContainer', component.id))
        .subscribe((response) => {
            this.loginPermissionService.setMergedComponentList(response.body.items);
            this.notificationService.updateSmallNotification('success', 'Personalização Salva');
        });
        changeIcon();
        this.usageAnalyticsService.sendEvent('Widget', action,
            this.usageAnalyticsService.getFeature(event.currentTarget), null, {});
        function changeIcon() {
            if ($(component).hasClass('full')) {
                $(el).addClass('la-compress');
                $(el).removeClass('la-expand');
            } else {
                $(el).addClass('la-expand');
                $(el).removeClass('la-compress');
            }
        }
    }

}
