import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'cmx-filter-search',
    templateUrl: './cmx-filter-search.component.html',
    styleUrls: ['./cmx-filter-search.component.scss'],
})
export class FilterSearchComponent {
    @Input() types: string[] = ['IMP'];
    @Input() type: string = 'IMP';
    @Input() width: string = '120px'
    @Input() value: string;
    @Input() disableAplly: boolean = false;

    @Output('onInput') onInput = new EventEmitter();
    @Output('onApply') onApply = new EventEmitter();
    @Output('onChangeType') onChangeType = new EventEmitter();

    typeModal: boolean = false;
    placeholder: string;

    constructor() {
        document.addEventListener('click', this._clickOutside.bind(this));
    }

    ngOnDestroy() {
        document.addEventListener('click', this._clickOutside, false);
    }

    onInputEvent($event) {
        this.onInput.emit($event);
    }

    onChangeTypeEvent(type) {
        this.onInput.emit('');
        this.onChangeType.emit(type);
        this.changeTypeModal();
    }

    changeTypeModal() {
        this.typeModal = !this.typeModal;
    }

    private _clickOutside(element) {
        if (!element.target.classList.contains('typeButton') && this.typeModal) {
            this.changeTypeModal();
        }
    }
}
