import { AppModule } from 'app/app.module';
import * as _ from 'lodash';
import moment from 'moment';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { HttpParams } from '@angular/common/http';

interface IWidgetFilter {
    widgetId: string;
    widgetFilters: any[];
}

interface ICustomWidgetsFilter {
    widgets: IWidgetFilter[];
}

export class CustomWidgetsFilter {
    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);

    private _initialCustomWidgetsFilter: ICustomWidgetsFilter = <ICustomWidgetsFilter>{ };
    private _customWidgetsFilter: ICustomWidgetsFilter = <ICustomWidgetsFilter>{
        widgets: [],
    };
    
    constructor(filter?: ICustomWidgetsFilter) {
        this._customWidgetsFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'customWidgetsFilter', this._customWidgetsFilter, filter);
        this._initialCustomWidgetsFilter = _.cloneDeep(this._customWidgetsFilter);
    }

    getWidgets(): IWidgetFilter[] { return this._customWidgetsFilter.widgets }
    getWidgetFilter(widgetId: string): IWidgetFilter {
        return this._customWidgetsFilter.widgets.find(widget => widget.widgetId === widgetId);
    }
    setWidgetFilter(widgetId: string, filters) {
        const existingWidget = this._customWidgetsFilter.widgets.find(widget => widget.widgetId === widgetId);
        if (existingWidget) {
            existingWidget.widgetFilters = filters;
        } else {
            this._customWidgetsFilter.widgets.push({
                widgetId: widgetId, widgetFilters: filters
            });
        }
        this.saveUserPreferences();
    }

    saveUserPreferences() {
        this._initialCustomWidgetsFilter = this._userPreferencesService.verifyFilterChanges(
            this._initialCustomWidgetsFilter, this._customWidgetsFilter, 'customWidgetsFilter', this._userCacheService.getUserPreferences().id);
    }

    getRequest(chartId) {
        const filters = this.getWidgetFilter(chartId);
        let params = new HttpParams();
        filters.widgetFilters?.forEach((filter) => {
            if (filter.type ===  'datepicker') {
                params = params.append('startDate', moment(filter?.value?.startDate, 'MM/YYYY')?.format('YYYY-MM-DD'));
                params = params.append('endDate', moment(filter?.value?.endDate, 'MM/YYYY')?.add(1, 'M')?.add(-1, 'days')?.format('YYYY-MM-DD'));
            } else {
                params = params.append(this._getFilterPath(filter.code, 'path'), filter?.value?.map((c) => c[this._getFilterPath(filter.code, 'dropdownType')]).join(','));
            }
        });
        return { params };
    }

    private _getFilterPath(filterCode, type: 'path' | 'dropdownType') {
        switch (filterCode) {
            case 'clientFilter':
                return type === 'path' ? 'clientGroupCodes' : 'id'
            case 'subsidiaryFilter':
                return type === 'path' ? 'subsidiaryNames' : 'itemName'
            case 'crFilter':
                return type === 'path' ? 'costCenters' : 'id'
            case 'modalFilter':
                return type === 'path' ? 'modals' : 'id'
            case 'statusFilter':
                return type === 'path' ? 'status'  : 'id'
        }   
    }

}
