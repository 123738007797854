// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

const host = 'https://bi-stage.comexport.com.br';

export const environment = {
    mock: false,
    production: false,
    endpoints: {
        adminService: `${host}/api/admin`,
        alertService: `${host}/api/notifications`,
        apportionmentService: `${host}/api/apportionment`,
        automotiveCommandService: `${host}/api/v2/automotive-command`,
        automotiveService: `${host}/api/automotive`,
        automotiveV2QueryService: `${host}/api/v2/automotive-query`,
        commercialPremiseService: `${host}/api/premise`,
        customService: `${host}/api/customization`,
        datamartService: `${host}/api/datamart`,
        deliveryCoordination: `${host}/api/delivery-coordination`,
        diValidationService: `${host}/api/di-validation`,
        diApprovalService: `${host}/api/di-approval`,
        documentCommandService: `${host}/api/ged-command`,
        documentQueryService: `${host}/api/ged-query`,
        domainService: `${host}/api/domain`,
        exchangeService: `${host}/api/exchange`,
        expensesService: `${host}/api/expenses`,
        expensesMongodbService: `${host}/api/expenses-mongodb`,
        featureFlagService: `${host}/api/feature-flag-rs`,
        financialService: `${host}/api/financial`,
        importationService: `${host}/api/importation`,
        invoiceService: `${host}/api/invoices`,
        salesOrderService: `${host}/api/sales-order`,
        licenseService: `${host}/api/license`,
        locationService: `${host}/api/location`,
        newAlertService: `${host}/api/alert`,
        newTrafficTraceability: `${host}/api/v2/traceability`,
        notificationService: `${host}/api/notification`,
        notificationWS: `${host}/ws/notification/ws`,
        operationalConfigService: `${host}/api/operational-config`,
        operationalService: `${host}/api/operational`,
        pricingService: `${host}/api/pricing`,
        quotationService: `${host}/api/quotation`,
        releasesService: `${host}/api/releases/api/v1/releases`,
        reportService: `${host}/api/report`,
        resultsService: `${host}/api/results`,
        siscomexService: `${host}/api/siscomex`,
        skuService: `${host}/api/sku`,
        slaKpiService: `${host}/api/sla-kpi`,
        statusService: `${host}/api/status`,
        supplyChainService: `${host}/api/supply-chain`,
        supplyEngineService: `${host}/api/supply-engine`,
        taxConsultationService: `${host}/api/tax-consultation`,
        usageAnalyticsService: `${host}/api/usage-analytics`,
        userPreferencesService: `${host}/api/preferences/api/v1`,
        purchaseOrderService: `${host}/api/purchase-order`,
        featureFlagServiceV2: `${host}/api/v2/feature-flag`,
        followupService: `${host}/api/followup-service`,
        invoiceExternalService: `${host}/api/invoice-external`,
        taxCodeService: `${host}/api/tax-code`,
        timesheetService: `${host}/api/timesheet/v1`,
        followupMongoDBService: `${host}/api/followup-mongodb-service`,
        paymentClosingService: `${host}/api/financial-closing/v1`,
        importationMongoDBService: `${host}/api/imp-mongodb-service`,
        customWidgetsService: `${host}/api/custom-dashboard/v1`,
        shipmentService: `${host}/api/v2/shipment`,
    },
    captchaEnable: false,
    captcha: {
        key: '6LfqX2gdAAAAAEBQwHtpVXj9UGgwnB5tMr-yqIFf',
    },
    chatAI: {
        whatsappBaseUrl: 'https://wa.me/5511949432237',
        name: 'ComeXa'
    }
};

export const toggles = {
    authorization: {
        missingPermissions: 'deny',
        missingRoute: 'allow',
    },
};
