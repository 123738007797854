import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { LayoutModule } from '../../layouts/layout.module';
import { SharedModule } from 'app/shared/shared.module';
import { NgxMaskModule } from 'ngx-mask';
import { AccordionModule } from 'primeng/accordion';
import { TreeTableModule } from 'primeng/treetable';

import { AdmPanelComponent } from './adm-panel.component';
import { ReleaseCrudComponent } from './adm-releases/adm-releases.component';
import { AdmAccountComponent } from './adm-account-permission/adm-account-permission.component';
import { AdmProfileComponent } from './adm-account-profile/adm-account-profile.component';
import { AdmUserComponent } from './adm-account-user/adm-account-user.component';
import { AdmClientGroupComponent } from './adm-domain-client-group/adm-domain-client-group.component';
import { AdmTraderDirectorComponent } from './adm-domain-trader-director/adm-domain-trader-director.component';

import { AdmDomainClientGroupCrud } from './adm-domain-client-group/adm-domain-client-group.crud';
import { AdmDomainTraderDirectorCrud } from './adm-domain-trader-director/adm-domain-trader-director.crud';
import { ReleasesCrud } from './adm-releases/adm-releases.crud';

import { CommissionService } from '#services/_commision/commision.service';
import { UtilsService } from '#services/_utils/utils.service';
import { AdmPanelService } from './adm-panel.service';
import { TranslateModule } from '@ngx-translate/core';

const routes: Routes = [
    {
        path: '',
        component: AdmPanelComponent,
    },
];

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        LayoutModule,
        RouterModule.forChild(routes),
        NgxMaskModule.forRoot(),
        // primeng modules
        TreeTableModule,
        AccordionModule,
    ],
    declarations: [
        AdmPanelComponent,
        AdmAccountComponent,
        AdmProfileComponent,
        AdmUserComponent,
        AdmTraderDirectorComponent,
        AdmClientGroupComponent,
        ReleaseCrudComponent,
    ],
    exports: [
        AdmAccountComponent,
        AdmProfileComponent,
        AdmUserComponent,
        AdmTraderDirectorComponent,
        AdmClientGroupComponent,
        ReleaseCrudComponent,
    ],
    providers: [
        AdmPanelService,
        AdmDomainClientGroupCrud,
        AdmDomainTraderDirectorCrud,
        ReleasesCrud,
        CommissionService,
        UtilsService
    ],
})
export class AdmModule {

}
