
import {first, map} from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '#environment';
import { Observable } from 'rxjs';
import { DocumentsFilter } from '../helpers/document.filter';
import { FilterStateService } from '#services/_filters/filter-state.service';

@Injectable()
export class DocumentService {

    filterState: DocumentsFilter = this._filterStateService.getDocumentsFilter();

    constructor(
        private http: HttpClient,
        private _filterStateService: FilterStateService
    ) { 
    }

    getDocumentTypes() {
        return this.http.get(`${environment.endpoints.documentQueryService}/v1/list-type-files`);
    }

    getImpsList(request): Observable<any> {
        return this.http.get(`${environment.endpoints.documentQueryService}/v1/list-imp-files-by-external-type`, request);
    }

    getDocumentsByImp(imp, clientGroup, isInternalUser): Observable<any> {
        const importationNumber = imp;
        const clientGroupHeader = clientGroup.map((c) => c.id).join(',');
        return this.http.get(`${environment.endpoints.documentQueryService}/v1/list-files-by-imp/${importationNumber}?X-Internal-User=${isInternalUser}`, {
            headers: new HttpHeaders().set('X-Requested-Client-Groups', clientGroupHeader),
        });
    }

    getAttachedFilesPackage(listImps: string){

        return this.http.get(`${environment.endpoints.exchangeService}/package/find-files-by-imp?imps=${listImps}`).pipe(first());
    }

    filterImps(imp): Observable<any> {
        return this.http.get(
            `${environment.endpoints.documentQueryService}/v1/file-by-importationNumber/${imp}`);
    }

    exportDocuments(imp, options, isInternalUser, uuidList): Observable<HttpResponse<any>> {
        const body = {...options, uuidList: uuidList };
        return this.http.post(
            `${environment.endpoints.documentQueryService}/v1/download/${imp}?X-Internal-User=${isInternalUser}`,
            body,
            { observe: 'response', responseType: 'blob' }
        );
    }

    exportSingleDocument(documentName, options, isInternalUser): Observable<HttpResponse<any>> {
        return this.http.post(`${environment.endpoints.documentQueryService}/v1/download-by-document-name/${documentName}?X-Internal-User=${isInternalUser}`,
        options, { observe: 'response', responseType: 'blob' });
    }

    getPDF(code): Observable<any> {
        return this.http.get(`${environment.endpoints.documentQueryService}/v1/download-by-document-name/${code}`,
            { responseType: 'blob'}).pipe(map((res) => {
                return new Blob([res], { type: 'application/pdf' });
            }));
    }

}
