// Data
export const DROPDOWN_AXYS_TYPES = [
    { id: 'DAILY', itemName: 'PERÍODO (DIÁRIO)' },
    { id: 'MONTHLY', itemName: 'PERÍODO (MENSAL)' },
    { id: 'YEAR', itemName: 'PERÍODO (ANUAL)' },
    { id: 'clientGroup.name', itemName: 'CLIENTE' },
    { id: 'subsidiaryName', itemName: 'FILIAL' },
    { id: 'statusEntity.currentStatus', itemName: 'STATUS' },
    { id: 'costCenter', itemName: 'CR' },
    { id: 'customsClearance.transportTypeDescription', itemName: 'MODALIDADE' },
];

export const DROPDOWN_SECONDARY_AXYS_TYPES = [
    { id: 'clientGroup.name', itemName: 'CLIENTE' },
    { id: 'subsidiaryName', itemName: 'FILIAL' },
    { id: 'statusEntity.currentStatus', itemName: 'STATUS' },
    { id: 'costCenter', itemName: 'CR' },
    { id: 'customsClearance.transportTypeDescription', itemName: 'MODALIDADE' },
];

export const DROPDOWN_AXYS_PERIOD_DEFAULT_DAILY = [
    { id: '30', itemName: '30 DIAS' },
    { id: '60', itemName: '60 DIAS' },
];

export const DROPDOWN_AXYS_PERIOD_DEFAULT_MONTH = [
    { id: '3', itemName: '3 MESES' },
    { id: '6', itemName: '6 MESES' },
    { id: '9', itemName: '9 MESES' },
    { id: '12', itemName: '12 MESES' },
];

export const DROPDOWN_AXYS_PERIOD_DEFAULT_YEAR = [
    { id: '2', itemName: '2 ANOS' },
    { id: '3', itemName: '3 ANOS' },
    { id: '4', itemName: '4 ANOS' },
    { id: '5', itemName: '5 ANOS' },
];

export const DROPDOWN_VALUE_TYPES = [
    { id: 'SUM', itemName: 'SOMA' },
    { id: 'COUNT', itemName: 'CONTAGEM' },
    { id: 'AVG', itemName: 'MÉDIA' },
    { id: 'MEDIAN', itemName: 'MEDIANA' },
    { id: 'MIN', itemName: 'MÍNIMO' },
    { id: 'MAX', itemName: 'MÁXIMO' },
    { id: 'STANDARD_DEVIATION', itemName: 'DESVIO PADRÃO' },
    { id: 'PERCENTAGE', itemName: 'PERCENTUAL' },
    { id: 'RELATIVE_FREQUENCY', itemName: 'FREQUÊNCIA RELATIVA' },
    { id: 'RANK', itemName: 'CLASSIFICAÇÃO (RANK)' },
];

export const DROPDOWN_REFERENCE_LINE_TYPES = [
    { id: 'AVG', itemName: 'MÉDIA' },
    { id: 'MAX', itemName: 'MÁXIMO' },
    { id: 'MIN', itemName: 'MÍNIMO' },
    { id: 'FIXED_VALUE', itemName: 'VALOR FIXO' },
];

export const DROPDOWN_OPERTION_TYPES = [
    { id: 'SUM', itemName: 'SOMA (+)' },
    { id: 'SUB', itemName: 'SUBTRAÇÃO (-)' },
    { id: 'MULTI', itemName: 'MULTIPLICAÇÃO (*)' },
    { id: 'DIV', itemName: 'DIVISÃO (/)' },
];

export const WIDGET_FILTERS = [
    { id: 'CLIENT', label: 'Cliente', formControlName: 'clientFilter' },
    { id: 'STATUS', label: 'Status', formControlName: 'statusFilter' },
    { id: 'SUBSIDIARY', label: 'Filial', formControlName: 'subsidiaryFilter' },
    { id: 'CR', label: 'CR', formControlName: 'crFilter' },
    { id: 'MODAL', label: 'Modal', formControlName: 'modalFilter' }
]

// Settings
export const DROPDOWN_SETTINGS = {
    singleSelection: true,
    enableCheckAll: false,
    maxHeight: 300,
    maxWidth: 450,
    disabled: false,
}