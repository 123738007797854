import { Injectable } from '@angular/core';
import { DropdownOption } from './cmx-dropdown.model';

@Injectable()
export class CmxDropdownService {

    private _instances: any[] = [];

    add(component: any) {
        this._instances.push(component);
    }

    remove(uniqueId: string) {
        this._instances = this._instances.filter((componentInstance) => componentInstance.uniqueId !== uniqueId);
    }

    open(uniqueId: string) {
        const component: any = this._instances.filter((componentInstance) => componentInstance.uniqueId === uniqueId && !componentInstance.isActive)[0];

        if (component) {
            component.isActive = true;
        }
    }

    close(uniqueId: string, ignoreClose: boolean = false) {
        const component: any = this._instances.filter((componentInstance) => componentInstance.uniqueId === uniqueId)[0];

        if (component && !ignoreClose) {
            component.isActive = false;
        }
    }

    toDropdownItemsEmail(items) {
        return items != null ? items?.map(this._toDropdownItemWithEmail) : [];
    }

    toDropdownItems(items, includeCode?) {
        return items != null ? items?.map(
            includeCode ? this._toDropdownItemWithCode : this._toDropdownItem) : [];
    }

    toDropdownItemsImg(items) {
        return items != null ? items?.map(this.toDropdownItemWithCodeImg) : [];
    }

    toDropdownItemsAmount(items) {
        return items != null ? items?.map((e) => {
            return new DropdownOption(e.code || e.id || e.name, `${e.name}`, null, null, null, null, e.amount, e.currency, e.disabled, e.disabledInfo);
        }) : [];
    }

    private _toDropdownItem(e: any): DropdownOption {
        return new DropdownOption(e.code || e.id || e.name, `${e.name}`, e.name, null, null, null, null, null, e.disabled, e.disabledInfo);
    }

    private _toDropdownItemWithEmail(e: any): DropdownOption {
        return new DropdownOption(e.email, e.name, null, null, null, null, null, null, e.disabled, e.disabledInfo);
    }

    private _toDropdownItemWithCode(e: any): DropdownOption {
        return new DropdownOption(e.code, `${e.code} - ${e.name}`, e.name, null, null, null, null, null, e.disabled, e.disabledInfo);
    }

    private toDropdownItemWithCodeImg(e: any): DropdownOption {
        return new DropdownOption(e.id, `${e.itemName}`, null, null, null, null, null, null, e.disabled, e.disabledInfo);
    }

}

