import { HttpHeaders, HttpParams } from '@angular/common/http';
import moment from 'moment';
import { AppModule } from 'app/app.module';
import * as _ from 'lodash';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { FormGroup } from '@angular/forms';
import { PROJECT_PRICING_STATUS } from './constants/project-pricing-status.constant';
import { IProjectPricingFilter } from './interfaces/project-pricing-filter.interface';

export class ProjectPricingFilter {
    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);

    private _initialProjectFPricingFilter: IProjectPricingFilter = <IProjectPricingFilter>{};
    private _projectFPricingFilter: IProjectPricingFilter = <IProjectPricingFilter>{
        searchType: 'N° Precificação',
        search: '',
        clientGroups: [],
        status: [],
        selectedTab: 'project_pricing_progress',
        subsidiaryName: [],
    };

    private startDate;
    private endDate;
    private page: number;
    private size: number;

    private filterField;
    private filterValue;

    form: FormGroup;
    file;
    fileName;
    editFile;
    editFileName;
    requestReviewFile;
    requestReviewFileName;

    constructor(filter?: ProjectPricingFilter) {
        this._projectFPricingFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'projectFPricingFilter', this._projectFPricingFilter, filter);
        this._initialProjectFPricingFilter = _.cloneDeep(this._projectFPricingFilter);
        this.startDate = filter ? filter.startDate : moment().add(-3, 'M').format('DD/MM/YYYY');
        this.endDate = filter ? filter.endDate : moment().format('DD/MM/YYYY');
        this.filterField = filter ? filter.filterField : '';
        this.filterValue = filter ? filter.filterValue : '';
        this.page = filter ? filter.page : 0;
        this.size = filter ? filter.size : 40;
    }

    public getSearchType(): string {
        return this._projectFPricingFilter.searchType;
    }
    public getSearch(): string {
        return this._projectFPricingFilter.search;
    }
    public getStartDate() {
        return this.startDate;
    }
    public getEndDate() {
        return this.endDate;
    }
    public getPage(): number {
        return this.page;
    }
    public getSize(): number {
        return this.size;
    }
    public getClientGroups(): string[] {
        return this._projectFPricingFilter.clientGroups;
    }
    public getStatus(): any[] {
        return this._projectFPricingFilter.status;
    }
    public getTab(): string {
        return this._projectFPricingFilter.selectedTab;
    }
    public getFilterField() {
        return this.filterField;
    }
    public getFilterValue() {
        return this.filterValue;
    }

    public setFilterField(filterField): void {
        this.filterField = filterField;
    }
    public setFilterValue(filterValue): void {
        this.filterValue = filterValue;
    }
    public setSearchType(event) {
        this._projectFPricingFilter.searchType = event;
    }
    public setSearch(search): void {
        this._projectFPricingFilter.search = search;
    }
    public setStartDate(startDate): void {
        this.startDate = startDate;
    }
    public setEndDate(endDate): void {
        this.endDate = endDate;
    }
    public setPage(page): void {
        this.page = page;
    }
    public setSize(size): void {
        this.size = size;
    }
    public setClientGroups(clientGroups): void {
        this._projectFPricingFilter.clientGroups = clientGroups;
    }
    public setStatus(status): void {
        this._projectFPricingFilter.status = status;
    }
    public setTab(selectedTab): void {
        this._projectFPricingFilter.selectedTab = selectedTab;
    }

    public getRequest(internalUser) {
        this._initialProjectFPricingFilter = this._userPreferencesService.verifyFilterChanges(
            this._initialProjectFPricingFilter, this._projectFPricingFilter, 'projectFPricingFilter', this._userCacheService.getUserPreferences().id);
        let params = new HttpParams();
        let headers = new HttpHeaders();
        headers = headers.set('X-Requested-Client-Groups', this.getClientGroups().map((c) => c['id']).join(',') || '');
        const status = this.getTab() === 'project_pricing_progress' ? PROJECT_PRICING_STATUS.project_pricing_progress :
        PROJECT_PRICING_STATUS.project_pricing_historic;

        if (this.getFilterValue()) {
            params = params.append(`${this.getFilterField()}`, `${this.getFilterValue()}`);
        }

        if (this._projectFPricingFilter.search) {
            params = params.append('pricingId', this._projectFPricingFilter.searchType === 'N° Precificação' ? this._projectFPricingFilter.search : '');
            params = params.append('cotationNumber', this._projectFPricingFilter.searchType === 'N° Cotação' ? this._projectFPricingFilter.search : '');
            params = params.append('startDate', '');
            params = params.append('endDate', '');
            params = params.append('status', status.map((c) => c).join(','));
        } else {
            params = params.append('pricingId', '');
            params = params.append('cotationNumber', '');
            params = params.append('startDate', `${moment(this.getStartDate(), 'DD/MM/YYYY').format('YYYY-MM-DD')}`);
            params = params.append('endDate', `${moment(this.getEndDate(), 'DD/MM/YYYY').format('YYYY-MM-DD')}`);
            params = params.append('status', this.getStatus().length === 0 ? status.map((c) => c).join(',') :
                this.getStatus().map((c) => c.id).join(','));
        }
        params = params.append('page', `${this.getPage()}`);
        params = params.append('size', `${this.getSize()}`);
        return { params, headers };
    }
}
