import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5locales_pt_BR from "@amcharts/amcharts5/locales/pt_BR";

export function createDragOrderingBars(root, chartConfig, data) {

    root.locale = am5locales_pt_BR;

    const chart = root.container.children.push(
        am5xy.XYChart.new(root, {
            layout: root.verticalLayout,
            panX: true,
            panY: true,
            wheelX: "panX",
            wheelY: "zoomX",
        })
    );

    // Adiciona um título ao gráfico, se configurado
    if (chartConfig.title) {
        chart.children.unshift(
            am5.Label.new(root, {
                text: chartConfig.title.text,
                fontSize: chartConfig.title.size || 12,
                fontWeight: "bold",
                x: am5.p50,
                centerX: am5.p50,
                paddingBottom: 10,
                paddingTop: 10
            })
        );
    }

    // Condicional para ajustar o eixo X baseado na quantidade de dados
    let xAxis;
    if (data.length === 1) {
        xAxis = chart.xAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererX.new(root, { minGridDistance: 30 }),
                min: 0,
                max: data[0].totalPrice * 1.1 
            })
        );
    } else {
        xAxis = chart.xAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererX.new(root, { minGridDistance: 30 })
            })
        );
    }

    // Configuração do eixo Y baseado no campo `categoryField`
    const yAxis = chart.yAxes.push(
        am5xy.CategoryAxis.new(root, {
            categoryField: chartConfig.content[0].valueYField,
            renderer: am5xy.AxisRendererY.new(root, { minGridDistance: 20 }),
        })
    );

    yAxis.data.setAll(data);

    chartConfig.content.forEach((seriesConfig) => {
        const series = chart.series.push(
            am5xy.ColumnSeries.new(root, {
                name: seriesConfig.name,
                xAxis: xAxis,
                yAxis: yAxis,
                valueXField: seriesConfig.valueXField,
                categoryYField: seriesConfig.valueYField,
                // Condicional para mostrar o tooltip (caixa de dica ao passar o mouse)
                // `showTooltip`: define se o tooltip deve ser exibido ou não
                tooltip: chartConfig.showTooltip !== false
                    ? am5.Tooltip.new(root, {
                        labelText: chartConfig.currency
                            ? "R$ {valueX.formatNumber('#,###.00')}"  // Formato de moeda no tooltip se `currency` for true
                            : "{valueX}"  // Exibe o valor sem formatação se `currency` for false
                    })
                    : undefined,  // Não exibe o tooltip se `showTooltip` for false
                draggable: true
            })
        );

        series.data.setAll(data);

        // Ajusta a cor das colunas de acordo com o índice
        series.columns.template.adapters.add("fill", (fill, target) => {
            return chart.get("colors").getIndex(series.columns.indexOf(target));
        });

        series.columns.template.adapters.add("stroke", (stroke, target) => {
            return chart.get("colors").getIndex(series.columns.indexOf(target));
        });

        // `showValuesX`: Se `true`, exibe o valor dentro da barra
        if (chartConfig.showValuesX) {
            series.bullets.push(function () {
                return am5.Bullet.new(root, {
                    locationX: 0.5,
                    sprite: am5.Label.new(root, {
                        // Se `currency` for true, exibe o valor no formato monetário dentro da barra
                        text: chartConfig.currency
                            ? "R$ {valueX.formatNumber('#,###.00')}" 
                            : "{valueX}",
                        fill: am5.color(0xffffff),  // Cor do texto dentro da barra (branco)
                        centerX: am5.p50,
                        centerY: am5.p50,
                        populateText: true
                    })
                });
            });
        }

        series.columns.template.events.on("dragstop", function (ev) {
            const column = ev.target;
            let index = series.dataItems.indexOf(column.dataItem);
            let newIndex = yAxis.data.indexOf(column.dataItem.dataContext);
            yAxis.data.moveIndex(index, newIndex);
        });

        series.columns.template.setAll({
            draggable: true,
            cornerRadiusBR: 10,
            cornerRadiusTR: 10,
            strokeOpacity: 0
        });
    });

    const cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
        behavior: "zoomX"
    }));

    cursor.lineX.set("visible", false);
    cursor.lineY.set("visible", false);

    return chart;
}
