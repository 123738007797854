import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppComponent } from '../app.component';
import { AuthenticationService } from './_services/authentication.service';
import { LoginService } from './_services/login.service';

@Component({
    selector: '.m-grid.m-grid--hor.m-grid--root.m-page',
    templateUrl: './logout-msg.component.html',
    styleUrls: ['./logout-msg.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class LogoutMsgComponent implements OnInit {
    model: any = {};
    initialized = false;
    loading = false;
    msgLogout = '';
    asyncLogin = false;

    logoLogin = this.loginService.getLogo();
    backLogin = this.loginService.getBackground();

    constructor(
        private route: ActivatedRoute,
        private loginService: LoginService,
        private _authenticationService$: AuthenticationService,
        private app: AppComponent
    ) {}

    getErrorMsg(code: string) {
        switch (code) {
            case 'UNAUTHORIZED':
                return 'auth.msgSessionEndedUnauthorized';
            case 'INVALID_OR_EXPIRED_SESSION':
                return 'auth.msgSessionEnded';
            case 'LOCATION_NOT_ALLOWED':
                return 'auth.msgLoginNotAllowedFromLocation';
            case 'TIME_NOT_ALLOWED':
                return 'auth.msgSystemAccessNotAllowedAtThisTime';
            case 'DOMAIN_NOT_ALLOWED':
                return 'auth.msgDomainAccessDenied';
            default:
                return 'auth.msgSessionEnded';
        }
    }

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            this.model.errorCode = params.errorCode;
        });
        this.app.globalLoader = false;
        $('.m-page-loader.m-page-loader--non-block').attr('style', 'display: none');
        this.msgLogout = this.getErrorMsg(this.model.errorCode);
        this.initialized = true;
    }

    goToLogin() {
        this._authenticationService$.logout(true, true);
    }
}
