import { IGridColumn } from 'app/shared/components/cmx-custom-grid/interfaces';

export const PROJECT_PRICING_COLUMNS: IGridColumn[] = [
    {
        field: 'pricingId',
        name: 'Precificação',
        formatterType: 'badge',
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
        width: 120,
        formatterData: {
            classes: "cursor-pointer",
            customIcons: [{
                atributeCondition: 'priority',
                valueConditions: [true],
                iconType: 'warning',
                iconColor: '#a70000',
                iconPosition: 'middle-right',
                iconTooltip: 'Urgente',
            }]
        }
    },
    {
        field: 'quotationNumber',
        name: 'Cotação',
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
        width: 90,
    },
    {
        field: 'clientGroupName',
        name: 'Cliente',
        width: 145,
    },
    {
        field: 'responsibleUsers',
        name: 'Responsáveis',
        formatterType: 'multiRound',
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
        width: 120,
    },
    {
        field: 'status',
        name: 'Status',
        formatterType: 'textStatus',
        formatterData: {
            customIcons: [{
                iconType: 'info',
                iconColor: 'grey',
                atributeCondition: 'hasCancelJustify',
                valueConditions: ['true'],
            }]
        },
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center popover-hover popover-status',
        width: 200,
    },
    {
        field: 'note',
        name: 'Observações',
        width: 180,
    },
    {
        field: 'numberOfRequests',
        name: 'Solicitação',
        formatterType: 'round',
        formatterData: {
            roundType: 'download',
            needValue: true,
        },
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
        width: 90,
    },
    {
        field: 'numberOfProposals',
        name: 'Proposta',
        formatterType: 'round',
        formatterData: {
            roundType: 'download',
            needValue: true,
            customIcons: [{
                iconType: 'assignment',
                iconColor: 'grey',
                atributeCondition: 'hasNote',
                valueConditions: ['true'],
            }]
        },
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center popover-hover popover-numberOfProposals',
        width: 80,
    },
    {
        field: 'totalQuotationValue',
        name: 'Valor total da Cotação',
        width: 160,
        formatterType: 'round',
        formatterData: {
            roundType: 'number',
            needValue: true,
            useDefaultValueWhenSingle: true,
        },
    },
    {
        field: 'multiButtons',
        name: 'Ações',
        formatterType: 'multiButtons',
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
        width: 350,
    },
    {
        field: 'requesterUserName',
        name: 'Solicitante',
        width: 145,
        isFilterable: true,
    },
    {
        field: 'requestDate',
        name: 'Solicitação',
        formatterType: 'date',
        formatterData: {
            hourFormat: true,
        },
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
        width: 155,
    },
    {
        field: 'sendProposalDate',
        name: 'Proposta',
        formatterType: 'date',
        formatterData: {
            hourFormat: true,
        },
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
        width: 155,
    },
    {
        field: 'approvalDate',
        name: 'Solicitação PO.',
        formatterType: 'date',
        formatterData: {
            hourFormat: true,
        },
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
        width: 150,
    },
    {
        field: 'cancelRequestDate',
        name: 'Cancelamento',
        formatterType: 'date',
        formatterData: {
            hourFormat: true,
        },
        headerCssClass: 'grid-header align-center',
        cssClass: 'grid-cell align-center',
        width: 150,
    },
];
