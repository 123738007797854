import { Injectable } from '@angular/core';
import { FORM_SCRIPT } from './constants/form-tab.constant';
import { Subject } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';

interface ISpecialScriptInput { 
    type: 'dropdown' | 'datepicker' | 'switch' ,
    field: string,
    open?: boolean,
    uniqueId?: string,
    switchElement?
    fatherSwitchElement?
}

@Injectable()
export class FormTabService {

    currentFormId: string;
    currentListIndex: number = 0;
    currentListFirstField;
    toNextListIndex: boolean = false;
    lastSpecialInputField: ISpecialScriptInput;
    dropdownSubject = new Subject<any>();

    constructor(private _router: Router) {
        this._router.events.subscribe((event) => {
            // verifica mudança de url/navegação para reset de valores do serviço
            if (event instanceof NavigationStart) {
                this._resetFormScript();
            }
        })
        document.addEventListener("click", (event) => {
            // verifica click com mouse
            this._changeListIndexOnClick(event);
        }, true);
    }

    identifyTabClick() {
        document.addEventListener('keydown', (event) => {
            // verifica click de Tab (9) em tela
            if (event.keyCode == 9) {
                this._verifyForm(event);
            }
            if (this.lastSpecialInputField?.type === 'dropdown' &&
                this.lastSpecialInputField?.open) {
                // Up (38) / Down (40) / Enter (13)
                if (event.keyCode == 38 || event.keyCode == 40 || event.keyCode == 13) {
                    this.dropdownSubject.next({ field: this.lastSpecialInputField, action: event.keyCode })
                }
            }
            if (this.lastSpecialInputField?.type === 'switch') {
                // Left (37) / Right (39) / Enter (13)
                if (event.keyCode == 37 || event.keyCode == 39 || event.keyCode == 13) {
                    const checked = this.lastSpecialInputField?.fatherSwitchElement?.classList.contains('p-inputswitch-checked');
                    if (event.keyCode == 13 || (event.keyCode == 37 && checked) || (event.keyCode == 39 && !checked)) {
                        $(this.lastSpecialInputField?.fatherSwitchElement)?.click();
                    }
                }
            }
        })
    }

    private _verifyForm(event) {
        // verifica mudança de formulário
        this._newFormScript();
        // obtem id de form em tela
        this.currentFormId = $('form')?.attr('id');
        // verifica form com script
        if (FORM_SCRIPT.hasOwnProperty(this.currentFormId)) {
            event.preventDefault();
            const currentFocusElement = $(":focus");
            this._removeAllFocus();
            if (currentFocusElement?.length && (currentFocusElement[0]?.localName === 'input'
                || currentFocusElement[0]?.localName === 'textarea')
                && currentFocusElement[0]?.classList?.contains('p-inputtext')) {
                // caso algum input tenha foco
                const formComtrolName = currentFocusElement?.attr('formcontrolname');
                if (formComtrolName && FORM_SCRIPT[this.currentFormId].hasOwnProperty(formComtrolName)) {
                    // caso o input seja de form + exista roteiro para o mesmo
                    const newFocus = FORM_SCRIPT[this.currentFormId][formComtrolName];
                    this._applyNewFocus(newFocus.newFocus, newFocus.dropdown, newFocus.switch, newFocus.datepicker, newFocus);
                }
            } else if (this.lastSpecialInputField?.field) {
                // caso algum input componentizado tenha sido selecionado recentemente (dropdown/datepicker/switch)
                const newFocus = FORM_SCRIPT[this.currentFormId][this.lastSpecialInputField?.field];
                this.dropdownSubject.next({ field: this.lastSpecialInputField, action: 'close' })
                if (newFocus) this._applyNewFocus(newFocus.newFocus, newFocus.dropdown, newFocus.switch, newFocus.datepicker, newFocus);
            } else {
                // caso nenhum elemento tenha foco, aplica ao primeiro do roteiro
                const newFocus = FORM_SCRIPT[this.currentFormId]?.noFocus;
                this._applyNewFocus(newFocus.newFocus, newFocus.dropdown, newFocus.switch, newFocus.datepicker, newFocus);
            }
        }
    }

    private _applyNewFocus(formControlName, isDropdown: boolean, isSwitch: boolean, isDatepicker: boolean, newFocus) {
        // verifica se campo solicitado está habilitado e presente em tela
        if (this._verifyDisabledField(formControlName, isDropdown, isDatepicker, isSwitch, newFocus?.list)) {
            this._jumpToNextInput(formControlName);
        // verifica se deve mover-se para novo indice da lista atual
        } else if (this.toNextListIndex) {
            this.toNextListIndex = false;
            this._applyNewFocus(this.currentListFirstField.newFocus, 
                this.currentListFirstField.dropdown,
                this.currentListFirstField.switch,
                this.currentListFirstField.datepicker, this.currentListFirstField);
        // fluxo comum
        } else {
            setTimeout(() => {
                if (!isSwitch) { $('.p-inputswitch-slider').removeClass('focus') }  
                if (isSwitch) {
                    this._focusSwitch(formControlName, newFocus);
                } else if (isDatepicker) {
                    this._focusDatepicker(formControlName, newFocus);
                } else if (isDropdown) {
                    this._focusDropdown(formControlName, newFocus);
                } else {
                    this._focusInput(formControlName, newFocus);
                }
                this._verifyList(newFocus);
            }, 50);
        }
    }

    private _verifyDisabledField(formControlName, dropdown: boolean, datepicker: boolean, isSwitch: boolean, isList: boolean): boolean {
        let element;
        if (datepicker) {
            if (isList) {
                element = $(`#${formControlName}${this.currentListIndex} .cmx-filter-datepicker`)[0] as HTMLElement
            } else {
                element = $(`#${formControlName} .cmx-filter-datepicker`)[0] as HTMLElement
            }
        } else if (isSwitch) {
            if (isList) {
                element = $(`#${formControlName}${this.currentListIndex} .p-inputswitch`)[0] as HTMLElement
            } else {
                element = $(`[formcontrolname=${formControlName}] .p-inputswitch`)[0] as HTMLElement;
            }
        } else {
            if (isList) {
                element = $(`#${formControlName}${this.currentListIndex}`)[0] as HTMLElement
            } else {
                element = $(`[formcontrolname=${formControlName}]`)[0] as HTMLInputElement;
            }
        }
        if (!element) { return true }
        if ((dropdown && $(element?.firstChild)?.attr("class")?.includes('disabled'))
            || (datepicker && $(element)?.attr("class")?.includes('disabled'))
            || (isSwitch && $(element)?.attr("class")?.includes('p-disabled'))
            || (element instanceof HTMLInputElement && !dropdown && element?.disabled)) {
                return true;
        } else {
            return false;
        }
    }

    private _jumpToNextInput(formControlName) {
        const nextFocus = FORM_SCRIPT[this.currentFormId][formControlName];
        const nextInput = nextFocus?.newFocus;
        const isDropdown = nextFocus?.dropdown;
        const isSwitch = nextFocus?.switch;
        const isDatepicker = nextFocus?.datepicker;
        this._applyNewFocus(nextInput, isDropdown, isSwitch, isDatepicker, nextFocus);
    }

    private _removeAllFocus() {
        setTimeout(() => {
            $('.p-inputswitch-slider').removeClass('focus');
            $('.Zebra_DatePicker').addClass('dp_hidden');
            const currentFocusElement = $(":focus");
            $(currentFocusElement).blur();
        }, 0);
    }

    private _newFormScript() {
        if (this.currentFormId !== $('form')?.attr('id')) {
            this.lastSpecialInputField = undefined;
        }
    }

    // verifica entrada/saída de listas
    private _verifyList(field) {
        // reseta valores de controle de listas ao focar em campo não pertencente à nenhuma lista
        if (!field?.list) {
            this.currentListIndex = 0;
            this.toNextListIndex = false;
        }
        // guarda valor do primeiro item de lista, para ser refocado em possível lista com vários indices
        if (field?.list && field?.first) {
            this.currentListFirstField = field;
        }
        if (field?.list && field?.last) {
            const hasNextFirstListField = $(`#${this.currentListFirstField?.newFocus}${this.currentListIndex + 1}`);
            // verifica se existe o próximo indice de lista a ser considerado
            if (hasNextFirstListField?.length) {
                this.currentListIndex++;
                this.toNextListIndex = true;
            } else {
                // verifica se a lista terminou
                this.currentListIndex = 0;
                this.toNextListIndex = false;
            }
        }
    }

    private _focusInput(formControlName, newFocus) {
        if (newFocus?.list) {
            $(`#${formControlName}${this.currentListIndex}`)?.focus();
        } else {
            $(`[formcontrolname=${formControlName}]`)[0]?.focus();
        }
        this.lastSpecialInputField = null;  
    }

    private _focusDropdown(formControlName, newFocus) {
        if (newFocus?.list) {
            $(`#${formControlName}${this.currentListIndex} .c-dropdown-btn`)[0]?.click();
        } else {
            $(`[formcontrolname=${formControlName}] .c-dropdown-btn`)[0]?.click();
        }
    }

    private _focusDatepicker(formControlName, newFocus) {
        const name = newFocus?.list ? `${formControlName}${this.currentListIndex}` : formControlName;
        this.lastSpecialInputField = { type: 'datepicker', open: false, field: formControlName } 
        $(`#${name} input`)?.focus();
        setTimeout(() => {
            $(`#${name} .material-icons`)?.click();
            $(`#${name} input`)?.focus();
        }, 100);
    }

    private _focusSwitch(formControlName, newFocus) {
        let _fatherSwitchElement;
        let _switchElement;
        if (newFocus?.list) {
            _fatherSwitchElement = $(`#${formControlName}${this.currentListIndex} .p-inputswitch`)[0];
            _switchElement = $(`#${formControlName}${this.currentListIndex} .p-inputswitch-slider`)[0]
        } else {
            _fatherSwitchElement = $(`[formcontrolname=${formControlName}] .p-inputswitch`)[0];
            _switchElement = $(`[formcontrolname=${formControlName}] .p-inputswitch-slider`)[0]
        }
        this.lastSpecialInputField = { fatherSwitchElement: _fatherSwitchElement, switchElement: _switchElement, type: 'switch', field: formControlName }
        $(_switchElement)?.addClass('focus');
    }

    private _changeListIndexOnClick(event) {
        this.currentFormId = $('form')?.attr('id');
        if (FORM_SCRIPT.hasOwnProperty(this.currentFormId)) {
            // verifica mudança de índice ao clicar em uma lista
            if (event.target.localName === 'input' || event.target.localName === 'textarea' || event.target.classList.contains('p-inputswitch-slider')
                || event.target.classList.contains('c-dropdown-btn')) {
                    const formListIndexElement = event.target.closest(".form-script-list");
                    if (formListIndexElement) {
                        const newIndex = formListIndexElement?.id?.split('-')[1];
                        if (newIndex || newIndex === 0) { this.currentListIndex = parseInt(newIndex) }
                    }  
            }
            // aplica foco a switch
            if (event.target.classList.contains('p-inputswitch-slider')) {
                const switchElement = event.target.closest('p-inputswitch')
                if (switchElement) {
                    const formControlName = $(switchElement)?.attr('formControlName')
                    const previousFocus = Object.keys(FORM_SCRIPT[this.currentFormId]).find((key) =>  
                        FORM_SCRIPT[this.currentFormId][key]?.newFocus === formControlName);
                    const newFocus = FORM_SCRIPT[this.currentFormId][previousFocus]
                    $('.p-inputswitch-slider').removeClass('focus')
                    // this._focusSwitch(formControlName, newFocus?.newFocus);
                    this._applyNewFocus(formControlName, false, true, false, newFocus)
                }
            }
            // ao clicar em um input ou textarea, remove foco de switchs
            if (event.target.classList.contains('p-inputtextarea') || event.target.classList.contains('p-inputtext')) {
                $('.p-inputswitch-slider').removeClass('focus');
                this.lastSpecialInputField = undefined;
            }
        }
    }

    private _resetFormScript() {
        this.currentFormId = undefined;
        this.currentListIndex = 0;
        this.currentListFirstField = undefined;
        this.toNextListIndex = false;
        this.lastSpecialInputField = undefined;
        this.dropdownSubject = new Subject<any>();
    }

}