<div *ngIf="enabledChatAI" class="floating-chatai" [ngClass]="{'expanded':  expanded}" (mouseleave)="collapse()">
    <div class="floating-icon" [ngClass]="{'expanded': expanded}" [title]="'auth.msgConfigureAIChat' |  translate" >
        <span *ngIf="!expanded && asyncEditPhone && !phoneIntegrationData && notification " class="header-nav-alerts-ball CRITICAL ng-star-inserted">1</span>
        <i class="fa fa-brands fa-whatsapp" (click)="expand()" ></i>
        <div *ngIf="expanded" class="floating-icon-title">
            <span class="message">{{ 'auth.msgChatWithComeXa' | translate }}</span>
            <i (click)="close()" class="la la-close"></i>
        </div>
    </div>
    <div *ngIf="expanded" class="chatai-content">
        <ng-container *ngIf="asyncEditPhone && !phoneIntegrationData">
            <div class="infoMessage">
                <span class="title">{{ 'auth.msgMeetComeXaAI' | translate }}</span>
                <span class="description">{{ 'auth.msgWhatsAppSetupInfo' | translate }}</span>
              </div>
            <div class="phone">
                <input pInputText (keydown)="editPhoneOnKeydown($event)" [(ngModel)]="phoneNumber" type="text" placeholder="(99) 99999-9999" mask="(00) 00000-0000"/>
            </div>
            <div *ngIf="errorMessage" class="errorMessage">
                <span>{{errorMessage}}</span>
            </div>
            <div class="buttons">
                <cmx-button (click)='savePhoneIntegration()'>{{ 'system::actions.save' | translate }}</cmx-button>
            </div>
            <label class="rememberClose" *ngIf="false">
                <input type="checkbox" [(ngModel)]="chatAiPreferences.rememberClose" [checked]="rememberCredential" [ngModelOptions]="{standalone: true}">{{ 'common::messages.doNotShowAgain' | translate }}
            </label>
        </ng-container>

        <ng-container *ngIf="asyncEditPhone && phoneIntegrationData">
            <div class="modalContent">
                <ol style="padding-left: 10px">
                    <li class="listItem">{{ 'auth.msgStartChatWithComeXa' | translate }}</li>
                    <li class="listItem">{{ 'auth.msgFirstAccessCodeRequired' | translate }}</li>
                    <li class="listItem">{{ 'auth.msgAccessCodeButtonInfo' | translate }}</li>
                    <li class="listItem">{{ 'auth.msgProvideCodeViaWhatsApp' | translate }}</li>
                </ol>

                <div class="integrationLinks">
                    <a [href]="whatsappUrl" class="whatsappIcon" ><img style="width: 120px;" src="assets/img/icons/whatsapp_green.svg"/></a>
                    <qrcode [qrdata]="whatsappUrl" [cssClass]="'center'" [width]="180" [errorCorrectionLevel]="'M'"></qrcode>
                </div>
                <div class="integrationOptions">
                    <cmx-button variant="success" *ngIf="phoneIntegrationData.otpCode && !showOtp" (click)="showOtp = !showOtp">{{ 'auth.showCode' | translate }}</cmx-button>
                    <span *ngIf="showOtp" class="otpCode">{{phoneIntegrationData.otpCode}}</span>

                    <div *ngIf="formattedPhoneNumber && !updatePhoneNumber" class="displayPhone">
                        <button class="cmx-button format-text" (click)="showEditPhoneOnKeydown()">
                            <span class="phone-edit-icon">
                                <span>{{formattedPhoneNumber}}</span>
                                <em class="material-icons edit">edit</em>
                            </span>
                        </button>
                    </div>

                </div>
                <ng-container *ngIf="updatePhoneNumber">
                    <div class="phone">
                        <input pInputText (keydown)="editPhoneOnKeydown($event)" [(ngModel)]="phoneNumber" type="text" placeholder="(99) 99999-9999" mask="(00) 00000-0000"/>
                    </div>
                    <div *ngIf="errorMessage" class="errorMessage">
                        <span>{{errorMessage}}</span>
                    </div>
                    <div class="buttons">
                        <cmx-button variant="light" (click)="cancelPhoneOnKeydown()">{{ 'system::actions.cancel' | translate }}</cmx-button>
                        <cmx-button (click)='savePhoneIntegration()'>{{ 'system::actions.update' | translate }}</cmx-button>
                    </div>
                </ng-container>

                <label class="rememberClose" *ngIf="false">
                    <input type="checkbox" [(ngModel)]="chatAiPreferences.rememberClose" [checked]="rememberCredential" [ngModelOptions]="{standalone: true}">{{ 'common::messages.doNotShowAgain' | translate }}
                </label>
            </div>
        </ng-container>

        <div *ngIf="!asyncEditPhone">
            <loader></loader>
        </div>
    </div>
</div>
