import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { AuthenticationService } from './_services/authentication.service';
import { LoginService } from './_services/login.service';
import { UserService } from './_services/user.service';
import { environment } from '#environment';
import { TemplateLoginEnum } from './template-login.enum';
import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'app-recovery-password-page',
    templateUrl: './pwd-recovery.component.html',
    styleUrls: ['./pwd-recovery.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PwdRecoveryComponent implements OnInit {
    @ViewChild('txtEmail') inputTxtEmail: ElementRef;
    @ViewChild('captcha') captcha;

    captchaEnable = environment.captchaEnable;
    initialized = false;
    loading = false;
    sentEmail = false;
    returnUrl: string;

    asyncLogin = false;
    isIE = false;
    captchaToken: string;
    themeLogo: string = null;
    themeBackground: string = null;
    themeClient: string = null;
    captchaValidated: boolean = true;
    captchaKey: string;
    showCaptcha: boolean = true;
    mobile: any = true;

    formRecoveryPassword: FormGroup = this._formBuilder.group({
        email: [null, [Validators.required, Validators.email]],
        domain: [null, [Validators.required]]
    });

    errorPasswordRecovery = '';
    msgPasswordRecovery = '';

    constructor(
        private router: Router,
        private userService: UserService,
        private route: ActivatedRoute,
        private _authenticationService: AuthenticationService,
        private _loginService$: LoginService,
        private app: AppComponent,
        private _formBuilder: FormBuilder,
        private _translateService: TranslateService
    ) {}

    ngOnInit() {
        this.showCaptcha = true;
        this.captchaKey = this._authenticationService.getCaptchaKey();
        this.route.queryParams.subscribe((params) => {
            this.formRecoveryPassword.controls['email'].patchValue(params.email);
            this.formRecoveryPassword.controls['domain'].patchValue(params.domain);
        });

        this.themeLogo = this._loginService$.getLogo();
        this.themeBackground = this._loginService$.getBackground();
        this.themeClient = this._loginService$.getClient();
        this.msgPasswordRecovery = this._translateService.instant('auth.msgPasswordRecovery');
        this.app.globalLoader = false;
        this.getUser();
    }

    ngAfterViewInit(): void {
        this.inputTxtEmail?.nativeElement.focus();
        setTimeout(() => {
            if(
                this.captchaEnable &&
                this.captcha &&
                this.captcha.el.nativeElement.childNodes.length > 0 &&
                this.captcha.el.nativeElement.childNodes[0].childElementCount > 0
            ){
                this.captchaValidated = false;
            }
        }, 1000);
    }

    sendEmail() {
        this.errorPasswordRecovery = '';
        this.showCaptcha = true;

        if (!this.formRecoveryPassword.controls['email'].valid || !this.validateEmail(this.formRecoveryPassword.controls['email'].value)) {
            this.errorPasswordRecovery = this._translateService.instant('auth.msgInvalidEmail');
            return;
        }

        if (!this.formRecoveryPassword.controls['domain'].valid) {
            this.errorPasswordRecovery = this._translateService.instant('auth.msgDomainNotSpecified');
            return;
        }

        this.asyncLogin = true;

        if (this.captchaValidated) {
            const origin = location.host;;
            this._authenticationService.sendPasswordResetEmail(this.formRecoveryPassword.controls['email'].value, false, this.captchaToken, this.mobile, origin).subscribe(
                () => {
                    this.msgPasswordRecovery = this._translateService.instant('auth.msgLinkSent');
                    this.sentEmail = true;
                    this.asyncLogin = false;
                    this.showCaptcha = false;
                }, error => {
                    this.asyncLogin = false;
                    this.resetCaptcha();
                    if (error.code === 'auth/invalid-email') {
                        this.errorPasswordRecovery = this._translateService.instant('auth.msgInvalidEmail');
                    } else if (error.code === 'auth/user-not-found') {
                        this.errorPasswordRecovery =  this._translateService.instant('auth.msgNotFoundEmail');
                    } else if(error.error === 'INVALID_CAPTCHA') {
                        this.errorPasswordRecovery = this._translateService.instant('auth.msgCaptchaError');
                    }
                    else {
                        this.errorPasswordRecovery = this._translateService.instant('auth.msgEmailSendFail');
                    }
                }
            );
        } else {
            this.errorPasswordRecovery = this._translateService.instant('auth.msgCaptchaError');
            this.asyncLogin = false;
        }
    }

    public validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    goBack() {
        this._authenticationService.setTemplate(TemplateLoginEnum.LOGIN);
        this.router.navigate(['/login']);
    }

    private getUser() {

        this.userService.setIsLoggedIn(false);
        this.initialize();
    }

    private initialize() {
        this.app.globalLoader = false;
        this.initialized = true;
    }

    validateCaptcha($event) {
        this.captchaValidated = true;
        this.captchaToken = $event.response;

	}

    resetCaptcha() {
        this.captchaValidated = false;
        this.captcha.reset();
    }
}
