export class Trader {

    public static blank() {
        return new Trader('Novo Trader', null, null);
    }

    name: string;
    code: string;
    director: Idirector;
    clientGroups: IclientGroup[];

    constructor(name: string, code: string, type?: string, director?: any, clientGroups?: IclientGroup[]);
    constructor(source: {name: string, code?: string, type?: string, director?: any, clientGroups?: IclientGroup[]});
    constructor(source: string | any, code?: string, type?: string, director?: any, clientGroups?: IclientGroup[]) {
        if (typeof source === 'string') {
            this.name = source;
            this.code = code;
            this.director = director;
            this.clientGroups = clientGroups;
        } else {
            this.name = source.name;
            this.code = source.code;
            this.director = source.director;
            this.clientGroups = source.clientGroups;
        }
    }

    public clone() {
        return new Trader(this);
    }

    public equals(other: Trader): boolean {
        return this.name === other.name
            && this.code === other.code
            && this.director.code === other.director.code
            && this.director.name === other.director.name;
    }
}

interface Idirector {
    name: string;
    code: string;
}

interface IclientGroup {
    name: string;
    code: string;
}
