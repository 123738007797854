import { UserPreferencesService } from './../../../services/_user/user-preferences.service';
import { AfterViewInit, Component, HostListener, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { SPECIFIC_USER_ROUTES } from 'app/auth/constants/specific-user-routes';
import * as moment from 'moment';
import * as _ from 'lodash';

import { CLIENT_GROUPS_PERMITED } from './constants/client-groups-permited.constants';
import { LoginService } from '../../../auth/_services/login.service';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { DownloadModalService } from '#services/_download/download-modal.service';
import { MessagesService } from '#services/_messages/messages.service';
import { NewFeaturesConsumerService } from '#services/_newFeatureConsumer/newFeatureConsumer.service';
import { UtilsService } from '#services/_utils/utils.service';
import { UserService } from 'app/auth/_services/user.service';
import { HeaderNavService } from 'app/theme/layouts/header-nav/header-nav.service';
import { TranslateService } from '@ngx-translate/core';

declare let mLayout: any;
@Component({
    selector: 'app-header-nav',
    templateUrl: './header-nav.component.html',
    styleUrls: ['./header-nav.component.scss', './header-nav.theme.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class HeaderNavComponent implements AfterViewInit {

    currentUser$ = this.userService$.currentUser$;
    userPreference: {
        darkTheme?: boolean,
        idPreference?: string,
        readOnly?: boolean,
    } = {};

    getLogo = null;
    hiddenMenu: boolean = true;
    daysExpirePassword: number = 100;
    enableAlterPassword: boolean = false;
    passwordMessage: string = '';
    showNotificationInfo: boolean = false;
    moment = moment;
    objectKeys = Object.keys;
    startDate = moment().format('YYYY-MM-01');
    momentEndDate = moment().format('YYYY-MM-01');
    endDate = moment(this.momentEndDate, 'YYYY-MM-DD').add('1', 'M').add('-1', 'days').format('YYYY-MM-DD');

    @HostListener('click', ['$event']) clickToCloseModals(event) {
        const elements = event.target.classList.value;
        const elementsToCloseModals = ['logo-content','main-menu-content','user-initials','profile-display-menu'];

        elementsToCloseModals.forEach(el => {
            if(elements.indexOf(el) >= 0) {
                this.newFeaturesConsumerService.closeReleaseList();
                this.downloadModalService.closeDownloads();
                this.headerNavService.closeAlert();
            }
        });
    }

    constructor(
        public headerNavService: HeaderNavService,
        public downloadModalService: DownloadModalService,
        public messagesService: MessagesService,
        public newFeaturesConsumerService: NewFeaturesConsumerService,
        private router: Router,
        private userService$: UserService,
        private loginService$: LoginService,
        private _userCacheService: UserCacheService,
        private userPreferencesService$: UserPreferencesService,
        private _utilsService: UtilsService,
        private _translateService: TranslateService,
    ) { }

    ngOnInit(){
        this.userPreference = {
            ...this._userCacheService.getUserPreferences(),
            darkTheme: this._userCacheService.getUserDarkTheme()
        };

        this.onChangeLogo(this.userPreference.darkTheme);
    }

    ngAfterViewInit() {
        mLayout.initHeader();
        this.headerNavService.loadAlerts();
        this.messagesService.loadMessages();
        $(window).on('click', (event) => {
            const $target = $(event.target);
            if (!$target.hasClass('user-profile-display')) {
                this.userProfileDisplayClick(true);
                event.stopPropagation();
            }
            if ($target.closest(".mainMenuContent").length) {
                if (!$target.closest(".profile-display-menu").length) {
                    this.newFeaturesConsumerService.closeReleaseList();
                    this.downloadModalService.closeDownloads();
                }
            }
        });
        this.userService$.currentUser$.subscribe((user) => {
            if(user.getTwoFactorKey() === null){
                const currentDate = new Date();
                let diff = moment(currentDate,"YYYY/MM/DD").diff(moment(user.getPasswordExpiration(),"YYYY/MM/DD"));
                diff = moment.duration(diff).asDays() * (-1) + 1;
                diff <= 5 ? this.enableAlterPassword = true : this.enableAlterPassword = false;
                this.daysExpirePassword = parseInt(diff.toFixed(0));
                const days = this.daysExpirePassword === 1 ? this._translateService.instant('auth.day') : this._translateService.instant('auth.days');
                this.passwordMessage = this.daysExpirePassword === 0 ? this._translateService.instant('auth.msgPasswordExpiringTomorrow') : `${this._translateService.instant('auth.msgPasswordExpiringInDays')} ${this.daysExpirePassword} ${days}`;
            }
        });
    }

    onChangeLogo(darkTheme){
        this.getLogo = this.loginService$.getLogo(darkTheme);
    }

    alterPassword() {
        this.enableAlterPassword = false;
        this.router.navigate(['/profile'], {queryParams: {mode:'ALTER_PASSWORD'}});
    }

    redirectToBasePage() {
        sessionStorage.setItem('lastPage', '/index');
        const user = this.userService$.getCurrentUser();
        if(user.has('SPECIFIC_USER')) {
            this.router.navigate([SPECIFIC_USER_ROUTES.find((route) => user.has(route.permission)).route || '']);
        } else {
            this.router.navigate(['']);
        }
    }

    permitAprrovalFlow(): boolean {
        const userClientCodes = [];
        this._userCacheService.getClientGroups().map((c) => userClientCodes.push(c['code']));
        return (_.intersection(userClientCodes, CLIENT_GROUPS_PERMITED.approval_flow).length > 0) && this.userService$.getCurrentUser().has('APPROVAL_FLOW');
    }

    userProfileDisplayClick(close?) {
        this.hiddenMenu = close ? true : !this.hiddenMenu;
    }

    getAlertMargin() {
        const quantity = this.headerNavService.alertList.notificationQty.toString().length || 1;
        return `${(quantity === 1 ? 2 : quantity) * 10}px`;
    }

    trackByFn(index: number, item: any) {
        return index;
    }

    get automotiveRouter(): string {
        return  "/report/followup-automotive";
    }

    onChangeThemeUserPreferences(darkTheme: boolean) {
        this._saveThemePreference(darkTheme);
    }

    private _saveThemePreference(darkTheme: boolean) {
        this._userCacheService.setUserDarkTheme(darkTheme);
        this.userPreference.darkTheme = darkTheme;
        this.onChangeLogo(this.userPreference.darkTheme);
        if (!this.userPreference.readOnly) {
            this.userPreferencesService$.savePreference(this.userPreference).subscribe(
                () => {},
                error => {
                    this._utilsService.errorHandler(error, 'Tema');
                }
            )
        }
    }
}
