export const FINANCIAL_GUARANTEE_ATTRIBUTES = [
    { key: 'waiver', title: 'Waiver' },
    { key: 'endorsement', title: 'Aval' },
    { key: 'comfortLetterMatriz', title: 'Confort Letter Matriz' },
    { key: 'corporateGuarantee', title: 'Fiança Corporativa' },
    { key: 'partnersGuarantee', title: 'Fiança Sócios' },
    { key: 'promissoryNote', title: 'Nota Promissória' },
    { key: 'guarantee', title: 'Arras' },
    { key: 'bankGuarantee', title: 'Fiança Bancária' },
    { key: 'customerBalance', title: 'Balanço do Cliente' },
    { key: 'standbyLetterOfCredit', title: 'SBLC (Stand by Letter of Credit)' }
];
