import { environment } from '#environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class DocumentsGedCRUDService {

    constructor(private http: HttpClient) { 
    }

    public getDocumentsConfiguration(clientGroupCode): Observable<any> {
        return this.http.get(`${ environment.endpoints.documentQueryService}/v1/search-client-config/${clientGroupCode}`);
    }

    public saveDocumentsConfiguration(clientGroupCode, configuration): Observable<any> {
        return this.http.put(`${ environment.endpoints.documentCommandService}/v1/update-client-config/${clientGroupCode}`, configuration);
    }

}