<div class="container-tracking-container">
    <div class="map-container">
        <loader *ngIf="asyncInit || loadingTranslate"></loader>
        <div id="mapBox" style="height: 500px; width: 100%; margin-bottom: 25px;"></div>
    </div>

    <div class="title-information">
        <cmx-help-modal
            [help-title]="'dashboard.title::newTrafficMap' | translate"
            [help-description]="helpDescription"
            [help-video-src]="'assets/videos/icone-informativo/dashboardsAtualizado.mp4'"
            help-video-name="{{'common::strings.dashboard' | translate }} {{ 'dashboard.title::newTrafficMap' | translate }}"
        ></cmx-help-modal>
        <h5>{{ 'dashboard.title::newTrafficMap' | translate }}</h5>
    </div>

    <div class="moveBtn">
        <div class="moveLine"></div>
    </div>

    <div id="searchLine" class="reduzed">
        <div class="search-container left" style="margin-right: 30px">
            <cmx-filter-search
                type="IMP / REF"
                width="140px"
                [types]="['IMP / REF']"
                [value]="filterState.getSearch()"
                [disableAplly]="true"
                (onInput)="onSearch($event)"
                (onApply)="applyFilter()"
            ></cmx-filter-search>
        </div>
        <div class="search-container right">
            <div class="input-group">
                <cmx-dropdown
                    [styles]="{width: '190px'}"
                    name="clients"
                    [data]="options.clients"
                    [ngModel]="filterState.getClientGroups()"
                    (ngModelChange)="onClientChanged($event)"
                    [settings]="dropdownSettings.clientGroups"
                ></cmx-dropdown>
            </div>
            <div class="input-group">
                <cmx-filter-datepicker
                    id="container-tracking-datepicker"
                    [date]="'dashboard::newTrafficMap.estimatedBerthing' | translate"
                    [inverted]="true"
                    [lockable]="true"
                    [startDate]="filterState.getStartDate()"
                    [endDate]="filterState.getEndDate()"
                    (onChangeDates)="datesChanged($event)"
                ></cmx-filter-datepicker>
            </div>
            <div
                *ngIf="filterState.getSearch().length !== 0"
                class="disableSearchContainer"
                [title]="'common::messages.filtersDisabledOnSearch' | translate"
            >
            <span class="material-icons iconDisableSearchContainer">error</span></div>
        </div>
    </div>

    <div *ngIf="summary.totalVessel || summary.totalImps" id="container-tracking-summary-container">
        {{ summary?.totalVessel }} {{ summary?.totalVessel === 1 ? ('dashboard::newTrafficMap.ship' | translate) : ('dashboard::newTrafficMap.ships' | translate) }} •
        {{ summary?.totalImps }} {{ summary?.totalImps === 1 ? ('dashboard::newTrafficMap.imp' | translate) : ('dashboard::newTrafficMap.imps' | translate) }}
    </div>

    <div class="imp-list-container">
        <div *ngIf="filteredImpList.length && pages.length" id="imp-list-content">
            <div *ngIf="page !== 0" (click)="changePage(-1)" class="arrow left">
                <span class="material-icons">arrow_left</span>
            </div>
            <ng-container *ngFor="let vessel of array(impsPerPage).fill(0); let i = index">
                <div *ngIf="filteredImpList[i + (page * impsPerPage)]?.imp"
                (click)="openVesselModal(null, filteredImpList[i + (page * impsPerPage)]?.firstImp, filteredImpList[i + (page * impsPerPage)]?.imp)" class="imp-container">
                    <img src="assets/img/icons/transito-checked.png" alt="">
                    <span class="vessel-imp">{{ filteredImpList[i + (page * impsPerPage)]?.imp }}</span>
                    <span class="vessel-eta">
                        <span class="material-icons">timer</span>
                        {{
                            filteredImpList[i + (page * impsPerPage)]?.vesselEta | localeDatePipe:'shortDate' || ('dashboard::newTrafficMap.noForecast' | translate)
                        }}
                    </span>
                </div>
            </ng-container>
            <div *ngIf="pages.length > 1 && page < pages.length - 1" (click)="changePage(1)" class="arrow right">
                <span class="material-icons">arrow_right</span>
            </div>
        </div>
        <div id="grey-bar"></div>
    </div>

    <cmx-custom-dialog
        id="container-tracking-vessel-modal"
        [hideHeader]="true"
        heightModal="270px"
        content="full"
        animation="bottom-top"
        customColorBorderTop="grey"
        [overlay]="true"
        (modal-closed)="closeVesselModal()">

        <ng-container cmx-custom-dialog-header>
            {{ selectedVessel?.vehicleName }}
        </ng-container>

        <ng-container cmx-custom-dialog-body>
            <loader *ngIf="asyncVesselModal || loadingTranslate"></loader>
            <div *ngIf="!asyncVesselModal" class="modal-container">
                <div class="vehicle-container">
                    <div class="title-container">
                        <img src="assets/img/icons/vessel.svg" alt="">
                        <span style="margin-right: 10px">{{ selectedVessel?.vehicleName }}</span>
                        <cmx-switch
                            *ngIf='selectedVesselImps.length > 1'
                            style="overflow: auto; padding-bottom: 4px"
                            [options]="selectedVesselImps"
                            [selected]="selectedVesselImp"
                            (onSelect)="selectVesselImp($event)"
                        ></cmx-switch>
                    </div>
                    <div class="info-container">
                        <cmx-assembled-data
                            maxRowElements="5"
                            [data]="vesselInfo"
                        ></cmx-assembled-data>
                        <cmx-assembled-data
                            maxRowElements="5"
                            [data]="vesselSecoundaryInfo"
                        ></cmx-assembled-data>
                    </div>
                </div>
                <div class="timeline-container">
                    {{ 'dashboard::newTrafficMap.timeline' | translate }}
                    <div class="timeline-content">
                        <div *ngFor="let event of vesselTimelineInfo" class="event">
                            <ng-container *ngIf="(event.id === 'estimatedDeparture' && event.value) || event.id !== 'estimatedDeparture'">
                                <div class="event-icon">
                                    <div class="vertical-line"></div>
                                    <div class="horizontal-line"></div>
                                    <span class="material-icons" [style.color]="event.id === 'estimatedDocking' ? '#345683' : 'green'">{{ event.id === 'estimatedDocking' ? ' watch_later' : 'check_circle' }}</span>
                                </div>
                                <span *ngIf="event.value" class="date">{{ event.value | localeDatePipe }}</span>
                                <div *ngIf="event.value" class="dot">-</div>
                                <span class="name" [style.margin-left]="event.value ? '0px' : '5px'">{{ event?.title }}</span>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </cmx-custom-dialog>
</div>
