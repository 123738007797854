import { environment } from '#environment';
import { FilterStateService } from '#services/_filters/filter-state.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { EventSourceService } from '#services/_eventSource/eventSource.service';
import { DownloadService } from '#services/_download/download.service';
import { UtilsService } from '#services/_utils/utils.service';
import { UserService } from "app/auth/_services/user.service";
import { ReportPreferencesService } from '#services/_report-preferences/report-preferences.service';
import { FeatureFlagService } from '#services/_feature-flag/feature-flag-service';

@Injectable()
export class CostsService {

    exportAsync: boolean = false;
    customizeAsync: boolean = true;
    detailedPage: boolean = false;
    selectedcategoryName: string;
    selectedsubCategoryName: string;
    detailGroupTransition: string = '';
    detailColumnTransition: string = '';
    currentUser;
    datamartFilters = [];
    useMongodb: boolean = false;
    private _filterState: any = this._filterStateService.getCostsFilter();

    constructor(
        private _http: HttpClient,
        private _reportPreferencesService: ReportPreferencesService,
        private _filterStateService: FilterStateService,
        private _eventChannel: EventSourceService,
        private _downloadService: DownloadService,
        private _utilsService: UtilsService,
        private _userService: UserService,
        private _featureFlagService: FeatureFlagService,
    ) {
        this.getCostsFeatureFlag().then(() => {
            this._userService.currentUser$.subscribe((user) => {
                this.currentUser = user;
                this.datamartFilters = this.determineDatamartFilters(user);
            })
        });
    }

    getCosts(request): Observable<any> {
        return this._http.get(`${environment.endpoints.expensesService}/summary/importationExpenses`, request);
    }

    getDetailedCosts(request, offset): Observable<any> {
        request.params = request.params.append('showUc', this._filterState.getShowUc());
        request.params = request.params.append('page', `${offset}`);
        request.params = request.params.append('size', '25');
        if ((request.params.get("context") === 'item' || request.params.get("context") === 'sku') 
            && this.useMongodb) {
            return this._http.get(`${environment.endpoints.expensesMongodbService}/importationExpenses`, request);
        }
        return this._http.get(`${environment.endpoints.expensesService}/importationExpenses`, request);
    }

    async getCostsFeatureFlag(): Promise<void> {
        this.useMongodb = await this._featureFlagService.isFeatureFlagEnabledV2('expenses_mongodb_enabled');
    }

    getAutomotiveDomainFilters(): Observable<any> {
        return this._http.get(`${environment.endpoints.importationMongoDBService}/chassis/filters`);
    }

    getContainerCosts(request): Observable<any> {
        return this._http.get(`${environment.endpoints.expensesService}/expenses-container`, request);
    }

    getSKUCosts(request): Observable<any> {
        return this._http.get(`${environment.endpoints.expensesService}/expenses-sku`, request);
    }

    getUtilCostColumns(): Observable<any> {
        return this._http.get(`${environment.endpoints.expensesService}/importationExpenses/expenses-columns-names`);
    }

    export(url, headers, params ): Observable<any> {
        return this._http.get(`${url}${params}`, {
            headers: {
                'X-Requested-Client-Groups': headers
            },
        });
    }

    exportCosts(type?, consolidated?: boolean) {
        this.callExportMessage();
        let params = '';
        params += `?startDate=${this._filterState.getSearch() === ''
                ? moment(this._filterState.getStartDate(), 'DD/MM/YYYY').format('YYYY-MM-DD')
                : moment().subtract(30, 'days').format('YYYY-MM-DD')
        }`;
        params += `&endDate=${this._filterState.getSearch() === ''
                ? moment(this._filterState.getEndDate(), 'DD/MM/YYYY').format('YYYY-MM-DD')
                : moment().format('YYYY-MM-DD')
        }`;
        params += `&profitCenters=${this._filterState.getSearch() === '' && this._filterState.getAutomotiveMode() === 'Desligado'
                ? this._filterState.getProfitCenters().map((c) => c['id']).join(',') : ''
        }`;
        params += `&models=${this._filterState.getSearch() === '' && this._filterState.getAutomotiveMode() === 'Ligado'
                ? this._filterState.getModels().map((c) => c['id']).join(',') : ''
        }`;
        params += `&colors=${this._filterState.getSearch() === '' && this._filterState.getAutomotiveMode() === 'Ligado'
                ? this._filterState.getColors().map((c) => c['id']).join(',') : ''
        }`;
        params += `&manufacturerYears=${this._filterState.getSearch() === '' && this._filterState.getAutomotiveMode() === 'Ligado'
                ? this._filterState.getManufacturerYears().map((c) => c['id']).join(',') : ''
        }`;
        params += `&modelYears=${this._filterState.getSearch() === '' && this._filterState.getAutomotiveMode() === 'Ligado'
                ? this._filterState.getModelYears().map((c) => c['id']).join(',') : ''
        }`;
        params += `&imp=${this._filterState.getImpSearch()}`;
        params += `&chassisNumbers=${this._filterState.getChassiSearch()}`;
        params += `${type === 'modal' ? `&expensesCategory=${this.selectedcategoryName}` : ''}`;
        params += `${type === 'modal' ? `&expensesCategoryValue=${this.selectedsubCategoryName || ''}` : ''}`;
        params += `&showUc=${ this._filterState.getShowUc() }`
        params += `&context=${ this._filterState.getAutomotiveMode() === 'Ligado' ? 'automotive'
            : this._filterState.getContext().toLowerCase() }`;
            const headers = `${!this._filterState.getSearch() ?
                this._filterState.getClientGroups().map((c) => c['id']).join(',') : ''}`;
        const url = `${ environment.endpoints.expensesService}/expenses${
            type === 'modal' ? '/expenses-sku-report/async' : type === 'detail' ?
            '/expenses-detail-report/async' : '/analytical-report/async' }`;
        params += `&preferencesId=${consolidated ? '' : this._reportPreferencesService?.selectedRightButton?.code || ''}`;
        params += `&showDuplicates=${this._filterState.getShowDuplicatas() &&
            (this._filterState.getAutomotiveMode() === 'Ligado' || this._filterState.getContext() === 'imp')}`;
        if (type === 'detail') {
            this._exportDetails(url, headers, params);
        } else {
            this._eventChannel.openEventSource(url, headers, params);
        }
    }

    determineDatamartFilters(user) {
        const filters = this._filterState.getAutomotiveMode() === 'Desligado' ? [
            { title: `${user.has('INTERNAL_FILTERS') ? 'CR' : ''}`, field: 'profitCenters' },
            { title: `${user.has('INTERNAL_FILTERS') ? 'Clientes' : ''}`, field: 'clientGroups' },
            { title: 'UC', field: 'showUc' },
            { title: 'Por', field: 'context' },
            { title: 'Período', field: 'period' }
        ] : [
            { title: `${!user.has('AUTOMOTIVE_USER') && user.has('INTERNAL_FILTERS') ? 'Clientes' : ''}`, field: 'clientGroups' },
            { title: 'Modelos', field: 'models' },
            { title: 'Cores', field: 'colors' },
            { title: 'Ano Modelo', field: 'modelYears' },
            { title: 'Ano Fabricação', field: 'manufacturerYears' },
            { title: 'UC', field: 'showUc' },
            { title: '', field: 'context' },
            { title: 'Período', field: 'period' }
        ];
        if (this._filterState.getContext() === 'imp' || this._filterState.getAutomotiveMode() === 'Ligado') {
            filters.push({ title: 'Duplicatas', field: 'showDuplicates' });
        }
        return filters;
    }

    determineUtilColumns(defaultColumns, utilColumns) {
        defaultColumns = _.cloneDeep(defaultColumns);
        Object.keys(utilColumns).forEach((key) => {
            defaultColumns.groups.forEach((group) => {
                if (group.label === key) {
                    group.columns.forEach((column) => {
                        if (!utilColumns[key].includes(column.label)) {
                            group.columns = group.columns.filter((obj) => {
                                return obj.label !== column.label;
                            });
                        }
                    });
                }
            });
        });
        const notEmpty = utilColumns.hasOwnProperty('Despesas Financeiras') ||
            utilColumns.hasOwnProperty('Despesas Operacionais') || utilColumns.hasOwnProperty('Despesas Servicos');
        return notEmpty ? defaultColumns : this._getEmptyUtilColumns(defaultColumns);
    }

    private _exportDetails(url, headers, params) {
        this.export(url, headers, params).subscribe(() => {
            this._downloadService.onAsyncReport();
        }, (error) => {
            this._utilsService.errorHandler(error, 'Custos Detalhados');
        });
    }

    private callExportMessage() {
        this.exportAsync = true;
        setTimeout(() => {
            this.exportAsync = false;
        }, 5000);
    }

    private _getEmptyUtilColumns(defaultColumns) {
        const groups = defaultColumns?.groups?.filter((group) => {
            return group.label !== 'Despesas Financeiras' && group.label !== 'Despesas Operacionais' && group.label !== 'Despesas Servicos';
        });
        return { ...defaultColumns, groups }
    }

}
