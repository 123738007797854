import { DomainTypeService } from '#services/_domain/domainType.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class FollowUpUnifiedDatamartService {

    constructor(
        private _http: HttpClient,
        private _domainTypeService: DomainTypeService,
    ) { }

    async getDatamartConfig(datamartName, serviceId, generatorId, qualifier, isCustomer) {
        
        const followupServiceURL = await this._domainTypeService.getFollowUpURL();
        let params = new HttpParams();
        params = params.append('isCustomer', isCustomer);
        return this._http.get(`${followupServiceURL}/followup-datamart/${serviceId}/${generatorId}/${qualifier}`, { params } );
    }

    async setDatamartConfiguration(datamartName, serviceId, generatorId, deliveryOptions, qualifier, parameters, modelId, isCustomer) {
        
        const followupServiceURL = await this._domainTypeService.getFollowUpURL();
        let headers = new HttpHeaders();
        let clientGroupCodes = parameters.clientGroupCodes;
        headers = headers.set('X-Requested-Client-Groups', clientGroupCodes.join(','));
        let params = new HttpParams();
        params = params.append('isCustomer', isCustomer);
        params = params.append('modelId', modelId);
        const allClientsSelected = parameters.allClientsSelected ?? false;
        delete parameters.allClientsSelected;
        const body = {
            datamartName,
            serviceId,
            generatorId,
            deliveryOptions,
            parameters,
            qualifier,
            intervalType: 'LAST_N_MONTHS',
            intervalValue: parameters.interval ? parameters.interval[0] : '12',
            periodicityType: 'MINUTES',
            periodicityValue: parameters.periodicity ? parameters.periodicity[0] : '120',
            allClientsSelected,
        };
        return this._http.put(`${followupServiceURL}/followup-datamart`, body, { params, headers });
    }
}
