import { AppModule } from 'app/app.module';
import * as _ from 'lodash';

import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';
import { IQuotationFreightRatesFilter } from '../interfaces/simulate-freight-rate-filter.interface';

export class SimulateFreightRateFilter {

    private _userCacheService = AppModule.injector.get(UserCacheService);
    private _userPreferencesService = AppModule.injector.get(UserPreferencesService);
    private _initialQuotationFreightRatesFilter: IQuotationFreightRatesFilter = <IQuotationFreightRatesFilter>{};
    private _quotationFreightRatesFilter: IQuotationFreightRatesFilter = <IQuotationFreightRatesFilter>{
        internationalCargoType: [],
        internationalOrigin: [],
        internationalDestination: [],
        nationalOrigin: [],
        nationalDestination: [],
        nationalTransport: [],
    };

    constructor(filter?: SimulateFreightRateFilter) {
        this._quotationFreightRatesFilter = this._userPreferencesService.defineFilter(this._userCacheService.getUserPreferences(), 'quotationFreightRatesFilter', this._quotationFreightRatesFilter, filter);
        this._initialQuotationFreightRatesFilter = _.cloneDeep(this._quotationFreightRatesFilter);
    }

    getInternationalCargoType() { return this._quotationFreightRatesFilter.internationalCargoType }
    getInternationalOrigin() { return this._quotationFreightRatesFilter.internationalOrigin }
    getInternationalDestination() { return this._quotationFreightRatesFilter.internationalDestination }
    getNationalOrigin() { return this._quotationFreightRatesFilter.nationalOrigin }
    getNationalDestination() { return this._quotationFreightRatesFilter.nationalDestination }
    getNationalTransport() { return this._quotationFreightRatesFilter.nationalTransport }

    setInternationalCargoType(internationalCargoType): void { this._quotationFreightRatesFilter.internationalCargoType = internationalCargoType }
    setInternationalOrigin(internationalOrigin): void { this._quotationFreightRatesFilter.internationalOrigin = internationalOrigin }
    setInternationalDestination(internationalDestination): void { this._quotationFreightRatesFilter.internationalDestination = internationalDestination }
    setNationalOrigin(nationalOrigin): void { this._quotationFreightRatesFilter.nationalOrigin = nationalOrigin }
    setNationalDestination(nationalDestination): void { this._quotationFreightRatesFilter.nationalDestination = nationalDestination }
    setNationalTransport(nationalTransport): void { this._quotationFreightRatesFilter.nationalTransport = nationalTransport }

    updatePreference() {
        this._initialQuotationFreightRatesFilter = this._userPreferencesService.verifyFilterChanges(
            this._initialQuotationFreightRatesFilter, this._quotationFreightRatesFilter, 'quotationFreightRatesFilter', this._userCacheService.getUserPreferences().id);
    }

}
