<div *ngIf="asyncExport" class="fullLoader">
    <span class="exporting">Solicitando Exportação</span>
    <loader></loader>
</div>

<div class="moveBtn">
    <div class="moveLine"></div>
</div>

<cmx-marker-filter
    [filters]="filterStatus"
    [preSelected]="filterState.getBoardingStatus()"
    type="status"
    [multiple]="true"
    [hasSelectAll]="false"
    [leftDistance]="230"
    (onSelected)="onSelectedStatus($event)"
></cmx-marker-filter>

<div class="title-information">
    <span class="analyticsName">{{ 'dashboard.title::boardingStatus' | translate }}</span>
    <cmx-help-modal
        [help-video-src]="'assets/videos/icone-informativo/dashboardsAtualizado.mp4'"
        help-video-name="{{'common::strings.dashboard' | translate }} {{ 'dashboard.title::boardingStatus' | translate }}"
        [help-title]="'dashboard.title::boardingStatus' | translate"
        [help-description]="helpDescription">
    </cmx-help-modal>

    <h5 id="quickTitle">{{ 'dashboard.title::boardingStatus' | translate }}</h5>
</div>

<div id="searchLine" class="reduzed" *ngIf="currentUser$ | async as user">
    <ng-container *ngFor="let dropdownType of dropdownTypes; trackBy: trackByFn">
        <div class="input-group" *ngIf="dropdownType === 'client' ? user.has('INTERNAL_FILTERS') : true">
            <span *ngIf="dropdownType !== 'client'" class="input-group-addon"></span>
            <cmx-dropdown
                [styles]="{ width: '155px' }"
                [style.margin-left]="dropdownType !== 'client' ? '-15px' : ''"
                [id]="dropdownType"
                [name]="dropdownType"
                [data]="dropdownOptions[dropdownType].options"
                [ngModel]="getDropdownSelected(dropdownType)"
                (ngModelChange)="setDropdownSelected(dropdownType, $event)"
                [settings]="dropdownSettings[dropdownType]"
            ></cmx-dropdown>
        </div>
    </ng-container>
    <div class="input-group">
        <cmx-filter-datepicker
            [date]="'Por ETD'"
            [startDate]="filterState.getStartDate()"
            [endDate]="filterState.getEndDate()"
            (onChangeDates)="datesChanged($event)"
        ></cmx-filter-datepicker>
    </div>
    <button (click)="applyFilter()" class="applyImpStatus">
        <i class="fa fa-check"></i>
    </button>
</div>

<div class="downloadButton">
    <span class="hidden-type" (click)="export('xls')">XLS</span>
    <em class="flaticon-download"></em>
</div>

<div id="infoLine" *ngIf="!asyncBoardingTable && gridRows !== undefined">
    <cmx-summary-line
        [dataList]="summaryLine"
    ></cmx-summary-line>
</div>


<div class="gridContainer">
    <div id="channel-status-loader" *ngIf="asyncBoardingTable"><loader></loader></div>

    <cmx-custom-grid
        id="boarding-status-grid"
        [style.visibility]="!asyncBoardingTable ? 'visible' : 'hidden'"
        [columns]="gridColumns"
        [rows]="gridRows"
        [styles]="gridStyles"
        (onCellClick)="onCellClick($event)"
        (onSortColumn)="onSortColumn($event)"
        [options]="{ useEmptyDataMessage: true, showEmptyDataHeader: true}"
    ></cmx-custom-grid>

    <div *ngIf="pages.length > 1">
        <pagination [numberOfPages]="pages.length" [queryId]="queryId" (onPageChange)="changePage($event)"></pagination>
    </div>
</div>

<!-- vehiclesModal -->
<cmx-custom-dialog
    [id]="'boarding-status-vehicle-dialog'"
    [title]="'Quantidade'"
    animation="left-right"
    [closeButton]="true"
    [widthModal]="25"
    (modal-closed)="onCloseVehiclesModal()"
>
    <ng-container cmx-custom-dialog-body>
        <div class="boarding-status-vehicle-dialog-container" *ngIf="showModal">
            <p class="boardingTitle">Embarque {{ boarding }}</p>

            <div class="boarding-status-vehicles-grid-content">
                <cmx-custom-grid
                    id="boarding-status-vehicle-grid"
                    [columns]="gridVehiclesColumns"
                    [rows]="gridVehiclesRows"
                    [options]="{ forceFitColumns: true }"
                    [styles]="{ 'height': '225px', 'margin-top': '0px'}"
                ></cmx-custom-grid>
            </div>
        </div>
    </ng-container>
</cmx-custom-dialog>
