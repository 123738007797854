export const PREMISE_FORM_SCRIPT = {
    // Premissa Comercial (Premissa)
    'premise-premise-form': {
        noFocus: { newFocus: 'effectiveDateDatepicker', datepicker: true },
        effectiveDateDatepicker: { newFocus: 'clientContractStatus', dropdown: true },
        clientContractStatus: { newFocus: 'recurrenceOperation', dropdown: true },
        recurrenceOperation: { newFocus: 'trader', dropdown: true },
        trader: { newFocus: 'director' },
        director: { newFocus: 'operationalResponsible', dropdown: true },
        operationalResponsible: { newFocus: 'legalName' },
        legalName: { newFocus: 'tradeName' },
        tradeName: { newFocus: 'headquartersCnpj' },
        headquartersCnpj: { newFocus: 'branchCnpj' },
        branchCnpj: { newFocus: 'stateRegistration' },
        stateRegistration: { newFocus: 'nonIcmsContributingClient', switch: true },
        nonIcmsContributingClient: { newFocus: 'address' },
        address: { newFocus: 'number' },
        number: { newFocus: 'complement' },
        complement: { newFocus: 'zipCode' },
        zipCode: { newFocus: 'neighborhood' },
        neighborhood: { newFocus: 'state', dropdown: true },
        state: { newFocus: 'city' },
        city: { newFocus: 'conexosClientCode' },
        conexosClientCode: { newFocus: 'cmsClientCode' },
        cmsClientCode: { newFocus: 'cmsClientGroupCode' },
        cmsClientGroupCode: { newFocus: 'costCenter', dropdown: true },
        costCenter: { newFocus: 'segment', dropdown: true },
        segment: { newFocus: 'annualRevenuePotential' },
        annualRevenuePotential: { newFocus: 'mainProducts' },
        mainProducts: { newFocus: 'operationDetails' },
        operationDetails: { newFocus: 'purchaseCurrency', dropdown: true },
        purchaseCurrency: { newFocus: 'annualImportValue' },
        annualImportValue: { newFocus: 'leasingOperation', switch: true },
        leasingOperation: { newFocus: 'acquiringBank' },
        acquiringBank: { newFocus: 'acquiringBankCnpj' },
        // inicio de lista
        acquiringBankCnpj: { newFocus: 'name', list: true, first: true },
        name: { newFocus: 'position', list: true },
        position: { newFocus: 'department', list: true },
        department: { newFocus: 'commercialPhone', list: true },
        commercialPhone: { newFocus: 'mobilePhone', list: true },
        mobilePhone: { newFocus: 'email', list: true, last: true },
        // término de lista
        email: { newFocus: 'effectiveDateDatepicker', datepicker: true },
    },
    // Premissa Comercial (Potenciais Soluções)
    'premise-potential-solutions-form': {
        noFocus: { newFocus: 'importationModality', dropdown: true },
        importationModality: { newFocus: 'comexportBranch', dropdown: true },
        comexportBranch: { newFocus: 'comexportSpecialRegime', dropdown: true },
        comexportSpecialRegime: { newFocus: 'hasUnlimitedRadar', switch: true },
        hasUnlimitedRadar: { newFocus: 'linkedToComexportRadar', switch: true },
        // inicio de lista
        linkedToComexportRadar: { newFocus: 'productDescription', list: true, first: true },
        productDescription: { newFocus: 'productNcm', list: true },
        productNcm: { newFocus: 'exTariff', switch: true , list: true },
        exTariff: { newFocus: 'icmsSubstitution', switch: true, list: true },
        icmsSubstitution: { newFocus: 'productWithoutNationalSimilar', switch: true, list: true },
        productWithoutNationalSimilar: { newFocus: 'monoPisCofins', switch: true, list: true },
        monoPisCofins: { newFocus: 'accumulatesPisCofins', switch: true, list: true },
        accumulatesPisCofins: { newFocus: 'productIsUsed', switch: true, list: true },
        productIsUsed: { newFocus: 'productIsCopperOrSteel', switch: true, list: true },
        productIsCopperOrSteel: { newFocus: 'productDestinedAnotherState', switch: true, list: true },
        productDestinedAnotherState: { newFocus: 'incoterm', dropdown: true, list: true },
        incoterm: { newFocus: 'number', list: true },
        number: { newFocus: 'yearOfManufacture', list: true },
        yearOfManufacture: { newFocus: 'countryOfRegistration', dropdown: true, list: true },
        countryOfRegistration: { newFocus: 'bluebookValue', list: true },
        bluebookValue: { newFocus: 'productGeneralObservations', list: true, last: true },
        // término de lista
        productGeneralObservations: { newFocus: 'importationModality', dropdown: true },
    },
    // Premissa Comercial (Tributário)
    'premise-tributary-form': {
        noFocus: { newFocus: 'mainDestinationMerchandise', dropdown: true },
        mainDestinationMerchandise: { newFocus: 'typeTaxOperation', dropdown: true },
        typeTaxOperation: { newFocus: 'customerTaxIncentives', dropdown: true },
        customerTaxIncentives: { newFocus: 'legalNameProductRecipient' },
        legalNameProductRecipient: { newFocus: 'cnpjProductRecipient' },
        cnpjProductRecipient: { newFocus: 'taxStudyId', dropdown: true },
        taxStudyId: { newFocus: 'relevantConsiderationsTaxConsulting' },
        relevantConsiderationsTaxConsulting: { newFocus: 'mainDestinationMerchandise', dropdown: true },
    },
    // Premissa Comercial (Jurídico & Compliance)
    'premise-legal-compliance-form': {
        noFocus: { newFocus: 'responsibleLegalAnalysis', dropdown: true },
        responsibleLegalAnalysis: { newFocus: 'companyName' },  
        companyName: { newFocus: 'companyCnpj' },
        companyCnpj: { newFocus: 'companyDossierDateDatepicker', datepicker: true },
        companyDossierDateDatepicker: { newFocus: 'companyCadastralSituation', switch: true },
        companyCadastralSituation: { newFocus: 'hasNda', switch: true },
        // inicio de lista
        hasNda: { newFocus: 'partnersName', list: true, first: true },
        partnersName: { newFocus: 'partnersCpf', list: true },
        partnersCpf: { newFocus: 'partnersDossierDateDatepicker', list: true, datepicker: true },
        partnersDossierDateDatepicker: { newFocus: 'partnersCadastralSituation', list: true, switch: true, last: true },
        // término de lista
        partnersCadastralSituation: { newFocus: 'partnersObservations' },
        partnersObservations: { newFocus: 'responsibleLegalAnalysis', dropdown: true },
    },
    // Premissa Comercial (Financeiro)
    'premise-financial-form': {
        noFocus: { newFocus: 'financialAnalysisResponsible', dropdown: true },
        financialAnalysisResponsible: { newFocus: 'approvedCashFlow', switch: true },
        approvedCashFlow: { newFocus: 'approvedExposureFlow', switch: true },
        approvedExposureFlow: { newFocus: 'preAnalysisPerformed', switch: true },
        preAnalysisPerformed: { newFocus: 'currency', dropdown: true },
        currency: { newFocus: 'operationValue' },
        operationValue: { newFocus: 'fobCurrency', dropdown: true },
        fobCurrency: { newFocus: 'fobOperationValue' },
        fobOperationValue: { newFocus: 'waiver', switch: true },
        waiver: { newFocus: 'comfortLetterMatriz', switch: true },
        comfortLetterMatriz: { newFocus: 'corporateGuarantee', switch: true },
        corporateGuarantee: { newFocus: 'partnersGuarantee', switch: true },
        partnersGuarantee: { newFocus: 'promissoryNote', switch: true },
        promissoryNote: { newFocus: 'guarantee', switch: true },
        guarantee: { newFocus: 'guaranteePercent' },
        guaranteePercent: { newFocus: 'guaranteeValue', dropdown: true },
        guaranteeValue: { newFocus: 'bankGuarantee', switch: true },
        bankGuarantee: { newFocus: 'customerBalance', switch: true },
        customerBalance: { newFocus: 'standbyLetterOfCredit', switch: true },
        standbyLetterOfCredit: { newFocus: 'endorsement', switch: true },
        endorsement: { newFocus: 'endorsementPercent' },
        endorsementPercent: { newFocus: 'endorsementValue', dropdown: true },
        // lista dentro de lista a ser considerada
        endorsementValue: { newFocus: 'deadlineReceivingExchangePortion' },
        deadlineReceivingExchangePortion: { newFocus: 'interestIndexFinancialCostExchange', dropdown: true },
        interestIndexFinancialCostExchange: { newFocus: 'responsibleNationalizationPayment', dropdown: true },
        responsibleNationalizationPayment: { newFocus: 'receiptDeadlineNationalizationExpenses' },
        receiptDeadlineNationalizationExpenses: { newFocus: 'responsiblePaymentNationalizationExpenses', dropdown: true },
        responsiblePaymentNationalizationExpenses: { newFocus: 'nationalizationPaymentTerm' },
        nationalizationPaymentTerm: { newFocus: 'interestGracePeriod' },
        interestGracePeriod: { newFocus: 'spreadPercentage' },
        spreadPercentage: { newFocus: 'spreadInterestPeriodicity', dropdown: true },
        spreadInterestPeriodicity: { newFocus: 'chargeIOF', switch: true },
        chargeIOF: { newFocus: 'interestIndexFinancialCost', dropdown: true },
        // inicio de lista
        interestIndexFinancialCost: { newFocus: 'comexportFeeType', dropdown: true, list: true, first: true },
        comexportFeeType: { newFocus: 'percentageFeeCharged', list: true },
        percentageFeeCharged: { newFocus: 'feeCalculationBase', dropdown: true, list: true },
        feeCalculationBase: { newFocus: 'percentageIcmsRate', dropdown: true, list: true },
        percentageIcmsRate: { newFocus: 'currencyFee', list: true },
        currencyFee: { newFocus: 'fixedFeeCharged',dropdown: true, list: true },
        fixedFeeCharged: { newFocus: 'fixedFeeBillingBase', dropdown: true, list: true },
        fixedFeeBillingBase: { newFocus: 'presumedCreditTaxIncentive', list: true },
        presumedCreditTaxIncentive: { newFocus: 'generalFeeObservations', list: true, last: true },
        // término de lista
        // inicio de lista
        generalFeeObservations: { newFocus: 'applyTaxIncentiveTransfer', switch: true, list: true, first: true },
        applyTaxIncentiveTransfer: { newFocus: 'typeDiscountApplied', dropdown: true, list: true },
        typeDiscountApplied: { newFocus: 'calculationBasisApplyingDiscount', dropdown: true, list: true },
        calculationBasisApplyingDiscount: { newFocus: 'percentageIcmsRateConditionsDiscount', dropdown: true, list: true },
        percentageIcmsRateConditionsDiscount: { newFocus: 'presumedCreditTaxIncentiveFiscal', list: true },
        presumedCreditTaxIncentiveFiscal: { newFocus: 'percentageDiscountCalculationBase', list: true },
        percentageDiscountCalculationBase: { newFocus: 'profitability', list: true },
        profitability: { newFocus: 'generalDiscountObservations', list: true, last: true },
        // presumedCreditTaxIncentive: { newFocus: 'generalDiscountObservations', list: true, last: true },
        // término de lista
        generalDiscountObservations: { newFocus: 'financialAnalysisResponsible', dropdown: true },
    },
    // Premissa Comercial (Despesas Operacionais)
    'premise-operational-expenses-form': {
        noFocus: { newFocus: 'internationalShipping', dropdown: true },
        internationalShipping: { newFocus: 'domesticShipping', dropdown: true },
        domesticShipping: { newFocus: 'internationalSecurePayment', dropdown: true },
        internationalSecurePayment: { newFocus: 'internationalInsurancePercentage' },
        internationalInsurancePercentage: { newFocus: 'forwardingAgent', dropdown: true },
        forwardingAgent: { newFocus: 'currencyDispatcher',  dropdown: true },
        currencyDispatcher: { newFocus: 'dispatcherValue' },
        dispatcherValue: { newFocus: 'billingBaseDispatcherValue', dropdown: true },
        billingBaseDispatcherValue: { newFocus: 'currencyExTariff', dropdown: true },
        currencyExTariff: { newFocus: 'exTariffValue' },
        exTariffValue: { newFocus: 'adValoremDeliveryShipping', switch: true },
        adValoremDeliveryShipping: { newFocus: 'internationalShipping', dropdown: true },
    },
}