<div class="mainNotificationsContainer" *ngIf="currentUser$ | async as user">
    <div *ngIf="!asyncNotifications || loadingTranslate" class="fullLoader"><loader></loader></div>
    <div *ngIf="asyncNotifications" class="notificationsListColumn">
        <div class="listColumn">
            <div class="headerContainer">
                <div class="balls">
                    <div [tooltip]="'page::config::notifications.systemAlert' | translate" class="ball"><img src="assets/img/icons/system.png" alt=""></div>
                    <div [tooltip]="'page::config::notifications.emailSending' | translate" class="ball"><img src="assets/img/icons/email.png" alt=""></div>
                    <div [tooltip]="'page::config::notifications.smsSending' | translate" class="ball"><div>SMS</div></div>
                    <div [tooltip]="'page::config::notifications.whatsappSending' | translate" class="ball"><img src="assets/img/icons/whatsapp.png" alt=""></div>
                </div>
                <span>{{ 'page::config::notifications.periodicity' | translate }}</span>
                <span *ngIf="user.has('INTERNAL_FILTERS')">{{ 'common::strings.clients' | translate }}</span>
            </div>
            <div class="notificationList">
                <div class="notification">
                    <div *ngIf="data.category.hasOwnProperty('children')" class="father">
                        <div style="margin-bottom: 8px">
                            <div class="left" id="{{ i }}" (click)="toogle(i, $event)">
                                <span class="toggle fa fa-caret-down"></span>
                                <span>{{ data.category.name }}</span>
                            </div>
                            <div class="right"></div>
                        </div>
                        <div *ngFor="let child of data.category.children; let i = index" class="single notification {{ i }}" >
                            <div class="left">
                                <span>{{ child.name }}</span>
                            </div>
                            <div class="right">
                                <div class="checks">
                                    <input
                                        type="checkbox"
                                        id="{{child.id}}-a"
                                        name="{{child.id}}-a"
                                        [checked]="child.mediums.SYSTEM" disabled
                                        (change)="child.mediums.SYSTEM = !child.mediums.SYSTEM">
                                    <input
                                        type="checkbox"
                                        id="{{child.id}}-b"
                                        name="{{child.id}}-b"
                                        [checked]="child.mediums.EMAIL"
                                        (change)="changeMedium(child,'EMAIL')">
                                    <input
                                        type="checkbox"
                                        id="{{child.id}}-c"
                                        name="{{child.id}}-c"
                                        [checked]="child.mediums.SMS" disabled
                                        (change)="child.mediums.SMS = !child.mediums.SMS">
                                    <input
                                        type="checkbox"
                                        id="{{child.id}}-d" name="{{child.id}}-d"
                                        [checked]="child.mediums.WHATS_APP" disabled
                                        (change)="child.mediums.WHATS_APP = !child.mediums.WHATS_APP">
                                </div>
                                <div [ngClass]="child.disabled ? 'disabled' : ''" class="frequency">
                                    <cmx-dropdown
                                        name="frequency"
                                        [data]="child.dropdown.frequency"
                                        [(ngModel)]='child.frequency'
                                        [settings]="child.dropdownSettingsFrequency"
                                    ></cmx-dropdown>
                                </div>
                                <div *ngIf="user.has('INTERNAL_FILTERS')" [ngClass]="child.disabled ? 'disabled' : ''" class="clients">
                                    <cmx-dropdown
                                        name="clients"
                                        [data]="child.dropdown.clients"
                                        [(ngModel)]='child.clients'
                                        [settings]="child.dropdownSettingsClients"
                                    ></cmx-dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!data.category.hasOwnProperty('children')" class="single">
                        <div class="left">
                            <span>{{ data.category.name }}</span>
                        </div>
                        <div class="right">
                            <div class="checks">
                                <input
                                    type="checkbox"
                                    id="{{data.category.id}}-a"
                                    name="{{data.category.id}}-a"
                                    [checked]="data.category.mediums.SYSTEM" disabled
                                    (change)="data.category.mediums.SYSTEM = !data.category.mediums.SYSTEM">
                                <input
                                    type="checkbox"
                                    id="{{data.category.id}}-b"
                                    name="{{data.category.id}}-b"
                                    [checked]="data.category.mediums.EMAIL"
                                    (change)="changeMedium(data.category,'EMAIL')">
                                <input
                                    type="checkbox"
                                    id="{{data.category.id}}-c"
                                    name="{{data.category.id}}-c"
                                    [checked]="data.category.mediums.SMS" disabled
                                    (change)="data.category.mediums.SMS = !data.category.mediums.SMS">
                                <input
                                    type="checkbox"
                                    id="{{data.category.id}}-d"
                                    name="{{data.category.id}}-d"
                                    [checked]="data.category.mediums.WHATS_APP" disabled
                                    (change)="data.category.mediums.WHATS_APP = !data.category.mediums.WHATS_APP">
                            </div>
                            <div [ngClass]="data.category.disabled ? 'disabled' : ''" class="frequency">
                                <cmx-dropdown
                                    size="md"
                                    name="frequency"
                                    [data]="data.category.dropdown.frequency"
                                    [(ngModel)]='data.category.frequency'
                                    [settings]="data.category.dropdownSettingsFrequency"
                                ></cmx-dropdown>
                            </div>
                            <div *ngIf="user.has('INTERNAL_FILTERS')" [ngClass]="data.category.disabled ? 'disabled' : ''" class="clients">
                                <cmx-dropdown
                                    size="md"
                                    name="clients"
                                    [data]="data.category.dropdown.clients"
                                    [(ngModel)]='data.category.clients'
                                    [settings]="data.category.dropdownSettingsClients"
                                ></cmx-dropdown>
                            </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="asyncNotifications" class="notificationsAddressColumn" [ngClass]="user.has('INTERNAL_FILTERS') ? '' : 'notInternalUser'">
        <div class="addressContainer">
            <cmx-splitter-line [title]="'page::config::notifications.emailList' | translate" [icon]="'mail'"></cmx-splitter-line>
            <div class="row">
                <div class="p-field col">
                    <label>{{ 'page::config::notifications.addressList' | translate }}</label>
                    <input type="text" pInputText [placeholder]="'page::config::notifications.addEmail' | translate" id="email" [(ngModel)]="email" />
                </div>
                <div class="col-md-auto align-self-end">
                    <cmx-button (click)="addEmail()">{{ 'system::actions.add' | translate }}</cmx-button>
                </div>
            </div>
            <div *ngIf="emailProperties != null" class="addressListContainer">
                <div *ngFor="let address of emailProperties.value; let i = index" class="address">
                    <div (click)="removeFrom('emailList', i)" class="deleteBall"><span class="material-icons">close</span></div>
                    <span>{{ address }}</span>
                </div>
            </div>
        </div>
        <cmx-button id="notifications-save" variant="success" (click)="saveConfiguration()">{{ 'system::actions.save' | translate }}</cmx-button>
    </div>
</div>

