<div [@openClose]="hidden ? true : false" class="cmx-confirmation-modal {{ hidden ? 'hidden' : '' }}" [ngStyle]="style">

    <div *ngIf="title || closeButton" class="dialog-header">

        <div *ngIf="title; else customTitleContent">
            <h5 class="dialog-title">
                {{ title }}
            </h5>
        </div>

        <ng-template #customTitleContent>
            <ng-content select="[cmx-confirmation-modal-header]"></ng-content>
        </ng-template>

        <ng-container *ngIf="closeButton">
            <span [style.flex]="'0 0 auto'"></span>
            <em (click)="close()" class="close-btn pointer la la-close"></em>
        </ng-container>
    </div>

    <ng-content select="[cmx-body]"></ng-content>
    <p class="question">{{ question }}</p>
    <div class="p-field textarea" *ngIf="enableInputArea">
        <span class="title-textarea">{{ titleInputArea }}</span>
        <textarea
            *ngIf="!currencyMaskOptions"
            pInputTextarea 
            class="field-textarea"
            [maxlength]="maxCharacters"
            (keydown.enter)="preventEnterKey($event)"
            [(ngModel)]="inputAreaValue"
            (input)="updateCharacterCount($event.target.value)"
            [required]="requiredInputArea"
            [mask]="maskInputArea"
        ></textarea>
        <input
            *ngIf="currencyMaskOptions"
            type="text"
            pInputText
            class="field-textarea"
            [maxlength]="maxCharacters"
            (keydown.enter)="preventEnterKey($event)"
            [(ngModel)]="inputAreaValue"
            [required]="requiredInputArea"
            currencyMask
            [options]="currencyMaskOptions"
        />
        <div *ngIf="enableCounterCharacters" class="character-counter">
            {{ currentCharacterCount }}/{{ maxCharacters }}
        </div>
    </div>
    <div class="buttons">
        <cmx-button
            class="confirm"
            [variant]="confirmType"
            (click)="confirm()"
            [disabled]=" (enableInputArea && requiredInputArea && (!inputAreaValue || inputAreaValue.trim().length < minCharacters))">
            {{ labelAction }}
        </cmx-button>
        <cmx-button
            class="cancel"
            variant="light"
            (click)="cancel()">
            {{ labelCancel }}
        </cmx-button>
    </div>
</div>
<div (click)="closeButton ? close() : cancel()" class="confirmation-modal-overlay" *ngIf="!hidden"></div>
