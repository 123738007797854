import 'moment/locale/pt-br';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';

import { DropdownOption } from 'app/shared/components/cmx-dropdown/cmx-dropdown.model';
import { FilterSelection } from './filter-selection';
import { groupingOptions } from './constants/grouping.options';
import { hierarchyOptions } from './constants/hierarchy.options';
import { overlayConfig } from './constants/overlay';
import { Type } from '#services/_domain/domain.type';

import { DomainService } from '#services/_domain/domain.service';
import { FilterStateService } from './filter-state.service';
import { GroupingStateService } from './grouping-state.service';
import { HierarchyStateService } from './hierarchy-state.service';
import { SortByStateService } from './sort-by-state.service';
import { CmxDropdownService } from 'app/shared/components/cmx-dropdown/cmx-dropdown.service';
import { Hierarchy, Grouping, ResultsService } from '#services/_results/results.service';
import { UtilsService } from '#services/_utils/utils.service';
import { UserCacheService } from '#services/_user/app-user-cache.service';

declare let mApp: any;

@Component({
    selector: 'cmx-results-filter',
    templateUrl: './filter.component.html',
})
export class FilterComponent implements OnInit {
    readonly dropdownSettings = {
        singleSelection: false,
        text: 'Todos',
    };

    @Output() public onChange = new EventEmitter<any>();
    @Input() public renderButtons: boolean;

    public options: {
        hierarchies: any[];
        groupings: any[];
        sortBy: any[];
        companies: DropdownOption[];
        profitCenter: DropdownOption[];
        trader: DropdownOption[];
        director: DropdownOption[];
        client: DropdownOption[];
        imps: DropdownOption[];
        transactionTypes: DropdownOption[];
    } = {
        hierarchies: hierarchyOptions,
        groupings: groupingOptions,
        sortBy: [],
        companies: [],
        profitCenter: [],
        trader: [],
        director: [],
        client: [],
        imps: [],
        transactionTypes: [],
    };

    public editable: State;

    public periodFrom: string;
    public periodTo: string;
    public active = new State();

    public currencySwitch = false;
    public currencyValue = false;

    public crGroupedSwitch = false;
    public crGroupedValue = false;

    private overrided = false;

    loadingData: boolean = true;

    constructor(
        private domainService: DomainService,
        private hierarchyState: HierarchyStateService,
        private groupingState: GroupingStateService,
        private sortByState: SortByStateService,
        private resultsService: ResultsService,
        private filterState: FilterStateService,
        private _userCacheService: UserCacheService,
        private _cmxDropdownService: CmxDropdownService,
        private _utilsService: UtilsService,
    ) {
        this.groupingState.loadGrouping();
        this.hierarchyState.loadHierarchy();
        this.sortByState.loadSortBy();

        this.resultsService.getAccountHierarchyGroups().subscribe((data) => {
            this.options.sortBy = data;
            this.loadingData = false;
        }, (error) => {
            this._utilsService.errorHandler(error, 'DRE - Filtros');
            this.loadingData = false;
        });
     }

    public ngOnInit() {
        if (this.overrided === false) {
            this.active = {
                hierarchy: this.options.hierarchies.length ? this.options.hierarchies[0].value : '',
                grouping: this.options.groupings.length ? this.options.groupings[0].value : '',
                sortBy: this.options.sortBy.length ? this.options.sortBy[0].value : '',
                filter: new FilterSelection(),
            };
            this.editable = this.clone(this.active);
        } else {
            this.active = this.editable;
        }
        this.filterState.current$.subscribe((filter) => {
            this.active.filter = filter;
        });
        this.groupingState.current$.subscribe((grouping) => {
            this.active.grouping = grouping;
            this.editable.grouping = grouping;
        });
        this.hierarchyState.current$.subscribe((hierarchy) => {
            this.active.hierarchy = hierarchy;
            this.editable.hierarchy = hierarchy;
        });
        this.sortByState.current$.subscribe((sortBy) => {
            this.active.sortBy = sortBy;
            this.editable.sortBy = sortBy;
        });
        this.options.profitCenter = this._cmxDropdownService.toDropdownItems(this._userCacheService.getProfitCenters());
        this.options.trader = this._cmxDropdownService.toDropdownItems(this._userCacheService.getTraders());
        this.options.director = this._cmxDropdownService.toDropdownItems(this._userCacheService.getDirectors());
        this.options.client = this._cmxDropdownService.toDropdownItems(this._userCacheService.getClientGroups());
        this.options.companies = this._cmxDropdownService.toDropdownItems(this._userCacheService.getCompanies());
        this.domainService.getTransactionTypes().subscribe((data) => {
            this.options.transactionTypes = this._cmxDropdownService.toDropdownItems(data, true);
        });
        setTimeout(() => {
            this.applyChanges();
            this.configInputs();
            this.editable = this.clone(this.active);
            this.prepare();
        }, 1500);
    }

    configInputs() {
        $('#periodFrom, #periodTo')
            .datepicker('destroy')
            .datepicker({
                todayHighlight: false,
                orientation: 'bottom left',
                minViewMode: 'months',
                maxViewMode: 'years',
                format: 'mm/yyyy',
                immediateUpdates: true,
                language: 'pt-BR',
                templates: {
                    leftArrow: "<i class='la la-angle-left'></i>",
                    rightArrow: "<i class='la la-angle-right'></i>",
                },
            });

        $.validator.addMethod(
            'maxDateRange',
            (periodTo, el, params) => {
                let periodFrom = params[0];
                const type = params[1];
                if (periodTo === '' || periodFrom === '') {
                    return true;
                }

                if (type === 'to') {
                    const period = periodTo;
                    periodTo = periodFrom;
                    periodFrom = period;
                }

                const parsedFrom = moment(periodFrom, 'MM/YYYY');
                const parsedTo = moment(periodTo, 'MM/YYYY');
                const diff = parsedTo.diff(parsedFrom, 'months');

                return diff <= 13;
            },
            'Range de data maior do que o permitido, que é de 13 meses.',
        );

        $.validator.addMethod(
            'validDateRange',
            (periodTo, el, params) => {
                let periodFrom = params[0];
                const type = params[1];
                if (periodTo === '' || periodFrom === '') {
                    return true;
                }

                if (type === 'to') {
                    const period = periodTo;
                    periodTo = periodFrom;
                    periodFrom = period;
                }

                const parsedFrom = moment(periodFrom, 'MM/YYYY');
                const parsedTo = moment(periodTo, 'MM/YYYY');
                const diff = parsedTo.diff(parsedFrom, 'months');

                return diff >= 0;
            },
            'A data inicial do período é maior do que a data final',
        );

        $('#filters').validate({
            groups: { period: 'periodFrom periodTo' },
            rules: {
                periodFrom: {
                    validDateRange: {
                        param: () => {
                            return [$('#periodTo').val(), 'to'];
                        },
                    },
                    maxDateRange: {
                        param: () => {
                            return [$('#periodTo').val(), 'to'];
                        },
                    },
                },
                periodTo: {
                    validDateRange: {
                        param: () => {
                            return [$('#periodFrom').val(), 'from'];
                        },
                    },
                    maxDateRange: {
                        param: () => {
                            return [$('#periodFrom').val(), 'from'];
                        },
                    },
                },
            },
        });

        $('.m_selectpicker').selectpicker('destroy');
        $('.m_selectpicker').selectpicker();
    }

    public overrideState(hierarchy: Hierarchy, grouping: Grouping, sortBy: string, filter: FilterSelection) {
        this.overrided = true;
        this.editable = new State();
        this.editable.grouping = grouping;
        this.editable.hierarchy = hierarchy;
        this.editable.sortBy = sortBy;
        this.editable.filter = filter;
        this.checkValueBySort();
    }

    public applyChanges() {
        if (this.currencySwitch) {
            this.currencyValue ? this.editable.filter.currency = 'USD' : this.editable.filter.currency = 'BRL';
        } else {
            this.editable.filter.currency = 'BRL';
        }

        if (this.crGroupedSwitch) {
            this.crGroupedValue ? this.editable.filter.crGrouped = true : this.editable.filter.crGrouped = false;
        } else {
            this.editable.filter.crGrouped = false;
        }

        this.editable.filter.refresh();

        const hierarchyChanged = this.active.hierarchy !== this.editable.hierarchy;
        const groupingChanged = this.active.grouping !== this.editable.grouping;

        this.active = this.clone(this.editable);

        if (hierarchyChanged) {
            this.hierarchyState.changeHierarchy(this.active.hierarchy);
        }

        if (groupingChanged) {
            this.groupingState.changeGrouping(this.active.grouping);
        }

        this.sortByState.changeSortBy(this.editable.sortBy || '');
        this.filterState.changeFilter(this.active.filter);
        this.onChange.emit(this.active.filter);
    }

    public verifyCompanies() {
        const companies = this.editable.filter.companies;
        const codeList = [];
        companies.map((company) => {
            codeList.push(company.id);
        });
        if (codeList.length === 2 && codeList.indexOf('12') > -1
            && codeList.indexOf('17') > -1) {
            this.currencySwitch = true;
        } else if (codeList.length === 1 && codeList.indexOf('12') > -1 ||
            codeList.length === 1 && codeList.indexOf('17') > -1) {
            this.currencySwitch = true;
        } else {
            this.currencySwitch = false;
        }
    }

    public prepare() {
        this.loadImpDropDown();
        $('#sel-hierarchy').selectpicker('val', this.editable.hierarchy);
        $('#sel-grouping').selectpicker('val', this.editable.grouping);
        $('#sel-sortBy').selectpicker('val', this.editable.sortBy);
        $('#periodFrom').datepicker('update', this.editable.filter.startDate);
        $('#periodTo').datepicker('update', this.editable.filter.endDate);
        this.checkValueBySort();
    }

    public verifyImpState() {
        return this.editable.filter.client.length === 1 &&
            this.editable.grouping === 'byImp';
    }

    public loadImpDropDown() {
        if (this.verifyImpState()) {
            this.domainService.getImpsByGroup(
                moment(this.editable.filter.startDate, 'MM/YYYY').format('YYYY-MM'),
                moment(this.editable.filter.endDate, 'MM/YYYY').format('YYYY-MM'),
                [this.editable.filter.client[0].id]).subscribe((data) => {
                    this.options.imps = this._cmxDropdownService.toDropdownItems(data);
                });
        }

        if (!this.verifyImpState()) {
            this.editable.filter.imps = this._cmxDropdownService.toDropdownItems([]);
        }

        if (this.editable.grouping === 'byCr') {
            this.crGroupedSwitch = true;
        } else {
            this.crGroupedSwitch = false;
            this.crGroupedValue = false;
            this.editable.filter.crGrouped = false;
        }
    }

    public changePeriod(type: string) {
        switch (type) {
            case 'YTD':
                $('#periodFrom').datepicker('update', moment().month(0).date(1).format('MM/YYYY'), );
                $('#periodTo').datepicker('update', moment().format('MM/YYYY'));
                break;
            case 'previousYear':
                const previousYear = moment().add(-1, 'year');
                $('#periodFrom').datepicker('update', previousYear.month(0).date(1).format('MM/YYYY'), );
                $('#periodTo').datepicker('update', previousYear.month(11).date(31).format('MM/YYYY'), );
                break;
        }
    }

    public derive(filter: any) {
        this.blockUI();
        let type: Type;

        switch (filter) {
            case 'company':
                type = Type.company;
                break;
            case 'profitCenter':
                type = Type.profitCenter;
                break;
            case 'client':
                type = Type.clientGroup;
                break;
            case 'director':
                type = Type.director;
                break;
            case 'trader':
                type = Type.trader;
                break;
        }

        const params = this.editable.filter.toDerivationParams(type);

        this.domainService.derive(type, params).subscribe(
            (data) => {
                if (filter !== 'company') {
                    this.editable.filter.companies = this._cmxDropdownService.toDropdownItems(data.company);
                }

                if (filter !== 'profitCenter') {
                    this.editable.filter.profitCenter = this._cmxDropdownService.toDropdownItems(data.profitCenter);
                }

                if (filter !== 'client') {
                    this.editable.filter.client = this._cmxDropdownService.toDropdownItems(data.clientGroup);
                }

                if (filter !== 'director') {
                    this.editable.filter.director = this._cmxDropdownService.toDropdownItems(data.director);
                }

                if (filter !== 'trader') {
                    this.editable.filter.trader = this._cmxDropdownService.toDropdownItems(data.trader);
                }
            },
            () => {
                //
            },
            () => this.unblockUI(),
        );
    }

    checkValueBySort() {
        if(this.editable.hierarchy === 'income-statement-mgmt' && this.editable.grouping === 'byClient') {
            $("#sel-sortBy").prop('disabled', false);
            $('#sel-sortBy').selectpicker('refresh');
        } else {
            this.editable.sortBy = '';
            this.sortByState.changeSortBy('');
            $('#sel-sortBy').selectpicker('val', this.editable.sortBy);
            $("#sel-sortBy").prop('disabled', true);
            $('#sel-sortBy').selectpicker('refresh');
        }
    }

    private blockUI() {
        mApp.block('#filter-form .m-portlet__body', overlayConfig);
    }

    private unblockUI() {
        mApp.unblock('#filter-form .m-portlet__body');
    }

    private clone(source: State) {
        return {
            hierarchy: source.hierarchy,
            grouping: source.grouping,
            sortBy: source.sortBy,
            filter: source.filter.clone(),
        };
    }

    trackByFn(index: number, item: any) {
        return index;
    }
}

// tslint:disable:max-classes-per-file
class State {
    public hierarchy: Hierarchy;
    public grouping: Grouping;
    public sortBy: string;
    public filter: FilterSelection;
}
