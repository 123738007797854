import { IGridColumn } from 'app/shared/components/cmx-custom-grid/interfaces';

export const BOARDING_STATUS_COLUMNS: IGridColumn[] = [
    {
        name: 'Cliente',
        field: 'clientGroup',
        formatterData: {
            singleField: 'name',
        },
        width: 130,
        isSortable: true,
    },
    {
        name: 'Embarque',
        field: 'shipmentCode',
        width: 120,
        isSortable: true,
        preSortable: 'asc'
    },
    {
        name: 'Navio Origem',
        field: 'shipOrigin',
        width: 150,
        isSortable: true,
    },
    {
        name: 'Navio Destino',
        field: 'shipName',
        width: 135,
        isSortable: true,
    },
    {
        name: 'Viagem',
        field: 'travelCode',
        width: 100,
        isSortable: true,
    },
    {
        name: 'Veículos',
        field: 'vehicles',
        formatterType: 'badge',
        formatterData: {
            singleField: 'totalVehicles',
            classes: 'pointer'
        },
        width: 100,
        isSortable: true,
    },
    {
        name: 'Status',
        field: 'status',
        formatterType: 'status',
        cssClass: 'grid-cell align-center',
        width: 90,
    },
    {
        name: 'ETD',
        field: 'etd',
        formatterType: 'date',
        cssClass: 'grid-cell align-center',
        width: 95,
        isSortable: true,
    },
    {
        name: 'ETA',
        field: 'eta',
        formatterType: 'date',
        cssClass: 'grid-cell align-center',
        width: 95,
        isSortable: true,
    },
    {
        name: 'ETA Origem',
        field: 'etaOrigin',
        formatterType: 'date',
        width: 120,
        isSortable: true,
    },
    {
        name: 'Armador',
        field: 'shipOwner',
        width: 135,
        isSortable: true,
    },
    {
        name: 'Porto Embarque',
        field: 'portOrigin',
        width: 160,
        isSortable: true,
    },
    {
        name: 'Porto Destino',
        field: 'portDestination',
        width: 155,
        isSortable: true,
    },
    {
        name: 'Transbordo',
        field: 'transhipment',
        width: 125,
        isSortable: true,
    },
    {
        name: 'Deferimento LI',
        field: 'dateGrantImportLicense',
        formatterType: 'date',
        cssClass: 'grid-cell align-center',
        width: 145,
        isSortable: true,
    },
];

export const BOARDING_STATUS_PAGINATION_STYLES = {
    height: `160px`,
    'margin-top': '0px',
}

export const BOARDING_STATUS_NON_PAGINATION_STYLES = {
    height: `195px`,
    'margin-top': '0px',
}
