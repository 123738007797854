import { Pipe, PipeTransform, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { UserCacheService } from '#services/_user/app-user-cache.service';
import { UserPreferencesService } from '#services/_user/user-preferences.service';

@Pipe({
  name: 'localeDatePipe',
  pure: false
})

export class LocaleDatePipe implements PipeTransform, OnDestroy {
    private locale: string;
    private translateSubscription: Subscription;

    constructor(
    private _userCacheService: UserCacheService,
    private _userPreferencesService: UserPreferencesService
    ) {
        this.locale = this._userCacheService.getLanguage();

        this.translateSubscription = this._userPreferencesService.changePreferences$.subscribe((typeChange) => {
            if (typeChange === 'language') this.locale = this._userCacheService.getLanguage();
        });
    }

    transform(value: Date | string | number, format: string = 'shortDate'): any {
        if (!value) return '';

        const datePipe = new DatePipe(this.locale);

        if (this.locale === 'en-US' && format === 'shortDate') {
            format = 'MM/dd/yyyy';
        }

        if (format === 'monthYear') {
            format = 'MM/yyyy';
        }

        const formattedDate = datePipe.transform(value, format);

        return formattedDate || '';
    }

    ngOnDestroy(): void {
        if (this.translateSubscription) this.translateSubscription.unsubscribe();
    }
}
