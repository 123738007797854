<div class="moveBtn"><div class="moveLine"></div></div>
<div *ngIf="asyncLogisticCosts || loadingTranslate"><loader></loader></div>

<div *ngIf="currentUser$ | async as user" class="header-container">
    <div class="title-information">
        <span class="analyticsName">{{ 'dashboard.title::logisticCosts' | translate }}</span>
        <cmx-help-modal
            [help-title]="'dashboard.title::logisticCosts' | translate"
            [help-description]="helpDescription"
            [help-video-src]="'assets/videos/icone-informativo/dashboardsAtualizado.mp4'"
            help-video-name="{{'common::strings.dashboard' | translate }} {{ 'dashboard.title::logisticCosts' | translate }}"
        ></cmx-help-modal>
        <h5 id="logisticCostsTitle">{{ 'dashboard.title::logisticCosts' | translate }}</h5>
        <div class="filter-container" style="z-index: 2" [style.margin-left]="isMixedUser ? '45px' : '0px'">
            <cmx-switch
                [options]="translatedSwitchOptions"
                [selected]="filterState.getType()"
                (onSelect)="toggleTypeSwitch($event)"
            ></cmx-switch>
        </div>
    </div>
</div>

<cmx-marker-filter
    *ngIf="currentUser$ | async as user"
    [filters]="translatedPeriodOptions"
    [preSelected]="['CURRENT_YEAR']"
    type="period"
    [multiple]="false"
    [hasSelectAll]="false"
    [leftDistance]="isMixedUser ? 500 : 455"
    (onSelected)="changeDateParam($event)"
></cmx-marker-filter>

<div *ngIf="currentUser$ | async as user" class="filterContainer">
    <div
        *ngIf="isMixedUser"
        tooltip="{{ 'common::strings.automotiveMode' | translate | uppercase }}: {{ (filterState.getAutomotiveMode() === 'Ligado' ? 'common::strings.on' : 'common::strings.off') | translate }}"
        class="filterMode"
        [ngClass]="{'clicked': filterState.getAutomotiveMode() === 'Ligado'}"
        (click)="toggleFilterMode()"
    >
        <div class="icon-align"><em class="material-icons">directions_car</em></div>
    </div>
    <div id="searchLine" class="reduzed">
        <div class="input-group" *ngIf="isInternalUser && clientOptions?.length > 1">
            <cmx-dropdown
                [styles]="{ width: '180px' }"
                name="clients"
                [data]="clientOptions"
                [ngModel]="filterState.getClientGroups()"
                (ngModelChange)="filterState.setClientGroups($event)"
                [settings]="dropdownSettings.clientGroups"
                (onChange)="loadLogisticCosts(selectedPeriod)"
            ></cmx-dropdown>
            <span class="input-group-addon" style="margin: 0 -6px 0 -15px;"></span>
        </div>
        <div class="input-group">
            <cmx-dropdown
                [styles]="{ width: '180px' }"
                name="costs"
                [data]="costsOptions"
                [ngModel]="filterState.getLogisticCosts()"
                (ngModelChange)="filterState.setLogisticCosts($event)"
                [settings]="dropdownSettings.costs"
                (onChange)="loadLogisticCosts(selectedPeriod)"
            ></cmx-dropdown>
            <span class="input-group-addon" style="margin: 0 -6px 0 -15px;"></span>
        </div>

        <div class="input-group">
            <cmx-dropdown
                [styles]="{ width: '160px' }"
                name="subsidiaryNames"
                [data]="subsidiaryNames"
                [settings]="dropdownSettings.subsidiary"
                [ngModel]="filterState.getSubsidiaryNames()"
                (ngModelChange)="filterState.setSubsidiaryNames($event)"
                (onChange)="loadLogisticCosts(selectedPeriod)"
            ></cmx-dropdown>
        </div>

        <div class="input-group">
            <span class="input-group-addon"></span>
            <cmx-dropdown
                [styles]="{ width: '125px' }"
                name="modal"
                [data]="modalOptions"
                [ngModel]="filterState.getModal()"
                (ngModelChange)="filterState.setModal($event)"
                [settings]="dropdownSettings.modals"
                (onChange)="loadLogisticCosts(selectedPeriod)"
            ></cmx-dropdown>
        </div>

        <div class="input-group">
            <span class="input-group-addon"></span>
            <cmx-filter-switch
                [values]="[ 'common::strings.columns' | translate, 'common::strings.rows' | translate ]"
                [inputIndex]="filterState.getChartType() === 'XYChart' ? 0 : 1"
                (onSwitch)="toggleVisualSwitch($event)"
            ></cmx-filter-switch>
        </div>

        <div *ngIf="user.has('AUTOMOTIVE_USER') || filterState.getAutomotiveMode() === 'Ligado'" class="input-group">
            <span class="input-group-addon" style="margin-right: -25px"></span>
            <div
                [tooltip]="'Armazenagem EADI'"
                [tooltip]="'dashboard::logisticCosts.eadiStorage' | translate"
                [ngClass]="{'clicked': filterState.getStorageToogle() === 'Considerando' }"
                class="storageToogle pointer"
                (click)="toggleStorage()"
            >
                <div style="pointer-events: none"><em class="material-icons">widgets</em></div>
            </div>
        </div>
    </div>
</div>

<div id="summaryLine">
    <cmx-summary-line [dataList]="summaryLine"></cmx-summary-line>
</div>

<div class="chart" id="logisticCosts" [ngClass]="{'hidden': isInternalUser}"></div>

<div *ngIf="!asyncLogisticCosts && chartData === undefined && !isInternalUser" class="reloadContainer">
    <div class="reloadContent">
        <i (click)="loadLogisticCosts(selectedPeriod)" class="material-icons">refresh</i>
    </div>
</div>

<div *ngIf="isInternalUser && filterState.getClientGroups()?.length < 1" class="empty-container">
    <p class="empty-client">{{ 'common::messages.pleaseSelectClient' | translate }}</p>
</div>
