import { Injectable } from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { createXYChart, createPieChart, createDraggableRangeChart, createDragOrderingBars, clusteredBarChart, createLineChart } from './graph-types';

export interface IChartAxis {
    axisPosition: string;
    categoryField: string;
    type?: string;
}

export interface IChartContent {
    name: string;
    valueYField: string;
    valueXField: string;
    tooltipMessage?: string;
    seriesType?: string;
    prefix?: string;
    suffix?: string;
}

export interface IChartReferenceLine {
    label: string;
    value: number;
}
export interface IChartTitle {
    text: string;
    size?: number;
}
export interface IChartAxisTitle {
    x?: string;
    y?: string;
}

export interface IChartConfig {
    labelText?: string;
    chartId: string;
    dataType?: string;
    colors?: string[];
    title?: IChartTitle;
    axisTitle?: IChartAxisTitle;
    chartType: string;
    axis: IChartAxis[];
    content?: IChartContent[];
    referenceLine?: IChartReferenceLine;
    showLegend?: boolean;
    showTooltip?: boolean;
    changeColors?: boolean;
    showValuesAboveBars?: boolean;
    labelConfig?: {
        fontSize?: number;
        fontWeight?: string;
        paddingTop?: number;
    };
    padding?: {
        top?: number;
        bottom?: number;
        left?: number;
        right?: number;
    };
    axisConfig?: {
        x?: {
            minGridDistance?: number;
        };
        y?: {
            extraMax?: number;
        };
    };
}
@Injectable()
export class AmCharts5Service {
    private chartRoots: Map<string, am5.Root> = new Map();

    createChart(chartConfig: IChartConfig, data, visibleDataPoints?: number, clickChartCallback?: (clickedData: any) => void) {
        if (this.chartRoots.has(chartConfig.chartId)) {
            const existingRoot = this.chartRoots.get(chartConfig.chartId);
            if (existingRoot) {
                existingRoot.dispose();
            }
            this.chartRoots.delete(chartConfig.chartId); 
        }

        const root = am5.Root.new(chartConfig.chartId);
        this.chartRoots.set(chartConfig.chartId, root); 

        root.autoResize = true;
        root.setThemes([am5themes_Animated.new(root)]);

        const chartType = chartConfig.chartType || 'XYChart';
        const limitedData = data.data.slice(0, visibleDataPoints);

        let chart;

        switch (chartType) {
            case 'XYChart':
                chart = createXYChart(root, chartConfig, limitedData);
                break;
            // Gráfico de Linha (LineChart)
            case 'LineChart':
                chart = createLineChart(root, chartConfig, limitedData, visibleDataPoints);
                break;
            // Gráfico de Barras horizontal com Ordenação por Arrasto (DragOrderingBars)
            case 'DragOrderingBars':
                chart = createDragOrderingBars(root, chartConfig, limitedData);
                break;
            // Gráfico de barras duplas horizontal (doubleBarChart)
            case 'doubleBarChart':
                chart = clusteredBarChart(root, chartConfig, limitedData);
                break;
            // Gráfico de pizza (PieChart)
            case 'PieChart':
                chart = createPieChart(root, chartConfig, limitedData, clickChartCallback);
                break;
            // Gráfico de Intervalo Arrastável (DraggableRange)
            case 'DraggableRange':
                chart = createDraggableRangeChart(root, chartConfig, limitedData, visibleDataPoints);
                break;
            default:
                chart = createXYChart(root, chartConfig, limitedData);
                break;
        }

        root._logo.dispose();
        const showLegend = chartConfig.showLegend !== undefined ? chartConfig.showLegend : false;
        this._createLegend(chart, root, showLegend);
    }

    destroyChart(chartId: string) {
        if (this.chartRoots.has(chartId)) {
            const root = this.chartRoots.get(chartId);
            if (root) {
                root.dispose();
            }
            this.chartRoots.delete(chartId);
        }
    }

    private _createLegend(chart, root, showLegend: boolean = true) {
        if (showLegend) {
            let legend = chart.children.push(
                am5.Legend.new(root, {
                    centerX: am5.p50,
                    x: am5.p50,
                    layout: root.horizontalLayout,
                    paddingTop: 10,
                    marginTop: 10,
                    y: am5.percent(95),
                    valign: "bottom",
                    width: am5.percent(90),
                    height: am5.percent(10),
                })
            );
            legend.data.setAll(chart.series.values);
        }
    }
}
