import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable()
export class FollowUpReportContainerFormatter {

    // Container
    formatContainer(row, cell, value, columnDef, dataContext) {
        const containers = [];
        value?.forEach((container) => {
            if (container.containerNumber && !containers.includes(container.containerNumber)) {
                containers.push(container.containerNumber);
            }
        });
        if (containers.length === 1) {
            return containers[0];
        } else if (containers.length > 1) {
            return `<span class="multiple-container large">Vários (${containers.length})</span>`;
        } else {
            return '';
        }
    }

    // Devolução do Container
    formatContainerDevolutionDate(row, cell, value, columnDef, dataContext) {
        const dates = [];
        value?.forEach((date) => {
            if (date.returnDate && !dates.includes(date.returnDate)) {
                dates.push(date.returnDate);
            }
        });
        if (dates.length === 1) {
            if (dates[0] === null || dates[0] === undefined || dates[0] === '') {
                return '';
            }
            return `<span class='dateValue'>${dates[0]}</span>`;
        } else if (dates.length > 1) {
            return `<span class="multiple-container-containerDevolutionDate">Várias (${dates.length})</span>`;
        } else {
            return '';
        }
    }

    // Tipo Container
    formatContainerTypeModel(row, cell, value, columnDef, dataContext) {
        const containerTypeModels = [];
        value?.forEach((container) => {
            if (container.containerTypeModel && !containerTypeModels.includes(container.containerTypeModel)) {
                containerTypeModels.push(container.containerTypeModel);
            }
        });
        if (containerTypeModels.length === 1) {
            return containerTypeModels[0];
        } else if (containerTypeModels.length > 1) {
            return `<span class="multiple-container-containerTypeModel">Vários (${containerTypeModels.length})</span>`;
        } else {
            return '';
        }
    }

    // Previsão Desova
    formatExpectedUnloadingDate(row, cell, value, columnDef, dataContext) {
        const dates = [];
        value?.forEach((container) => {
            if (container.expectedUnloadingDate && !dates.includes(container.expectedUnloadingDate)) {
                dates.push(container.expectedUnloadingDate);
            }
        });
        if (dates.length === 1) {
            if (dates[0] === null || dates[0] === undefined || dates[0] === '') {
                return '';
            } else if(dates[0] == 'NÃO SE APLICA') {
                return dates[0];
            }
            return `<span class='dateValue'>${moment(dates[0], 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>`;
        } else if (dates.length > 1) {
            return `<span class="multiple-container-expectedUnloadingDate">Várias (${dates.length})</span>`;
        } else {
            return '';
        }
    }

    // Data Solicitação de Desova
    formatRequestUnloadingDate(row, cell, value, columnDef, dataContext) {
        const dates = [];
        value?.forEach((container) => {
            if (container.requestUnloadingDate && !dates.includes(container.requestUnloadingDate)) {
                dates.push(container.requestUnloadingDate);
            }
        });
        if (dates.length === 1) {
            if (dates[0] === null || dates[0] === undefined || dates[0] === '') {
                return '';
            } else if(dates[0] == 'NÃO SE APLICA') {
                return dates[0];
            }
            return `<span class='dateValue'>${moment(dates[0], 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>`;
        } else if (dates.length > 1) {
            return `<span class="multiple-container-requestUnloadingDate">Várias (${dates.length})</span>`;
        } else {
            return '';
        }
    }

    // Data Desova
    formatUnloadingDate(row, cell, value, columnDef, dataContext) {
        const dates = [];
        value?.forEach((container) => {
            if (container.unloadingDate && !dates.includes(container.unloadingDate)) {
                dates.push(container.unloadingDate);
            }
        });
        if (dates.length === 1) {
            if (dates[0] === null || dates[0] === undefined || dates[0] === '') {
                return '';
            } else if(dates[0] == 'NÃO SE APLICA') {
                return dates[0];
            }
            return `<span class='dateValue'>${moment(dates[0], 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>`;
        } else if (dates.length > 1) {
            return `<span class="multiple-container-unloadingDate">Várias (${dates.length})</span>`;
        } else {
            return '';
        }
    }

    // Data conclusão
    formatTransitConclusionRemoveDate(row, cell, value, columnDef, dataContext) {
        const dates = [];
        value?.forEach((container) => {
            if (container.transitConclusionRemoveDate && !dates.includes(container.transitConclusionRemoveDate)) {
                dates.push(container.transitConclusionRemoveDate);
            }
        });
        if (dates.length === 1) {
            if (dates[0] === null || dates[0] === undefined || dates[0] === '') {
                return '';
            }
            return `<span class='dateValue'>${moment(dates[0], 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>`;
        } else if (dates.length > 1) {
            return `<span class="multiple-container-transitConclusionRemoveDate">Várias (${dates.length})</span>`;
        } else {
            return '';
        }
    }

    // Número da programação
    formatProgNumberRemove(row, cell, value, columnDef, dataContext) {
        const containers = [];
        value?.forEach((container) => {
            if (container.progNumberRemove && !containers.includes(container.progNumberRemove)) {
                containers.push(container.progNumberRemove);
            }
        });
        if (containers.length === 1) {
            return containers[0];
        } else if (containers.length > 1) {
            return `<span class="multiple-container-progNumberRemove">Vários (${containers.length})</span>`;
        } else {
            return '';
        }
    }

    // Data da remoção
    formatRemoveDate(row, cell, value, columnDef, dataContext) {
        const dates = [];
        value?.forEach((container) => {
            if (container.removeDate && !dates.includes(container.removeDate)) {
                dates.push(container.removeDate);
            }
        });
        if (dates.length === 1) {
            if (dates[0] === null || dates[0] === undefined || dates[0] === '') {
                return '';
            }
            return `<span class='dateValue'>${moment(dates[0], 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>`;
        } else if (dates.length > 1) {
            return `<span class="multiple-container-removeDate">Várias (${dates.length})</span>`;
        } else {
            return '';
        }
    }

    // Data Solicitação da remoção
    formatRequestRemoveDate(row, cell, value, columnDef, dataContext) {
        const dates = [];
        value?.forEach((container) => {
            if (container.requestRemoveDate && !dates.includes(container.requestRemoveDate)) {
                dates.push(container.requestRemoveDate);
            }
        });
        if (dates.length === 1) {
            if (dates[0] === null || dates[0] === undefined || dates[0] === '') {
                return '';
            }
            return `<span class='dateValue'>${moment(dates[0], 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>`;
        } else if (dates.length > 1) {
            return `<span class="multiple-container-requestRemoveDate">Várias (${dates.length})</span>`;
        } else {
            return '';
        }
    }

    // Transportadora Remoção
    formatCarrierDescription(row, cell, value, columnDef, dataContext) {
        const containers = [];
        value?.forEach((container) => {
            if (container.carrierDescription && !containers.includes(container.carrierDescription)) {
                containers.push(container.carrierDescription);
            }
        });
        if (containers.length === 1) {
            return containers[0];
        } else if (containers.length > 1) {
            return `<span class="multiple-container-carrierDescription">Vários (${containers.length})</span>`;
        } else {
            return '';
        }
    }

    // Previsão de entrega Searates (ETA SeaRates)
    formatEtaSearatesDate(row, cell, value, columnDef, dataContext) {
        const dates = [];
        value?.forEach((container) => {
            if (container.etaSearates && container.etaSearates != null && container.etaSearates != '') {
                dates.push(container.etaSearates);
            }
        });

        if (dates.length > 0) {
            return `<span class='dateValue'>${moment(dates[0], 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>`;
        } else {
            return '';
        }
    }    

    // Previsão de entrega Project44 (ETA P44)
    formatEtaP44Date(row, cell, value, columnDef, dataContext) {
        const dates = [];
        value?.forEach((container) => {
            if (container.etaP44 && container.etaP44 != null && container.etaP44 != '') {
                dates.push(container.etaP44);
            }
        });

        if (dates.length > 0) {
            return `<span class='dateValue'>${moment(dates[0], 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>`;
        } else {
            return '';
        }
    }

    // Navio Searates
    formatVehicleVADNameSearates(row, cell, value, columnDef, dataContext) {
        const containers = [];
        value?.forEach((container) => {
            if (container.vehicleVADNameSearates && container.vehicleVADNameSearates != null && container.vehicleVADNameSearates != '') {
                containers.push(container.vehicleVADNameSearates);
            }
        });
        if (containers.length > 0) {
            return containers[0];
        } else {
            return '';
        }
    }   

    // Volume
    formatContainerVolume(row, cell, value, columnDef, dataContext) {
        const containers = [];
        value?.forEach((container) => {
            if (container.quantity && !containers.includes(container.quantity)) {
                containers.push(container.quantity);
            }
        });
        if (containers.length === 1) {
            return containers[0];
        } else if (containers.length > 1) {
            return `<span class="multiple-container-volume-description large">Vários (${containers.length})</span>`;
        } else {
            return '';
        }
    }

    // Description
    formatContainerDescription(row, cell, value, columnDef, dataContext) {
        const containers = [];
        value?.forEach((container) => {
            if (container.description && !containers.includes(container.description)) {
                containers.push(container.description);
            }
        });
        if (containers.length === 1) {
            return containers[0];
        } else if (containers.length > 1) {
            return `<span class="multiple-container-volume-description large">Vários (${containers.length})</span>`;
        } else {
            return '';
        }
    }
}
